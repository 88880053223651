import { AddCircle, ViewStream } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { useTranslation } from "react-i18next";

export const TemplateItemItemsControls = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(
    state =>
      (state.templateForm.current !== null &&
        state.templateForm.current.published_at !== null) ||
      state.loading.models.templates.loading ||
      state.loading.models.app.loading,
  );

  const onAddTitle = () => {
    dispatch.templateForm.createSection({
      partial: { widget_type: "SECTION_HEADER" },
    });
  };

  const onAddSection = () => {
    dispatch.templateForm.createSection({ partial: { widget_type: "TEXT" } });
  };

  return (
    <Box display="flex" m="auto" mb={1}>
      <Button
        disabled={disabled}
        startIcon={<ViewStream />}
        sx={{ mr: 2 }}
        onClick={onAddTitle}
      >
        {t("Add Section Header")}
      </Button>
      <Button disabled={disabled} startIcon={<AddCircle />} onClick={onAddSection}>
        {t("Add Question Card")}
      </Button>
    </Box>
  );
};
