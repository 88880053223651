import { TemplateItem } from "@api";
import i18next from "../i18n";

export function formatTemplateItemWidgetType(
  type: TemplateItem["widget_type"],
): string {
  switch (type) {
    case "TEXT":
      return i18next.t("Short answer").toString();
    case "PARAGRAPH":
      return i18next.t("Paragraph").toString();
    case "CHECKBOX_LIST":
      return i18next.t("Checkboxes").toString();
    case "RADIO_LIST":
      return i18next.t("Multiple choices").toString();
    case "SECTION_HEADER":
      return i18next.t("Section header").toString();
    default:
      return i18next.t("Unknown template widget type").toString();
  }
}
