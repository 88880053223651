import { Box } from "@mui/material";
import { styled } from "@mui/system";

export interface CenteredProps {
  children?: React.ReactNode;
}

export const Centered = styled(Box)(() => {
  return {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  };
});

// export const Centered: React.FC<CenteredProps> = ({ children }) => {
//   return (
//     <Box
//     sx={{
//       display: "flex",
//       justifyItems: "center",
//       alignItems: "center",
//       height: "100%",
//     }}
//   >

//     </Box>
//   );
// };
