import { ContextMenu, CtxMenuItem } from "@components";

import { MenuList } from "@mui/material";
import { RouterHelper } from "@pages";
import {
  ContextMenuTypes,
  QuestionDialogTypes,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import React from "react";
import { useTranslation } from "react-i18next";

export const WearersContextMenu: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isInactive = useAppSelector(state => state.entityTable.quickFilter.isInactive);
  const selected = useAppSelector(state => state.entityTable.selectedIds);
  const numSelected = selected.length;
  const isMultiSelect = numSelected > 1;

  const onClose = () => {
    dispatch.ctxMenu.closeAll();
  };

  return (
    <ContextMenu type={ContextMenuTypes.RECORD_TABLE}>
      <MenuList>
        <CtxMenuItem
          label={t("View details")}
          onClick={() => {
            dispatch.viewDetails.viewWearer(selected[0]);
            onClose();
          }}
          divider
          disabled={isMultiSelect}
          tooltip={t("Multiple wearers selected")}
        />
        <CtxMenuItem
          label="Rename"
          to={
            selected.length
              ? RouterHelper.workspaceSettingsWearersEdit.getRelativePath({
                  props: { wearerId: selected[0] },
                })
              : undefined
          }
          disabled={isMultiSelect}
          tooltip={t("Multiple wearers selected")}
          divider
        />

        {isInactive && (
          <CtxMenuItem
            onClick={() => {
              onClose();
              dispatch.questionDialog.set({
                type: QuestionDialogTypes.WEARERS_RESTORE,
                onSuccess: () => {
                  dispatch.wearers.updateInactive({
                    ids: selected,
                    inactive: false,
                  });
                },
                extra: { count: selected.length },
              });
            }}
            label={
              numSelected === 1
                ? t("Restore Wearer")
                : t("Restore {{numSelected}} Wearers", { numSelected })
            }
          />
        )}

        {isInactive && (
          <CtxMenuItem
            label={
              numSelected === 1
                ? t("Delete Wearer")
                : t("Delete {{numSelected}} Wearers", { numSelected })
            }
            onClick={() => {
              onClose();
              dispatch.questionDialog.set({
                type: QuestionDialogTypes.WEARERS_DELETE,
                onSuccess: () => {
                  dispatch.wearers.delete(selected);
                },
                extra: {
                  count: selected.length,
                },
              });
            }}
          />
        )}

        {!isInactive && (
          <CtxMenuItem
            onClick={() => {
              onClose();
              dispatch.wearers.updateInactive({ ids: selected, inactive: true });
            }}
            label={
              numSelected === 1
                ? t("Trash wearer")
                : t("Trash {{numSelected}} wearers", {
                    numSelected,
                  })
            }
          />
        )}
      </MenuList>
    </ContextMenu>
  );
};
