import { TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

export const TemplateNameEdit = () => {
  const { t } = useTranslation();
  const name = useAppSelector(state => state.templateForm.draft.name);
  const disabled = useAppSelector(
    state =>
      (state.templateForm.current !== null &&
        state.templateForm.current.published_at !== null) ||
      state.loading.models.templates.loading ||
      state.loading.models.app.loading,
  );
  const dispatch = useAppDispatch();

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch.templateForm.setTemplateName(e.target.value);
  };

  return (
    <TextField
      disabled={disabled}
      size="small"
      value={name}
      onChange={onChange}
      placeholder={t("Enter Template Name")}
    />
  );
};
