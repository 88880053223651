import { SubmitButton, TextField } from "@form";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import i18n from "../../i18n";
import { DefaultActionsProps } from "./DefaultActions";

export const deleteFormSchema = yup
  .object()
  .shape({
    delete: yup
      .string()
      .matches(/^(DELETE)$/gi, i18n.t("Please type DELETE to confirm").toString()),
  })
  .required();

export const deleteFormValues = {
  delete: "",
};

export const DeleteFormContent = () => {
  const { t } = useTranslation();

  return (
    <TextField
      name="delete"
      placeholder={t("Type DELETE to confirm")}
      fullWidth
      autoFocus
    />
  );
};

export const DeleteFormAction = (props: DefaultActionsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCancel = () => {
    props.onCancel();
    navigate(-1);
  };

  return (
    <>
      <Button onClick={handleCancel}>{t("Cancel")}</Button>
      <SubmitButton color="error" useIsDirty useIsValid>
        {t("Delete")}
      </SubmitButton>
    </>
  );
};
