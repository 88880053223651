import { RouterHelper } from "@pages";
import { useAppSelector } from "@storeRematch";
import { localStorageAdapter, LocalStorageAdapterNames } from "@utils";
import { Navigate } from "react-router-dom";

export const Home = () => {
  const user = useAppSelector(state => state.app.apiUser);

  if (user) {
    const previousWorkspaceId = localStorageAdapter.get<string>(
      LocalStorageAdapterNames.lastWorkspaceId,
    );
    const currentWorkspaceId = previousWorkspaceId || user.default_workspace_id;

    return (
      <Navigate
        to={RouterHelper.workspaceRecordings.getFullPath({
          props: { workspaceId: currentWorkspaceId },
        })}
        replace
      />
    );
  }

  return null;
};
