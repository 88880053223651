import { Check } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  SelectProps,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const Select: FC<
  SelectProps & {
    name: string;
    options: Array<{
      value: string | number;
      label: string | number;
      sub?: string | number;
    }>;
    checkedIcon?: boolean;
  }
> = ({
  name,
  options,
  fullWidth,
  required,
  label,
  sx,
  disabled,
  checkedIcon,
  ...props
}) => {
  const methods = useFormContext();

  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field, formState: { errors } }) => {
        const isError = !!errors[name];

        return (
          <FormControl sx={sx} variant="standard" fullWidth={fullWidth}>
            {label && (
              <InputLabel
                shrink
                disabled={disabled}
                error={isError}
                color="primary"
                required={required}
                disableAnimation
              >
                {label}
              </InputLabel>
            )}
            <MuiSelect
              {...props}
              {...field}
              fullWidth={fullWidth}
              label={undefined}
              value={field.value || ""}
              error={isError}
              disabled={disabled}
              renderValue={value => {
                return (
                  <ListItemText>
                    {options.find(o => o.value === value)?.label}
                  </ListItemText>
                );
              }}
            >
              {options.map(({ value, label, sub }) => (
                <MenuItem
                  key={value}
                  value={value}
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <ListItemText>{label}</ListItemText>
                    {field.value === value && checkedIcon ? (
                      <ListItemIcon>
                        <Check fontSize="small" sx={{ ml: "auto" }} />
                      </ListItemIcon>
                    ) : null}
                  </Box>
                  {sub && (
                    <Typography
                      variant="body3"
                      color="text.secondary"
                      sx={{ width: "100%" }}
                    >
                      {sub}
                    </Typography>
                  )}
                </MenuItem>
              ))}
            </MuiSelect>
            {isError && (
              <FormHelperText error>{errors[name]?.message as string}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};
