export class CheckIfModelNeedsUpdate {
  private id: string | null = null;
  private models = new Set<string>();

  reset() {
    this.id = null;
    this.models = new Set();
  }

  check(id: string, obj: any) {
    const str = JSON.stringify(obj);

    if (id !== this.id) {
      this.reset();
    }

    if (this.models.has(str)) return false;

    this.id = id;
    this.models.add(str);

    return true;
  }

  checkOne(id: string, obj: any) {
    if (!this.check(id, obj)) return false;

    if (this.models.size === 2) {
      this.reset();
    }

    return true;
  }
}
