import { EnrichmentTypesEnum, ProjectEnrichment, VisualizationTypes } from "@api";
import { ReactRouterLinkCustom, VirtualizedList } from "@components";
import { ModalIds } from "@components/onboarding/OnboardingConfig";
import { OpenInNewTab } from "@customIcons";
import { Add } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  Dialog,
  Link,
  MenuItem,
  MenuList,
  Paper,
  Portal,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { RouterHelper } from "@pages";
import { store, useAppDispatch, useAppSelector } from "@storeRematch";
import {
  DEFAULT_ENRICHMENT,
  DEFAULT_VISUALIZATION,
  DisableByPermissionsType,
  EnrichmentVisualizationNames,
  SELECT_ENRICHMENT,
  SELECT_VISUALIZATION,
  SelectEnrichmentProps,
  disabledByPermission,
  formatReadableEnrichment,
  sanitizeForRegex,
} from "@utils";
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SearchBox } from "./Common/SearchBox";

const defaultData: {
  model: SelectEnrichmentProps | undefined;
  viz: boolean;
} = { model: DEFAULT_ENRICHMENT, viz: false };

export const NewProjectModelDialogMain = ({ buttonId }: { buttonId?: ModalIds }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(
    state =>
      !state.loading.effects.app.loadProjectData.success ||
      !state.projectEdit.recordings.length ||
      state.loading.effects.projectEdit.createEnrichment.loading ||
      state.loading.effects.visualizations.create.loading ||
      state.loading.effects.staticImageMappers.create.loading,
  );
  const [open, setOpen] = useState(false);

  const [data, setData] = useState({ ...defaultData });
  const disabledPermissions = disabledByPermission({
    type: DisableByPermissionsType.CREATE,
  });
  const [enrichmentId, setEnrichmentId] = useState<string | null>(null);
  const enrichmentSelect =
    data.model?.value === "aoi" || data.model?.value === "heatmap";

  const handleChange = (viz: boolean) => (model: SelectEnrichmentProps) => {
    setEnrichmentId(null);
    setData({ model, viz });
  };

  const toggleOpen = () => {
    if (!open) {
      if (RouterHelper.projectVisualizations.matchCurrentPath()) {
        setData({ model: DEFAULT_VISUALIZATION, viz: true });
      } else {
        setData({ ...defaultData });
      }
    }

    setOpen(!open);
  };

  const reset = () => {
    toggleOpen();
    setData({ ...defaultData });
  };

  const onClick = async () => {
    const state = store.getState();
    const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
    const projectId = state.app.currentProject?.id;

    if (!workspaceId || !projectId) return;

    if (data.model && (data.model.value === "aoi" || data.model?.value === "heatmap")) {
      if (!enrichmentId) return;

      const model = await dispatch.visualizations.create({
        type:
          data.model.value === "aoi"
            ? VisualizationTypes.AOI_HEATMAP
            : VisualizationTypes.HEATMAP,
        enrichmentId,
      });

      if (!model) return;

      navigate(
        data.model.value === "aoi"
          ? RouterHelper.projectVisualizationsAoi.getFullPath({
              prefix: "/",
              props: {
                projectId,
                workspaceId,
                visualizationId: model.id,
              },
            })
          : RouterHelper.projectVisualizationsHeatmap.getFullPath({
              prefix: "/",
              props: {
                projectId,
                workspaceId,
                visualizationId: model.id,
              },
            }),
      );

      return;
    }

    let modelId: string | null = null;

    if (data.model?.value === "static-image-mapper") {
      const model = await dispatch.staticImageMappers.create({
        name: "Untitled",
        from_event_name: "recording.begin",
        to_event_name: "recording.end",
      });

      if (!model) return;

      modelId = model.id;
    } else {
      const model = await dispatch.projectEdit.createEnrichment({
        name: "Untitled",
        kind: data.model?.value as unknown as ProjectEnrichment["kind"],
        from_event_name: "recording.begin",
        to_event_name: "recording.end",
      } as ProjectEnrichment);

      if (!model) return;

      modelId = model.id;
    }

    navigate(
      data.viz
        ? RouterHelper.projectVisualizationsRender.getFullPath({
            prefix: "/",
            props: {
              projectId,
              workspaceId,
              enrichmentId: modelId,
            },
          })
        : RouterHelper.projectEnrichmentsEdit.getFullPath({
            prefix: "/",
            props: {
              projectId,
              workspaceId,
              enrichmentId: modelId,
            },
          }),
    );

    toggleOpen();
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={reset}
        sx={{
          "& .MuiDialog-paper": {
            minHeight: "70vh",
            maxHeight: 663,
          },
        }}
      >
        <Box sx={{ display: "flex", p: 2, flex: 1, minHeight: "70vh", maxHeight: 663 }}>
          <Box display="flex" flexDirection="column">
            {RouterHelper.projectVisualizations.matchCurrentPath() ? (
              <List
                model={data.model}
                list={SELECT_VISUALIZATION}
                disabled={disabled}
                onClick={handleChange(true)}
                title={t("Visualization")}
              />
            ) : (
              <List
                model={data.model}
                list={SELECT_ENRICHMENT}
                disabled={disabled}
                onClick={handleChange(false)}
                title={t("Enrichment")}
              />
            )}
          </Box>
          <Box flex={1} display="flex" flexDirection="column" ml={1}>
            {data.model && (
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h6">{data.model.label}</Typography>
                  {data.model.value === "static-image-mapper" ? null : (
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      endIcon={<OpenInNewTab />}
                      component={ReactRouterLinkCustom}
                      to={data.model?.link || ""}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("Learn More")}
                    </Button>
                  )}
                </Box>

                <Box
                  sx={{
                    maxHeight: 272,
                    backgroundColor: "#1f1e23",
                    mt: 2,
                    display: "flex",
                    justifyContent: "center",

                    width: "100%",
                    height: "auto",
                  }}
                >
                  {data.model.video ? (
                    <video width="auto" height="auto" autoPlay loop>
                      <source src={data.model.video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      src={require(`/src/assets/images/${data.model.value}.png`)}
                      alt={data.model.value}
                    />
                  )}
                </Box>

                <Content type={data.model.value} />
              </>
            )}

            <EnrichmentSelect
              enrichmentId={enrichmentId}
              setEnrichmentId={setEnrichmentId}
              show={enrichmentSelect}
            />

            <Button
              fullWidth
              color="primary"
              sx={{ mt: "auto", ml: -0.5 }}
              onClick={onClick}
              disabled={(enrichmentSelect && !enrichmentId) || disabled}
            >
              {t("Create")}
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Button
        id={buttonId}
        disabled={disabledPermissions || disabled}
        startIcon={<Add />}
        color="primary"
        size="small"
        onClick={toggleOpen}
        sx={{ mx: 2, my: 3, visibility: "hidden", position: "absolute" }}
      >
        {t("Create")}
      </Button>
    </>
  );
};

export const NewProjectModelDialogMainButton: FC<{ main?: boolean }> = memo(
  ({ main }) => {
    const { t } = useTranslation();
    const disabledPermissions = disabledByPermission({
      type: DisableByPermissionsType.CREATE,
    });
    const disabled = useAppSelector(
      state =>
        !state.loading.effects.app.loadProjectData.success ||
        !state.projectEdit.recordings.length ||
        state.loading.effects.projectEdit.createEnrichment.loading,
    );

    return (
      <Button
        disabled={disabledPermissions || disabled}
        variant={main ? "contained" : "text"}
        color={"primary"}
        onClick={() => {
          document.getElementById(ModalIds.ENRICHMENTS)?.click();
        }}
        startIcon={<Add />}
        size={main ? "small" : undefined}
        sx={{
          mb: main ? 1 : undefined,
          maxHeight: main ? 26 : undefined,
        }}
      >
        {t(
          RouterHelper.projectVisualizations.matchCurrentPath()
            ? "Create visualization"
            : "Create enrichment",
        )}
      </Button>
    );
  },
);

const Content: FC<{ type: EnrichmentVisualizationNames }> = ({ type }) => {
  const { t } = useTranslation();
  const [text, setText] = useState<{
    description: string;
    setup?: JSX.Element;
    output?: JSX.Element;
  } | null>(null);

  useEffect(() => {
    switch (type) {
      case "slam-mapper":
        setText({
          description:
            "Use the Reference Image Mapper to automatically map gaze onto features visible in the scene.",
          setup: (
            <>
              <Typography component="li" variant="body2">
                Select a scanning recording. The scanning recording is a special video
                where you use the eye tracking glasses to scan the area of the
                environment. Learn how to make a successful scanning recording{" "}
                <Link
                  href="https://docs.pupil-labs.com/neon/pupil-cloud/enrichments/reference-image-mapper/#scanning-best-practices"
                  target="_blank"
                  rel="noreferrer"
                  sx={{ textDecoration: "underline" }}
                >
                  here
                </Link>
                .
              </Typography>
              <Typography component="li" variant="body2">
                Upload a reference image. The reference image is a photo of the same
                environment captured in the scanning recording.
              </Typography>
              <Typography component="li" variant="body2">
                Run it! The Reference Image Mapper will use the scanning recording to
                build a 3D model of the environment, localize the reference image, and
                finally, map gaze from your recordings onto the reference image.
              </Typography>
            </>
          ),
          output: (
            <>
              <Typography component="li" variant="body2">
                CSV files of gaze and fixation locations on the reference image
                available in Downloads.
              </Typography>
              <Typography component="li" variant="body2">
                Areas of Interest (AOIs)
                <Box component="ul" sx={{ ml: 4, listStyle: "circle" }}>
                  <Typography component="li" variant="body2">
                    Use the AOI Editor in the enrichment to draw AOIs on top of the
                    reference image.
                  </Typography>
                  <Typography component="li" variant="body2">
                    CSV files of standard metrics on AOIs - dwell time, time to first
                    fixation, reach. Available in Downloads.
                  </Typography>
                </Box>
              </Typography>
              <Typography component="li" variant="body2">
                The following visualizations can be created:
                <Box component="ul" sx={{ ml: 4, listStyle: "circle" }}>
                  <Typography component="li" variant="body2">
                    Heatmap: Create a heatmap to visualize gaze from multiple recordings
                    on the reference image.
                  </Typography>
                  <Typography component="li" variant="body2">
                    AOI Heatmap: Create an AOI heatmap to visualize metrics - dwell
                    time, time to first fixation, and reach - on AOIs.
                  </Typography>
                </Box>
              </Typography>
            </>
          ),
        });
        break;
      case "marker-mapper":
        setText({
          description: "Use the Marker Mapper to automatically map gaze onto surfaces.",
          setup: (
            <>
              <Typography component="li" variant="body2">
                Markers: You need to equip the surface(s) you want to track with
                AprilTag markers. At least 3 markers should be detectable on a given
                surface whenever it is in view to ensure good results. Learn more about
                the requirements here.
              </Typography>
              <Typography component="li" variant="body2">
                Surface: You need to define a surface by picking which markers you want
                to use in the scene and adjusting the boundaries of the surface. Learn
                more here.
              </Typography>
              <Typography component="li" variant="body2">
                Run it! Marker Mapper will look through all selected recordings to
                detect markers, localize the surface, and automatically map gaze to the
                surface.
              </Typography>
            </>
          ),
          output: (
            <>
              <Typography component="li" variant="body2">
                CSV files of gaze and fixation locations on the surface and surface
                coordinates available in Downloads.
              </Typography>
              <Typography component="li" variant="body2">
                Areas of Interest (AOIs)
                <Box component="ul" sx={{ ml: 4, listStyle: "circle" }}>
                  <Typography component="li" variant="body2">
                    Use the AOI Editor in the enrichment to draw AOIs on top of the
                    reference image.
                  </Typography>
                  <Typography component="li" variant="body2">
                    CSV files of standard metrics on AOIs - dwell time, time to first
                    fixation, reach. Available in Downloads.
                  </Typography>
                </Box>
              </Typography>
              <Typography component="li" variant="body2">
                The following visualizations can be created:
                <Box component="ul" sx={{ ml: 4, listStyle: "circle" }}>
                  <Typography component="li" variant="body2">
                    Heatmap: Create a heatmap to visualize gaze from multiple recordings
                    on the surface.
                  </Typography>
                  <Typography component="li" variant="body2">
                    AOI Heatmap: Create an AOI heatmap to visualize metrics - dwell
                    time, time to first fixation, and reach - on AOIs.
                  </Typography>
                </Box>
              </Typography>
            </>
          ),
        });
        break;
      case "face-mapper":
        setText({
          description:
            "The Face Mapper allows you to automatically track faces in the scene video and map gaze onto them.",
          setup: (
            <>
              <Typography component="li" variant="body2">
                Run it! Face Mapper will look through all selected recordings to detect
                faces and automatically map gaze onto detected faces.
              </Typography>
            </>
          ),
          output: (
            <>
              <Typography component="li" variant="body2">
                CSV files of gaze and fixations mapped on the detected face available in
                Downloads.
              </Typography>
              <Typography component="li" variant="body2">
                CSV files with coordinates of detected face bounding boxes and facial
                landmarks (i.e., eyes, mouth, nose) available in Downloads.
              </Typography>
            </>
          ),
        });
        break;
      case "render":
        setText({
          description:
            "Create custom renderings of eye tracking data on scene video and export them to downloadable videos.",
          setup: (
            <>
              <Typography component="li" variant="body2">
                Recordings: You can select which recordings you want to include in the
                enrichment.
              </Typography>
              <Typography component="li" variant="body2">
                Events: Optionally specify events. This will result in clips or trimmed
                recordings.
              </Typography>
              <Typography component="li" variant="body2">
                Configuration: Select the color and size of the gaze visualization.
              </Typography>
              <Typography component="li" variant="body2">
                Run it! This visualization takes time because it re-renders videos with
                your settings.
              </Typography>
            </>
          ),
          output: (
            <>
              <Typography variant="body2">
                Videos rendered out with your selected configuration are available for
                download within the visualization.
              </Typography>
            </>
          ),
        });
        break;
      case "heatmap":
        setText({
          description:
            "Aggregate mapped gaze data from multiple recordings into a heatmap. The heatmap can be configured and downloaded.",
          setup: (
            <>
              <Typography component="li" variant="body2">
                Enrichment: A successful Reference Image Mapper or Marker Mapper is
                required to create a Heatmap visualization.
              </Typography>
              <Typography component="li" variant="body2">
                Configuration: You can select which recordings to include in the
                visualization and configure its appearance.
              </Typography>
            </>
          ),
          output: (
            <>
              <Typography variant="body2">
                Heatmap image available for download within the visualization.
              </Typography>
            </>
          ),
        });
        break;
      case "aoi":
        setText({
          description:
            "Use AOI Heatmap to create visualizations of mapped gaze on AOIs with metrics dwell time, time to first fixation, and average fixation duration.",
          setup: (
            <>
              <Typography component="li" variant="body2">
                Enrichment: A successful Reference Image Mapper or Marker Mapper
                enrichment is needed to create an AOI Heatmap.
              </Typography>
              <Typography component="li" variant="body2">
                AOIs: You will need to draw AOIs on the reference image or surface. You
                can do this in the associated enrichment. AOIs can be drawn before or
                after creating the visualization.
              </Typography>
              <Typography component="li" variant="body2">
                Configuration: You can select which recordings to include in the
                visualization, which AOIs to include, and configure its appearance.
              </Typography>
            </>
          ),
          output: (
            <>
              <Typography variant="body2">
                AOI heatmap image available for download within the visualization.
              </Typography>
            </>
          ),
        });
        break;
      case "static-image-mapper": {
        setText({
          description:
            "The Manual Mapper allows you to manually map fixations onto a reference image.",
          setup: (
            <>
              <Typography component="li" variant="body2">
                Upload a reference image. This is where fixations will be mapped.
              </Typography>
              <Typography component="li" variant="body2">
                Start mapping fixations observed in the recording onto the reference
                image. Click on the reference image to map the fixation location. If the
                fixation is not on the reference image, use the keyboard shortcut{" "}
                <Typography fontWeight={500} component="span">
                  s
                </Typography>
                .
              </Typography>
            </>
          ),
          output: (
            <>
              <Typography component="li" variant="body2">
                CSV file of fixation locations on the reference image available in
                Downloads.
              </Typography>
              <Typography component="li" variant="body2">
                Areas of Interest (AOIs)
                <Box component="ul" sx={{ ml: 4, listStyle: "circle" }}>
                  <Typography component="li" variant="body2">
                    Use the AOI Editor in the enrichment to draw AOIs on top of the
                    reference image.
                  </Typography>
                  <Typography component="li" variant="body2">
                    CSV files of standard metrics on AOIs - dwell time, time to first
                    fixation, reach. Available in Downloads.
                  </Typography>
                </Box>
              </Typography>
              <Typography component="li" variant="body2">
                The following visualizations can be created:
                <Box component="ul" sx={{ ml: 4, listStyle: "circle" }}>
                  <Typography component="li" variant="body2">
                    Heatmap: Create a heatmap to visualize gaze from multiple recordings
                    on the reference image.
                  </Typography>
                  <Typography component="li" variant="body2">
                    AOI Heatmap: Create an AOI heatmap to visualize metrics - dwell
                    time, time to first fixation, and reach - on AOIs.
                  </Typography>
                </Box>
              </Typography>
            </>
          ),
        });
      }
    }
  }, [type]);

  return (
    <Box
      sx={{
        maxHeight: type === "heatmap" || type === "aoi" ? "19vh" : "28vh",
        overflow: "auto",
        mb: 2,
      }}
    >
      <Typography variant="body2" mt={2} sx={{ whiteSpace: "pre-line" }}>
        {text?.description}
      </Typography>
      {text?.setup && (
        <>
          <Typography variant="body2" fontWeight={600} mt={2}>
            {t("Setup")}
          </Typography>
          <Box sx={{ mt: 0.5, ml: 2 }}>{text.setup}</Box>
        </>
      )}
      {text?.output && (
        <>
          <Typography variant="body2" fontWeight={600} mt={1}>
            {t("Output")}
          </Typography>
          <Box sx={{ mt: 0.5, ml: 2 }}>{text.output}</Box>
        </>
      )}
    </Box>
  );
};

const List: FC<{
  onClick: (model: SelectEnrichmentProps) => void;
  list: SelectEnrichmentProps[];
  model?: SelectEnrichmentProps;
  disabled?: boolean;
  title: string;
}> = ({ model, onClick, list, title, disabled }) => {
  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <MenuList sx={{ width: "100%" }} disableListWrap>
        {model &&
          list.map(one => {
            return (
              <MenuItem
                disabled={disabled}
                key={one.value}
                sx={{
                  bgcolor: model.value === one.value ? "action.selected" : "unset",
                }}
                onClick={() => {
                  onClick(one);
                }}
              >
                {one.label}
              </MenuItem>
            );
          })}
      </MenuList>
    </>
  );
};

const virtualListItemHeight = 50;
const EnrichmentSelect = ({
  enrichmentId,
  setEnrichmentId,
  show,
}: {
  enrichmentId: string | null;
  setEnrichmentId: Dispatch<SetStateAction<string | null>>;
  show: boolean;
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const enrichments = useAppSelector(state => state.enrichments.data);
  const enrichmentsById = useAppSelector(state => state.enrichments.dataById);
  const [found, setFound] = useState<ProjectEnrichment[]>([]);
  const enrichmentName =
    (enrichmentId && enrichmentsById.get(enrichmentId)?.name) || " ";
  const filtered = useMemo(() => {
    const arr: ProjectEnrichment[] = [];

    for (const one of enrichments) {
      if (
        one.kind === EnrichmentTypesEnum.SLAM_MAPPER ||
        one.kind === EnrichmentTypesEnum.MARKER_MAPPER ||
        one.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER
      ) {
        arr.push(one);
      }
    }

    return arr;
  }, [enrichments]);

  useEffect(() => {
    if (!open) {
      setFound(filtered);
    }
  }, [open, filtered]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const next = filtered.filter(one =>
      one.name.match(new RegExp(sanitizeForRegex(e.currentTarget.value), "i")),
    );
    setFound(next);
  };

  const handleSelect = (id: string) => () => {
    setOpen(false);
    setEnrichmentId(id);
  };

  if (!show) return null;

  return (
    <Paper
      elevation={6}
      sx={{
        boxShadow: "none",
        p: 2,
        mt: "auto",
        ml: -0.5,
        mb: 1,
        width: "100%",
        backgroundColor: !enrichments.length ? "#6D7BE029" : undefined,
      }}
    >
      {!enrichments.length ? (
        <Typography variant="body2">
          A Reference Image Mapper or Marker Mapper enrichment is required to create
          this visualization.
        </Typography>
      ) : found.length ? (
        <Box sx={{ display: "flex", flexDirection: "column", position: "relative" }}>
          <Typography variant="subtitle2">{t("Enrichment")}</Typography>
          <Box ref={containerRef} sx={{ height: open ? 33 : 0 }} />
          {open ? (
            <>
              <Portal container={() => document.getElementById("root")!}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <Paper
                    sx={{
                      position: "absolute",
                      p: 2,
                      // overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      zIndex: 10000,
                      top: containerRef.current?.getBoundingClientRect().top || 0,
                      left: containerRef.current?.getBoundingClientRect().left || 0,
                      width: containerRef.current?.clientWidth || 0,
                      maxHeight: "25vh",
                    }}
                    elevation={7}
                  >
                    <SearchBox
                      name="project_create_search_enr"
                      sx={{ mb: 1 }}
                      onChange={handleSearch}
                      onClear={() => setFound(filtered)}
                    />
                    <Box height={found.length * virtualListItemHeight}>
                      <VirtualizedList
                        itemData={found}
                        itemKey={(index, data) => data[index].id}
                        itemSize={virtualListItemHeight}
                        renderRow={({ index, style, data }) => {
                          const model = data[index];
                          const checked = enrichmentId === model.id;

                          return (
                            <MenuItem
                              key={index}
                              style={style}
                              sx={{
                                backgroundColor: checked
                                  ? "action.selected"
                                  : "inherit",
                                "&:hover": {
                                  backgroundColor: checked
                                    ? "action.selected"
                                    : undefined,
                                },
                              }}
                              onClick={handleSelect(model.id)}
                            >
                              <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography
                                  noWrap
                                  maxWidth={containerRef.current?.clientWidth}
                                  sx={{
                                    textTransform: "inherit",
                                    color: checked ? "text.primary" : "text.secondary",
                                  }}
                                >
                                  {model.name}
                                </Typography>
                                <Typography
                                  noWrap
                                  maxWidth={containerRef.current?.clientWidth}
                                  sx={{
                                    textTransform: "inherit",
                                    color: "text.secondary",
                                  }}
                                  variant="body2"
                                >
                                  {formatReadableEnrichment(model.kind)}
                                </Typography>
                              </Box>
                            </MenuItem>
                          );
                        }}
                      />
                    </Box>
                  </Paper>
                </ClickAwayListener>
              </Portal>
            </>
          ) : (
            <Select
              fullWidth
              onOpen={() => setOpen(true)}
              value={enrichmentName}
              variant="standard"
            >
              <MenuItem value={enrichmentName}>{enrichmentName}</MenuItem>
            </Select>
          )}
        </Box>
      ) : null}
    </Paper>
  );
};
