import {
  GazeCircle,
  GazeDatapoint,
  GazeOnAoi,
  OffsetCorrection,
  Recording,
} from "@api";
import { RouterHelper } from "@pages";
import { store } from "@storeRematch";
import { drag } from "d3";
import { overlayCalculations } from "./OverlayCalculations";
import { overlayControl } from "./OverlayControl";
import { DataType, ShapesControl, ShapesControlChildProps } from "./ShapesControl";
import { getPlConfig } from "./helpers";

const defaultConfigInvisible = {
  stroke_width: 20,
  radius: 80,
  color: { alpha: 204, blue: 0, green: 0, red: 255 },
};

const defaultConfigNeon = {
  stroke_width: 13,
  radius: 45,
  color: { alpha: 204, blue: 0, green: 0, red: 255 },
};

const BLINK_GAZE_COLOR = "rgba(160, 159, 166, 0.8)";
const ANDROID_GAZE_COLOR = "rgba(26, 177, 224, 0.8)";

class GazeAggregate<T extends DataType, D extends d3.BaseType> extends ShapesControl<
  T,
  D
> {
  defaultConfig = defaultConfigInvisible;

  setDefaultConfig(recording: Recording) {
    this.defaultConfig =
      recording.family === "neon" ? defaultConfigNeon : defaultConfigInvisible;
  }
}

export class GazeOverlay extends GazeAggregate<GazeDatapoint, SVGCircleElement> {
  configs: GazeCircle[] = [];
  _hideBlinks = false;
  alwaysBlinkColor = false;
  _offset = { x: 0, y: 0 };
  _androidOffset = { x: 0, y: 0 };

  constructor(props?: ShapesControlChildProps) {
    super({ canvasId: "GazeOverlay", paintType: true, ...props });
  }

  get hide() {
    return super.hide;
  }

  set hide(next: boolean) {
    store.dispatch.projectEdit.setHideGaze(next);
    super.hide = next;
  }

  get hideBlinks() {
    return this._hideBlinks;
  }

  set offset(next: OffsetCorrection | null) {
    if (!next || !RouterHelper.workspaceRecordingsEditGazeOffset.matchCurrentPath()) {
      this._offset.x = 0;
      this._offset.y = 0;

      return;
    }

    this._offset.x = next.x;
    this._offset.y = next.y;
  }

  set androidOffset(next: OffsetCorrection | null) {
    if (!next || !RouterHelper.workspaceRecordingsEditGazeOffset.matchCurrentPath()) {
      this._androidOffset.x = 0;
      this._androidOffset.y = 0;

      return;
    }

    this._androidOffset.x = next.x;
    this._androidOffset.y = next.y;
  }

  set hideBlinks(next: boolean) {
    store.dispatch.projectEdit.setHideBlinks(next);
    this._hideBlinks = next;
    overlayControl.shouldRender(this);
  }

  checkConditions(model?: GazeDatapoint) {
    return !model || !model.worn ? false : true;
  }

  reset(full: boolean): void {
    this.configs = [];
    super.reset(full);
  }

  addConfig(c?: GazeCircle) {
    this.configs = c ? [c] : [];

    if (
      overlayControl.instance &&
      overlayControl.instance.paused() &&
      !overlayControl.instance.isInPictureInPicture()
    ) {
      overlayControl.shouldRender(this);
    }
  }

  checkConfig(c: GazeCircle) {
    const radiusChecked = c.radius !== undefined ? c.radius : this.defaultConfig.radius;
    const radius = overlayCalculations.scaleCoordinates({
      x: radiusChecked,
      y: radiusChecked,
    });

    const strokeWidthChecked =
      c.stroke_width !== undefined ? c.stroke_width : this.defaultConfig.stroke_width;
    const strokeWidth = overlayCalculations.scaleCoordinates({
      x: strokeWidthChecked,
      y: strokeWidthChecked,
    });

    return {
      radius: !radius ? this.defaultConfig.radius : radius.x,
      color: {
        alpha:
          c.color?.alpha !== undefined ? c.color.alpha : this.defaultConfig.color.alpha,
        blue:
          c.color?.blue !== undefined ? c.color.blue : this.defaultConfig.color.blue,
        green:
          c.color?.green !== undefined ? c.color.green : this.defaultConfig.color.green,
        red: c.color?.red !== undefined ? c.color.red : this.defaultConfig.color.red,
      },
      stroke_width: !strokeWidth ? this.defaultConfig.stroke_width : strokeWidth.x,
    };
  }

  circle(
    ctx: CanvasRenderingContext2D,
    point: number[],
    c: GazeCircle,
    event: GazeDatapoint,
    strokeStyle = BLINK_GAZE_COLOR,
  ) {
    const check = this.checkConfig(c);
    const plConfig = getPlConfig();

    ctx.beginPath();
    ctx.arc(point[0], point[1], check.radius, 0, 2 * Math.PI, false);

    if (this.alwaysBlinkColor || (!this.hideBlinks && !!event.blink_id)) {
      ctx.strokeStyle = plConfig?.gaze?.opacity
        ? strokeStyle.replace("0.8", plConfig.gaze.opacity.toString())
        : strokeStyle;
    } else {
      ctx.strokeStyle = `rgba(${check.color.red}, ${check.color.green}, ${
        check.color.blue
      }, ${plConfig?.gaze?.opacity ? plConfig.gaze.opacity : check.color.alpha / 255})`;
    }

    ctx.lineWidth = plConfig?.gaze?.lineWidth
      ? plConfig.gaze.lineWidth
      : check.stroke_width;
    ctx.stroke();
    ctx.closePath();
  }

  paintAll(event?: GazeDatapoint) {
    if (!event) return;

    const scale = overlayCalculations.scaleCoordinates(event);
    const offset = overlayCalculations.scaleCoordinates(this._offset);

    if (!scale || !scale.x || !scale.y || !offset) return;

    const ctx = this.getCtx();

    if (!ctx) return;

    const x = scale.x - offset.x;
    const y = scale.y - offset.y;

    if (this.configs.length) {
      this.configs.forEach(c => {
        this.circle(ctx, [x, y], c, event);
      });
    } else {
      this.circle(ctx, [x, y], this.defaultConfig, event);
    }

    if (
      this.alwaysBlinkColor &&
      this._androidOffset.x !== 0 &&
      this._androidOffset.y !== 0
    ) {
      const androidOffset = overlayCalculations.scaleCoordinates(this._androidOffset);

      if (androidOffset) {
        this.circle(
          ctx,
          [x + androidOffset.x, y + androidOffset.y],
          this.defaultConfig,
          event,
          ANDROID_GAZE_COLOR,
        );
      }
    }
  }
}

export class GazeAOIOverlay extends GazeAggregate<GazeOnAoi, SVGCircleElement> {
  configs: GazeCircle[] = [];

  constructor(props?: ShapesControlChildProps) {
    super({
      canvasId: "GazeAOIOverlay",
      paintType: true,
      canvasOverReference: true,
      ...props,
    });
  }

  circle(ctx: CanvasRenderingContext2D, point: number[], isOffScreen = false) {
    const check = this.defaultConfig;

    const radius = overlayCalculations.scaleCoordinates({
      x: check.radius,
      y: check.radius,
    });

    const strokeWidth = overlayCalculations.scaleCoordinates({
      x: check.stroke_width,
      y: check.stroke_width,
    });

    ctx.beginPath();
    ctx.arc(point[0], point[1], radius ? radius.x : check.radius / 2.5, 0, 2 * Math.PI);
    ctx.strokeStyle = `rgba(${check.color.red}, ${check.color.green}, ${
      check.color.blue
    }, ${isOffScreen ? 0.2 : check.color.alpha / 255})`;
    ctx.lineWidth = strokeWidth?.x || check.stroke_width / 2.5;

    ctx.stroke();
    ctx.closePath();
  }

  paintAll(event?: GazeOnAoi) {
    if (
      !event ||
      typeof event.gaze_in_aoi_x !== "number" ||
      typeof event.gaze_in_aoi_y !== "number"
    )
      return;

    const ctx = this.getCtx();

    if (!ctx) return;

    const { width, height } = ctx.canvas.getBoundingClientRect();

    const x = width * event.gaze_in_aoi_x;
    const y = height * event.gaze_in_aoi_y;
    const isOffScreen =
      event.gaze_in_aoi_y < 0 ||
      event.gaze_in_aoi_y > 1 ||
      event.gaze_in_aoi_x < 0 ||
      event.gaze_in_aoi_x > 1;

    this.circle(ctx, [x, y], isOffScreen);
  }
}

export class GazeOffsetOverlay extends GazeAggregate<GazeDatapoint, SVGCircleElement> {
  private nameStroke = "GazeOffsetStroke";
  private nameFill = "GazeOffsetFill";
  offset = { x: 0, y: 0 };
  initOffset = { x: 0, y: 0 };
  moved = false;

  constructor(props?: ShapesControlChildProps) {
    super({ shapeType: "circle", paintType: true, ...props });
  }

  checkConditions(model?: GazeDatapoint) {
    return model && !model.worn ? false : true && model?.blink_id ? false : true;
  }

  validateCoordinate(coordinate: number, max: number) {
    if (coordinate < 0) return 0;
    if (coordinate > max) return max;
    return coordinate;
  }

  paintAll(event?: GazeDatapoint) {
    if (!event) return;

    const { shape: stroke } = this.firstOrCreateShape(this.nameStroke);
    const { shape: fill } = this.firstOrCreateShape(this.nameFill);
    this.showShape(stroke);
    this.showShape(fill);

    const scale = overlayCalculations.scaleCoordinates(event);
    const radius = overlayCalculations.scaleCoordinates({
      x: this.defaultConfig.radius,
      y: this.defaultConfig.radius,
    });
    const strokeWidth = overlayCalculations.scaleCoordinates({
      x: this.defaultConfig.stroke_width,
      y: this.defaultConfig.stroke_width,
    });
    const initOffset = overlayCalculations.scaleCoordinates(this.initOffset);
    const offset = overlayCalculations.scaleCoordinates(this.offset);

    if (!scale || !radius || !strokeWidth || !initOffset || !offset) return;

    const maxWidth = +overlayControl.d3.style("width").slice(0, -2);
    const maxHeight = +overlayControl.d3.style("height").slice(0, -2);

    const x = this.moved ? scale.x - initOffset.x + offset.x : scale.x;
    const y = this.moved ? scale.y - initOffset.y + offset.y : scale.y;

    fill
      .attr("cx", x)
      .attr("cy", y)
      .attr("r", radius.x - strokeWidth.x / 2)
      .style("fill", "transparent")
      .style("pointer-events", "none");

    stroke
      .attr("cx", x)
      .attr("cy", y)
      .attr("r", radius.x)
      .attr(
        "stroke",
        `rgba(${this.defaultConfig.color.red},${this.defaultConfig.color.green},${
          this.defaultConfig.color.blue
        },${this.defaultConfig.color.alpha / 255})`,
      )
      .attr("stroke-width", strokeWidth.x)
      .attr("fill", "transparent");

    if (!overlayControl.instance?.paused()) return;

    stroke
      .style("cursor", "grab")
      .on("mouseover", function () {
        fill.style("fill", "#F4433633");
      })
      .on("mouseout", function () {
        fill.style("fill", "transparent");
      })
      .call(
        drag<SVGCircleElement, unknown>()
          .on("start", () => {
            stroke.style("cursor", "grabbing");
          })
          .on("drag", (e: DragEvent) => {
            this.moved = true;
            const x = this.validateCoordinate(e.x, maxWidth);
            const y = this.validateCoordinate(e.y, maxHeight);

            stroke.attr("cx", x).attr("cy", y);
            fill.attr("cx", x).attr("cy", y);
          })
          .on("end", (e: DragEvent) => {
            const validX = this.validateCoordinate(e.x, maxWidth);
            const validY = this.validateCoordinate(e.y, maxHeight);

            const [x, y] = overlayCalculations.revertScale([
              validX - (scale.x - initOffset.x),
              validY - (scale.y - initOffset.y),
            ]);

            this.offset = { x, y };

            store.dispatch.recordingGazeOffset.setNextOffset(this.offset);

            stroke.style("cursor", "grab");
          }),
      );
  }
}
