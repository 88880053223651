import { INACTIVE_FIELD_NAME } from "@constants";
import {
  getDefaultGridFilterModel,
  GridFilterItem,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { useAppSelector } from "@storeRematch";
import { localStorageAdapter, LocalStorageAdapterNames } from "@utils";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

enum ModelTypeNames {
  FILTER_MODEL = "filterModel",
  SORT_MODEL = "sortModel",
}

export function createInactive(value?: boolean): GridFilterItem {
  return {
    id: INACTIVE_FIELD_NAME,
    field: INACTIVE_FIELD_NAME,
    operator: "is",
    value: value ? "true" : "false",
  };
}

const initFilterModel: GridFilterModel = {
  ...getDefaultGridFilterModel(),
  items: [createInactive()],
};

export const useTableSearchParams = ({
  defaultSortModel,
  defaultFilterModel,
  filterLocalStorageKey,
  sortLocalStorageKey,
}: {
  defaultSortModel?: GridSortModel;
  defaultFilterModel?: GridFilterModel;
  filterLocalStorageKey?: LocalStorageAdapterNames;
  sortLocalStorageKey?: LocalStorageAdapterNames;
}) => {
  const [filterModel, setFilterModel] = useState(defaultFilterModel || initFilterModel);
  const [sortModel, setSortModel] = useState(defaultSortModel || []);
  const [searchParams, setSearchParams] = useSearchParams();
  const resetOnSwitch = useAppSelector(state => state.app.resetOnSwitch);

  useEffect(() => {
    if (resetOnSwitch === null) return;
    onClear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetOnSwitch]);

  useEffect(() => {
    const nextFilterModel = searchParams.get(ModelTypeNames.FILTER_MODEL);
    const nextSortModel = searchParams.get(ModelTypeNames.SORT_MODEL);

    try {
      if (nextFilterModel && nextFilterModel !== "undefined")
        setFilterModel(JSON.parse(nextFilterModel));
      else setFilterModel(defaultFilterModel || initFilterModel);
      if (nextSortModel && nextSortModel !== "undefined")
        setSortModel(JSON.parse(nextSortModel));
      else setSortModel(defaultSortModel || []);
    } catch (error) {
      console.error("useTableSearchParams parsing", error);
    }

    // only on init check filter state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterModelChange = (params: GridFilterModel) => {
    setFilterModel(params);
    searchParams.set(ModelTypeNames.FILTER_MODEL, JSON.stringify(params));
    setSearchParams(searchParams);

    if (filterLocalStorageKey) localStorageAdapter.set(filterLocalStorageKey, params);
  };

  const onSortModelChange = (params: GridSortModel) => {
    setSortModel(params);
    searchParams.set(ModelTypeNames.SORT_MODEL, JSON.stringify(params));
    setSearchParams(searchParams);

    if (sortLocalStorageKey) localStorageAdapter.set(sortLocalStorageKey, params);
  };

  const onClear = () => {
    searchParams.set(
      ModelTypeNames.FILTER_MODEL,
      JSON.stringify(defaultFilterModel || initFilterModel),
    );
    searchParams.set(ModelTypeNames.SORT_MODEL, JSON.stringify(defaultSortModel || []));
    setSearchParams(searchParams);
    setFilterModel(defaultFilterModel || initFilterModel);
    setSortModel(defaultSortModel || []);
  };

  return { onFilterModelChange, filterModel, onSortModelChange, sortModel, onClear };
};
