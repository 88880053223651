import { Close } from "@mui/icons-material";
import {
  Chip,
  ChipProps,
  IconButton,
  Input,
  InputProps,
  TextFieldProps,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { FC, FocusEvent, KeyboardEvent, forwardRef } from "react";

export interface TextFieldChipProps extends InputProps {
  onClear?: () => void;
  onDelete?: (index: number) => void;
  onAdd?: (name: string) => void;
  options?: Array<any>;
  disabled?: boolean;
  placeholder?: string;
  getItemName?: (one: any) => string;
  getItemColor?: (name: string) => ChipProps["color"];
  isError?: (name: string) => boolean;
  helperText?: string;
  variant?: TextFieldProps["variant"];
}

export const TextFieldChip: FC<TextFieldChipProps> = forwardRef(
  (
    {
      onClear,
      onDelete,
      onAdd,
      getItemName = (one: string) => one,
      getItemColor = (_name: string) => "default",
      isError = (_name: string) => false,
      options,
      helperText: _,
      variant = "outlined",
      ...props
    },
    ref,
  ) => {
    const handleUpdate = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if ((e.key === "Tab" || e.key === "Enter") && e.currentTarget.value) {
        e.preventDefault();
        onAdd?.(e.currentTarget.value);
        e.currentTarget.value = "";
      }
    };

    const handleBlur = (
      e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    ) => {
      if (e.currentTarget.value) {
        onAdd?.(e.currentTarget.value);
        e.currentTarget.value = "";
      }
    };

    return (
      <CustomInputBase disabled={props.disabled} variant={variant}>
        <Box display="flex" width="95%" flexWrap="wrap">
          {options?.map((one, index) => {
            const name = getItemName?.(one);
            const error = isError(name);

            return (
              <Chip
                disabled={props.disabled}
                sx={{
                  m: 0.325,
                  my: 0.325,
                  ...(error
                    ? {
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: "error.main",
                      }
                    : {}),
                }}
                key={`${name}-${index}`}
                label={name}
                onDelete={() => onDelete?.(index)}
                color={getItemColor(one) as any}
              />
            );
          })}
          <Input
            ref={ref}
            {...props}
            disabled={props.disabled}
            sx={{ flexGrow: 1, p: 0.5, paddingLeft: variant === "filled" ? 0 : 0.5 }}
            size="small"
            inputProps={{ style: { padding: 0 } }}
            onKeyDown={handleUpdate}
            onBlur={handleBlur}
            disableUnderline
          />
        </Box>
        <IconButton
          disabled={props.disabled}
          sx={{ m: "auto" }}
          edge="end"
          size="small"
          onClick={onClear}
        >
          <Close fontSize="small" />
        </IconButton>
      </CustomInputBase>
    );
  },
);

const CustomInputBase = styled("div")<{
  disabled?: boolean;
  variant: TextFieldProps["variant"];
}>(({ theme, disabled, variant }) => {
  return {
    display: "flex",
    justifyItems: "center",
    width: "100%",
    [variant === "outlined"
      ? "border"
      : "borderBottom"]: `1px solid ${theme.palette.customColors.light1}`,
    borderRadius: variant === "filled" ? 0 : theme.spacing(0.5),
    padding: theme.spacing(0.2),
    paddingLeft: variant === "filled" ? 0 : theme.spacing(0.2),

    "&:hover": {
      borderColor: disabled
        ? theme.palette.customColors.light1
        : theme.palette.customColors.light5,
    },
    "&:focus-within": {
      borderColor: disabled
        ? theme.palette.customColors.light1
        : theme.palette.customColors.plue1,
    },
  };
});
