import {
  Label,
  Markerless,
  Project,
  ProjectEnrichment,
  ProjectEnrichmentAoi,
  ProjectEnrichmentSlice,
  ProjectRecordingEvent,
  Recording,
  StaticImageMapper,
  StaticImageMapperFixation,
  Surface,
  Template,
  Visualizations,
  Wearer,
  Workspace,
  WorkspaceMember,
} from "@api";
import { aprilTagOverlay, overlayControl } from "@components/videoPlayer/controller";
import { store } from "@storeRematch";
import { isArrayEqual } from "./isArrayEqual";
import { remapStaticImageMapper } from "./remapModels";

enum MessageType {
  UPDATED = "UPDATED",
  DELETED = "DELETED",
  INSERTED = "INSERTED",
  CREATED = "CREATED",
}

enum ModelType {
  Wearer = "Wearer",
  Recording = "Recording",
  Label = "Label",
  Template = "Template",
  Project = "Project",
  ProjectRecordingEvent = "ProjectRecordingEvent",
  ProjectEnrichment = "ProjectEnrichment",
  Surface = "Surface",
  SurfaceDefinition = "SurfaceDefinition",
  Workspace = "Workspace",
  Invitation = "Invitation",
  WorkspaceMember = "WorkspaceMember",
  EnrichmentSlice = "EnrichmentSlice",
  MarkerlessDefinition = "MarkerlessDefinition",
  WorldRenderDefinition = "WorldRenderDefinition",
  RecordingEvent = "RecordingEvent",
  Aoi = "Aoi",
  VisualizationConfig = "VisualizationConfig",
  StaticImageMapper = "StaticImageMapper",
  ManuallyMappedFixations = "ManuallyMappedFixations",
}

type MessageGenerator<T, M extends ModelType> = {
  data: T;
  event: MessageType;
  model: M;
  timestamp: number;
};

type Message =
  | MessageGenerator<Wearer, ModelType.Wearer>
  | MessageGenerator<Recording, ModelType.Recording>
  | MessageGenerator<Label, ModelType.Label>
  | MessageGenerator<Template, ModelType.Template>
  | MessageGenerator<Project, ModelType.Project>
  | MessageGenerator<ProjectRecordingEvent, ModelType.ProjectRecordingEvent>
  | MessageGenerator<ProjectRecordingEvent, ModelType.RecordingEvent>
  | MessageGenerator<ProjectEnrichment, ModelType.ProjectEnrichment>
  | MessageGenerator<any, ModelType.Surface>
  | MessageGenerator<Surface, ModelType.SurfaceDefinition>
  | MessageGenerator<Workspace, ModelType.Workspace>
  | MessageGenerator<any, ModelType.Invitation>
  | MessageGenerator<WorkspaceMember, ModelType.WorkspaceMember>
  | MessageGenerator<ProjectEnrichmentSlice, ModelType.EnrichmentSlice>
  | MessageGenerator<Markerless, ModelType.MarkerlessDefinition>
  | MessageGenerator<ProjectEnrichmentAoi, ModelType.Aoi>
  | MessageGenerator<Visualizations, ModelType.VisualizationConfig>
  | MessageGenerator<StaticImageMapper, ModelType.StaticImageMapper>
  | MessageGenerator<
      StaticImageMapperFixation & {
        enrichment_id: string;
        recording_id: string;
        workspace_id: string;
        updated_at: string | null;
      },
      ModelType.ManuallyMappedFixations
    >;

type ExtractedDispatch =
  | typeof store.dispatch.wearers
  | typeof store.dispatch.recordings
  | typeof store.dispatch.labels
  | typeof store.dispatch.templates
  | typeof store.dispatch.projects
  | typeof store.dispatch.members
  | typeof store.dispatch.recordingEvents
  | typeof store.dispatch.enrichments
  | typeof store.dispatch.invites
  | typeof store.dispatch.aoiAreas
  | typeof store.dispatch.visualizations;

function extract(d: ExtractedDispatch) {
  return {
    upsertOne: d.upsertOne,
    updatePartial: d.updatePartial,
    deleteMany: d.deleteMany,
    prepare: d.prepare,
  };
}

class WsClient {
  private ws: WebSocket | null = null;
  private adapter: Partial<Record<ModelType, ReturnType<typeof extract>>> = {};

  connect(workspaceId: string) {
    if (this.ws) this.disconnect();

    const apiUrl = store.getState().app.apiUrl;
    const apiKey = store.getState().app.apiKey;

    const wsUrl = apiUrl.replace("http", "ws").replace("/v2", "");
    const apiKeyChecked = apiKey ? `?api_key=${apiKey}` : "";

    this.ws = new WebSocket(`${wsUrl}/websocket/${workspaceId}${apiKeyChecked}`);

    this.ws.onopen = this.open;
    this.ws.onmessage = this.message;
    this.ws.onerror = this.error;
    this.ws.onclose = this.close;
  }

  disconnect() {
    if (!this.ws) return;

    this.ws.onopen = null;
    this.ws.onmessage = null;
    this.ws.onerror = null;
    this.ws.close();
    this.ws = null;
  }

  close() {
    if (this.ws?.readyState !== WebSocket.CLOSED) return;

    const workspaceId = store.getState().app.currentWorkspaceMembership?.workspace.id;

    if (!workspaceId) return;

    this.connect(workspaceId);
  }

  private open() {
    this.adapter = {
      [ModelType.Wearer]: extract(store.dispatch.wearers),
      [ModelType.Recording]: extract(store.dispatch.recordings),
      [ModelType.Label]: extract(store.dispatch.labels),
      [ModelType.Template]: extract(store.dispatch.templates),
      [ModelType.Project]: extract(store.dispatch.projects),
      [ModelType.ProjectRecordingEvent]: extract(store.dispatch.recordingEvents),
      [ModelType.RecordingEvent]: extract(store.dispatch.recordingEvents),
      [ModelType.ProjectEnrichment]: extract(store.dispatch.enrichments),
      [ModelType.StaticImageMapper]: extract(store.dispatch.enrichments),
      [ModelType.Invitation]: extract(store.dispatch.invites),
      [ModelType.Aoi]: extract(store.dispatch.aoiAreas),
      [ModelType.VisualizationConfig]: extract(store.dispatch.visualizations),
    };
  }

  private message(e: MessageEvent<string>) {
    try {
      const msg: Message = JSON.parse(e.data);

      if (msg.model === ModelType.Workspace) {
        switch (msg.event) {
          case MessageType.CREATED:
          case MessageType.UPDATED:
            store.dispatch.workspaceMemberships.wsWorkspace(msg.data);
            break;
          default:
            return console.error("MessageType Workspace not found", e);
        }
        return;
      }

      if (
        msg.data.workspace_id &&
        store.getState().app.currentWorkspaceMembership?.workspace.id &&
        msg.data.workspace_id !==
          store.getState().app.currentWorkspaceMembership?.workspace.id
      ) {
        return;
      }

      if (msg.model === ModelType.ManuallyMappedFixations) {
        if (
          msg.data.recording_id === store.getState().video.recording?.id &&
          msg.data.enrichment_id === store.getState().projectEdit.currentEnrichment?.id
        ) {
          const fixations = store.getState().staticImageMappers.fixations;

          if (!fixations.length) return;

          const index = fixations.findIndex(
            one => one.fixation_id === msg.data.fixation_id,
          );

          if (index < 0) return;

          const old = fixations[index];

          if (
            old.gaze_on_aoi !== msg.data.gaze_on_aoi ||
            old.x !== msg.data.x ||
            old.y !== msg.data.y
          ) {
            store.dispatch.staticImageMappers.updateFixation({
              x: msg.data.x,
              y: msg.data.y,
              gazeOnAoi: msg.data.gaze_on_aoi,
              index,
              ws: true,
            });
          }

          store.dispatch.localization.prepareMarkerMapper(null);
        }

        return;
      }

      if (msg.model === ModelType.MarkerlessDefinition) {
        const currentMarkerLess = store.getState().projectEdit.currentMarkerLess;

        if (!currentMarkerLess) return;
        if (currentMarkerLess.id !== msg.data.id) return;

        switch (msg.event) {
          case MessageType.CREATED:
            return;
          case MessageType.UPDATED:
            store.dispatch.projectEdit.setCurrentMarkerLess(msg.data as any);
            return;
          case MessageType.DELETED:
            store.dispatch.projectEdit.setCurrentMarkerLess(undefined);
            return;
          default:
            return console.error("MarkerlessDefinition MessageType not found ", e);
        }
      }

      if (
        msg.model === ModelType.SurfaceDefinition &&
        msg.data.id === store.getState().projectEdit.currentSurface?.id
      ) {
        aprilTagOverlay.availableTags = (msg.data.markers_used || []).reduce<
          Record<string, boolean>
        >((acc, one) => {
          acc[one] = true;

          return acc;
        }, {});
        overlayControl.shouldRender(aprilTagOverlay);
        return;
      }

      if (
        msg.model === ModelType.EnrichmentSlice &&
        msg.event === MessageType.UPDATED
      ) {
        const currentEnrichment = store.getState().projectEdit.currentEnrichment;

        if (currentEnrichment && msg.data.enrichment_id === currentEnrichment.id) {
          store.dispatch.projectEdit.addSlice(msg.data);
          store.dispatch.localization.get(null);
        }

        return;
      }

      const adapter = this.adapter[msg.model];

      if (!adapter) return console.error("adapter not found", e);

      switch (msg.event) {
        case MessageType.UPDATED:
        case MessageType.INSERTED:
        case MessageType.CREATED:
          if (
            (msg.model === ModelType.ProjectEnrichment ||
              msg.model === ModelType.ProjectRecordingEvent ||
              msg.model === ModelType.VisualizationConfig) &&
            msg.data.project_id !== store.getState().app.currentProject?.id
          ) {
            break;
          }

          if (
            msg.event === MessageType.CREATED &&
            msg.model === ModelType.WorkspaceMember &&
            msg.data.user?.email
          ) {
            store.dispatch.invites.deleteByEmail(msg.data.user.email);
            // do not beak, remove invitations
          }

          if (
            msg.model === ModelType.RecordingEvent &&
            msg.event === MessageType.CREATED &&
            msg.data.name &&
            !store.getState().uniqEvents.dataById.has(msg.data.name)
          ) {
            store.dispatch.uniqEvents.upsertOne({ name: msg.data.name, count: 1 });
            store.dispatch.uniqEvents.prepare(null);
            // do not break, unique events are different model
          }

          if (
            msg.model === ModelType.RecordingEvent &&
            msg.data.recording_id &&
            store
              .getState()
              .projectEdit.recordings.find(r => r.id === msg.data.recording_id)
          ) {
            store.dispatch.projectEvents.upsertOne(msg.data);
            store.dispatch.projectEvents.prepare(null);
            // do not break, unique events are different model
          }

          if (
            msg.model === ModelType.RecordingEvent &&
            msg.data.recording_id !== store.getState().video.recording?.id
          ) {
            break;
          }

          if (
            msg.model === ModelType.Project &&
            msg.data.id === store.getState().app.currentProject?.id
          ) {
            store.dispatch.app.setCurrentProject(msg.data);
          }

          if (
            msg.model === ModelType.Project &&
            msg.event === MessageType.UPDATED &&
            msg.data.id === store.getState().app.currentProject?.id &&
            msg.data.recording_ids &&
            store.getState().app.currentProject?.recording_ids &&
            !isArrayEqual(
              msg.data.recording_ids,
              store.getState().app.currentProject
                ?.recording_ids as unknown as unknown[],
            )
          ) {
            store.dispatch.enrichments.prepare(null);
            store.dispatch.visualizations.tryToRemoveRecordings(msg.data.recording_ids);
          }

          if (
            msg.model === ModelType.Aoi &&
            (!store.getState().projectEdit.currentEnrichment ||
              msg.data.enrichment_id !==
                store.getState().projectEdit.currentEnrichment?.id)
          ) {
            break;
          }

          if (
            msg.model === ModelType.Aoi &&
            msg.event === MessageType.UPDATED &&
            msg.data.id === store.getState().aoiAreas.current?.id
          ) {
            store.dispatch.aoiAreas.setCurrent(msg.data);
          }

          if (
            msg.model === ModelType.VisualizationConfig &&
            msg.event === MessageType.UPDATED &&
            msg.data.id === store.getState().visualizations.currentVisualization?.id
          ) {
            store.dispatch.visualizations.setCurrentVisualization(msg.data);
          }

          if (
            msg.model === ModelType.Recording &&
            store.getState().video.recording?.id === msg.data.id
          ) {
            store.dispatch.video.setRecording(msg.data);
          }

          if (msg.model === ModelType.StaticImageMapper) {
            if (store.getState().staticImageMappers.current?.id === msg.data.id) {
              store.dispatch.staticImageMappers.setCurrent(msg.data);
            }

            msg.data = remapStaticImageMapper(msg.data) as unknown as any;
          }

          adapter.upsertOne(msg.data);
          break;

        case MessageType.DELETED:
          if (msg.model === ModelType.RecordingEvent && msg.data.name) {
            store.dispatch.uniqEvents.deleteMany([msg.data.name]);
            store.dispatch.uniqEvents.prepare(null);

            store.dispatch.projectEvents.deleteMany([msg.data.id]);
            store.dispatch.projectEvents.prepare(null);
            // do not break, unique events are different model
          }

          if (msg.model === ModelType.Aoi) {
            store.dispatch.visualizations.tryToRemoveAoi(msg.data.id);

            const recordingIds = (msg.data as any).recording_ids;

            if (
              recordingIds &&
              Array.isArray(recordingIds) &&
              typeof recordingIds[0] === "string"
            ) {
              store.dispatch.visualizations.tryToRemoveRecordings(recordingIds);
            }
          }

          if (msg.data.id) adapter.deleteMany([msg.data.id]);
          else console.error("Delete id not found");
          break;

        default:
          return console.error("MessageType not found", e);
      }

      adapter.prepare?.(null);

      if (msg.model === ModelType.RecordingEvent) {
        store.dispatch.enrichments.checkStatus(msg.data);
      }

      if (msg.model === ModelType.ProjectEnrichment) {
        store.dispatch.enrichments.prepare(null);
      }

      if (
        msg.model === ModelType.ProjectEnrichment &&
        msg.event === MessageType.UPDATED &&
        msg.data.id === store.getState().projectEdit.currentEnrichment?.id
      ) {
        const currentTime = store.getState().video.instance?.currentTime();

        if (currentTime !== undefined)
          store.dispatch.video.getNextDataChunk({ currentTime });
      }

      if (
        (msg.model === ModelType.Recording &&
          msg.data.is_processed === true &&
          store.getState().app.currentProject &&
          store.getState().app.currentProject?.recording_ids?.includes(msg.data.id)) ||
        (msg.model === ModelType.Project &&
          msg.data.id === store.getState().app.currentProject?.id)
      ) {
        store.dispatch.projectEdit.prepare(null);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private error(e: Event) {
    console.error(e);
  }
}

export const wsClient = new WsClient();
