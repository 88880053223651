import { TextField, TextFieldProps } from "@mui/material";
import { ChangeEventHandler, FC, forwardRef } from "react";
import { PatternFormat } from "react-number-format";

const DEFAULT_MASK = "_";

export type DurationTextFieldOnChangeValueType = {
  floatValue: number;
  formattedValue: string;
  value: string;
};

export type DurationTextFieldProps = Omit<TextFieldProps, "onChange"> & {
  onChange?: (
    value: DurationTextFieldOnChangeValueType,
    event: { event: ChangeEventHandler<HTMLInputElement>; source: "string" },
  ) => void;
};

export const DurationTextField: FC<DurationTextFieldProps> = ({
  InputProps,
  sx,
  onChange,
  ...props
}) => {
  return (
    <TextField
      InputProps={{
        disableUnderline: true,
        ...InputProps,
        inputComponent: TimeFormat as any,
      }}
      sx={{ maxWidth: "6.5rem", ...sx }}
      {...props}
      onChange={onChange as any}
      variant="standard"
    />
  );
};

const TimeFormat = forwardRef<typeof PatternFormat>(function (props, ref) {
  //@ts-ignore
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={onChange}
      allowEmptyFormatting
      mask={DEFAULT_MASK}
      format="##:##:##.###"
    />
  );
});
