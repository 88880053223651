import { RecordingEvent } from "@api";
import { VIDEO_TIMELINE_SCROLL_ROOT, useInstanceDuration } from "@hooks";
import {
  Box,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { Instance } from "@popperjs/core";
import { VideoEventsTypes, VirtualListRows, useAppSelector } from "@storeRematch";
import { DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT } from "@storeRematch/videoEvents";
import { memo, useLayoutEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useVirtualListScrollWidth } from "./VideoComponentsBase";
import { EVENTS_CALC_ROOT_ID } from "./VideoEvents";
import { formatDurationInput } from "./utils";

interface RefPointsData {
  x: number;
  y: number;
  data: RecordingEvent;
}

const DEFAULT_ICON_SIZE = 9;
const OPEN_OFFSET = DEFAULT_ICON_SIZE / 2;

export const VideoEventsCanvas = memo(
  ({ event, events }: { event?: RecordingEvent; events?: VirtualListRows[] }) => {
    const popperRef = useRef<Instance>(null);
    const tooltipNameRef = useRef<HTMLSpanElement | null>(null);
    const tooltipTimeRef = useRef<HTMLSpanElement | null>(null);
    const refPoints = useRef<Array<RefPointsData>>([]);
    const positionRef = useRef<{ x: number; y: number }>({
      x: 0,
      y: 0,
    });
    const root = useRef<HTMLDialogElement | null>(null);
    const canvas = useRef<HTMLCanvasElement | null>(null);

    const disabled = useAppSelector(state => state.video.hideVideoPlayer);
    const zoomOffset = useAppSelector(state => state.video.zoom.zoomOffset);
    const zoomCss = useAppSelector(state => state.video.zoom.css);
    const fullScreen = useAppSelector(state => state.video.fullScreen);
    const size = useAppSelector(
      state => state.dragger.table.size + state.dragger.tableProject.size,
    );
    const recordingId = useAppSelector(state => state.video.recording?.id);

    const navigationParams = useParams();
    const params = useVirtualListScrollWidth();
    const duration = useInstanceDuration();

    useLayoutEffect(() => {
      function draw() {
        const rootEvents = document.getElementById(EVENTS_CALC_ROOT_ID);

        if (!canvas.current || !root.current || !rootEvents) return;

        canvas.current.width = root.current.offsetWidth + OPEN_OFFSET;

        const ctx = canvas.current.getContext("2d");

        if (!ctx) return;

        ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);

        if (disabled) return;

        const checkedDuration =
          duration === undefined || isNaN(duration) ? -1 : duration;
        if (checkedDuration === -1) return;
        if (!events && !event) return;

        const arr = (events || [
          { height: 0, type: VideoEventsTypes.EVENT, data: event },
        ]) as VirtualListRows[];

        refPoints.current = [];
        for (let i = 0; i < arr.length; i++) {
          const one = arr[i];

          if (one.type !== VideoEventsTypes.EVENT) continue;

          const data = drawEvent({ ctx, event: one.data, zoomOffset, checkedDuration });

          if (!data) continue;

          refPoints.current.push(data);
        }
      }

      draw();

      window.addEventListener("resize", draw);
      return () => window.removeEventListener("resize", draw);
    }, [
      event,
      events,
      duration,
      params,
      fullScreen,
      size,
      navigationParams,
      recordingId,
      disabled,
      zoomCss.width,
      zoomOffset,
    ]);

    return (
      <VideoEventsCanvasTooltip
        title={
          <>
            <Typography variant="body2" ref={tooltipNameRef}></Typography>
            <Typography variant="body2" ref={tooltipTimeRef}></Typography>
          </>
        }
        PopperProps={{
          popperRef,
          anchorEl: {
            getBoundingClientRect: () => {
              return new DOMRect(
                positionRef.current.x,
                root.current!.getBoundingClientRect().y,
                0,
                0,
              );
            },
          },
        }}
      >
        <Box
          id="event-root"
          ref={root}
          sx={{
            marginLeft: `-${OPEN_OFFSET}px`,
            width: `calc(100% + ${OPEN_OFFSET}px)`,
            position: "absolute",
          }}
        >
          <canvas
            ref={canvas}
            height={DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT}
            onMouseMove={e => {
              const canvas = e.currentTarget;
              const bounding = canvas.getBoundingClientRect();
              const mouseX = e.clientX - bounding.x;
              const mouseY = e.clientY - bounding.y;
              const ctx = canvas.getContext("2d");

              if (!ctx) return;

              refPoints.current.find(p => {
                if (
                  p.x <= mouseX &&
                  mouseX <= p.x + DEFAULT_ICON_SIZE &&
                  p.y <= mouseY &&
                  mouseY <= p.y + DEFAULT_ICON_SIZE
                ) {
                  positionRef.current = { x: e.clientX, y: e.clientY };

                  if (popperRef.current !== null) {
                    popperRef.current.update();

                    if (tooltipNameRef.current)
                      tooltipNameRef.current.innerHTML = p.data.name;

                    if (tooltipTimeRef.current)
                      tooltipTimeRef.current.innerHTML = formatDurationInput(
                        p.data.offset_s,
                      );
                  }

                  return true;
                } else {
                  positionRef.current = { x: -10000, y: -10000 };

                  if (popperRef.current !== null) {
                    popperRef.current.update();
                  }

                  return false;
                }
              });
            }}
          />
        </Box>
      </VideoEventsCanvasTooltip>
    );
  },
);

const VideoEventsCanvasTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    placement="top"
    arrow
    disableInteractive
    classes={{ popper: className }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    margin: "5px !important",
  },
}));

function drawEvent({
  ctx,
  event,
  checkedDuration,
  zoomOffset,
}: {
  ctx: CanvasRenderingContext2D;
  event: RecordingEvent;
  checkedDuration: number;
  zoomOffset: number;
}): RefPointsData | null {
  const offset = (event.offset_s / checkedDuration) * 100;
  const root = document.getElementById(VIDEO_TIMELINE_SCROLL_ROOT);
  const rootWidth = root?.offsetWidth;
  const rootParentWidth = root?.parentElement?.offsetWidth;

  if (!rootWidth || !rootParentWidth) return null;

  const posInPx = (rootWidth * offset) / 100 - zoomOffset;

  const img = isSystemEvent(event) ? imageSystem : imageUser;
  ctx.drawImage(img, posInPx, DEFAULT_ICON_SIZE);
  ctx.rect(posInPx, DEFAULT_ICON_SIZE, DEFAULT_ICON_SIZE, DEFAULT_ICON_SIZE);

  return { x: posInPx, y: DEFAULT_ICON_SIZE, data: event };
}

function isSystemEvent(event: RecordingEvent) {
  return event.origin === "recording";
}

const eventSystem = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve" height="100px" width="100px">
<path d="M4.89032 7.83832L5.59743 8.54543L4.89032 7.83833C4.7868 7.94184 4.6464 8 4.5 8C4.3536 8 4.2132 7.94184 4.10968 7.83832L1.16168 4.89032L0.454569 5.59743L1.16168 4.89032C1.05816 4.7868 1 4.6464 1 4.5C1 4.3536 1.05816 4.2132 1.16168 4.10968L4.10968 1.16168C4.2132 1.05816 4.3536 1 4.5 1C4.6464 1 4.7868 1.05816 4.89032 1.16168L5.59743 0.454569L4.89032 1.16168L7.83832 4.10968C7.94184 4.2132 8 4.3536 8 4.5C8 4.6464 7.94184 4.7868 7.83833 4.89032L4.89032 7.83832Z" stroke="#A09FA6" stroke-width="2"/>
</svg>
`;

const eventUser = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve" height="100px" width="100px" >
<path d="M0.376577 3.48057L3.48057 0.376577C3.72168 0.135459 4.04871 0 4.3897 0H4.6103C4.95129 0 5.27832 0.135459 5.51943 0.376577L8.62342 3.48057C8.86454 3.72168 9 4.04871 9 4.3897V4.6103C9 4.95129 8.86454 5.27832 8.62342 5.51943L5.51943 8.62342C5.27832 8.86454 4.95129 9 4.6103 9H4.3897C4.04871 9 3.72168 8.86454 3.48057 8.62342L0.376577 5.51943C0.135459 5.27832 0 4.95129 0 4.6103V4.3897C0 4.04871 0.135459 3.72168 0.376577 3.48057Z" fill="#A09FA6"/>
<mask id="mask0_5937_28707" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="4" y="0" width="5" height="9">
<path d="M5.14196 9H4.51953V0H5.14196L8.9991 3.85714V5.14286L5.14196 9Z" fill="#A09FA6"/>
</mask>
<g mask="url(#mask0_5937_28707)">
<path d="M0.376577 3.48057L3.48057 0.376577C3.72168 0.135459 4.04871 0 4.3897 0H4.6103C4.95129 0 5.27832 0.135459 5.51943 0.376577L8.62342 3.48057C8.86454 3.72168 9 4.04871 9 4.3897V4.6103C9 4.95129 8.86454 5.27832 8.62342 5.51943L5.51943 8.62342C5.27832 8.86454 4.95129 9 4.6103 9H4.3897C4.04871 9 3.72168 8.86454 3.48057 8.62342L0.376577 5.51943C0.135459 5.27832 0 4.95129 0 4.6103V4.3897C0 4.04871 0.135459 3.72168 0.376577 3.48057Z" fill="#D2D2D2"/>
</g>
</svg>
`;

const imageSystem = new Image();
imageSystem.src = `data:image/svg+xml;base64,${window.btoa(eventSystem)}`;

const imageUser = new Image();
imageUser.src = `data:image/svg+xml;base64,${window.btoa(eventUser)}`;
