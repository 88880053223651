import { ErrorPage } from "@components/ErrorPage";
import { RoutingHelper } from "@utils";
import { AcceptInvite } from "./AcceptInvite";
import { GoogleAuthServices } from "./GoogleAuthServices";
import { SendResetPassword } from "./SendResetPassword";
import { SignIn } from "./SignIn";
import { SignUp } from "./SignUp";
import { VerifyEmailRequest } from "./VerifyEmailRequest";
import {
  AccountDelete,
  AccountDeveloper,
  AccountEmailEdit,
  AccountImageEdit,
  AccountLayout,
  AccountNameEdit,
  AccountPasswordEdit,
  AccountSettings,
  AccountTokenAdd,
  Demo,
} from "./account";
import {
  HeatMap,
  LabelAdd,
  LabelEdit,
  Labels,
  Members,
  ProjectAdd,
  ProjectDownload,
  ProjectEdit,
  ProjectEnrichmentForm,
  ProjectEnrichmentTable,
  ProjectEnrichmentsAOI,
  ProjectLayout,
  ProjectRecordingsRoute,
  ProjectVisualizations,
  Projects,
  RecordingEdit,
  RecordingEditGazeOffset,
  RecordingEditWearer,
  Recordings,
  Render,
  TemplateEdit,
  Templates,
  VisualizationsAoi,
  WearerAdd,
  WearerEdit,
  Wearers,
  WorkspaceDelete,
  WorkspaceEdit,
  WorkspaceLayout,
  WorkspaceSettings,
  WorkspaceSettingsLayout,
} from "./workspaces";

// UnAuthenticated routes
export const signIn = new RoutingHelper({
  path: "signin",
  title: "Sign in",
  Element: SignIn,
});
export const singUp = new RoutingHelper({
  path: "signup",
  title: "Sign up",
  Element: SignUp,
});
export const remind = new RoutingHelper({
  path: "remind",
  title: "Reset password",
  Element: SendResetPassword,
});
export const verifyEmail = new RoutingHelper({
  path: "verify/email-request",
  title: "Verify email",
  Element: VerifyEmailRequest,
});
export const authService = new RoutingHelper({
  path: "auth/*",
  title: "Authentication service",
  Element: GoogleAuthServices,
});

// Authenticated routes
export const acceptInvites = new RoutingHelper<"token", any>({
  path: "accept/invites/:token",
  title: "Accept invitation",
  Element: AcceptInvite,
});
export const accountSettingsLayout = new RoutingHelper({
  path: "settings",
  Element: AccountLayout,
});
export const accountSettings = new RoutingHelper({
  path: "account",
  title: "Account settings",
  parent: accountSettingsLayout,
  Element: AccountSettings,
});
export const accountSettingsEditImage = new RoutingHelper({
  path: "edit/image",
  title: "Account image edit",
  parent: accountSettings,
  Element: AccountImageEdit,
});
export const accountSettingsEditName = new RoutingHelper({
  path: "edit/name",
  title: "Account name edit",
  parent: accountSettings,
  Element: AccountNameEdit,
});
export const accountSettingsEditEmail = new RoutingHelper({
  path: "edit/email",
  title: "Account email edit",
  parent: accountSettings,
  Element: AccountEmailEdit,
});
export const accountSettingsEditPassword = new RoutingHelper({
  path: "edit/password",
  title: "Account password edit",
  parent: accountSettings,
  Element: AccountPasswordEdit,
});
export const accountSettingsDelete = new RoutingHelper({
  path: "delete",
  title: "Account delete page",
  parent: accountSettings,
  Element: AccountDelete,
});
export const accountSettingsDeveloper = new RoutingHelper({
  path: "developer",
  title: "Account developer page",
  parent: accountSettingsLayout,
  Element: AccountDeveloper,
});
export const accountSettingsDeveloperNew = new RoutingHelper({
  path: "new",
  title: "Create token",
  parent: accountSettingsDeveloper,
  Element: AccountTokenAdd,
});
export const accountSettingsDemo = new RoutingHelper({
  path: "demo",
  title: "Demo dev page",
  parent: accountSettingsLayout,
  Element: Demo,
});
export const workspaceLayout = new RoutingHelper<"workspaceId", any>({
  path: "workspaces/:workspaceId",
  Element: WorkspaceLayout,
});
export const projectLayout = new RoutingHelper<"projectId", typeof workspaceLayout>({
  path: "projects/:projectId",
  parent: workspaceLayout,
  Element: ProjectLayout,
});
export const projectRecordings = new RoutingHelper({
  path: "recordings",
  title: "Project page",
  parent: projectLayout,
  Element: ProjectRecordingsRoute,
});
export const projectRecordingsEdit = new RoutingHelper<
  "recordingId",
  typeof projectRecordings
>({
  path: "edit/:recordingId",
  title: "Project recording edit",
  parent: projectRecordings,
  Element: RecordingEdit,
});
export const projectRecordingsEditWearer = new RoutingHelper<
  "recordingId",
  typeof projectRecordings
>({
  path: "edit/wearer/:recordingId",
  title: "Project recording edit wearer",
  parent: projectRecordings,
  Element: RecordingEditWearer,
});
export const projectEnrichments = new RoutingHelper({
  path: "enrichments",
  title: "Project enrichments",
  parent: projectLayout,
  Element: ProjectEnrichmentTable,
});
export const projectEnrichmentsEdit = new RoutingHelper<
  "enrichmentId",
  typeof projectEnrichments
>({
  path: ":enrichmentId",
  title: "Project enrichment edit",
  parent: projectEnrichments,
  Element: ProjectEnrichmentForm,
});
export const projectEnrichmentsAOI = new RoutingHelper({
  path: "aoi",
  title: "Project enrichment aoi",
  parent: projectEnrichmentsEdit,
  Element: ProjectEnrichmentsAOI,
});
export const projectVisualizations = new RoutingHelper({
  path: "visualizations",
  title: "Project visualizations",
  parent: projectLayout,
  Element: ProjectVisualizations,
});
export const projectVisualizationsHeatmap = new RoutingHelper({
  path: "visualizations/heatmap/:visualizationId",
  title: "Project visualizations heatmap",
  parent: projectLayout,
  Element: HeatMap,
});
export const projectVisualizationsRender = new RoutingHelper({
  path: "visualizations/render/:enrichmentId",
  title: "Project visualizations render",
  parent: projectLayout,
  Element: Render,
});
export const projectVisualizationsAoi = new RoutingHelper({
  path: "visualizations/aoi/:visualizationId",
  title: "Project visualizations aoi",
  parent: projectLayout,
  Element: VisualizationsAoi,
});
export const projectDownloads = new RoutingHelper({
  path: "downloads",
  title: "Project downloads",
  parent: projectLayout,
  Element: ProjectDownload,
});
export const workspaceRecordings = new RoutingHelper({
  path: "recordings",
  title: "Workspace page",
  parent: workspaceLayout,
  Element: Recordings,
});
export const workspaceRecordingsEdit = new RoutingHelper<
  "recordingId",
  typeof workspaceRecordings
>({
  path: "edit/:recordingId",
  title: "Workspace recording edit",
  parent: workspaceRecordings,
  Element: RecordingEdit,
});
export const workspaceRecordingsEditWearer = new RoutingHelper<
  "recordingId",
  typeof workspaceRecordings
>({
  path: "edit/wearer/:recordingId",
  title: "Workspace recording edit wearer",
  parent: workspaceRecordings,
  Element: RecordingEditWearer,
});
export const workspaceRecordingsEditGazeOffset = new RoutingHelper<
  "recordingId",
  typeof workspaceRecordings
>({
  path: "recordings/edit/gaze/:recordingId",
  title: "Workspace recording edit gaze offset",
  parent: workspaceLayout,
  Element: RecordingEditGazeOffset,
});
export const workspaceSettingsLayout = new RoutingHelper({
  path: "settings",
  title: "Workspace settings",
  parent: workspaceLayout,
  Element: WorkspaceSettingsLayout,
});
export const workspaceSettings = new RoutingHelper({
  title: "Workspace settings",
  parent: workspaceSettingsLayout,
  Element: WorkspaceSettings,
});
export const workspaceSettingsEdit = new RoutingHelper({
  path: "edit",
  title: "Workspace edit",
  parent: workspaceSettings,
  Element: WorkspaceEdit,
});
export const workspaceSettingsDelete = new RoutingHelper({
  path: "delete",
  title: "Workspace delete",
  parent: workspaceSettings,
  Element: WorkspaceDelete,
});
export const workspaceSettingsWearers = new RoutingHelper({
  path: "wearers",
  title: "Workspace wearers",
  parent: workspaceSettingsLayout,
  Element: Wearers,
});
export const workspaceSettingsWearersNew = new RoutingHelper({
  path: "new",
  title: "Workspace new wearer",
  parent: workspaceSettingsWearers,
  Element: WearerAdd,
});
export const workspaceSettingsWearersEdit = new RoutingHelper<
  "wearerId",
  typeof workspaceSettingsWearers
>({
  path: "edit/:wearerId",
  title: "Workspace edit wearer",
  parent: workspaceSettingsWearers,
  Element: WearerEdit,
});
export const workspaceSettingsLabels = new RoutingHelper({
  path: "labels",
  title: "Workspace labels",
  parent: workspaceSettingsLayout,
  Element: Labels,
});
export const workspaceSettingsLabelsNew = new RoutingHelper({
  path: "new",
  title: "Workspace new label",
  parent: workspaceSettingsLabels,
  Element: LabelAdd,
});
export const workspaceSettingsLabelsEdit = new RoutingHelper<
  "labelId",
  typeof workspaceSettingsLabels
>({
  path: "edit/:labelId",
  title: "Workspace edit label",
  parent: workspaceSettingsLabels,
  Element: LabelEdit,
});
export const workspaceSettingsProjects = new RoutingHelper({
  path: "projects",
  title: "Workspace projects",
  parent: workspaceSettingsLayout,
  Element: Projects,
});
export const workspaceSettingsProjectsNew = new RoutingHelper({
  path: "new",
  title: "Workspace new project",
  parent: workspaceSettingsProjects,
  Element: ProjectAdd,
});
export const workspaceSettingsProjectsEdit = new RoutingHelper<
  "projectId",
  typeof workspaceSettingsProjects
>({
  path: "edit/:projectId",
  title: "Workspace edit project",
  parent: workspaceSettingsProjects,
  Element: ProjectEdit,
});
export const workspaceSettingsTemplates = new RoutingHelper({
  path: "templates",
  title: "Workspace templates",
  parent: workspaceSettingsLayout,
  Element: Templates,
});
export const workspaceSettingsTemplatesEdit = new RoutingHelper<
  "templateId",
  typeof workspaceSettingsTemplates
>({
  path: "edit/:templateId",
  title: "Workspace edit template",
  parent: workspaceSettingsTemplates,
  Element: TemplateEdit,
});
export const workspaceSettingsMembers = new RoutingHelper({
  path: "members",
  title: "Workspace members",
  parent: workspaceSettingsLayout,
  Element: Members,
});
export const errorPage = new RoutingHelper({
  path: "*",
  title: "Not found page",
  Element: ErrorPage,
});
