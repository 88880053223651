import { SvgIcon, SvgIconProps } from "@mui/material";

export const VisualizationsAOI: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="65"
      height="48"
      viewBox="0 0 65 48"
      {...props}
      sx={{ ...props.sx, fill: "none", fontSize: "4.5rem" }}
    >
      <g clipPath="url(#clip0_3659_74382)">
        <rect width="65" height="48" fill="#2C3232" />
        <rect x="22.6094" y="16" width="20" height="16" rx="2" fill="#9EC1E1" />
        <path
          d="M24.6094 19C24.6094 18.4477 25.0571 18 25.6094 18H30.6094C31.1617 18 31.6094 18.4477 31.6094 19V29C31.6094 29.5523 31.1617 30 30.6094 30H25.6094C25.0571 30 24.6094 29.5523 24.6094 29V19Z"
          stroke="#2C3232"
        />
        <rect x="31.6094" y="18" width="9" height="5" rx="1" stroke="#2C3232" />
        <rect x="31.6094" y="23" width="9" height="7" rx="1" stroke="#2C3232" />
      </g>
      <defs>
        <clipPath id="clip0_3659_74382">
          <rect width="65" height="48" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
