import { Fade, Paper, Popper, PopperProps } from "@mui/material";
import { FC, PropsWithChildren } from "react";

export const CtxMenuBase: FC<
  PropsWithChildren<PopperProps & { minWidth?: number; maxHeight?: number | string }>
> = ({ children, minWidth = 250, maxHeight, sx, id, ...props }) => {
  return (
    <Popper
      id={id}
      role={undefined}
      transition
      disablePortal
      sx={{
        zIndex: 9,
        display: props.open ? "unset" : "none",
        minWidth,
        maxHeight,
        height: maxHeight ? "100%" : "unset",
        position: "relative",
        ...sx,
      }}
      {...props}
      modifiers={[
        {
          name: "myModifier",
          enabled: true,
          phase: "main",
          requiresIfExists: ["offset"],
          fn({ state, ...rest }) {
            setTimeout(() => {
              if (state.elements.popper.offsetHeight !== state.rects.popper.height) {
                rest.instance.forceUpdate();
              }
            });
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Paper elevation={1} sx={{ width: "100%", height: "100%" }}>
            {children}
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};
