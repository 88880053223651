import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@storeRematch/index";
import Konva from "konva";
import { memo, useEffect, useRef, useState } from "react";
import { Image, Layer, Stage } from "react-konva";
import { AreaLayersBase, AreaLayersBaseProps } from "./AreaLayersBase";

export const AoiDownloadLogic = () => {
  const data = useAppSelector(state => state.visualizationDownloads.query);

  return (
    <Box sx={{ position: "absolute" }}>
      {Array.from(data).map(id => (
        <Logic key={id} id={id} />
      ))}
    </Box>
  );
};

const Logic = memo(({ id }: { id: string }) => {
  const dispatch = useAppDispatch();
  const stageRef = useRef<Konva.Stage | null>(null);
  const [imgUrl, setImgUrl] = useState<string | null>(null);
  const [dim, setDim] = useState({ width: 0, height: 0 });
  const [props, setProps] = useState<AreaLayersBaseProps | undefined>();
  const [img, setImg] = useState<CanvasImageSource | undefined>();

  useEffect(() => {
    dispatch.visualizationDownloads
      .getImage(id)
      .then(url => (url ? setImgUrl(url) : null));

    return () => {
      dispatch.visualizationDownloads.removeFromQuery(id);
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (dim.width && dim.height) {
      dispatch.visualizationDownloads
        .getAois({
          id,
          width: stageRef.current?.width() || 0,
          height: stageRef.current?.height() || 0,
          scale: stageRef.current?.scale(),
        })
        .then(params => {
          setProps(params);
        });
    }
  }, [id, dim, dispatch]);

  useEffect(() => {
    if (props) {
      dispatch.visualizationDownloads.startDownload({
        id,
        canvas: stageRef.current?.toCanvas(),
      });
    }
  }, [id, props, dispatch]);

  if (!imgUrl) return null;

  return (
    <Box
      sx={{
        m: 1,
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        opacity: 0,
      }}
    >
      <img
        src={imgUrl}
        onLoadStart={() => {}}
        onLoad={e => {
          setDim({
            width: e.currentTarget.naturalWidth,
            height: e.currentTarget.naturalHeight,
          });

          setImg(e.currentTarget);
        }}
        onError={() => {}}
        style={{
          maxHeight: "100%",
          maxWidth: "100%",
          height: "auto",
          width: "auto",
          margin: "auto",
          display: "block",
          position: "absolute",
        }}
        alt="aoi_image"
      />
      <Stage ref={stageRef} width={dim.width} height={dim.height} draggable={false}>
        <Layer>
          <Image image={img} />
        </Layer>
        {props ? <AreaLayersBase {...props} textWidth={props.width} /> : null}
      </Stage>
    </Box>
  );
});
