import { ProjectEnrichmentAoi } from "@api";
import { AOI_HEATMAP_TYPES, AoiStatsWithData, AreaImages } from "@storeRematch";
import {
  formatReadableAoiHeatMapMetric,
  getAoiHeatMapPercentage,
  prepareAoiHeatMapColor,
} from "@utils";
import Konva from "konva";
import { Vector2d } from "konva/lib/types";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { Image, Layer, Text, Transformer } from "react-konva";
import {
  DEFAULT_DISABLED_OPACITY,
  DEFAULT_OPACITY,
  HOVERED_OPACITY,
  textAbstract,
} from "./helpers";

export interface AreaLayersBaseProps {
  width: number;
  height: number;
  scale?: Vector2d;
  disabled: boolean;
  data: ProjectEnrichmentAoi[];
  images: Record<string, AreaImages>;
  visibility: Record<string, boolean>;
  draw: boolean;
  aoiStatsById: Map<string, AoiStatsWithData>;
  heatmapType: AOI_HEATMAP_TYPES | null;
  heatmapColor: "Turbo" | "Jet" | "Magma" | "Viridis" | "Traffic Light" | undefined;
  showMetricValue: boolean;
  showNames: boolean;
  aoiAreasById: Map<string, ProjectEnrichmentAoi>;
  textWidth?: number;
}

export const AreaLayersBase: FC<AreaLayersBaseProps> = ({
  width,
  height,
  scale,
  disabled,
  data,
  images,
  visibility,
  draw,
  aoiStatsById,
  heatmapType,
  heatmapColor,
  showMetricValue,
  showNames,
  aoiAreasById,
  textWidth = 250,
}) => {
  const [selected, setSelected] = useState<string | null>(null);
  const mapRef = useRef(
    new Map<string, { text: Konva.Text | null; trans: Konva.Transformer | null }>(),
  );

  useEffect(() => {
    if (selected) {
      const curr = mapRef.current.get(selected);

      if (curr && curr.trans && curr.text) {
        curr.trans.nodes([curr.text]);
        curr.trans.anchorFill("white");
        curr.trans.anchorStroke("rgb(0, 161, 255)");
        curr.trans.getLayer()?.batchDraw();
      }
    }
  }, [selected]);

  const onClear = () => {
    if (selected) {
      const curr = mapRef.current.get(selected);

      if (curr && curr.trans) {
        curr.trans.nodes([]);
        curr.trans.getLayer()?.batchDraw();
      }
    }

    setSelected(null);
  };

  return (
    <Layer
      opacity={disabled ? 1 : draw ? HOVERED_OPACITY : DEFAULT_OPACITY}
      onClick={(e: Konva.KonvaEventObject<MouseEvent>) => {
        if (e.target.className === "Image") onClear();
      }}
      onMouseLeave={onClear}
    >
      {data.map(({ id }) => {
        if (!visibility[id] || !images[id]) return null;

        const [red, green, blue] = prepareAoiHeatMapColor(
          getAoiHeatMapPercentage(heatmapType, aoiStatsById.get(id)),
          heatmapColor,
        );

        return (
          <Fragment key={id}>
            <Image
              ref={r => r?.cache()}
              id={id}
              image={images[id].image}
              scale={scale}
              width={width}
              height={height}
              filters={disabled ? [Konva.Filters.RGBA] : undefined}
              red={disabled ? red : undefined}
              green={disabled ? green : undefined}
              blue={disabled ? blue : undefined}
              opacity={disabled ? DEFAULT_DISABLED_OPACITY : undefined}
            />
          </Fragment>
        );
      })}
      {data.map(({ id }) => {
        if (!visibility[id] || !images[id] || !disabled) return undefined;

        const aoi = aoiAreasById.get(id);

        if (!aoi || !aoi.bounding_box) return null;

        const metricReadable = formatReadableAoiHeatMapMetric(
          heatmapType,
          aoiStatsById.get(id),
        );

        const xChecked = width * aoi.bounding_box.min_x + 5;
        const yChecked = height * aoi.bounding_box.max_y + 8;

        let text = "";

        if (showNames) {
          text += textAbstract(`Name: ${aoi.name}`);
        }

        if (showMetricValue) {
          text += textAbstract(`Metric: ${metricReadable}`);
        }

        const onSelect = () => {
          if (selected) {
            const curr = mapRef.current.get(selected);

            if (curr && curr.trans) {
              curr.trans.nodes([]);
              curr.trans.getLayer()?.batchDraw();
            }
          }
          setSelected(id);
        };

        return (
          <>
            <Text
              ref={ref => {
                if (!mapRef.current.has(id)) {
                  mapRef.current.set(id, { text: null, trans: null });
                }

                const curr = mapRef.current.get(id);

                if (!curr) return;

                curr.text = ref;
              }}
              key={id}
              x={xChecked}
              y={yChecked}
              text={text}
              fontSize={(width > height ? width : height) * (18 / 1000)}
              font
              draggable
              fontFamily="Inter"
              fill="#fff"
              stroke="#666"
              strokeWidth={(width > height ? width : height) * (1 / 1000)}
              shadowBlur={(width > height ? width : height) * (3 / 1000)}
              shadowColor="#000000E5"
              fillAfterStrokeEnabled
              width={textWidth}
              opacity={1}
              scaleX={1 / window.devicePixelRatio}
              scaleY={1 / window.devicePixelRatio}
              onClick={onSelect}
              onTap={onSelect}
              onDragEnd={() => {}}
              onMouseEnter={() => {
                if (selected === id) return;

                const curr = mapRef.current.get(id);

                if (!curr || !curr.trans || !curr.text) return;

                curr.trans.nodes([curr.text]);
                curr.trans.anchorFill("transparent");
                curr.trans.anchorStroke("transparent");
                curr.trans.getLayer()?.batchDraw();
              }}
              onMouseLeave={() => {
                if (selected === id) return;

                const curr = mapRef.current.get(id);

                if (!curr || !curr.trans) return;

                curr.trans.nodes([]);
                curr.trans.getLayer()?.batchDraw();
              }}
            />
            <Transformer
              ref={ref => {
                if (!mapRef.current.has(id)) {
                  mapRef.current.set(id, { text: null, trans: null });
                }

                const curr = mapRef.current.get(id);

                if (!curr) return;

                curr.trans = ref;
              }}
              rotateEnabled={false}
              flipEnabled={false}
              keepRatio={true}
              boundBoxFunc={(curr, next) => {
                if (next.width < 40 || next.height < 30) {
                  return curr;
                }
                return next;
              }}
              enabledAnchors={["top-left", "top-right", "bottom-left", "bottom-right"]}
            />
          </>
        );
      })}
    </Layer>
  );
};
