import { SurfaceEnrichmentLocalization, api } from "@api";

enum SlicerLoadingStatus {
  NOT_STARTED,
  LOADING,
  DONE,
}

class Slicer<T> {
  protected sliceDuration = 600; // 10 min
  duration = 0;
  sliceCounter = 0;
  slices: Record<number, T[]> = {};
  loading: Record<number, SlicerLoadingStatus> = {};
  recordingId = "";

  init(duration = 0) {
    this.duration = duration;
    this.sliceCounter = Math.ceil(this.duration / this.sliceDuration);

    for (let i = 0; i < this.sliceCounter; i++) {
      this.slices[i] = [];
      this.loading[i] = SlicerLoadingStatus.NOT_STARTED;
    }

    return this;
  }

  reset() {
    this.duration = 0;
    this.sliceCounter = 0;
    this.slices = {};
    this.loading = {};
    this.recordingId = "";
  }

  getIndexFromStart(start: number) {
    if (start < this.sliceDuration) return 0;

    const index = Math.ceil(start / this.sliceDuration);

    return index;
  }

  useSlicer() {
    const test = true;

    if (test) return true;

    return this.duration > this.sliceDuration;
  }
}

class MarkerMapper extends Slicer<SurfaceEnrichmentLocalization> {
  async get({
    workspaceId,
    recordingId,
    surfaceId,
    time,
  }: {
    workspaceId: string;
    recordingId: string;
    surfaceId: string;
    time: number;
  }) {
    const index = this.getIndexFromStart(time);

    if (!this.useSlicer()) return;
    if (this.loading[index] !== SlicerLoadingStatus.NOT_STARTED) return;

    try {
      this.recordingId = recordingId;
      this.loading[index] = SlicerLoadingStatus.LOADING;

      const start = this.sliceDuration * index;
      const end = start + this.sliceDuration;

      const data = await api.getSurfaceEnrichmentLocalization(
        {
          workspaceId,
          recordingId,
          surfaceId,
          start,
          end,
        },
        true,
      );

      if (this.recordingId === recordingId) this.slices[index] = data.result;
    } catch (error) {
      console.error(error);
    } finally {
      if (this.recordingId === recordingId)
        this.loading[index] = SlicerLoadingStatus.DONE;
    }
  }
}

export const markerMapperLocalizationSlicer = new MarkerMapper();
