import { Box, Button } from "@mui/material";
import { useAppDispatch } from "@storeRematch";
import { memo } from "react";
import { useTranslation } from "react-i18next";

interface TokenCopyProp {
  token: string;
}

export const TokenCopy = memo<TokenCopyProp>(token => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const updateClipboard = (newClip: string) => {
    navigator.clipboard.writeText(newClip).then(
      () => {
        dispatch.notifier.enqueueSnackbar({
          message: t("API token copied to clipboard"),
          options: {
            variant: "success",
          },
        });
      },
      () => {
        dispatch.notifier.enqueueSnackbar({
          message: t("Error copying token"),
          options: {
            variant: "error",
          },
        });
      },
    );
  };

  if (token) {
    return (
      <Box display={"flex"} alignItems={"center"} gap={2}>
        <Box bgcolor={"rgba(255,255,255,0.1)"} p={1} borderRadius={1}>
          <code>{token.token}</code>
        </Box>
        <Button
          onClick={() => updateClipboard(token.token)}
          color="primary"
          variant="text"
          disableElevation
        >
          {t("Copy")}
        </Button>
      </Box>
    );
  } else {
    return null;
  }
});
