import { Profile, User } from "@api";
import { AvatarBlob, FormDialog } from "@form";
import { Grid } from "@mui/material";
import { auth } from "@services/firebase";
import { useAppDispatch } from "@storeRematch";
import { updateProfile } from "firebase/auth";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useTranslation } from "react-i18next";

export interface AccountImageFormProps {
  open: boolean;
  onClose(): void;
  user?: User | Profile;
}

export const AccountImageForm: React.FC<AccountImageFormProps> = ({
  open,
  onClose,
  user = undefined,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSubmitUpload = async ({ blobList }: { blobList: FileList }) => {
    if (!user || !auth.currentUser) return;

    try {
      const file = blobList[0];

      if (!file) return;

      const storageRef = ref(getStorage(), `${auth.currentUser.uid}/profile.jpg`);

      await uploadBytes(storageRef, file);

      const photoURL = await getDownloadURL(storageRef);

      await updateProfile(auth.currentUser, { photoURL });

      dispatch.app.updateAccount();

      onClose();
    } catch (error) {
      dispatch.notifier.enqueueSnackbar({
        message: t("Unable to upload the image"),
        options: { variant: "error" },
      });
    }
  };

  return (
    <FormDialog
      open={open}
      title={t("Display Picture")}
      onClose={onClose}
      onFormSubmit={handleSubmitUpload}
      useFormProps={{ defaultValues: { blobList: [user?.photo_url] } }}
      loading={false}
    >
      <Grid container alignItems="center" justifyContent="center">
        <AvatarBlob name="blobList" />
      </Grid>
    </FormDialog>
  );
};
