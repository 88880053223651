import { SvgIcon, SvgIconProps } from "@mui/material";

export const Twitter: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7711 8.08766C19.7779 8.26254 19.7802 8.43736 19.7802 8.61224C19.7802 13.9144 16.0503 20.0355 9.22975 20.0355C7.13459 20.0355 5.18638 19.3678 3.54492 18.231C3.83508 18.2628 4.12976 18.2867 4.42896 18.2867C6.16614 18.2867 7.76615 17.6427 9.03531 16.5616C7.41269 16.5377 6.04255 15.3692 5.57 13.7793C5.79686 13.827 6.03047 13.8509 6.26938 13.8509C6.60627 13.8509 6.93335 13.8033 7.24687 13.7079C5.54888 13.3422 4.26994 11.7206 4.26994 9.77301C4.26994 9.74916 4.26994 9.74116 4.26994 9.72526C4.77037 10.0194 5.34314 10.2021 5.95134 10.226C4.95501 9.50259 4.30007 8.27047 4.30007 6.87934C4.30007 6.148 4.48246 5.45638 4.80351 4.86018C6.63188 7.29268 9.36542 8.89051 12.4471 9.05745C12.3838 8.76332 12.3514 8.45338 12.3514 8.14335C12.3514 5.92549 14.0117 4.12891 16.0601 4.12891C17.1265 4.12891 18.0897 4.61389 18.7657 5.39292C19.6121 5.21803 20.405 4.88419 21.1224 4.42312C20.8443 5.36114 20.2572 6.14797 19.49 6.64082C20.2407 6.54543 20.9566 6.33096 21.6206 6.01299C21.1224 6.81587 20.4954 7.52325 19.7711 8.08766Z"
      />
    </SvgIcon>
  );
};
