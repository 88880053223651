import { EntityDataTable, NoDataDefault, ReactRouterLinkCustom } from "@components";
import { WearerAvatar } from "@components/Common/StringAvatar";
import { INACTIVE_FIELD_NAME } from "@constants";
import { Box, Button, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { RouterHelper } from "@pages";
import { WearersTableData, useAppSelector } from "@storeRematch";
import {
  DisableByPermissionsType,
  LocalStorageAdapterNames,
  disabledByPermission,
} from "@utils";
import { Outlet } from "react-router-dom";
import TimeAgo from "timeago-react";
import { WearersContextMenu } from "./WearersContextMenu";

export const Wearers = () => {
  const rows = useAppSelector(state => state.wearers.tableData);
  const isLoading = useAppSelector(
    state => state.loading.effects.app.loadWorkspaceData.loading,
  );
  const disabledPermissions = disabledByPermission({
    type: DisableByPermissionsType.CREATE,
  });

  return (
    <>
      <EntityDataTable
        loading={isLoading}
        rows={rows}
        columns={columns}
        noDataMessage={<NoDataDefault name="wearers" />}
        noFilters
        initialState={{
          columns: {
            columnVisibilityModel: {
              [INACTIVE_FIELD_NAME]: false,
            },
          },
        }}
        tableColumnInitialStateStorageKey={
          LocalStorageAdapterNames.settingsWearersColumState
        }
      >
        <Button
          component={ReactRouterLinkCustom}
          to={RouterHelper.workspaceSettingsWearersNew.getRelativePath()}
          replace={true}
          color="primary"
          disabled={disabledPermissions}
        >
          New Wearer
        </Button>
      </EntityDataTable>
      <WearersContextMenu />
      <Outlet />
    </>
  );
};

const columns: GridColDef<WearersTableData>[] = [
  {
    width: 200,
    field: "name",
    headerName: "Name",
    valueGetter: (_, row) => row.wearer.name,
    renderCell: params => {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }}
        >
          <WearerAvatar wearer={params.row.wearer} />
          <Typography ml={1.5} noWrap>
            {params.row.wearer.name}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "recordingsCount",
    headerName: "Recordings",
    type: "number",
    align: "left",
  },
  {
    field: "created_at",
    headerName: "Created",
    type: "date",
    renderCell: params => {
      return (
        params.row.wearer.created_at && (
          <TimeAgo datetime={params.row.wearer.created_at} opts={{ minInterval: 60 }} />
        )
      );
    },
  },
  { field: "id", headerName: "ID" },
  {
    field: INACTIVE_FIELD_NAME,
    headerName: "Trashed",
    type: "boolean",
    valueGetter: (_, row) => {
      return row.wearer.archived_at ? true : false;
    },
  },
];
