import { DurationTextField } from "@components/DurationTextField";
import { CreatedByAsList } from "@components/createdBy";
import { LabelsAsList } from "@components/labels";
import { ProjectsAsList } from "@components/projects";
import { TemplatesAsList } from "@components/templates";
import { formatDurationNsFull } from "@components/videoPlayer/utils";
import { WearersAsList } from "@components/wearer";
import { Box, Button, TextField } from "@mui/material";
import { ContextMenuTypes, useAppDispatch, useAppSelector } from "@storeRematch";
import { KeyboardEvent, useEffect, useState } from "react";
import { CustomDatePicker } from "./CustomDatePicker";
import { isCustomFilter } from "./utils";

interface FilterValueFormProps {
  defaultValue?: string | string[];
  onSave: (value: any) => void;
  filterType: ContextMenuTypes;
  item: { field?: string; type?: string };
  isContained?: boolean;
}

type LocalState<T> = Record<
  string,
  { checked: boolean; indeterminate: boolean; model: T }
>;

const defaultGetId = <T,>(m: T): string => (m as any).id;
const defaultGetName = <T,>(m: T) => (m as any).name;

function useLocalState<
  T extends { id?: string; name?: string; user?: any },
  S extends LocalState<T>,
>({
  is,
  defaultValue,
  valuesById,
  getId = defaultGetId,
  getName = defaultGetName,
}: {
  is: boolean;
  valuesById: Map<string, T>;
  defaultValue?: string | string[];
  getId?: (m: T) => string;
  getName?: (m: T) => string;
}) {
  const [state, setState] = useState<S>({} as S);

  useEffect(() => {
    if (is && defaultValue && Array.isArray(defaultValue)) {
      const typed = defaultValue as unknown as T[];

      const nextState = typed.reduce<S>((acc, model) => {
        const id = defaultGetId(model);

        if (!id) return acc;

        const nextModel = valuesById.get(id);

        if (model) {
          // @ts-ignore
          acc[id] = {
            checked: true,
            indeterminate: false,
            model: nextModel,
          };
        }

        return acc;
      }, {} as S);

      setState(s => (JSON.stringify(s) !== JSON.stringify(nextState) ? nextState : s));
    }
  }, [is, defaultValue, valuesById, getId]);

  const getFilterValue = () => {
    return Object.values(state).map(v => ({
      id: getId(v.model),
      name: getName(v.model),
    }));
  };

  const onClick = (checked: boolean, model: T) => {
    const nextState = {
      ...state,
      [getId(model)]: { checked: !checked, indeterminate: false, model },
    };

    if (checked) delete nextState[getId(model)];

    setState(nextState);
  };

  const onReset = () => {
    setState({} as S);
  };

  return { state, getFilterValue, onClick, onReset };
}

export function FilterValueForm({
  defaultValue,
  onSave,
  filterType,
  item,
  isContained,
}: FilterValueFormProps) {
  const {
    isArray,
    isCreatedBy,
    isDate,
    isDuration,
    isLabel,
    isTemplateName,
    isWearers,
    isProjects,
  } = isCustomFilter(item);
  const dispatch = useAppDispatch();
  const labelsById = useAppSelector(state => state.labels.dataById);
  const wearersById = useAppSelector(state => state.wearers.dataById);
  const templatesById = useAppSelector(state => state.templates.dataById);
  const createdById = useAppSelector(state => state.members.dataById);
  const projectsById = useAppSelector(state => state.projects.dataById);
  const [value, setValue] = useState<string | string[]>(defaultValue ?? "");

  const {
    state: labelsState,
    getFilterValue: getLabelsFilterValue,
    onClick: labelsOnClick,
  } = useLocalState({
    is: isLabel,
    defaultValue,
    valuesById: labelsById,
  });
  const {
    state: wearersState,
    getFilterValue: getWearersFilterValue,
    onClick: wearersOnClick,
  } = useLocalState({
    is: isWearers,
    defaultValue,
    valuesById: wearersById,
  });
  const {
    state: templatesState,
    getFilterValue: getTemplatesFilterValue,
    onClick: templatesOnClick,
  } = useLocalState({
    is: isTemplateName,
    defaultValue,
    valuesById: templatesById,
  });
  const {
    state: createdByState,
    getFilterValue: getCreatedByFilterValue,
    onClick: createdByOnClick,
  } = useLocalState({
    is: isCreatedBy,
    defaultValue,
    valuesById: createdById,
    getId: m => m.user?.id ?? "",
    getName: m => m.user?.name ?? "",
  });
  const {
    state: projectsState,
    getFilterValue: getProjectsFilterValue,
    onClick: projectsOnClick,
  } = useLocalState({
    is: isProjects,
    defaultValue,
    valuesById: projectsById,
  });

  useEffect(() => {
    if (isContained) setValue("");
  }, [isContained]);

  useEffect(() => {
    if (defaultValue && !value) setValue(defaultValue);
  }, [defaultValue, value]);

  const handleClick = () => {
    const saveValue =
      isLabel && !isContained
        ? getLabelsFilterValue()
        : isWearers && !isContained
        ? getWearersFilterValue()
        : isTemplateName && !isContained
        ? getTemplatesFilterValue()
        : isCreatedBy && !isContained
        ? getCreatedByFilterValue()
        : isProjects && !isContained
        ? getProjectsFilterValue()
        : value;

    onSave(saveValue);
    dispatch.ctxMenu.close(filterType);
  };

  const handleEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") handleClick();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={isDate ? 320 : 244}
      borderRadius={8}
      px={(isArray && !isContained) || isDate ? 0 : 2}
      pb={1.5}
      pt={isArray && !isContained ? 0 : 1.5}
    >
      {(item?.type === "string" ||
        item?.type === "number" ||
        (isArray && isContained)) &&
      item.field !== "duration_ns" ? (
        <TextField
          size="small"
          placeholder="Value"
          onChange={e => setValue(e.target.value)}
          onKeyDown={handleEnter}
          fullWidth
          autoFocus
          value={isContained && Array.isArray(value) ? "" : value}
        />
      ) : null}

      {isDate && (
        <CustomDatePicker
          value={value as string}
          onClick={next => {
            dispatch.ctxMenu.close(filterType);
            setValue(next || "");
            onSave(next || "");
          }}
        />
      )}

      {isDuration && (
        <DurationTextField
          defaultValue={getTimeFormat(value as string)}
          InputProps={{ disableUnderline: false }}
          sx={{ maxWidth: "100%" }}
          onChange={a => {
            let nextValue = String(
              new Date(
                `1970-01-01T${a.formattedValue.replaceAll("_", "0")}Z`,
              ).getTime(),
            );

            if (nextValue.length < 8) {
              nextValue = `${nextValue}${Array(6).fill("0").join("")}`;
            }

            setValue(nextValue);
          }}
          onKeyDown={handleEnter}
        />
      )}

      {isLabel && !isContained ? (
        <LabelsAsList onClick={labelsOnClick} localState={labelsState} />
      ) : null}

      {isWearers && !isContained ? (
        <WearersAsList onClick={wearersOnClick} localState={wearersState} />
      ) : null}

      {isTemplateName && !isContained ? (
        <TemplatesAsList onClick={templatesOnClick} localState={templatesState} />
      ) : null}

      {isCreatedBy && !isContained ? (
        <CreatedByAsList onClick={createdByOnClick} localState={createdByState} />
      ) : null}

      {isProjects && !isContained ? (
        <ProjectsAsList onClick={projectsOnClick} localState={projectsState} />
      ) : null}

      {!isDate && (
        <Button
          size="small"
          color="primary"
          sx={{ ml: "auto", mt: 1.5 }}
          onClick={handleClick}
        >
          Apply
        </Button>
      )}
    </Box>
  );
}

function getTimeFormat(value?: string) {
  try {
    return formatDurationNsFull(Number(value));
  } catch (error) {
    return value || "00:00:00.000";
  }
}
