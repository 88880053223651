import {
  EnrichmentTypesEnum,
  ProjectEnrichment,
  StaticImageMapper,
  StaticImageMapperFixation,
  StaticImageMapperPostRequest,
  api,
} from "@api";
import i18n from "../i18n";

import { createModel } from "@rematch/core";
import {
  CheckIfModelNeedsUpdate,
  downloadLocally,
  remapMarkerless,
  remapStaticImageMapper,
} from "@utils";
import { type RootModel } from "./index";

const checkUpdate = new CheckIfModelNeedsUpdate();

interface InitialState {
  current: StaticImageMapper | null;
  fixations: StaticImageMapperFixation[];
  checkedFixations: number;
  currentFixationIndex: number | null;
  autoMove: boolean;
  delayIndicator: boolean;
  mousePositionNotOnRefImg: boolean;
  rerenderMouse: boolean;
}

const initialState: InitialState = {
  current: null,
  fixations: [],
  checkedFixations: 0,
  currentFixationIndex: null,
  autoMove: true,
  delayIndicator: false,
  mousePositionNotOnRefImg: false,
  rerenderMouse: false,
};

export const staticImageMappers = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setCurrent(state, data: InitialState["current"]) {
      state.current = data;

      if (state.current?.id !== data?.id) checkUpdate.reset();
    },
    setFixations(
      state,
      data: {
        fixations: InitialState["fixations"];
        checkedFixations: InitialState["checkedFixations"];
      },
    ) {
      state.fixations = data.fixations;
      state.checkedFixations = data.checkedFixations;
    },
    updateFixation(
      state,
      data: {
        x: number | null;
        y: number | null;
        gazeOnAoi: number | null;
        index: number;
        ws?: boolean;
      },
    ) {
      const checkRes = state.current
        ? checkUpdate.check(state.current.id, {
            index: data.index,
            x: data.x,
            y: data.y,
            gazeOnAoi: data.gazeOnAoi,
          })
        : false;

      if (state.fixations[data.index] && (!data.ws || checkRes)) {
        state.checkedFixations =
          state.checkedFixations +
          (data.x === null && data.y === null && data.gazeOnAoi === null ? -1 : 1);
        state.fixations[data.index].x = data.x;
        state.fixations[data.index].y = data.y;
        state.fixations[data.index].gaze_on_aoi = data.gazeOnAoi;
      }
    },
    setCurrentFixationIndex(state, data: InitialState["currentFixationIndex"]) {
      state.currentFixationIndex = data;
    },
    toggleAutoMove(state) {
      state.autoMove = !state.autoMove;
    },
    toggleDelayIndicator(state) {
      state.delayIndicator = !state.delayIndicator;
    },
    setMousePositionNotOnRefImg(state, data: InitialState["mousePositionNotOnRefImg"]) {
      state.mousePositionNotOnRefImg = data;
    },
    toggleRerenderMouse(state) {
      state.rerenderMouse = !state.rerenderMouse;
    },
    reset() {
      checkUpdate.reset();
      return { ...initialState };
    },
  },
  effects: dispatch => ({
    async get(model: ProjectEnrichment, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const currentId = state.staticImageMappers.current?.id;

      if (
        !workspaceId ||
        !projectId ||
        model.id === currentId ||
        model.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER
      ) {
        if (model.id !== currentId) dispatch.staticImageMappers.setCurrent(null);
        return;
      }

      try {
        const data = await api.getStaticImageMapper({
          workspaceId,
          projectId,
          enrichmentId: model.id,
        });

        const remapped = remapStaticImageMapper(data.result);

        dispatch.staticImageMappers.setCurrent(data.result);
        dispatch.enrichments.upsertOne(remapped);
        dispatch.staticImageMappers.setMarkerless(null);
        dispatch.staticImageMappers.getFixations(null);
      } catch (error) {
        console.error(error);
      }
    },
    async create(props: StaticImageMapperPostRequest, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;

      if (!workspaceId || !projectId) return;

      try {
        const data = await api.postStaticImageMapper({
          workspaceId,
          projectId,
          projectStaticImageMapperPostRequest: props,
        });

        const remapped = remapStaticImageMapper(data.result);

        dispatch.staticImageMappers.setCurrent(data.result);
        dispatch.enrichments.upsertOne(remapped);

        return remapped;
      } catch (error) {
        console.error(error);
      }
    },
    async update(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const form = state.projectEdit.form;
      const currentEnrichment = state.projectEdit.currentEnrichment;

      if (
        !workspaceId ||
        !projectId ||
        form.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER ||
        !currentEnrichment
      ) {
        return;
      }

      try {
        const data = await api.patchStaticImageMapper({
          workspaceId,
          projectId,
          enrichmentId: currentEnrichment.id,
          projectStaticImageMapperPatchRequest: {
            static_image_id: form.imageId,
          },
        });

        const remapped = remapStaticImageMapper(data.result);

        dispatch.staticImageMappers.setCurrent(data.result);
        dispatch.projectEdit.setCurrentEnrichment(remapped);
        dispatch.enrichments.upsertOne(remapped);

        dispatch.staticImageMappers.setMarkerless(null);
        dispatch.staticImageMappers.getFixations(null);
      } catch (error) {
        console.error(error);
      }
    },
    setMarkerless(_, state) {
      const markerless = remapMarkerless(state.staticImageMappers.current);

      if (!markerless) return;

      dispatch.projectEdit.setCurrentMarkerLess(markerless);
    },
    async getFixations(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const current = state.staticImageMappers.current;
      const recordingId = state.video.recording?.id;

      if (
        !workspaceId ||
        !projectId ||
        !current ||
        !recordingId ||
        current.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER
      ) {
        return;
      }

      try {
        const data = await api.getStaticImageMapperFixations({
          workspaceId,
          projectId,
          enrichmentId: current.id,
          recordingId,
        });

        dispatch.staticImageMappers.tryToSetCurrentFixationIndex(null);
        dispatch.staticImageMappers.setFixations({
          fixations: data.result.fixations,
          checkedFixations: data.result.checked_fixations,
        });

        dispatch.staticImageMappers.nextFixation(null);
        dispatch.localization.prepareMarkerMapper(null);
      } catch (error) {
        console.error(error);
      }
    },
    async setFixationPosition(
      pos: { x: number; y: number; gazeOnAoi: number | null },
      state,
    ) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const current = state.staticImageMappers.current;
      const recordingId = state.video.recording?.id;
      const currentFixationIndex = state.staticImageMappers.currentFixationIndex;
      const fixations = state.staticImageMappers.fixations;
      const fixationId =
        currentFixationIndex !== null
          ? fixations[currentFixationIndex]?.fixation_id
          : null;

      if (
        !workspaceId ||
        !projectId ||
        !current ||
        !recordingId ||
        !fixationId ||
        currentFixationIndex === null ||
        current.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER ||
        !current.static_image_id
      ) {
        return;
      }

      try {
        dispatch.staticImageMappers.updateFixation({
          index: currentFixationIndex,
          ...pos,
        });

        if (state.staticImageMappers.autoMove) {
          dispatch.staticImageMappers.nextFixation(null);
        }

        await api.putStaticImageMapperFixation({
          workspaceId,
          projectId,
          enrichmentId: current.id,
          recordingId,
          fixationId,
          projectStaticImageMapperFixationPutRequest: {
            x: pos.x,
            y: pos.y,
            gaze_on_aoi: pos.gazeOnAoi,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async deleteFixationPosition(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const current = state.staticImageMappers.current;
      const recordingId = state.video.recording?.id;
      const currentFixationIndex = state.staticImageMappers.currentFixationIndex;
      const fixations = state.staticImageMappers.fixations;
      const fixation =
        currentFixationIndex !== null ? fixations[currentFixationIndex] : null;

      if (
        !workspaceId ||
        !projectId ||
        !current ||
        !recordingId ||
        !fixation ||
        (fixation.x === null && fixation.y === null && fixation.gaze_on_aoi === null) ||
        currentFixationIndex === null ||
        current.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER ||
        !current.static_image_id
      ) {
        return;
      }

      try {
        dispatch.staticImageMappers.updateFixation({
          index: currentFixationIndex,
          x: null,
          y: null,
          gazeOnAoi: null,
        });

        await api.deleteStaticImageMapperFixation({
          workspaceId,
          projectId,
          enrichmentId: current.id,
          recordingId,
          fixationId: fixation.fixation_id,
        });
      } catch (error) {
        console.error(error);
      }
    },
    async setFixationNotOnRefImg(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const current = state.staticImageMappers.current;
      const recordingId = state.video.recording?.id;
      const currentFixationIndex = state.staticImageMappers.currentFixationIndex;
      const fixations = state.staticImageMappers.fixations;
      const fixation =
        currentFixationIndex !== null ? fixations[currentFixationIndex] : null;

      if (!current?.static_image_id) return;

      if (
        !workspaceId ||
        !projectId ||
        !current ||
        !recordingId ||
        !fixation ||
        (fixation.x === null && fixation.y === null && fixation.gaze_on_aoi === 0) ||
        currentFixationIndex === null ||
        current.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER
      ) {
        dispatch.staticImageMappers.nextFixation(null);
        return;
      }

      try {
        dispatch.staticImageMappers.updateFixation({
          index: currentFixationIndex,
          x: null,
          y: null,
          gazeOnAoi: 0,
        });

        if (state.staticImageMappers.autoMove) {
          dispatch.staticImageMappers.toggleDelayIndicator();
          dispatch.staticImageMappers.nextFixation(null);
        }

        await api.putStaticImageMapperFixation({
          workspaceId,
          projectId,
          enrichmentId: current.id,
          recordingId,
          fixationId: fixation.fixation_id,
          projectStaticImageMapperFixationPutRequest: {
            x: null,
            y: null,
            gaze_on_aoi: 0,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async resetFixations(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const current = state.staticImageMappers.current;
      const recordingId = state.video.recording?.id;

      if (
        !workspaceId ||
        !projectId ||
        !current ||
        !recordingId ||
        current.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER ||
        !current.static_image_id
      ) {
        return;
      }

      try {
        await api.resetStaticImageMapperFixations({
          workspaceId,
          projectId,
          enrichmentId: current.id,
          recordingId,
        });

        dispatch.staticImageMappers.getFixations(null);
      } catch (error) {
        console.error(error);
      }
    },
    nextFixation(_, state) {
      const instance = state.video.instance;
      const currentFixationIndex = state.staticImageMappers.currentFixationIndex;
      const fixations = state.staticImageMappers.fixations;

      if (!instance) return;

      const next =
        currentFixationIndex === null
          ? getFixationIndexBasedOnVideoTime(fixations, instance.currentTime())
          : currentFixationIndex + 1;
      const nextFixation = fixations[next];
      const fixationTime = getTimeForFixation(nextFixation);

      if (!nextFixation || fixationTime === null) return;

      instance.hasStarted(true);
      instance.currentTime(fixationTime);
      dispatch.staticImageMappers.tryToSetCurrentFixationIndex(next);
    },
    prevFixation(_, state) {
      const instance = state.video.instance;
      const currentFixationIndex = state.staticImageMappers.currentFixationIndex;
      const fixations = state.staticImageMappers.fixations;

      if (!instance) return;

      const next =
        currentFixationIndex === null
          ? getFixationIndexBasedOnVideoTime(fixations, instance.currentTime(), true)
          : currentFixationIndex - 1;
      const nextFixation = fixations[next];
      const fixationTime = getTimeForFixation(nextFixation);

      if (!nextFixation || fixationTime === null) return;

      instance.hasStarted(true);
      instance.currentTime(fixationTime);
      dispatch.staticImageMappers.tryToSetCurrentFixationIndex(next);
    },
    setFixation(id: number, state) {
      const instance = state.video.instance;
      const fixations = state.staticImageMappers.fixations;

      if (!instance) return;

      const index =
        state.staticImageMappers.currentFixationIndex !== null &&
        fixations[state.staticImageMappers.currentFixationIndex]?.fixation_id === id
          ? state.staticImageMappers.currentFixationIndex
          : fixations.findIndex(f => f.fixation_id === id);

      const nextFixation = fixations[index];
      const fixationTime = getTimeForFixation(nextFixation);

      if (!nextFixation || fixationTime === null) {
        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Fixation not found"),
          options: {
            variant: "error",
          },
        });
        return;
      }

      instance.hasStarted(true);
      instance.currentTime(fixationTime);
      dispatch.staticImageMappers.tryToSetCurrentFixationIndex(index);
    },
    async download(id: string, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichment = state.enrichments.dataById.get(id);

      if (
        !workspaceId ||
        !projectId ||
        !enrichment ||
        !enrichment.args?.static_image_id
      ) {
        return;
      }

      try {
        downloadLocally({
          href: `${state.app.apiUrl}/workspaces/${workspaceId}/projects/${projectId}/mappers/static/${id}/export`,
          name: "enrichment_csv.zip",
        });
      } catch (error) {
        console.error(error);
      }
    },
    tryToSetCurrentFixationIndex(index: InitialState["currentFixationIndex"], state) {
      if (index === null) {
        dispatch.staticImageMappers.setCurrentFixationIndex(null);
        return;
      }

      const slices = state.video.recordingSlices;
      const duration = state.video.instance?.duration();
      const currentTime = state.video.instance?.currentTime();
      let found = false;

      for (const slice of slices) {
        if (
          slice.start !== undefined &&
          slice.end !== undefined &&
          duration !== undefined &&
          currentTime !== undefined &&
          currentTime >= (duration * slice.start) / 100 &&
          currentTime <= (duration * slice.end) / 100
        ) {
          found = true;
          break;
        }
      }

      if (found) {
        dispatch.staticImageMappers.setCurrentFixationIndex(index);
      } else {
        dispatch.staticImageMappers.setCurrentFixationIndex(null);
      }
    },
  }),
});

function getTimeForFixation(fixation: StaticImageMapperFixation | null | undefined) {
  if (!fixation) return null;
  if (fixation.seek_timestamp) return fixation.seek_timestamp;

  if (!fixation.start_timestamp || !fixation.end_timestamp) return null;

  return fixation.start_timestamp;
}

function getFixationIndexBasedOnVideoTime(
  fixations: StaticImageMapperFixation[],
  currentTime: number,
  back?: boolean,
): number {
  if (!fixations.length) return 0;

  const index =
    fixations.findIndex(
      a => a.seek_timestamp !== null && a.seek_timestamp >= currentTime,
    ) + (back ? -1 : 0);

  if (index === -1) {
    const lastIndex = fixations.length - 1;
    const fist = fixations[0];
    const last = fixations[lastIndex];

    if (fist.seek_timestamp !== null && currentTime < fist.seek_timestamp) return 0;
    if (last.seek_timestamp !== null && currentTime > last.seek_timestamp)
      return lastIndex;
  }

  return index;
}
