import { ErrorPage } from "@components";
import { useAppSelector } from "@storeRematch";
import { useNavigate } from "react-router-dom";
import { AccountEmailPassForm } from "./AccountEmailPassForm";

export const AccountPasswordEdit = () => {
  const navigate = useNavigate();
  const currentUser = useAppSelector(state => state.app.apiUser);

  if (!currentUser) {
    return <ErrorPage title={`User not found in this workspace`} />;
  }

  return (
    <AccountEmailPassForm
      type="password"
      user={currentUser}
      onClose={() => navigate(-1)}
      open={true}
    />
  );
};
