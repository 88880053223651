import { api, Label, Recording, RecordingPatchResponse } from "@api";
import { createModel } from "@rematch/core";
import { rematchAdapter, RematchAdapter } from "@utils";
import i18n from "../i18n";
import type { RecordingWithData, RootModel } from "./index";

interface ExtraProps {
  counters: Record<
    "labels" | "wearers" | "templates",
    Record<string, { count: number; recordingIds: Map<string, boolean> } | undefined>
  >;
}

interface Adapter extends RematchAdapter<Recording, RecordingWithData>, ExtraProps {}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.id,
  extraProps: {
    counters: {
      labels: {},
      wearers: {},
      templates: {},
    },
  },
});

export const recordings = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
    setCounters(state, data: Adapter["counters"]) {
      state.counters = data;
    },
  },
  effects: dispatch => ({
    async get(workspaceId: string) {
      dispatch.projectEdit.setRecordings([]);
      const data = await api.getRecordings({ workspaceId });

      dispatch.recordings.setAll(data.result);
    },
    async rename(props: { name: string; id: string }, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectRecordings = state.projectEdit.recordings;

      if (!workspaceId) return;

      try {
        const data = await api.patchRecording({
          workspaceId,
          recordingId: props.id,
          recordingPatchRequest: { id: props.id, name: props.name },
        });

        dispatch.recordings.upsertOne(data.result);
        dispatch.projectEdit.setRecordings(
          projectRecordings.map(r =>
            r.id === props.id
              ? { ...r, recording: { ...r.recording, name: props.name } }
              : r,
          ),
        );
        dispatch.recordings.prepare(null);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Recording renamed"),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async changeWearer(props: { wearerId: string; id: string }, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectRecordings = state.projectEdit.recordings;

      if (!workspaceId) return;

      try {
        const data = await api.patchRecording({
          workspaceId,
          recordingId: props.id,
          recordingPatchRequest: { id: props.id, wearer_id: props.wearerId },
        });

        dispatch.recordings.upsertOne(data.result);
        dispatch.projectEdit.setRecordings(
          projectRecordings.map(r =>
            r.id === props.id
              ? { ...r, recording: { ...r.recording, name: props.wearerId } }
              : r,
          ),
        );
        dispatch.recordings.prepare(null);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Recording wearer changed"),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    prepare(_, state) {
      const recordings = state.recordings.data;
      const wearersById = state.wearers.dataById;
      const labelsById = state.labels.dataById;
      const membersById = state.members.dataById;
      const templatesById = state.templates.dataById;
      const projectsById = state.projects.projectsByRecordingId;
      const tableData: RecordingWithData[] = [];
      const tableDataById = new Map<string, RecordingWithData>();
      const counters: Adapter["counters"] = {
        labels: {},
        wearers: {},
        templates: {},
      };

      for (const recording of recordings) {
        updateCounters(counters.wearers, recording.wearer_id, recording.id);
        updateCounters(counters.templates, recording.template_id, recording.id);
        const projects = projectsById.get(recording.id)?.values();

        const recordingWithData: RecordingWithData = {
          id: recording.id,
          recording: recording,
          wearer: wearersById.get(recording.wearer_id),
          createdBy: membersById.get(recording.created_by_user_id),
          labels: recording.label_ids
            ? recording.label_ids.reduce<Label[]>((acc, labelId) => {
                updateCounters(counters.labels, labelId, recording.id);

                const label = labelsById.get(labelId);

                if (label) acc.push(label);

                return acc;
              }, [])
            : [],
          template: templatesById.get(recording.template_id),
          projects: projects ? Array.from(projects) : [],
        };

        tableData.push(recordingWithData);
        tableDataById.set(recording.id, recordingWithData);
      }

      dispatch.recordings.setTableData(tableData);
      dispatch.recordings.setTableDataById(tableDataById);
      dispatch.recordings.setCounters(counters);
    },
    async addLabelsToSelected(labelIds: string[], state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const recordingsById = state.recordings.dataById;
      const recordingIds = state.entityTable.selectedIds;
      const promises: Promise<RecordingPatchResponse>[] = [];

      if (!workspaceId) return;

      recordingIds.forEach(id => {
        const recording = recordingsById.get(id);

        if (recording) {
          const nextLabelIds = new Set(recording.label_ids);
          labelIds.forEach(l => {
            if (!nextLabelIds.has(l)) nextLabelIds.add(l);
          });

          promises.push(
            api.patchRecording({
              workspaceId,
              recordingId: recording.id,
              recordingPatchRequest: {
                id: recording.id,
                label_ids: Array.from(nextLabelIds),
              },
            }),
          );
        }
      });

      try {
        const res = await Promise.all(promises);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Recording labels added"),
          options: {
            key: "Recordings labels added",
            variant: "success",
          },
        });

        dispatch.recordings.updateMany(res.map(r => r.result));
        dispatch.recordings.prepare(null);
        dispatch.labels.prepare(null);
        dispatch.projectEdit.prepare(null);
      } catch (error) {
        console.error(error);
      }
    },
    async removeLabelsFromSelected(labelIds: string[], state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const recordingsById = state.recordings.dataById;
      const recordingIds = state.entityTable.selectedIds;
      const promises: Promise<RecordingPatchResponse>[] = [];

      if (!workspaceId) return;

      recordingIds.forEach(id => {
        const recording = recordingsById.get(id);

        if (recording) {
          const nextLabelIds = new Set(recording.label_ids || []);
          labelIds.forEach(id => {
            if (nextLabelIds.has(id)) nextLabelIds.delete(id);
          });

          promises.push(
            api.patchRecording({
              workspaceId,
              recordingId: recording.id,
              recordingPatchRequest: {
                id: recording.id,
                label_ids: Array.from(nextLabelIds),
              },
            }),
          );
        }
      });

      try {
        const res = await Promise.all(promises);

        dispatch.recordings.updateMany(res.map(r => r.result));
        dispatch.recordings.prepare(null);
        dispatch.labels.prepare(null);
        dispatch.projectEdit.prepare(null);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Recording labels removed"),
          options: {
            key: "Recording labels removed",
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async updateInactive(
      { inactive, ids }: { inactive: boolean; ids: string[] },
      state,
    ) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const promises: Promise<RecordingPatchResponse>[] = [];

      if (!workspaceId) return;

      ids.forEach(id => {
        promises.push(
          api.patchRecording({
            workspaceId,
            recordingId: id,
            recordingPatchRequest: {
              id,
              trashed_at: inactive ? new Date().toISOString() : null,
            },
          }),
        );
      });

      try {
        const res = await Promise.all(promises);

        dispatch.recordings.updateMany(res.map(r => r.result));
        dispatch.recordings.prepare(null);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t(inactive ? "Recordings trashed" : "Recordings restored"),
          options: {
            variant: "success",
            buttonComponent: inactive
              ? {
                  text: i18n.t("Undo"),
                  onClick: () => {
                    dispatch.recordings.updateInactive({
                      ids,
                      inactive: false,
                    });
                  },
                }
              : undefined,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async delete(ids: string[], state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;

      if (!workspaceId) return;

      try {
        await api.deleteRecordings({
          workspaceId,
          recordingsDeleteRequest: { recording_ids: ids },
        });

        dispatch.recordings.deleteMany(ids);
        dispatch.recordings.prepare(null);
        dispatch.wearers.prepare(null);
        dispatch.labels.prepare(null);
        dispatch.projects.prepare(null);
        dispatch.templates.prepare(null);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Recordings deleted"),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
  }),
});

// helpers
function updateCounters(
  state: ExtraProps["counters"]["labels"],
  labelId: string,
  recordingId: string,
) {
  let counter = state[labelId];

  if (!counter) {
    counter = {
      count: 0,
      recordingIds: new Map<string, boolean>(),
    };
  }

  counter.count = counter.count ? counter.count + 1 : 1;
  counter.recordingIds?.set(recordingId, true);

  state[labelId] = counter;
}
