import { Button, Paper } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { useTranslation } from "react-i18next";

export const ProjectFilteredRecordingsMessage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filteredSize = useAppSelector(
    state => state.projectFilterRecordings.filtered.length,
  );

  if (filteredSize === 0) return null;

  return (
    <Paper sx={{ p: 1.5, mb: 2, boxShadow: "none" }}>
      {filteredSize} recording{filteredSize === 1 ? "" : "s"} cannot be added to the
      project because they are processing or have errors.
      <Button
        size="small"
        variant="text"
        color="primary"
        sx={{ mt: 0.5, ml: 0.5 }}
        onClick={() => dispatch.projectFilterRecordings.setShowCheck(true)}
      >
        {t("View recordings")}
      </Button>
    </Paper>
  );
};
