import { Recording } from "@api";
import { Autocomplete, FormDialog } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    wearer_id: yup.string().required(),
  })
  .required();

export const RecordingEditWearer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const recordingId = String(params.recordingId);
  const recording = useAppSelector(state => state.recordings.dataById.get(recordingId));
  const wearers = useAppSelector(state => state.wearers.data);
  const wearersById = useAppSelector(state => state.wearers.dataById);
  const dispatch = useAppDispatch();

  const options = useMemo(() => {
    return wearers.reduce(
      (acc, one) => {
        acc.push({ value: one.id, label: one.name });

        return acc;
      },
      [] as {
        value: string;
        label: string;
      }[],
    );
  }, [wearers]);

  const onClose = () => {
    navigate(-1);
  };

  const handleSubmit = async (formData: Recording) => {
    if (!params.workspaceId || !recording) return;

    await dispatch.recordings.changeWearer({
      id: recording.id,
      wearerId: formData.wearer_id as string,
    });

    onClose();
  };

  if (!recording) return null;

  const wearer = wearersById.get(recording.wearer_id);
  const defaultValue = wearer ? { label: wearer.name, value: wearer.id } : undefined;

  return (
    <FormDialog
      open
      title={t("Change Wearer")}
      onFormSubmit={handleSubmit}
      onClose={onClose}
      useFormProps={{
        defaultValues: recording,
        resolver: yupResolver(schema),
      }}
    >
      <Stack spacing={3}>
        <Autocomplete name="wearer_id" options={options} defaultValue={defaultValue} />

        <Typography variant="body2" color="text.secondary">
          {t("Editing the wearer may affect your downstream data in this Project.")}
        </Typography>
      </Stack>
    </FormDialog>
  );
};
