import { Box, styled } from "@mui/system";
import { useAppSelector } from "@storeRematch";

// Grid item spacing 16px
export const AppContent = styled(Box)(({ theme }) => {
  const fullScreen = useAppSelector(state => state.video.fullScreen);

  return {
    marginTop:
      fullScreen || theme.components?.MuiAppBar?.styleOverrides?.root?.maxHeight
        ? 0
        : 48,
    height: "100%",
    width: "100%",
    maxHeight: `calc(100vh - ${
      fullScreen ? 0 : theme.components?.MuiAppBar?.styleOverrides?.root?.maxHeight
    }px)`,

    "& > div": {
      flex: 1,
    },
  };
});
