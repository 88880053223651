import { ContextMenu, CtxMenuItem } from "@components";
import { MenuList } from "@mui/material";
import {
  ContextMenuTypes,
  QuestionDialogTypes,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import React from "react";
import { useTranslation } from "react-i18next";

export const DeveloperContextMenu: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selected = useAppSelector(state => state.entityTable.selectedIds);
  const numSelected = selected.length;

  const onClose = () => {
    dispatch.ctxMenu.closeAll();
  };

  const handleDelete = () => {
    onClose();
    dispatch.questionDialog.set({
      type: QuestionDialogTypes.TOKEN_DELETE,
      onSuccess: () => {
        dispatch.tokens.delete(selected);
      },
      extra: { count: selected.length },
    });
  };

  return (
    <ContextMenu type={ContextMenuTypes.RECORD_TABLE}>
      <MenuList>
        <CtxMenuItem
          label={
            numSelected === 1
              ? t("Delete token")
              : t("Delete {{numSelected}} tokens", { numSelected })
          }
          onClick={handleDelete}
        />
      </MenuList>
    </ContextMenu>
  );
};
