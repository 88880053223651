import { RecordingFile } from "@api";
import { createModel } from "@rematch/core";
import { Upload, UploadOptions } from "tus-js-client";
import type { RootModel } from "./index";

interface InitialState {
  loading: boolean;
  file?: RecordingFile | null;
  base64?: File;
}

const initialState: InitialState = {
  loading: false,
  file: undefined,
  base64: undefined,
};

export const uploader = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setLoading(state, data: InitialState["loading"]) {
      state.loading = data;
    },
    setFile(state, data: InitialState["file"]) {
      state.file = data;
    },
    setBase64(state, data: InitialState["base64"]) {
      state.base64 = data;
    },
  },
  effects: dispatch => ({
    async upload(
      {
        recordingId,
        aoiId,
        file,
        withState = true,
      }: {
        recordingId?: string;
        aoiId?: string;
        file: File;
        withState?: boolean;
      },
      state,
    ): Promise<RecordingFile | undefined | void> {
      let model: RecordingFile | undefined = undefined;

      if (withState) dispatch.uploader.setBase64(file);

      return new Promise(resolve => {
        const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
        const apiKey = state.app.apiKey;

        if (!workspaceId) return;

        if (withState) dispatch.uploader.setLoading(true);

        const chunkSizeInMB = 6;
        const options: UploadOptions = {
          endpoint: `${state.app.apiUrl}/workspaces/${workspaceId}/files/`,
          headers: apiKey ? { "x-api-key": apiKey } : undefined,
          chunkSize: chunkSizeInMB * 1024 * 1024,
          retryDelays: [0, 1000, 3000, 5000],
          uploadSize: file.size,
          metadata: {
            name: file.name,
            content_type: file.type,
            ...(recordingId ? { recording_id: recordingId } : {}),
            ...(aoiId ? { aoi_id: aoiId } : {}),
          },
          onError(error) {
            console.error(
              "file: " +
                file.name +
                " had an error during uploading, file type was: " +
                file.type,
            );
            console.error(error);

            if (withState) dispatch.uploader.setLoading(false);
            dispatch.notifier.enqueueSnackbar({
              message: "Error uploading a file",
              options: { variant: "error" },
            });
          },
          onSuccess() {
            if (withState) {
              dispatch.uploader.setLoading(false);
              dispatch.notifier.enqueueSnackbar({
                message: "File uploaded",
                options: { variant: "success" },
              });
            }
            resolve(model);
          },
          onBeforeRequest: function (req) {
            const xhr = req.getUnderlyingObject();
            xhr.withCredentials = true;
          },
          onAfterResponse(_, res) {
            try {
              if (res.getStatus() === 201) {
                const body = res.getBody();
                const json = JSON.parse(body);

                if (json.result) {
                  if (withState) dispatch.uploader.setFile(json.result);
                  model = json.result;
                }
              }
            } catch (e) {
              console.error(e);
            }
          },
        };

        const uploader = new Upload(file, options);
        uploader.start();
      });
    },
    async uploadEnrichmentAoi(url: string, state) {
      const current = state.aoiAreas.current;

      if (!current) return;

      const blob = await (await fetch(url)).blob();

      const file = new File([blob], `aoi_${current.id}.png`, { type: "image/png" });

      const model = await dispatch.uploader.upload({
        file,
        withState: false,
        aoiId: current.id,
      });

      if (!model) return;

      dispatch.aoiAreas.update({ id: current.id, mask_image_id: model.id });
    },
  }),
});
