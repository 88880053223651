import { store } from "@storeRematch/index";
import { useGesture as useBaseHook } from "@use-gesture/react";
import { setScrollDisabled } from "@utils";
import { useRef } from "react";

export const useGesture = () => {
  const target = useRef<HTMLElement | null>(null);
  useBaseHook(
    {
      onPinch: e => {
        const offset = e.delta[0];

        if (
          offset === 0 ||
          (offset > 0 && offset < 0.00001) ||
          (offset < 0 && offset > -0.00001) ||
          offset.toString().includes("e")
        ) {
          return;
        }

        store.dispatch.video.onTimeLineZoom({ direction: offset > 0 });
      },
      onTouchStart: () => {
        setScrollDisabled(true);
      },
      onTouchEnd: () => {
        setScrollDisabled(false);
      },
    },
    { target, pinch: { threshold: 5 } },
  );

  return target;
};
