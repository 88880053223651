import { SvgIcon, SvgIconProps } from "@mui/material";

export const ArtWork: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="136" height="38" viewBox="0 0 136 38" fill="none" {...props}>
      <path
        d="M19.3281 8.71979C25.3849 13.5198 29.4228 20.5016 30.5284 28.138C26.9712 29.5683 23.2218 30.2955 19.3281 30.2955C15.4344 30.2955 11.6849 29.5683 8.12773 28.138C9.23335 20.5016 13.2712 13.5198 19.3281 8.71979ZM19.3281 0.453125C18.7753 0.453125 18.2225 0.59858 17.7418 0.913731C7.88738 7.31373 1.20563 18.1743 0.580713 30.6349C0.532643 31.7743 1.15756 32.8652 2.16703 33.3743C7.31054 36.041 13.1511 37.544 19.3281 37.544C25.5051 37.544 31.3456 36.041 36.4891 33.3986C37.4986 32.8652 38.1235 31.7986 38.0755 30.6592C37.4505 18.1743 30.7688 7.31373 20.9384 0.937973C20.4337 0.59858 19.8809 0.453125 19.3281 0.453125Z"
        fill="url(#paint0_linear_1562_97106)"
      />
      <path
        d="M59.1316 10.0524H56.8242V9.17969H62.8811V11.4827C62.8811 12.21 62.7609 12.6464 62.4244 12.9615C62.1119 13.2767 61.6072 13.3979 61.0304 13.3979C60.4776 13.3979 59.9488 13.2524 59.6123 12.8888C59.2758 12.5494 59.1316 12.0888 59.1316 11.4827V10.0524ZM59.9007 11.8221C59.9007 12.2342 60.1891 12.5009 60.5977 12.5009H61.487C61.8956 12.5009 62.136 12.2585 62.136 11.8221V10.0282H59.9007V11.8221Z"
        fill="#FAFAFA"
      />
      <path
        d="M57.5918 17.3238C57.0631 17.0814 56.7266 16.6693 56.7266 15.9905C56.7266 15.142 57.1832 14.5117 58.3369 14.5117H61.3653V15.3117H58.4331C57.7841 15.3117 57.3755 15.5299 57.3755 16.2087C57.3755 16.8875 57.9283 17.3238 58.7215 17.3238H61.3653V18.1238H56.8227V17.3238H57.5918Z"
        fill="#FAFAFA"
      />
      <path
        d="M55.3092 19.6289H61.366V20.4289H60.6209C61.1737 20.7198 61.4622 21.2047 61.4622 21.8835C61.4622 23.1198 60.4046 23.7016 59.0827 23.7016C57.7608 23.7016 56.7032 23.1198 56.7032 21.8835C56.7032 21.1804 57.0157 20.6956 57.5445 20.4289H55.2852V19.6289H55.3092ZM58.7222 20.4289C57.929 20.4289 57.3762 20.8895 57.3762 21.6895C57.3762 22.3683 57.7848 22.8531 58.4097 22.8531H59.8278C60.4527 22.8531 60.8613 22.3683 60.8613 21.6895C60.8613 20.8895 60.2844 20.4289 59.5153 20.4289H58.7222Z"
        fill="#FAFAFA"
      />
      <path
        d="M56.8242 24.9617H61.3668V25.7617H56.8242V24.9617ZM62.0639 25.786V24.9375H62.9051V25.786H62.0639Z"
        fill="#FAFAFA"
      />
      <path d="M56.8242 27.2891H62.8811V28.0891H56.8242V27.2891Z" fill="#FAFAFA" />
      <path
        d="M67.2051 18.8777C67.2051 12.7444 69.7047 8.91406 74.8963 8.91406C78.4775 8.91406 81.2175 11.0959 81.4819 14.6595H78.8861C78.6458 12.4535 77.1075 11.2171 74.656 11.2171C72.0602 11.2171 70.0893 13.1322 70.0893 15.8232V21.6171C70.0893 24.2353 72.2284 26.2232 74.8242 26.2232C77.2998 26.2232 78.9342 25.1807 79.1745 22.6353H81.7703C81.53 26.1747 78.6458 28.5262 74.8482 28.5262C69.921 28.5019 67.2051 24.7201 67.2051 18.8777Z"
        fill="#FAFAFA"
      />
      <path d="M85.4727 28.0887V9.30078H87.9483V28.0887H85.4727Z" fill="#FAFAFA" />
      <path
        d="M98.0661 28.4308C93.8119 28.4308 91.793 25.352 91.793 21.0611C91.793 16.746 93.8119 13.6914 98.0661 13.6914C102.32 13.6914 104.339 16.7702 104.339 21.0611C104.339 25.352 102.32 28.4308 98.0661 28.4308ZM101.744 18.8793C101.744 16.9399 100.037 15.6793 98.0661 15.6793C96.0953 15.6793 94.3888 16.9399 94.3888 18.8793V23.2429C94.3888 25.1823 96.0953 26.4429 98.0661 26.4429C100.037 26.4429 101.744 25.1823 101.744 23.2429V18.8793Z"
        fill="#FAFAFA"
      />
      <path
        d="M116.646 25.7857C115.876 27.4342 114.675 28.4281 112.56 28.4281C109.988 28.4281 108.041 27.0221 108.041 23.41V14.0039H110.517V23.1191C110.517 25.1312 111.166 26.4403 113.257 26.4403C115.348 26.4403 116.646 24.6948 116.646 22.2463V14.0039H119.121V28.0888H116.646V25.7857Z"
        fill="#FAFAFA"
      />
      <path
        d="M132.942 28.0887V25.7856C132.077 27.4826 130.611 28.4038 128.544 28.4038C124.77 28.4038 122.943 25.1068 122.943 21.0341C122.943 16.9371 124.722 13.6644 128.471 13.6644C130.562 13.6644 132.053 14.6099 132.942 16.2826V9.30078H135.418V28.0887H132.942ZM132.942 19.8705C132.942 17.4462 131.572 15.6765 129.096 15.6765C127.077 15.6765 125.539 16.9856 125.539 18.8765V23.2402C125.539 25.1553 127.077 26.4402 129.096 26.4402C131.572 26.4402 132.942 24.6705 132.942 22.2462V19.8705Z"
        fill="#FAFAFA"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1562_97106"
          x1="6.23807"
          y1="11.2846"
          x2="32.6507"
          y2="37.4713"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="1.3e-07" stopColor="#68C3EF" />
          <stop offset="0.0762296" stopColor="#61B5E6" />
          <stop offset="0.3876" stopColor="#497EC2" />
          <stop offset="0.6565" stopColor="#3756A9" />
          <stop offset="0.8694" stopColor="#2C3E99" />
          <stop offset="1" stopColor="#283593" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
