import { Loader } from "@components";
import { Button, Link, Typography } from "@mui/material";
import { auth } from "@services/firebase";
import { ActionCodeInfo, applyActionCode, checkActionCode } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useSearchParams } from "react-router-dom";

export const RecoverEmailAction: React.FC = () => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const [data, setData] = useState<ActionCodeInfo["data"] | null>(null);
  const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(false);

  const onClick = async () => {
    const oobCode = params.get("oobCode");

    if (!oobCode) return;

    setLoading(true);
    await applyActionCode(auth, oobCode);
    setDone(true);
  };

  useEffect(() => {
    const oobCode = params.get("oobCode");

    if (oobCode) {
      checkActionCode(auth, oobCode)
        .then(info => {
          setData(info.data);
          setLoading(false);
        })
        .catch(() => {});
    }
  }, [params]);

  if (done) return <Navigate replace to={"../"} />;

  if (loading) return <Loader />;

  if (!data) {
    return (
      <>
        <Typography variant="body2" color="text.secondary">
          {t("Incorrect access code. Please try again.")}
        </Typography>
        <Typography variant="body2" component="span">
          <Link href={"/signin"}>{t("Log In for Pupil Cloud")}</Link>
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography variant="body2" color="text.secondary">
        {t(
          `Your sing-in email ${data.email} for Pupil Labs - Pupil Cloud has been changed to ${data.previousEmail}.`,
        )}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {t("If you didn't change your email, please reset it.")}
      </Typography>
      <Button fullWidth onClick={onClick} color="primary">
        {t("Reset")}
      </Button>
    </>
  );
};
