import { ContextMenu, ReactRouterLinkCustom } from "@components";
import { KeyboardArrowDown } from "@mui/icons-material";
import { CircularProgress, IconButton, Link } from "@mui/material";
import { ContextMenuTypes, useAppDispatch, useAppSelector } from "@storeRematch";
import { FC, PropsWithChildren } from "react";
import { BreadCrumbsText } from "./BreadCrumbsText";

interface BreadcrumbsPopoverProps {
  text?: string;
  filterType?: ContextMenuTypes;
  to?: string;
  id?: string;
  active?: boolean;
}

export const BreadcrumbsPopover: FC<PropsWithChildren<BreadcrumbsPopoverProps>> = ({
  text = "",
  filterType = ContextMenuTypes.FILTER,
  to,
  id,
  active = false,
  children,
}) => {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(
    state => state.loading.effects.app.loadWorkspaceData.loading,
  );

  return (
    <>
      <ContextMenu type={filterType}>{children}</ContextMenu>

      {to ? (
        <Link
          component={ReactRouterLinkCustom}
          to={to}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
            "&:hover": {
              backgroundColor: "action.hover",
            },
            color: active ? "inherit" : "text.secondary",
          }}
        >
          <BreadCrumbsText>{text}</BreadCrumbsText>
        </Link>
      ) : (
        <BreadCrumbsText mr={1.5} sx={{ color: active ? "inherit" : "text.secondary" }}>
          {text}
        </BreadCrumbsText>
      )}
      <IconButton
        id={id}
        disabled={disabled}
        size="small"
        onClick={e =>
          dispatch.ctxMenu.handleClick({ type: filterType, position: true, e })
        }
      >
        {disabled ? <CircularProgress size={20} /> : <KeyboardArrowDown />}
      </IconButton>
    </>
  );
};
