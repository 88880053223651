import {
  GridColDef,
  GridColumnOrderChangeParams,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid-pro";
import { LocalStorageAdapterNames, localStorageAdapter } from "@utils";
import { useEffect, useState } from "react";

interface UseGetTableColumnState {
  orderedFields: string[];
  columnVisibilityModel: Record<string, boolean>;
  dimensions: Record<string, { width?: number }>;
}

export const useGetTableColumnState = ({
  columns,
  localStorageName,
  visibilityModelDefault = {},
}: {
  columns: readonly GridColDef[];
  localStorageName: LocalStorageAdapterNames;
  visibilityModelDefault?: GridColumnVisibilityModel;
}) => {
  const [state] = useState(
    localStorageAdapter.get<UseGetTableColumnState>(localStorageName) ||
      columns.reduce<UseGetTableColumnState>(
        (acc, { field, width }) => {
          acc.orderedFields.push(field);
          acc.columnVisibilityModel[field] = visibilityModelDefault[field] ?? true;
          if (width) acc.dimensions[field] = { width };

          return acc;
        },
        { orderedFields: [], columnVisibilityModel: {}, dimensions: {} },
      ),
  );

  useEffect(() => {
    localStorageAdapter.set(
      LocalStorageAdapterNames.tableVisibilityStateDefault,
      visibilityModelDefault,
    );

    return () => {
      localStorageAdapter.delete(LocalStorageAdapterNames.tableVisibilityStateDefault);
    };
  }, [visibilityModelDefault]);

  const onColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const nextToOld = state.orderedFields[params.targetIndex];

    state.orderedFields[params.targetIndex] = params.column.field;
    state.orderedFields[params.oldIndex] = nextToOld;

    localStorageAdapter.set(localStorageName, state);
  };

  const onColumnResize = (params: GridColumnResizeParams) => {
    state.dimensions[params.colDef.field] = { width: params.width };

    localStorageAdapter.set(localStorageName, state);
  };

  const onColumnVisibilityModelChange = (params: GridColumnVisibilityModel) => {
    state.columnVisibilityModel = params;

    localStorageAdapter.set(localStorageName, state);
  };

  return {
    columnsState: state,
    onColumnOrderChange,
    onColumnResize,
    onColumnVisibilityModelChange,
  };
};
