import { GoogleIcon } from "@customIcons";
import { Button, ButtonProps } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const GoogleButton: FC<ButtonProps> = props => {
  const { t } = useTranslation();

  return (
    <Button variant="contained" {...props} fullWidth>
      <Box sx={{ mr: "auto" }}>
        <GoogleIcon />
      </Box>
      <Box sx={{ mr: "auto" }}>{t(`Continue with Google`)}</Box>
    </Button>
  );
};
