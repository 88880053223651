/**
 * ![mauve1](https://singlecolorimage.com/get/161618/24x24)
 * ![mauve2](https://singlecolorimage.com/get/161618/24x24)
 * ![mauve3](https://singlecolorimage.com/get/232326/24x24)
 * ![mauve4](https://singlecolorimage.com/get/28282c/24x24)
 * ![mauve5](https://singlecolorimage.com/get/2e2e32/24x24)
 * ![mauve6](https://singlecolorimage.com/get/34343a/24x24)
 * ![mauve7](https://singlecolorimage.com/get/3e3e44/24x24)
 * ![light1](https://singlecolorimage.com/get/504F57/24x24)
 * ![light2](https://singlecolorimage.com/get/706F78/24x24)
 * ![light3](https://singlecolorimage.com/get/7E7D86/24x24)
 * ![light4](https://singlecolorimage.com/get/A09FA6/24x24)
 * ![light5](https://singlecolorimage.com/get/EDEDEF/24x24)
 * ![plue1](https://singlecolorimage.com/get/5C66A7/24x24)
 * ![plue2](https://singlecolorimage.com/get/6875CF/24x24)
 */
export const customColors = {
  /**
   * Preview: ![mauve1](https://singlecolorimage.com/get/1E1F20/24x24)
   */
  mauve1: "#161618",
  /**
   * Preview: ![mauve2](https://singlecolorimage.com/get/34363C/24x24)
   */
  mauve2: "#1C1C1F",
  /**
   * Preview: ![mauve3](https://singlecolorimage.com/get/242527/24x24)
   */
  mauve3: "#232326",
  /**
   * Preview: ![mauve4](https://singlecolorimage.com/get/28282c/24x24)
   */
  mauve4: "#28282C",
  /**
   * Preview: ![mauve5](https://singlecolorimage.com/get/2e2e32/24x24)
   */
  mauve5: "#2E2E32",
  /**
   * Preview: ![mauve6](https://singlecolorimage.com/get/34343a/24x24)
   */
  mauve6: "#34343A",
  /**
   * Preview: ![mauve7](https://singlecolorimage.com/get/3e3e44/24x24)
   */
  mauve7: "#3E3E44",
  /**
   * Preview: ![light1](https://singlecolorimage.com/get/504F57/24x24)
   */
  light1: "#504F57",
  /**
   * Preview: ![light2](https://singlecolorimage.com/get/706F78/24x24)
   */
  light2: "#706F78",
  /**
   * Preview: ![light3](https://singlecolorimage.com/get/7E7D86/24x24)
   */
  light3: "#7E7D86",
  /**
   * Preview: ![light4](https://singlecolorimage.com/get/A09FA6/24x24)
   */
  light4: "#A09FA6",
  /**
   * Preview: ![light5](https://singlecolorimage.com/get/EDEDEF/24x24)
   */
  light5: "#EDEDEF",
  /**
   * Preview: ![plue1](https://singlecolorimage.com/get/5C66A7/24x24)
   */
  plue1: "#5C66A7",
  /**
   * Preview: ![plue2](https://singlecolorimage.com/get/6875CF/24x24)
   */
  plue2: "#6875CF",
};
