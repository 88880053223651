import { TextFieldChip as Field, TextFieldChipProps } from "@components";
import { FC } from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";

export const TextFieldChip: FC<TextFieldChipProps & { name: string }> = ({
  name,
  ...props
}) => {
  const { control } = useFormContext();
  const { errors } = useFormState();

  return (
    <Controller
      render={({ field: { value, onChange, onBlur } }) => {
        return (
          <Field
            {...props}
            onAdd={(one: string) => onChange([...value, one])}
            onDelete={(index: number) => {
              const next = [...value];
              next.splice(index, 1);
              onChange(next);
            }}
            onClear={() => onChange([])}
            options={value}
            onBlur={onBlur}
            error={!!errors[name]}
            helperText={errors[name]?.message as string}
          />
        );
      }}
      name={name}
      control={control}
    />
  );
};
