import { WorkspaceMember } from "@api";
import { DataAsList } from "@components/DataAsList";
import { useAppSelector } from "@storeRematch";
import { FC } from "react";

export const CreatedByAsList: FC<{
  onClick: (checked: boolean, model: WorkspaceMember) => void;
  localState?: Record<string, { checked: boolean; indeterminate: boolean }>;
}> = ({ localState, onClick }) => {
  const tableData = useAppSelector(state => state.members.data);

  return (
    <DataAsList
      localState={localState}
      tableData={tableData}
      getIdValue={t => (t.user ? t.user.id : "unknown")}
      getModel={t => t}
      getSearchValue={t => (t.user ? t.user.name : "unknown")}
      onClick={onClick}
    />
  );
};
