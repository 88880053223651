import { Image } from "@mui/icons-material";
import { Box, Slider, Typography } from "@mui/material";
import Cropper from "cropperjs";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";

export const AvatarBlob: FC<{ name: string }> = ({ name }) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const cropperRef = useRef<Cropper | null>(null);
  const zoomRef = useRef(0.1);
  const methods = useFormContext();
  const [url, setUrl] = useState<undefined | string>(undefined);

  const onDrop = useCallback(
    (acceptedFiles: Blob[]) => {
      acceptedFiles.forEach(file => {
        const reader = new FileReader();

        reader.onabort = () => console.error("file reading was aborted");
        reader.onerror = () => console.error("file reading has failed");
        reader.onload = () => {
          methods.setValue(name, reader.result);
          setUrl(reader.result as string);
        };
        reader.readAsDataURL(file);
      });
    },
    [methods, name],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "image/*": [],
    },
  });

  useEffect(() => {
    open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (url) {
    return (
      <>
        <Box sx={{ height: 250, width: 400, mb: 2 }}>
          <img
            id="profile-image-edit"
            src={url}
            ref={imgRef}
            alt="cropper"
            style={{ display: "none", height: 250, width: 200 }}
            onLoad={() => {
              if (!url) return;
              if (!imgRef.current) return;
              if (cropperRef.current) return;

              cropperRef.current = new Cropper(imgRef.current, {
                minCropBoxWidth: 200,
                minCropBoxHeight: 200,
                cropBoxResizable: false,
                guides: false,
                center: false,
                zoomOnTouch: false,
                zoomOnWheel: false,
                aspectRatio: 1 / 1,
                toggleDragModeOnDblclick: false,
                cropBoxMovable: false,
                dragMode: "move",
                background: false,
                crop: () => {
                  cropperRef.current
                    ?.getCroppedCanvas({
                      width: 200,
                      height: 200,
                      maxHeight: 200,
                      maxWidth: 200,
                    })
                    .toBlob(blob => {
                      methods.setValue(name, [blob], { shouldDirty: true });
                    });
                },
              });
            }}
            onError={() => {}}
          />
        </Box>

        <Box sx={{ display: "flex", width: 410, mx: "auto", alignItems: "center" }}>
          <Image fontSize="small" />
          <Slider
            min={0.1}
            max={0.9}
            step={0.1}
            size="small"
            sx={{ mx: 2.5 }}
            onChange={(_, a) => {
              const typed = a as number;

              cropperRef.current?.zoom(Math.round((typed - zoomRef.current) * 10) / 10);
              zoomRef.current = typed;
            }}
          />
          <Image fontSize="large" />
        </Box>
      </>
    );
  }

  return (
    <Box
      {...getRootProps()}
      sx={{
        py: 2,
        border: "1px dashed black",
        borderColor: "text.secondary",
        width: "100%",
        textAlign: "center",
      }}
    >
      <input {...getInputProps()} />
      <Typography color="text.secondary">
        Drag 'n' drop some files here, or click to select files
      </Typography>
      <Typography color="text.secondary">(Only 1 image file allowed)</Typography>
    </Box>
  );
};
