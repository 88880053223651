import { SvgIcon, SvgIconProps } from "@mui/material";

export const DrawPenAdd: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M15.7333 3.55556C16.0889 3.2 16.0889 2.66667 15.7333 2.31111L13.6889 0.266667C13.3333 -0.0888889 12.8 -0.0888889 12.4444 0.266667L10.8444 1.86667L14.2222 5.24444M0 12.6222L0 16H3.37778L13.1556 6.13333L9.86667 2.75556L0 12.6222Z"
        fill="currentColor"
      />
      <path d="M15 10H13V13H10V15H13V18H15V15H18V13H15V10Z" fill="currentColor" />
    </SvgIcon>
  );
};
