import { formatDurationInputTimeline } from "@components/videoPlayer/utils";
import { useAppDispatch, useAppSelector } from "@storeRematch/index";
import { useEffect, useLayoutEffect, useState } from "react";

type Times = Array<{ key: string; text: string; offset: number; ml: string }>;

interface Config {
  delta: number;
  numberOfSteps: number;
  index: number;
}

const timeWidth = 100;
let timeWidthToUse: number | null = null;
const splits = [
  36000, 18000, 14400, 10800, 7200, 3600, 2400, 1200, 900, 600, 300, 120, 60, 40, 30,
  20, 15, 10, 5, 2, 1,
];

export const VIDEO_TIMELINE_SCROLL_ROOT = "VideoTimeline_scroll_root";
export const VIDEO_POSITIONS_ROOT = "VideoPositions_root";

const cacheDefault: {
  times: Times;
  zoomCurrent: number | null;
  zoomWidth: string | null;
  config: Config | null;
  configIndex: number | null;
  windowWidth: number | null;
  url: string | null;
} = {
  zoomCurrent: null,
  zoomWidth: null,
  times: [],
  config: null,
  configIndex: null,
  windowWidth: null,
  url: null,
};
let cache = { ...cacheDefault };

export const useGetVideoTimes = () => {
  const enrichmentId = useAppSelector(state => state.projectEdit.currentEnrichment?.id);
  const zoomMax = useAppSelector(state => state.video.zoom.max);
  const zoomCurrent = useAppSelector(state => state.video.zoom.current);
  const zoomWidth = useAppSelector(state => state.video.zoom.css.width);
  const zoomTime = useAppSelector(state => state.video.zoom.time);
  const dragger = useAppSelector(
    state => state.dragger.table.size + state.dragger.tableProject.size,
  );
  const fullScreen = useAppSelector(state => state.video.fullScreen);
  const [times, setTimes] = useState<Times>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      cache = { ...cacheDefault };
    };
  }, []);

  useEffect(() => {
    const resize = () => {
      if (
        cache.url === document.location.pathname &&
        cache.zoomCurrent &&
        zoomCurrent === cache.zoomCurrent &&
        cache.zoomWidth &&
        zoomWidth === cache.zoomWidth &&
        window.innerWidth === cache.windowWidth
      ) {
        setTimes(cache.times);
        return;
      }

      cache.zoomCurrent =
        window.innerWidth !== cache.windowWidth ||
        cache.url !== document.location.pathname
          ? null
          : zoomCurrent;
      cache.zoomWidth = zoomWidth;
      cache.windowWidth = window.innerWidth;
      cache.url = document.location.pathname;

      const nextTimes: Times = [];
      const timeInSeconds = zoomTime.end;

      const { delta, numberOfSteps } = findSplit(timeInSeconds, cache.zoomCurrent);

      for (let i = 0; i < timeInSeconds + delta; i += delta) {
        const time = Math.min(i, timeInSeconds);
        const offset = (time / timeInSeconds) * 100;

        if (time % delta) break;

        const text = formatDurationInputTimeline(time);

        nextTimes.push({
          key: `${text}-${i}`,
          text,
          offset,
          ml:
            i === 0
              ? "0px"
              : `calc(${offset}% - ${
                  numberOfSteps === nextTimes.length - 1
                    ? timeWidthToUse
                    : (timeWidthToUse || 0) / 2
                }px)`,
        });
      }

      cache.times = nextTimes;

      setTimes(nextTimes);
    };

    resize();

    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [
    zoomWidth,
    zoomCurrent,
    zoomTime.end,
    zoomMax,
    dragger,
    fullScreen,
    enrichmentId,
  ]);

  useLayoutEffect(() => {
    setTimeout(() => {
      dispatch.video.setZoomCss(undefined);
    });
  }, [dragger, fullScreen, zoomCurrent, enrichmentId, dispatch]);

  return { times };
};

export function getNumberOfTimesConfigSize(timeInSeconds: number) {
  const { index } = findSplit(timeInSeconds);

  return { min: index, max: splits.length - 1 };
}

function findSplit(timeInSeconds: number, zoomCurrent?: number | null) {
  const width =
    document.getElementById(VIDEO_TIMELINE_SCROLL_ROOT)?.parentElement?.offsetWidth ||
    0;
  const data = {
    delta: 1,
    numberOfSteps: 0,
    index: splits.length - 1,
  };

  if (
    zoomCurrent !== null &&
    zoomCurrent !== undefined &&
    splits[zoomCurrent] &&
    timeWidthToUse
  ) {
    data.delta = splits[zoomCurrent];
    data.index = zoomCurrent;
  } else {
    for (let i = 0; i < splits.length; i++) {
      if (
        (timeInSeconds / splits[i]) * timeWidth < width &&
        (splits[i + 1] === undefined ||
          !((timeInSeconds / splits[i + 1]) * timeWidth < width))
      ) {
        data.delta = splits[i];
        data.index = i;

        timeWidthToUse = width / (timeInSeconds / data.delta);

        break;
      }
    }
  }

  data.numberOfSteps = Math.ceil((timeInSeconds + data.delta) / data.delta) - 1;

  cache.config = data;

  return data;
}

export function getTimesSplit() {
  return {
    delta: cache.config?.delta || 0,
    steps: cache.config?.numberOfSteps || 0,
    timeWidth: timeWidthToUse || 0,
  };
}

export function resetTimeWidthToUse() {
  timeWidthToUse = null;
}
