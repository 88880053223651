import { ProjectEnrichment, VisualizationTypes } from "@api";
import { EntityDataTable, NewProjectModelDialogMainButton } from "@components";
import { NoVisualizationsData } from "@components/table";
import {
  VisualizationsAOI,
  VisualizationsHeatmap,
  VisualizationsVideo,
} from "@customIcons";
import { Box, Divider, Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { VisualizationsTableData, useAppDispatch, useAppSelector } from "@storeRematch";
import {
  LocalStorageAdapterNames,
  formatReadableEnrichment,
  getEnrichmentStatusTextFull,
  useGetEnrichmentStatusIcon,
} from "@utils";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import TimeAgo from "timeago-react";
import { ProjectVisualizationsContextMenu } from "./ProjectVisualizationsContextMenu";

const columns: GridColDef<VisualizationsTableData>[] = [
  { field: "id", headerName: "ID" },
  {
    field: "name",
    width: 240,
    hideable: false,
    headerName: "Visualization Name",
    valueGetter: (_, row) => row.visualization.name,
    renderCell: params => {
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              width: 65,
              height: 45,
              minWidth: 65,
              minHeight: 45,
              backgroundColor: "#2C3232",
              mr: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 1,
            }}
          >
            {params.row.visualization.kind === VisualizationTypes.RENDER ? (
              <VisualizationsVideo />
            ) : params.row.visualization.kind === VisualizationTypes.HEATMAP ? (
              <VisualizationsHeatmap />
            ) : (
              <VisualizationsAOI />
            )}
          </Box>
          <Typography noWrap variant="body2">
            {params.row.visualization.name}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "type",
    width: 110,
    hideable: false,
    headerName: "Type",
    valueGetter: (_, row) => {
      return formatReadableEnrichment(
        row.visualization.kind === VisualizationTypes.RENDER
          ? "render"
          : row.visualization.kind === VisualizationTypes.HEATMAP
          ? "heatmap"
          : "aoi",
      );
    },
  },
  {
    field: "enrichment_type",
    width: 170,
    headerName: "Enrichment Type",
    valueGetter: (_, row) => {
      return row.enrichment ? formatReadableEnrichment(row.enrichment.kind) : "";
    },
  },
  {
    field: "enrichment",
    width: 300,
    headerName: "Enrichment",
    valueGetter: (_, row) => {
      return row.enrichment?.name;
    },
  },
  {
    field: "created_at",
    width: 110,
    hideable: false,
    type: "date",
    headerName: "Created",
    valueGetter: (_, row) =>
      row.visualization.created_at ? new Date(row.visualization.created_at) : undefined,
    renderCell: params => {
      return params.row.visualization.created_at ? (
        <TimeAgo datetime={params.row.visualization.created_at} />
      ) : null;
    },
  },
  {
    field: "status",
    width: 150,
    hideable: false,
    type: "string",
    filterable: false,
    headerName: "Status",
    renderCell: params => {
      if (!params.row.enrichment) return "Not selected";

      return <StatusCell model={params.row.enrichment} />;
    },
  },
];

const StatusCell = memo(({ model }: { model: ProjectEnrichment }) => {
  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%", alignItems: "center" }}>
      {useGetEnrichmentStatusIcon(model, true)}
      <Typography variant="body2" sx={{ ml: 1 }}>
        {getEnrichmentStatusTextFull(model)}
      </Typography>
    </Box>
  );
});

export const ProjectVisualizations: React.FC = () => {
  const navigate = useNavigate();
  const loading = useAppSelector(
    state =>
      state.loading.models.projects.loading ||
      state.loading.models.app.loading ||
      state.loading.models.enrichments.loading ||
      state.loading.models.visualizations.loading,
  );
  const rows = useAppSelector(state => state.visualizations.tableData);
  const dispatch = useAppDispatch();

  return (
    <Box sx={{ display: "flex", width: "100%", flexGrow: 1 }}>
      <EntityDataTable
        rows={rows}
        columns={columns}
        hideFooter
        loading={loading}
        noDataMessage={<NoVisualizationsData />}
        onRowDoubleClick={(props: GridRowParams<VisualizationsTableData>) => {
          dispatch.visualizations.resetAoiIdsToAutoAdd();
          const prefix =
            props.row.visualization.kind === VisualizationTypes.RENDER
              ? "render"
              : props.row.visualization.kind === VisualizationTypes.HEATMAP
              ? "heatmap"
              : "aoi";

          navigate(`./${prefix}/${props.row.id}`);
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        tableColumnInitialStateStorageKey={
          LocalStorageAdapterNames.projectVisualizationsColumState
        }
        childrenLeftFilter
        toolbarPadding
      >
        <NewProjectModelDialogMainButton main />
        <Divider orientation="vertical" sx={{ mx: 1, height: 26 }} />
      </EntityDataTable>
      <ProjectVisualizationsContextMenu />
    </Box>
  );
};
