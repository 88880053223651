import { SearchBox } from "@components/Common/SearchBox";
import { OnlyCheckBox } from "@components/OnlyCheckBox";
import { CtxMenuItem, CtxMenuLabel, CtxNestedMenu } from "@components/ctxMenu";
import { INACTIVE_FIELD_NAME } from "@constants";
import { MoreVert } from "@mui/icons-material";
import { Divider, MenuList } from "@mui/material";
import { Box } from "@mui/system";
import {
  GridColumnVisibilityModel,
  gridColumnVisibilityModelSelector,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { ContextMenuTypes, useAppDispatch } from "@storeRematch";
import {
  LocalStorageAdapterNames,
  localStorageAdapter,
  sanitizeForRegex,
} from "@utils";
import { ChangeEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterPopover } from "./FilterPopover";
import { TrashFilter } from "./TrashFilter";

export const ColumnsFilter = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const apiRef = useGridApiContext();
  const columnsState = gridColumnVisibilityModelSelector(apiRef);
  const columns = useMemo(() => {
    return Object.values(apiRef.current.getAllColumns()).reduce((acc, v) => {
      acc[v.field] = v;

      if (columnsState[v.field] === undefined) columnsState[v.field] = true;

      return acc;
    }, {} as any);
  }, [apiRef, columnsState]);
  const columnsStateArr = Object.keys(columns);
  const [foundBySearch, setFoundBySearch] = useState(new Set<string>(columnsStateArr));
  const filteredColumns = columnsStateArr.filter(key =>
    key === INACTIVE_FIELD_NAME ? false : foundBySearch.has(key),
  );

  const onClick = (name: string, isVisible: boolean) => () => {
    apiRef.current.setColumnVisibility(name, isVisible);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) {
      setFoundBySearch(new Set(columnsStateArr));
      return;
    }

    const nextFound = Object.values(columns).filter((data: any) =>
      data.headerName.match(new RegExp(sanitizeForRegex(e.currentTarget.value), "i")),
    );

    if (!nextFound.length) {
      setFoundBySearch(new Set());
      return;
    }

    setFoundBySearch(new Set(nextFound.map((d: any) => d.field)));
  };

  const handleReset = () => {
    const initState = localStorageAdapter.get(
      LocalStorageAdapterNames.tableVisibilityStateDefault,
    );

    if (!initState) return;

    Object.keys(initState).map(key =>
      apiRef.current.setColumnVisibility(
        key,
        (initState as GridColumnVisibilityModel)[key],
      ),
    );
    dispatch.ctxMenu.close(ContextMenuTypes.FILTER_COLUMN);
  };

  return (
    <Box mr={1}>
      <FilterPopover
        sx={{
          minWidth: 0,
          px: 0.5,

          "& .MuiButton-startIcon, & .MuiButton-endIcon": {
            m: 0,
          },
        }}
        startIcon={<MoreVert />}
        filterType={ContextMenuTypes.FILTER_COLUMN}
      >
        <MenuList>
          {!document.URL.includes("settings") && (
            <CtxNestedMenu
              CtxMenuItemProps={{ label: t("Manage columns") }}
              onClose={() => setFoundBySearch(new Set(columnsStateArr))}
            >
              <MenuList>
                <CtxMenuLabel>
                  <SearchBox
                    sx={{ width: "250px" }}
                    autoFocus
                    name="Search"
                    onChange={handleSearch}
                    onKeyDown={e => e.stopPropagation()}
                  />
                </CtxMenuLabel>
                {filteredColumns.map(key => {
                  return (
                    <CtxMenuItem
                      key={key}
                      label={columns[key].headerName}
                      onClick={onClick(key, !columnsState[key])}
                      icon={<OnlyCheckBox checked={columnsState[key]} />}
                      disabled={columns[key].hideable === false}
                    />
                  );
                })}

                {!filteredColumns.length && (
                  <CtxMenuItem disabled label="Nothing found" />
                )}

                <Divider />
                <CtxMenuItem
                  label={t("Reset to default columns")}
                  onClick={handleReset}
                />
              </MenuList>
            </CtxNestedMenu>
          )}
          <TrashFilter />
        </MenuList>
      </FilterPopover>
    </Box>
  );
};
