export enum QuestionDialogTypes {
  TEMPLATE_FORM_PUBLISH,
  TEMPLATE_FORM_CHANGE_WIDGET_TYPE,
  RECORDING_TRASH,
  RECORDING_RESTORE,
  RECORDING_DELETE,
  PROJECTS_DELETE,
  LABELS_DELETE,
  TEMPLATES_RESTORE,
  TEMPLATES_DELETE,
  WEARERS_RESTORE,
  WEARERS_DELETE,
  CONFIRM_DELETE,
  EVENT_DELETE,
  MEMBERS_DELETE,
  TOKEN_DELETE,
  ENRICHMENT_DELETE,
  VISUALIZATION_DELETE,
  AOI_DELETE,
  AOIS_DELETE,
  DRAW_AREA_CLEAR,
  TEMPLATE_FORM_UNSAVED,
  CLEAR_ALL_RECORDING_MAPPINGS,
  GAZE_OFFSET_CHANGE,
}

interface QuestionDialogPropTypesGenerator<T extends QuestionDialogTypes, E = null> {
  type: T;
  onSuccess: Function;
  extra?: E;
}

type QuestionDialogCounter<T extends QuestionDialogTypes> =
  QuestionDialogPropTypesGenerator<T, QuestionDialogCount>;

export type QuestionDialogTemplateFormChangeWidgetExtra = { widgetTypeTo: string };
export type QuestionDialogCount = { count: number };
export type QuestionDialogConfirmDelete = { title: string };
export type QuestionDialogNames = { names: string[] };
export type QuestionDialogRunEnrichmentExtra = {
  seconds: number;
  readableFormat: string;
  fromName: string;
  toName: string;
};

export type QuestionDialogPropTypes =
  | QuestionDialogPropTypesGenerator<QuestionDialogTypes.TEMPLATE_FORM_PUBLISH>
  | QuestionDialogPropTypesGenerator<QuestionDialogTypes.TEMPLATE_FORM_UNSAVED>
  | QuestionDialogPropTypesGenerator<
      QuestionDialogTypes.TEMPLATE_FORM_CHANGE_WIDGET_TYPE,
      QuestionDialogTemplateFormChangeWidgetExtra
    >
  | QuestionDialogCounter<QuestionDialogTypes.RECORDING_RESTORE>
  | QuestionDialogCounter<QuestionDialogTypes.RECORDING_TRASH>
  | QuestionDialogCounter<QuestionDialogTypes.RECORDING_DELETE>
  | QuestionDialogCounter<QuestionDialogTypes.PROJECTS_DELETE>
  | QuestionDialogCounter<QuestionDialogTypes.LABELS_DELETE>
  | QuestionDialogCounter<QuestionDialogTypes.TEMPLATES_RESTORE>
  | QuestionDialogCounter<QuestionDialogTypes.TEMPLATES_DELETE>
  | QuestionDialogCounter<QuestionDialogTypes.WEARERS_RESTORE>
  | QuestionDialogCounter<QuestionDialogTypes.WEARERS_DELETE>
  | QuestionDialogCounter<QuestionDialogTypes.VISUALIZATION_DELETE>
  | QuestionDialogPropTypesGenerator<QuestionDialogTypes.EVENT_DELETE>
  | QuestionDialogPropTypesGenerator<
      QuestionDialogTypes.CONFIRM_DELETE,
      QuestionDialogConfirmDelete
    >
  | QuestionDialogPropTypesGenerator<
      QuestionDialogTypes.MEMBERS_DELETE,
      QuestionDialogNames
    >
  | QuestionDialogCounter<QuestionDialogTypes.TOKEN_DELETE>
  | QuestionDialogPropTypesGenerator<
      QuestionDialogTypes.ENRICHMENT_DELETE,
      QuestionDialogConfirmDelete | QuestionDialogCount
    >
  | QuestionDialogPropTypesGenerator<
      QuestionDialogTypes.AOI_DELETE,
      QuestionDialogConfirmDelete
    >
  | QuestionDialogPropTypesGenerator<
      QuestionDialogTypes.AOIS_DELETE,
      QuestionDialogCount
    >
  | QuestionDialogCounter<QuestionDialogTypes.DRAW_AREA_CLEAR>
  | QuestionDialogCounter<QuestionDialogTypes.CLEAR_ALL_RECORDING_MAPPINGS>
  | QuestionDialogPropTypesGenerator<QuestionDialogTypes.GAZE_OFFSET_CHANGE>;
