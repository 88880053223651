import {
  EnrichmentTypesEnum,
  FaceEnrichmentLocalization,
  MarkerMapperEnrichmentLocalization,
  MarkerlessEnrichmentLocalization,
  SurfaceEnrichmentLocalization,
  api,
} from "@api";
import { createModel } from "@rematch/core";
import { markerMapperLocalizationSlicer } from "@utils";
import type { RootModel } from "./index";

interface InitialState {
  current: {
    workspaceId: string;
    recordingId: string;
    duration: number;
    surfaceId?: string;
    markerlessId?: string;
  } | null;
  face: FaceEnrichmentLocalization[];
  surface: SurfaceEnrichmentLocalization[];
  markerless: MarkerlessEnrichmentLocalization[];
  markerMapper: MarkerMapperEnrichmentLocalization[];
}

const initState: InitialState = {
  current: null,
  face: [],
  surface: [],
  markerless: [],
  markerMapper: [],
};

export const localization = createModel<RootModel>()({
  state: initState,
  reducers: {
    setCurrent(state, data: InitialState["current"]) {
      state.current = data;
    },
    setFace(state, data: InitialState["face"]) {
      state.face = data;
    },
    setSurface(state, data: InitialState["surface"]) {
      state.surface = data;
    },
    setMarkerless(state, data: InitialState["markerless"]) {
      state.markerless = data;
    },
    setMarkerMapper(state, data: InitialState["markerMapper"]) {
      state.markerMapper = data;
    },
    reset() {
      return { ...initState };
    },
  },
  effects: dispatch => ({
    async get(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const recordingId = state.video.recording?.id;
      const current = state.localization.current;
      const enrichment = state.projectEdit.currentEnrichment;
      const duration = state.video.instance?.duration();

      if (!workspaceId || !recordingId || !duration) return;

      // need for the frame size
      const intervalSizeBase = duration / 500;
      const intervalSize =
        intervalSizeBase < 0.1 ? 0.1 : intervalSizeBase > 4 ? 4 : intervalSizeBase;

      try {
        if (
          enrichment?.kind === EnrichmentTypesEnum.MARKER_MAPPER &&
          enrichment.args?.surface_id &&
          (!current ||
            current.workspaceId !== workspaceId ||
            current.recordingId !== recordingId ||
            current.duration !== duration ||
            current.surfaceId !== enrichment.args?.surface_id)
        ) {
          const surfaceId = enrichment.args.surface_id;

          dispatch.localization.setCurrent({
            workspaceId,
            recordingId,
            duration,
            surfaceId,
          });

          markerMapperLocalizationSlicer
            .init(duration)
            .get({ workspaceId, recordingId, surfaceId, time: 0 });

          const data = await api.getSurfaceEnrichmentLocalization({
            workspaceId,
            recordingId,
            surfaceId,
            start: 0,
            end: duration,
            //intervalSize,
          });

          dispatch.localization.setSurface(data.result);
          return;
        }

        if (
          enrichment?.kind === EnrichmentTypesEnum.SLAM_MAPPER &&
          enrichment.args?.markerless_id &&
          (!current ||
            current.workspaceId !== workspaceId ||
            current.recordingId !== recordingId ||
            current.duration !== duration ||
            current.markerlessId !== enrichment.args?.markerless_id)
        ) {
          const markerlessId = enrichment.args.markerless_id;

          dispatch.localization.setCurrent({
            workspaceId,
            recordingId,
            duration,
            markerlessId,
          });

          const data = await api.getMarkerlessEnrichmentLocalization({
            workspaceId,
            recordingId,
            start: 0,
            end: duration,
            //intervalSize,
            markerlessId,
          });

          dispatch.localization.setMarkerless(data.result);
          return;
        }

        if (
          enrichment?.kind === EnrichmentTypesEnum.FACE_MAPPER &&
          (!current ||
            current.workspaceId !== workspaceId ||
            current.recordingId !== recordingId ||
            current.duration !== duration)
        ) {
          dispatch.localization.setCurrent({ workspaceId, recordingId, duration });

          const data = await api.getFaceEnrichmentLocalization({
            workspaceId,
            recordingId,
            start: 0,
            end: duration,
            intervalSize,
          });

          dispatch.localization.setFace(data.result);
          return;
        }
      } catch (error) {
        console.error(error);
      }
    },
    prepareMarkerMapper(_, state) {
      const fixations = state.staticImageMappers.fixations;
      const markerMapper: MarkerMapperEnrichmentLocalization[] = [];

      for (const one of fixations) {
        if (one.start_timestamp && one.end_timestamp)
          markerMapper.push({
            start_timestamp: one.start_timestamp,
            end_timestamp: one.end_timestamp,
            hasCoordinates: one.x !== null && one.y !== null,
            onRefImg: one.gaze_on_aoi,
            markerMapper: true,
          });
      }

      dispatch.localization.setMarkerMapper(markerMapper);
    },
  }),
});
