import { SvgIcon, SvgIconProps } from "@mui/material";

export const Move: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <g clipPath="url(#clip0_5329_127896)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.72342 0.496252L3.85984 2.35984C3.71339 2.50628 3.71339 2.74372 3.85984 2.89016C4.00628 3.03661 4.24372 3.03661 4.39016 2.89016L5.62457 1.65576L5.62475 5.625H1.65533L2.89016 4.39016C3.03661 4.24372 3.03661 4.00628 2.89016 3.85984C2.74372 3.71339 2.50628 3.71339 2.35984 3.85984L0.484835 5.73484C0.338388 5.88128 0.338388 6.11872 0.484835 6.26516L2.35984 8.14017C2.50628 8.28661 2.74372 8.28661 2.89016 8.14017C3.03661 7.99372 3.03661 7.75628 2.89016 7.60983L1.65533 6.375H5.62478L5.62496 10.3446L4.39016 9.10983C4.24372 8.96339 4.00628 8.96339 3.85984 9.10983C3.71339 9.25628 3.71339 9.49372 3.85984 9.64017L5.73484 11.5152C5.88128 11.6616 6.11872 11.6616 6.26516 11.5152L8.14017 9.64017C8.28661 9.49372 8.28661 9.25628 8.14017 9.10983C7.99372 8.96339 7.75628 8.96339 7.60983 9.10983L6.37496 10.3447L6.37478 6.375H10.3447L9.10983 7.60983C8.96339 7.75628 8.96339 7.99372 9.10983 8.14017C9.25628 8.28661 9.49372 8.28661 9.64017 8.14017L11.5152 6.26516C11.6616 6.11872 11.6616 5.88128 11.5152 5.73484L9.64017 3.85984C9.49372 3.71339 9.25628 3.71339 9.10983 3.85984C8.96339 4.00628 8.96339 4.24372 9.10983 4.39016L10.3447 5.625H6.37475L6.37457 1.6549L7.60983 2.89016C7.75628 3.03661 7.99372 3.03661 8.14017 2.89016C8.28661 2.74372 8.28661 2.50628 8.14017 2.35984L6.26516 0.484835C6.19188 0.411551 6.09581 0.37494 5.99976 0.375C5.99968 0.375 5.9996 0.375 5.99951 0.375C5.89028 0.375005 5.79195 0.421716 5.72342 0.496252Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5329_127896">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
