import { SvgIcon, SvgIconProps } from "@mui/material";

export const VideoBackgroundMove: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="15"
      height="18"
      viewBox="0 0 15 18"
      {...props}
      sx={{ ...props.sx, fill: "none" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 0.75H6.5V2.75H7V17.2982C7 17.6858 7.22386 18 7.5 18C7.77614 18 8 17.6858 8 17.2982V2.75H8.5V0.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3H2C0.895431 3 0 3.89543 0 5V14C0 15.1046 0.89543 16 2 16H6V14.75H2C1.58579 14.75 1.25 14.4142 1.25 14V5C1.25 4.58579 1.58579 4.25 2 4.25H6V3ZM9 14.75H13C13.4142 14.75 13.75 14.4142 13.75 14V5C13.75 4.58579 13.4142 4.25 13 4.25H9V3H13C14.1046 3 15 3.89543 15 5V14C15 15.1046 14.1046 16 13 16H9V14.75Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
