import { Template, TemplateItem } from "@api";
import { createModel } from "@rematch/core";
import { onDragEnd } from "@utils/dragAndDrop";
import { formatTemplateItemWidgetType } from "@utils/formatTemplateItemWidgetType";
import { formatShortUUID } from "@utils/formatUUID";
import { v4 as uuidv4 } from "uuid";
import { QuestionDialogTypes, type RootModel } from "./index";

interface Draft {
  name: string;
  description: string;
  nameFormat: string[];
  availableFormat: string[];
  items: TemplateItem[];
  published: boolean;
}

interface InitialState {
  current: Template | null;
  draft: Draft;
  showPreview: Template | Draft | null;
  unsaved: boolean;
}

const initAvailableFormat = [
  "{template_name}",
  "{wearer_name}",
  "{date}",
  "{time}",
  "{uuid}",
];

const initialDraft: Draft = {
  name: "",
  description: "",
  nameFormat: [],
  availableFormat: [...initAvailableFormat],
  items: [],
  published: false,
};

const initialState: InitialState = {
  current: null,
  draft: { ...initialDraft },
  showPreview: null,
  unsaved: false,
};

export const templateForm = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setCurrent(state, data: Template | null) {
      state.draft = data
        ? {
            name: data.name,
            description: data.description || "",
            items: data.items || [],
            nameFormat: data.recording_name_format || [],
            availableFormat: [...initAvailableFormat],
            published: Boolean(data.published_at),
          }
        : { ...initialDraft };

      state.current = data;
    },
    addNameFormat(state, data: string) {
      state.draft.nameFormat.push(data);
    },
    removeNameFormat(state, index: number) {
      state.draft.nameFormat.splice(index, 1);
    },
    clearNameFormat(state) {
      state.draft.nameFormat = [];
    },
    setDescription(state, data: string) {
      state.draft.description = data;
    },
    setTitle(state, { index, data }: { index: number; data: string }) {
      state.draft.items[index].title = data;
    },
    setHelperText(state, { index, data }: { index: number; data: string }) {
      state.draft.items[index].help_text = data;
    },
    setChoice(
      state,
      {
        indexItem,
        indexChoice,
        data,
      }: { indexItem: number; indexChoice: number; data: string },
    ) {
      const choices = state.draft.items[indexItem].choices;

      if (choices) {
        choices[indexChoice] = data;
      }
    },
    addChoice(state, { index, data }: { index: number; data: string }) {
      const item = state.draft.items[index];

      if (item.choices !== undefined) {
        item.choices.push(data);
      } else {
        item.choices = [data];
      }
    },
    deleteChoice(
      state,
      { indexItem, indexChoice }: { indexItem: number; indexChoice: number },
    ) {
      state.draft.items[indexItem]?.choices?.splice(indexChoice, 1);
    },
    setRequired(state, { index, data }: { index: number; data: boolean }) {
      const item = state.draft.items[index];

      if (item) {
        item.required = data;
      }
    },
    deleteSectionByIndex(state, data: number) {
      const item = state.draft.items[data];
      updateAvailableFormat(state, item);
      state.draft.items.splice(data, 1);
    },
    copySectionByIndex(
      state,
      { indexAddAfter, indexCopy }: { indexAddAfter: number; indexCopy: number },
    ) {
      const next = {
        ...state.draft.items[indexCopy],
        id: uuidv4(),
      };

      state.draft.items.splice(indexAddAfter, 0, next);

      updateAvailableFormat(state, next);
    },
    createSection(
      state,
      {
        index,
        partial,
      }: { index?: number; partial: Partial<Omit<TemplateItem, "id">> },
    ) {
      const next: TemplateItem = {
        id: uuidv4(),
        choices: [],
        help_text: "",
        input_type: "any",
        required: false,
        title: "",
        widget_type: "SECTION_HEADER",
        ...partial,
      };

      if (index === undefined) {
        state.draft.items.push(next);
      } else {
        state.draft.items.splice(index + 1, 0, next);
      }

      updateAvailableFormat(state, next);
    },
    updateSection(
      state,
      { index, partial }: { index: number; partial: Partial<Omit<TemplateItem, "id">> },
    ) {
      const item = state.draft.items[index];
      const shouldUpdate =
        partial.widget_type &&
        ((item.widget_type === "TEXT" && partial.widget_type !== "TEXT") ||
          (item.widget_type !== "TEXT" && partial.widget_type === "TEXT"));

      state.draft.items[index] = { ...state.draft.items[index], ...partial };

      if (shouldUpdate) {
        updateAvailableFormat(state, state.draft.items[index]);
      }
    },
    setTemplateName(state, data: string) {
      state.draft.name = data;
    },
    onDragEnd: onDragEnd(state => state.draft.items),
    setShowPreview(state, item?: InitialState["showPreview"]) {
      state.showPreview = item === undefined ? state.draft : item;
    },
    setUnsaved(state, data: InitialState["unsaved"]) {
      state.unsaved = data;
    },
  },
  effects: dispatch => ({
    tryToChangeWidgetType(
      {
        id,
        index,
        type,
      }: { id: string; index: number; type: TemplateItem["widget_type"] },
      state,
    ) {
      const findName = createNameFormatString(id);
      const cb = () =>
        dispatch.templateForm.updateSection({
          index,
          partial: { widget_type: type },
        });

      if (state.templateForm.draft.nameFormat.some(n => n === findName)) {
        dispatch.questionDialog.set({
          type: QuestionDialogTypes.TEMPLATE_FORM_CHANGE_WIDGET_TYPE,
          onSuccess: cb,
          extra: { widgetTypeTo: formatTemplateItemWidgetType(type) },
        });
      } else {
        cb();
      }
    },
  }),
});

// helper function

function createNameFormatString(id: string) {
  return `{answer_from_${formatShortUUID(id)}}`;
}

function updateAvailableFormat(state: InitialState, item: TemplateItem) {
  const name = createNameFormatString(item.id);
  const index = state.draft.availableFormat.findIndex(n => n === name);

  if (index !== -1) {
    state.draft.availableFormat.splice(index, 1);
    state.draft.nameFormat = state.draft.nameFormat.filter(n => n !== name);
  } else if (item.widget_type === "TEXT") {
    state.draft.availableFormat.push(name);
  }
}
