import { ContextMenu, CtxMenuItem } from "@components/ctxMenu";
import { IconButton } from "@mui/material";
import { ContextMenuTypes, useAppDispatch, useAppSelector } from "@storeRematch";

import { localStorageAdapter, LocalStorageAdapterNames } from "@utils";
import { useEffect, useState } from "react";

const DEFAULT_SPEED = 1;
const getDefault = () => {
  return (
    localStorageAdapter.get<number | undefined>(LocalStorageAdapterNames.videoSpeed) ??
    DEFAULT_SPEED
  );
};

const PLAYBACK_RATES = [0.25, 0.5, 1, 2, 4];

export const VideoSpeed = () => {
  const dispatch = useAppDispatch();
  const instance = useAppSelector(state => state.video.instance);
  const [value, setValue] = useState(getDefault());

  useEffect(() => {
    if (!instance) return;

    const update = () => {
      instance.playbackRate(getDefault());
    };

    instance.on("loadeddata", update);

    return () => {
      instance.off("loadeddata", update);
    };
  }, [instance]);

  useEffect(() => {
    if (!instance || instance.isDisposed() || instance.playbackRate() === value) return;

    instance.playbackRate(value);
    localStorageAdapter.set(LocalStorageAdapterNames.videoSpeed, value);
  }, [value, instance]);

  return (
    <>
      <IconButton
        sx={{ fontSize: "14px", minWidth: "40px" }}
        onClick={e =>
          dispatch.ctxMenu.handleClick({
            e,
            type: ContextMenuTypes.VIDEO_SPEED,
            position: true,
          })
        }
      >
        {value}x
      </IconButton>
      <ContextMenu minWidth={80} type={ContextMenuTypes.VIDEO_SPEED}>
        {PLAYBACK_RATES.map(r => (
          <CtxMenuItem
            key={r}
            label={`${r}x`}
            onClick={() => {
              setValue(r);
              dispatch.ctxMenu.close(ContextMenuTypes.VIDEO_SPEED);
            }}
          />
        ))}
      </ContextMenu>
    </>
  );
};
