import { ContextMenu, CtxMenuItem } from "@components";
import { MenuList } from "@mui/material";
import { RouterHelper } from "@pages";
import {
  ContextMenuTypes,
  QuestionDialogTypes,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import { DisableByPermissionsType, disabledByPermission } from "@utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const MembersContextMenu: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selected = useAppSelector(state => state.entityTable.selectedIds);
  const dataByIdMembers = useAppSelector(state => state.members.dataById);
  const dataByIdInvites = useAppSelector(state => state.invites.dataById);
  const numSelected = selected.length;
  const disabled = disabledByPermission({
    type: DisableByPermissionsType.DELETE_MEMBERS,
    ids: selected,
  });
  const currentUserId = useAppSelector(state => state.app.apiUser?.id);
  const defaultWorkspaceId = useAppSelector(
    state => state.app.apiUser?.default_workspace_id,
  );
  const currentRole = useAppSelector(
    state => state.app.currentWorkspaceMembership?.role,
  );

  const onClose = () => {
    dispatch.ctxMenu.closeAll();
  };

  return (
    <ContextMenu type={ContextMenuTypes.RECORD_TABLE}>
      <MenuList>
        <CtxMenuItem
          label={
            numSelected === 1
              ? t("Delete Member")
              : t("Delete {{numSelected}} Members", { numSelected })
          }
          onClick={() => {
            onClose();
            dispatch.questionDialog.set({
              type: QuestionDialogTypes.MEMBERS_DELETE,
              onSuccess: async () => {
                await dispatch.members.removeMembers(selected);

                if (
                  numSelected === 1 &&
                  selected[0] === currentUserId &&
                  defaultWorkspaceId
                ) {
                  navigate(
                    RouterHelper.workspaceRecordings.getFullPath({
                      prefix: "/",
                      props: { workspaceId: defaultWorkspaceId },
                    }),
                    { replace: true },
                  );
                }
              },
              extra: {
                names: selected.map(
                  id =>
                    dataByIdMembers.get(id)?.user?.email ||
                    dataByIdInvites.get(id)?.email ||
                    "Unknown",
                ),
              },
            });
          }}
          disabled={
            numSelected === 1 &&
            selected[0] === currentUserId &&
            currentRole !== "OWNER"
              ? false
              : disabled
          }
          tooltip={t("Disabled by permissions")}
        />
      </MenuList>
    </ContextMenu>
  );
};
