import { TextField as MuiTextField, TextFieldProps } from "@mui/material";
import { FC } from "react";
import { useFormContext, useFormState } from "react-hook-form";

export const TextField: FC<TextFieldProps & { name: string }> = ({
  name,
  ...props
}) => {
  const methods = useFormContext();
  const { errors } = useFormState();

  return (
    <MuiTextField
      {...props}
      {...methods.register(name)}
      error={!!errors[name]}
      helperText={errors[name]?.message as string}
    />
  );
};
