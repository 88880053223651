import { Workspace, WorkspaceMembership } from "@api";
import { createModel } from "@rematch/core";
import { RematchAdapter, rematchAdapter } from "@utils/rematchAdapter";
import type { RootModel } from "./index";

interface Adapter extends RematchAdapter<WorkspaceMembership> {}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.workspace.id,
  updatedAtSelector: m => m.workspace.updated_at as string,
  extraProps: {},
});

export const workspaceMemberships = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
  },
  effects: dispatch => ({
    prepare() {},
    wsWorkspace(data: Workspace, state): void {
      const workspace = state.workspaceMemberships.dataById.get(data.id);

      if (workspace) {
        if (data.deleted_at !== null) {
          dispatch.workspaceMemberships.setAll(
            state.workspaceMemberships.data.filter(o => o.workspace.id !== data.id),
          );
          return;
        }

        const next = { ...workspace, workspace: data };

        dispatch.workspaceMemberships.upsertOne(next);
        if (state.app.currentWorkspaceMembership?.workspace.id !== data.id) return;

        dispatch.app.setCurrentWorkspaceMembership(next);
      } else {
        dispatch.workspaceMemberships.upsertOne({ role: "OWNER", workspace: data });
      }
    },
  }),
});
