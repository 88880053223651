import { forwardRef } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from "react-router-dom";

/**
 * Use instead of `import { Link } from "react-router-dom"`
 * so that we can automatically get previous location
 * for any "BackButton" logic
 */
export const ReactRouterLinkCustom = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => {
    const location = useLocation();

    return <RouterLink ref={ref} state={{ previous: location }} {...props} />;
  },
);
