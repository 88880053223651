import { SearchBox } from "@components/Common/SearchBox";
import { CtxMenuItem, CtxMenuLabel } from "@components/ctxMenu";
import { OnlyCheckBox } from "@components/OnlyCheckBox";
import { VirtualizedList } from "@components/VirtualizedList";
import { MenuList } from "@mui/material";
import { Box } from "@mui/system";
import { useAppDispatch } from "@storeRematch";
import { sanitizeForRegex } from "@utils";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export function DataAsList<T, M>({
  onClick,
  localState,
  tableData,
  getSearchValue,
  getIdValue,
  getModel,
  onNothingFound,
  onEnter,
}: {
  onClick: (checked: boolean, model: M) => void;
  localState?: Record<string, { checked: boolean; indeterminate: boolean }>;
  tableData: T[];
  getSearchValue: (model: T) => string;
  getIdValue: (model: T) => string;
  getModel: (model: T) => M;
  onNothingFound?: (search: string | null) => void;
  onEnter?: (search: string) => void;
}) {
  const lastSearch = useRef("");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [found, setFound] = useState(tableData);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const nextFound = tableData.filter(data =>
      getSearchValue(data).match(
        new RegExp(sanitizeForRegex(e.currentTarget.value), "i"),
      ),
    );

    if (onNothingFound) {
      const notFound = !tableData.filter(
        data => getSearchValue(data) === e.currentTarget.value,
      ).length;
      onNothingFound(notFound && e.currentTarget.value ? e.currentTarget.value : null);
    }

    lastSearch.current = e.currentTarget.value;
    setFound(nextFound);
  };

  useEffect(() => {
    setFound(
      lastSearch.current
        ? tableData.filter(data =>
            getSearchValue(data).match(
              new RegExp(sanitizeForRegex(lastSearch.current), "i"),
            ),
          )
        : tableData,
    );
  }, [tableData, setFound, getSearchValue]);

  return (
    <MenuList>
      <CtxMenuLabel>
        <SearchBox
          sx={{ width: "250px" }}
          autoFocus
          name="Search"
          onChange={handleSearch}
          onKeyDown={e => {
            if (e.key === "Enter") {
              const notFound = !tableData.filter(
                data => getSearchValue(data) === e.currentTarget.value,
              ).length;

              if (notFound && e.currentTarget.value) onEnter?.(e.currentTarget.value);
            }
            e.stopPropagation();
          }}
          onClear={() => setFound(tableData)}
        />
      </CtxMenuLabel>

      {!found.length && <CtxMenuItem label={t("Nothing found")} disabled />}

      <Box height={found.length * 33} maxHeight="35vh">
        <VirtualizedList
          itemData={found}
          itemKey={(index, data) => getIdValue(data[index])}
          itemSize={33}
          renderRow={({ index, style, data }) => {
            const item = data[index];
            const { checked, indeterminate } = localState
              ? localState[getIdValue(data[index])]
                ? localState[getIdValue(data[index])]
                : { checked: false, indeterminate: false }
              : dispatch.labels.getLabelState(getIdValue(data[index]));

            return (
              <CtxMenuItem
                key={index}
                style={style}
                onClick={() => {
                  onClick(checked, getModel(item));
                }}
                icon={<OnlyCheckBox checked={checked} indeterminate={indeterminate} />}
                label={getSearchValue(item)}
              />
            );
          }}
        />
      </Box>
    </MenuList>
  );
}
