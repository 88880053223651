import { Loader } from "@components/Loader";
import { PasswordTextField, SubmitButton } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { auth } from "@services/firebase";
import {
  EmailPasswordCredentials,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import { newPasswordSchema, passwordConfirmSchema } from "@utils";
import { confirmPasswordReset } from "firebase/auth";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    password: newPasswordSchema,
    confirm: passwordConfirmSchema,
  })
  .required();

export const SendNewPassword: React.FC = () => {
  const { t } = useTranslation();
  const [done, setDone] = useState(false);
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();

  const isLoading = useAppSelector(
    state => state.app.authLoading || state.loading.effects.app.finalizeAuth.loading,
  );

  const methods = useForm<EmailPasswordCredentials>({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const handleSubmit = async ({ password }: { password: string }) => {
    try {
      const oobCode = params.get("oobCode");

      if (!oobCode) throw new Error();

      await confirmPasswordReset(auth, oobCode, password);
      setDone(true);
    } catch (error) {
      dispatch.notifier.enqueueSnackbar({
        message: t(`Unable to reset the password`),
        options: {
          variant: "error",
        },
      });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (done) {
    return (
      <>
        <Typography variant="body2" color="text.secondary">
          {t(`Password has been updated.`)}
        </Typography>
        <Box>
          <Typography variant="body1" component="span">
            <Link href={"/signin"}>{t(`Go to sign in page`)}</Link>
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <Typography variant="body2" color="text.secondary">
        {t(`Enter your new password.`)}
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)} noValidate>
          <Stack spacing={2}>
            <PasswordTextField
              name="password"
              label="Password"
              autoFocus
              fullWidth
              required
            />

            <PasswordTextField
              name="confirm"
              label="Confirm password"
              fullWidth
              required
            />

            <SubmitButton fullWidth>{t("Send")}</SubmitButton>
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};
