import { ReactRouterLinkCustom } from "@components/ReactRouterLinkCustom";
import { DEMO_WORKSPACE_URL } from "@constants";
import { Button, Typography } from "@mui/material";
import { memo } from "react";
import { useParams } from "react-router-dom";

export const NoDataRecordings = memo(() => {
  const { projectId } = useParams();

  return (
    <>
      {projectId ? (
        <>
          <Typography sx={{ textAlign: "center" }}>
            No recordings in this project.
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Go back to your workspace. Select recordings and
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            add them to this project.
          </Typography>
        </>
      ) : (
        <>
          <Typography sx={{ textAlign: "center" }}>
            No recordings in this workspace.
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            Make recordings with Neon or Invisible on and upload to this
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            workspace or check out the Demo workspace.
          </Typography>
          <Button
            component={ReactRouterLinkCustom}
            to={DEMO_WORKSPACE_URL}
            color="primary"
            sx={{ mt: 2 }}
          >
            View Demo Workspace
          </Button>
        </>
      )}
    </>
  );
});
