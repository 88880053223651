import { ColorSlider as Component } from "@components";
import { FC, PropsWithChildren } from "react";
import { Controller, useFormContext } from "react-hook-form";
import * as yup from "yup";

export const colorSliderYupSchema = yup.object().shape({
  r: yup.number().required(),
  g: yup.number().required(),
  b: yup.number().required(),
  a: yup.number().required(),
});

export const ColorSlider: FC<PropsWithChildren<{ label: string; name: string }>> = ({
  label,
  name,
  children,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field: { value, onChange } }) => {
        return (
          <Component value={value} onChange={onChange} label={label}>
            {children}
          </Component>
        );
      }}
      name={name}
      control={control}
    />
  );
};
