import { Label } from "@api";
import {
  Dragger,
  EntityDataTable,
  FilterLabelChip,
  NoDataRecordings,
  ReactRouterLinkCustom,
  RecordingQuickSelectBar,
  RecordingViewer,
} from "@components";
import {
  recordingCreatedByFilterOperatorsParams,
  recordingLabelsFilterOperatorsParams,
  recordingProjectsFilterOperatorsParams,
  recordingTemplateNameFilterOperatorsParams,
  recordingWearerFilterOperatorsParams,
} from "@components/filter/utils";
import { RecordingsContextMenu } from "@components/recordings/RecordingsContextMenu";
import { Thumbnail } from "@components/recordings/Thumbnail";
import { formatDurationNs } from "@components/videoPlayer/utils";
import { INACTIVE_FIELD_NAME } from "@constants";
import { Chip, Grid, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GridColDef } from "@mui/x-data-grid-pro";
import { RouterHelper } from "@pages";
import {
  DraggerTypes,
  RecordingWithData,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import { LocalStorageAdapterNames } from "@utils";
import { memo, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import TimeAgo from "timeago-react";
import "video.js/dist/video-js.css";

export const Recordings = () => {
  const dispatch = useAppDispatch();
  const fullScreen = useAppSelector(state => state.video.fullScreen);
  const size = useAppSelector(state => state.dragger.table.size);

  useEffect(() => {
    dispatch.entityTable.setWorkspaceDefaultSelected(null);
  }, [dispatch]);

  return (
    <>
      <Grid container height="100%">
        <Box
          sx={{
            display: fullScreen ? "none" : "unset",
            width: `calc(100% - ${size}px)`,
          }}
        >
          <RecordingsTable />
          <RecordingsContextMenu />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            borderLeft: `1px solid #FFFFFF0F`,
            width: fullScreen ? "100%" : `${size}px`,
            position: "relative",
          }}
        >
          <Dragger type={DraggerTypes.TABLE} vertical />
          {fullScreen && (
            <RecordingQuickSelectBar>
              <RecordingsTable
                singleSelect
                noContextMenu
                noResetSelected
                disableCssHeight
              />
            </RecordingQuickSelectBar>
          )}
          <RecordingViewer />
        </Box>
      </Grid>
      <Outlet />
    </>
  );
};

const Projects = memo(({ id }: { id: string }) => {
  const params = useParams();
  const projects = useAppSelector(state =>
    state.projects.projectsByRecordingId.get(id),
  );

  const arr = projects ? Array.from(projects.values()) : [];

  return (
    <Box display="flex" gap={0.5}>
      {arr.map(m => (
        <Tooltip key={m.id} title={m.name}>
          <Chip
            variant="outlined"
            size="small"
            label={m.name}
            component={ReactRouterLinkCustom}
            to={RouterHelper.projectRecordings.getFullPath({
              prefix: "/",
              props: { workspaceId: params.workspaceId, projectId: m.id },
            })}
            sx={{ cursor: "pointer" }}
          />
        </Tooltip>
      ))}
    </Box>
  );
});

export const columns: GridColDef<RecordingWithData>[] = [
  { field: "id", headerName: "ID" },
  {
    field: "name",
    width: 224,
    hideable: false,
    headerName: "Recording Name",
    valueGetter: (_, row) => {
      return row.recording.name;
    },
    renderCell: params => {
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Thumbnail recording={params.row.recording} />
          <Tooltip title={params.row.recording.name}>
            <Typography ml={1.5} noWrap variant="body2">
              {params.row.recording.name}
            </Typography>
          </Tooltip>
        </Box>
      );
    },
  },
  {
    field: "duration_ns",
    width: 100,
    headerName: "Duration",
    type: "number",
    valueGetter: (_, row) => {
      return row.recording.duration_ns;
    },
    renderCell: params => {
      if (!params.row.recording.duration_ns) return null;

      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {formatDurationNs(params.row.recording.duration_ns)}
        </Box>
      );
    },
  },
  {
    field: "recorded_at",
    width: 107,
    headerName: "Recorded",
    type: "date",
    valueGetter: (_, row) => {
      return row.recording.recorded_at ? new Date(row.recording.recorded_at) : null;
    },
    renderCell: params => {
      return params.row.recording.recorded_at ? (
        <TimeAgo
          datetime={params.row.recording.recorded_at}
          opts={{ minInterval: 60 }}
        />
      ) : null;
    },
  },
  {
    field: "wearer",
    width: 93,
    headerName: "Wearer",
    valueGetter: (_, row) => {
      return row.wearer?.name;
    },
    ...recordingWearerFilterOperatorsParams(),
  },
  {
    field: "labels",
    width: 170,
    headerName: "Labels",
    sortable: false,
    renderCell: params => {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }}
          gap={0.5}
        >
          {params.row.labels.map((label: Label) => (
            <FilterLabelChip key={label.id} label={label} />
          ))}
        </Box>
      );
    },
    ...recordingLabelsFilterOperatorsParams(),
  },
  {
    field: "projects",
    width: 170,
    headerName: "Projects",
    sortable: false,
    renderCell: params => {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }}
        >
          <Projects id={params.row.id} />
        </Box>
      );
    },
    ...recordingProjectsFilterOperatorsParams(),
  },
  {
    field: "template_id",
    headerName: "Template Name",
    valueGetter: (_, row) => {
      return row.template?.name;
    },
    ...recordingTemplateNameFilterOperatorsParams(),
  },
  {
    field: "created_by_user_id",
    headerName: "Created By",
    valueGetter: (_, row) => {
      return row.createdBy?.user?.name;
    },
    ...recordingCreatedByFilterOperatorsParams(),
  },
  {
    field: INACTIVE_FIELD_NAME,
    headerName: "Trashed",
    type: "boolean",
    valueGetter: (_, row) => {
      return row.recording.trashed_at ? true : false;
    },
  },
];

const RecordingsTable = ({
  noResetSelected,
  singleSelect,
  noContextMenu,
  onCustomSelect,
  disableCssHeight,
}: {
  noResetSelected?: boolean;
  singleSelect?: boolean;
  noContextMenu?: boolean;
  onCustomSelect?: (id: string) => void;
  disableCssHeight?: boolean;
}) => {
  const rows = useAppSelector(state => state.recordings.tableData);
  const isLoading = useAppSelector(
    state => state.loading.effects.app.loadWorkspaceData.loading,
  );

  return (
    <EntityDataTable
      loading={isLoading}
      rows={rows}
      columns={columns}
      toolbarPadding
      noContextMenu={noContextMenu}
      singleSelect={singleSelect}
      noResetSelected={noResetSelected}
      onCustomSelect={onCustomSelect}
      initialState={{
        sorting: { sortModel: [{ field: "recorded_at", sort: "desc" }] },
        columns: {
          columnVisibilityModel: {
            id: false,
            template_id: false,
            created_by_user_id: false,
            [INACTIVE_FIELD_NAME]: false,
          },
        },
      }}
      noDataMessage={<NoDataRecordings />}
      cssHeight={disableCssHeight ? undefined : "calc(100vh - 48px)"}
      tableColumnInitialStateStorageKey={
        LocalStorageAdapterNames.workspaceRecordingsColumState
      }
    />
  );
};
