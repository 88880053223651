import { SliderTextField as Component } from "@components";
import { Controller, useFormContext } from "react-hook-form";

export const SliderTextField = ({
  label,
  min = 20,
  max = 120,
  name,
  disabled,
}: {
  label: string;
  min?: number;
  max?: number;
  name: string;
  disabled?: boolean;
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field: { value, onChange } }) => {
        return (
          <Component
            value={value}
            min={min}
            max={max}
            label={label}
            disabled={disabled}
            onChange={onChange}
          />
        );
      }}
      name={name}
      control={control}
    />
  );
};
