import { ContextMenu } from "@components/ctxMenu";
import { DEMO_WORKSPACE_ID, DEMO_WORKSPACE_URL } from "@constants";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { ContextMenuTypes, useAppDispatch, useAppSelector } from "@storeRematch";
import { MuiMarkdown, getOverrides } from "mui-markdown";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ONBOARDING_STEPS } from "./OnboardingConfig";
import { OnboardingModal } from "./OnboardingModal";

export const UserOnboarding = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const openOnboard = useAppSelector(state => state.onboarding.openOnboard);
  const startOnboard = useAppSelector(state => state.onboarding.startOnboard);
  const currentStepIndex = useAppSelector(state => state.onboarding.currentStepIndex);
  const userWorkspaces = useAppSelector(state => state.app.apiUser?.user_workspaces);
  const openReleaseNotes = useAppSelector(state => state.onboarding.openReleaseNotes);
  const releaseNotesData = useAppSelector(state => state.onboarding.releaseNotesData);

  const currentStep = ONBOARDING_STEPS[currentStepIndex];
  const { id, subId, highlight, to, placement } = currentStep;
  const haveDemoWorkspace = userWorkspaces?.find(
    item => item.workspace.id === DEMO_WORKSPACE_ID,
  );

  const handleStartTour = () => {
    if (haveDemoWorkspace) {
      navigate(DEMO_WORKSPACE_URL);
      dispatch.onboarding.setStartOnboard(true);
      dispatch.onboarding.setSteps(ONBOARDING_STEPS.length);
    } else {
      dispatch.onboarding.setOpenOnboard(false);
      dispatch.ctxMenu.closeAll();
      dispatch.notifier.enqueueSnackbar({
        message: t(`No Demo workspace found in this account`),
        options: {
          variant: "error",
        },
      });
    }
  };

  const handleNext = () => {
    if (currentStepIndex + 1 < ONBOARDING_STEPS.length) {
      dispatch.ctxMenu.closeAll();
      dispatch.onboarding.setCurrentStepIndex(currentStepIndex + 1);
      to &&
        navigate(to(), {
          replace: true,
        });
    } else {
      handleOnClose();
    }
  };

  const closeOnboarding = () => {
    dispatch.onboarding.setOpenOnboard(false);
  };

  const handleOnClose = () => {
    closeOnboarding();
    dispatch.onboarding.reset();
    dispatch.ctxMenu.close(ContextMenuTypes.ONBOARDING_MODAL);
  };

  const isElementLoaded = async (selector: string) => {
    while (document.getElementById(selector) === null) {
      await new Promise(resolve => requestAnimationFrame(resolve));
    }
    return document.getElementById(selector);
  };

  useEffect(() => {
    if (!startOnboard) return;

    const target = document.getElementById(id);
    const highLightTarget = highlight && document.getElementById(highlight);
    // track onboarding current step
    dispatch.onboarding.setCurrentStepIndex(currentStepIndex);

    // toggle button targets
    if (target && target.tagName === "BUTTON") {
      target.click();
    }

    // wait for target element then open the modal
    isElementLoaded(subId || id).then(modalTarget => {
      if (highLightTarget) {
        highLightTarget.style.zIndex = "1250";
        highLightTarget.style.position = "relative";
      }
      dispatch.ctxMenu.onboardOpen({
        type: ContextMenuTypes.ONBOARDING_MODAL,
        position: modalTarget?.getBoundingClientRect(),
        placement: placement,
      });
    });

    return () => {
      if (highLightTarget) {
        highLightTarget.style.zIndex = "";
        highLightTarget.style.position = "";
      }
    };
  }, [
    startOnboard,
    dispatch,
    currentStepIndex,
    highlight,
    id,
    subId,
    placement,
    navigate,
    userWorkspaces,
  ]);

  if (openReleaseNotes) {
    if (!releaseNotesData) return null;

    return (
      <Dialog
        open={openReleaseNotes}
        disableEscapeKeyDown={false}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontSize: "1.7rem" }}>{releaseNotesData.title}</DialogTitle>
        <DialogContent
          sx={{ "div:first-of-type": { pt: 1 }, "* > img, iframe": { mx: "auto" } }}
        >
          <MuiMarkdown
            overrides={{
              ...getOverrides({}),
              a: { props: { style: { color: "#6D7BE0" } } },
              ul: {
                props: {
                  style: {
                    listStyle: "unset",
                    margin: "revert",
                    padding: "revert",
                  },
                },
              },
              ol: {
                props: {
                  style: {
                    listStyle: "auto",
                    margin: "0px",
                    paddingLeft: "24px",
                    paddingTop: "8px",
                  },
                },
              },
            }}
          >
            {releaseNotesData.text}
          </MuiMarkdown>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            onClick={() => dispatch.onboarding.reset()}
          >
            {t("Close")}
          </Button>
          {releaseNotesData.action && (
            <Button
              color="primary"
              component={Link}
              href={releaseNotesData.action.url}
              sx={{ ml: 2 }}
            >
              {t(releaseNotesData.action.title)}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      <Modal
        open={openOnboard}
        disableEscapeKeyDown={false}
        sx={{ display: "flex", justifyContent: "center", zIndex: 1200 }}
      >
        <>
          {!startOnboard && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Card sx={{ maxWidth: "800px", width: "100%", backgroundImage: "unset" }}>
                <CardContent>
                  <Typography variant="h6" color="text.primary">
                    {t("New to Pupil Cloud?")}
                  </Typography>
                  <Paper sx={{ width: "100%", height: "450px", mb: 1, mt: 2 }}>
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/dPLTYLpFDwc"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </Paper>
                  <Typography variant="body2" color="text.secondary">
                    {t("Walk through Pupil Cloud's features")}
                  </Typography>
                </CardContent>
                <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button size="small" color="primary" onClick={closeOnboarding}>
                    {t("Ok")}
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    onClick={handleStartTour}
                    sx={{ display: "none" }}
                  >
                    {t("Take a tour")}
                  </Button>
                </CardActions>
              </Card>
            </Box>
          )}
        </>
      </Modal>
      {startOnboard && (
        <Box
          sx={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 1400,
            backgroundColor: "transparent",
          }}
        />
      )}
      <Box sx={{ zIndex: 1500, maxWidth: "265px", position: "relative" }}>
        <ContextMenu type={ContextMenuTypes.ONBOARDING_MODAL}>
          <Box color="white">
            <OnboardingModal
              content={currentStep}
              onNext={handleNext}
              onClose={handleOnClose}
            />
          </Box>
        </ContextMenu>
      </Box>
    </>
  );
};
