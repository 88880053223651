import { Dragger } from "@components/Dragger";
import { Box } from "@mui/system";
import { DraggerTypes, useAppSelector } from "@storeRematch";
import { memo } from "react";
import { VideoFullScreen } from "./VideoFullScreen";
import { VideoMoveBackgroundButton } from "./VideoMoveBackgroundButton";
import { VideoPlayButton } from "./VideoPlayButton";
import { VideoSound } from "./VideoSound";
import { VideoSpeed } from "./VideoSpeed";
import { VideoTime } from "./VideoTime";

export const VideoControls = memo(() => {
  return (
    <Box
      px={1}
      pt={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      id="video-idtes"
      position="relative"
    >
      <DraggerSwitcher />
      <Box display="flex">
        <VideoPlayButton />
        <VideoSound />
      </Box>

      <VideoTime />
      <Box minWidth={94} display="flex" justifyContent="flex-end">
        <VideoMoveBackgroundButton />
        <VideoSpeed />
        <VideoFullScreen />
      </Box>
    </Box>
  );
});

const DraggerSwitcher = () => {
  const fullScreen = useAppSelector(state => state.video.fullScreen);

  return (
    <Dragger type={fullScreen ? DraggerTypes.VIDEO_FULL_SCREEN : DraggerTypes.VIDEO} />
  );
};
