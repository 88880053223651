import {
  DurationTextField,
  DurationTextFieldOnChangeValueType,
} from "@components/DurationTextField";
import { CircularProgress, Typography } from "@mui/material";
import { useAppSelector } from "@storeRematch";
import { useCallback, useEffect, useRef, useState } from "react";
import { formatDurationInput, getHMSMsFromDurationString } from "./utils";

const useAnimationFrame = (callback: (num: number) => void) => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef<number>(0);
  const previousTimeRef = useRef<number>(0);

  const animate = (time: number) => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  const start = () => {
    requestRef.current = requestAnimationFrame(animate);
  };

  const stop = () => {
    cancelAnimationFrame(requestRef.current);
  };

  useEffect(() => {
    return () => stop();
  }, []); // Make sure the effect runs only once

  return { start, stop };
};

export const VideoTime = () => {
  const show = useAppSelector(state =>
    state.video.hideVideoPlayer
      ? false
      : document.URL.includes("projects")
      ? state.projectEdit.recordings.length > 0
      : state.recordings.data.length > 0,
  );
  const instance = useAppSelector(state => state.video.instance);
  const [time, setTime] = useState("");
  const [disabled, setDisabled] = useState(false);

  const update = useCallback(() => {
    if (!instance || instance.isDisposed()) return;

    const current = instance.currentTime();

    if (!isNaN(current)) {
      setTime(formatDurationInput(current));
    }
  }, [instance]);

  const { start, stop } = useAnimationFrame(update);

  useEffect(() => {
    if (!instance) return;

    function reset() {
      setTime("");
    }

    function disabled() {
      start();
      setDisabled(true);
    }

    function enable() {
      stop();
      setDisabled(false);
    }

    function updateIfNeeded() {
      if (instance && instance.paused()) update();
    }

    function loaded() {
      const current = instance?.currentTime();

      if (current !== undefined && !isNaN(current)) {
        setTime(formatDurationInput(current));
      }
    }

    instance.on("play", disabled);
    instance.on("pause", enable);
    instance.on("loadeddata", loaded);
    instance.on("loadstart", reset);
    instance.on("timeupdate", updateIfNeeded);

    return () => {
      instance.off("play", disabled);
      instance.off("pause", enable);
      instance.off("loadeddata", loaded);
      instance.off("loadstart", reset);
      instance.off("timeupdate", updateIfNeeded);
    };
  }, [instance, start, stop, update]);

  if (!show) return null;

  if (!time) return <CircularProgress size={25} />;

  if (disabled) {
    return <Typography sx={{ cursor: "default" }}>{time}</Typography>;
  }

  function setInstanceTime(a: DurationTextFieldOnChangeValueType) {
    if (!instance) return 0;

    const { h, m, s, ms } = getHMSMsFromDurationString(a.formattedValue);
    const added = h * 60 * 60 + m * 60 + s + ms / 1000;

    if (a.value.length === 9) {
      instance.currentTime(instance.duration() < added ? instance.duration() : added);
      instance.isInPictureInPicture(false);
    }

    return added;
  }

  const fillValue = () => {
    const cleanTime = time.replace(/[:.]/g, "");

    setInstanceTime({
      floatValue: 0,
      formattedValue: time,
      value: cleanTime.replace(/[_]/g, "0"),
    });
    setTime(time.replace(/[_]/g, "0"));
  };

  return (
    <DurationTextField
      onChange={a => {
        if (!instance) return;

        const added = setInstanceTime(a);

        setTime(
          instance.duration() < added
            ? formatDurationInput(instance.duration())
            : a.formattedValue,
        );
      }}
      onKeyDown={e => {
        if (e.key !== "Enter") return;

        fillValue();
      }}
      value={time}
      sx={{ input: { color: "text.secondary" } }}
    />
  );
};
