import {
  GenerateHeatmap,
  VisualizationDataHeatmap,
  VisualizationTypes,
  Visualizations,
} from "@api";
import { HeatMapLegend, Loader, ReactRouterLinkCustom } from "@components";
import {
  RecordingsSelectModal,
  Select,
  SliderTextField,
  SubmitButton,
  TextField,
} from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useVisualizationRecordingIds } from "@hooks";
import { useWindowDimensions } from "@hooks/useWindowDimensions";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { CircularProgress, Divider, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { RouterHelper } from "@pages";
import { store, useAppDispatch, useAppSelector } from "@storeRematch";
import { CheckIfModelNeedsUpdate, isArrayEqual } from "@utils";
import debounce from "debounce";
import { FC, memo, useEffect } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { EnrichmentName } from "./EnrichmentName";

export const HeatMap = () => {
  const { t } = useTranslation();
  const params = useParams();
  const visualizationId = String(params.visualizationId);
  const dispatch = useAppDispatch();
  const isLoaded = useAppSelector(
    state =>
      state.loading.effects.app.loadProjectData.success &&
      state.loading.effects.enrichments.get.success,
  );
  const visualization = useAppSelector(state =>
    state.visualizations.dataById.get(visualizationId),
  );
  const setCurrentVisualization = useAppSelector(
    state => state.visualizations.currentVisualization,
  );
  const enrichment = useAppSelector(state =>
    visualization?.enrichment_id
      ? state.enrichments.dataById.get(visualization.enrichment_id)
      : undefined,
  );

  useEffect(() => {
    dispatch.projectEdit.setCurrentEnrichment(enrichment);

    return () => {
      dispatch.projectEdit.reset();
    };
  }, [enrichment, dispatch]);

  useEffect(() => {
    dispatch.visualizations.setCurrentVisualization(visualization);

    return () => {
      dispatch.visualizations.setCurrentVisualization(null);
    };
  }, [dispatch, visualization]);

  useEffect(() => {
    if (
      enrichment?.id &&
      setCurrentVisualization &&
      setCurrentVisualization.kind === VisualizationTypes.HEATMAP
    ) {
      dispatch.heatmap.get({
        id: enrichment.id,
        recordingIds: setCurrentVisualization.payload.recording_ids,
      });
    }
  }, [dispatch, enrichment?.id, setCurrentVisualization]);

  useEffect(() => {
    return () => {
      dispatch.heatmap.reset();
      dispatch.aoiStats.setHeatmapColor(undefined);
    };
  }, [dispatch]);

  return (
    <>
      <Box width="300px" display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" py={1} px={2} height={44}>
          <IconButton
            sx={{ mr: 1 }}
            size="small"
            component={ReactRouterLinkCustom}
            to={RouterHelper.projectVisualizations.getRelativePath({
              prefix: "../",
            })}
          >
            <KeyboardArrowLeft fontSize="small" />
          </IconButton>
          <Typography>{t("Heatmap")}</Typography>
        </Box>
        <Divider />
        <Box py={1} px={2} display="flex" flexDirection="column" height="100%">
          {isLoaded && visualization ? <Form model={visualization} /> : null}
        </Box>
      </Box>
      <Divider orientation="vertical" />
      {isLoaded && visualization ? (
        <Box flexGrow={1} display="flex">
          <Image />
          <HeatMapLegend />
        </Box>
      ) : (
        <Loader />
      )}
    </>
  );
};

const Image = () => {
  const isLoading = useAppSelector(
    state =>
      state.loading.effects.heatmap.get.loading ||
      !state.loading.effects.app.loadWorkspaceData.success ||
      !state.loading.effects.app.loadProjectData.success,
  );
  const url = useAppSelector(state => state.heatmap.imageUrl);
  const dispatch = useAppDispatch();

  return (
    <Box flexGrow={1} height="100%" display="flex">
      <Box maxHeight="100%" p={1} position="relative" display="flex" flexGrow={1}>
        {!isLoading && !url ? (
          <Box sx={{ margin: "auto", justifyContent: "center" }}>
            Enrichment does not exist
          </Box>
        ) : url ? (
          <>
            <img
              src={url}
              alt="heatmap"
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                height: "auto",
                width: "auto",
                margin: "auto",
                display: "block",
              }}
              onLoad={() => dispatch.heatmap.setImageLoaded(true)}
              onError={() => {}}
            />
            <canvas
              ref={dispatch.heatmap.setCanvasRef}
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          </>
        ) : (
          <CircularProgress sx={{ margin: "auto" }} />
        )}
      </Box>
    </Box>
  );
};

const checkDebounce = new CheckIfModelNeedsUpdate();
const debouncedUpdate = debounce(
  (data: Parameters<typeof store.dispatch.visualizations.update>[0]) => {
    if (checkDebounce.checkOne(data.id, data)) {
      store.dispatch.visualizations.update(data);
    }
  },
  500,
);

export interface HeatmapFormValues {
  id: string;
  name: string;
  colorMap: GenerateHeatmap["colormap"];
  scale: number;
  recordingIds: string[];
  enrichmentId?: string | null;
}

export const DEFAULT_HEATMAP_COLOR_TYPES: Array<{
  value: GenerateHeatmap["colormap"];
  label: string;
}> = [
  { value: "Turbo", label: "Turbo" },
  { value: "Jet", label: "Jet" },
  { value: "Magma", label: "Magma" },
  { value: "Traffic Light", label: "Traffic Light" },
  { value: "Viridis", label: "Viridis" },
];

const schema = yup
  .object()
  .shape({
    id: yup.string().required(),
    name: yup.string(),
    colorMap: yup.string().required(),
    scale: yup.number().required().min(1).max(10),
    recordingIds: yup.array().of(yup.string()).min(1),
    enrichmentId: yup.string(),
  })
  .required();

const Form = memo(({ model }: { model: Visualizations }) => {
  // TODO(ivan): enrichment select disabled
  //const idRef = useRef<string | null>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoaded = useAppSelector(
    state => state.loading.effects.heatmap.get.success && state.heatmap.imageLoaded,
  );
  const isDisabled = useAppSelector(
    state => !!state.loading.effects.heatmap.download.loading,
  );
  const dim = useWindowDimensions();

  const filteredRecordingIds = useVisualizationRecordingIds(
    model.enrichment_id || "",
    (model.payload as VisualizationDataHeatmap).recording_ids,
  );

  const methods = useForm<HeatmapFormValues>({
    defaultValues: {
      id: model.id,
      name: model.name,
      enrichmentId: model.enrichment_id,
      colorMap: (model.payload as VisualizationDataHeatmap).color_map,
      scale: (model.payload as VisualizationDataHeatmap).scale,
      recordingIds: filteredRecordingIds,
    },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });
  const watchAll = methods.watch();

  const handleSubmit = (data: HeatmapFormValues) => {
    if (!data.enrichmentId) {
      return;
    }

    dispatch.heatmap.download({ ...data, enrichmentId: data.enrichmentId });
  };

  useEffect(() => {
    if (isLoaded && watchAll.scale <= 10) {
      dispatch.heatmap.dataChange(watchAll);
      dispatch.aoiStats.setHeatmapColor(watchAll.colorMap);
    }
    if (!watchAll.recordingIds.length) {
      dispatch.heatmap.clearCanvas(null);
    }
    // TODO(ivan): enrichment select disabled
    // if (idRef.current !== watchAll.enrichmentId && watchAll.enrichmentId) {
    //   dispatch.heatmap.get({ id: watchAll.enrichmentId });
    //   idRef.current = watchAll.enrichmentId;
    // }
  }, [watchAll, isLoaded, dispatch, dim]);

  useEffect(() => {
    debouncedUpdate({
      id: watchAll.id,
      name: watchAll.name,
      enrichment_id: watchAll.enrichmentId,
      payload: {
        scale: watchAll.scale,
        color_map: watchAll.colorMap,
        recording_ids: watchAll.recordingIds,
      },
    });
  }, [watchAll, dispatch]);

  useEffect(() => {
    if (
      model?.kind === VisualizationTypes.HEATMAP &&
      !isArrayEqual(filteredRecordingIds, methods.getValues("recordingIds"))
    ) {
      methods.setValue("recordingIds", filteredRecordingIds);
    }
  }, [model, filteredRecordingIds, methods]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleSubmit)}
        noValidate
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <TextField
          name="name"
          label="Visualization name"
          sx={{ mt: 1 }}
          disabled={isDisabled}
        />
        <InnerDivider />
        {/* <VizEnrichmentSelect disabled={isDisabled} /> */}
        <EnrichmentName id={model.enrichment_id} />
        <InnerDivider />
        <Select
          name="colorMap"
          label={t("Color map")}
          options={DEFAULT_HEATMAP_COLOR_TYPES as any}
          fullWidth
          sx={{ mb: 2 }}
          disabled={isDisabled}
          variant="standard"
        />
        <SliderTextField
          name="scale"
          label={t("Scale")}
          min={1}
          max={10}
          disabled={isDisabled}
        />
        <InnerDivider />

        <RecordingsSelect enrichmentId={watchAll.enrichmentId} disabled={isDisabled} />

        <InnerDivider />

        <Submit disabled={isDisabled} />
      </form>
    </FormProvider>
  );
});

const Submit: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { t } = useTranslation();
  const recordingIds: [] = useWatch({ name: "recordingIds" });

  return (
    <SubmitButton
      color="primary"
      fullWidth
      sx={{ mt: "auto" }}
      useIsDirty={false}
      disabled={disabled || !recordingIds.length}
    >
      {t("Download")}
    </SubmitButton>
  );
};

const InnerDivider: FC = () => {
  return <Divider sx={{ my: 2, width: "calc(100% + 32px)", ml: -2 }} />;
};

const RecordingsSelect = ({
  enrichmentId,
  disabled,
}: {
  enrichmentId?: string | null;
  disabled?: boolean;
}) => {
  const dispatch = useAppDispatch();

  return (
    <RecordingsSelectModal
      handleFirstReset={() => {
        dispatch.heatmap.clearCanvas(null);
      }}
      handleConfirm={({ enrichmentId, selectedIds }) => {
        if (!selectedIds.length) dispatch.heatmap.clearCanvas(null);
        else
          dispatch.heatmap.get({
            id: enrichmentId,
            recordingIds: selectedIds,
            disableCheck: true,
          });
      }}
      handleReset={({ enrichmentId, selectedIds }) => {
        dispatch.heatmap.get({
          id: enrichmentId,
          recordingIds: selectedIds,
          disableCheck: true,
        });
      }}
      enrichmentId={enrichmentId || ""}
      disabled={disabled}
    />
  );
};
