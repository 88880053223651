import { envConfigs } from "./EnvConfigs";

// use dev env for `yarn start` and prd env for `yarn build`
let config =
  envConfigs[
    process.env.REACT_APP_PL_ENV ||
      (process.env.NODE_ENV === "production" ? "prd" : "dev")
  ];

// @ts-ignore
const runtimeConfig: Config = window.plConfig;

if (runtimeConfig) {
  Object.entries(runtimeConfig).forEach(([key, value]) => {
    // @ts-ignore
    config[key] = value;
  });
}

export const MUI_KEY = process.env.REACT_APP_PL_MUI_KEY;

if (process.env.REACT_APP_PL_DISABLE_SENTRY) {
  config.sentryDsn = "";
  console.debug(`sentry disabled`);
} else {
  console.debug(`sentry enabled: ${config.sentryDsn}`);
}

export const API_KEY = process.env.REACT_APP_PL_CLOUD_API_KEY;
export const API_URL = config.apiUrl;
export const APP_VERSION = document.getElementById("app-version")?.innerHTML;

export const READABLE_DATE_FORMAT = "dd/MM/yyy";
export const FILTER_DATE_FORMAT = "yyyy-MM-dd";

export const DEMO_WORKSPACE_ID = config.demoWorkspaceId;
export const DEMO_WORKSPACE_URL = `/workspaces/${config.demoWorkspaceId}/recordings`;

export const FIREBASE_CONFIG = {
  apiKey: config.firebase.apiKey,
  projectId: config.firebase.projectId,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
  authDomain: `${config.firebase.projectId}.firebaseapp.com`,
  databaseURL: `https://${config.firebase.projectId}.firebaseio.com`,
  storageBucket: `gs://${config.firebase.projectId}.appspot.com`,
};

export const IS_PRODUCTION = config.environment === "prd";
export const IS_DEV_ENVIRONMENT = !IS_PRODUCTION;
export const ENVIRONMENT = config.environment;
export const SENTRY_DSN = config.sentryDsn;
export const RELEASE_NOTES_URL = config.releasesUrl;
export const GOOGLE_ANALYTICS_4_ID = config.analyticsGA4;
console.debug(config);

export const HORIZONTAL_SCROLL_ID = "HorizontalScroll_root";
export const INFO_EMAIL = "info@pupil-labs.com";
