import { Recording } from "@api";
import { GridFilterItem } from "@mui/x-data-grid-pro";
import { createModel } from "@rematch/core";
import { LocalStorageAdapterNames, localStorageAdapter } from "@utils";
import { QuestionDialogTypes, type RootModel } from "./index";

const defaultQuickFilter: InitialState["quickFilter"] = {
  isInactive: false,
};

interface InitialState {
  selectedIds: string[];
  quickFilter: {
    isInactive: boolean;
  };
  filter: GridFilterItem[];
  hotkeys: boolean;
  scrollToId: string | null;
}

const initialState: InitialState = {
  selectedIds: [],
  quickFilter: defaultQuickFilter,
  filter: [],
  hotkeys: false,
  scrollToId: null,
};

export const entityTable = createModel<RootModel>()({
  state: initialState,
  reducers: {
    addSelected(state, data: string) {
      if (!state.selectedIds.includes(data)) {
        state.selectedIds.push(data);
      }
    },
    setSelected(state, data: InitialState["selectedIds"]) {
      state.selectedIds = data;
    },
    toggleSelected(state, data: string) {
      const index = state.selectedIds.indexOf(data);

      if (index !== -1) state.selectedIds.splice(index, 1);
      else state.selectedIds.push(data);
    },
    resetSelected(state) {
      state.selectedIds = [];
    },
    setFilter(state, data: InitialState["filter"]) {
      state.filter = data;
    },
    setQuickFilter(state, data: Partial<InitialState["quickFilter"]>) {
      state.quickFilter = { ...state.quickFilter, ...data };
    },
    resetQuickFilter(state) {
      state.quickFilter = { ...defaultQuickFilter };
    },
    toggleHotKeys(state) {
      state.hotkeys = !state.hotkeys;
    },
    setScrollToId(state, data: InitialState["scrollToId"]) {
      state.scrollToId = data;
    },
  },
  effects: dispatch => ({
    setWorkspaceDefaultSelected(_, state) {
      // dangerous but don't see any other way
      setTimeout(() => {
        if (
          document.location.pathname.includes("enrichments") ||
          document.location.pathname.includes("visualizations") ||
          document.location.pathname.includes("/recordings/edit/gaze/")
        ) {
          return;
        }

        const autoSelectRecording = localStorageAdapter.get<string | undefined>(
          LocalStorageAdapterNames.autoSelectRecording,
        );
        localStorageAdapter.delete(LocalStorageAdapterNames.autoSelectRecording);

        const id =
          autoSelectRecording ||
          document.querySelector("[data-rowindex='0']")?.getAttribute("data-id");

        if (!id && !state.recordings.tableData.length) {
          dispatch.entityTable.setSelected([]);
          dispatch.video.setHideVideoPlayer(true);
          return;
        }

        if (!id) {
          setTimeout(() => dispatch.entityTable.setWorkspaceDefaultSelected(null));

          return;
        }

        const recording = state.recordings.dataById.get(id);

        if (!recording) {
          dispatch.entityTable.setSelected([]);
          return;
        }

        dispatch.entityTable.setSelected([recording.id]);
        dispatch.video.setHideVideoPlayer(false);
        dispatch.entityTable.setScrollToId(id);
      });
    },
    setProjectDefaultSelected(_, state) {
      const ids = state.app.currentProject?.recording_ids;
      const selectedIds = state.entityTable.selectedIds;
      const currentRecordingIdToLoad = state.projectEdit.currentRecordingIdToLoad;
      const recordings = state.projectEdit.recordings;

      const autoSelectRecording = localStorageAdapter.get<string | undefined>(
        LocalStorageAdapterNames.autoSelectRecording,
      );
      localStorageAdapter.delete(LocalStorageAdapterNames.autoSelectRecording);

      if (autoSelectRecording && recordings.find(r => r.id === autoSelectRecording)) {
        dispatch.entityTable.setSelected([autoSelectRecording]);
        dispatch.entityTable.setScrollToId(autoSelectRecording);
        return;
      }

      if (selectedIds.length) return;

      if (
        currentRecordingIdToLoad &&
        recordings.find(r => r.id === currentRecordingIdToLoad)
      ) {
        dispatch.entityTable.setSelected([currentRecordingIdToLoad]);
        return;
      }

      if (ids && ids.length) {
        dispatch.entityTable.setSelected([ids[0]]);
        return;
      }
    },
    deleteOrTrash(_, state) {
      const ids = state.entityTable.selectedIds;
      const dataById = state.recordings.dataById;
      const recordings = ids.reduce<Recording[]>((acc, id) => {
        if (dataById.has(id)) acc.push(dataById.get(id) as Recording);

        return acc;
      }, []);

      if (recordings[0] && recordings[0].trashed_at) {
        dispatch.questionDialog.set({
          type: QuestionDialogTypes.RECORDING_DELETE,
          onSuccess: () => {
            dispatch.recordings.delete(ids);
          },
          extra: { count: ids.length },
        });
      } else {
        dispatch.recordings.updateInactive({ ids, inactive: true });
      }
    },
  }),
});
