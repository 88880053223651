import { DEMO_WORKSPACE_ID } from "@constants";
import { store } from "@storeRematch";

export function getSelectRoles(
  role: "OWNER" | "VIEWER" | "EDITOR" | "DEMO" | "ADMIN" | undefined,
) {
  const showDemo =
    store.getState().app.currentWorkspaceMembership?.workspace.id ===
      DEMO_WORKSPACE_ID && role === "OWNER";

  switch (role) {
    case "OWNER":
    case "ADMIN":
      return addDemo(
        [
          { value: "ADMIN", label: "ADMIN" },
          { value: "EDITOR", label: "EDITOR" },
          { value: "VIEWER", label: "VIEWER" },
        ],
        showDemo,
      );
    case "EDITOR":
    case "VIEWER":
    case "DEMO":
    default:
      return [];
  }
}

function addDemo(arr: Array<{ value: string; label: string }>, show: boolean) {
  if (show) {
    arr.push({ value: "DEMO", label: "DEMO" });
  }

  return arr;
}
