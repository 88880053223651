import { Markerless, ProjectEnrichment, StaticImageMapper } from "@api";

export function remapStaticImageMapper(model: StaticImageMapper): ProjectEnrichment {
  const copy = { ...model };
  const static_image_id = copy.static_image_id;
  delete (copy as any)["static_image_id"];

  return { ...model, args: { static_image_id: static_image_id || undefined } };
}

export function remapMarkerless(model: StaticImageMapper | null): Markerless | null {
  if (!model || !model.static_image_id || !model.static_image_image_thumbnail_url)
    return null;

  return {
    id: model.id,
    reference_image_id: model.id,
    reference_image_thumbnail_url: model.static_image_image_thumbnail_url,
    scanning_recording_id: "",
  };
}
