import { ContextMenu, CtxMenuItem } from "@components";
import { UserAvatar } from "@components/Common/StringAvatar";
import { ModalIds } from "@components/onboarding/OnboardingConfig";
import { APP_VERSION } from "@constants";
import { Help, Logout, Settings } from "@mui/icons-material";
import { IconButton, ListItem, MenuList, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { RouterHelper } from "@pages";
import { ContextMenuTypes, useAppDispatch, useAppSelector } from "@storeRematch";
import { useTranslation } from "react-i18next";

export const UserControls = () => {
  const { t } = useTranslation();
  const user = useAppSelector(state => state.app.apiUser);
  const disableReleaseNotes = useAppSelector(
    state => !state.onboarding.releaseNotesData,
  );
  const dispatch = useAppDispatch();

  const openOnboard = () => {
    dispatch.onboarding.setOpenOnboard(true);
    dispatch.ctxMenu.closeAll();
  };

  const openReleaseNotes = () => {
    dispatch.onboarding.showReleaseNotes(null);
    dispatch.ctxMenu.closeAll();
  };

  const openHotKeys = () => {
    dispatch.entityTable.toggleHotKeys();
    dispatch.ctxMenu.closeAll();
  };

  return (
    <>
      <IconButton
        id={ModalIds.GET_HELP}
        size="small"
        onClick={e =>
          dispatch.ctxMenu.handleClick({
            type: ContextMenuTypes.HELP_MENU,
            position: true,
            e,
          })
        }
      >
        <Help />
      </IconButton>
      <IconButton
        size="small"
        onClick={e =>
          dispatch.ctxMenu.handleClick({
            type: ContextMenuTypes.USER_MENU,
            position: true,
            e,
          })
        }
      >
        <UserAvatar user={user} />
      </IconButton>
      <ContextMenu type={ContextMenuTypes.HELP_MENU}>
        <MenuList id={ModalIds.GET_HELP_MODAL}>
          <CtxMenuItem label={t("Product Intro")} onClick={openOnboard} />
          <CtxMenuItem
            label={t("Release Notes")}
            onClick={openReleaseNotes}
            disabled={disableReleaseNotes}
            tooltip={t("Release notes not available")}
          />
          <CtxMenuItem label={t("Keyboard Shortcuts")} onClick={openHotKeys} />
          <CtxMenuItem label={t("Documentation")} href="https://docs.pupil-labs.com/" />
          <CtxMenuItem
            label={t("Feedback & Requests")}
            href="https://feedback.pupil-labs.com/"
          />
          <CtxMenuItem label={t("Discord Chat")} href="https://pupil-labs.com/chat/" />
          <CtxMenuItem label={t("Legal")} href="https://pupil-labs.com/legal/" />
          <CtxMenuItem
            label={t("About")}
            href="https://pupil-labs.com/about/"
            divider
          />
          <ListItem>
            <Typography variant="subtitle2" color="GrayText">
              {t("Version")}: {APP_VERSION}
            </Typography>
          </ListItem>
        </MenuList>
      </ContextMenu>
      <ContextMenu type={ContextMenuTypes.USER_MENU}>
        <ListItem divider>
          <Box display="flex" alignItems="center">
            <UserAvatar user={user} />
            <Box display="flex" flexDirection="column" ml={1.5}>
              <Typography variant="subtitle2" noWrap maxWidth={300}>
                {user?.name}
              </Typography>
              <Typography variant="body2" noWrap maxWidth={300}>
                {user?.email}
              </Typography>
            </Box>
          </Box>
        </ListItem>

        <MenuList>
          <CtxMenuItem
            icon={<Settings />}
            to={RouterHelper.accountSettings.getFullPath()}
            label={t("Account settings")}
          />
          <CtxMenuItem
            icon={<Logout />}
            to=""
            label={t("Sign out")}
            onClick={dispatch.app.logout}
          />
        </MenuList>
      </ContextMenu>
    </>
  );
};
