import { ContextMenu, CtxMenuItem } from "@components";
import { MenuList } from "@mui/material";
import { RouterHelper } from "@pages";
import {
  ContextMenuTypes,
  QuestionDialogTypes,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import { DisableByPermissionsType, disabledByPermission } from "@utils";
import React from "react";
import { useTranslation } from "react-i18next";

export const LabelsContextMenu: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selected = useAppSelector(state => state.entityTable.selectedIds);
  const numSelected = selected.length;
  const isMultiSelect = numSelected > 1;
  const disabledPermissionsEdit = disabledByPermission({
    type: DisableByPermissionsType.EDIT,
  });
  const disabledPermissionsDelete = disabledByPermission({
    type: DisableByPermissionsType.DELETE,
  });

  const onClose = () => {
    dispatch.ctxMenu.closeAll();
  };

  const handleDelete = () => {
    onClose();
    dispatch.questionDialog.set({
      type: QuestionDialogTypes.LABELS_DELETE,
      onSuccess: () => {
        dispatch.labels.delete(selected);
      },
      extra: {
        count: selected.length,
      },
    });
  };

  return (
    <ContextMenu type={ContextMenuTypes.RECORD_TABLE}>
      <MenuList>
        <CtxMenuItem
          label={t("View details")}
          onClick={() => {
            dispatch.viewDetails.viewLabel(selected[0]);
            onClose();
          }}
          divider
          disabled={isMultiSelect}
          tooltip={t("Multiple labels selected")}
        />
        <CtxMenuItem
          label={t("Edit")}
          to={
            selected.length
              ? RouterHelper.workspaceSettingsLabelsEdit.getRelativePath({
                  props: { labelId: selected[0] },
                })
              : undefined
          }
          disabled={disabledPermissionsEdit || isMultiSelect}
          tooltip={
            disabledPermissionsEdit
              ? t("Disabled by permissions")
              : t("Multiple labels selected")
          }
        />

        <CtxMenuItem
          label={
            numSelected === 1
              ? t("Delete label")
              : t("Delete {{numSelected}} labels", { numSelected })
          }
          onClick={handleDelete}
          disabled={disabledPermissionsDelete}
          tooltip={t("Disabled by permissions")}
        />
      </MenuList>
    </ContextMenu>
  );
};
