import { TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  TemplateItemBase,
  TemplateItemBody,
  TemplateItemTitle,
} from "./TemplateItemBase";

export const TemplateRecordDescription = () => {
  const { t } = useTranslation();
  const description = useAppSelector(state => state.templateForm.draft.description);
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(
    state =>
      (state.templateForm.current !== null &&
        state.templateForm.current.published_at !== null) ||
      state.loading.models.templates.loading ||
      state.loading.models.app.loading,
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch.templateForm.setDescription(e.currentTarget.value);
  };

  return (
    <TemplateItemBase sx={{ marginTop: 3 }}>
      <TemplateItemTitle>{t("Description")}</TemplateItemTitle>

      <TemplateItemBody>
        <TextField
          disabled={disabled}
          minRows={3}
          fullWidth
          multiline
          onChange={handleChange}
          value={description}
          placeholder={t(
            "Note down details of the template of your research for future reference.",
          )}
        />
      </TemplateItemBody>
    </TemplateItemBase>
  );
};
