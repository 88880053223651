interface DownloadLocally {
  href: string;
  name: string;
}

export function downloadLocally({ href, name }: DownloadLocally) {
  const link = document.createElement("a");
  link.href = href;
  link.download = name;
  link.click();
}

interface DownloadLocallyBlob {
  blob: Blob;
  name: DownloadLocally["name"];
}

export function downloadLocallyBlob({ blob, name }: DownloadLocallyBlob) {
  const href = window.URL.createObjectURL(blob);

  downloadLocally({ href, name });

  window.URL.revokeObjectURL(href);
}
