import { ContextMenu } from "@components";
import { Button, ButtonProps, Tooltip } from "@mui/material";
import { ContextMenuTypes, useAppDispatch } from "@storeRematch";
import { FC, PropsWithChildren, ReactNode } from "react";

interface FilterPopoverProps extends ButtonProps {
  text?: ReactNode;
  filterType?: ContextMenuTypes;
  autoOpen?: boolean;
  tooltipText?: string;
}

export const FilterPopover: FC<PropsWithChildren<FilterPopoverProps>> = ({
  text = "",
  filterType = ContextMenuTypes.FILTER,
  children,
  autoOpen = false,
  disabled,
  tooltipText,
  ...buttonProps
}) => {
  const dispatch = useAppDispatch();

  return (
    <>
      <ContextMenu type={filterType}>{children}</ContextMenu>
      <Tooltip title={tooltipText} placement="top">
        <Button
          ref={autoOpen ? dispatch.ctxMenu.setAutoOpenFilterFormRef : undefined}
          onClick={
            disabled
              ? undefined
              : e =>
                  dispatch.ctxMenu.handleClick({ type: filterType, position: true, e })
          }
          {...buttonProps}
          size="small"
        >
          {text}
        </Button>
      </Tooltip>
    </>
  );
};
