import { ReactRouterLinkCustom } from "@components";
import { Box, Button, Divider, Typography } from "@mui/material";
import { RouterHelper } from "@pages";
import { useAppSelector } from "@storeRematch";
import { useTranslation } from "react-i18next";

export const AddTokenButton = () => {
  const { t } = useTranslation();
  const disabled = useAppSelector(state => state.loading.models.tokens.loading);
  const activeToken = useAppSelector(state => state.tokens.activeToken);

  return (
    <Box sx={{ flexBasis: "100%" }}>
      <Button
        color={"primary"}
        component={ReactRouterLinkCustom}
        to={RouterHelper.accountSettingsDeveloperNew.relativeTemplatePath}
        disabled={disabled}
      >
        {t("New Token")}
      </Button>
      <Typography maxWidth={600} my={2}>
        {activeToken &&
          t(
            "Make sure to copy your new API token now. You will not be able to view it again.",
          )}
      </Typography>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
    </Box>
  );
};
