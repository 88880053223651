import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  QuestionDialogConfirmDelete,
  QuestionDialogCount,
  QuestionDialogNames,
  QuestionDialogPropTypes,
  QuestionDialogTemplateFormChangeWidgetExtra,
  QuestionDialogTypes,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import { formatTemplateItemWidgetType } from "@utils/formatTemplateItemWidgetType";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  ConfirmActions,
  DefaultActions,
  DefaultActionsProps,
  DeleteActions,
  RestoreActions,
  TrashActions,
} from "./DefaultActions";
import {
  DeleteFormAction,
  DeleteFormContent,
  deleteFormSchema,
  deleteFormValues,
} from "./DeleteForm";

function multi(count: number, string: string) {
  return `${string}${count === 1 ? "" : "s"}`;
}

const components = {
  [QuestionDialogTypes.TEMPLATE_FORM_PUBLISH]: {
    Title: () => <>Publish template to Companion App?</>,
    Content: () => (
      <>
        Publishing enables you to use this template on Pupil Companion app. Once you
        publish you will not be able to edit the template. The template will be locked
        to ensure consistent data for your recordings.
      </>
    ),
    Actions: (props: DefaultActionsProps) => (
      <DefaultActions {...props} successText="Publish template" />
    ),
  },
  [QuestionDialogTypes.TEMPLATE_FORM_CHANGE_WIDGET_TYPE]: {
    Title: () => <>Warning</>,
    Content: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => (
      <>
        Only "
        <Typography component="span" fontWeight="bold">
          {formatTemplateItemWidgetType("TEXT")}
        </Typography>
        " type questions can be used in the recording name. Changing to "
        <Typography component="span" fontWeight="bold">
          {(extra as QuestionDialogTemplateFormChangeWidgetExtra).widgetTypeTo}
        </Typography>
        " will remove the answer to this question from the recording name pattern.
      </>
    ),
    Actions: DefaultActions,
  },
  [QuestionDialogTypes.TEMPLATE_FORM_UNSAVED]: {
    Title: () => <>Warning</>,
    Content: () => (
      <>
        Form has{" "}
        <Typography component="span" fontWeight="bold">
          unsaved changes
        </Typography>{" "}
        if you leave now everything will be lost.
        <Typography>Do you want to leave?</Typography>
      </>
    ),
    Actions: DefaultActions,
  },
  [QuestionDialogTypes.RECORDING_RESTORE]: {
    Title: () => <>Restore recordings from trashed</>,
    Content: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => (
      <Typography component="span">
        Are you sure you want to restore {(extra as QuestionDialogCount).count}{" "}
        {multi((extra as QuestionDialogCount).count, "recording")} from trashed?
      </Typography>
    ),
    Actions: RestoreActions,
  },
  [QuestionDialogTypes.RECORDING_TRASH]: {
    // TODO(ivan): change text after backend implementation
    // in the future -> after 30 days
    Title: () => <>Are you sure you want to trash these recordings??</>,
    Content: ({ extra: _extra }: { extra: QuestionDialogPropTypes["extra"] }) => (
      <Typography component="span">
        Recordings in trash will be automatically deleted in the future. If selected
        recordings are part of projects, these recordings will be removed from all
        projects.
      </Typography>
    ),
    Actions: TrashActions,
  },
  [QuestionDialogTypes.RECORDING_DELETE]: {
    Title: () => <>Delete recordings</>,
    Content: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => (
      <Typography component="span">
        Are you sure you want to delete {(extra as QuestionDialogCount).count}{" "}
        {multi((extra as QuestionDialogCount).count, "recording")}? You cannot undo this
        action.
      </Typography>
    ),
    Actions: DeleteActions,
  },
  [QuestionDialogTypes.PROJECTS_DELETE]: {
    Title: () => <>Delete projects</>,
    Content: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => (
      <>
        <Typography component="span">
          Are you sure you want to delete {(extra as QuestionDialogCount).count}{" "}
          {multi((extra as QuestionDialogCount).count, "project")}?
        </Typography>
        <Typography mt={1}>This will also delete:</Typography>
        <Typography mt={1} ml={2}>
          - All enrichments in the project.
        </Typography>
        <Typography mt={1} ml={2}>
          - All visualization in the project.
        </Typography>
      </>
    ),
    Actions: DeleteActions,
  },
  [QuestionDialogTypes.LABELS_DELETE]: {
    Title: () => <>Delete labels</>,
    Content: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => (
      <Typography component="span">
        Are you sure you want to delete {(extra as QuestionDialogCount).count}{" "}
        {multi((extra as QuestionDialogCount).count, "label")}?
      </Typography>
    ),
    Actions: DeleteActions,
  },
  [QuestionDialogTypes.TEMPLATES_RESTORE]: {
    Title: () => <>Restore templates from trash</>,
    Content: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => (
      <Typography component="span">
        Are you sure you want to restore {(extra as QuestionDialogCount).count}{" "}
        {multi((extra as QuestionDialogCount).count, "template")} from trash?
      </Typography>
    ),
    Actions: RestoreActions,
  },
  [QuestionDialogTypes.TEMPLATES_DELETE]: {
    Title: () => <>Delete templates</>,
    Content: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => (
      <Typography component="span">
        Are you sure you want to delete {(extra as QuestionDialogCount).count}{" "}
        {multi((extra as QuestionDialogCount).count, "template")}?
      </Typography>
    ),
    Actions: DefaultActions,
  },
  [QuestionDialogTypes.WEARERS_RESTORE]: {
    Title: () => <>Restore wearers from trash</>,
    Content: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => (
      <Typography component="span">
        Are you sure you want to restore {(extra as QuestionDialogCount).count}{" "}
        {multi((extra as QuestionDialogCount).count, "wearer")}
        from trash?
      </Typography>
    ),
    Actions: RestoreActions,
  },
  [QuestionDialogTypes.WEARERS_DELETE]: {
    Title: () => <>Delete wearers</>,
    Content: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => (
      <Typography component="span">
        Are you sure you want to delete {(extra as QuestionDialogCount).count}{" "}
        {multi((extra as QuestionDialogCount).count, "wearer")}?
      </Typography>
    ),
    Actions: DefaultActions,
  },
  [QuestionDialogTypes.EVENT_DELETE]: {
    Title: () => <>Delete event</>,
    Content: () => (
      <Typography component="span">
        Are you sure you want to delete this event?
      </Typography>
    ),
    Actions: DeleteActions,
  },
  [QuestionDialogTypes.CONFIRM_DELETE]: {
    Title: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => (
      <>Delete {(extra as QuestionDialogConfirmDelete).title}</>
    ),
    Content: () => (
      <>
        <Typography component="span">
          This action cannot be undone. All of your data will be permanently deleted.
        </Typography>
        <Typography mb={2}>Are you absolutely sure?</Typography>
        <DeleteFormContent />
      </>
    ),
    Actions: DeleteFormAction,
  },
  [QuestionDialogTypes.MEMBERS_DELETE]: {
    Title: () => <>Delete members</>,
    Content: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => (
      <>
        <Typography component="span">Are you sure you want to delete?</Typography>
        <Typography mt={2}>This action cannot be undone.</Typography>
        <Typography mt={2}>
          {(extra as QuestionDialogNames).names.join(", ")}
        </Typography>
      </>
    ),
    Actions: DeleteActions,
  },
  [QuestionDialogTypes.TOKEN_DELETE]: {
    Title: () => <>Delete tokens</>,
    Content: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => (
      <>
        <Typography component="span">
          Are you sure you want to delete {(extra as QuestionDialogCount).count}{" "}
          {multi((extra as QuestionDialogCount).count, "token")}?
        </Typography>
        <Typography mt={2}>
          Any applications or scripts using this token will no longer be able to access
          Pupil Cloud API. You cannot undo this action.
        </Typography>
      </>
    ),
    Actions: DeleteActions,
  },
  [QuestionDialogTypes.ENRICHMENT_DELETE]: {
    Title: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => {
      const isVisualizations = document.URL.includes("/visualizations");
      const text =
        (extra as QuestionDialogCount).count ||
        (extra as QuestionDialogConfirmDelete).title;
      const isNumber = !isNaN((extra as QuestionDialogCount).count);
      const type = isVisualizations ? "visualization" : "enrichment";

      return (
        <>
          Delete{" "}
          {isNumber
            ? `${multi((extra as QuestionDialogCount).count, type)}`
            : `${type} "${text}"`}
        </>
      );
    },
    Content: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => {
      const isVisualizations = document.URL.includes("/visualizations");
      const isNumber = !isNaN((extra as QuestionDialogCount).count);

      if (isNumber) {
        return (
          <Typography component="span">
            Are you sure you want to delete {(extra as QuestionDialogCount).count}{" "}
            {multi(
              (extra as QuestionDialogCount).count,
              isVisualizations ? "visualization" : "enrichment",
            )}
            ?
          </Typography>
        );
      }

      return (
        <Typography component="span">
          Are you sure you want to delete this{" "}
          {isVisualizations ? "visualization" : "enrichment"}?
        </Typography>
      );
    },
    Actions: DeleteActions,
  },
  [QuestionDialogTypes.VISUALIZATION_DELETE]: {
    Title: () => {
      return <>Delete visualizations</>;
    },
    Content: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => {
      return (
        <Typography component="span">
          Are you sure you want to delete {(extra as QuestionDialogCount).count}{" "}
          {multi((extra as QuestionDialogCount).count, "visualization")}?
        </Typography>
      );
    },
    Actions: DeleteActions,
  },
  [QuestionDialogTypes.AOI_DELETE]: {
    Title: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => (
      <>Delete "{(extra as QuestionDialogConfirmDelete).title}"</>
    ),
    Content: () => (
      <>
        <Typography component="span">Are you sure you want to delete?</Typography>
        <Typography mt={2}>This action cannot be undone.</Typography>
      </>
    ),
    Actions: DeleteActions,
  },
  [QuestionDialogTypes.AOIS_DELETE]: {
    Title: ({ extra }: { extra: QuestionDialogPropTypes["extra"] }) => (
      <>Are you sure you want to delete {(extra as QuestionDialogCount).count} AOIs?</>
    ),
    Content: () => (
      <>
        <Typography>This action cannot be undone.</Typography>
      </>
    ),
    Actions: DeleteActions,
  },
  [QuestionDialogTypes.DRAW_AREA_CLEAR]: {
    Title: () => <>Are you sure you want to clear this AOI?</>,
    Content: () => (
      <>
        <Typography color="text.secondary">This action cannot be undone.</Typography>
      </>
    ),
    Actions: ConfirmActions,
  },
  [QuestionDialogTypes.CLEAR_ALL_RECORDING_MAPPINGS]: {
    Title: () => <>Are you sure you want to clear all recording mappings?</>,
    Content: () => (
      <>
        <Typography color="text.secondary">This action cannot be undone.</Typography>
      </>
    ),
    Actions: ConfirmActions,
  },
  [QuestionDialogTypes.GAZE_OFFSET_CHANGE]: {
    Title: () => <>Confirm gaze offset</>,
    Content: () => (
      <>
        <Typography color="text.secondary">
          Modifying the gaze offset in this recording impacts all derived data, such as
          fixation detection, mapped gaze from enrichments, and visualizations.
        </Typography>
        <br />
        <Typography color="text.secondary">
          Where possible, derived data is updated instantly. If not, the respective data
          will be{" "}
          <Typography fontWeight="bold" component="span">
            deleted
          </Typography>
          , requiring (partial) re-computation of enrichments or visualizations.
        </Typography>
      </>
    ),
    Actions: ConfirmActions,
  },
};

const schemas: Partial<Record<QuestionDialogTypes, any>> = {
  [QuestionDialogTypes.CONFIRM_DELETE]: deleteFormSchema,
};

const values: Partial<Record<QuestionDialogTypes, any>> = {
  [QuestionDialogTypes.CONFIRM_DELETE]: deleteFormValues,
};

export const QuestionDialog = () => {
  const state = useAppSelector(state => state.questionDialog);
  const dispatch = useAppDispatch();
  const open = state.open;
  const type = state.props?.type;

  const methods = useForm({
    resolver: type && schemas[type] ? yupResolver(schemas[type]) : undefined,
    values: type && values[type] ? values[type] : undefined,
    mode: "onChange",
  });

  const onCancel = () => {
    dispatch.questionDialog.reset();
  };

  const onSuccess = () => {
    state.props?.onSuccess?.();
    onCancel();
  };

  useEffect(() => {
    if (!open) {
      methods.reset();
    }
  }, [methods, open]);

  if (!open) {
    return null;
  }

  if (type === undefined || type === null) {
    return null;
  }

  const Title = components[type].Title;
  const Content = components[type].Content;
  const Actions = components[type].Actions;

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSuccess)} noValidate>
          <DialogTitle>
            <Title extra={state.props?.extra} />
          </DialogTitle>
          <DialogContent>
            <Content extra={state.props?.extra} />
          </DialogContent>
          <DialogActions>
            <Actions onSuccess={onSuccess} onCancel={onCancel} />
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
