import { ReactRouterLinkCustom } from "@components/ReactRouterLinkCustom";
import { Close } from "@mui/icons-material";
import { Button, Card, IconButton, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { SnackbarNotification } from "@storeRematch";
import {
  CustomContentProps,
  SnackbarContent,
  VariantType,
  useSnackbar,
} from "notistack";
import { forwardRef, useCallback } from "react";

interface SnackbarNotifierProps extends CustomContentProps {
  linkComponent: SnackbarNotification["options"]["linkComponent"];
  buttonComponent: SnackbarNotification["options"]["buttonComponent"];
}

const Root = styled(Card, {
  shouldForwardProp: prop => prop !== "customVariant",
})<{
  customVariant?: VariantType;
}>(({ theme, customVariant }) => {
  let bgColor = theme.palette.success.main;

  switch (customVariant) {
    case "success":
      bgColor = theme.palette.success.main;
      break;
    case "error":
      bgColor = theme.palette.error.main;
      break;
    case "warning":
      bgColor = theme.palette.warning.main;
      break;
    case "default":
      bgColor = theme.palette.default.main;
      break;
    default:
      bgColor = theme.palette.info.main;
      break;
  }

  return {
    backgroundColor: bgColor,
    height: "100%",
    minWidth: "6px",
  };
});

const CustomButton = styled(Button)(({ theme }) => {
  return {
    borderColor: "rgba(255,255,255, 0.25)",
    marginTop: theme.spacing(1),
  };
});
CustomButton.defaultProps = {
  size: "small",
  color: "inherit",
  variant: "outlined",
};

export const SnackbarNotifier = forwardRef<HTMLDivElement, SnackbarNotifierProps>(
  ({ id, message, variant, persist, linkComponent, buttonComponent }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref}>
        <Root
          customVariant={variant}
          elevation={2}
          sx={{ display: "flex", width: "25rem" }}
        >
          <Box py={1} px={2} width={"inherit"}>
            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
              <Typography
                variant="body1"
                sx={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 5,
                  wordBreak: "break-word",
                  alignSelf: "center",
                  textTransform: "capitalize",
                }}
              >
                {message}
              </Typography>
              {persist && (
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleDismiss();
                    }}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                </Box>
              )}
            </Box>
            {linkComponent && (
              <CustomButton
                // @ts-ignore
                component={ReactRouterLinkCustom}
                to={linkComponent.to}
                onClick={() => {
                  linkComponent?.onClick?.();
                  handleDismiss();
                }}
              >
                {linkComponent.text}
              </CustomButton>
            )}
            {buttonComponent && (
              <CustomButton
                onClick={() => {
                  buttonComponent?.onClick?.();
                  handleDismiss();
                }}
              >
                {buttonComponent.text}
              </CustomButton>
            )}
          </Box>
        </Root>
      </SnackbarContent>
    );
  },
);
