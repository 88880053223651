import { RoutePage } from "@components";
import { RouteObject, RouteProps, generatePath, matchPath } from "react-router-dom";
import { generatePageTitle } from "./generatePageTitle";

type GetFullPathParams<P, U extends Base<any, any> | undefined> = U extends Base<
  any,
  any
>
  ? P & Parameters<U["getFullPath"]>[0]["props"]
  : P;

type PathFunctionProps<T> = {
  prefix?: string;
  suffix?: string;
  props?: T;
};

abstract class Base<
  N extends string,
  U extends Base<any, any> | undefined,
  P = Record<N, string | undefined>,
> {
  public parent?: U;
  public fullTemplatePath = "";
  public fullTemplatePathAny = "";
  public relativeTemplatePath = "";
  public relativeTemplatePathAny = "";
  public title = "";
  public Element: React.ComponentType;

  constructor({
    path,
    title,
    parent,
    Element,
  }: RouteProps & { parent?: U; title?: string; Element: React.ComponentType }) {
    const checkedPath = path || "";

    this.parent = parent;
    this.fullTemplatePath = parent
      ? `${parent.fullTemplatePath}/${checkedPath}`
      : checkedPath;
    this.fullTemplatePathAny = `${this.fullTemplatePath}/*`;
    this.relativeTemplatePath = checkedPath;
    this.relativeTemplatePathAny = `/${this.relativeTemplatePath}/*`;
    this.title = generatePageTitle(title);
    this.Element = Element;
  }

  getFullPath(): string;
  getFullPath(props: PathFunctionProps<GetFullPathParams<P, U>>): string;
  getFullPath(props?: PathFunctionProps<GetFullPathParams<P, U>>): string {
    const template = this.fullTemplatePath;
    const path = props
      ? `${props.prefix || ""}${template}${props.suffix || ""}`
      : template;

    return generatePath(path, props?.props as any);
  }

  getRelativePath(): string;
  getRelativePath(props: PathFunctionProps<P>): string;
  getRelativePath(props?: PathFunctionProps<P>): string {
    const template = this.relativeTemplatePath;
    const path = props
      ? `${props.prefix || ""}${template}${props.suffix || ""}`
      : template;

    return generatePath(path, props?.props as any);
  }

  getRouterProps(children?: Array<RoutingHelper<any, any> | RouteObject>): RouteObject {
    const Component = this.Element;

    return {
      path: this.relativeTemplatePath,
      element: this.title ? (
        <RoutePage title={this.title}>
          <Component />
        </RoutePage>
      ) : (
        <Component />
      ),
      children: children
        ? children.map(c => (c instanceof RoutingHelper ? c.getRouterProps() : c))
        : undefined,
    };
  }

  matchCurrentPath() {
    return !!matchPath(this.fullTemplatePath, document.location.pathname);
  }
}

export class RoutingHelper<N extends string, U extends Base<any, any>> extends Base<
  N,
  U
> {}
