import { Typography } from "@mui/material";
import { memo } from "react";

export const NoDataMembers = memo(() => {
  return (
    <>
      <Typography sx={{ textAlign: "center" }}>
        No members in this workspace.
      </Typography>
      <Typography sx={{ textAlign: "center" }}>Invite someone.</Typography>
    </>
  );
});
