import { createModel } from "@rematch/core";

import { LocalStorageAdapterNames, localStorageAdapter } from "@utils";
import type { RootModel } from "./index";
import { DraggerTypes } from "./index";

interface StateConfig {
  size: number;
  min: number;
  max: number;
  percentage: number;
}

type InitialState = Record<DraggerTypes, StateConfig>;

function getInitialState(): InitialState {
  const store = localStorageAdapter.get<InitialState | undefined>(
    LocalStorageAdapterNames.draggerConfig,
  );

  return {
    video: getOfHeightVideo(),
    table: getOfWidth(),
    tableProject: getOfWidth(45, undefined, 75),
    ...(store ? store : {}),
    videoFullScreen: getOfHeightVideoFullScreen(),
  };
}

const initialState = getInitialState();

export const dragger = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resize(
      state,
      {
        size,
        percentage,
        type,
      }: { size: number; percentage: number; type: DraggerTypes },
    ) {
      const config = state[type];

      if (size >= config.min && size <= config.max) {
        config.size = size;
        config.percentage = percentage;
        localStorageAdapter.set(LocalStorageAdapterNames.draggerConfig, state);
      }
    },
    tryToRecalculate(state) {
      for (const [key] of Object.entries(state)) {
        const keyTyped = key as DraggerTypes;
        const sizeOf =
          keyTyped === DraggerTypes.VIDEO ? window.innerHeight : window.innerWidth;

        state[keyTyped].size = sizeOf * state[keyTyped].percentage;
      }
      localStorageAdapter.set(LocalStorageAdapterNames.draggerConfig, state);
    },
    resetVideoFullScreen(state) {
      state.videoFullScreen = getOfHeightVideoFullScreen();
    },
    reset() {
      return { ...getInitialState() };
    },
  },
});

function getOfWidth(num = 50, min = 15, max = 85): StateConfig {
  const calcSize = window.innerWidth;

  return {
    size: (calcSize * num) / 100,
    min: (calcSize * min) / 100,
    max: (calcSize * max) / 100,
    percentage: num / 100,
  };
}

function getOfHeightVideo(): StateConfig {
  const calcSize = window.innerHeight - 48;
  const maxP = 100 - calcSize / 75;

  return {
    size: (calcSize * 55) / 100,
    min: (calcSize * 30) / 100,
    max: (calcSize * maxP) / 100,
    percentage: 55 / 100,
  };
}

function getOfHeightVideoFullScreen(): StateConfig {
  const calcSize = window.innerHeight;
  const max = calcSize - 45;

  return {
    size: max,
    min: (calcSize * 30) / 100,
    max: max,
    percentage: max / calcSize,
  };
}
