import { ErrorPage } from "@components";
import { WearerForm } from "@pages/workspaces/settings/wearers/WearerForm";
import { useAppSelector } from "@storeRematch";
import { useNavigate, useParams } from "react-router-dom";

export const WearerEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const wearerId = String(params.wearerId);
  const wearer = useAppSelector(state => state.wearers.dataById.get(wearerId));
  const isLoading = useAppSelector(
    state => state.loading.models.wearers.loading || state.loading.models.app.loading,
  );

  if (!wearer && !isLoading) {
    return <ErrorPage title={`Wearer ${wearerId} not found in this workspace`} />;
  }

  return <WearerForm wearer={wearer} onClose={() => navigate(-1)} open={true} />;
};
