import { FILTER_DATE_FORMAT, READABLE_DATE_FORMAT } from "@constants";
import { Button, IconButton, Paper, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, StaticDatePicker } from "@mui/x-date-pickers";
import { format, isValid } from "date-fns";
import { FC, PropsWithChildren, useState } from "react";

export const CustomDatePicker = ({
  value,
  onClick,
}: {
  value: string;
  onClick: (value: string | null) => void;
}) => {
  const [error, setError] = useState(false);
  const [textValue, setTextValue] = useState(
    value || format(new Date(), FILTER_DATE_FORMAT),
  );
  const [keyboardInputValue, setKeyboardInputValue] = useState<string | undefined>(
    value || format(new Date(), FILTER_DATE_FORMAT),
  );

  const handleClick = (next: string | Date | null) => () => {
    onClick(next ? format(new Date(next), FILTER_DATE_FORMAT) : "");
  };

  return (
    <>
      <Box sx={{ px: 3 }}>
        <DatePicker
          value={keyboardInputValue}
          onChange={(nextValue, input) => {
            if (nextValue && isValid(nextValue) && input?.length === 10) {
              const valid = format(new Date(nextValue), FILTER_DATE_FORMAT);

              setTextValue(valid);
            } else {
              setTextValue("");
            }

            setKeyboardInputValue(nextValue as any);
          }}
          inputFormat={READABLE_DATE_FORMAT}
          disableOpenPicker
          renderInput={props => <TextField {...props} fullWidth color="primary" />}
          onError={reason => setError(!reason)}
        />
      </Box>
      <StaticDatePicker
        label="Date desktop"
        inputFormat={READABLE_DATE_FORMAT}
        value={textValue}
        onChange={() => {}}
        renderInput={() => <></>}
        displayStaticWrapperAs="desktop"
        renderDay={(day, _selectedDays, pickersDayProps) => {
          const dayTyped = new Date(pickersDayProps.day as any);
          const currentDay = format(dayTyped, "d");

          return (
            <DayContent
              key={day}
              day={currentDay}
              selected={pickersDayProps.selected}
              show={!pickersDayProps.outsideCurrentMonth}
              onClick={() => {
                const nextValue = format(dayTyped, FILTER_DATE_FORMAT);

                setKeyboardInputValue(nextValue);
                setTextValue(nextValue);
              }}
            />
          );
        }}
        components={{ PaperContent }}
      />
      <Button
        color="primary"
        sx={{ ml: "auto", mr: 3, mt: 1 }}
        onClick={handleClick(textValue)}
        disabled={error || !textValue}
      >
        Apply
      </Button>
    </>
  );
};

const PaperContent: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Paper elevation={8} sx={{ borderRadius: 0, height: 290 }}>
      {children}
    </Paper>
  );
};

const DayContent: FC<{
  day: string;
  selected?: boolean;
  show?: boolean;
  onClick: () => void;
}> = ({ day, selected, show, onClick }) => {
  return (
    <IconButton
      sx={{
        width: 36,
        height: 36,
        mx: 0.2,
        border: selected ? 1 : "none",
        borderColor: "primary.main",

        "&:hover": {
          backgroundColor: "primary.main",
        },
      }}
      disabled={!show}
      size="small"
      onClick={onClick}
    >
      {show && day}
    </IconButton>
  );
};
