import {
  api,
  PatchWearerApiArg,
  PostWearerApiArg,
  Wearer,
  WearerPatchResponse,
} from "@api";
import { createModel } from "@rematch/core";
import { rematchAdapter, RematchAdapter } from "@utils";
import i18n from "../i18n";
import type { RootModel, WearersTableData } from "./index";

interface Adapter extends RematchAdapter<Wearer, WearersTableData> {}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.id,
  extraProps: {},
});

export const wearers = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
  },
  effects: dispatch => ({
    async get(workspaceId: string) {
      const data = await api.getWearers({ workspaceId });

      dispatch.wearers.setAll(data.result);
    },
    async create(props: PostWearerApiArg) {
      const data = await api.postWearer(props);

      if (typeof data === "object") {
        dispatch.wearers.upsertOne(data.result);
      }

      dispatch.wearers.prepare(null);
    },
    async update(props: PatchWearerApiArg) {
      const data = await api.patchWearer(props);

      dispatch.wearers.upsertOne(data.result);
      dispatch.wearers.prepare(null);
      dispatch.recordings.prepare(null);
    },
    async updateInactive(
      { inactive, ids }: { inactive: boolean; ids: string[] },
      state,
    ) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const promises: Promise<WearerPatchResponse>[] = [];

      if (!workspaceId) return;

      ids.forEach(id => {
        promises.push(
          api.patchWearer({
            workspaceId,
            wearerId: id,
            wearerPatchRequest: {
              id,
              archived_at: inactive ? new Date().toISOString() : null,
            },
          }),
        );
      });

      try {
        const res = await Promise.all(promises);

        dispatch.wearers.updateMany(res.map(r => r.result));
        dispatch.wearers.prepare(null);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t(inactive ? "Wearers trashed" : "Wearers restored"),
          options: {
            variant: "success",
            buttonComponent: inactive
              ? {
                  text: i18n.t("Undo"),
                  onClick: () => {
                    dispatch.wearers.updateInactive({
                      ids,
                      inactive: false,
                    });
                  },
                }
              : undefined,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async delete(ids: string[], state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;

      if (!workspaceId) return null;

      try {
        await api.deleteWearers({
          workspaceId,
          wearersDeleteRequest: { wearer_ids: ids },
        });

        dispatch.wearers.deleteMany(ids);
        dispatch.wearers.prepare(null);
        dispatch.recordings.prepare(null);

        const message = ids.length
          ? i18n.t("Wearers deleted").toString()
          : i18n.t("Wearer deleted").toString();
        dispatch.notifier.enqueueSnackbar({
          message,
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    prepare(_, state) {
      const wearers = state.wearers.data;
      const counters = state.recordings.counters;
      const tableDataById = new Map<string, WearersTableData>();

      const tableData: WearersTableData[] = wearers.map(wearer => {
        const next: WearersTableData = {
          id: wearer.id,
          wearer,
          recordingsCount: counters.wearers[wearer.id]?.count || 0,
        };
        tableDataById.set(wearer.id, next);
        return next;
      });

      dispatch.wearers.setTableData(tableData);
      dispatch.wearers.setTableDataById(tableDataById);
    },
  }),
});
