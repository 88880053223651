import { CopyIcon } from "@customIcons";
import { IconButton } from "@mui/material";
import { copyText } from "@utils";
import { useTranslation } from "react-i18next";

export interface CopyTextProps {
  copy?: { title: string; text: string };
  disabled?: boolean;
}

export const CopyText = ({ copy, disabled }: CopyTextProps) => {
  const { t } = useTranslation();

  if (!copy) return null;

  return (
    <IconButton
      size="small"
      disabled={disabled}
      onClick={() => copyText(t(`${copy.title} ID copied`), copy.text)}
    >
      <CopyIcon fontSize="small" />
    </IconButton>
  );
};
