import {
  GetApplyQuickFilterFn,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GridFilterItem,
  GridFilterOperator,
} from "@mui/x-data-grid-pro";
import { RecordingWithData } from "@storeRematch";
import { sanitizeForRegex } from "@utils";

enum CustomFilterTypes {
  RECORDING_LABELS = "recordingLabels",
  WEARER_NAME = "wearerName",
  TEMPLATE_NAME = "templateName",
  CREATED_BY = "createdBy",
  PROJECTS = "projects",
}

const filteredDateOperators = getGridDateOperators().filter(
  o => o.value === "is" || o.value === "before" || o.value === "after",
);

export function getOperatorsByType(type?: string) {
  switch (type) {
    case "number":
      return getGridNumericOperators();
    case "boolean":
      return getGridBooleanOperators();
    case "dateTime":
    case "date":
      return filteredDateOperators;
    case CustomFilterTypes.RECORDING_LABELS:
      return recordingLabelsFilterOperatorsParams().filterOperators;
    case CustomFilterTypes.WEARER_NAME:
      return recordingWearerFilterOperatorsParams().filterOperators;
    case CustomFilterTypes.TEMPLATE_NAME:
      return recordingTemplateNameFilterOperatorsParams().filterOperators;
    case CustomFilterTypes.CREATED_BY:
      return recordingCreatedByFilterOperatorsParams().filterOperators;
    case CustomFilterTypes.PROJECTS:
      return recordingProjectsFilterOperatorsParams().filterOperators;
    case "string":
    default:
      return getGridStringOperators();
  }
}

export function isCustomFilter({ type, field }: { type?: string; field?: string }) {
  const isLabel = type === CustomFilterTypes.RECORDING_LABELS;
  const isDate = type === "date" || type === "dateTime";
  const isDuration = field === "duration_ns";
  const isWearers = type === CustomFilterTypes.WEARER_NAME;
  const isTemplateName = type === CustomFilterTypes.TEMPLATE_NAME;
  const isCreatedBy = type === CustomFilterTypes.CREATED_BY;
  const isProjects = type === CustomFilterTypes.PROJECTS;
  const isArray = isCreatedBy || isTemplateName || isWearers || isLabel || isProjects;

  return {
    isLabel,
    isDate,
    isDuration,
    isWearers,
    isTemplateName,
    isCreatedBy,
    isProjects,
    isArray,
  };
}

export function recordingProjectsFilterOperatorsParams() {
  return arrayArrayFilterOperatorsParams(CustomFilterTypes.PROJECTS, p => p.projects);
}

export function recordingLabelsFilterOperatorsParams() {
  return arrayArrayFilterOperatorsParams(
    CustomFilterTypes.RECORDING_LABELS,
    p => p.labels,
  );
}

export function arrayArrayFilterOperatorsParams<T extends { id: string; name: string }>(
  type: string,
  getter: (params: RecordingWithData) => T[],
): {
  type: any;
  filterOperators: GridFilterOperator<RecordingWithData, any, any>[];
  getApplyQuickFilterFn: GetApplyQuickFilterFn<RecordingWithData, any>;
} {
  return {
    type,
    getApplyQuickFilterFn(value: string) {
      if (!value) return null;

      return (_, params) => {
        const model: T | undefined = getter(params).find(l => l.name.includes(value));
        return !!model;
      };
    },
    filterOperators: [
      {
        label: "is one of",
        value: "isOneOf",
        getApplyFilterFn(item: GridFilterItem) {
          if (!item.value) return null;

          return (_, params) => {
            const model: T | undefined = getter(params).find(l =>
              item.value.find(({ name }: Pick<T, "name" | "id">) => name === l.name)
                ? true
                : false,
            );
            return !!model;
          };
        },
      },
      {
        label: "is not",
        value: "isNot",
        getApplyFilterFn(item: GridFilterItem) {
          if (!item.value) return null;

          return (_, params) => {
            const model: T | undefined = getter(params).find(l =>
              item.value.find(({ name }: Pick<T, "name" | "id">) => name === l.name)
                ? true
                : false,
            );

            return !model;
          };
        },
      },
    ],
  };
}

export function recordingWearerFilterOperatorsParams() {
  return stringArrayFilterOperatorsParams(CustomFilterTypes.WEARER_NAME);
}

export function recordingTemplateNameFilterOperatorsParams() {
  return stringArrayFilterOperatorsParams(CustomFilterTypes.TEMPLATE_NAME);
}

export function recordingCreatedByFilterOperatorsParams() {
  return stringArrayFilterOperatorsParams(CustomFilterTypes.CREATED_BY);
}

function stringArrayFilterOperatorsParams<T extends { id: string; name: string }>(
  type: string,
): {
  type: any;
  filterOperators: GridFilterOperator<RecordingWithData, any, any>[];
} {
  return {
    type,
    filterOperators: [
      {
        label: "is one of",
        value: "isOneOf",
        getApplyFilterFn(item, column) {
          if (!item.value || !column.valueGetter) return null;

          return value => {
            const model: T | undefined = item.value.find(
              ({ name }: Pick<T, "name" | "id">) => name === value,
            );

            return !!model;
          };
        },
      },
      {
        label: "is not",
        value: "isNot",
        getApplyFilterFn(item, column) {
          if (!item.value || !column.valueGetter) return null;

          return value => {
            const model: T | undefined = item.value.find(
              ({ name }: Pick<T, "name" | "id">) => name === value,
            );

            return !model;
          };
        },
      },
      {
        label: "contains",
        value: "contains",
        getApplyFilterFn(item, column) {
          if (!item.value || !column.valueGetter) return null;

          return value => {
            return value.match(new RegExp(sanitizeForRegex(item.value), "i"));
          };
        },
      },
    ],
  };
}

export function isOperatorExcluded(name: string) {
  if (name === "isEmpty" || name === "isNotEmpty" || name === "isAnyOf") return true;

  return false;
}
