import { Label } from "@api";
import { DataAsList } from "@components/DataAsList";
import { useAppSelector } from "@storeRematch";
import { FC } from "react";

export const LabelsAsList: FC<{
  onClick: (checked: boolean, model: Label) => void;
  localState?: Record<string, { checked: boolean; indeterminate: boolean }>;
  onNothingFound?: (search: string | null) => void;
  onEnter?: (search: string) => void;
}> = ({ localState, onClick, onNothingFound, onEnter }) => {
  const tableData = useAppSelector(state => state.labels.tableData);

  return (
    <DataAsList
      localState={localState}
      tableData={tableData}
      getIdValue={t => t.label.id}
      getModel={t => t.label}
      getSearchValue={t => t.label.name}
      onClick={onClick}
      onNothingFound={onNothingFound}
      onEnter={onEnter}
    />
  );
};
