import { VIDEO_TIMELINE_SCROLL_ROOT } from "@hooks/useGetVideoTimes";
import { createModel } from "@rematch/core";
import { createVideoSources } from "@utils";
import { FixedSizeList } from "react-window";
import { store, type RootModel } from ".";
import { DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_OPEN_HEIGHT } from "./videoEvents";

interface InitialState {
  count: number;
  averageTime: number;
  listRef: FixedSizeList | null;
  hide: boolean;
  instance: HTMLVideoElement | null;
  query: Map<number, number>;
  images: Map<number, { canvas: HTMLCanvasElement; done: boolean }>;
}

const initialState: InitialState = {
  count: 0,
  averageTime: 0,
  listRef: null,
  hide: false,
  instance: null,
  query: new Map(),
  images: new Map(),
};

let currentIndex = 0;
let prevAverageTime = 0;

export const filmstrip = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setState: (state, data: Partial<InitialState>) => {
      return { ...state, ...data };
    },
    setListRef(state, data: InitialState["listRef"]) {
      state.listRef = data;
    },
    setHide(state, data: InitialState["hide"]) {
      if (state.hide !== data) state.hide = data;
    },
    setInstance(state, data: InitialState["instance"]) {
      state.instance = data;
    },
    addToQuery(state, data: number) {
      if (!state.query.has(data)) state.query.set(data, data);
    },
    removeFromQuery(state, data: number) {
      if (state.query.has(data)) state.query.delete(data);
    },
    addToImages(
      state,
      {
        index,
        data,
      }: { index: number; data: { canvas: HTMLCanvasElement; done: boolean } },
    ) {
      if (!state.images.has(index)) state.images.set(index, data);
    },
    removeFromImages(state, data: number) {
      if (state.images.has(data)) state.images.delete(data);
    },
    setImageDone(state, { index, done }: { index: number; done: boolean }) {
      if (state.images.has(index)) {
        const item = state.images.get(index);

        if (item) item.done = done;
      }
    },
    resetImages(state) {
      const next: InitialState["images"] = new Map();
      state.images.forEach((v, k) => {
        v.canvas.getContext("2d")?.clearRect(0, 0, v.canvas.width, v.canvas.height);
        next.set(k, { canvas: v.canvas, done: false });
      });
      state.images = next;
    },
  },
  effects: dispatch => ({
    prepare(_, state) {
      const video = document.createElement("video");
      const instance = state.filmstrip.instance;
      const recording = state.video.recording;
      const averageTime = state.filmstrip.averageTime;

      if (!recording) return;

      const src = createVideoSources(recording, true)[0].src;

      if (instance && src === instance.src && averageTime === prevAverageTime) return;

      prevAverageTime = averageTime;

      dispatch.filmstrip.setInstance(video);
      dispatch.filmstrip.resetImages();

      video.onloadedmetadata = () => {
        dispatch.filmstrip.startNext(null);
      };
      video.ontimeupdate = () => {
        const canvas = store.getState().filmstrip.images.get(currentIndex)?.canvas;
        canvas?.getContext("2d")?.drawImage(video, 0, 0, canvas.width, canvas.height);
        dispatch.filmstrip.setImageDone({ index: currentIndex, done: true });
        dispatch.filmstrip.startNext(null);
      };
      video.onerror = () => {};
      video.src = src;
    },
    recalculate(drop: boolean, state) {
      const root = document.getElementById(VIDEO_TIMELINE_SCROLL_ROOT);
      const duration = state.video.zoom.time.end;

      if (!root) return;

      const count = Math.ceil(
        root.offsetWidth / DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_OPEN_HEIGHT,
      );
      const averageTime = duration / count;

      dispatch.filmstrip.setState({ count, averageTime, hide: false });

      if (drop) prevAverageTime = 0;
      if (averageTime) dispatch.filmstrip.prepare(null);
    },
    scroll(scrollTo: number, state) {
      const ref = state.filmstrip.listRef;

      if (ref) ref.scrollTo(scrollTo);
    },
    startNext(_, state) {
      const instance = state.filmstrip.instance;
      const query = state.filmstrip.query;
      const images = state.filmstrip.images;
      const averageTime = state.filmstrip.averageTime;

      if (!instance) return;

      for (let [key] of query) {
        currentIndex = key;

        if (images.has(currentIndex) && images.get(currentIndex)?.done) continue;

        if (currentIndex === 0) {
          instance.currentTime = averageTime / 2;
        } else {
          instance.currentTime = averageTime * currentIndex + averageTime / 2;
        }

        break;
      }
    },
  }),
});
