import { Recording } from "@api";
import { store } from "@storeRematch";
import videojs from "video.js";

export function createVideoSources(
  recording: Recording,
  small?: boolean,
): videojs.Tech.SourceObject[] {
  const apiKey = store.getState().app.apiKey;
  const url = small ? recording.scene_thumb_url : recording.transcoded_url;
  let src = "";

  if (url) {
    const obj = new URL(url);

    if (apiKey) {
      obj.searchParams.set("api-key", apiKey);
    }

    src = obj.toString();
  }

  return [
    {
      src,
      type: "video/mp4",
    },
  ];
}
