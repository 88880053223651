import { VIDEO_TIMELINE_SCROLL_ROOT } from "@hooks/useGetVideoTimes";
import { Box, Typography } from "@mui/material";
import { store, useAppDispatch, useAppSelector } from "@storeRematch";
import {
  DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT,
  DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_OPEN_HEIGHT,
} from "@storeRematch/videoEvents";
import { themes } from "@styles/theme";
import { createVideoSources, isScrollDisabled, moveZoomHorizontalScroll } from "@utils";
import { memo, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import videojs from "video.js";
import { PositionLine } from "./VideoComponentsBase";
import { VideoJS } from "./VideoJs";

const svg = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve" height="100px" width="100px">
<path d="M5 0H2V10.8878L3 12H4L5 10.8878V0Z" fill="${themes.dark.custom.palette.primary.main}" />
</svg>`;
const img = new Image();
img.src = `data:image/svg+xml;base64,${window.btoa(svg)}`;

let requestAnimationFrameId: number | null = null;
let per: number | null = null;

const CurrentPositionCanvas = memo(() => {
  const ref = useRef<HTMLCanvasElement | null>(null);
  const currentLineCanvasRef = useAppSelector(
    state => state.video.currentLineCanvasRef,
  );
  const zoomOffset = useAppSelector(state => state.video.zoom.zoomOffset);
  const zoomOffsetUpdateCurrentPosition = useAppSelector(
    state => state.video.zoom.zoomOffsetUpdateCurrentPosition,
  );
  const instance = useAppSelector(state => state.video.instance);
  const size = useAppSelector(
    state =>
      state.dragger.table.size +
      state.dragger.tableProject.size +
      state.dragger.video.size +
      state.dragger.videoFullScreen.size +
      Number(state.video.fullScreen),
  );
  const enrichment = useAppSelector(state => !state.projectEdit.currentEnrichment);

  useEffect(() => {
    return () => {
      per = null;
      requestAnimationFrameId = null;
    };
  }, []);

  useLayoutEffect(() => {
    if (!instance) return;

    const update = () => {
      const current = ref.current;
      const parentElement = current?.parentElement;
      const maxWidth = parentElement?.offsetWidth || 0;
      const scrollRootWidth =
        document.getElementById(VIDEO_TIMELINE_SCROLL_ROOT)?.offsetWidth || 0;
      const zoomOffset = store.getState().video.zoom.zoomOffset;

      if (!current || !parentElement || !instance || instance.isDisposed()) return;

      const ratio = window.devicePixelRatio;

      current.style.width = `${parentElement.offsetWidth}px`;
      current.style.height = `${parentElement.offsetHeight}px`;
      current.width = parentElement.offsetWidth * ratio;
      current.height = parentElement.offsetHeight * ratio;

      const height = current.height;
      const ctx = current.getContext("2d");

      if (!ctx) return;

      ctx.scale(window.devicePixelRatio, window.devicePixelRatio);

      const duration = instance.duration();
      const currentTime = instance.currentTime();

      if (isNaN(duration) || isNaN(currentTime)) return;

      const next = (currentTime / duration) * 100;
      const posInPx = (scrollRootWidth * next) / 100 - zoomOffset;
      const widthPer = posInPx / maxWidth;
      const isInMargin = widthPer >= 0.491 && widthPer < 0.519;

      if (!isInMargin || instance.paused()) per = null;
      if (isInMargin && (!per || per < widthPer)) per = widthPer;

      const checkedPosInPx =
        isInMargin && per ? maxWidth * per : posInPx === 0 ? 0.5 : posInPx;

      ctx.lineWidth = 1.5;
      ctx.fillStyle = themes.dark.custom.palette.primary.main;
      ctx.strokeStyle = themes.dark.custom.palette.primary.main;

      ctx.clearRect(0, 0, current.width, current.height);

      if (maxWidth < checkedPosInPx || checkedPosInPx < 0) {
        if (!isScrollDisabled()) moveZoomHorizontalScroll();
        return;
      }

      ctx.beginPath();
      ctx.drawImage(img, checkedPosInPx - 3.5, 0);
      ctx.moveTo(checkedPosInPx, 0);
      ctx.lineTo(checkedPosInPx, height);

      ctx.fill();
      ctx.stroke();

      if (!isScrollDisabled()) moveZoomHorizontalScroll();
    };

    update();

    const timeUpdate = () => {
      if (!instance.paused()) return;
      store.dispatch.video.setTimeToCurrent();
      update();
    };

    const play = () => {
      if (instance && (instance.isDisposed() || instance.paused())) {
        return;
      }

      update();

      requestAnimationFrameId = requestAnimationFrame(play);
    };

    const pause = () => {
      if (requestAnimationFrameId === null) return;

      cancelAnimationFrame(requestAnimationFrameId);
    };

    instance.on("play", play);
    instance.on("pause", pause);
    instance.on("timeupdate", timeUpdate);
    instance.on("loadeddata", timeUpdate);

    return () => {
      instance.off("play", play);
      instance.off("pause", pause);
      instance.off("timeupdate", timeUpdate);
      instance.off("loadeddata", timeUpdate);
    };
  }, [
    instance,
    currentLineCanvasRef,
    zoomOffset,
    size,
    enrichment,
    zoomOffsetUpdateCurrentPosition,
  ]);

  return <canvas id="CurrentPosition" ref={ref} style={{ position: "absolute" }} />;
});

const DEFAULT_WIDTH = 68;
const DEFAULT_MARGIN_LEFT = `-${DEFAULT_WIDTH / 2}px`;

const NextPosition = () => {
  const dispatch = useAppDispatch();
  const show = useAppSelector(
    state =>
      state.videoEvents.virtualListRows[0].height >
      DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT,
  );
  const hideVideoPlayer = useAppSelector(state => state.video.hideVideoPlayer);

  return (
    <PositionLine
      ref={dispatch.video.setSelectedLineRef}
      sub
      sx={{ opacity: hideVideoPlayer ? 0 : 1 }}
    >
      <Box
        sx={{
          width: DEFAULT_WIDTH,
          color: "text.secondary",
          backgroundColor: "text.disabled",
          marginLeft: DEFAULT_MARGIN_LEFT,
          position: "absolute",
          zIndex: 11,
          display: "flex",
          flexDirection: "column",
          borderBottom: "2px solid",
          borderColor: "text.disabled",
          boxSizing: "border-box",
        }}
      >
        <Typography
          variant="caption"
          sx={{ height: 9, textAlign: "center", mx: "auto", mt: "2px" }}
        />
      </Box>
      <Box
        sx={{
          width: DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_OPEN_HEIGHT + 4,
          color: "text.secondary",
          backgroundColor: "text.disabled",
          marginLeft: DEFAULT_MARGIN_LEFT,
          position: "absolute",
          zIndex: 11,
          marginTop: "13.1px",
          display: "flex",
          flexDirection: "column",
          borderBottom: "2px solid",
          borderColor: "text.disabled",
          boxSizing: "border-box",
        }}
      >
        {show && <Thumbnail />}
      </Box>
    </PositionLine>
  );
};

const Thumbnail = () => {
  const instance = useAppSelector(state => state.video.instance);
  const recording = useAppSelector(state => state.video.recording);
  const selectedPercentage = useAppSelector(
    state => state.video.zoom.selectedPercentage,
  );
  const playerRef = useRef<ReturnType<typeof videojs> | null>(null);
  const show = useAppSelector(state =>
    state.video.hideVideoPlayer
      ? false
      : document.URL.includes("projects")
      ? state.projectEdit.recordings.length > 0
      : state.recordings.data.length > 0,
  );

  useEffect(() => {
    if (
      !instance ||
      !playerRef.current ||
      playerRef.current.isDisposed() ||
      selectedPercentage === null ||
      isNaN(instance.duration())
    )
      return;

    const time =
      selectedPercentage > 0 ? (instance.duration() * selectedPercentage) / 100 : 0;

    playerRef.current.currentTime(time);
  }, [selectedPercentage, instance]);

  const video = useMemo(() => {
    if (!recording || !recording.transcoded_url) return;

    return (
      <VideoJS
        onReady={player => {
          playerRef.current = player;
        }}
        options={{
          sources: createVideoSources(recording, true),
        }}
      />
    );
  }, [recording]);

  if (!recording || !recording.transcoded_url) return null;

  return (
    <Box
      width={DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_OPEN_HEIGHT}
      height={DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_OPEN_HEIGHT}
      mx="auto"
      display={show ? "auto" : "none"}
    >
      {video}
    </Box>
  );
};

export const VideoPositions = () => {
  return (
    <>
      <CurrentPositionCanvas />
      <NextPosition />
    </>
  );
};
