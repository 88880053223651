import { SvgIcon, SvgIconProps } from "@mui/material";

export const CheckBoxIndeterminate: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <rect x="3" y="3" width="10" height="10" fill="#EDEDEF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 1.5H12.5C13.0303 1.50058 13.5386 1.71148 13.9136 2.08643C14.2885 2.46137 14.4994 2.96974 14.5 3.5V12.5C14.4994 13.0303 14.2885 13.5386 13.9136 13.9136C13.5386 14.2885 13.0303 14.4994 12.5 14.5H3.5C2.96974 14.4994 2.46137 14.2885 2.08643 13.9136C1.71148 13.5386 1.50058 13.0303 1.5 12.5V3.5C1.50058 2.96974 1.71148 2.46137 2.08643 2.08643C2.46137 1.71148 2.96974 1.50058 3.5 1.5ZM4.5 7.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H4.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
