import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import { ContextMenuTypes, store, useAppDispatch, useAppSelector } from "@storeRematch";
import debounce from "debounce";
import { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";

const AreaListRow = ({ index, style }: { index: number; style?: any }) => {
  return (
    <div style={style}>
      <AreaItem index={index} />
    </div>
  );
};

const debounceUpdate = debounce(store.dispatch.aoiAreas.update, 500);

const AreaItem = memo(({ index }: { index: number }) => {
  const dispatch = useAppDispatch();
  const item = useAppSelector(state => state.aoiAreas.data[index]);
  const selectedMoreThenOne = useAppSelector(
    state => state.aoiAreas.selectedIds.size > 1,
  );
  const checked = useAppSelector(state =>
    state.aoiAreas.selectedIds.has(state.aoiAreas.data[index]?.id),
  );
  const visibility = useAppSelector(
    state => state.aoiAreas.visibility[state.aoiAreas.data[index].id],
  );
  const hovered = useAppSelector(
    state =>
      state.aoiAreas.hover.length &&
      state.aoiAreas.hover[state.aoiAreas.hover.length - 1] === item.id,
  );
  const [state, setState] = useState(item);

  useEffect(() => {
    if (
      item.id !== state.id ||
      (item.name !== state.name &&
        new Date(state.updated_at) > new Date(item.updated_at))
    ) {
      setState(item);
    }
  }, [item, state]);

  return (
    <MenuItem
      sx={{
        height: 45,
        py: 0,
        display: "flex",
        alignItems: "stretch",
        justifyItems: "center",
        backgroundColor:
          hovered && !checked
            ? "action.hover"
            : checked
            ? "action.selected"
            : "inherit",
      }}
      onClick={e => {
        if (e.ctrlKey || e.metaKey || e.shiftKey) {
          window.getSelection()?.removeAllRanges();
        }

        if (e.shiftKey) {
          dispatch.aoiAreas.selectShift(index);
          return;
        }

        dispatch.aoiAreas.select({ id: item.id, multi: e.ctrlKey || e.metaKey });
      }}
      onContextMenu={e => {
        if (!selectedMoreThenOne || !checked) dispatch.aoiAreas.select({ id: item.id });
        dispatch.ctxMenu.handleClick({ e, type: ContextMenuTypes.AOI_LIST });
      }}
      onMouseEnter={() => dispatch.aoiAreas.updateHovered(item.id)}
      onMouseLeave={() => dispatch.aoiAreas.updateHovered(undefined)}
    >
      <Typography alignSelf="center" sx={{ minWidth: 25 }}>
        {index + 1}
      </Typography>

      <Box
        sx={{
          maxWidth: 8,
          maxHeight: 8,
          width: "100%",
          height: "100%",
          borderRadius: "2px",
          backgroundColor: `${item.color?.[0] === "#" ? "" : "#"}${item.color}`,
          my: "auto",
          mx: 1,
        }}
      />

      <TextField
        value={state.name}
        InputProps={{
          disableUnderline: true,
          sx: {
            height: "100%",
            caretColor: selectedMoreThenOne ? "transparent" : undefined,
            userSelect: selectedMoreThenOne ? "none" : undefined,
          },
        }}
        onChange={e => {
          if (selectedMoreThenOne) return;

          setState({ ...state, name: e.target.value });
          debounceUpdate({ id: item.id, name: e.target.value });
        }}
      />
      {!checked && (
        <IconButton
          sx={{ ml: 1, my: "auto" }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch.aoiAreas.setVisibility({ id: item.id, value: !visibility });
          }}
          disabled={checked}
        >
          {visibility ? (
            <Visibility fontSize="small" />
          ) : (
            <VisibilityOff fontSize="small" />
          )}
        </IconButton>
      )}
    </MenuItem>
  );
});

export const AreaList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const rowsLength = useAppSelector(state => state.aoiAreas.data.length);
  const scrollToIndex = useAppSelector(state => state.aoiAreas.scrollToIndex);
  const ref = useRef<FixedSizeList | null>(null);

  useEffect(() => {
    if (ref.current && scrollToIndex !== null) {
      ref.current.scrollToItem(scrollToIndex);
      dispatch.aoiAreas.setScrollToIndex(null);
    }
  }, [scrollToIndex, dispatch]);

  if (!rowsLength) {
    return (
      <Typography sx={{ color: "text.secondary", m: 2 }} variant="body2">
        {t("Add an AOI and start drawing.")}
      </Typography>
    );
  }

  return (
    <AutoSizer disableWidth>
      {({ height }) => {
        return (
          <FixedSizeList
            ref={ref}
            overscanCount={5}
            height={height || 0}
            width="100%"
            itemCount={rowsLength}
            itemSize={48}
          >
            {AreaListRow}
          </FixedSizeList>
        );
      }}
    </AutoSizer>
  );
};
