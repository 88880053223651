import { ProjectForm, WorkspaceForm } from "@pages";
import { useAppSelector } from "@storeRematch";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorPage } from "./ErrorPage";

export function createGlobalModalRoute(type: GlobalModalTypes) {
  return `?${GLOBAL_MODAL_QUERY_NAME}=${type}`;
}

const GLOBAL_MODAL_QUERY_NAME = "globalModal";

export enum GlobalModalTypes {
  WORKSPACE_CREATE,
  PROJECT_CREATE_SELECTION,
  PROJECT_EDIT,
}

export const GlobalModal = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [typed, setTyped] = useState<GlobalModalTypes | null>(null);
  const currentProject = useAppSelector(state => state.app.currentProject);

  useEffect(() => {
    const type = searchParams.get(GLOBAL_MODAL_QUERY_NAME);

    if (type) {
      setTyped(+type);
    } else {
      setTyped(null);
    }
  }, [searchParams]);

  const handleClose = () => {
    navigate(-1);
  };

  if (typed === GlobalModalTypes.WORKSPACE_CREATE) {
    return <WorkspaceForm onClose={handleClose} open={true} />;
  } else if (typed === GlobalModalTypes.PROJECT_CREATE_SELECTION) {
    return <ProjectForm onClose={handleClose} open={true} createWithSelection />;
  } else if (typed === GlobalModalTypes.PROJECT_EDIT) {
    if (!currentProject) return <ErrorPage title={`Project not found`} />;
    return <ProjectForm project={currentProject} onClose={handleClose} open={true} />;
  }
  return null;
};
