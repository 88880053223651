import { CheckBoxChecked, CheckBoxIndeterminate } from "@customIcons";
import { CheckBoxOutlineBlank } from "@mui/icons-material";

export const OnlyCheckBox = ({
  checked,
  indeterminate,
}: {
  checked?: boolean;
  indeterminate?: boolean;
}) => {
  if (checked) return <CheckBoxChecked color="primary" />;
  if (indeterminate) return <CheckBoxIndeterminate color="primary" />;
  return <CheckBoxOutlineBlank />;
};
