import { Profile, User } from "@api";
import { FormDialog, PasswordTextField, TextField } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { auth } from "@services/firebase";
import { EmailPasswordCredentials, useAppDispatch } from "@storeRematch";
import {
  currentPasswordSchema,
  emailSchema,
  googleGroupedLogin,
  newPasswordSchema,
  useNavigateToEmailVerified,
} from "@utils";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

export interface AccountEmailPassFormProps {
  open: boolean;
  onClose(): void;
  user?: User | Profile;
  type?: "email" | "password";
}

interface FormDataProps {
  email: string;
  currentPassword: string;
  newPassword: string;
}

const MAX_TRIES = 3;

const emailFormSchema = yup
  .object()
  .shape({
    currentPassword: currentPasswordSchema,
    email: emailSchema,
  })
  .required();

const passFormSchema = yup
  .object()
  .shape({
    currentPassword: currentPasswordSchema,
    newPassword: newPasswordSchema,
  })
  .required();

export const AccountEmailPassForm: React.FC<AccountEmailPassFormProps> = ({
  open,
  onClose,
  user = undefined,
  type = "email",
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [passwordTries, setPasswordTries] = useState(1);
  const { setNavigate, Navigate } = useNavigateToEmailVerified();

  const verifyEmail = (credentials: EmailPasswordCredentials) =>
    setNavigate(googleGroupedLogin(dispatch, credentials));

  const handleAuthError = (error: any) => {
    console.error(error);
    switch (error.code) {
      case "auth/wrong-password":
        dispatch.notifier.enqueueSnackbar({
          message: t("Incorrect password"),
          options: { variant: "error" },
        });
        setPasswordTries(tries => {
          if (passwordTries === MAX_TRIES) dispatch.app.logout();
          return tries + 1;
        });
        break;
      default:
        dispatch.notifier.enqueueSnackbar({
          message: t("Unable to re-authenitcate"),
          options: { variant: "error" },
        });
        break;
    }
  };

  const handleSubmit = async ({
    currentPassword,
    newPassword,
    email,
  }: FormDataProps) => {
    if (!user || !auth.currentUser?.email) return;

    const credentials = {
      email: email,
      password: currentPassword,
    };

    const firebaseCredential = EmailAuthProvider.credential(
      auth.currentUser.email,
      currentPassword,
    );

    const authed = await reauthenticateWithCredential(
      auth.currentUser,
      firebaseCredential,
    ).catch(error => handleAuthError(error));

    if (authed) {
      type === "email"
        ? dispatch.app
            .updateEmail(credentials.email)
            .then(() => verifyEmail(credentials))
        : dispatch.app.updatePassword(newPassword).then(onClose);
    }
  };

  if (Navigate) return Navigate;

  return (
    <FormDialog
      loading={false}
      open={open}
      title={type === "email" ? t("Edit Email") : t("Edit Password")}
      onClose={onClose}
      onFormSubmit={handleSubmit}
      useFormProps={{
        defaultValues: { ...user, currentPassword: "", newPassword: "" } || {
          email: "",
          currentPassword: "",
          newPassword: "",
        },
        resolver: yupResolver(type === "email" ? emailFormSchema : passFormSchema),
      }}
    >
      <Box sx={{ display: "grid", gap: 2 }}>
        <Box sx={{ mb: 2 }}>
          <PasswordTextField
            name="currentPassword"
            autoComplete="current-password"
            label="Current Password"
            fullWidth
            required
          />
          <Typography variant="body2" mt={1}>
            Entering an incorrect password more than 3 times will force a logout.
          </Typography>
        </Box>
        <Box>
          {type === "email" ? (
            <TextField fullWidth name="email" autoFocus placeholder={t("Edit email")} />
          ) : (
            <>
              <PasswordTextField
                name="newPassword"
                autoComplete="new-password"
                label="New Password"
                fullWidth
                required
              />
            </>
          )}
          <Typography variant="body2" mt={1}>
            {type === "email"
              ? `Your email will be used for account related notifications and can be
                used for password resets.`
              : `Make sure it’s at least 8 characters, including a number and an
                uppercase letter.`}
          </Typography>
        </Box>
      </Box>
    </FormDialog>
  );
};
