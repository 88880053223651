import { CtxMenuItem } from "@components";
import { Box, Divider } from "@mui/material";
import { RouterHelper } from "@pages";
import { useAppDispatch } from "@storeRematch";
import { FC, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { LabelsAsList } from "./LabelsAsList";

export const LabelsAsListAddRemove: FC = () => {
  const dispatch = useAppDispatch();
  const { workspaceId } = useParams();
  const [notFound, setNotFound] = useState<string | null>(null);

  const list = useMemo(
    () => (
      <LabelsAsList
        onClick={(checked, { id }) =>
          checked
            ? dispatch.recordings.removeLabelsFromSelected([id])
            : dispatch.recordings.addLabelsToSelected([id])
        }
        onNothingFound={setNotFound}
        onEnter={search => {
          dispatch.labels.createAndAddToRecordings(search);
          setNotFound(null);
        }}
      />
    ),
    [dispatch],
  );

  return (
    <>
      {list}
      <Divider />
      {notFound && (
        <CtxMenuItem
          label={`Create label “${notFound}”`}
          onClick={() => {
            dispatch.labels.createAndAddToRecordings(notFound);
            setNotFound(null);
          }}
        />
      )}
      <CtxMenuItem
        label="Manage labels"
        to={RouterHelper.workspaceSettingsLabels.getFullPath({
          prefix: "/",
          props: { workspaceId },
        })}
      />
      <Box sx={{ height: 8, width: "100%" }} />
    </>
  );
};
