import { StyledComponent } from "@emotion/styled";
import { SxProps } from "@mui/system";
import { FC, PropsWithChildren } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
  ResponderProvided,
} from "react-beautiful-dnd";

interface DragCustomContextProps {
  droppableId: string;
  onDragEnd?: (result: DropResult, provided: ResponderProvided) => void;
  direction?: "vertical" | "horizontal";
  Root: StyledComponent<any, HTMLElement, {}>;
}

export const DragCustomContext: FC<PropsWithChildren<DragCustomContextProps>> = ({
  droppableId,
  onDragEnd,
  direction = "vertical",
  Root,
  children,
}) => {
  if (!onDragEnd) return <Root>{children}</Root>;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId} direction={direction}>
        {provided => (
          <Root ref={provided.innerRef} {...provided.droppableProps}>
            {children}
            {provided.placeholder}
          </Root>
        )}
      </Droppable>
    </DragDropContext>
  );
};

interface DraggableCustomProps {
  droppableId: string;
  index: number;
  Root: StyledComponent<any, HTMLElement, {}>;
  RootProps?: { sx?: SxProps };
  disabled?: boolean;
}

export const DraggableCustom: FC<PropsWithChildren<DraggableCustomProps>> = ({
  droppableId,
  index,
  Root,
  RootProps,
  disabled,
  children,
}) => {
  if (disabled) return <Root {...RootProps}>{children}</Root>;

  return (
    <Draggable draggableId={droppableId} index={index}>
      {provided => (
        <Root
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          {...RootProps}
        >
          {children}
        </Root>
      )}
    </Draggable>
  );
};
