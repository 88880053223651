import { Token } from "@api";
import { FormDialog, TextField } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import Stack from "@mui/material/Stack";
import { useAppDispatch } from "@storeRematch";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

export interface DeveloperFormProps {
  open: boolean;
  onClose(): void;
  token?: Token;
}

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
  })
  .required();

export const DeveloperForm: React.FC<DeveloperFormProps> = ({
  open,
  onClose,
  token = undefined,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleSubmit = async (formData: Token) => {
    await dispatch.tokens.create(formData.name);
    onClose();
  };

  return (
    <FormDialog
      open={open}
      title={t("Add Token")}
      onFormSubmit={handleSubmit}
      onClose={onClose}
      useFormProps={{
        defaultValues: token || { name: "" },
        resolver: yupResolver(schema),
      }}
      create={!token}
      loading={false}
    >
      <Stack spacing={4}>
        <TextField name="name" autoFocus required placeholder={t("Token Name")} />
      </Stack>
    </FormDialog>
  );
};
