import { EntityDataTable, NoDataDefault, ReactRouterLinkCustom } from "@components";
import { Button } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { RouterHelper } from "@pages";
import { ProjectsTableData, useAppSelector } from "@storeRematch";
import {
  DisableByPermissionsType,
  LocalStorageAdapterNames,
  disabledByPermission,
} from "@utils";
import React from "react";
import { Outlet } from "react-router-dom";
import TimeAgo from "timeago-react";
import { ProjectsContextMenu } from "./ProjectsContextMenu";

const columns: GridColDef<ProjectsTableData>[] = [
  {
    width: 200,
    field: "name",
    headerName: "Name",
    valueGetter: (_, row) => {
      return row.project.name;
    },
  },
  {
    field: "recordings",
    headerName: "Recordings",
    type: "number",
    align: "left",
    valueGetter: (_, row) => {
      return row.recordings.length;
    },
  },
  {
    field: "created_at",
    headerName: "Created",
    type: "date",
    valueGetter: (_, row) => {
      return new Date(row.project.created_at || "");
    },
    renderCell: params => {
      return (
        params.row.project.created_at && (
          <TimeAgo
            datetime={new Date(params.row.project.created_at)}
            opts={{ minInterval: 60 }}
          />
        )
      );
    },
  },
  {
    field: "updated_at",
    headerName: "Updated",
    type: "date",
    valueGetter: (_, row) => {
      return row.project.updated_at ? new Date(row.project.updated_at) : null;
    },
    renderCell: params => {
      return (
        params.row.project.updated_at && (
          <TimeAgo
            datetime={new Date(params.row.project.updated_at)}
            opts={{ minInterval: 60 }}
          />
        )
      );
    },
  },
  {
    field: "created_by_user_id",
    headerName: "Created By",
    valueGetter: (_, row) => {
      return row.createdBy?.user?.name;
    },
  },
];

export const Projects: React.FC = () => {
  const rows = useAppSelector(state => state.projects.tableData);
  const isLoading = useAppSelector(
    state => state.loading.effects.app.loadWorkspaceData.loading,
  );
  const disabled = disabledByPermission({ type: DisableByPermissionsType.CREATE });

  return (
    <>
      <EntityDataTable
        loading={isLoading}
        rows={rows}
        columns={columns}
        noFilters
        noColumnFilter
        noDataMessage={<NoDataDefault name="projects" />}
        tableColumnInitialStateStorageKey={
          LocalStorageAdapterNames.settingsProjectsColumState
        }
      >
        <Button
          component={ReactRouterLinkCustom}
          to={RouterHelper.workspaceSettingsProjectsNew.getRelativePath()}
          replace={true}
          color="primary"
          disabled={disabled}
        >
          New project
        </Button>
      </EntityDataTable>
      <ProjectsContextMenu />
      <Outlet />
    </>
  );
};
