export const DEFAULT_OPACITY = 0.6;
export const DEFAULT_DISABLED_OPACITY = 0.8;
export const HOVERED_OPACITY = 0.3;

export function textAbstract(str: string, maxlength = 24) {
  if (str.length <= maxlength) {
    return `${str}\n`;
  }

  str = str.substring(0, maxlength - 3);

  if (str[str.length - 1] === " ") str = str.substring(0, str.length - 1);

  return `${str}...\n`;
}
