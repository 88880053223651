import { AOI_HEATMAP_TYPES } from "@storeRematch/types";
import { fetchBase } from "./apiController";

export const api = {
  postLogin: (queryArg: PostLoginApiArg) =>
    fetchBase<PostLoginApiResponse>({
      url: `/auth/login`,
      method: "POST",
      body: queryArg.loginRequest,
    }),
  postLogout: (_queryArg: PostLogoutApiArg) =>
    fetchBase<PostLogoutApiResponse>({ url: `/auth/logout`, method: "POST" }),
  getProfile: (_queryArg: GetProfileApiArg) =>
    fetchBase<GetProfileApiResponse>({ url: `/auth/profile` }),
  patchProfile: (queryArg: PatchProfileApiArg) =>
    fetchBase<PatchProfileApiResponse>({
      url: `/auth/profile`,
      method: "PATCH",
      body: queryArg.profilePatchRequest,
    }),
  getWorkspaceInvitations: (_queryArg: GetWorkspaceInvitationsApiArg) =>
    fetchBase<GetWorkspaceInvitationsApiResponse>({ url: `/auth/profile/invites` }),
  getAuthInvitation: (queryArg: GetAuthInvitationApiArg) =>
    fetchBase<GetAuthInvitationApiResponse>({
      url: `/auth/profile/invites/${queryArg.token}`,
    }),
  deleteAuthWorkspaceInvitation: (queryArg: DeleteAuthWorkspaceInvitationApiArg) =>
    fetchBase<DeleteAuthWorkspaceInvitationApiResponse>({
      url: `/auth/profile/invites/${queryArg.token}`,
      method: "DELETE",
    }),
  postAcceptInvitationResource: (queryArg: PostAcceptInvitationResourceApiArg) =>
    fetchBase<PostAcceptInvitationResourceApiResponse>({
      url: `/auth/profile/invites/${queryArg.token}/accept`,
      method: "POST",
    }),
  getTokens: (_queryArg: GetTokensApiArg) =>
    fetchBase<GetTokensApiResponse>({ url: `/auth/tokens` }),
  postToken: (queryArg: PostTokenApiArg) =>
    fetchBase<PostTokenApiResponse>({
      url: `/auth/tokens`,
      method: "POST",
      body: queryArg.tokenPostRequest,
    }),
  getToken: (queryArg: GetTokenApiArg) =>
    fetchBase<GetTokenApiResponse>({ url: `/auth/tokens/${queryArg.tokenId}` }),
  deleteToken: (queryArg: DeleteTokenApiArg) =>
    fetchBase<DeleteTokenApiResponse>({
      url: `/auth/tokens/${queryArg.tokenId}`,
      method: "DELETE",
    }),
  postUnregister: (_queryArg: PostUnregisterApiArg) =>
    fetchBase<PostUnregisterApiResponse>({ url: `/auth/unregister`, method: "POST" }),
  getCannySsoToken: async () =>
    fetchBase<GetCannySsoTokenApiResponse>({
      url: `/auth/canny-sso`,
    }),
  getHardware: (queryArg: GetHardwareApiArg) =>
    fetchBase<GetHardwareApiResponse>({
      url: `/hardware/${queryArg.serialNumber}/calibration.${queryArg.version}`,
    }),
  getHardwareScene: (queryArg: GetHardwareSceneApiArg) =>
    fetchBase<GetHardwareApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}/scene_camera.json`,
    }),
  getWorkspaces: (_queryArg: GetWorkspacesApiArg) =>
    fetchBase<GetWorkspacesApiResponse>({ url: `/workspaces/` }),
  postWorkspace: (queryArg: PostWorkspaceApiArg) =>
    fetchBase<PostWorkspaceApiResponse>({
      url: `/workspaces/`,
      method: "POST",
      body: queryArg.workspacePostRequest,
    }),
  getWorkspace: (queryArg: GetWorkspaceApiArg) =>
    fetchBase<GetWorkspaceApiResponse>({ url: `/workspaces/${queryArg.workspaceId}` }),
  deleteWorkspace: (queryArg: DeleteWorkspaceApiArg) =>
    fetchBase<DeleteWorkspaceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}`,
      method: "DELETE",
    }),
  updateWorkspace: (queryArg: UpdateWorkspaceApiArg) =>
    fetchBase<UpdateWorkspaceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}`,
      method: "PATCH",
      body: queryArg.workspacePatchRequest,
    }),
  getFiles: (queryArg: GetFilesApiArg) =>
    fetchBase<GetFilesApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/files/`,
    }),
  postFile: (queryArg: PostFileApiArg) =>
    fetchBase<PostFileApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/files/`,
      method: "POST",
      headers: { "Tus-Resumable": queryArg["Tus-Resumable"] },
    }),
  getFileMetadata: (queryArg: GetFileMetadataApiArg) =>
    fetchBase<GetFileMetadataApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/files/${queryArg.fileId}`,
    }),
  headFileMetadata: (queryArg: HeadFileMetadataApiArg) =>
    fetchBase<HeadFileMetadataApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/files/${queryArg.fileId}`,
      method: "HEAD",
      headers: { "Tus-Resumable": queryArg["Tus-Resumable"] },
    }),
  patchFile: (queryArg: PatchFileApiArg) =>
    fetchBase<PatchFileApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/files/${queryArg.fileId}`,
      method: "PATCH",
      headers: { "Tus-Resumable": queryArg["Tus-Resumable"] },
    }),
  downloadFile: (queryArg: DownloadFileApiArg) =>
    fetchBase<DownloadFileApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/files/${queryArg.fileId}/${queryArg.filename}`,
    }),
  getFilters: (queryArg: GetFiltersApiArg) =>
    fetchBase<GetFiltersApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/filters/`,
    }),
  postFilters: (queryArg: PostFiltersApiArg) =>
    fetchBase<PostFiltersApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/filters/`,
      method: "POST",
      body: queryArg.filterPostRequest,
    }),
  deleteFilters: (queryArg: DeleteFiltersApiArg) =>
    fetchBase<DeleteFiltersApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/filters/`,
      method: "DELETE",
      body: queryArg.filtersDeleteRequest,
    }),
  getFilter: (queryArg: GetFilterApiArg) =>
    fetchBase<GetFilterApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/filters/${queryArg.filterId}`,
    }),
  deleteFilter: (queryArg: DeleteFilterApiArg) =>
    fetchBase<DeleteFilterApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/filters/${queryArg.filterId}`,
      method: "DELETE",
    }),
  patchFilter: (queryArg: PatchFilterApiArg) =>
    fetchBase<PatchFilterApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/filters/${queryArg.filterId}`,
      method: "PATCH",
      body: queryArg.filterPatchRequest,
    }),
  getHeatmap: (queryArg: GetHeatmapApiArg) =>
    fetchBase<GetHeatmapApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/heatmap/`,
    }),
  postHeatmap: (queryArg: PostHeatmapApiArg) =>
    fetchBase<PostHeatmapApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/heatmap/`,
      method: "POST",
      body: queryArg.generateHeatmap,
    }),
  getHeatmapBackground: (
    queryArg: GetHeatmapBackgroundApiArg,
    disableAbort?: boolean,
    disableError?: boolean,
  ) =>
    fetchBase<GetHeatmapBackgroundApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/heatmap/background?project_id=${
        queryArg.project_id
      }&enrichment_id=${queryArg.enrichment_id}${
        queryArg.disableMaxSize ? "" : `&max_size=${queryArg.maxSize || 1024}`
      }`,
      disableAbort,
      disableError,
    }),
  getDownloadHeatmap: (queryArg: GetDownloadHeatmapApiArg) =>
    fetchBase<GetDownloadHeatmapApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/heatmap/zip_download`,
    }),
  postDownloadHeatmap: (queryArg: PostDownloadHeatmapApiArg) =>
    fetchBase<PostDownloadHeatmapApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/heatmap/zip_download`,
      method: "POST",
      body: queryArg.generateHeatmap,
    }),
  listWorkspaceInvitations: (queryArg: ListWorkspaceInvitationsApiArg) =>
    fetchBase<ListWorkspaceInvitationsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/invites`,
    }),
  createWorkspaceInvitation: (queryArg: CreateWorkspaceInvitationApiArg) =>
    fetchBase<CreateWorkspaceInvitationApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/invites`,
      method: "POST",
      body: queryArg.workspaceInvitationPostRequest,
    }),
  getWorkspaceInvitation: (queryArg: GetWorkspaceInvitationApiArg) =>
    fetchBase<GetWorkspaceInvitationApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/invites/${queryArg.token}`,
    }),
  deleteWorkspaceInvitation: (queryArg: DeleteWorkspaceInvitationApiArg) =>
    fetchBase<DeleteWorkspaceInvitationApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/invites/${queryArg.token}`,
      method: "DELETE",
    }),
  getLabels: (queryArg: GetLabelsApiArg) =>
    fetchBase<GetLabelsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/labels/`,
    }),
  postLabel: (queryArg: PostLabelApiArg) =>
    fetchBase<PostLabelApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/labels/`,
      method: "POST",
      body: queryArg.labelPostRequest,
    }),
  deleteLabels: (queryArg: DeleteLabelsApiArg) =>
    fetchBase<DeleteLabelsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/labels/`,
      method: "DELETE",
      body: queryArg.labelsDeleteRequest,
    }),
  getLabel: (queryArg: GetLabelApiArg) =>
    fetchBase<GetLabelApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/labels/${queryArg.labelId}`,
    }),
  deleteLabel: (queryArg: DeleteLabelApiArg) =>
    fetchBase<DeleteLabelApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/labels/${queryArg.labelId}`,
      method: "DELETE",
    }),
  patchLabel: (queryArg: PatchLabelApiArg) =>
    fetchBase<PatchLabelApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/labels/${queryArg.labelId}`,
      method: "PATCH",
      body: queryArg.labelPatchRequest,
    }),
  postMarkerless: (queryArg: PostMarkerlessApiArg) =>
    fetchBase<PostMarkerlessApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/markerless/`,
      method: "POST",
      body: queryArg.markerlessInitialization,
    }),
  getMarkerless: (queryArg: GetMarkerlessApiArg) =>
    fetchBase<GetMarkerlessApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/markerless/${queryArg.markerlessId}`,
    }),
  getGazeOnAoi: (queryArg: GetGazeOnAoiApiArg) =>
    fetchBase<GetGazeOnAoiApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/markerless/${queryArg.markerlessId}/gaze_in_aoi${queryArg.format}`,
    }),
  getGazeOnAoiSurface: (queryArg: GetGazeOnAoiSurfaceApiArg) =>
    fetchBase<GazeOnAoiSurfaceGetResponse>({
      url: `/workspaces/${queryArg.workspaceId}/surfaces/${queryArg.surfaceId}/gaze_in_aoi${queryArg.format}`,
    }),
  scanMarkerless: (queryArg: ScanMarkerlessApiArg) =>
    fetchBase<ScanMarkerlessApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/markerless/${queryArg.markerlessId}/scan`,
      method: "POST",
    }),
  patchMarkerlessGazeOnAoiData: (queryArg: PatchMarkerlessGazeOnAoiDataApiArg) =>
    fetchBase<PatchMarkerlessGazeOnAoiDataApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/markerless/${queryArg.markerlessId}/update_gaze_on_aoi`,
      method: "PATCH",
      body: queryArg.projectEnrichment,
    }),
  getWorkspaceMembers: (queryArg: GetWorkspaceMembersApiArg) =>
    fetchBase<GetWorkspaceMembersApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/members`,
    }),
  postWorkspaceMembersResource: (queryArg: PostWorkspaceMembersResourceApiArg) =>
    fetchBase<PostWorkspaceMembersResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/members`,
      method: "POST",
      body: queryArg.workspaceMemberPostRequest,
    }),
  getWorkspaceMember: (queryArg: GetWorkspaceMemberApiArg) =>
    fetchBase<GetWorkspaceMemberApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/members/${queryArg.userId}`,
    }),
  deleteWorkspaceMember: (queryArg: DeleteWorkspaceMemberApiArg) =>
    fetchBase<DeleteWorkspaceMemberApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/members/${queryArg.userId}`,
      method: "DELETE",
    }),
  patchWorkspaceMember: (queryArg: PatchWorkspaceMemberApiArg) =>
    fetchBase<PatchWorkspaceMemberApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/members/${queryArg.userId}`,
      method: "PATCH",
      body: queryArg.workspaceMemberPatchRequest,
    }),
  getProjectsResource: (queryArg: GetProjectsResourceApiArg) =>
    fetchBase<GetProjectsResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/`,
    }),
  postProjectsResource: (queryArg: PostProjectsResourceApiArg) =>
    fetchBase<PostProjectsResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/`,
      method: "POST",
      body: queryArg.projectPostRequest,
    }),
  deleteProjectsResource: (queryArg: DeleteProjectsResourceApiArg) =>
    fetchBase<DeleteProjectsResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/`,
      method: "DELETE",
      body: queryArg.projectsDeleteRequest,
    }),
  getProjectResource: (queryArg: GetProjectResourceApiArg) =>
    fetchBase<GetProjectResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}`,
    }),
  deleteProjectResource: (queryArg: DeleteProjectResourceApiArg) =>
    fetchBase<DeleteProjectResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}`,
      method: "DELETE",
    }),
  patchProjectResource: (queryArg: PatchProjectResourceApiArg) =>
    fetchBase<PatchProjectResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}`,
      method: "PATCH",
      body: queryArg.projectPatchRequest,
    }),
  getProjectEnrichments: (queryArg: GetProjectEnrichmentsApiArg) =>
    fetchBase<GetProjectEnrichmentsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/enrichments`,
    }),
  postProjectEnrichment: (queryArg: PostProjectEnrichmentApiArg) =>
    fetchBase<PostProjectEnrichmentApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/enrichments`,
      method: "POST",
      body: queryArg.projectEnrichmentPostRequest,
    }),
  getProjectEnrichment: (queryArg: GetProjectEnrichmentApiArg) =>
    fetchBase<GetProjectEnrichmentApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/enrichments/${queryArg.enrichmentId}`,
    }),
  deleteProjectEnrichmentResource: (queryArg: DeleteProjectEnrichmentResourceApiArg) =>
    fetchBase<DeleteProjectEnrichmentResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/enrichments/${queryArg.enrichmentId}`,
      method: "DELETE",
    }),
  patchProjectEnrichmentResource: (queryArg: PatchProjectEnrichmentResourceApiArg) =>
    fetchBase<PatchProjectEnrichmentResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/enrichments/${queryArg.enrichmentId}`,
      method: "PATCH",
      body: queryArg.projectEnrichmentPatchRequest,
    }),
  postProjectEnrichmentCancelResource: (
    queryArg: PostProjectEnrichmentCancelResourceApiArg,
  ) =>
    fetchBase<PostProjectEnrichmentCancelResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/enrichments/${queryArg.enrichmentId}/cancel`,
      method: "POST",
    }),
  getProjectEnrichmentComputeResource: (
    queryArg: GetProjectEnrichmentComputeResourceApiArg,
  ) =>
    fetchBase<GetProjectEnrichmentComputeResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/enrichments/${queryArg.enrichmentId}/compute`,
    }),
  postProjectEnrichmentComputeResource: (
    queryArg: PostProjectEnrichmentComputeResourceApiArg,
  ) =>
    fetchBase<PostProjectEnrichmentComputeResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/enrichments/${queryArg.enrichmentId}/compute`,
      method: "POST",
    }),
  getProjectEnrichmentExportResource: (
    queryArg: GetProjectEnrichmentExportResourceApiArg,
  ) =>
    fetchBase<GetProjectEnrichmentExportResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/enrichments/${queryArg.enrichmentId}/export`,
    }),
  postProjectEnrichmentExportResource: (
    queryArg: PostProjectEnrichmentExportResourceApiArg,
  ) =>
    fetchBase<PostProjectEnrichmentExportResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/enrichments/${queryArg.enrichmentId}/export`,
      method: "POST",
      body: queryArg.projectEnrichmentExport,
    }),
  getProjectEventsResource: (queryArg: GetProjectEventsResourceApiArg) =>
    fetchBase<GetProjectEventsResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/events/`,
    }),
  getProjectRecordingsResource: (queryArg: GetProjectRecordingsResourceApiArg) =>
    fetchBase<GetProjectRecordingsResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/recordings/`,
    }),
  getProjectRecordingEnrichmentSlices: (
    queryArg: GetProjectRecordingEnrichmentSlicesApiArg,
  ) =>
    fetchBase<GetProjectRecordingEnrichmentSlicesApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/recordings/${queryArg.recordingId}/enrichments/${queryArg.enrichmentId}/slices`,
    }),
  getProjectRecordingEventsResource: (
    queryArg: GetProjectRecordingEventsResourceApiArg,
  ) =>
    fetchBase<GetProjectRecordingEventsResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/recordings/${queryArg.recordingId}/events`,
    }),
  postProjectRecordingEventsResource: (
    queryArg: PostProjectRecordingEventsResourceApiArg,
  ) =>
    fetchBase<PostProjectRecordingEventsResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/recordings/${queryArg.recordingId}/events`,
      method: "POST",
      body: queryArg.projectRecordingEventPostRequest,
    }),
  deleteProjectRecordingEventResource: (
    queryArg: DeleteProjectRecordingEventResourceApiArg,
  ) =>
    fetchBase<DeleteProjectRecordingEventResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/recordings/${queryArg.recordingId}/events/${queryArg.eventId}`,
      method: "DELETE",
    }),
  patchProjectRecordingEventResource: (
    queryArg: PatchProjectRecordingEventResourceApiArg,
  ) =>
    fetchBase<PatchProjectRecordingEventResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/recordings/${queryArg.recordingId}/events/${queryArg.eventId}`,
      method: "PATCH",
      body: queryArg.projectRecordingEventPatchRequest,
    }),
  getProjectUniqueEvents: (queryArg: GetProjectUniqueEventsApiArg) =>
    fetchBase<GetProjectUniqueEventsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/unique-events/`,
    }),
  getWorkspaceUniqueEvents: (queryArg: GetWorkspaceUniqueEventsApiArg) =>
    fetchBase<GetWorkspaceUniqueEventsApiResponse>({
      //https://api.cloud.pupil-labs.dev/v2/workspaces/805abbd1-b56b-4dc9-9179-532eead979bd/recordings:unique-events
      url: `/workspaces/${queryArg.workspaceId}/recordings:unique-events`,
    }),
  downloadProject: (queryArg: DownloadProjectApiArg) =>
    fetchBase<unknown>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/download`,
      method: "POST",
      body: queryArg.downloadProjectPostRequest,
    }),
  downloadRecordingsZip: (queryArg: DownloadRecordingsZipApiArg) =>
    fetchBase<DownloadRecordingsZipApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings.zip`,
      params: { ids: queryArg.ids },
    }),
  getRecordings: (queryArg: GetRecordingsApiArg) =>
    fetchBase<GetRecordingsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/`,
    }),
  postRecording: (queryArg: PostRecordingApiArg) =>
    fetchBase<PostRecordingApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/`,
      method: "POST",
      body: queryArg.recordingPostRequest,
    }),
  deleteRecordings: (queryArg: DeleteRecordingsApiArg) =>
    fetchBase<DeleteRecordingsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/`,
      method: "DELETE",
      body: queryArg.recordingsDeleteRequest,
    }),
  getRecording: (queryArg: GetRecordingApiArg) =>
    fetchBase<GetRecordingApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}`,
    }),
  deleteRecording: (queryArg: DeleteRecordingApiArg) =>
    fetchBase<DeleteRecordingApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}`,
      method: "DELETE",
    }),
  patchRecording: (queryArg: PatchRecordingApiArg) =>
    fetchBase<PatchRecordingApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}`,
      method: "PATCH",
      body: queryArg.recordingPatchRequest,
    }),
  downloadRecordingZip: (queryArg: DownloadRecordingZipApiArg) =>
    fetchBase<DownloadRecordingZipApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}.zip`,
    }),
  getApriltagsAtTimestamp: (queryArg: GetApriltagsAtTimestampApiArg) =>
    fetchBase<GetApriltagsAtTimestampApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}/apriltags_at_timestamp/`,
      params: {
        timestamp: queryArg.timestamp,
        with_duplicates: queryArg.withDuplicates,
      },
    }),
  getRecordingEvents: (queryArg: GetRecordingEventsApiArg) =>
    fetchBase<GetRecordingEventsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}/events`,
    }),
  postRecordingEvents: (queryArg: PostRecordingEventsApiArg) =>
    fetchBase<PostRecordingEventsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}/events`,
      method: "POST",
      body: queryArg.RecordingEventPostRequest,
    }),
  patchRecordingEvents: (queryArg: PatchRecordingEventsApiArg) =>
    fetchBase<PatchRecordingEventsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}/events/${queryArg.eventId}`,
      method: "PATCH",
      body: queryArg.RecordingEventPatchRequest,
    }),
  deleteRecordingEvents: (queryArg: DeleteRecordingEventsApiArg) =>
    fetchBase<DeleteRecordingEventsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}/events/${queryArg.eventId}`,
      method: "DELETE",
    }),
  getRecordingFaceDetections: (queryArg: GetRecordingFaceDetectionsApiArg) =>
    fetchBase<GetRecordingFaceDetectionsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}/face_detections${queryArg.format}`,
    }),
  getRecordingPointCloud: (queryArg: GetRecordingPointCloudApiArg) =>
    fetchBase<RecordingFPointCloudGetResponse>({
      url: `/workspaces/${queryArg.workspaceId}/markerless/${queryArg.markerlessId}/recordings/${queryArg.recordingId}/camera_pose${queryArg.format}`,
    }),
  getMarkerLessPointCloud: (queryArg: GetMarkerLessPointCloudApiArg) =>
    fetchBase<MarkerLessPointCloudGetResponse>({
      url: `/workspaces/${queryArg.workspaceId}/markerless/${queryArg.markerlessId}/point_cloud.json`,
    }),
  getRecordingFiles: (queryArg: GetRecordingFilesApiArg) =>
    fetchBase<GetRecordingFilesApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}/files`,
    }),
  downloadRecordingFile: (queryArg: DownloadRecordingFileApiArg) =>
    fetchBase<DownloadRecordingFileApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}/files/${queryArg.filename}`,
    }),
  getRecordingGaze: (queryArg: GetRecordingGazeApiArg) =>
    fetchBase<GetRecordingGazeApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}/gaze.${queryArg.format}`,
    }),
  getRecordingScanPath: (queryArg: GetRecordingGazeApiArg) =>
    fetchBase<GetRecordingScanPathApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}/scanpath.${queryArg.format}`,
    }),
  getWorldFrame: (queryArg: GetWorldFrameApiArg) =>
    fetchBase<GetWorldFrameApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}/world-frame.jpg`,
      params: {
        timestamp: queryArg.timestamp,
        height: queryArg.height,
        width: queryArg.width,
      },
    }),
  postRender: (queryArg: PostRenderApiArg) =>
    fetchBase<PostRenderApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/render/`,
      method: "POST",
      body: queryArg.worldRender,
    }),
  getRender: (queryArg: GetRenderApiArg) =>
    fetchBase<GetRenderApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/render/${queryArg.renderDefinitionId}`,
    }),
  patchRender: (queryArg: PatchRenderApiArg) =>
    fetchBase<PatchRenderApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/render/${queryArg.renderDefinitionId}`,
      method: "PATCH",
      body: queryArg.worldRender,
    }),
  postSurface: (queryArg: PostSurfaceApiArg) =>
    fetchBase<PostSurfaceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/surfaces/`,
      method: "POST",
      body: queryArg.surfaceInitialization,
    }),
  getSurface: (queryArg: GetSurfaceApiArg) =>
    fetchBase<GetSurfaceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/surfaces/${queryArg.surfaceId}`,
    }),
  patchSurface: (queryArg: PatchSurfaceApiArg) =>
    fetchBase<PatchSurfaceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/surfaces/${queryArg.surfaceId}`,
      method: "PATCH",
      body: queryArg.patchSurface,
    }),
  getApriltagDetections: (queryArg: GetApriltagDetectionsApiArg) =>
    fetchBase<GetApriltagDetectionsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/surfaces/${queryArg.surfaceId}/apriltags${queryArg.format}`,
    }),
  patchRotateSurfaceOrientation: (queryArg: PatchRotateSurfaceOrientationApiArg) =>
    fetchBase<PatchRotateSurfaceOrientationApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/surfaces/${queryArg.surfaceId}/rotate`,
      method: "PATCH",
      body: queryArg.orientationRotation,
    }),
  postDistortedSurfaceLocation: (queryArg: PostDistortedSurfaceLocationApiArg) =>
    fetchBase<PostDistortedSurfaceLocationApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/surfaces/${queryArg.surfaceId}/set_distorted_location_using_recording`,
      method: "POST",
      body: queryArg.setDistortedSurfaceRequest,
    }),
  getSurfaceCorners: (queryArg: GetSurfaceCornersApiArg) =>
    fetchBase<GetSurfaceCornersApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/surfaces/${queryArg.surfaceId}/surface_corners${queryArg.format}`,
    }),
  patchSurfaceGazeOnAoiData: (queryArg: PatchSurfaceGazeOnAoiDataApiArg) =>
    fetchBase<PatchSurfaceGazeOnAoiDataApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/surfaces/${queryArg.surfaceId}/update_gaze_on_aoi`,
      method: "PATCH",
      body: queryArg.projectEnrichment,
    }),
  getTemplates: (queryArg: GetTemplatesApiArg) =>
    fetchBase<GetTemplatesApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/templates/`,
    }),
  postTemplate: (queryArg: PostTemplateApiArg) =>
    fetchBase<PostTemplateApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/templates/`,
      method: "POST",
      body: queryArg.templatePostRequest,
    }),
  deleteTemplates: (queryArg: DeleteTemplatesApiArg) =>
    fetchBase<DeleteTemplatesApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/templates/`,
      method: "DELETE",
      body: queryArg.templatesDeleteRequest,
    }),
  getTemplate: (queryArg: GetTemplateApiArg) =>
    fetchBase<GetTemplateApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/templates/${queryArg.templateId}`,
    }),
  deleteTemplate: (queryArg: DeleteTemplateApiArg) =>
    fetchBase<DeleteTemplateApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/templates/${queryArg.templateId}`,
      method: "DELETE",
    }),
  patchTemplate: (queryArg: PatchTemplateApiArg) =>
    fetchBase<PatchTemplateApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/templates/${queryArg.templateId}`,
      method: "PATCH",
      body: queryArg.templatePatchRequest,
    }),
  getWearers: (queryArg: GetWearersApiArg) =>
    fetchBase<GetWearersApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/wearers/`,
    }),
  postWearer: (queryArg: PostWearerApiArg) =>
    fetchBase<PostWearerApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/wearers/`,
      method: "POST",
      body: queryArg.wearerPostRequest,
    }),
  deleteWearers: (queryArg: DeleteWearersApiArg) =>
    fetchBase<DeleteWearersApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/wearers/`,
      method: "DELETE",
      body: queryArg.wearersDeleteRequest,
    }),
  getWearer: (queryArg: GetWearerApiArg) =>
    fetchBase<GetWearerApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/wearers/${queryArg.wearerId}`,
    }),
  deleteWearer: (queryArg: DeleteWearerApiArg) =>
    fetchBase<DeleteWearerApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/wearers/${queryArg.wearerId}`,
      method: "DELETE",
    }),
  patchWearer: (queryArg: PatchWearerApiArg) =>
    fetchBase<PatchWearerApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/wearers/${queryArg.wearerId}`,
      method: "PATCH",
      body: queryArg.wearerPatchRequest,
    }),
  getProjectEnrichmentsAois: (
    queryArg: GetProjectEnrichmentsAoiApiArg,
    disableAbort?: boolean,
  ) =>
    fetchBase<GetProjectEnrichmentAoisApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/enrichments/${queryArg.enrichmentId}/aois`,
      disableAbort,
    }),
  postProjectEnrichmentAois: (queryArg: PostProjectEnrichmentAoiApiArg) => {
    delete queryArg.projectEnrichmentPostRequest["centroid_xy"];
    delete queryArg.projectEnrichmentPostRequest["bounding_box"];

    fetchBase<PostProjectEnrichmentAoiApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/enrichments/${queryArg.enrichmentId}/aois`,
      method: "POST",
      body: queryArg.projectEnrichmentPostRequest,
    });
  },
  deleteProjectEnrichmentAoiResource: (
    queryArg: DeleteProjectEnrichmentAoiResourceApiArg,
  ) =>
    fetchBase<DeleteProjectEnrichmentAoiResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/enrichments/${queryArg.enrichmentId}/aois/${queryArg.aoiId}`,
      method: "DELETE",
    }),
  patchProjectEnrichmentAoiResource: (
    queryArg: PatchProjectEnrichmentAoiResourceApiArg,
  ) =>
    fetchBase<PatchProjectEnrichmentAoiResourceApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/enrichments/${queryArg.enrichmentId}/aois/${queryArg.aoiId}`,
      method: "PATCH",
      body: queryArg.projectEnrichmentAoiPatchRequest,
    }),
  getFaceEnrichmentLocalization: (queryArg: GetFaceEnrichmentLocalizationApiArg) =>
    fetchBase<GetFaceEnrichmentLocalizationApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/recordings/${queryArg.recordingId}/face_detections_timeline?start=${queryArg.start}&end=${queryArg.end}&intervalsize=${queryArg.intervalSize}`,
    }),
  getSurfaceEnrichmentLocalization: (
    queryArg: GetSurfaceEnrichmentLocalizationApiArg,
    disableAbort = false,
  ) =>
    fetchBase<GetSurfaceEnrichmentLocalizationApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/surfaces/${
        queryArg.surfaceId
      }/recordings/${queryArg.recordingId}/gaze_in_aoi_timeline?start=${
        queryArg.start
      }&end=${queryArg.end}${
        queryArg.intervalSize ? `&intervalsize=${queryArg.intervalSize}` : ""
      }&content=1`,
      disableAbort,
    }),
  getMarkerlessEnrichmentLocalization: (
    queryArg: GetMarkerlessEnrichmentLocalizationApiArg,
  ) =>
    fetchBase<GetMarkerlessEnrichmentLocalizationApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/markerless/${
        queryArg.markerlessId
      }/recordings/${queryArg.recordingId}/gaze_in_aoi_timeline?start=${
        queryArg.start
      }&end=${queryArg.end}${
        queryArg.intervalSize ? `&intervalsize=${queryArg.intervalSize}` : ""
      }&content=1`,
    }),
  getAoiStats: (queryArg: GetAoiStatsApiArg, disableAbort?: boolean) =>
    fetchBase<GetAoiStatsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${
        queryArg.projectId
      }/enrichments/${queryArg.enrichmentId}/aois:stats${
        queryArg.params ? `?${queryArg.params}` : ""
      }`,
      disableAbort,
    }),
  getProjectVisualizations: (queryArg: GetProjectVisualizationsApiArg) =>
    fetchBase<GetProjectVisualizationsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/visualization_configs`,
    }),
  postProjectVisualizations: (queryArg: PostProjectVisualizationApiArg) =>
    fetchBase<PostProjectVisualizationApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/visualization_configs`,
      method: "POST",
      body: queryArg.projectVisualizationPostRequest,
    }),
  deleteProjectVisualizations: (queryArg: DeleteProjectVisualizationApiArg) =>
    fetchBase<PostProjectVisualizationApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/visualization_configs/${queryArg.visualizationId}`,
      method: "DELETE",
    }),
  patchProjectVisualizations: (queryArg: PatchProjectVisualizationApiArg) =>
    fetchBase<PostProjectVisualizationApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/visualization_configs/${queryArg.visualizationId}`,
      method: "PATCH",
      body: queryArg.projectVisualizationPatchRequest,
    }),
  getStaticImageMappers: (queryArg: GetStaticImageMappersApiArg) =>
    fetchBase<GetStaticImageMappersApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/mappers/static`,
      method: "GET",
    }),
  getStaticImageMapper: (queryArg: GetStaticImageMapperApiArg) =>
    fetchBase<PostStaticImageMapperApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/mappers/static/${queryArg.enrichmentId}`,
      method: "GET",
    }),
  postStaticImageMapper: (queryArg: PostStaticImageMapperApiArg) =>
    fetchBase<PostStaticImageMapperApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/mappers/static`,
      method: "POST",
      body: queryArg.projectStaticImageMapperPostRequest,
    }),
  patchStaticImageMapper: (queryArg: PatchStaticImageMapperApiArg) =>
    fetchBase<PostStaticImageMapperApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/mappers/static/${queryArg.enrichmentId}`,
      method: "PATCH",
      body: queryArg.projectStaticImageMapperPatchRequest,
    }),
  getStaticImageMapperFixations: (queryArg: GetStaticImageMapperFixationsApiArg) =>
    fetchBase<GetStaticImageMapperFixationsApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/mappers/static/${queryArg.enrichmentId}/recordings/${queryArg.recordingId}/fixations`,
      method: "GET",
    }),
  putStaticImageMapperFixation: (queryArg: PutStaticImageMapperFixationApiArg) =>
    fetchBase<PutStaticImageMapperFixationApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/mappers/static/${queryArg.enrichmentId}/recordings/${queryArg.recordingId}/fixations/${queryArg.fixationId}`,
      method: "PUT",
      body: queryArg.projectStaticImageMapperFixationPutRequest,
    }),
  deleteStaticImageMapperFixation: (queryArg: DeleteStaticImageMapperFixationApiArg) =>
    fetchBase<DeleteStaticImageMapperFixationApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/mappers/static/${queryArg.enrichmentId}/recordings/${queryArg.recordingId}/fixations/${queryArg.fixationId}`,
      method: "DELETE",
    }),
  resetStaticImageMapperFixations: (queryArg: ResetStaticImageMapperFixationsApiArg) =>
    fetchBase<DeleteStaticImageMapperFixationApiResponse>({
      url: `/workspaces/${queryArg.workspaceId}/projects/${queryArg.projectId}/mappers/static/${queryArg.enrichmentId}/recordings/${queryArg.recordingId}/fixations:clear`,
      method: "POST",
    }),
  generatePupilPlayerFormatDownloadLink: (queryArg: any) =>
    fetchBase<{ result: string }>({
      url: `/workspaces/${queryArg.workspaceId}/recordings.zip?${queryArg.queryString}&make-share-link=604800`,
      method: "GET",
    }),
};

export type PostLoginApiResponse = /** status 200 Login success */ LoginPostResponse;
export type PostLoginApiArg = {
  loginRequest: LoginRequest;
};
export type PostLogoutApiResponse =
  /** status 200 The current user */ LogoutPostResponse;
export type PostLogoutApiArg = void;
export type GetProfileApiResponse =
  /** status 200 The current user */ ProfileGetResponse;
export type GetProfileApiArg = void;
export type PatchProfileApiResponse =
  /** status 200 Updated profile */ ProfilePatchResponse;
export type PatchProfileApiArg = {
  profilePatchRequest: ProfilePatchRequest;
};
export type GetWorkspaceInvitationsApiResponse =
  /** status 200 Workspace invitations */ WorkspaceInvitationsGetResponse;
export type GetWorkspaceInvitationsApiArg = void;
export type GetAuthInvitationApiResponse =
  /** status 200 Workspace invitation */ AuthInvitationGetResponse;
export type GetAuthInvitationApiArg = {
  token: string;
};
export type DeleteAuthWorkspaceInvitationApiResponse = unknown;
export type DeleteAuthWorkspaceInvitationApiArg = {
  token: string;
};
export type PostAcceptInvitationResourceApiResponse =
  /** status 200 Workspace member */ AcceptInvitationResourcePostResponse;
export type PostAcceptInvitationResourceApiArg = {
  token: string;
};
export type GetTokensApiResponse =
  /** status 200 The token response */ TokensGetResponse;
export type GetTokensApiArg = void;
export type PostTokenApiResponse =
  /** status 200 The token response */ TokenPostResponse;
export type PostTokenApiArg = {
  tokenPostRequest: TokenPostRequest;
};
export type GetTokenApiResponse = /** status 200 The token response */ TokenGetResponse;
export type GetTokenApiArg = {
  tokenId: string;
};
export type DeleteTokenApiResponse =
  /** status 200 The token response */ TokenDeleteResponse;
export type DeleteTokenApiArg = {
  tokenId: string;
};
export type PostUnregisterApiResponse = unknown;
export type GetCannySsoTokenApiResponse = {
  code: number;
  result: { token: string };
  status: string;
};
export type PostUnregisterApiArg = void;
export type GetHardwareApiResponse = HardwareGetResponse;
export type GetHardwareApiArg = {
  serialNumber: string;
  version: string;
};
export type GetHardwareSceneApiArg = {
  workspaceId: string;
  recordingId: string;
};
export type GetWorkspacesApiResponse =
  /** status 200 List of workspaces */ WorkspacesGetResponse;
export type GetWorkspacesApiArg = void;
export type PostWorkspaceApiResponse =
  /** status 200 Created a workspace */
  WorkspacePostResponse | /** status 201 Successful operation */ string;
export type PostWorkspaceApiArg = {
  workspacePostRequest: WorkspacePostRequest;
};
export type GetWorkspaceApiResponse =
  /** status 200 A single workspace */ WorkspaceGetResponse;
export type GetWorkspaceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
};
export type DeleteWorkspaceApiResponse = unknown;
export type DeleteWorkspaceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
};
export type UpdateWorkspaceApiResponse =
  /** status 200 Update a workspace */
  WorkspaceUpdateResponse | /** status 201 Successful operation */ string;
export type UpdateWorkspaceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  workspacePatchRequest: WorkspacePatchRequest;
};
export type GetFilesApiResponse = /** status 200 A list of files */ FilesGetResponse;
export type GetFilesApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
};
export type PostFileApiResponse = /** status 200 File data */ FilePostResponse;
export type PostFileApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  /** Used for tus upload requests */
  "Tus-Resumable"?: any;
};
export type GetFileMetadataApiResponse =
  /** status 200 File details */ FileMetadataGetResponse;
export type GetFileMetadataApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  fileId: string;
};
export type HeadFileMetadataApiResponse = unknown;
export type HeadFileMetadataApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  fileId: string;
  /** Used for tus upload requests */
  "Tus-Resumable"?: any;
};
export type PatchFileApiResponse = unknown;
export type PatchFileApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  fileId: string;
  /** Used for tus upload requests */
  "Tus-Resumable"?: any;
};
export type DownloadFileApiResponse = unknown;
export type DownloadFileApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  fileId: string;
  filename: string;
};
export type GetFiltersApiResponse =
  /** status 200 A list of filters */ FiltersGetResponse;
export type GetFiltersApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
};
export type PostFiltersApiResponse =
  /** status 200 Created filter */
  FiltersPostResponse | /** status 201 Successful operation */ string;
export type PostFiltersApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  filterPostRequest: FilterPostRequest;
};
export type DeleteFiltersApiResponse = unknown;
export type DeleteFiltersApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  filtersDeleteRequest: FiltersDeleteRequest;
};
export type GetFilterApiResponse = /** status 200 A single filter */ FilterGetResponse;
export type GetFilterApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  filterId: string;
};
export type DeleteFilterApiResponse = unknown;
export type DeleteFilterApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  filterId: string;
};
export type PatchFilterApiResponse =
  /** status 200 Updated filter */ FilterPatchResponse;
export type PatchFilterApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  filterId: string;
  filterPatchRequest: FilterPatchRequest;
};
export type GetHeatmapApiResponse = unknown;
export type GetHeatmapApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
};
export type PostHeatmapApiResponse = {
  result: { height: number; width: number; histogram: number[] };
};
export type PostHeatmapApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  generateHeatmap: GenerateHeatmap;
};
export type GetHeatmapBackgroundApiResponse = string;
export type GetHeatmapBackgroundApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  project_id: string;
  enrichment_id: string;
  maxSize?: number;
  disableMaxSize?: boolean;
};
export type GetDownloadHeatmapApiResponse = unknown;
export type GetDownloadHeatmapApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
};
export type PostDownloadHeatmapApiResponse = unknown;
export type PostDownloadHeatmapApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  generateHeatmap: GenerateHeatmap;
};
export type ListWorkspaceInvitationsApiResponse =
  /** status 200 Workspace invitations */ WorkspaceInvitationsListResponse;
export type ListWorkspaceInvitationsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
};
export type CreateWorkspaceInvitationApiResponse =
  /** status 200 Workspace invitations */ WorkspaceInvitationCreateResponse;
export type CreateWorkspaceInvitationApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  workspaceInvitationPostRequest: WorkspaceInvitationPostRequest;
};
export type GetWorkspaceInvitationApiResponse =
  /** status 200 Workspace invitation */ WorkspaceInvitationGetResponse;
export type GetWorkspaceInvitationApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  token: string;
};
export type DeleteWorkspaceInvitationApiResponse = unknown;
export type DeleteWorkspaceInvitationApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  token: string;
};
export type GetLabelsApiResponse = /** status 200 A list of labels */ LabelsGetResponse;
export type GetLabelsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
};
export type PostLabelApiResponse =
  /** status 200 Created label */
  LabelPostResponse | /** status 201 Successful operation */ string;
export type PostLabelApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  labelPostRequest: LabelPostRequest;
};
export type DeleteLabelsApiResponse = unknown;
export type DeleteLabelsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  labelsDeleteRequest: LabelsDeleteRequest;
};
export type GetLabelApiResponse = /** status 200 A single label */ LabelGetResponse;
export type GetLabelApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  labelId: string;
};
export type DeleteLabelApiResponse = unknown;
export type DeleteLabelApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  labelId: string;
};
export type PatchLabelApiResponse = /** status 200 Updated label */ LabelPatchResponse;
export type PatchLabelApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  labelId: string;
  labelPatchRequest: LabelPatchRequest;
};
export type PostMarkerlessApiResponse =
  /** status 200 Success */
  MarkerlessPostResponse;
export type PostMarkerlessApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  markerlessInitialization: MarkerlessInitialization;
};
export type GetMarkerlessApiResponse =
  /** status 200 Markerless scanning definition */ MarkerlessGetResponse;
export type GetMarkerlessApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  markerlessId: string;
};
export type GetGazeOnAoiApiResponse =
  /** status 200 List of gaze in aoi coordinates with time ranges */ GazeOnAoiGetResponse;
export type GetGazeOnAoiApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  markerlessId: string;
  format: string;
};
export type GetGazeOnAoiSurfaceApiResponse =
  /** status 200 List of gaze in aoi coordinates with time ranges */ GazeOnAoiSurfaceGetResponse;
export type GetGazeOnAoiSurfaceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  surfaceId: string;
  format: string;
};
export type ScanMarkerlessApiResponse = unknown;
export type ScanMarkerlessApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  markerlessId: string;
};
export type PatchMarkerlessGazeOnAoiDataApiResponse = unknown;
export type PatchMarkerlessGazeOnAoiDataApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  markerlessId: string;
  projectEnrichment: ProjectEnrichment;
};
export type GetWorkspaceMembersApiResponse =
  /** status 200 Workspace members */ WorkspaceMembersGetResponse;
export type GetWorkspaceMembersApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
};
export type PostWorkspaceMembersResourceApiResponse =
  /** status 200 Success */ WorkspaceMembersResourcePostResponse;
export type PostWorkspaceMembersResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  workspaceMemberPostRequest: WorkspaceMemberPostRequest;
};
export type GetWorkspaceMemberApiResponse =
  /** status 200 Workspace member */ WorkspaceMemberGetResponse;
export type GetWorkspaceMemberApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  userId: string;
};
export type DeleteWorkspaceMemberApiResponse =
  /** status 200 Workspace members */ WorkspaceMemberDeleteResponse;
export type DeleteWorkspaceMemberApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  userId: string;
};
export type PatchWorkspaceMemberApiResponse =
  /** status 200 Patch workspace members */ WorkspaceMemberPatchResponse;
export type PatchWorkspaceMemberApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  userId: string;
  workspaceMemberPatchRequest: WorkspaceMemberPatchRequest;
};
export type GetProjectsResourceApiResponse =
  /** status 200 A list of projects */ ProjectsResourceGetResponse;
export type GetProjectsResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
};
export type PostProjectsResourceApiResponse =
  /** status 200 Created project */
  ProjectsResourcePostResponse | /** status 201 Successful operation */ string;
export type PostProjectsResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectPostRequest: ProjectPostRequest;
};
export type DeleteProjectsResourceApiResponse = unknown;
export type DeleteProjectsResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectsDeleteRequest: ProjectsDeleteRequest;
};
export type GetProjectResourceApiResponse =
  /** status 200 A single project */ ProjectResourceGetResponse;
export type GetProjectResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
};
export type DeleteProjectResourceApiResponse = unknown;
export type DeleteProjectResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
};
export type PatchProjectResourceApiResponse =
  /** status 200 Updated project */ ProjectResourcePatchResponse;
export type PatchProjectResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  projectPatchRequest: ProjectPatchRequest;
};
export type GetProjectEnrichmentsApiResponse =
  /** status 200 A list of project enrichments */ ProjectEnrichmentsGetResponse;
export type GetProjectEnrichmentsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
};
export type PostProjectEnrichmentApiResponse =
  /** status 200 Created project enrichment */
  ProjectEnrichmentPostResponse | /** status 201 Successful operation */ string;
export type PostProjectEnrichmentApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  projectEnrichmentPostRequest: ProjectEnrichmentPostRequest;
};
export type GetProjectEnrichmentApiResponse =
  /** status 200 A project enrichment */ ProjectEnrichmentGetResponse;
export type GetProjectEnrichmentApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
};
export type DeleteProjectEnrichmentResourceApiResponse = unknown;
export type DeleteProjectEnrichmentResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
};
export type PatchProjectEnrichmentResourceApiResponse =
  /** status 200 Updated project enrichment */ ProjectEnrichmentResourcePatchResponse;
export type PatchProjectEnrichmentResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
  projectEnrichmentPatchRequest: ProjectEnrichmentPatchRequest;
};
export type PostProjectEnrichmentCancelResourceApiResponse = unknown;
export type PostProjectEnrichmentCancelResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
};
export type GetProjectEnrichmentComputeResourceApiResponse = unknown;
export type GetProjectEnrichmentComputeResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
};
export type PostProjectEnrichmentComputeResourceApiResponse = unknown;
export type PostProjectEnrichmentComputeResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
};
export type GetProjectEnrichmentExportResourceApiResponse = unknown;
export type GetProjectEnrichmentExportResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
};
export type PostProjectEnrichmentExportResourceApiResponse = unknown;
export type PostProjectEnrichmentExportResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
  projectEnrichmentExport: ProjectEnrichmentExport;
};
export type GetProjectEventsResourceApiResponse =
  /** status 200 Project recording events */ ProjectEventsResourceGetResponse;
export type GetProjectEventsResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
};
export type GetProjectRecordingsResourceApiResponse =
  /** status 200 A project's recordings */ ProjectRecordingsResourceGetResponse;
export type GetProjectRecordingsResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
};
export type GetProjectRecordingEnrichmentSlicesApiResponse =
  /** status 200 A recordings enrichment slices */ ProjectRecordingEnrichmentSlicesGetResponse;
export type GetProjectRecordingEnrichmentSlicesApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  recordingId: string;
  enrichmentId: string;
};
export type GetProjectRecordingEventsResourceApiResponse =
  /** status 200 A project's recording events */ ProjectRecordingEventsResourceGetResponse;
export type GetProjectRecordingEventsResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  recordingId: string;
};
export type PostProjectRecordingEventsResourceApiResponse =
  /** status 200 Created project recording event */
  | ProjectRecordingEventsResourcePostResponse
  | /** status 201 Successful operation */ string;
export type PostProjectRecordingEventsResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  recordingId: string;
  projectRecordingEventPostRequest: ProjectRecordingEventPostRequest;
};
export type DeleteProjectRecordingEventResourceApiResponse = unknown;
export type DeleteProjectRecordingEventResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  recordingId: string;
  eventId: string;
};
export type PatchProjectRecordingEventResourceApiResponse =
  /** status 200 Updated event */ ProjectRecordingEventResourcePatchResponse;
export type PatchProjectRecordingEventResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  recordingId: string;
  eventId: string;
  projectRecordingEventPatchRequest: ProjectRecordingEventPatchRequest;
};
export type GetProjectUniqueEventsApiResponse =
  /** status 200 A project's unique events */ ProjectUniqueEventsGetResponse;
export type GetProjectUniqueEventsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
};
export type GetWorkspaceUniqueEventsApiResponse =
  /** status 200 A project's unique events */ WorkspaceUniqueEventsGetResponse;
export type GetWorkspaceUniqueEventsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
};
export type DownloadProjectApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  downloadProjectPostRequest: DownloadProjectPostRequest;
};
export type DownloadRecordingsZipApiResponse = unknown;
export type DownloadRecordingsZipApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  /** recording ids to download */
  ids?: string[];
};
export type GetRecordingsApiResponse =
  /** status 200 A list of recordings */ RecordingsGetResponse;
export type GetRecordingsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
};
export type PostRecordingApiResponse =
  /** status 200 Recording */ RecordingPostResponse;
export type PostRecordingApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingPostRequest: RecordingPostRequest;
};
export type DeleteRecordingsApiResponse = unknown;
export type DeleteRecordingsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingsDeleteRequest: RecordingsDeleteRequest;
};
export type GetRecordingApiResponse =
  /** status 200 A single recording */ RecordingGetResponse;
export type GetRecordingApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
};
export type DeleteRecordingApiResponse = unknown;
export type DeleteRecordingApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
};
export type PatchRecordingApiResponse =
  /** status 200 Updated recording */ RecordingPatchResponse;
export type PatchRecordingApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
  recordingPatchRequest: RecordingPatchRequest;
};
export type DownloadRecordingZipApiResponse = unknown;
export type DownloadRecordingZipApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
};
export type GetApriltagsAtTimestampApiResponse =
  /** status 200 Apriltags in frame */ ApriltagsAtTimestampGetResponse;
export type GetApriltagsAtTimestampApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
  /** timestamp offset in recording in seconds */
  timestamp?: any;
  /** return duplicate apriltags (defaults to best) */
  withDuplicates?: boolean;
};
export type GetRecordingEventsApiResponse =
  /** status 200 A list of recording events */ RecordingEventsGetResponse;
export type PostRecordingEventsApiResponse =
  /** status 200 A list of recording events */ RecordingEventsPostResponse;
export type PatchRecordingEventsApiResponse =
  /** status 200 A list of recording events */ RecordingEventsPatchResponse;
export type DeleteRecordingEventsApiResponse =
  /** status 200 A list of recording events */ RecordingEventsDeleteResponse;
export type GetRecordingEventsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
};
export type PostRecordingEventsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
  RecordingEventPostRequest: RecordingEventPostRequest;
};
export type PatchRecordingEventsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
  eventId: string;
  RecordingEventPatchRequest: Partial<RecordingEvent>;
};
export type DeleteRecordingEventsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
  eventId: string;
};
export type GetRecordingFaceDetectionsApiResponse =
  /** status 200 A list of face detections */ RecordingFaceDetectionsGetResponse;
export type GetRecordingFaceDetectionsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
  format: string;
};
export type GetRecordingPointCloudApiResponse =
  /** status 200 A list of face detections */ RecordingFPointCloudGetResponse;
export type GetRecordingPointCloudApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
  markerlessId: string;
  format: string;
};
export type GetMarkerLessPointCloudApiResponse =
  /** status 200 A list of face detections */ RecordingFaceDetectionsGetResponse;
export type GetMarkerLessPointCloudApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  markerlessId: string;
};
export type GetRecordingFilesApiResponse =
  /** status 200 A list of a recording's files */ RecordingFilesGetResponse;
export type GetRecordingFilesApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
};
export type DownloadRecordingFileApiResponse = unknown;
export type DownloadRecordingFileApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
  filename: string;
};
export type GetRecordingGazeApiResponse =
  /** status 200 A list of gaze points */ RecordingGazeGetResponse;
export type GetRecordingScanPathApiResponse =
  /** status 200 A list of gaze points */ RecordingScanPathGetResponse;
export type GetRecordingGazeApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
  format: string;
};
export type GetWorldFrameApiResponse = unknown;
export type GetWorldFrameApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
  /** timestamp in recording for */
  timestamp?: number;
  /** height of result */
  height?: number;
  /** width of result */
  width?: number;
};
export type PostRenderApiResponse =
  /** status 200 Created render definition */
  RenderPostResponse | /** status 201 Successful operation */ string;
export type PostRenderApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  worldRender: WorldRender;
};
export type GetRenderApiResponse =
  /** status 200 A render definition */ RenderGetResponse;
export type GetRenderApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  renderDefinitionId: string;
};
export type PatchRenderApiResponse =
  /** status 200 Updated render definition */ RenderPatchResponse;
export type PatchRenderApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  renderDefinitionId: string;
  worldRender: WorldRender;
};
export type PostSurfaceApiResponse =
  /** status 200 Success */
  SurfacePostResponse | /** status 201 Successful operation */ string;
export type PostSurfaceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  surfaceInitialization: SurfaceInitialization;
};
export type GetSurfaceApiResponse = /** status 200 Success */ SurfaceGetResponse;
export type GetSurfaceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  surfaceId: string;
};
export type PatchSurfaceApiResponse = /** status 200 Success */ SurfacePatchResponse;
export type PatchSurfaceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  surfaceId: string;
  patchSurface: PatchSurface;
};
export type GetApriltagDetectionsApiResponse =
  /** status 200 List of apriltag detections */ ApriltagDetectionsGetResponse;
export type GetApriltagDetectionsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  surfaceId: string;
  format: string;
};
export type PatchRotateSurfaceOrientationApiResponse = unknown;
export type PatchRotateSurfaceOrientationApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  surfaceId: string;
  orientationRotation: OrientationRotation;
};
export type PostDistortedSurfaceLocationApiResponse =
  /** status 200 Surface corner coordinates */ DistortedSurfaceLocationPostResponse;
export type PostDistortedSurfaceLocationApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  surfaceId: string;
  setDistortedSurfaceRequest: SetDistortedSurfaceRequest;
};
export type GetSurfaceCornersApiResponse =
  /** status 200 List of surface locations */ SurfaceCornersGetResponse;
export type GetSurfaceCornersApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  surfaceId: string;
  format: string;
};
export type PatchSurfaceGazeOnAoiDataApiResponse = unknown;
export type PatchSurfaceGazeOnAoiDataApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  surfaceId: string;
  projectEnrichment: ProjectEnrichment;
};
export type GetTemplatesApiResponse =
  /** status 200 A list of templates */ TemplatesGetResponse;
export type GetTemplatesApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
};
export type PostTemplateApiResponse =
  /** status 200 Created template */
  TemplatePostResponse;
export type PostTemplateApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  templatePostRequest: TemplatePostRequest;
};
export type DeleteTemplatesApiResponse = unknown;
export type DeleteTemplatesApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  templatesDeleteRequest: TemplatesDeleteRequest;
};
export type GetTemplateApiResponse =
  /** status 200 A single template */ TemplateGetResponse;
export type GetTemplateApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  templateId: string;
};
export type DeleteTemplateApiResponse =
  /** status 200 Delete a single template */ TemplateDeleteResponse;
export type DeleteTemplateApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  templateId: string;
};
export type PatchTemplateApiResponse =
  /** status 200 Updated template */ TemplatePatchResponse;
export type PatchTemplateApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  templateId: string;
  templatePatchRequest: TemplatePatchRequest;
};
export type GetWearersApiResponse =
  /** status 200 A list of wearers */ WearersGetResponse;
export type GetWearersApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
};
export type PostWearerApiResponse =
  /** status 200 Created wearer */
  WearerPostResponse | /** status 201 Successful operation */ string;
export type PostWearerApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  wearerPostRequest: WearerPostRequest;
};
export type DeleteWearersApiResponse = unknown;
export type DeleteWearersApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  wearersDeleteRequest: WearersDeleteRequest;
};
export type GetWearerApiResponse = /** status 200 A single wearer */ WearerGetResponse;
export type GetWearerApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  wearerId: string;
};
export type DeleteWearerApiResponse =
  /** status 200 Delete a single wearer */ WearerDeleteResponse;
export type DeleteWearerApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  wearerId: string;
};
export type PatchWearerApiResponse =
  /** status 200 Updated wearer */ WearerPatchResponse;
export type PatchWearerApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  wearerId: string;
  wearerPatchRequest: WearerPatchRequest;
};
export type GetProjectEnrichmentAoisApiResponse =
  /** status 200 A list of project enrichment aois */ ProjectEnrichmentsAoiGetResponse;
export type GetProjectEnrichmentsAoiApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
};
export type GetFaceEnrichmentLocalizationApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
  start?: number;
  end?: number;
  intervalSize: number;
};
export type GetSurfaceEnrichmentLocalizationApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
  surfaceId: string;
  start?: number;
  end?: number;
  intervalSize?: number;
};
export type GetAoiStatsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
  params?: string;
};
export type GetMarkerlessEnrichmentLocalizationApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  recordingId: string;
  markerlessId: string;
  start?: number;
  end?: number;
  intervalSize?: number;
};
export type GetFaceEnrichmentLocalizationApiResponse =
  /** status 200 A list of project enrichment aois */ FaceEnrichmentLocalizationGetResponse;
export type GetSurfaceEnrichmentLocalizationApiResponse =
  /** status 200 A list of project enrichment aois */ SurfaceEnrichmentLocalizationGetResponse;
export type GetMarkerlessEnrichmentLocalizationApiResponse =
  /** status 200 A list of project enrichment aois */ MarkerlessEnrichmentLocalizationGetResponse;
export type GetAoiStatsApiResponse =
  /** status 200 A list of project enrichment aois */ AoiStatsGetResponse;
export type PostProjectEnrichmentAoiApiResponse =
  /** status 200 Created project enrichment aois */ ProjectEnrichmentAoiPostResponse;
export type PostProjectEnrichmentAoiApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
  projectEnrichmentPostRequest: ProjectEnrichmentAoiPostRequest;
};
export type DeleteProjectEnrichmentAoiResourceApiResponse = unknown;
export type DeleteProjectEnrichmentAoiResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
  aoiId: string;
};
export type PatchProjectEnrichmentAoiResourceApiResponse =
  /** status 200 Updated project enrichment */ ProjectEnrichmentAoiResourcePatchResponse;
export type PatchProjectEnrichmentAoiResourceApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
  aoiId: string;
  projectEnrichmentAoiPatchRequest: ProjectEnrichmentAoiPatchRequest;
};
export type GetProjectVisualizationsApiResponse =
  /** status 200 A list of project visualization */ ProjectVisualizationsGetResponse;
export type GetProjectVisualizationsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
};
export type PostProjectVisualizationApiResponse =
  /** status 200 Created project visualization */
  ProjectVisualizationPostResponse;
export type PostProjectVisualizationApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  projectVisualizationPostRequest: ProjectVisualizationPostRequest;
};
export type DeleteProjectVisualizationApiResponse = unknown;
export type DeleteProjectVisualizationApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  visualizationId: string;
};
export type PatchProjectVisualizationApiResponse =
  /** status 200 Updated project visualization */ ProjectVisualizationPatchResponse;
export type PatchProjectVisualizationApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  visualizationId: string;
  projectVisualizationPatchRequest: ProjectVisualizationPatchRequest;
};
export type GetStaticImageMappersApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
};
export type GetStaticImageMapperApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
};
export type GetStaticImageMapperFixationsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
  recordingId: string;
};
export type PutStaticImageMapperFixationApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
  recordingId: string;
  fixationId: number;
  projectStaticImageMapperFixationPutRequest: ProjectStaticImageMapperFixationPutRequest;
};
export type DeleteStaticImageMapperFixationApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
  recordingId: string;
  fixationId: number;
};
export type ResetStaticImageMapperFixationsApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
  recordingId: string;
};
export type PostStaticImageMapperApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  projectStaticImageMapperPostRequest: StaticImageMapperPostRequest;
};
export type PatchStaticImageMapperApiArg = {
  /** Workspace UUID, can also use `default` for your personal workspace */
  workspaceId: string;
  projectId: string;
  enrichmentId: string;
  projectStaticImageMapperPatchRequest: StaticImageMapperPostRequest;
};
export type User = {
  id: string;
  name: string;
  default_workspace_id: string;
  email: string;
  email_verified: boolean;
  photo_url: string;
  product_updates_subscribed: boolean;
  provider: string;
  uid: string;
  onboarded_at: string;
  user_workspaces: WorkspaceMembership[];
};
export type Profile = {
  id: string;
  name: string;
  default_workspace_id: string;
  email: string;
  email_verified: boolean;
  photo_url: string;
  product_updates_subscribed: boolean;
  provider: string;
  uid: string;
  onboarded_at: string;
  user_workspaces: WorkspaceMembership[];
};
export type LoginPostResponse = {
  code: number;
  result: Profile;
  status: string;
};
export type LoginRequest = {
  csrf_token: string;
  id_token: string;
  product_updates_subscribed?: boolean;
  type: "login" | "register";
};
export type LogoutPostResponse = {
  code: number;
  result: Profile;
  status: string;
};
export type ProfileGetResponse = {
  code: number;
  result: Profile;
  status: string;
};
export type ProfilePatchResponse = {
  code: number;
  result: Profile;
  status: string;
};
export type ProfilePatchRequest = {
  name?: string;
  onboarded?: boolean;
  photo_url?: string;
  product_updates_subscribed?: boolean;
};
export type WorkspaceInvitation = {
  created_at?: string;
  created_by_user_id: string;
  email: string;
  expires_at?: string;
  id: string;
  role: "VIEWER" | "EDITOR" | "DEMO" | "ADMIN";
  token?: string;
  workspace_id: string;
};
export type WorkspaceInvitationsGetResponse = {
  code: number;
  result: WorkspaceInvitation[];
  status: string;
};
export type AuthInvitationGetResponse = {
  code: number;
  result: WorkspaceInvitation;
  status: string;
};
export type WorkspaceRoles = "VIEWER" | "EDITOR" | "DEMO" | "ADMIN" | "OWNER";
export type WorkspaceMember = {
  created_at?: string;
  role?: WorkspaceRoles;
  updated_at?: string;
  user?: User;
  user_id: string;
  workspace_id: string;
};
export type WorkspaceMembership = {
  role: WorkspaceRoles;
  workspace: Workspace;
};
export type AcceptInvitationResourcePostResponse = {
  code: number;
  result: WorkspaceMember;
  status: string;
};
export type Token = {
  created_at?: string;
  id: string;
  last_used_at?: string;
  name?: string | null;
  token?: string;
};
export type TokensGetResponse = {
  code: number;
  result: Token[];
  status: string;
};
export type TokenPostResponse = {
  code: number;
  result: Token;
  status: string;
};
export type TokenPostRequest = {
  name: string;
};
export type TokenGetResponse = {
  code: number;
  result: Token;
  status: string;
};
export type TokenDeleteResponse = {
  code: number;
  result: Token;
  status: string;
};
export type WorkspaceWorldVideoMode = "NORMAL" | "NOUPLOAD" | "BLUR";
export type Workspace = {
  alpha_features?: boolean;
  created_at?: string;
  created_by_user_id: string;
  default_template_id: string;
  deleted_at: string | null;
  deleted_by_user_id: string | null;
  world_video_mode: WorkspaceWorldVideoMode;
  id: string;
  name: string;
  picture_url?: string;
  plan_id: string;
  status: string;
  updated_at?: string;
  raw_file_downloads?: boolean;
};
export type WorkspacesGetResponse = {
  code: number;
  result: Workspace[];
  status: string;
};
export type WorkspacePostResponse = {
  code: number;
  result: Workspace;
  status: string;
};
export type WorkspacePostRequest = {
  name: string;
  picture_url?: string;
  world_video_mode: WorkspaceWorldVideoMode;
};
export type WorkspaceGetResponse = {
  code: number;
  result: Workspace;
  status: string;
};
export type HardwareGetResponse = {
  code: number;
  result: Calibration;
  status: string;
};
export type WorkspaceUpdateResponse = {
  code: number;
  result: Workspace;
  status: string;
};
export type WorkspacePatchRequest = {
  alpha_features?: boolean;
  created_at?: string;
  default_template_id?: string;
  deleted_at?: string;
  disable_world_video_upload?: boolean;
  id?: string;
  name?: string;
  picture_url?: string;
  plan_id?: string;
  status?: string;
  updated_at?: string;
  raw_file_downloads?: boolean;
};
export type FileResponse = {
  created_at?: string;
  created_by_user_id: string;
  id: string;
  mimetype?: string;
  name?: string;
  size_bytes?: number;
  started_at?: string;
  uploaded_bytes?: number;
  workspace_id: string;
  download_url: string;
};
export type FilesGetResponse = {
  code: number;
  result: FileResponse[];
  status: string;
};
export type FilePostResponse = {
  code: number;
  result: FileResponse;
  status: string;
};
export type FileMetadataGetResponse = {
  code: number;
  result: FileResponse;
  status: string;
};
export type Filter = {
  created_at?: string;
  created_by_user_id: string;
  filter_query?: string;
  filter_scope?: "recordings" | "templates" | "wearers";
  id: string;
  kind?: "simple" | "lucene";
  name: string;
  updated_at?: string;
};
export type FiltersGetResponse = {
  code: number;
  result: Filter[];
  status: string;
};
export type FiltersPostResponse = {
  code: number;
  result: Filter;
  status: string;
};
export type FilterPostRequest = {
  created_at?: string;
  filter_query?: string;
  filter_scope?: "recordings" | "templates" | "wearers";
  id: string;
  kind?: "simple" | "lucene";
  name: string;
  updated_at?: string;
};
export type FiltersDeleteRequest = {
  filter_ids?: string[];
};
export type FilterGetResponse = {
  code: number;
  result: Filter;
  status: string;
};
export type FilterPatchResponse = {
  code: number;
  result: Filter;
  status: string;
};
export type FilterPatchRequest = {
  created_at?: string;
  filter_query?: string;
  filter_scope?: "recordings" | "templates" | "wearers";
  id: string;
  kind?: "simple" | "lucene";
  name: string;
  updated_at?: string;
};
export type GenerateHeatmap = {
  colormap?: "Turbo" | "Jet" | "Magma" | "Viridis" | "Traffic Light";
  enrichment_id: string;
  height?: number;
  json?: boolean;
  max_size?: number;
  n_bins?: number;
  project_id: string;
  recording_ids?: string[];
  sigma?: number;
  width?: number;
};
export type WorkspaceInvitationsListResponse = {
  code: number;
  result: WorkspaceInvitation[];
  status: string;
};
export type WorkspaceInvitationCreateResponse = {
  code: number;
  result: WorkspaceInvitation;
  status: string;
};
export type WorkspaceInvitationPostRequest = {
  email: string;
  role: WorkspaceRoles;
};
export type WorkspaceInvitationGetResponse = {
  code: number;
  result: WorkspaceInvitation[];
  status: string;
};
export type Label = {
  created_at: Date;
  created_by_user_id: string;
  id: string;
  name: string;
  // recording_ids?: string[];
  description: string | null;
  color: string | null;
  updated_at: Date | null;
};
export type LabelsGetResponse = {
  code: number;
  result: readonly Label[];
  status: string;
};
export type LabelPostResponse = {
  code: number;
  result: Label;
  status: string;
};
export type LabelPostRequest = {
  created_at?: string;
  id?: string;
  name: string;
  color?: string | null;
  description?: string | null;
  recording_ids?: string[];
  updated_at?: string;
};
export type LabelsDeleteRequest = {
  label_ids?: string[];
};
export type LabelGetResponse = {
  code: number;
  result: Label;
  status: string;
};
export type LabelPatchResponse = {
  code: number;
  result: Label;
  status: string;
};
export type LabelPatchRequest = {
  name: string;
  description?: string | null;
  color?: string | null;
  recording_ids?: string[];
  updated_at?: string;
};
export type Markerless = {
  id: string;
  reference_image_id: string;
  scanning_recording_id: string;
  reference_image_thumbnail_url: string;
};
export type MarkerlessPostResponse = {
  code: number;
  result: Markerless;
  status: string;
};
export type MarkerlessInitialization = {
  reference_image_id: string;
  scanning_recording_id: string;
};
export type MarkerlessGetResponse = {
  code: number;
  result: Markerless;
  status: string;
};
export type GazeOnAoi = {
  end_timestamp?: number;
  gaze_in_aoi_x?: number;
  gaze_in_aoi_y?: number;
  start_timestamp?: number;
  fixation_id: number | null;
};
export type GazeOnAoiGetResponse = {
  code: number;
  result: GazeOnAoi[];
  status: string;
};
export type GazeOnAoiSurfaceGetResponse = {
  code: number;
  result: GazeOnAoi[];
  status: string;
};
export type EnrichmentTypes =
  | "face-mapper"
  | "marker-mapper"
  | "raw-data-export"
  | "render"
  | "slam-mapper"
  | "static-image-mapper";
export enum EnrichmentTypesEnum {
  FACE_MAPPER = "face-mapper",
  MARKER_MAPPER = "marker-mapper",
  RAW_DATA_EXPORT = "raw-data-export",
  RENDER = "render",
  SLAM_MAPPER = "slam-mapper",
  STATIC_IMAGE_MAPPER = "static-image-mapper",
}
export enum ProjectEnrichmentStatusType {
  COMPUTING = "COMPUTING",
  SUCCESS = "SUCCESS",
  READY = "READY",
  ERROR = "ERROR",
  STALE = "STALE",
}
export interface ProjectEnrichmentSlice {
  id: string;
  end_time_s: number;
  enrichment_id: string;
  error_message: string;
  recording_id: string;
  start_time_s: number;
  status: "SUCCESS" | "READY" | "ERROR" | "SCHEDULED" | "CANCELLING" | "PROCESSING";
}
export type ProjectEnrichmentArgs = Partial<
  Record<
    "render_definition_id" | "markerless_id" | "surface_id" | "static_image_id",
    string
  >
>;
export type ProjectEnrichmentBase<T = EnrichmentTypesEnum, S = any> = {
  created_by_user_id: string;
  deleted_by_user_id: string | null;
  //enrichment_id: string;
  project_id: string;
  status?: Record<ProjectEnrichmentStatusType, number>;
  slices: S[];
  args?: ProjectEnrichmentArgs;
  created_at?: string;
  from_event_name: string;
  id: string;
  kind?: T;
  name: string;
  to_event_name: string;
  updated_at?: string;
};

export type ProjectEnrichment =
  | ProjectEnrichmentBase<EnrichmentTypesEnum.FACE_MAPPER, ProjectEnrichmentSlice>
  | ProjectEnrichmentBase<EnrichmentTypesEnum.MARKER_MAPPER, ProjectEnrichmentSlice>
  | ProjectEnrichmentBase<EnrichmentTypesEnum.RAW_DATA_EXPORT, ProjectEnrichmentSlice>
  | ProjectEnrichmentBase<EnrichmentTypesEnum.RENDER, ProjectEnrichmentSlice>
  | ProjectEnrichmentBase<EnrichmentTypesEnum.SLAM_MAPPER, ProjectEnrichmentSlice>
  | (ProjectEnrichmentBase<
      EnrichmentTypesEnum.STATIC_IMAGE_MAPPER,
      ProjectEnrichmentSlice
    > & {
      fixations_status: Array<ManualMapperFixationStatus>;
    });

export type ProjectEnrichmentAoi = {
  bounding_box?: {
    max_x: number;
    max_y: number;
    min_x: number;
    min_y: number;
  };
  centroid_xy?: { x: number; y: number };
  color: string | null;
  created_at: string;
  created_by_user_id: string;
  description: string | null;
  enrichment_id: string;
  id: string;
  label_ids?: string[];
  mask_image_id?: string;
  mask_image_thumbnail_url?: string;
  mask_image_data_url?: string;
  name: string;
  updated_at: string;
};
export type FaceEnrichmentLocalization = {
  avg_faces: number;
  end_timestamp: number;
  max_faces: number;
  min_faces: number;
  start_timestamp: number;
  total_frames: number;
};
export type SurfaceEnrichmentLocalization = {
  end_timestamp: number;
  start_timestamp: number;
  total_frames: number;
  aoi_detected: number;
  gaze_on_aoi: number;
};
export type MarkerlessEnrichmentLocalization = {
  end_timestamp: number;
  start_timestamp: number;
  total_frames: number;
  aoi_detected: number;
  gaze_on_aoi: number;
};
export interface MarkerMapperEnrichmentLocalization {
  end_timestamp: number;
  start_timestamp: number;
  markerMapper: boolean;
  hasCoordinates: boolean;
  onRefImg: number | null;
}

export type AoiStatsRecording = {
  avg_fixation_duration_ms: number;
  first_fixation_id: number;
  fixations: number;
  gazes: number;
  ms_till_first_fixation: number;
  ms_till_first_gaze: number;
  recording_id: string;
  time_span_ms: number;
  total_fixation_duration_ms: number;
  total_gaze_duration_ms: number;
};
export type AoiStats = {
  aoi_id: string;
  avg_fixation_duration_ms: number | null;
  avg_fixations: number | null;
  avg_ms_till_first_fixation: number | null;
  max_fixation_duration_ms: number | null;
  max_ms_till_first_fixation: number | null;
  min_fixation_duration_ms: number | null;
  min_ms_till_first_fixation: number | null;
  total_fixation_duration_ms: number | null;
  reach: number | null;
  sum_fixation_duration_ms: number;
  recordings: Record<string, AoiStatsRecording>;
  _name: string;
  wearers: string[];
};
export type WorkspaceMembersGetResponse = {
  code: number;
  result: WorkspaceMember[];
  status: string;
};
export type WorkspaceMembersResourcePostResponse = {
  code: number;
  result: WorkspaceMember;
  status: string;
};
export type WorkspaceMemberPostRequest = {
  role: "VIEWER" | "EDITOR" | "DEMO" | "ADMIN";
  user_id: string;
};
export type WorkspaceMemberGetResponse = {
  code: number;
  result: WorkspaceMember;
  status: string;
};
export type WorkspaceMemberDeleteResponse = {
  code: number;
  result: WorkspaceMember[];
  status: string;
};
export type WorkspaceMemberPatchResponse = {
  code: number;
  result: WorkspaceMember;
  status: string;
};
export type WorkspaceMemberPatchRequest = {
  role: WorkspaceRoles;
  updated_at?: string;
};
export type Project = {
  created_at?: string;
  created_by_user_id: string;
  deleted_by_user_id: string;
  filter_ids?: string[];
  id: string;
  name: string;
  recording_ids?: string[];
  updated_at?: string;
};
export type ProjectsResourceGetResponse = {
  code: number;
  result: Project[];
  status: string;
};
export type ProjectsResourcePostResponse = {
  code: number;
  result: Project;
  status: string;
};
export type ProjectPostRequest = {
  created_at?: string;
  filter_ids?: string[];
  id?: string;
  name: string;
  recording_ids?: string[];
  updated_at?: string;
};
export type ProjectsDeleteRequest = {
  project_ids?: string[];
};
export type ProjectResourceGetResponse = {
  code: number;
  result: Project;
  status: string;
};
export type ProjectResourcePatchResponse = {
  code: number;
  result: Project;
  status: string;
};
export type ProjectPatchRequest = {
  created_at?: string;
  filter_ids?: string[];
  id: string;
  name: string;
  recording_ids?: string[];
  updated_at?: string;
};
export type ProjectEnrichmentsGetResponse = {
  code: number;
  result: ProjectEnrichment[];
  status: string;
};
export type ProjectEnrichmentPostResponse = {
  code: number;
  result: ProjectEnrichment;
  status: string;
};
export type ProjectEnrichmentPostRequest = {
  args?: ProjectEnrichmentArgs;
  created_at?: string;
  from_event_name: string;
  id: string;
  kind?: EnrichmentTypesEnum;
  name: string;
  project_id: string;
  to_event_name: string;
  updated_at?: string;
};
export type ProjectEnrichmentGetResponse = {
  code: number;
  result: ProjectEnrichment;
  status: string;
};
export type ProjectEnrichmentResourcePatchResponse = {
  code: number;
  result: ProjectEnrichment;
  status: string;
};
export type ProjectEnrichmentPatchRequest = {
  args?: ProjectEnrichmentArgs;
  created_at?: string;
  from_event_name?: string;
  id: string;
  kind?: "marker-mapper" | "slam-mapper" | "render" | "raw-data-export" | "face-mapper";
  name?: string;
  project_id: string;
  to_event_name?: string;
  updated_at?: string;
};
export type ProjectEnrichmentExport = {
  recording_ids?: string[];
};
export type ProjectRecordingEvent = {
  created_at?: string;
  id: string;
  name: string;
  offset_s: number;
  origin: "recording" | "project";
  project_id: string;
  recording_id: string;
  suppressed?: boolean;
  updated_at?: string;
};
export type ProjectEventsResourceGetResponse = {
  code: number;
  result: ProjectRecordingEvent[];
  status: string;
};
export type ProjectEvent = {
  count?: number;
  name?: string;
};
export type ProjectRecording = {
  events?: ProjectEvent[];
  project_id: string;
  recording_id: string;
};
export type ProjectRecordingsResourceGetResponse = {
  code: number;
  result: ProjectRecording[];
  status: string;
};
export type EnrichmentSlice = {
  end_time_s?: number;
  enrichment_id: string;
  error_message?: string;
  id: string;
  recording_id: string;
  start_time_s?: number;
  status: "READY" | "SCHEDULED" | "CANCELLING" | "PROCESSING" | "SUCCESS" | "ERROR";
};
export type ProjectRecordingEnrichmentSlicesGetResponse = {
  code: number;
  result: EnrichmentSlice[];
  status: string;
};
export type ProjectRecordingEventsResourceGetResponse = {
  code: number;
  result: ProjectRecordingEvent[];
  status: string;
};
export type ProjectRecordingEventsResourcePostResponse = {
  code: number;
  result: ProjectRecordingEvent;
  status: string;
};
export type ProjectRecordingEventPostRequest = {
  created_at?: string;
  id: string;
  name: string;
  offset_s: number;
  origin: "recording" | "project";
  project_id: string;
  recording_id: string;
  suppressed?: boolean;
  updated_at?: string;
};
export type ProjectRecordingEventResourcePatchResponse = {
  code: number;
  result: ProjectRecordingEvent;
  status: string;
};
export type ProjectRecordingEventPatchRequest = {
  created_at?: string;
  id: string;
  name: string;
  offset_s: number;
  origin: "recording" | "project";
  project_id: string;
  recording_id: string;
  suppressed?: boolean;
  updated_at?: string;
};
export type ProjectUniqueEventsGetResponse = {
  code: number;
  result: ProjectEvent[];
  status: string;
};
export type WorkspaceUniqueEventsGetResponse = {
  code: number;
  result: RecordingEventUnique[];
  status: string;
};
export type StaticImageMapperPostRequest = {
  created_at?: string;
  from_event_name?: string;
  name?: string;
  static_image_id?: string;
  to_event_name?: string;
  updated_at?: string;
};
export type GetStaticImageMappersApiResponse = {
  code: number;
  result: StaticImageMapper[];
  status: string;
};
export type PostStaticImageMapperApiResponse = {
  code: number;
  result: StaticImageMapper;
  status: string;
};
export type GetStaticImageMapperApiResponse = {
  code: number;
  result: StaticImageMapper;
  status: string;
};
export type GetStaticImageMapperFixationsApiResponse = {
  code: number;
  result: {
    fixations: StaticImageMapperFixation[];
    total_fixations: number;
    checked_fixations: number;
  };
  status: string;
};
export type StaticImageMapperFixation = {
  end_timestamp?: number;
  start_timestamp?: number;
  fixation_id: number;
  x: number | null;
  y: number | null;
  gaze_on_aoi: number | null;
  seek_timestamp: number | null;
  updated_at: string | null;
};
export type PutStaticImageMapperFixationApiResponse = {
  code: number;
  result: StaticImageMapperFixation;
  status: string;
};
export type DeleteStaticImageMapperFixationApiResponse = {
  code: number;
  result: null;
  status: string;
};
export type RecordingEventUnique = {
  count: number;
  name: string;
};
export type OffsetCorrection = {
  x: number;
  y: number;
};
export type Recording = {
  app_version?: string;
  created_at?: string;
  created_by_user_id: string;
  device_model?: string;
  device_name?: string;
  duration_ns?: number;
  gaze_offset: OffsetCorrection;
  android_gaze_offset: OffsetCorrection;
  gaze_offset_x: number;
  gaze_offset_y: number;
  gazepipeline_status: "queued" | "processing" | "done" | "error" | "n/a";
  has_scanpath?: boolean;
  glasses_id: string;
  id: string;
  is_uploaded?: boolean;
  is_viewable?: boolean;
  is_processed?: boolean;
  label_ids?: string[];
  name?: string;
  preprocessing_error_message?: string;
  recorded_at?: string;
  scene_camera_id: string;
  sensors?: string[];
  size?: number;
  template_data?: {
    [key: string]: string[];
  };
  file_ids?: string[];
  files?: RecordingFile[];
  template_id: string;
  thumbnail_url?: string;
  transcoded_url?: string;
  scene_thumb_url?: string;
  transcoding_status: "queued" | "processing" | "done" | "error" | "n/a";
  trashed_at?: string;
  trashed_by_user_id: string;
  updated_at?: string;
  uploaded_bytes?: number;
  wearer_id: string;
  workspace_id: string;
  family: "invisible" | "neon";
};
export type RecordingsGetResponse = {
  code: number;
  result: Recording[];
  status: string;
};
export type RecordingFile = {
  created_at?: string;
  id: string;
  mimetype?: string;
  name?: string;
  size_bytes?: number;
  started_at?: string;
  uploaded_bytes?: number;
  workspace_id: string;
};
export type RecordingPostResponse = {
  code: number;
  result: Recording;
  status: string;
};
export type RecordingPostRequestFile = {
  created?: string;
  mimetype?: string;
  name?: string;
  size?: number;
};
export type RecordingPostRequest = {
  files?: RecordingPostRequestFile[];
  id: string;
  name?: string;
  template_id: string;
  trashed_at?: string;
  updated_at?: string;
  wearer_id: string;
};
export type DownloadProjectRecordingsObject = {
  recording_id: string;
  exclude_files: string[];
};
export type DownloadProjectEnrichmentsObject = {
  enrichment_id: string;
  exclude_files: string[];
};
export type DownloadProjectPostRequest = {
  recordings: DownloadProjectRecordingsObject[];
  enrichments: DownloadProjectEnrichmentsObject[];
};
export type RecordingsDeleteRequest = {
  recording_ids?: string[];
};
export type RecordingGetResponse = {
  code: number;
  result: Recording;
  status: string;
};
export type RecordingPatchResponse = {
  code: number;
  result: Recording;
  status: string;
};
export type RecordingPatchRequest = {
  gaze_offset_x?: number | null;
  gaze_offset_y?: number | null;
  id: string;
  label_ids?: string[];
  name?: string;
  trashed_at?: string | null;
  updated_at?: string;
  wearer_id?: string;
};
export type Point2D = {
  x?: number;
  y?: number;
};
export type Apriltag = {
  corners?: Apriltag[];
  center?: Point2D;
  decision_margin?: number;
  hamming?: number;
  tag_family?: string;
  tag_id?: number;
};
export type Tags = {
  tags?: Apriltag[];
};
export type ApriltagsAtTimestampGetResponse = {
  code: number;
  result: Tags;
  status: string;
};
export type RecordingEvent = {
  epoch_ns?: number;
  name: string;
  offset_ns?: number;
  created_at: string;
  id: string;
  offset_s: number;
  origin: "recording" | "cloud";
  recording_id: string;
  updated_at: string;
};
export type RecordingEventPostRequest = {
  name: string;
  offset_s: number;
};
export type RecordingEventsGetResponse = {
  code: number;
  result: RecordingEvent[];
  status: string;
};
export type RecordingEventsPostResponse = {
  code: number;
  result: RecordingEvent;
  status: string;
};
export type RecordingEventsPatchResponse = {
  code: number;
  result: RecordingEvent;
  status: string;
};
export type RecordingEventsDeleteResponse = {
  code: number;
  result: null;
  status: string;
};
export type BoundingBox = {
  upper_left?: Point2D;
  lower_right?: Point2D;
};
export type Mouth = {
  left?: Point2D;
  right?: Point2D;
};
export type Eyes = {
  left?: Point2D;
  right?: Point2D;
};
export type Face = {
  bounding_box?: BoundingBox;
  mouth?: Mouth;
  nose?: Point2D;
  eyes?: Eyes;
};
export type FaceDetection = {
  end_timestamp?: number;
  faces?: Face[];
  start_timestamp?: number;
};
export type RecordingFaceDetectionsGetResponse = {
  code: number;
  result: FaceDetection[];
  status: string;
};
export type PointCloud = {
  end_timestamp?: number;
  rotation_x?: number;
  rotation_y?: number;
  rotation_z?: number;
  translation_x?: number;
  translation_y?: number;
  translation_z?: number;
  start_timestamp?: number;
};
export type RecordingFPointCloudGetResponse = {
  code: number;
  result: PointCloud[];
  status: string;
};
export type MarkerLessPointCloud = {
  id: string;
  x: number;
  y: number;
  z: number;
};
export type MarkerLessPointCloudGetResponse = {
  code: number;
  result: MarkerLessPointCloud[];
  status: string;
};
export type RecordingFilesGetResponse = {
  code: number;
  result: RecordingFile[];
  status: string;
};
export type GazeDatapoint = {
  end_timestamp?: number;
  fixation_id?: number;
  start_timestamp?: number;
  worn?: number;
  blink_id?: number;
  x?: number;
  y?: number;
};
export type RecordingGazeGetResponse = {
  code: number;
  result: GazeDatapoint[];
  status: string;
};
export type ScanPathDatapointPath = { id: number; ms: number; x: number; y: number };
export type ScanPathDatapoint = {
  end_timestamp?: number;
  start_timestamp?: number;
  path: Array<ScanPathDatapointPath>;
};
export type RecordingScanPathGetResponse = {
  code: number;
  result: ScanPathDatapoint[];
  status: string;
};
export type Color = {
  alpha?: number;
  blue?: number;
  green?: number;
  red?: number;
};
export type GazeCircle = {
  color?: Color;
  radius?: number;
  stroke_width?: number;
};
export type WorldRender = {
  audio?: boolean;
  gaze_circle?: GazeCircle;
  id: string;
  undistort_frames?: boolean;
  with_gaze?: boolean;
  with_timestamps?: boolean;
  with_scanpath?: boolean;
};
export type RenderPostResponse = {
  code: number;
  result: WorldRender;
  status: string;
};
export type RenderGetResponse = {
  code: number;
  result: WorldRender;
  status: string;
};
export type RenderPatchResponse = {
  code: number;
  result: WorldRender;
  status: string;
};
export type Surface = {
  id: string;
  is_initialized?: boolean;
  markers_used?: string[];
  name?: string;
};
export type SurfacePostResponse = {
  code: number;
  result: Surface;
  status: string;
};
export type SurfaceInitialization = {
  name: string;
};
export type SurfaceGetResponse = {
  code: number;
  result: Surface;
  status: string;
};
export type SurfacePatchResponse = {
  code: number;
  result: Surface;
  status: string;
};
export type PatchSurface = {
  markers?: string[];
};
export type ApriltagResult = {
  distorted_apriltags?: string;
  end_timestamp?: number;
  normalized_apriltags?: Record<string, number[][]>;
  start_timestamp?: number;
};
export type ApriltagDetectionsGetResponse = {
  code: number;
  result: ApriltagResult[];
  status: string;
};
export type OrientationRotation = {
  rotation: number;
};
export type UndistortedSurface = {
  corners?: string;
  marker_ids?: string[];
  ts?: number;
};
export type DistortedSurfaceLocationPostResponse = {
  code: number;
  result: UndistortedSurface;
  status: string;
};
export type SetDistortedSurfaceRequest = {
  corners?: any;
  markers_used: string[];
  recording_id: string;
  timestamp: number;
};
export enum SurfaceCornerNames {
  "BOTTOM_LEFT" = "BOTTOM_LEFT",
  "BOTTOM_RIGHT" = "BOTTOM_RIGHT",
  "TOP_LEFT" = "TOP_LEFT",
  "TOP_RIGHT" = "TOP_RIGHT",
}
export type SurfaceResult = {
  corners?: Record<SurfaceCornerNames, number[]>;
  end_timestamp?: number;
  marker_ids?: string[];
  start_timestamp?: number;
};
export type SurfaceCornersGetResponse = {
  code: number;
  result: SurfaceResult[];
  status: string;
};
export type TemplateItem = {
  choices?: string[];
  help_text?: string;
  id: string;
  input_type?: "any" | "integer" | "float";
  required?: boolean;
  title?: string;
  widget_type:
    | "TEXT"
    | "PARAGRAPH"
    | "RADIO_LIST"
    | "CHECKBOX_LIST"
    | "SECTION_HEADER"
    | "PAGE_BREAK";
};
export type Template = {
  archived_at?: string;
  archived_by_user_id: string;
  created_at?: string;
  created_by_user_id: string;
  id: string;
  is_default_template?: boolean;
  items?: TemplateItem[];
  label_ids?: string[];
  name: string;
  published_at?: string;
  recording_ids?: string[];
  recording_name_format?: string[];
  updated_at?: string;
  description?: string;
};
export type TemplatesGetResponse = {
  code: number;
  result: Template[];
  status: string;
};
export type TemplatePostResponse = {
  code: number;
  result: Template;
  status: string;
};
export type TemplatePostRequest = {
  archived_at?: string;
  created_at?: string;
  id: string;
  items?: TemplateItem[];
  name: string;
  published_at?: string;
  recording_name_format?: string[];
  updated_at?: string;
};
export type TemplatesDeleteRequest = {
  template_ids?: string[];
};
export type TemplateGetResponse = {
  code: number;
  result: Template;
  status: string;
};
export type TemplateDeleteResponse = {
  code: number;
  result: Template;
  status: string;
};
export type TemplatePatchResponse = {
  code: number;
  result: Template;
  status: string;
};
export type TemplatePatchRequest = {
  archived_at?: string | null;
  created_at?: string;
  id: string;
  items?: TemplateItem[];
  label_ids?: string[];
  name?: string;
  description?: string;
  published_at?: string;
  recording_name_format?: string[];
  updated_at?: string;
};
export type Wearer = {
  archived_at?: Date | null;
  archived_by_user_id: string;
  created_at?: Date;
  created_by_user_id: string;
  photo_url: string | null;
  id: string;
  name: string;
  offset_correction?: OffsetCorrection;
  training_updated_on?: string;
  updated_at?: Date | null;
  workspace_id: string;
};
export type WearersGetResponse = {
  code: number;
  result: Wearer[];
  status: string;
};
export type WearerPostResponse = {
  code: number;
  result: Wearer;
  status: string;
};
export type WearerPostRequest = {
  archived_at?: string;
  created_at?: string;
  id: string;
  name: string;
  offset_correction?: OffsetCorrection;
  training_updated_on?: string;
  updated_at?: string;
};
export type WearersDeleteRequest = {
  wearer_ids?: string[];
};
export type WearerGetResponse = {
  code: number;
  result: Wearer;
  status: string;
};
export type WearerDeleteResponse = {
  code: number;
  result: Wearer;
  status: string;
};
export type WearerPatchResponse = {
  code: number;
  result: Wearer;
  status: string;
};
export type WearerPatchRequest = {
  archived_at?: string | null;
  created_at?: string;
  id: string;
  name?: string;
  offset_correction?: OffsetCorrection;
  training_updated_on?: string;
  updated_at?: string;
};
export type ProjectEnrichmentsAoiGetResponse = {
  code: number;
  result: ProjectEnrichmentAoi[];
  status: string;
};
export type FaceEnrichmentLocalizationGetResponse = {
  code: number;
  result: FaceEnrichmentLocalization[];
  status: string;
};
export type SurfaceEnrichmentLocalizationGetResponse = {
  code: number;
  result: SurfaceEnrichmentLocalization[];
  status: string;
};
export type MarkerlessEnrichmentLocalizationGetResponse = {
  code: number;
  result: MarkerlessEnrichmentLocalization[];
  status: string;
};
export type AoiStatsGetResponse = {
  code: number;
  result: { aois: Record<string, AoiStats> };
  status: string;
};
export type ProjectEnrichmentAoiPostResponse = {
  code: number;
  result: ProjectEnrichmentAoi;
  status: string;
};
export type ProjectEnrichmentAoiPostRequest = {
  color: string | null;
  description?: string | null;
  enrichment_id: string;
  id?: string;
  label_ids?: string[];
  mask_image_id?: string;
  mask_image_thumbnail_url?: string;
  mask_image_data_url?: string;
  name: string;
  bounding_box?: object;
  centroid_xy?: object;
};
export type ProjectEnrichmentAoiResourcePatchResponse = {
  code: number;
  result: ProjectEnrichmentAoi;
  status: string;
};
export type ProjectEnrichmentAoiPatchRequest = {
  color?: string | null;
  description?: string | null;
  enrichment_id?: string;
  id: string;
  label_ids?: string[];
  mask_image_id?: string;
  mask_image_thumbnail_url?: string;
  mask_image_data_url?: string;
  name?: string;
};
export interface Calibration {
  camera_matrix: Array<Array<number>>;
  dist_coefs: Array<Array<number>>;
  rotation_matrix: Array<Array<number>>;
  serial_number: string;
  version: string;
}
export type ProjectVisualizationsGetResponse = {
  code: number;
  result: Visualizations[];
  status: string;
};
export type ProjectVisualizationPostResponse = {
  code: number;
  result: Visualizations;
  status: string;
};
export type ProjectVisualizationPatchResponse = {
  code: number;
  result: Visualizations;
  status: string;
};
export type ProjectVisualizationPostRequest = Partial<Visualizations> &
  Pick<Visualizations, "payload" | "name" | "kind" | "enrichment_id">;
export type ProjectVisualizationPatchRequest = Partial<Visualizations> &
  Pick<Visualizations, "payload" | "name" | "kind" | "enrichment_id" | "id">;
export type ProjectStaticImageMapperFixationPutRequest = {
  x: number | null;
  y: number | null;
  gaze_on_aoi: number | null;
};
export enum VisualizationTypes {
  HEATMAP = "heatmap",
  AOI_HEATMAP = "aoi_heatmap",
  RENDER = "render",
}
interface VisualizationBase<Type = VisualizationTypes, Data = object> {
  id: string;
  name: string;
  enrichment_id?: string | null;
  project_id?: string | null;
  kind: Type;
  payload: Data;
  created_at?: string;
  updated_at?: string;
}
export interface VisualizationDataHeatmap {
  color_map: GenerateHeatmap["colormap"];
  scale: number;
  recording_ids: string[];
}
export interface VisualizationDataAoi {
  color_map: GenerateHeatmap["colormap"];
  recording_ids: string[];
  aoi_ids: string[];
  show_names: boolean;
  show_metric_values: boolean;
  metrics: AOI_HEATMAP_TYPES;
}
export type Visualizations =
  | VisualizationBase<VisualizationTypes.HEATMAP, VisualizationDataHeatmap>
  | VisualizationBase<VisualizationTypes.AOI_HEATMAP, VisualizationDataAoi>
  | VisualizationBase<VisualizationTypes.RENDER, ProjectEnrichment>;
export interface StaticImageMapper {
  id: string;
  project_id: string;
  created_at: string;
  updated_at: string;
  name: string;
  kind: EnrichmentTypesEnum.STATIC_IMAGE_MAPPER;
  from_event_name: string;
  to_event_name: string;
  static_image_id: string | null;
  static_image_image_thumbnail_url: string | null;
  status?: Record<ProjectEnrichmentStatusType, number>;
  created_by_user_id: string;
  deleted_by_user_id: string | null;
  workspace_id: string;
  slices: ProjectEnrichmentSlice[];
  fixations_status: Array<ManualMapperFixationStatus>;
}
export interface ManualMapperFixationStatus {
  enrichment_id: string;
  recording_id: string;
  checked_fixations: number;
  total_fixations: number;
}
