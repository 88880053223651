import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_OPEN_HEIGHT } from "@storeRematch/videoEvents";
import { debounce } from "debounce";
import { memo, useEffect, useMemo, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";

export const VideoEventsFilmstrip = memo(() => {
  return (
    <Box
      id="FilmStrip_root"
      sx={{
        pointerEvents: "none",
        opacity: 1,
        position: "absolute",
        width: "100%",
        display: "flex",
        height: DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_OPEN_HEIGHT,
      }}
    >
      <List />
    </Box>
  );
});

const List = () => {
  const dispatch = useAppDispatch();
  const itemCount = useAppSelector(state => state.filmstrip.count);
  const zoomWidth = useAppSelector(state => state.video.zoom.css.width);
  const fullScreen = useAppSelector(state => state.video.fullScreen);
  const dragger = useAppSelector(
    state => state.dragger.table.size + state.dragger.tableProject.size,
  );

  const debounced = useMemo(() => {
    return debounce(dispatch.filmstrip.recalculate, 500);
  }, [dispatch]);

  useEffect(() => {
    dispatch.filmstrip.setHide(true);
    debounced(true);
  }, [fullScreen, zoomWidth, dragger, debounced, dispatch]);

  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <FixedSizeList
            ref={dispatch.filmstrip.setListRef}
            style={{ overflow: "hidden" }}
            layout="horizontal"
            overscanCount={5}
            height={height}
            width={width}
            itemCount={itemCount}
            itemSize={DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_OPEN_HEIGHT}
          >
            {Item}
          </FixedSizeList>
        );
      }}
    </AutoSizer>
  );
};

const Item = ({ index, style }: { index: number; style: any }) => {
  return (
    <div style={style}>
      <Video index={index} />
    </div>
  );
};

const Video = memo(({ index }: { index: number }) => {
  const hide = useAppSelector(
    state => state.video.hideVideoPlayer || state.filmstrip.hide,
  );
  const ref = useRef<HTMLCanvasElement | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ref.current) {
      dispatch.filmstrip.addToQuery(index);
      dispatch.filmstrip.addToImages({
        index,
        data: { canvas: ref.current, done: false },
      });
      dispatch.filmstrip.startNext(null);
    }

    return () => {
      dispatch.filmstrip.removeFromQuery(index);
      dispatch.filmstrip.removeFromImages(index);
    };
  }, [index, dispatch]);

  return (
    <canvas
      data-id={`canvas-${index}`}
      ref={ref}
      width={DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_OPEN_HEIGHT}
      height={DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_OPEN_HEIGHT}
      style={{ opacity: hide ? 0 : 1 }}
    />
  );
});
