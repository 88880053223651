import { Loader } from "@components/Loader";
import { SubmitButton, TextField } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import { auth } from "@services/firebase";
import {
  EmailPasswordCredentials,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import { emailSchema } from "@utils";
import { sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    email: emailSchema,
  })
  .required();

export const SendResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const [done, setDone] = useState("");
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(
    state => state.app.authLoading || state.loading.effects.app.finalizeAuth.loading,
  );

  const methods = useForm<EmailPasswordCredentials>({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const handleSubmit = async ({ email }: { email: string }) => {
    try {
      await sendPasswordResetEmail(auth, email);
      setDone(email);
    } catch (error) {
      dispatch.notifier.enqueueSnackbar({
        message: t(`Unable to send the email`),
        options: {
          variant: "error",
        },
      });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (done) {
    return (
      <>
        <Typography variant="body2" color="text.secondary">
          {t(`An email with password reset instructions have been sent to ${done}.`)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {t(
            `Click on the link and follow the instructions to reset your password. Check the spam folder if the email does not appear within a few minutes.`,
          )}
        </Typography>
        <Box>
          <Typography variant="body2" color="text.secondary" component="span" mr={1}>
            {t(`Still need help?`)}
          </Typography>
          <Typography variant="body1" component="span">
            <Link href={"/signup"}>{t(`Contact us`)}</Link>
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <Typography variant="body2" color="text.secondary">
        {t(
          `Enter your account email. Google will send you an email with a link to reset your password.`,
        )}
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)} noValidate>
          <Stack spacing={2}>
            <TextField
              autoFocus
              name="email"
              autoComplete="email"
              fullWidth
              label="Email"
              required
            />

            <SubmitButton fullWidth>{t("Send")}</SubmitButton>
          </Stack>
        </form>
      </FormProvider>

      <Divider />

      <Box>
        <Typography variant="body2" color="text.secondary" component="span" mr={1}>
          {t(`Still need help?`)}
        </Typography>
        <Typography variant="body1" component="span" color="primary.main">
          <Link href={"/signup"}>{t(`Contact us`)}</Link>
        </Typography>
      </Box>
    </>
  );
};
