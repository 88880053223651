import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, TextField as MuiTextField, TextFieldProps } from "@mui/material";
import { FC, useState } from "react";
import { useFormContext, useFormState } from "react-hook-form";

export const PasswordTextField: FC<TextFieldProps & { name: string }> = ({
  name,
  ...props
}) => {
  const methods = useFormContext();
  const { errors } = useFormState();
  const [show, setShow] = useState(false);

  return (
    <MuiTextField
      {...props}
      {...methods.register(name)}
      error={!!errors[name]}
      helperText={errors[name]?.message as string}
      type={show ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <IconButton size="small" onClick={() => setShow(!show)} sx={{ mb: 0.6 }}>
            {show ? (
              <VisibilityOff fontSize="small" />
            ) : (
              <Visibility fontSize="small" />
            )}
          </IconButton>
        ),
      }}
    />
  );
};
