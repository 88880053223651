import { VideoBackgroundMove } from "@customIcons";
import { IconButton, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { useTranslation } from "react-i18next";

export const VideoMoveBackgroundButton = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(state =>
    state.video.hideVideoPlayer
      ? true
      : document.URL.includes("projects")
      ? state.projectEdit.recordings.length === 0
      : state.recordings.data.length === 0,
  );
  const videoEventsBackgroundMove = useAppSelector(
    state => state.video.videoEventsBackgroundMove,
  );

  return (
    <Tooltip
      title={t(`Timeline: Follow playhead ${videoEventsBackgroundMove ? "ON" : "OFF"}`)}
    >
      <span>
        <IconButton
          onClick={dispatch.video.toggleVideoEventsBackgroundMove}
          disabled={disabled}
          sx={{
            maxWidth: 37,
            maxHeight: 37,
            width: "100%",
          }}
          color={videoEventsBackgroundMove ? "primary" : undefined}
        >
          <VideoBackgroundMove />
        </IconButton>
      </span>
    </Tooltip>
  );
};
