import {
  api,
  EnrichmentTypesEnum,
  Markerless,
  ProjectEnrichment,
  ProjectEnrichmentArgs,
  ProjectEnrichmentSlice,
  Recording,
  RecordingFile,
  Surface,
  WorldRender,
} from "@api";
import { MyRGB } from "@components";
import {
  aprilTagOverlay,
  gazeOverlay,
  overlayControl,
  scanPathOverlay,
  surfaceCornersOverlay,
} from "@components/videoPlayer/controller";
import { defaultRenderConfig } from "@constants";
import { createModel } from "@rematch/core";
import { isEnrichmentFormDisabled } from "@utils";
import i18n from "../i18n";

import type { RecordingWithData, RootModel } from "./index";

let savedGazeOverlayHide: boolean | null = null;

interface InitialState {
  recordings: RecordingWithData[];
  currentImage?: RecordingFile;
  currentMarkerLess?: Markerless;
  currentEnrichment?: ProjectEnrichment;
  currentSurface?: Surface;
  isSurfaceDetected: boolean;
  isSelectedAprilTagVisible: boolean;
  currentRecordingIdToLoad?: string;
  currentMarkerLessRecording?: Recording;
  currentRender?: WorldRender;
  form: {
    name: string;
    kind: ProjectEnrichment["kind"];
    from_event_name: string;
    to_event_name: string;
    imageName: string;
    imageId: string;
    recordingName: string;
    recordingId: string;
    gazeColor: MyRGB;
    gazeCircle: {
      radius: number;
      stroke: number;
    };
    undistorted?: boolean;
    withGaze?: boolean;
    withScanPath?: boolean;
  };
  temporalSelectionHover: {
    from: string | null;
    to: string | null;
  };
  hideGaze: boolean;
  hideFixation: boolean;
  hideBlinks: boolean;
  markerMapperImgUrl: string | null;
}

const initialState: InitialState = {
  recordings: [],
  currentImage: undefined,
  currentMarkerLess: undefined,
  currentEnrichment: undefined,
  currentSurface: undefined,
  currentRecordingIdToLoad: undefined,
  currentMarkerLessRecording: undefined,
  currentRender: undefined,
  isSurfaceDetected: false,
  isSelectedAprilTagVisible: false,
  form: {
    name: "",
    kind: EnrichmentTypesEnum.SLAM_MAPPER,
    from_event_name: "recording.begin",
    to_event_name: "recording.end",
    imageName: "",
    imageId: "",
    recordingName: "",
    recordingId: "",
    gazeColor: {
      r: 0,
      g: 0,
      b: 0,
      a: 0,
    },
    gazeCircle: {
      radius: 0,
      stroke: 0,
    },
    undistorted: false,
    withGaze: false,
    withScanPath: false,
  },
  temporalSelectionHover: {
    from: null,
    to: null,
  },
  hideGaze: false,
  hideFixation: false,
  hideBlinks: false,
  markerMapperImgUrl: null,
};

export const projectEdit = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setRecordings(state, data: InitialState["recordings"]) {
      state.recordings = data;
    },
    setCurrentImage(state, data: InitialState["currentImage"]) {
      state.currentImage = data;
    },
    setCurrentMarkerLess(state, data: InitialState["currentMarkerLess"]) {
      state.currentMarkerLess = data;
    },
    setCurrentEnrichmentReducer(state, data?: InitialState["currentEnrichment"]) {
      state.currentEnrichment = data;
    },
    setCurrentSurface(state, data?: InitialState["currentSurface"]) {
      state.currentSurface = data;
    },
    setIsSurfaceDetected(state, data: InitialState["isSurfaceDetected"]) {
      state.isSurfaceDetected = data;
    },
    setIsSelectedAprilTagVisible(
      state,
      data: InitialState["isSelectedAprilTagVisible"],
    ) {
      if (state.isSelectedAprilTagVisible !== data) {
        state.isSelectedAprilTagVisible = data;
      }
    },
    setCurrentRecordingIdToLoad(state, data: InitialState["currentRecordingIdToLoad"]) {
      state.currentRecordingIdToLoad = data;
    },
    setCurrentMarkerLessRecording(
      state,
      data: InitialState["currentMarkerLessRecording"],
    ) {
      state.currentMarkerLessRecording = data;
    },
    setCurrentRender(state, data: InitialState["currentRender"]) {
      state.currentRender = data;
    },
    setForm(state, data: Partial<InitialState["form"]>) {
      state.form = { ...state.form, ...data };
    },
    setTemporalSelectionHover(
      state,
      data: Partial<InitialState["temporalSelectionHover"]>,
    ) {
      state.temporalSelectionHover = { ...state.temporalSelectionHover, ...data };
    },
    setHideGaze(state, data: InitialState["hideGaze"]) {
      state.hideGaze = data;
    },
    setHideFixation(state, data: InitialState["hideFixation"]) {
      state.hideFixation = data;
    },
    setHideBlinks(state, data: InitialState["hideBlinks"]) {
      state.hideBlinks = data;
    },
    addSlice(state, data: ProjectEnrichmentSlice) {
      if (
        state.currentEnrichment &&
        state.currentEnrichment.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER
      ) {
        const index = state.currentEnrichment.slices.findIndex(
          a => (a as ProjectEnrichmentSlice).id === data.id,
        );

        if (index >= 0) {
          (state.currentEnrichment.slices as ProjectEnrichmentSlice[]).splice(
            index,
            0,
            data,
          );
        } else {
          (state.currentEnrichment.slices as ProjectEnrichmentSlice[]).push(data);
        }
      }
    },
    setMarkerMapperImgUrl(state, data: InitialState["markerMapperImgUrl"]) {
      state.markerMapperImgUrl = data;
    },
    reset(state) {
      gazeOverlay.addConfig();

      return {
        ...initialState,
        recordings: state.recordings,
        hideGaze: state.hideGaze,
        hideFixation: state.hideFixation,
        currentRecordingIdToLoad: state.currentRecordingIdToLoad,
      };
    },
  },
  effects: dispatch => ({
    async get(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const project = state.app.currentProject;

      if (!project || !workspaceId) return;

      dispatch.projectEdit.prepare(null);

      try {
        await dispatch.projectEvents.get(null);
        await dispatch.enrichments.get({ workspaceId, projectId: project.id });
      } catch (error) {
        console.error(error);
      }
    },
    prepare(_, state) {
      const project = state.app.currentProject;
      const recordingsById = state.recordings.tableDataById;

      if (!project) return;

      if (!project.recording_ids || !project.recording_ids.length) {
        dispatch.projectEdit.setRecordings([]);
        dispatch.video.setHideVideoPlayer(true);

        return;
      }

      dispatch.projectEdit.setRecordings(
        project.recording_ids.reduce<InitialState["recordings"]>((acc, id) => {
          const recording = recordingsById.get(id);
          if (recording && !recording.recording.trashed_at) {
            acc.push(recording);
          }

          return acc;
        }, []),
      );
      dispatch.video.setHideVideoPlayer(false);
    },
    async removeFromProject(_, state) {
      const project = state.app.currentProject;

      if (!project) return;

      const ids = await dispatch.projects.removeRecordingsFromProject(project);

      if (!ids || !ids.length) {
        dispatch.entityTable.setSelected([]);
        dispatch.projectEdit.setRecordings([]);
        return;
      }

      dispatch.projectEdit.setRecordings(
        ids.reduce<InitialState["recordings"]>((acc, id) => {
          const recording = state.recordings.tableDataById.get(id);

          if (recording) {
            acc.push(recording);
          }

          return acc;
        }, []),
      );

      dispatch.entityTable.setSelected([ids[0]]);
    },
    async createMarkerLess(_, state): Promise<Markerless | void> {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const currentImage = state.projectEdit.currentImage;
      const recordingId = state.projectEdit.form.recordingId;

      if (!workspaceId || !currentImage || !recordingId) return;

      try {
        const res = await api.postMarkerless({
          workspaceId,
          markerlessInitialization: {
            reference_image_id: currentImage.id,
            scanning_recording_id: recordingId,
          },
        });

        dispatch.projectEdit.setCurrentMarkerLess(res.result as any);

        return res.result;
      } catch (error) {
        console.error(error);
      }
    },
    async getMarkerLess(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const model = state.projectEdit.currentEnrichment;
      const currentMarkerLessId = state.projectEdit.currentMarkerLess?.id;

      if (currentMarkerLessId === (model?.args?.markerless_id as any)) return;

      if (
        !workspaceId ||
        !model ||
        model.kind !== EnrichmentTypesEnum.SLAM_MAPPER ||
        !model.args ||
        !model.args.markerless_id
      ) {
        dispatch.projectEdit.setCurrentMarkerLess(undefined);
        dispatch.projectEdit.setCurrentMarkerLessRecording(undefined);
        return;
      }

      try {
        const res = await api.getMarkerless({
          workspaceId,
          markerlessId: model.args.markerless_id as unknown as string,
        });

        dispatch.projectEdit.setCurrentMarkerLess(res.result);
        dispatch.projectEdit.setCurrentMarkerLessRecording(
          state.recordings.dataById.get(res.result.scanning_recording_id),
        );

        if (
          state.projectEdit.currentEnrichment?.kind ===
            EnrichmentTypesEnum.SLAM_MAPPER &&
          model.status?.SUCCESS === 1
        ) {
          await dispatch.video.getMarkerLessPointCloud(null);
        }

        dispatch.video.getNextDataChunk({});
      } catch (error) {
        console.error(error);
      }
    },
    setCurrentEnrichment(data: InitialState["currentEnrichment"], state) {
      if (
        (data &&
          state.projectEdit.currentEnrichment &&
          data.id !== state.projectEdit.currentEnrichment.id) ||
        !state.projectEdit.currentEnrichment
      ) {
        overlayControl.reset();
        dispatch.staticImageMappers.reset();

        if (data?.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER) {
          if (savedGazeOverlayHide === null) savedGazeOverlayHide = gazeOverlay.hide;
          gazeOverlay.hide = true;
        } else if (savedGazeOverlayHide !== null) {
          gazeOverlay.hide = savedGazeOverlayHide;
          savedGazeOverlayHide = null;
        }
      }

      dispatch.projectEdit.setCurrentEnrichmentReducer(data);

      if (data?.args?.surface_id) {
        dispatch.video.getSurface(null);
      }

      dispatch.localization.get(null);

      if (data) {
        dispatch.videoEvents.calculateEnrichmentEvents(data);
      }

      aprilTagOverlay.availableTags = {};
      dispatch.video.getNextDataChunk({});
      dispatch.video.prepareRecordingSlices(null);
    },
    async createEnrichment(
      data: ProjectEnrichment,
      state,
    ): Promise<void | ProjectEnrichment> {
      const isVisualizations = document.URL.includes("/visualizations");
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;

      if (!workspaceId || !projectId) return;

      try {
        const args: ProjectEnrichmentArgs = {};

        if (data.kind === EnrichmentTypesEnum.RENDER) {
          const renderDefinition = await api.postRender({
            workspaceId,
            worldRender: defaultRenderConfig,
          });

          if (typeof renderDefinition === "string") return;

          args.render_definition_id = renderDefinition.result.id;
        }

        if (data.kind === EnrichmentTypesEnum.SLAM_MAPPER) {
          args.markerless_id = "";
        }

        if (data.kind === EnrichmentTypesEnum.MARKER_MAPPER) {
          const surface = await api.postSurface({
            workspaceId,
            surfaceInitialization: { name: data.name },
          });

          if (typeof surface === "string") return;

          args.surface_id = surface.result.id;
        }

        const enrichmentRes = await api.postProjectEnrichment({
          workspaceId,
          projectId,
          projectEnrichmentPostRequest: { ...data, args },
        });

        if (typeof enrichmentRes === "string") return;

        dispatch.notifier.enqueueSnackbar({
          message: i18n
            .t(`${isVisualizations ? "Visualization" : "Enrichment"} created`)
            .toString(),
          options: { variant: "success" },
        });

        return enrichmentRes.result;
      } catch (error) {
        console.error(error);
      }
    },
    async updateEnrichment(_, state) {
      const isVisualizations = document.URL.includes("/visualizations");
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichment = state.projectEdit.currentEnrichment;
      const form = state.projectEdit.form;

      if (!workspaceId || !projectId || !enrichment) return;

      try {
        dispatch.notifier.enqueueSnackbar({
          message: i18n
            .t(`${isVisualizations ? "Visualization" : "Enrichment"} updated`)
            .toString(),
          options: { key: "enrichment_update_success", variant: "success" },
        });

        if (enrichment.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER) {
          await api.patchStaticImageMapper({
            workspaceId,
            projectId,
            enrichmentId: enrichment.id,
            projectStaticImageMapperPatchRequest: {
              name: form.name,
              from_event_name: form.from_event_name,
              to_event_name: form.to_event_name,
            },
          });
        } else {
          await api.patchProjectEnrichmentResource({
            workspaceId,
            projectId,
            enrichmentId: enrichment.id,
            projectEnrichmentPatchRequest: {
              id: enrichment.id,
              project_id: enrichment.project_id,
              name: form.name,
              from_event_name: form.from_event_name,
              to_event_name: form.to_event_name,
            },
          });
        }

        if (
          enrichment.from_event_name !== form.from_event_name ||
          enrichment.to_event_name !== form.to_event_name
        ) {
          dispatch.staticImageMappers.getFixations(null);
          dispatch.video.prepareRecordingSlices(null);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async deleteEnrichment(ids: string[] | null, state) {
      const isVisualizations = document.URL.includes("/visualizations");
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichment = state.projectEdit.currentEnrichment;
      const checkedIds = ids ? ids : enrichment ? [enrichment.id] : null;

      if (!workspaceId || !projectId || !checkedIds) return;

      try {
        await Promise.all(
          checkedIds.map(id =>
            api.deleteProjectEnrichmentResource({
              workspaceId,
              projectId,
              enrichmentId: id,
            }),
          ),
        );

        dispatch.notifier.enqueueSnackbar({
          message: i18n
            .t(`${isVisualizations ? "Visualization" : "Enrichment"} deleted`)
            .toString(),
          options: { variant: "success" },
        });

        return true;
      } catch (error) {
        console.error(error);

        return false;
      }
    },
    async runEnrichment(_, state) {
      const isVisualizations = document.URL.includes("/visualizations");
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichment = state.projectEdit.currentEnrichment;

      if (!workspaceId || !projectId || !enrichment) return;

      try {
        dispatch.projectEdit.setCurrentEnrichmentReducer({
          ...enrichment,
          status: enrichment.status
            ? { ...enrichment.status, COMPUTING: 0.1 }
            : { COMPUTING: 0.1, SUCCESS: 0, ERROR: 0, READY: 0, STALE: 0 },
        });

        await api.postProjectEnrichmentComputeResource({
          workspaceId,
          projectId,
          enrichmentId: enrichment.id,
        });

        const res = await api.getProjectEnrichment({
          workspaceId,
          projectId,
          enrichmentId: enrichment.id,
        });

        dispatch.projectEdit.setCurrentEnrichment(res.result);
        dispatch.enrichments.upsertOne(res.result);

        dispatch.notifier.enqueueSnackbar({
          message: i18n
            .t(`${isVisualizations ? "Visualization" : "Enrichment"} running`)
            .toString(),
          options: { variant: "success" },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async stopRunningEnrichment(_, state) {
      const isVisualizations = document.URL.includes("/visualizations");
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichmentId = state.projectEdit.currentEnrichment?.id;

      if (!workspaceId || !projectId || !enrichmentId) return;

      try {
        api.postProjectEnrichmentCancelResource({
          workspaceId,
          projectId,
          enrichmentId,
        });

        dispatch.notifier.enqueueSnackbar({
          message: i18n
            .t(`${isVisualizations ? "Visualization" : "Enrichment"} canceled`)
            .toString(),
          options: { variant: "success" },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async defineSurface(_, state) {
      const surface = state.projectEdit.currentSurface;
      const instance = state.video.instance;

      if (!surface || !instance) return;

      dispatch.projectEdit.setCurrentSurface({ ...surface, is_initialized: true });
      dispatch.video.setDistortedLocationUsingRecording(instance.currentTime());
    },
    async resetSurface(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const surface = state.projectEdit.currentSurface;
      const instance = state.video.instance;

      if (!workspaceId || !surface || !instance) return;

      dispatch.projectEdit.setCurrentSurface({ ...surface, is_initialized: false });
      api.patchSurface({
        workspaceId,
        surfaceId: surface.id,
        patchSurface: { markers: [] },
      });

      dispatch.projectEdit.setMarkerMapperImgUrl(null);

      const event = aprilTagOverlay.getCurrentEvent();
      const tags = Object.keys(event?.normalized_apriltags || []);

      if (tags.length) {
        aprilTagOverlay.availableTags = tags.reduce<Record<string, boolean>>(
          (acc, k) => {
            acc[k] = true;
            return acc;
          },
          {},
        );
        overlayControl.shouldRender(aprilTagOverlay);
      }

      surfaceCornersOverlay.setEvents([]);
    },
    async rotateSurface(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const surface = state.projectEdit.currentSurface;
      const instance = state.video.instance;
      const recording = state.video.recording;
      const enrichmentId = state.projectEdit.currentEnrichment?.id;
      const projectId = state.app.currentProject?.id;

      if (
        !workspaceId ||
        !surface ||
        !instance ||
        !enrichmentId ||
        !recording ||
        !projectId
      )
        return;

      try {
        await api.patchRotateSurfaceOrientation({
          workspaceId,
          surfaceId: surface.id,
          orientationRotation: { rotation: 90 },
        });

        const start = instance.currentTime();
        const format = `?start=${start}&detect=1&project_id=${projectId}&enrichment_id=${enrichmentId}&recording_id=${recording.id}`;

        const res = await api.getSurfaceCorners({
          workspaceId,
          surfaceId: surface.id,
          format,
        });

        if (res.result.length) surfaceCornersOverlay.setEvents(res.result);
        else surfaceCornersOverlay.setEvents([]);

        overlayControl.shouldRender(surfaceCornersOverlay);
      } catch (error) {
        console.error(error);
      }
    },
    async tryUpdateRender(_, state) {
      if (state.projectEdit.currentEnrichment?.kind !== "render") return;
      if (isEnrichmentFormDisabled(state.projectEdit.currentEnrichment)) return;

      dispatch.projectEdit.setForm({
        withGaze: !gazeOverlay.hide,
        withScanPath: !scanPathOverlay.hide,
      });
      dispatch.projectEdit.updateRender(null);
    },
    async updateRender(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichment = state.projectEdit.currentEnrichment;
      const gazeCircle = state.projectEdit.form.gazeCircle;
      const gazeColor = state.projectEdit.form.gazeColor;
      const undistorted = state.projectEdit.form.undistorted;
      const withGaze = state.projectEdit.form.withGaze;
      const withScanPath = state.projectEdit.form.withScanPath;

      if (
        !workspaceId ||
        !projectId ||
        !enrichment ||
        !enrichment.args?.render_definition_id
      )
        return;

      try {
        const defaultConfig = {
          ...state.projectEdit.currentRender,
          gaze_circle: {
            color: {
              red: gazeColor?.r,
              green: gazeColor?.g,
              blue: gazeColor?.b,
              alpha: gazeColor?.a,
            },
            radius: gazeCircle.radius,
            stroke_width: gazeCircle.stroke,
          },
          undistort_frames: undistorted,
          with_gaze: withGaze,
          with_scanpath: withScanPath,
        } as WorldRender;

        const renderDefinition = await api.patchRender({
          workspaceId,
          renderDefinitionId: enrichment.args
            ?.render_definition_id as unknown as string,
          worldRender: defaultConfig,
        });

        if (renderDefinition.result.gaze_circle) {
          gazeOverlay.addConfig(renderDefinition.result.gaze_circle);
        }

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Video renderer updated").toString(),
          options: { variant: "success" },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async updateMarkerless(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichment = state.projectEdit.currentEnrichment;

      if (
        !workspaceId ||
        !projectId ||
        !enrichment ||
        enrichment.kind !== EnrichmentTypesEnum.SLAM_MAPPER
      )
        return;

      try {
        const markerless = await dispatch.projectEdit.createMarkerLess(null);

        if (!markerless) return;

        const updatedEnrichment = await api.patchProjectEnrichmentResource({
          workspaceId,
          projectId,
          enrichmentId: enrichment.id,
          projectEnrichmentPatchRequest: {
            args: { markerless_id: markerless.id } as any,
            id: enrichment.id,
            project_id: enrichment.project_id,
          },
        });

        dispatch.projectEdit.setCurrentEnrichment(updatedEnrichment.result);
      } catch (error) {
        console.error(error);
      }
    },
    async deleteVisualizations(ids: string[], state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;

      if (!workspaceId || !projectId) return;

      try {
        await Promise.all(
          ids.map(id =>
            api.deleteProjectEnrichmentResource({
              workspaceId,
              projectId,
              enrichmentId: id,
            }),
          ),
        );

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Visualization deleted").toString(),
          options: { variant: "success" },
        });

        return true;
      } catch (error) {
        console.error(error);

        return false;
      }
    },
    async getMarkerMapperImgUrl(model: ProjectEnrichment | undefined, state) {
      if (
        model?.kind !== EnrichmentTypesEnum.MARKER_MAPPER ||
        !model.status?.SUCCESS ||
        !model.slices.find(s => s.status === "SUCCESS")
      ) {
        dispatch.projectEdit.setMarkerMapperImgUrl(null);
        return;
      }

      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const isInitialized = state.projectEdit.currentSurface?.is_initialized;

      if (!workspaceId || !projectId || !isInitialized) return;

      try {
        const url = await api.getHeatmapBackground(
          {
            enrichment_id: model.id,
            project_id: projectId,
            workspaceId,
          },
          false,
          true,
        );

        dispatch.projectEdit.setMarkerMapperImgUrl(url);
      } catch (error) {
        console.error(error);
      }
    },
  }),
});
