import { EnrichmentTypes } from "@api";

export type EnrichmentVisualizationNames = EnrichmentTypes | "heatmap" | "aoi";

export function formatReadableEnrichment(kind?: EnrichmentVisualizationNames) {
  switch (kind) {
    case "face-mapper":
      return "Face Mapper";
    case "marker-mapper":
      return "Marker Mapper";
    case "raw-data-export":
      return "Timeseries CSV and Scene Video";
    case "render":
      return "Video Renderer";
    case "heatmap":
      return "Heatmap";
    case "aoi":
      return "AOI Heatmap";
    case "slam-mapper":
      return "Reference Image Mapper";
    case "static-image-mapper":
      return "Manual Mapper";
    default:
      console.error("formatReadableEnrichment unknown type", kind);
      return "Unknown type";
  }
}

export function formatReadableEnrichmentEyeIconTooltip(
  kind?: EnrichmentVisualizationNames,
) {
  switch (kind) {
    case "face-mapper":
      return "face bounding boxes";
    case "marker-mapper":
      return "surface and markers";
    case "render":
      return "gaze overlay";
    case "slam-mapper":
      return "point cloud";
    default:
      return "Unknown type";
  }
}

export interface SelectEnrichmentProps {
  value: EnrichmentVisualizationNames;
  label: string;
  link?: string;
  video?: string;
}

export const SELECT_ENRICHMENT: SelectEnrichmentProps[] = [
  {
    value: "slam-mapper",
    label: formatReadableEnrichment("slam-mapper"),
    link: "https://docs.pupil-labs.com/neon/pupil-cloud/enrichments/reference-image-mapper/",
  },
  {
    value: "marker-mapper",
    label: formatReadableEnrichment("marker-mapper"),
    link: "https://docs.pupil-labs.com/neon/pupil-cloud/enrichments/marker-mapper/",
  },
  {
    value: "face-mapper",
    label: formatReadableEnrichment("face-mapper"),
    link: "https://docs.pupil-labs.com/neon/pupil-cloud/enrichments/face-mapper/",
  },
  {
    value: "static-image-mapper",
    label: formatReadableEnrichment("static-image-mapper"),
    link: "https://docs.pupil-labs.com/neon/pupil-cloud/enrichments/manual-mapper/",
    video: "https://assets.pupil-labs.com/cloud/manual-mapper-animation.mp4",
  },
];

export const SELECT_VISUALIZATION: SelectEnrichmentProps[] = [
  {
    value: "render",
    label: formatReadableEnrichment("render"),
    link: "https://docs.pupil-labs.com/neon/pupil-cloud/visualizations/video-renderer/",
  },
  {
    value: "heatmap",
    label: formatReadableEnrichment("heatmap"),
    link: "https://docs.pupil-labs.com/neon/pupil-cloud/visualizations/heatmap/",
  },
  {
    value: "aoi",
    label: formatReadableEnrichment("aoi"),

    link: "https://docs.pupil-labs.com/neon/pupil-cloud/visualizations/areas-of-interest/",
  },
];

const DEFAULT_KIND_ENRICHMENT: EnrichmentTypes = "slam-mapper";
export const DEFAULT_ENRICHMENT: SelectEnrichmentProps | undefined =
  SELECT_ENRICHMENT.find(el => el.value === DEFAULT_KIND_ENRICHMENT);

const DEFAULT_KIND_VISUALIZATION: EnrichmentTypes = "render";
export const DEFAULT_VISUALIZATION: SelectEnrichmentProps | undefined =
  SELECT_VISUALIZATION.find(el => el.value === DEFAULT_KIND_VISUALIZATION);
