import { Divider, Paper } from "@mui/material";
import { styled } from "@mui/system";

const MAX_WIDTH = 780;

export const TemplateItemDragRoot = styled("div")(() => {
  return {
    maxWidth: MAX_WIDTH,
    width: "100%",
    margin: "auto",
  };
});

export const TemplateItemBase = styled(Paper)(({ theme }) => {
  return {
    maxWidth: MAX_WIDTH,
    width: "100%",
    margin: "auto",
    marginBottom: theme.spacing(3),
    position: "relative",
  };
});
TemplateItemBase.defaultProps = {
  elevation: 2,
  className: "templateItemBaseActionsHover templateItemMoveHover",
};

export const TemplateItemBaseActions = styled("div")(() => {
  return {
    position: "absolute",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    opacity: 0,
    //`right` and `width` are related
    right: "-70px",
    width: "70px",
    padding: "0px 10px",

    ".templateItemBaseActionsHover:focus-within &, .templateItemBaseActionsHover:hover &, &:hover":
      {
        opacity: 1,
      },
  };
});

export const TemplateItemTitle = styled("div")<{
  divider?: boolean;
}>(({ theme, divider }) => {
  return {
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(1.5)}`,
    marginBottom: theme.spacing(divider ? 3 : 1.5),

    borderBottom: divider ? `1px solid ${theme.palette.customColors.light2}` : "none",
  };
});

export const TemplateItemBody = styled("div")(({ theme }) => {
  return {
    padding: `0px ${theme.spacing(2)} ${theme.spacing(1.5)}`,
    marginBottom: theme.spacing(2),
  };
});

export const TemplateFormDivider = styled(Divider)(({ theme }) => {
  return {
    maxWidth: MAX_WIDTH,
    width: "100%",
    margin: `${theme.spacing(2)} auto`,
  };
});
