import { EnrichmentTypesEnum, ProjectEnrichment, ProjectEnrichmentSlice } from "@api";
import { useAppSelector } from "@storeRematch";
import { DisableByPermissionsType, disabledByPermission } from "./disabledByPermission";

export function isErrorAvailable(model?: ProjectEnrichment): boolean {
  if (!model) return false;

  return !!model.status?.ERROR;
}

export function isRunningAvailable(model?: ProjectEnrichment): boolean {
  if (!model) return false;

  return !(model.status?.COMPUTING !== undefined && model.status?.COMPUTING > 0);
}

export function useIsRunDisabledCheck(m?: ProjectEnrichment): boolean {
  const model = useAppSelector(state => state.projectEdit.currentEnrichment);
  const surfaceInitialized = useAppSelector(
    state => state.projectEdit.currentSurface?.is_initialized,
  );
  const hasRecordingAndImage = useAppSelector(
    state => !!state.projectEdit.form.recordingId && !!state.projectEdit.form.imageId,
  );
  const isDisabledByPermissions = disabledByPermission({
    type: DisableByPermissionsType.EDIT,
  });

  if (isDisabledByPermissions) return true;

  if (m) {
    return m.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER &&
      !m.args?.static_image_id
      ? true
      : m.kind === EnrichmentTypesEnum.MARKER_MAPPER && !m.args?.surface_id
      ? true
      : m.kind === EnrichmentTypesEnum.SLAM_MAPPER && !m.args?.markerless_id
      ? true
      : false;
  }

  return model?.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER &&
    !model?.args?.static_image_id
    ? true
    : model?.kind === EnrichmentTypesEnum.MARKER_MAPPER
    ? surfaceInitialized !== true
    : model?.kind === EnrichmentTypesEnum.SLAM_MAPPER
    ? !hasRecordingAndImage
    : false;
}

export function isEnrichmentFormDisabled(model?: ProjectEnrichment): boolean {
  if (!model || !model.status) return true;

  return (
    model.status?.COMPUTING > 0 || model.status?.SUCCESS > 0 || model.status?.ERROR > 0
  );
}

const DEFAULT_ERROR = "Please contact info+cloud-support@pupil-labs.com for support.";
export function useGetFullMessage(model?: ProjectEnrichment) {
  const disabled = useIsRunDisabledCheck();

  if (!model || disabled) {
    return "Not ready (configurations are not complete)";
  }

  if (model.status?.ERROR) {
    const sliceError =
      model.slices[
        model.slices.findIndex(m => !!(m as ProjectEnrichmentSlice).error_message)
      ];
    const message = sliceError
      ? (sliceError as ProjectEnrichmentSlice).error_message
      : DEFAULT_ERROR;

    return `Error: ${message}`;
  }

  if (model.status?.COMPUTING) {
    return `Processing: ${Math.ceil((1 - model.status.COMPUTING) * 100)}%`;
  }

  if (model.status?.READY || model.status?.STALE) {
    return "Ready to be computed (configurations complete)";
  }

  if (model.status?.SUCCESS === 1) {
    return "Enrichment is completed";
  }

  return "Unknown status";
}
