import { api, EnrichmentTypesEnum, VisualizationTypes } from "@api";
import { AreaLayersBaseProps } from "@components/aoi/AreaLayersBase";
import { createModel } from "@rematch/core";
import { createCanvasLegend, downloadLocally } from "@utils";
import { Vector2d } from "konva/lib/types";
import i18n from "../i18n";
import { AOI_HEATMAP_TYPES, RootModel } from "./index";

interface InitialState {
  query: Set<string>;
  maxById: Map<string, Record<AOI_HEATMAP_TYPES, number>>;
}

const initialState: InitialState = {
  query: new Set(),
  maxById: new Map(),
};

export const visualizationDownloads = createModel<RootModel>()({
  state: initialState,
  reducers: {
    addToQuery: (state, data: string) => {
      state.query.add(data);
    },
    removeFromQuery: (state, data: string) => {
      state.query.delete(data);
    },
    addToMax: (
      state,
      { id, data }: { id: string; data: Record<AOI_HEATMAP_TYPES, number> },
    ) => {
      state.maxById.set(id, data);
    },
    removeFromMax: (state, data: string) => {
      state.maxById.delete(data);
    },
  },
  effects: dispatch => ({
    async getImage(id: string, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const visualization = state.visualizations.dataById.get(id);

      if (!visualization?.enrichment_id) return;

      const enrichment = state.enrichments.dataById.get(visualization.enrichment_id);

      if (
        !workspaceId ||
        !projectId ||
        !enrichment ||
        (enrichment.kind !== EnrichmentTypesEnum.SLAM_MAPPER &&
          enrichment.kind !== EnrichmentTypesEnum.MARKER_MAPPER &&
          enrichment.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER)
      ) {
        return;
      }

      try {
        const url = await api.getHeatmapBackground(
          {
            enrichment_id: enrichment.id,
            project_id: projectId,
            workspaceId,
            maxSize: 1920,
          },
          true,
        );

        return url;
      } catch (error) {
        console.error(error);
      }
    },
    async getAois(
      {
        id,
        width,
        height,
        scale,
      }: { id: string; width: number; height: number; scale?: Vector2d },
      state,
    ): Promise<AreaLayersBaseProps | undefined> {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const visualization = state.visualizations.dataById.get(id);

      if (!visualization?.enrichment_id) return;

      const enrichmentId = state.enrichments.dataById.get(
        visualization.enrichment_id,
      )?.id;

      if (
        !workspaceId ||
        !projectId ||
        !enrichmentId ||
        visualization.kind !== VisualizationTypes.AOI_HEATMAP
      ) {
        return;
      }

      try {
        const res = await api.getProjectEnrichmentsAois(
          {
            workspaceId,
            projectId,
            enrichmentId,
          },
          true,
        );

        const data: AreaLayersBaseProps["data"] = [];
        const aoiAreasById: AreaLayersBaseProps["aoiAreasById"] = new Map();

        res.result.forEach(one => {
          data.push(one);
          aoiAreasById.set(one.id, one);
        });

        const { images, visibility } = await dispatch.aoiAreas.innerPrepare({
          stateImages: {},
          visualizationAoiIds: new Set(visualization.payload.aoi_ids),
          data,
          width,
          height,
          all: null,
        });

        const resStats = await api.getAoiStats(
          {
            workspaceId,
            projectId,
            enrichmentId,
            params: visualization.payload.recording_ids?.length
              ? visualization.payload.recording_ids.reduce((acc, id, i) => {
                  if (i !== 0) {
                    acc += "&";
                  }

                  acc += `rids=${id}`;

                  return acc;
                }, "")
              : undefined,
          },
          true,
        );

        const { tableDataById: aoiStatsById, nextMax } = dispatch.aoiStats.innerPrepare(
          {
            data: Object.values(resStats.result.aois),
            aoiAreasById,
            visibility,
          },
        );

        dispatch.visualizationDownloads.addToMax({ id, data: nextMax });

        return {
          data,
          aoiAreasById,
          images,
          visibility,
          aoiStatsById,
          showNames: visualization.payload.show_names,
          showMetricValue: visualization.payload.show_metric_values,
          heatmapType: visualization.payload.metrics,
          heatmapColor: visualization.payload.color_map,
          disabled: true,
          draw: false,
          height,
          width,
          scale,
        };
      } catch (error) {
        console.error(error);
      }
    },
    startDownload({ id, canvas }: { id: string; canvas?: HTMLCanvasElement }, state) {
      const visualization = state.visualizations.dataById.get(id);
      const max = state.visualizationDownloads.maxById.get(id);

      if (
        !visualization ||
        visualization.kind !== VisualizationTypes.AOI_HEATMAP ||
        !canvas ||
        !max
      )
        return;

      try {
        const href = createCanvasLegend({
          width: canvas.width,
          height: canvas.height,
          draw: ctx => {
            ctx.drawImage(canvas, 0, 0);
          },
          heatmapType: visualization.payload.metrics,
          heatmapColor: visualization.payload.color_map,
          max,
        }).toDataURL();

        downloadLocally({ href, name: visualization.name });
        dispatch.visualizationDownloads.removeFromMax(id);
        dispatch.visualizationDownloads.removeFromQuery(id);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("AOI heatmap downloaded").toString(),
          options: { variant: "success" },
        });
      } catch (error) {
        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Error downloading AOI heatmap").toString(),
          options: { variant: "error" },
        });
      }
    },
  }),
});
