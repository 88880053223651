import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { RouterHelper } from "@pages";
import { store } from "@storeRematch";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingStep } from "./OnboardingModal";

const ListDisc = styled("ul")(() => {
  return {
    listStyle: "disc",
  };
});

const ListItem: FC<{ text: string }> = ({ text }) => {
  const { t } = useTranslation();
  return (
    <Typography component={"li"} variant="body2" ml={2}>
      {t(text)}
    </Typography>
  );
};

const TextItem: FC<{ text: string }> = ({ text }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant="body2">{t(text)}</Typography>
    </div>
  );
};

const getProjectPath = () => {
  const state = store.getState();
  // todo: get demo workspace id
  const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
  const projectId = state.projects.data[0].id;

  if (!workspaceId || !projectId) return "";

  return RouterHelper.projectRecordings.getFullPath({
    props: { workspaceId, projectId },
  });
};

const getToProject = (path: string) => {
  return getProjectPath() + path;
};

const getToProjectEnrichment = () => {
  const enrichmentId = store.getState().enrichments.data[0].id;
  return getProjectPath() + `enrichments/${enrichmentId}`;
};

export enum ModalIds {
  "DEMO_WORKSPACE" = "tour-demo-workspace",
  "DEMO_WORKSPACE_MODAL" = "tour-demo-workspace-modal",
  "PROJECTS" = "tour-projects",
  "PROJECTS_MODAL" = "tour-projects-modal",
  "PROJECT" = "tour-project",
  "ENRICHMENTS" = "tour-enrichments",
  "ENRICHMENTS_MODAL" = "tour-enrichments-modal",
  "ENRICH_SETUP" = "tour-enrich-setup",
  "DOWNLOADS" = "tour-downloads",
  "GET_HELP" = "tour-get-help",
  "GET_HELP_MODAL" = "tour-get-help-modal",
  "DEMO_WORKSPACE_HEADER" = "tour-demo-workspace-header",
}

export const ONBOARDING_STEPS: OnboardingStep[] = [
  {
    id: ModalIds.DEMO_WORKSPACE,
    subId: ModalIds.DEMO_WORKSPACE_MODAL,
    title: "Demo Workspace",
    placement: "top-right",
    highlight: ModalIds.DEMO_WORKSPACE_HEADER,
    children: (
      <ListDisc>
        <ListItem text="All recordings are uploaded to your selected Workspace" />
        <ListItem text="Sharing your workspace" />
        <ListItem text="Navigate and annotate your videos" />
        <ListItem text="Switch back to your personal workspace at any time!" />
      </ListDisc>
    ),
  },
  {
    id: ModalIds.PROJECTS,
    subId: ModalIds.PROJECTS_MODAL,
    title: "Projects",
    placement: "top-right",
    highlight: ModalIds.DEMO_WORKSPACE_HEADER,
    to: () => getToProject("recordings"),
    children: (
      <ListDisc>
        <ListItem text="All recordings are uploaded to your selected Workspace" />
        <ListItem text="Sharing your workspace" />
        <ListItem text="Navigate and annotate your videos" />
        <ListItem text="Switch back to your personal workspace at any time!" />
      </ListDisc>
    ),
  },
  {
    id: ModalIds.PROJECT,
    title: "In your project",
    placement: "top-right",
    highlight: ModalIds.PROJECT,
    children: (
      <ListDisc>
        <ListItem text="Add recordings you want to a project" />
        <ListItem text="Sharing your workspace" />
        <ListItem text="Navigate and annotate your videos" />
      </ListDisc>
    ),
  },
  {
    id: ModalIds.ENRICHMENTS,
    subId: ModalIds.ENRICHMENTS_MODAL,
    title: "Enrichments",
    placement: "bottom-left",
    highlight: ModalIds.PROJECT,
    to: () => getToProjectEnrichment(),
    children: (
      <TextItem
        text="
        Enrichments are tools that allow you to explore and visualize your data in new ways. 
        These tools require complex algorithms and significant computational resources."
      />
    ),
  },
  {
    id: ModalIds.ENRICH_SETUP,
    title: "Enrichment Setup",
    placement: "top-right",
    highlight: ModalIds.ENRICH_SETUP,
    to: () => getToProject("downloads"),
    children: (
      <ListDisc>
        <ListItem text="Set up your enrichment" />
        <ListItem text="They run on recordings in the project" />
        <ListItem text="Visualize and review the enrichment" />
      </ListDisc>
    ),
  },
  {
    id: ModalIds.DOWNLOADS,
    title: "Downloads",
    placement: "bottom-right",
    highlight: ModalIds.PROJECT,
    children: (
      <ListDisc>
        <ListItem text="Download data from your recordings" />
        <ListItem text="Download data from your enrichments" />
      </ListDisc>
    ),
  },
  {
    id: ModalIds.GET_HELP,
    subId: ModalIds.GET_HELP_MODAL,
    title: "Get Help",
    placement: "top-left",
    highlight: ModalIds.DEMO_WORKSPACE_HEADER,
    children: (
      <TextItem text="You can always repeat this tour under the ‘Help’ menu." />
    ),
  },
];
