import {
  VIDEO_POSITIONS_ROOT,
  VIDEO_TIMELINE_SCROLL_ROOT,
  useGetVideoTimes,
  useInstanceDuration,
} from "@hooks";
import { Box, useTheme } from "@mui/material";
import { useAppSelector } from "@storeRematch";
import { useLayoutEffect, useRef } from "react";

export const VideoTimeline = () => {
  const hideVideoPlayer = useAppSelector(state => state.video.hideVideoPlayer);
  const zoomCss = useAppSelector(state => state.video.zoom.css);
  const zoomOffset = useAppSelector(state => state.video.zoom.zoomOffset);
  const dragger = useAppSelector(
    state =>
      state.dragger.tableProject.size +
      state.dragger.table.size +
      state.dragger.video.size +
      state.dragger.videoFullScreen.size +
      Number(state.video.fullScreen),
  );
  const fullScreen = useAppSelector(state => state.video.fullScreen);
  const { times } = useGetVideoTimes();
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const duration = useInstanceDuration();
  const theme = useTheme();

  useLayoutEffect(() => {
    function draw() {
      const root = canvas.current?.parentElement;
      const height = document.getElementById(VIDEO_POSITIONS_ROOT)?.offsetHeight;
      const scrollRoot = document.getElementById(VIDEO_TIMELINE_SCROLL_ROOT);

      if (!canvas.current || !root || !scrollRoot || height === undefined) return;

      const ratio = window.devicePixelRatio;

      canvas.current.style.width = `${root.offsetWidth}px`;
      canvas.current.style.height = `${height}px`;
      canvas.current.width = root.offsetWidth * ratio;
      canvas.current.height = height * ratio;

      const ctx = canvas.current.getContext("2d");

      if (!ctx) return;

      ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
      ctx.font = `${theme.typography.caption} ${theme.typography.fontFamily}`;

      ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);

      const checkedDuration = duration === undefined || isNaN(duration) ? -1 : duration;
      if (checkedDuration === -1) return;

      for (let i = 0; i < times.length; i++) {
        const one = times[i];

        const posInPx = (scrollRoot.offsetWidth * one.offset) / 100 - zoomOffset;

        if (posInPx && posInPx > canvas.current.width) break;
        if (posInPx < -2) continue;

        ctx.fillStyle = theme.palette.text.secondary;
        ctx.fillText(
          one.text,
          i === 0 ? posInPx : posInPx - ctx.measureText(one.text).width / 2,
          8.5,
        );

        if (i === 0) continue;

        ctx.fillStyle = theme.palette.divider;
        ctx.fillRect(posInPx, 12, 1, canvas.current.height);
      }
    }

    draw();

    window.addEventListener("resize", draw);
    return () => window.removeEventListener("resize", draw);
  }, [duration, theme, times, zoomOffset, fullScreen, dragger]);

  return (
    <Box
      sx={{
        display: "flex",
        height: 12,
        backgroundColor: "action.hover",
        opacity: hideVideoPlayer ? 0 : 1,
        position: "relative",
        scrollbarWidth: "none",
        flexGrow: 1,
      }}
    >
      <Box
        id={VIDEO_TIMELINE_SCROLL_ROOT}
        sx={{
          ...zoomCss,
          display: "flex",
          height: "100%",
          position: "absolute",
          //maxWidth: "100%",
        }}
      ></Box>
      <canvas
        ref={canvas}
        style={{ position: "absolute", zIndex: 3, pointerEvents: "none" }}
      />
    </Box>
  );
};
