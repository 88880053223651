import { VideoPlayer } from "@components";
import { gazeOverlay, scanPathOverlay } from "@components/videoPlayer/controller";
import { Move } from "@customIcons";
import { Box, Button, Divider, Typography } from "@mui/material";
import {
  QuestionDialogTypes,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch/index";
import { LocalStorageAdapterNames, localStorageAdapter } from "@utils";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export const RecordingEditGazeOffset = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { recordingId } = useParams();
  const recording = useAppSelector(state =>
    recordingId ? state.recordings.tableDataById.get(recordingId) : null,
  );

  useEffect(() => {
    const gazeOverlayBlinks = gazeOverlay.hideBlinks;
    const gazeOverlayHide = gazeOverlay.hide;
    gazeOverlay.hide = false;
    gazeOverlay.hideBlinks = true;
    gazeOverlay.alwaysBlinkColor = true;

    const scanPathHide = scanPathOverlay.hide;
    scanPathOverlay.hide = true;

    return () => {
      dispatch.video.setRememberRecordingCurrentTime(true);

      gazeOverlay.hide = gazeOverlayHide;
      gazeOverlay.hideBlinks = gazeOverlayBlinks;
      gazeOverlay.alwaysBlinkColor = false;
      scanPathOverlay.hide = scanPathHide;
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      if (recording) {
        localStorageAdapter.set(
          LocalStorageAdapterNames.autoSelectRecording,
          recording.id,
        );
      }
    };
  }, [dispatch, recording]);

  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: 300, p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body1">{t("Settings")}</Typography>
          <SaveButton />
        </Box>
        <InnerDivider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="body3">
            <Move fontSize="small" sx={{ mr: 1 }} />
            {t("Drag gaze circle to move gaze.")}
          </Typography>
          <Typography variant="body3" color="text.secondary" sx={{ mt: 1 }}>
            {t("Gaze offset only applies to this recording.")}
          </Typography>
        </Box>
        <InnerDivider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2">
            <Circle color="#F44336" />
            {t("Current gaze position")}
          </Typography>
          {recording?.recording.android_gaze_offset.x !== 0 &&
            recording?.recording.android_gaze_offset.y !== 0 && (
              <Typography variant="body2" component="span">
                <Circle color="#1AB1E0" />
                {t("Offset in Companion app")}
              </Typography>
            )}
          <Typography variant="body2">
            <Circle color="#A09FA6" />
            {t("Gazenet position")}
          </Typography>
        </Box>
        <InnerDivider />
        <Typography variant="body1" sx={{ mb: 1 }}>
          {t("Recording Name")}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {recording?.recording.name || "-"}
        </Typography>

        <InnerDivider />
      </Box>
      <Divider orientation="vertical" />
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <VideoPlayer recording={recording?.recording} />
      </Box>
    </Box>
  );
};

const InnerDivider: FC = () => {
  return <Divider sx={{ mb: 2, mt: 2, width: "calc(100% + 32px)", ml: -2 }} />;
};

const SaveButton: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(
    state =>
      state.loading.effects.app.loadWorkspaceData.loading ||
      state.loading.effects.recordingGazeOffset.updateOffset.loading,
  );
  const disabled = useAppSelector(state => !state.recordingGazeOffset.nextOffset);

  return (
    <Button
      color="primary"
      size="small"
      onClick={() => {
        dispatch.questionDialog.set({
          type: QuestionDialogTypes.GAZE_OFFSET_CHANGE,
          onSuccess: async () => {
            await dispatch.recordingGazeOffset.updateOffset(false);
            navigate(-1);
          },
        });
      }}
      disabled={loading || disabled}
    >
      {t("Save")}
    </Button>
  );
};

const Circle: FC<{ color: string }> = ({ color }) => {
  return (
    <Box
      component="span"
      sx={{
        display: "inline-block",
        border: `1px solid ${color}`,
        borderRadius: "50%",
        width: 10,
        height: 10,
        mr: 1,
      }}
    />
  );
};
