import { Loader } from "@components/Loader";
import { RecoverEmailAction, RouterHelper } from "@pages";
import { useAppSelector } from "@storeRematch";
import { Navigate, useSearchParams } from "react-router-dom";
import { SendNewPassword } from "./SendNewPassword";
import { VerifyEmailAction } from "./VerifyEmailAction";

export const GoogleAuthServices = () => {
  const [params] = useSearchParams();
  const isLoading = useAppSelector(
    state => state.app.authLoading || state.loading.effects.app.finalizeAuth.loading,
  );

  if (params.has("canny-sso"))
    return <Navigate to={RouterHelper.signIn.getFullPath({ prefix: "../" })} />;

  if (isLoading) return <Loader />;

  if (document.URL.includes("accept_invite"))
    return (
      <Navigate
        to={RouterHelper.acceptInvites.getFullPath({
          prefix: "../",
          props: { token: document.URL.split("/").pop() },
        })}
      />
    );

  if (params.get("mode") === "verifyEmail") return <VerifyEmailAction />;

  if (params.get("mode") === "recoverEmail") return <RecoverEmailAction />;

  return <SendNewPassword />;
};
