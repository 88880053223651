import { FaceDetection } from "@api";
import { overlayCalculations } from "./OverlayCalculations";
import { ShapesControl, ShapesControlChildProps } from "./ShapesControl";

export class FaceOverlay extends ShapesControl<FaceDetection, SVGCircleElement> {
  constructor(props?: ShapesControlChildProps) {
    super({ canvasId: "FaceOverlay", paintType: true, ...props });
  }

  circle(ctx: CanvasRenderingContext2D, point: number[]) {
    const scaled = overlayCalculations.scaleNormalized(point);

    if (!scaled) return;

    ctx.beginPath();
    ctx.arc(scaled[0], scaled[1], 4, 0, 2 * Math.PI);
    ctx.fillStyle = "#039BE5";
    ctx.strokeStyle = "#039BE5";
    ctx.lineWidth = 1;
    ctx.stroke();
    ctx.fill();
    ctx.closePath();
  }

  paintAll(event?: FaceDetection) {
    if (!event) return;

    const ctx = this.getCtx();

    if (!ctx) return;

    event.faces?.forEach(face => {
      const surface = face.bounding_box;

      if (surface) {
        var x1 = surface.upper_left?.x;
        var y1 = surface.upper_left?.y;
        var x2 = surface.lower_right?.x;
        var y2 = surface.lower_right?.y;

        if (
          x1 === undefined ||
          y1 === undefined ||
          x2 === undefined ||
          y2 === undefined
        )
          return;

        const scaled1 = overlayCalculations.scaleNormalized([x1, y1]);
        const scaled2 = overlayCalculations.scaleNormalized([x2, y2]);

        if (!scaled1 || !scaled2) return;

        ctx.beginPath();
        ctx.rect(
          scaled1[0],
          scaled1[1],
          Math.abs(scaled1[0] - scaled2[0]),
          Math.abs(scaled1[1] - scaled2[1]),
        );
        ctx.strokeStyle = "#039BE5";
        ctx.lineWidth = 3;
        ctx.stroke();
        ctx.closePath();
      }

      const { eyes, mouth, nose } = face;

      if (eyes) {
        if (eyes.left && eyes.left?.x !== undefined && eyes.left?.y !== undefined) {
          this.circle(ctx, [eyes.left.x, eyes.left.y]);
        }

        if (eyes.right && eyes.right?.x !== undefined && eyes.right?.y !== undefined) {
          this.circle(ctx, [eyes.right.x, eyes.right.y]);
        }
      }

      if (mouth) {
        if (mouth.left && mouth.left?.x !== undefined && mouth.left?.y !== undefined) {
          this.circle(ctx, [mouth.left.x, mouth.left.y]);
        }

        if (
          mouth.right &&
          mouth.right?.x !== undefined &&
          mouth.right?.y !== undefined
        ) {
          this.circle(ctx, [mouth.right.x, mouth.right.y]);
        }
      }

      if (nose && nose.x !== undefined && nose.y !== undefined) {
        this.circle(ctx, [nose.x, nose.y]);
      }
    });
  }
}
