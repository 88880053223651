import {
  ArrowBack,
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
} from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { store, useAppDispatch, useAppSelector } from "@storeRematch";
import { Fragment, useEffect } from "react";

function disableEvent(e: KeyboardEvent) {
  e.preventDefault();
  e.stopPropagation();
  store.dispatch.ctxMenu.closeAll();
}

function isInputOrTextArea() {
  if (!document.activeElement) return false;

  return (
    document.activeElement.tagName === "INPUT" ||
    document.activeElement.tagName === "TEXTAREA" ||
    document.activeElement.tagName === "LI"
  );
}

function isMacintosh() {
  return navigator.userAgent.indexOf("Mac") > -1;
}

function isWindows() {
  return navigator.userAgent.indexOf("Win") > -1;
}

function isControlClicked(e: KeyboardEvent) {
  if (isMacintosh()) return e.metaKey;
  if (isWindows()) return e.ctrlKey;
  return e.ctrlKey || e.metaKey;
}

function isShiftClicked(e: KeyboardEvent) {
  return e.shiftKey;
}

function isTableFocused() {
  return document.activeElement?.className.includes("MuiDataGrid-");
}

function isSkipCustomHotkey(e: KeyboardEvent) {
  return (e.ctrlKey || e.metaKey) && e.shiftKey;
}

function isAoiTool() {
  return document.URL.includes("/aoi");
}

const rows = [
  {
    title: "Table",
    rows: [
      {
        title: "Move selected up",
        key: [<ArrowUpward fontSize="small" />],
      },
      {
        title: "Move selected down",
        key: [<ArrowDownward fontSize="small" />],
      },
      {
        title: "Multiselect up",
        key: ["Shift", <ArrowUpward fontSize="small" />],
      },
      {
        title: "Multiselect down",
        key: ["Shift", <ArrowDownward fontSize="small" />],
      },
      {
        title: "Clear selection",
        key: ["Esc"],
      },
      {
        title: "Activate search bar",
        key: ["/"],
      },
      {
        title: "Delete recordings(s)",
        key: ["Del"],
      },
      {
        title: "Create project with selection",
        key: ["P"],
      },
      {
        title: "View recording information",
        key: ["I"],
      },
    ],
  },
  {
    title: "Player",
    rows: [
      {
        title: "Play / Pause",
        key: ["Space Bar"],
      },
      {
        title: "Forwards 5 seconds",
        key: [<ArrowForward fontSize="small" />],
      },
      {
        title: "Backwards 5 seconds",
        key: [<ArrowBack fontSize="small" />],
      },
      {
        title: "Forwards 0.03 seconds",
        key: ["Shift", <ArrowForward fontSize="small" />],
      },
      {
        title: "Backwards 0.03 second",
        key: ["Shift", <ArrowBack fontSize="small" />],
      },
      {
        title: "Fullscreen",
        key: ["F"],
      },
      {
        title: "Mute / Unmute",
        key: ["M"],
      },
    ],
  },
  {
    title: "Timeline",
    rows: [
      {
        title: "Expand all",
        key: ["Ctrl", "E"],
      },
      {
        title: "Collapse all",
        key: ["Ctrl", "E"],
      },
      {
        title: "Create event ",
        key: ["X"],
      },
    ],
  },
  {
    title: "AOI Editor",
    rows: [
      {
        title: "New AOI",
        key: ["N"],
      },
      {
        title: "Select",
        key: ["V", "Esc"],
        or: 1,
      },
      {
        title: "Pen",
        key: ["P"],
      },
      {
        title: "Erase",
        key: ["E"],
      },
    ],
  },
  {
    title: "Manual Mapper",
    rows: [
      {
        title: "Previous Fixation",
        key: ["A"],
      },
      {
        title: "Next Fixation",
        key: ["D"],
      },
      {
        title: "Mark as not on reference image",
        key: ["S"],
      },
    ],
  },
];

export const HotKeys = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(state => state.entityTable.hotkeys);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (isSkipCustomHotkey(e)) return;

      const state = store.getState();

      if (e.code === "Space") {
        if (isInputOrTextArea()) return;

        disableEvent(e);
        const instance = state.video.instance;

        if (!instance) return;

        if (instance.paused()) instance.play();
        else instance.pause();

        return;
      }

      if (e.code === "ArrowRight") {
        if (isInputOrTextArea()) return;
        if (isTableFocused()) return;

        disableEvent(e);
        const instance = state.video.instance;

        if (!instance) return;

        const num = e.shiftKey ? 0.03 : 5;
        instance.currentTime(instance.currentTime() + num);

        return;
      }

      if (e.code === "ArrowLeft") {
        if (isInputOrTextArea()) return;
        if (isTableFocused()) return;

        disableEvent(e);
        const instance = state.video.instance;

        if (!instance) return;

        const num = e.shiftKey ? 0.03 : 5;
        instance.currentTime(instance.currentTime() - num);

        return;
      }

      if (e.code === "KeyM") {
        if (isControlClicked(e)) return;
        if (isInputOrTextArea()) return;

        disableEvent(e);
        const instance = state.video.instance;

        if (!instance) return;

        instance.volume(instance.volume() ? 0 : 1);

        return;
      }

      if (e.code === "KeyF") {
        if (isControlClicked(e)) return;
        if (isInputOrTextArea()) return;

        disableEvent(e);
        store.dispatch.video.toggleFullScreenEffect(null);
        return;
      }

      if (e.code === "Slash") {
        if (isControlClicked(e)) return;
        if (isInputOrTextArea()) return;

        disableEvent(e);
        document.getElementById("table_search")?.focus();
        return;
      }

      if (e.code === "KeyE") {
        if (isControlClicked(e)) return;
        if (isInputOrTextArea()) return;

        if (isAoiTool() && state.aoiAreas.current) {
          disableEvent(e);
          store.dispatch.aoiTool.selectPenRemoveTool(null);
          return;
        }

        if (!isControlClicked(e)) return;

        disableEvent(e);
        document.getElementById("video_events_open")?.click();
        return;
      }

      if (e.code === "KeyX") {
        if (isControlClicked(e)) return;
        if (isInputOrTextArea()) return;

        disableEvent(e);
        store.dispatch.videoEvents.prepareNewEvent(null);
        return;
      }

      if (e.code === "KeyS") {
        if (isControlClicked(e)) return;
        if (isInputOrTextArea()) return;

        disableEvent(e);
        if (state.staticImageMappers.currentFixationIndex === null) return;
        store.dispatch.staticImageMappers.setFixationNotOnRefImg(null);
        return;
      }

      if (e.code === "KeyA") {
        if (isControlClicked(e)) return;
        if (isInputOrTextArea()) return;

        if (isShiftClicked(e)) {
          disableEvent(e);
          store.dispatch.aoiAreas.selectAll(null);
          return;
        }

        disableEvent(e);

        store.dispatch.staticImageMappers.prevFixation(null);
        return;
      }

      if (e.code === "KeyD") {
        if (isControlClicked(e)) return;
        if (isInputOrTextArea()) return;

        disableEvent(e);

        store.dispatch.staticImageMappers.nextFixation(null);
        return;
      }

      if (e.code === "KeyI") {
        if (isControlClicked(e)) return;
        if (isInputOrTextArea()) return;
        if (!document.URL.includes("/recordings")) return;

        disableEvent(e);

        store.dispatch.viewDetails.viewRecordingFirst(null);
        return;
      }

      if (e.code === "KeyV" || e.code === "Escape") {
        if (e.code === "Escape") {
          store.dispatch.ctxMenu.closeAll();
          store.dispatch.video.setFullScreen(false);
        }

        if (isInputOrTextArea()) return;
        if (!isAoiTool()) return;

        disableEvent(e);
        store.dispatch.aoiTool.selectPointerTool(null);
        return;
      }

      if (e.code === "KeyN") {
        if (isControlClicked(e)) return;
        if (isInputOrTextArea()) return;
        if (!isAoiTool()) return;

        disableEvent(e);
        store.dispatch.aoiAreas.create(null);
        return;
      }

      if (e.code === "KeyP" && state.aoiAreas.current) {
        if (isControlClicked(e)) return;
        if (isInputOrTextArea()) return;
        if (!isAoiTool()) return;

        disableEvent(e);
        store.dispatch.aoiTool.selectPenAddTool(null);
        return;
      }

      if (e.code === "ArrowUp" && state.aoiAreas.current) {
        if (isInputOrTextArea()) return;
        if (!isAoiTool()) return;

        const currentIndex = state.aoiAreas.data.findIndex(
          d => d.id === state.aoiAreas.current?.id,
        );

        if (currentIndex !== -1) {
          disableEvent(e);

          const nextIndex =
            currentIndex === 0 ? state.aoiAreas.data.length - 1 : currentIndex - 1;

          store.dispatch.aoiAreas.select({ id: state.aoiAreas.data[nextIndex].id });
          return;
        }
      }

      if (e.code === "ArrowDown" && state.aoiAreas.current) {
        if (isInputOrTextArea()) return;
        if (!isAoiTool()) return;

        const currentIndex = state.aoiAreas.data.findIndex(
          d => d.id === state.aoiAreas.current?.id,
        );

        if (currentIndex !== -1) {
          disableEvent(e);

          const nextIndex =
            currentIndex === state.aoiAreas.data.length - 1 ? 0 : currentIndex + 1;

          store.dispatch.aoiAreas.select({ id: state.aoiAreas.data[nextIndex].id });
          return;
        }
      }
    };

    document.addEventListener("keypress", onKeyDown);
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.addEventListener("keypress", onKeyDown);
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <Dialog
      open={open}
      fullWidth
      fullScreen
      sx={{ height: "450px", mt: "auto" }}
      onClose={dispatch.entityTable.toggleHotKeys}
    >
      <DialogTitle>Keyboard Shortcuts</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="row" sx={{ height: "100%" }}>
          {rows.map((r, index) => {
            return (
              <Fragment key={index}>
                {index !== rows.length && index !== 0 && (
                  <Divider orientation="vertical" sx={{ mr: 4 }} />
                )}
                <Box display="flex" flexDirection="column" sx={{ height: "100%" }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    {r.title}
                  </Typography>
                  {r.rows.map(i => (
                    <Grid
                      container
                      key={i.title}
                      sx={{ mb: 2, minWidth: "350px" }}
                      spacing={2}
                    >
                      <Grid item sm={8}>
                        {i.title}
                      </Grid>
                      <Grid item sm={4}>
                        <Box display="flex">
                          {i.key.map((k, kI) => {
                            return (
                              <Fragment key={kI}>
                                {(i as any).or === kI ? (
                                  <Typography sx={{ ml: 0.5, mr: 1 }}>/</Typography>
                                ) : null}
                                <Box
                                  sx={{
                                    backgroundColor: "primary.main",
                                    p: 0.2,
                                    px: 0.8,
                                    borderRadius: "4px",
                                    minWidth: 23,
                                    minHeight: 23,
                                    display: "flex",
                                    mr: 0.5,
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {k}
                                </Box>
                              </Fragment>
                            );
                          })}
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Fragment>
            );
          })}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
