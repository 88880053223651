import { createModel } from "@rematch/core";
import type { QuestionDialogPropTypes, RootModel } from "./index";

interface InitialState {
  open: boolean;
  props: QuestionDialogPropTypes | null;
}

const initialState: InitialState = {
  open: false,
  props: null,
};

export const questionDialog = createModel<RootModel>()({
  state: initialState,
  reducers: {
    set(state, data: QuestionDialogPropTypes) {
      state.open = true;
      state.props = data;
    },
    reset(state) {
      state.open = false;
      state.props = null;
    },
  },
});
