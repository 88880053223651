import {
  AoiDownloadLogic,
  AuthenticatedRoute,
  CtxMenuControl,
  DraggerWindowResizeListener,
  ErrorPage,
  GlobalModal,
  HotKeys,
  InviteMembersModal,
  Loader,
  Notifier,
  ProjectFilteredRecordingsDialog,
  QuestionDialog,
  SnackbarNotifier,
  TemplatePreview,
  UnAuthenticatedRoute,
  UserOnboarding,
  ViewDetails,
} from "@components";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Home, ProjectAddDialog, RouterHelper } from "@pages";
import { ErrorBoundary } from "@sentry/react";
import { store, useAppSelector } from "@storeRematch";
import { themes } from "@styles/theme";
import { SnackbarProvider } from "notistack";
import { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useNavigationType,
} from "react-router-dom";
import { BackButtonProvider } from "./hooks";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <CtxMenuControl />
        <BackButtonProvider>
          <App />
        </BackButtonProvider>
      </>
    ),
    children: [
      {
        element: <UnAuthenticatedRoute />,
        children: [
          RouterHelper.signIn.getRouterProps(),
          RouterHelper.singUp.getRouterProps(),
          RouterHelper.remind.getRouterProps(),
          RouterHelper.verifyEmail.getRouterProps(),
          RouterHelper.authService.getRouterProps(),
        ],
      },
      {
        element: <AuthenticatedRoute />,
        children: [
          RouterHelper.acceptInvites.getRouterProps(),
          RouterHelper.accountSettingsLayout.getRouterProps([
            RouterHelper.accountSettings.getRouterProps([
              RouterHelper.accountSettingsEditImage,
              RouterHelper.accountSettingsEditName,
              RouterHelper.accountSettingsEditEmail,
              RouterHelper.accountSettingsEditPassword,
              RouterHelper.accountSettingsDelete,
            ]),
            RouterHelper.accountSettingsDeveloper.getRouterProps([
              RouterHelper.accountSettingsDeveloperNew,
            ]),
            RouterHelper.accountSettingsDemo,
          ]),
          { index: true, element: <Home /> },
          RouterHelper.workspaceLayout.getRouterProps([
            RouterHelper.projectLayout.getRouterProps([
              RouterHelper.projectRecordings.getRouterProps([
                RouterHelper.projectRecordingsEdit,
                RouterHelper.projectRecordingsEditWearer,
              ]),
              RouterHelper.projectEnrichments.getRouterProps([
                RouterHelper.projectEnrichmentsEdit.getRouterProps([
                  RouterHelper.projectEnrichmentsAOI,
                ]),
              ]),
              RouterHelper.projectVisualizations,
              RouterHelper.projectVisualizationsHeatmap,
              RouterHelper.projectVisualizationsRender,
              RouterHelper.projectVisualizationsAoi,
              RouterHelper.projectDownloads,
            ]),
            RouterHelper.workspaceRecordings.getRouterProps([
              RouterHelper.workspaceRecordingsEdit,
              RouterHelper.workspaceRecordingsEditWearer,
            ]),
            RouterHelper.workspaceRecordingsEditGazeOffset,
            RouterHelper.workspaceSettingsLayout.getRouterProps([
              RouterHelper.workspaceSettings.getRouterProps([
                RouterHelper.workspaceSettingsEdit,
                RouterHelper.workspaceSettingsDelete,
              ]),
              RouterHelper.workspaceSettingsWearers.getRouterProps([
                RouterHelper.workspaceSettingsWearersNew,
                RouterHelper.workspaceSettingsWearersEdit,
              ]),
              RouterHelper.workspaceSettingsLabels.getRouterProps([
                RouterHelper.workspaceSettingsLabelsNew,
                RouterHelper.workspaceSettingsLabelsEdit,
              ]),
              RouterHelper.workspaceSettingsProjects.getRouterProps([
                RouterHelper.workspaceSettingsProjectsNew,
                RouterHelper.workspaceSettingsProjectsEdit,
              ]),
              RouterHelper.workspaceSettingsTemplates.getRouterProps([
                RouterHelper.workspaceSettingsTemplatesEdit,
              ]),
              RouterHelper.workspaceSettingsMembers,
            ]),
          ]),
          RouterHelper.errorPage.getRouterProps(),
        ],
      },
    ],
  },
]);

const NavigationListener = () => {
  const navType = useNavigationType();

  useEffect(() => {
    if (navType === "POP") {
      store.dispatch.video.setFullScreen(false);
    }
  }, [navType]);

  return null;
};

function App() {
  const devMode = true;
  const darkMode = useAppSelector(state => state.app.useDarkMode);
  const devUseDefaultTheme = useAppSelector(state => state.app.useDefaultTheme);

  const theme =
    themes[darkMode ? "dark" : "light"][
      devMode && devUseDefaultTheme ? "default" : "custom"
    ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary fallback={() => <ErrorPage />}>
        <SnackbarProvider
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          dense={false}
          autoHideDuration={4000}
          Components={{
            default: SnackbarNotifier,
            error: SnackbarNotifier,
            success: SnackbarNotifier,
            warning: SnackbarNotifier,
            info: SnackbarNotifier,
          }}
        >
          <NavigationListener />
          <HotKeys />
          <UserOnboarding />
          <GlobalModal />
          <ViewDetails />
          <TemplatePreview />
          <QuestionDialog />
          <Notifier />
          <ProjectFilteredRecordingsDialog />
          <ProjectAddDialog />
          <InviteMembersModal />
          <AoiDownloadLogic />
          <DraggerWindowResizeListener />
          <Outlet />
        </SnackbarProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

const AppWrapper = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </Provider>
    </Suspense>
  );
};

export default AppWrapper;
