import { ContextMenu } from "@components";
import {
  InputLabel,
  StandardTextFieldProps,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { ContextMenuTypes, useAppDispatch } from "@storeRematch";
import { ChangeEvent, FC, PropsWithChildren, useEffect, useState } from "react";
import { AlphaPicker, HuePicker, RGBColor } from "react-color";

export type MyRGB = Record<"r" | "g" | "b" | "a", number | string | undefined>;

export const ColorSlider: FC<
  PropsWithChildren<{
    label: string;
    value: any;
    onChange: (value: MyRGB) => void;
    disabled?: boolean;
  }>
> = ({ label, value, onChange, children, disabled }) => {
  const dispatch = useAppDispatch();

  const typedValue = value as MyRGB | undefined;
  const color = typedValue ? typedValue : { r: 0, g: 0, b: 0, a: 0 };
  const { r, g, b, a } = color;
  const error = isError(color);
  const backgroundColor = `rgba(${r},${g},${b},${a})`;
  const pikerColor = { ...color } as RGBColor;

  if (typeof pikerColor.a === "number" && pikerColor.a > 1)
    pikerColor.a = pikerColor.a / 255;

  return (
    <>
      <InputLabel error={error} shrink>
        {label}
      </InputLabel>

      <HexText
        fullWidth
        backgroundColor={backgroundColor}
        defaultValue={toHex(color)}
        onClick={e =>
          disabled
            ? null
            : dispatch.ctxMenu.handleClick({
                type: ContextMenuTypes.COLOR_PICKER,
                e,
                position: true,
              })
        }
        off
        error={error}
        disabled={disabled}
      />

      <ContextMenu type={ContextMenuTypes.COLOR_PICKER}>
        <Box display="flex" flexDirection="column" px={2} py={1.5}>
          <InputLabel error={error}>{label}</InputLabel>

          <HexText
            backgroundColor={backgroundColor}
            defaultValue={toHex(color)}
            onChange={(_, next) => {
              onChange(next);
            }}
            error={error}
          />

          {children}

          <Box mb={2} mt={1.5}>
            <HuePicker
              onChange={next => {
                onChange({ ...next.rgb, a });
              }}
              color={pikerColor}
            />
          </Box>

          <Box mb={2}>
            <AlphaPicker
              onChange={next => {
                onChange({
                  ...color,
                  a:
                    next.rgb.a !== undefined
                      ? next.rgb.a > 0 && next.rgb.a <= 1
                        ? Math.floor(next.rgb.a * 255)
                        : Math.floor(next.rgb.a * 255)
                      : 255,
                });
              }}
              color={pikerColor}
            />
          </Box>

          <Box display="flex">
            <RGBAText
              label={"R"}
              value={r}
              onChange={e => {
                let next = Number(e.target.value);

                if (isNaN(next) || next > 255 || next < 0) next = 255;

                onChange({ r: !e.target.value ? "" : next, g, b, a });
              }}
              error={error}
            />
            <RGBAText
              label={"G"}
              value={g}
              onChange={e => {
                let next = Number(e.target.value);

                if (isNaN(next) || next > 255 || next < 0) next = 255;

                onChange({ r, g: !e.target.value ? "" : next, b, a });
              }}
              error={error}
            />
            <RGBAText
              label={"B"}
              value={b}
              onChange={e => {
                let next = Number(e.target.value);

                if (isNaN(next) || next > 255 || next < 0) next = 255;

                onChange({ r, g, b: !e.target.value ? "" : next, a });
              }}
              error={error}
            />
            <RGBAText
              label={"A"}
              value={a && !isNaN(Number(a)) ? ((a as number) / 255).toFixed(2) : a}
              onChange={e => {
                let next = Number(e.target.value);

                if (next > 0 && next < 1) next = 255 * next;
                else next = 255;

                onChange({ r, g, b, a: !e.target.value ? "" : next });
              }}
              error={error}
            />
          </Box>
        </Box>
      </ContextMenu>
    </>
  );
};

interface HexTextProps extends Omit<StandardTextFieldProps, "onChange"> {
  backgroundColor: string;
  off?: boolean;
  onChange?: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    value: MyRGB,
  ) => void;
}

const HexText = ({
  backgroundColor,
  off,
  value: pValue,
  defaultValue,
  ...props
}: HexTextProps) => {
  const [value, setValue] = useState(defaultValue || pValue || "");

  useEffect(() => {
    if (
      typeof defaultValue === "string" &&
      (defaultValue.length === 7 || defaultValue.length > 7)
    )
      setValue(defaultValue.substring(0, 7));
  }, [defaultValue]);

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <Box
            width={16}
            height={15}
            sx={{
              backgroundColor,
              borderRadius: "3px",
              mr: 1.5,
            }}
          />
        ),
      }}
      inputProps={{
        style: { textTransform: "uppercase" },
      }}
      sx={off ? { caretColor: "transparent" } : undefined}
      {...props}
      spellCheck={"false"}
      value={value}
      onChange={e => {
        let next = e.target.value;

        if (next[0] !== "#") next = `#${next}`;
        if (next.length > 7) next = next.substring(0, 7);

        if (next.length === 7) {
          const rgba = toRGBA(next);

          if (rgba) {
            props.onChange?.(e, rgba);
          }
        }

        setValue(next);
      }}
    />
  );
};

const RGBAText = (props: TextFieldProps) => {
  return (
    <Box display="flex">
      <Typography display="flex" alignItems="center" color="GrayText">
        {props.label}
      </Typography>
      <TextField
        type="number"
        sx={{ width: "45px", ml: 2, mr: 1.5 }}
        {...props}
        label={undefined}
        inputProps={{ step: "0.1" }}
      />
    </Box>
  );
};

function isError({ r, g, b, a }: MyRGB) {
  return (
    r === undefined ||
    g === undefined ||
    b === undefined ||
    a === undefined ||
    r === "" ||
    g === "" ||
    b === "" ||
    a === "" ||
    isNaN(Number(r)) ||
    isNaN(Number(g)) ||
    isNaN(Number(b)) ||
    isNaN(Number(a))
  );
}

export function toHex(color: MyRGB) {
  if (isError(color)) return "";

  const { r, g, b, a } = color;

  return `#${numToHex(Number(r))}${numToHex(Number(g))}${numToHex(Number(b))}${numToHex(
    Number(a),
  )}`;
}

function numToHex(num: number) {
  return (num | (1 << 8)).toString(16).slice(1);
}

export function toRGBA(color: string): MyRGB | undefined {
  const rgba = color.match(/\w\w/g)?.map(x => parseInt(x, 16));

  if (!rgba) return undefined;

  const [r, g, b, a] = rgba;

  return {
    r: isNaN(r) ? 255 : r,
    g: isNaN(g) ? 255 : g,
    b: isNaN(b) ? 255 : b,
    a: a === undefined ? 255 : a,
  };
}
