import { TemplatesTableData } from "@storeRematch";
import { formatTimeZone } from "@utils/formatTimeZone";
import { DetailItem } from "./DetailItem";

export const TemplateContent: React.FC<{ model: TemplatesTableData }> = ({ model }) => {
  return (
    <>
      <DetailItem
        name="ID"
        content={model.template.id}
        copyTextProps={{ copy: { title: "Template", text: model.template.id } }}
      />
      <DetailItem name="Name" content={model.template.name} />
      <DetailItem
        name="Published"
        content={formatTimeZone(model.template.published_at)}
      />
      <DetailItem name="Recordings" content={model.recordingsCount} />
      <DetailItem name="Created" content={formatTimeZone(model.template.created_at)} />
      <DetailItem name="Updated" content={formatTimeZone(model.template.updated_at)} />
      <DetailItem
        name="Description"
        // @ts-ignore
        content={model.template.description}
      />
    </>
  );
};
