import { Recording } from "@api";
import { VideocamOff, Warning } from "@mui/icons-material";
import {
  Avatar,
  CircularProgress,
  Tooltip,
  circularProgressClasses,
} from "@mui/material";
import { Box } from "@mui/system";

function getContent(recording: Recording) {
  if (recording.preprocessing_error_message) {
    return {
      Content: <Warning color="warning" />,
      text: recording.preprocessing_error_message || "Unknown error",
    };
  }

  if (!recording.sensors?.includes("world")) {
    return {
      Content: <VideocamOff color="action" />,
      text: recording.preprocessing_error_message || "No video",
    };
  }

  if (recording.uploaded_bytes === 0) {
    return {
      Content: <CircularProgress size={16} color="inherit" />,
      text: recording.preprocessing_error_message || "Uploading",
    };
  }

  if (recording.uploaded_bytes !== recording.size) {
    const value =
      recording.size !== undefined && recording.uploaded_bytes !== undefined
        ? (recording.uploaded_bytes / recording.size) * 100
        : undefined;
    const isIndeterminate = !!value && value < 0.1;

    return {
      Content: (
        <CircularProgress
          size={16}
          color="inherit"
          variant={isIndeterminate ? "indeterminate" : "determinate"}
          value={value}
          sx={{
            [`& .${circularProgressClasses.circle}`]: {
              strokeDasharray: "5px, 200px",
            },
          }}
          disableShrink={isIndeterminate}
        />
      ),
      text: value ? `Uploading ${value.toFixed(1)}%` : "Uploading",
    };
  }

  if (recording.is_processed) {
    return {
      Content: null,
      text: recording.preprocessing_error_message,
    };
  }

  if (recording.is_uploaded && !recording.is_processed) {
    return {
      Content: <CircularProgress size={16} />,
      text: recording.preprocessing_error_message || "Processing",
    };
  }

  return { Content: null };
}

export const Thumbnail: React.FC<{ recording: Recording }> = ({ recording }) => {
  const { Content, text } = getContent(recording);

  return (
    <Tooltip title={text}>
      <Box sx={{ position: "relative" }}>
        <Avatar
          sx={{ borderRadius: 1, width: 65, height: 48, backgroundColor: "inherit" }}
          variant="square"
          alt={recording.name}
          src={recording.thumbnail_url}
        >
          {" "}
        </Avatar>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: Content ? "#00000066" : "none",
            borderRadius: 1,
          }}
        >
          {Content}
        </Box>
      </Box>
    </Tooltip>
  );
};
