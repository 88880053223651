import { api, Token } from "@api";
import { createModel } from "@rematch/core";
import { rematchAdapter, RematchAdapter } from "@utils";
import i18n from "../i18n";
import type { RootModel } from "./index";

interface TokenState {
  activeToken: Token | null;
}

interface Adapter extends RematchAdapter<Token>, TokenState {}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.id,
  extraProps: {
    activeToken: null,
  },
});

export const tokens = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
    setActiveToken(state, payload: TokenState["activeToken"]) {
      state.activeToken = payload;
    },
  },
  effects: dispatch => ({
    async get() {
      const data = await api.getTokens();
      dispatch.tokens.setAll(data.result);
    },
    async create(name: string | null | undefined): Promise<void> {
      if (name)
        try {
          const res = await api.postToken({
            tokenPostRequest: { name },
          });

          if (!res.result.token) return;

          dispatch.tokens.upsertOne(res.result);
          dispatch.tokens.setActiveToken(res.result);
          dispatch.notifier.enqueueSnackbar({
            message: i18n.t("New token added"),
            options: {
              variant: "success",
            },
          });
        } catch (error) {
          console.error(error);
        }
    },
    async delete(tokenIds: string[]): Promise<void> {
      try {
        await Promise.all([
          tokenIds.forEach(tokenId => {
            api.deleteToken({ tokenId });
          }),
        ]);

        dispatch.tokens.deleteMany(tokenIds);
        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Token deleted"),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
  }),
});
