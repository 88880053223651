import { ProjectEnrichment } from "@api";
import { BackButton } from "@components";
import { SearchBox } from "@components/Common/SearchBox";
import { ReactRouterLinkCustom } from "@components/ReactRouterLinkCustom";
import { VirtualizedList } from "@components/VirtualizedList";
import { CtxMenuItem, CtxMenuLabel } from "@components/ctxMenu";
import { ModalIds } from "@components/onboarding/OnboardingConfig";
import { AppBar, Link, MenuItem, MenuList, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { RouterHelper } from "@pages";
import { ContextMenuTypes, useAppSelector } from "@storeRematch";
import {
  formatReadableEnrichment,
  sanitizeForRegex,
  useGetEnrichmentStatusIcon,
} from "@utils";
import { ChangeEvent, FC, memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useParams } from "react-router-dom";
import { BreadCrumbsText } from "./BreadCrumbsText";
import { BreadcrumbsPopover } from "./BreadcrumbsPopover";
import { ProjectBreadcrumb } from "./ProjectBreadcrumb";
import { UserControls } from "./UserControls";
import { WorkspaceBreadcrumb } from "./WorkspaceBreadcrumb";

export const AppLayoutBase = () => {
  const fullScreen = useAppSelector(state => state.video.fullScreen);

  if (fullScreen) return null;

  return (
    <AppBar id={ModalIds.DEMO_WORKSPACE_HEADER}>
      <Toolbar variant="dense">
        <BreadcrumbsMenus />
        <Box ml="auto">
          <UserControls />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export const AppLayout = memo(AppLayoutBase);

const BreadcrumbsMenus = () => {
  const params = useParams();
  const location = useLocation();
  const accountSettingsRoute = !!matchPath(
    RouterHelper.accountSettingsLayout.relativeTemplatePathAny,
    location.pathname,
  );
  const projectSettingsRoute = !!matchPath(
    RouterHelper.workspaceSettingsLayout.fullTemplatePathAny,
    location.pathname,
  );
  const workspaceProject = !!matchPath(
    RouterHelper.projectLayout.fullTemplatePathAny,
    location.pathname,
  );
  const workspaceRoot = !!matchPath(
    RouterHelper.workspaceRecordings.fullTemplatePath,
    location.pathname,
  );
  const isAOI = !!matchPath(
    RouterHelper.projectEnrichmentsAOI.fullTemplatePath,
    location.pathname,
  );
  const isRecordingGazeEdit = !!matchPath(
    RouterHelper.workspaceRecordingsEditGazeOffset.fullTemplatePath,
    location.pathname,
  );
  const showProjectBreadcrumbs = !projectSettingsRoute && !accountSettingsRoute;

  if (isAOI) {
    return (
      <BackButton
        text="AOI Editor"
        prev={RouterHelper.projectEnrichmentsEdit.getFullPath({
          props: {
            workspaceId: params.workspaceId,
            projectId: params.projectId,
            enrichmentId: params.enrichmentId,
          },
        })}
      />
    );
  }

  if (isRecordingGazeEdit) {
    return (
      <BackButton
        text="Edit Gaze Offset"
        prev={RouterHelper.workspaceRecordings.getFullPath({
          props: {
            workspaceId: params.workspaceId,
          },
        })}
      />
    );
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="nowrap"
    >
      <WorkspaceBreadcrumb active={workspaceRoot} />
      <BreadcrumbsDivider />
      {showProjectBreadcrumbs && <ProjectBreadcrumb />}
      {projectSettingsRoute && <BreadcrumbsDivider text="Workspace Settings" />}
      {accountSettingsRoute && <BreadcrumbsDivider text="Account Settings" />}
      {workspaceProject && <WorkspaceProjectBreadcrumb />}
    </Box>
  );
};

const BreadcrumbsDivider = ({ text, to }: { text?: string; to?: string }) => {
  if (to) {
    return (
      <Link
        mr={1.5}
        component={ReactRouterLinkCustom}
        to={to}
        sx={{
          borderRadius: 1,
          px: 1,
          py: 0.5,
          color: "text.secondary",
          "&:hover": {
            backgroundColor: "action.hover",
          },
        }}
      >
        <BreadCrumbsText>{text}</BreadCrumbsText>
      </Link>
    );
  }

  return (
    <BreadCrumbsText
      mr={1.5}
      ml={text ? 0 : 1}
      minWidthOff={!text}
      sx={{ color: text ? "inherit" : "text.secondary" }}
    >
      {text || "/"}
    </BreadCrumbsText>
  );
};

const WorkspaceProjectBreadcrumb = () => {
  const location = useLocation();
  const params = useParams();
  const enrichmentId = String(params.enrichmentId);
  const visualizationId = String(params.visualizationId);
  const name = useAppSelector(state =>
    enrichmentId ? state.enrichments.dataById.get(enrichmentId)?.name : undefined,
  );
  const vizName = useAppSelector(state =>
    enrichmentId ? state.visualizations.dataById.get(visualizationId)?.name : undefined,
  );

  const isRecordings = !!matchPath(
    RouterHelper.projectRecordings.fullTemplatePath,
    location.pathname,
  );
  const isDownloads = !!matchPath(
    RouterHelper.projectDownloads.fullTemplatePath,
    location.pathname,
  );
  const isVisualizations = !!matchPath(
    RouterHelper.projectVisualizations.fullTemplatePathAny,
    location.pathname,
  );
  const isHeatmap = !!matchPath(
    RouterHelper.projectVisualizationsHeatmap.fullTemplatePathAny,
    location.pathname,
  );
  const isRender = !!matchPath(
    RouterHelper.projectVisualizationsRender.fullTemplatePathAny,
    location.pathname,
  );
  const isVisualizationsAoi = !!matchPath(
    RouterHelper.projectVisualizationsAoi.fullTemplatePathAny,
    location.pathname,
  );
  const isEnrichments = !!matchPath(
    RouterHelper.projectEnrichments.fullTemplatePath,
    location.pathname,
  );
  const isEnrichmentEdit = !!matchPath(
    RouterHelper.projectEnrichmentsEdit.fullTemplatePath,
    location.pathname,
  );

  return (
    <>
      <BreadcrumbsDivider />
      {isRecordings && <BreadcrumbsDivider text="Recordings" />}
      {isEnrichments && <BreadcrumbsDivider text="Enrichments" />}
      {isDownloads && <BreadcrumbsDivider text="Downloads" />}
      {isVisualizations && (
        <BreadcrumbsDivider
          text="Visualizations"
          to={
            isHeatmap || isRender || isVisualizationsAoi
              ? RouterHelper.projectVisualizations.getFullPath({
                  props: {
                    workspaceId: params.workspaceId,
                    projectId: params.projectId,
                  },
                })
              : undefined
          }
        />
      )}

      {isEnrichmentEdit && name ? <EnrichmentsPicker text={name} /> : null}

      {(isHeatmap || isVisualizationsAoi) && (
        <>
          <BreadcrumbsDivider />
          <BreadcrumbsDivider text={vizName || "..."} />
        </>
      )}
      {isRender && (
        <>
          <BreadcrumbsDivider />
          <BreadcrumbsDivider text={name || "..."} />
        </>
      )}
    </>
  );
};
const filterType = ContextMenuTypes.ENRICHMENT_SELECT;
const EnrichmentsPicker = ({ text }: { text: string }) => {
  return (
    <BreadcrumbsPopover text={text} filterType={filterType}>
      <MenuList>
        <CtxMenuLabel onKeyDown={e => e.stopPropagation()} sx={{ m: 0 }}>
          <EnrichmentsAsList filterType={filterType} />
        </CtxMenuLabel>
      </MenuList>
    </BreadcrumbsPopover>
  );
};

interface ProjectsAsListProps {
  filterType: ContextMenuTypes;
}

const EnrichmentsAsList: FC<ProjectsAsListProps> = ({ filterType }) => {
  const { workspaceId, projectId, enrichmentId } = useParams();
  const { t } = useTranslation();
  const enrichments = useAppSelector(state => state.enrichments.data);
  const sorted = useMemo(() => {
    return [...enrichments]
      .filter(o => o.kind !== "render")
      .sort((a, b) =>
        a.created_at && b.created_at
          ? new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          : 0,
      );
  }, [enrichments]);
  const closed = useAppSelector(state => !state.ctxMenu.open[filterType]);
  const [found, setFound] = useState(sorted);

  useEffect(() => {
    if (closed) {
      setFound(sorted);
    }
  }, [closed, sorted]);

  useEffect(() => {
    if (sorted) {
      setFound(sorted);
    }
  }, [sorted]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const next = sorted.filter(project =>
      project.name.match(new RegExp(sanitizeForRegex(e.currentTarget.value), "i")),
    );
    setFound(next);
  };

  return (
    <>
      <CtxMenuLabel>
        <SearchBox
          sx={{ width: "250px" }}
          name="Projects settings search"
          onChange={handleSearch}
          onKeyDown={e => e.stopPropagation()}
          onClear={() => setFound(sorted)}
        />
      </CtxMenuLabel>

      {!found.length && <CtxMenuItem label={t("No projects")} disabled />}

      <Box height={found.length * 50} maxHeight="40vh">
        <VirtualizedList
          itemData={found}
          itemKey={(index, data) => data[index].id}
          itemSize={50}
          renderRow={({ index, style, data }) => {
            const model = data[index];
            const checked = enrichmentId === model.id;

            return (
              <MenuItem
                key={index}
                style={style}
                component={ReactRouterLinkCustom}
                to={RouterHelper.projectEnrichmentsEdit.getFullPath({
                  props: {
                    workspaceId,
                    projectId,
                    enrichmentId: model.id,
                  },
                })}
                sx={{
                  backgroundColor: checked ? "action.selected" : "inherit",
                  "&:hover": {
                    backgroundColor: checked ? "action.selected" : undefined,
                  },
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    noWrap
                    maxWidth={200}
                    sx={{
                      textTransform: "inherit",
                      color: checked ? "text.primary" : "text.secondary",
                    }}
                  >
                    {model.name}
                  </Typography>
                  <Typography
                    noWrap
                    maxWidth={200}
                    sx={{ textTransform: "inherit", color: "text.secondary" }}
                    variant="body2"
                  >
                    {formatReadableEnrichment(model.kind)}
                  </Typography>
                </Box>
                <EnrichmentStatus model={model} />
              </MenuItem>
            );
          }}
        />
      </Box>
    </>
  );
};

const EnrichmentStatus = ({ model }: { model: ProjectEnrichment }) => {
  const status = useGetEnrichmentStatusIcon(model, true);

  return <Box sx={{ ml: "auto" }}>{status}</Box>;
};
