import { Button, ButtonProps } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { useFormState } from "react-hook-form";

export const CancelButton: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...props
}) => {
  const { isSubmitting } = useFormState();

  return (
    <Button {...props} disabled={isSubmitting} variant="text" color="primary">
      {children || "Cancel"}
    </Button>
  );
};
