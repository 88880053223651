import { Wearer } from "@api";
import { FormDialog, TextField } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "@storeRematch";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";

export interface WearerFormProps {
  open: boolean;
  onClose(): void;
  wearer?: Wearer;
}

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
  })
  .required();

export const WearerForm: React.FC<WearerFormProps> = ({
  open,
  onClose,
  wearer = undefined,
}) => {
  const params = useParams();
  const workspaceId = String(params.workspaceId);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleSubmit = async (formData: Wearer) => {
    const payload = {
      id: formData.id,
      name: formData.name,
    };

    try {
      if (formData.id) {
        await dispatch.wearers.update({
          workspaceId: workspaceId,
          wearerId: formData.id,
          wearerPatchRequest: payload,
        });
      } else {
        await dispatch.wearers.create({
          workspaceId: workspaceId,
          wearerPostRequest: payload,
        });
      }

      const message = wearer ? t("Wearer updated") : t("Wearer created");
      dispatch.notifier.enqueueSnackbar({
        message,
        options: {
          variant: "success",
        },
      });
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormDialog
      open={open}
      title={wearer ? t("Edit Wearer") : t("Add Wearer")}
      onFormSubmit={handleSubmit}
      onClose={onClose}
      useFormProps={{
        defaultValues: wearer || { name: "" },
        resolver: yupResolver(schema),
      }}
      create={!wearer}
    >
      <TextField name="name" label={t("Wearer Name")} autoFocus fullWidth />
    </FormDialog>
  );
};
