import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const VolumeDown: FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.80594 12.8593C7.93447 12.9543 8.09011 13.0057 8.25 13.0058V13.0052C8.44891 13.0052 8.63968 12.9262 8.78033 12.7855C8.92098 12.6449 9 12.4541 9 12.2552V3.74457C9.00008 3.60581 8.96166 3.46975 8.88902 3.35152C8.81639 3.2333 8.71237 3.13754 8.58856 3.0749C8.46474 3.01227 8.32598 2.98521 8.1877 2.99673C8.04942 3.00826 7.91705 3.05792 7.80531 3.14019L7.78469 3.15613L4.92656 5.49988H2.75C2.55109 5.49988 2.36032 5.5789 2.21967 5.71955C2.07902 5.8602 2 6.05097 2 6.24988V9.74957C2 9.94848 2.07902 10.1392 2.21967 10.2799C2.36032 10.4206 2.55109 10.4996 2.75 10.4996H4.92719L7.78531 12.8433L7.80594 12.8593ZM10.7564 10.4364C10.8309 10.478 10.9147 10.4998 11 10.4999C11.0927 10.4999 11.1836 10.4741 11.2625 10.4254C11.3414 10.3768 11.4053 10.3071 11.4469 10.2243C11.8125 9.49301 12 8.74457 12 7.99988C12 7.23332 11.8188 6.50488 11.4456 5.77269C11.384 5.65707 11.2794 5.57028 11.1544 5.53099C11.0294 5.49169 10.8939 5.50304 10.7772 5.56259C10.6605 5.62214 10.5718 5.72513 10.5303 5.8494C10.4887 5.97367 10.4976 6.10928 10.555 6.22707C10.8547 6.81488 11.0003 7.39488 11.0003 7.99988C11.0003 8.58801 10.85 9.18488 10.5534 9.77551C10.5152 9.85172 10.497 9.93645 10.5007 10.0216C10.5045 10.1068 10.5299 10.1897 10.5746 10.2623C10.6194 10.3349 10.682 10.3948 10.7564 10.4364ZM12.2503 11.9329C12.3263 11.9767 12.4126 11.9998 12.5004 11.9997C12.5883 11.9998 12.6747 11.9768 12.7508 11.9329C12.827 11.889 12.8902 11.8258 12.9342 11.7497C13.5923 10.6009 14.0004 9.57345 14.0004 7.9997C14.0004 6.4022 13.5917 5.3797 12.9326 4.2497C12.8636 4.1401 12.7548 4.06153 12.6291 4.03054C12.5034 3.99956 12.3705 4.01856 12.2585 4.08355C12.1465 4.14854 12.0641 4.25446 12.0286 4.379C11.9931 4.50354 12.0073 4.63699 12.0682 4.75126C12.6586 5.7647 13.0004 6.62095 13.0004 7.9997C13.0004 9.35751 12.6567 10.22 12.067 11.2497C12.0231 11.3257 12 11.412 12 11.4998C12 11.5876 12.0232 11.6739 12.0671 11.7499C12.111 11.8259 12.1742 11.889 12.2503 11.9329Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
