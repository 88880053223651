import { api, WorkspaceInvitation, WorkspaceMember, WorkspaceRoles } from "@api";
import { createModel } from "@rematch/core";
import { rematchAdapter, RematchAdapter } from "@utils";
import i18n from "../i18n";
import type { RootModel } from "./index";

interface Adapter extends RematchAdapter<WorkspaceMember> {}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.user_id,
  extraProps: {},
});

export const members = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
  },
  effects: dispatch => ({
    prepare() {},
    async get(workspaceId: string) {
      const data = await api.getWorkspaceMembers({ workspaceId });

      dispatch.members.setAll(data.result);
    },
    async removeMembers(ids: string[], state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;

      if (!workspaceId) return;

      try {
        const members: WorkspaceMember[] = [];
        const invites: WorkspaceInvitation[] = [];

        const dataByIdMembers = state.members.dataById;
        const dataByIdInvites = state.invites.dataById;

        for (const id of ids) {
          const user = dataByIdMembers.get(id);
          if (user) members.push(user);

          const invite = dataByIdInvites.get(id);
          if (invite) invites.push(invite);
        }

        await Promise.all(
          members.map(({ user_id }) =>
            api.deleteWorkspaceMember({ workspaceId, userId: user_id }),
          ),
        );
        await Promise.all(
          invites.map(({ token }) =>
            // @ts-ignore
            api.deleteWorkspaceInvitation({ workspaceId, token }),
          ),
        );

        dispatch.members.deleteMany(members.map(m => m.user_id));
        dispatch.invites.deleteMany(invites.map(i => i.id));

        const message = ids.length
          ? i18n.t("Members deleted").toString()
          : i18n.t("Member deleted").toString();
        dispatch.notifier.enqueueSnackbar({
          message,
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async changeRole({ userId, role }: { userId: string; role: string }, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;

      if (!workspaceId) return;

      try {
        const res = await api.patchWorkspaceMember({
          workspaceId,
          userId: userId,
          workspaceMemberPatchRequest: { role: role as WorkspaceRoles },
        });

        dispatch.members.upsertOne(res.result);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Role updated").toString(),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Error updating role").toString(),
          options: {
            variant: "success",
          },
        });
      }
    },
  }),
});
