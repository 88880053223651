import { InputLabel, Slider, TextField } from "@mui/material";
import { Box } from "@mui/system";

export const SliderTextField = ({
  label,
  min = 20,
  max = 120,
  value,
  disabled,
  onChange,
}: {
  label: string;
  min?: number;
  max?: number;
  value: any;
  disabled?: boolean;
  onChange: (value: number) => void;
}) => {
  const numValue = Number(value);
  const testedValue =
    !isNaN(numValue) && numValue >= min && numValue <= max ? numValue : undefined;

  const isError = testedValue === undefined;

  return (
    <>
      <InputLabel error={isError} sx={{ fontSize: "0.75rem" }}>
        {label}
      </InputLabel>
      <Box display="flex" mt={1.5}>
        <Slider
          size="small"
          value={testedValue === undefined ? 0 : testedValue}
          onChange={(_, value) => {
            onChange(Number(value));
          }}
          min={min}
          max={max}
          disabled={disabled}
        />
        <TextField
          sx={{ width: "60px", ml: 2 }}
          type="number"
          value={testedValue === undefined ? value : testedValue}
          onChange={e => {
            onChange(Number(e.target.value));
          }}
          error={isError}
          disabled={disabled}
        />
      </Box>
    </>
  );
};
