import {
  EnrichmentTypesEnum,
  ProjectVisualizationPatchRequest,
  ProjectVisualizationPostRequest,
  VisualizationTypes,
  Visualizations,
  api,
} from "@api";
import { createModel } from "@rematch/core";
import { RematchAdapter, rematchAdapter } from "@utils";
import { AOI_HEATMAP_TYPES, RootModel, VisualizationsTableData } from "./index";

interface ExtraProps {
  currentVisualization?: Visualizations | null;
  aoiIdsToAutoAdd: string[];
}

interface Adapter
  extends RematchAdapter<Visualizations, VisualizationsTableData>,
    ExtraProps {}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.id,
  extraProps: {
    currentVisualization: null,
    aoiIdsToAutoAdd: [],
  },
});

export const visualizations = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
    setCurrentVisualization(state, data: Adapter["currentVisualization"]) {
      state.currentVisualization = data;
    },
    resetAoiIdsToAutoAdd(state) {
      state.aoiIdsToAutoAdd = [];
    },
    addAoiIdsToAutoAdd(state, data: string) {
      state.aoiIdsToAutoAdd.push(data);
    },
    removeAoiIdsToAutoAdd(state, data: string) {
      state.aoiIdsToAutoAdd = state.aoiIdsToAutoAdd.filter(one => one !== data);
    },
  },
  effects: dispatch => ({
    prepare(_, state) {
      const enrichments = state.enrichments.data;
      const enrichmentsById = state.enrichments.dataById;
      const visualizations = state.visualizations.data;
      const tableData: Adapter["tableData"] = [];
      const tableDataById: Adapter["tableDataById"] = new Map();

      for (const enrichment of enrichments) {
        if (enrichment.kind === EnrichmentTypesEnum.RENDER) {
          const next: VisualizationsTableData = {
            id: enrichment.id,
            visualization: {
              id: enrichment.id,
              updated_at: enrichment.updated_at,
              created_at: enrichment.created_at,
              name: enrichment.name,
              kind: VisualizationTypes.RENDER,
              enrichment_id: enrichment.id,
              payload: enrichment,
            },
            enrichment,
          };

          tableData.push(next);
          tableDataById.set(next.id, next);
        }
      }

      for (const viz of visualizations) {
        const next: VisualizationsTableData = {
          id: viz.id,
          visualization: viz,
        };

        if (viz.enrichment_id) {
          next.enrichment = enrichmentsById.get(viz.enrichment_id);
        }

        tableData.push(next);
        tableDataById.set(next.id, next);
      }

      dispatch.visualizations.setTableData(tableData);
      dispatch.visualizations.setTableDataById(tableDataById);
    },
    async get(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;

      if (!workspaceId || !projectId) return;

      try {
        const data = await api.getProjectVisualizations({ workspaceId, projectId });

        dispatch.visualizations.setAll(data.result);
        dispatch.visualizations.prepare(null);
      } catch (error) {
        console.error(error);
      }
    },
    async create(
      { type, enrichmentId }: { type: VisualizationTypes; enrichmentId: string },
      state,
    ) {
      dispatch.visualizations.resetAoiIdsToAutoAdd();

      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;

      if (!workspaceId || !projectId) return;

      try {
        const next: Omit<Visualizations, "id"> = {
          name: "Untitled",
          kind: type,
          payload: {} as any,
          enrichment_id: enrichmentId,
        };

        const recordingIdsSet =
          state.enrichments.enrichmentsWithRecordings.get(enrichmentId);
        const recordingIds = recordingIdsSet ? Array.from(recordingIdsSet) : [];

        if (type === VisualizationTypes.AOI_HEATMAP) {
          const aois = await api.getProjectEnrichmentsAois({
            workspaceId,
            projectId,
            enrichmentId,
          });

          next.payload = {
            color_map: "Turbo",
            metrics: AOI_HEATMAP_TYPES.TOTAL_FIXATION_DURATION,
            recording_ids: recordingIds,
            aoi_ids: aois.result.map(({ id }) => id),
            show_names: true,
            show_metric_values: true,
          };
        } else {
          next.payload = {
            color_map: "Turbo",
            scale: 5,
            recording_ids: recordingIds,
          };
        }

        const data = await api.postProjectVisualizations({
          workspaceId,
          projectId,
          projectVisualizationPostRequest: next as ProjectVisualizationPostRequest,
        });

        dispatch.visualizations.upsertOne(data.result);
        dispatch.visualizations.prepare(null);

        return data.result;
      } catch (error) {
        console.error(error);
      }
    },
    async update(
      data: Pick<Visualizations, "id" | "name" | "enrichment_id" | "payload">,
      state,
    ) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const model = state.visualizations.dataById.get(data.id);

      if (!workspaceId || !projectId || !model) return;

      try {
        dispatch.visualizations.prepare(null);

        await api.patchProjectVisualizations({
          workspaceId,
          projectId,
          visualizationId: data.id,
          projectVisualizationPatchRequest: {
            ...model,
            ...data,
          } as ProjectVisualizationPatchRequest,
        });
      } catch (error) {
        console.error(error);
      }
    },
    async delete(ids: string[], state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;

      if (!workspaceId || !projectId) return;

      try {
        dispatch.visualizations.deleteMany(ids);
        dispatch.visualizations.prepare(null);

        await Promise.all(
          ids.map(id =>
            api.deleteProjectVisualizations({
              workspaceId,
              projectId,
              visualizationId: id,
            }),
          ),
        );
      } catch (error) {
        console.error(error);
      }
    },
    tryToRemoveAoi(id: string, state) {
      const data = state.visualizations.data;
      const next: Visualizations[] = [];
      let prepare = false;

      for (const one of data) {
        if (one.kind !== VisualizationTypes.AOI_HEATMAP) {
          next.push(one);
          continue;
        }

        const nextAoiIds = one.payload.aoi_ids.filter(i => i !== id);

        if (nextAoiIds.length === one.payload.aoi_ids.length) {
          next.push(one);
          continue;
        }

        prepare = true;
        next.push({
          ...one,
          updated_at: new Date().toISOString(),
          payload: { ...one.payload, aoi_ids: nextAoiIds },
        });
      }

      if (!prepare) return;

      dispatch.visualizations.setAll(next);
      dispatch.visualizations.prepare(null);
    },
    tryToRemoveRecordings(ids: string[] | undefined, state) {
      if (!ids) return;

      const data = state.visualizations.data;
      const next: Visualizations[] = [];
      let prepare = false;

      for (const one of data) {
        if (
          one.kind !== VisualizationTypes.AOI_HEATMAP &&
          one.kind !== VisualizationTypes.HEATMAP
        ) {
          next.push(one);
          continue;
        }

        const nextRecordingIds = one.payload.recording_ids.filter(
          i => !ids.includes(i),
        );

        if (nextRecordingIds.length === one.payload.recording_ids.length) {
          next.push(one);
          continue;
        }

        prepare = true;
        next.push({
          ...one,
          updated_at: new Date().toISOString(),
          payload: { ...one.payload, recording_ids: nextRecordingIds },
        } as Visualizations);
      }

      if (!prepare) return;

      dispatch.visualizations.setAll(next);
      dispatch.visualizations.prepare(null);
    },
    tryToAutoAddAois(model: Visualizations | undefined, state) {
      if (!model) {
        dispatch.visualizations.setCurrentVisualization(null);
        return;
      }

      if (model.kind !== VisualizationTypes.AOI_HEATMAP) return;

      const aoiIds = new Set([
        ...model.payload.aoi_ids,
        ...state.visualizations.aoiIdsToAutoAdd,
      ]);

      dispatch.visualizations.resetAoiIdsToAutoAdd();
      dispatch.visualizations.setCurrentVisualization({
        ...model,
        payload: { ...model.payload, aoi_ids: Array.from(aoiIds) },
      });
    },
  }),
});
