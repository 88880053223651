import * as d3 from "d3";

export const OVERLAY_ID = "video_overlay";

export function selectOverlay() {
  return d3.select(`#${OVERLAY_ID}`);
}

export function drawScanPath({
  ctx,
  x,
  y,
  radius,
  text,
  strokeStyleBlur = true,
  lineWidth = 2,
  offsetTextX = 0,
  offsetTextY = 15,
  fillCircle = false,
}: {
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  radius: number;
  text: string;
  strokeStyleBlur?: boolean;
  lineWidth?: number;
  offsetTextX?: number;
  offsetTextY?: number;
  fillCircle?: boolean;
}) {
  const color = strokeStyleBlur ? "#1263CC" : "#1263CCB3";
  const plConfig = getPlConfig();

  ctx.beginPath();
  ctx.arc(
    x,
    y,
    plConfig?.scanpath?.radius ? plConfig.scanpath.radius : radius,
    0,
    2 * Math.PI,
    false,
  );
  ctx.strokeStyle = color;

  ctx.lineWidth = plConfig?.scanpath?.lineWidth
    ? plConfig.scanpath.lineWidth
    : lineWidth;
  ctx.stroke();

  if (fillCircle) {
    ctx.fillStyle = color;
    ctx.fill();
  }

  ctx.closePath();

  ctx.beginPath();
  ctx.font = "14px Inter";
  ctx.fillStyle = "#fff";
  ctx.shadowColor = "#000000";
  ctx.shadowBlur = 3;
  ctx.fillText(text, x + offsetTextX, y + offsetTextY);
  ctx.fill();
  ctx.closePath();
}

// for deign
// ex.
// add for scanpath - window.plConfig = { scanpath: { lineWidth: 10, radius: 20 } }
// add for point cloud - window.plConfig = { pointCloud: { radius: 20 } }
// add for gaze - window.plConfig = { gaze: { lineWidth: 20, opacity: 1 } }
// remove - window.plConfig = undefined
interface PLConfig {
  scanpath?: { lineWidth: number; radius: number };
  pointCloud?: { radius: number };
  gaze?: { lineWidth: number; opacity?: number };
}

export function getPlConfig() {
  // @ts-ignore
  const config = window.plConfig as PLConfig | undefined;

  return config;
}
