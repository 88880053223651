import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { useTranslation } from "react-i18next";

export const TemplatePreview = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const template = useAppSelector(state => state.templateForm.showPreview);
  const recording = useAppSelector(state =>
    state.recordings.dataById.get(state.entityTable.selectedIds[0]),
  );

  if (!template) return null;

  return (
    <>
      <Dialog open maxWidth="sm" fullWidth>
        <DialogTitle>{template.name}</DialogTitle>
        <DialogContent>
          {template.items?.map((one, index) => {
            const value = recording?.template_data?.[one.id];

            return (
              <Box key={one.id} width="100%">
                {one.widget_type === "SECTION_HEADER" && (
                  <>
                    <Typography
                      component="div"
                      variant="h6"
                      mt={index === 0 ? 1 : 3}
                      mb={1}
                    >
                      {one.title}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                  </>
                )}

                {one.widget_type === "TEXT" && (
                  <Box mb={3}>
                    <InputLabel required={one.required} sx={{ mb: 1 }}>
                      {one.title}
                    </InputLabel>
                    <TextField
                      disabled
                      fullWidth
                      placeholder={one.help_text}
                      value={value?.[0]}
                      type={one.input_type === "any" ? "text" : "number"}
                    />
                  </Box>
                )}

                {one.widget_type === "PARAGRAPH" && (
                  <Box mb={3}>
                    <InputLabel required={one.required} sx={{ mb: 1 }}>
                      {one.title}
                    </InputLabel>
                    <TextField
                      disabled
                      fullWidth
                      multiline
                      minRows={3}
                      placeholder={one.help_text}
                      value={value?.[0]}
                    />
                  </Box>
                )}

                {one.widget_type === "RADIO_LIST" ? (
                  <Box mb={3}>
                    <InputLabel required={one.required} sx={{ mb: 1 }}>
                      {one.title}
                    </InputLabel>
                    <RadioGroup>
                      {one.choices?.map(name => (
                        <FormControlLabel
                          key={name}
                          label={name}
                          control={<Radio />}
                          disabled
                          checked={value?.[0] === name}
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                ) : null}

                {one.widget_type === "CHECKBOX_LIST" && (
                  <Box mb={3}>
                    <InputLabel required={one.required} sx={{ mb: 1 }}>
                      {one.title}
                    </InputLabel>
                    <FormGroup>
                      {one.choices?.map(name => (
                        <FormControlLabel
                          key={name}
                          label={name}
                          control={
                            <Checkbox
                              checked={value ? !!value.find(x => x === name) : false}
                              disabled
                              name={name}
                            />
                          }
                        />
                      ))}
                    </FormGroup>
                  </Box>
                )}
              </Box>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dispatch.templateForm.setShowPreview(null)}>
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
