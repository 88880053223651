import { SvgIcon, SvgIconProps } from "@mui/material";

export const EditAoiLink: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="20"
      height="19"
      viewBox="0 0 20 19"
      {...props}
      sx={{ fill: "none", ...props.sx }}
    >
      <rect x="5.5" y="4.5" width="10" height="10" rx="1.5" stroke="currentColor" />
      <rect width="6" height="6" transform="translate(11 3)" fill="#1C1C1F" />
      <path
        d="M17.8667 3.77778C18.0444 3.6 18.0444 3.33333 17.8667 3.15556L16.8444 2.13333C16.6667 1.95556 16.4 1.95556 16.2222 2.13333L15.4222 2.93333L17.1111 4.62222M10 8.31111V10H11.6889L16.5778 5.06667L14.9333 3.37778L10 8.31111Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
