import { ReactRouterLinkCustom } from "@components";
import { EditAoiLink } from "@customIcons";
import { Button, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { RouterHelper } from "@pages";
import { useAppSelector } from "@storeRematch/index";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const LinkToAoiEdit = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { workspaceId, projectId, visualizationId, enrichmentId } = useParams();
  const { t } = useTranslation();
  const id =
    useAppSelector(
      state => state.visualizations.dataById.get(visualizationId || "")?.enrichment_id,
    ) ||
    enrichmentId ||
    "";

  const noUrl = useAppSelector(
    state =>
      !state.projectEdit.currentImage &&
      !state.projectEdit.currentMarkerLess &&
      !state.projectEdit.markerMapperImgUrl &&
      !state.aoiTool.imageUrl,
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 1,
        width: "100%",
      }}
    >
      <Typography variant="subtitle2">{t("Areas of Interest")}</Typography>

      <Tooltip title={noUrl ? t("Reference image is not available") : undefined}>
        <span>
          <Button
            variant="text"
            color="primary"
            size="small"
            component={ReactRouterLinkCustom}
            to={RouterHelper.projectEnrichmentsAOI.getFullPath({
              prefix: "/",
              props: { workspaceId, projectId, enrichmentId: id },
            })}
            disabled={isDisabled || !id || noUrl}
            startIcon={<EditAoiLink />}
          >
            {t("Edit AOIs")}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};
