import { ReactRouterLinkCustom } from "@components/ReactRouterLinkCustom";
import { ListItemText, MenuItem, MenuList, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

interface LinkMenuListProps {
  links: {
    to: string;
    name: string;
    secondary?: any;
    disabled?: boolean;
  }[];
}

export const LinkMenuList: React.FC<LinkMenuListProps> = ({ links }) => {
  const location = useLocation();

  return (
    <MenuList>
      {links.map((link, i) => {
        // Careful when changing, used to detect the correct page
        const end = link.to || "settings";
        const match = location.pathname.endsWith(end);

        return (
          <MenuItem
            key={i}
            disabled={link.disabled}
            component={ReactRouterLinkCustom}
            to={link.to}
            sx={{ backgroundColor: !match ? "inherit" : "action.hover" }}
          >
            <ListItemText primary={link.name} />
            {link.secondary ? (
              <Typography variant="body2" color="text.secondary">
                {link.secondary}
              </Typography>
            ) : null}
          </MenuItem>
        );
      })}
    </MenuList>
  );
};
