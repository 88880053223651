import { ContextMenuTypes, store } from "@storeRematch";
import { MouseEvent } from "react";

export function ctxMenuAwaitClickAway(data: {
  e: MouseEvent;
  type: ContextMenuTypes;
  position?: boolean;
}) {
  const currentTarget = data.e.currentTarget;
  setTimeout(() => {
    data.e.currentTarget = currentTarget;
    store.dispatch.ctxMenu.handleClick(data);
  });
}
