import { Add } from "@mui/icons-material";
import { Box } from "@mui/system";
import { memo } from "react";
import { FilterContent } from "./FilterContent";
import { FilterPopover } from "./FilterPopover";

function FilterBase() {
  return (
    <Box mr={1} mb={1.5}>
      <FilterPopover text="Add Filter" startIcon={<Add />}>
        <FilterContent />
      </FilterPopover>
    </Box>
  );
}

export const Filter = memo(FilterBase);
