import { NewProjectModelDialogMainButton } from "@components/NewProjectModelDialog";
import { Typography } from "@mui/material";
import { memo } from "react";

export const NoEnrichmentsData = memo(() => {
  return (
    <>
      <Typography sx={{ textAlign: "center", mb: 2 }}>
        Ready to enrich your data?
      </Typography>
      <NewProjectModelDialogMainButton />
    </>
  );
});
