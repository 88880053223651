import { store } from "@storeRematch/index";

function move(ref = store.getState().video.currentLinePosTrackerRef) {
  ref?.scrollIntoView({
    inline: "center",
    block: "nearest",
  });
}

export function moveZoomHorizontalScroll(
  timeout?: boolean,
  ref?: HTMLDivElement | null,
) {
  if (!timeout) return move(ref);

  setTimeout(() => {
    move();
    store.dispatch.video.toggleZoomOffsetUpdateCurrentPosition();
  }, 120);
}

export let jumpedToEvent = false;
export function setJumpedToEvent(next: boolean) {
  jumpedToEvent = next;
}
