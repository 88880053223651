import { QuestionDialogTypes, useAppDispatch, useAppSelector } from "@storeRematch";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AccountDelete = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userId = useAppSelector(state => state.app.apiUser?.id);
  const isLoading = useAppSelector(
    state =>
      state.loading.effects.app.deleteAccount.loading ||
      state.loading.effects.app.logout.loading,
  );

  useEffect(() => {
    if (userId && !isLoading) {
      dispatch.questionDialog.set({
        type: QuestionDialogTypes.CONFIRM_DELETE,
        onSuccess: async () => {
          await dispatch.app.deleteAccount();
          await dispatch.app.logout();
        },
        extra: { title: "Account" },
      });
    }
  }, [isLoading, dispatch, navigate, userId]);

  return null;
};
