import { EnrichmentTypesEnum, Recording, RecordingFile } from "@api";
import { UploadButton } from "@components/UploadButton";
import { CircularProgress, Paper, Typography, useTheme } from "@mui/material";
import { Box, alpha } from "@mui/system";
import { RouterHelper } from "@pages";
import { ContextMenuTypes, store, useAppDispatch, useAppSelector } from "@storeRematch";
import {
  DisableByPermissionsType,
  calculateAspectRatioFit,
  disabledByPermission,
  isEnrichmentFormDisabled,
  isStaticImageMapperPointerCanvasDisabled,
} from "@utils";
import { FC, memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useParams } from "react-router-dom";
import { VideoImageContextMenu } from "./VideoImageContextMenu";
import { VideoOverlay } from "./VideoOverlay";
import { overlayControl, staticImageMapperOverlay } from "./controller";

export const VideoWrapper = ({ recording }: { recording?: Recording }) => {
  const ref = useRef<HTMLVideoElement | null>(null);
  const dispatch = useAppDispatch();
  const size = useAppSelector(state =>
    state.video.fullScreen
      ? state.dragger.videoFullScreen.size
      : state.dragger.video.size,
  );
  const show = useAppSelector(state =>
    state.video.hideVideoPlayer
      ? false
      : document.URL.includes("projects")
      ? state.projectEdit.recordings.length > 0
      : state.recordings.data.length > 0,
  );
  const enrichmentLoading = useAppSelector(
    state =>
      state.loading.effects.enrichments.get.success &&
      state.loading.effects.uniqEvents.get.success &&
      state.loading.effects.projectEvents.get.success,
  );

  useEffect(() => {
    if (
      ref.current &&
      recording &&
      (enrichmentLoading ||
        RouterHelper.workspaceRecordings.matchCurrentPath() ||
        RouterHelper.workspaceRecordingsEditGazeOffset.matchCurrentPath())
    ) {
      dispatch.video.init({ element: ref.current });
      dispatch.video.changeSrc(recording);
    }
  }, [enrichmentLoading, recording, dispatch]);

  useEffect(() => {
    return () => {
      dispatch.video.dispose();
      dispatch.video.setRecording(null);
    };
  }, [dispatch]);

  return (
    <Box
      display="flex"
      flexBasis="100%"
      width="100%"
      minHeight={size}
      maxHeight={size}
      sx={{ backgroundColor: "#000" }}
    >
      <Box
        position="relative"
        display="flex"
        flexBasis="100%"
        width="100%"
        sx={{ opacity: show ? 1 : 0 }}
      >
        <video
          ref={ref}
          className="video-js vjs-default-skin"
          style={{ margin: "auto" }}
        />
        <VideoOverlay />
      </Box>
      <VideoImagePreview />
      <VideoImageContextMenu />
    </Box>
  );
};

function resizeImage(imgRoot: HTMLDivElement | null, img: HTMLImageElement) {
  if (!imgRoot) return;

  const { width, height } = calculateAspectRatioFit(
    img.naturalWidth,
    img.naturalHeight,
    imgRoot.offsetWidth,
    imgRoot.offsetHeight,
  );

  img.style.width = `${width}px`;
  img.style.height = `${height}px`;

  updateDimensionsOverReference(width, height);
}

function updateDimensionsOverReference(w: number, h: number) {
  const dimW = `${w}px`;
  const dimH = `${h}px`;
  const ratio = window.devicePixelRatio;
  const scaledW = w * ratio;
  const scaledH = h * ratio;

  overlayControl.models.forEach(m => {
    if (!m.canvasOverReference) return;

    const can = m.getCanvas();

    if (can) {
      can.style.width = dimW;
      can.style.height = dimH;

      can.width = scaledW;
      can.height = scaledH;
      can.getContext("2d")?.scale(ratio, ratio);
    }
  });

  overlayControl.rerender();

  if (
    staticImageMapperCanvasRef &&
    staticImageMapperCanvasRef.width > 0 &&
    staticImageMapperCanvasRef.height > 0
  ) {
    const temp = staticImageMapperCanvasRef
      .getContext("2d", { willReadFrequently: true })
      ?.getImageData(
        0,
        0,
        staticImageMapperCanvasRef.width,
        staticImageMapperCanvasRef.height,
      );
    staticImageMapperCanvasRef.style.width = dimW;
    staticImageMapperCanvasRef.style.height = dimH;

    staticImageMapperCanvasRef.width = scaledW;
    staticImageMapperCanvasRef.height = scaledH;
    staticImageMapperCanvasRef.getContext("2d")?.scale(ratio, ratio);

    if (temp) {
      staticImageMapperCanvasRef.getContext("2d")?.putImageData(temp, 0, 0);
    }
  }
}

let setDelayMapperBorderFlagTimeout: any = null;
const VideoImagePreview = () => {
  const { t } = useTranslation();
  const imgRoot = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const params = useParams();
  const theme = useTheme();
  const enrichmentId = String(params.enrichmentId);
  const isEnrichment = !!matchPath(
    RouterHelper.projectEnrichments.fullTemplatePathAny,
    location.pathname,
  );
  const model = useAppSelector(state =>
    isEnrichment ? state.enrichments.dataById.get(enrichmentId) : undefined,
  );
  const fixationFound = useAppSelector(
    state => state.staticImageMappers.currentFixationIndex !== null,
  );
  const modelIdRef = useRef<string | undefined>(undefined);
  const surfaceIdRef = useRef<string | undefined>(undefined);
  const dispatch = useAppDispatch();
  const markerLess = useAppSelector(state => state.projectEdit.currentMarkerLess);
  const currentImage = useAppSelector(state => state.projectEdit.currentImage);
  const isLoading = useAppSelector(
    state =>
      state.loading.effects.projectEdit.createMarkerLess.loading ||
      state.loading.effects.projectEdit.getMarkerLess.loading ||
      state.loading.effects.projectEdit.getMarkerMapperImgUrl.loading ||
      state.loading.effects.video.setDistortedLocationUsingRecording.loading,
  );
  const isDistortedDone = useAppSelector(
    state => state.loading.effects.video.setDistortedLocationUsingRecording.success,
  );
  const markerUrl = useAppSelector(state => state.projectEdit.markerMapperImgUrl);
  const currentSurface = useAppSelector(state => state.projectEdit.currentSurface);
  const isInitiated = currentSurface?.is_initialized;
  const isDisabledByPermissions = disabledByPermission({
    type: DisableByPermissionsType.EDIT,
  });
  const isFormDisabled = isEnrichmentFormDisabled(model);
  const size = useAppSelector(
    state =>
      state.dragger.table.size +
      state.dragger.tableProject.size +
      state.dragger.video.size +
      state.dragger.videoFullScreen.size +
      Number(state.video.fullScreen),
  );
  const currentFixationIndex = useAppSelector(
    state => state.staticImageMappers.currentFixationIndex,
  );
  const notOnRefImage = useAppSelector(state =>
    state.staticImageMappers.currentFixationIndex === null
      ? false
      : state.staticImageMappers.fixations[
          state.staticImageMappers.currentFixationIndex
        ]?.gaze_on_aoi === 0
      ? true
      : false,
  );
  const delayIndicator = useAppSelector(
    state => state.staticImageMappers.delayIndicator,
  );
  const mousePositionNotOnRefImg = useAppSelector(
    state => state.staticImageMappers.mousePositionNotOnRefImg,
  );
  const refIgnoreAnimation = useRef(0);
  const [delayMapperBorderFlag, setDelayMapperBorderFlag] = useState(false);

  useEffect(() => {
    if (setDelayMapperBorderFlagTimeout) clearTimeout(setDelayMapperBorderFlagTimeout);

    if (mousePositionNotOnRefImg) {
      refIgnoreAnimation.current = 1;
      return;
    }

    if (refIgnoreAnimation.current === 1) {
      refIgnoreAnimation.current = 2;
      return;
    }

    if (refIgnoreAnimation.current === 2) {
      refIgnoreAnimation.current = 0;
      return;
    }

    setDelayMapperBorderFlag(true);

    setDelayMapperBorderFlagTimeout = setTimeout(() => {
      setDelayMapperBorderFlag(false);
    }, 150);
  }, [delayIndicator, mousePositionNotOnRefImg]);

  useEffect(() => {
    refIgnoreAnimation.current = 0;
  }, [currentFixationIndex]);

  useEffect(() => {
    if (
      modelIdRef.current !== model?.id ||
      surfaceIdRef.current !== currentSurface?.id
    ) {
      modelIdRef.current = model?.id;
      surfaceIdRef.current = currentSurface?.id;
      dispatch.projectEdit.getMarkerMapperImgUrl(model);
    }
  }, [model, currentSurface, dispatch, isDistortedDone]);

  useLayoutEffect(() => {
    const resize = () => {
      const img = document.getElementById("video_image") as HTMLImageElement | null;

      if (!img) return;

      resizeImage(imgRoot.current, img);
    };

    resize();

    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [size]);

  if (
    !isEnrichment ||
    !model ||
    (model.kind !== EnrichmentTypesEnum.SLAM_MAPPER &&
      model.kind !== EnrichmentTypesEnum.MARKER_MAPPER &&
      model.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER)
  )
    return null;

  const onSuccess = (file: RecordingFile) => {
    dispatch.projectEdit.setCurrentImage(file);
    dispatch.projectEdit.updateMarkerless(null);
  };

  return (
    <Box display="flex" width="90%" sx={{ backgroundColor: "black", px: 0.5 }}>
      <Paper
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          backgroundColor: "inherit",
          "&:hover:not(:has(canvas:hover)) > div > img":
            fixationFound && model.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER
              ? {
                  border: `3px solid ${
                    mousePositionNotOnRefImg || delayMapperBorderFlag || notOnRefImage
                      ? theme.palette.error.dark
                      : alpha(theme.palette.error.dark, 0.7)
                  } !important`,
                }
              : undefined,
        }}
      >
        <Box
          ref={imgRoot}
          display="flex"
          width="100%"
          height="100%"
          sx={{
            backgroundColor: "inherit",
            position: "relative",
          }}
          onContextMenu={e => {
            dispatch.ctxMenu.handleClick({
              e,
              type: ContextMenuTypes.VIDEO_REFERENCE_IMAGE,
            });
          }}
        >
          {!isLoading &&
          ((markerLess && markerLess.reference_image_thumbnail_url) || markerUrl) ? (
            <>
              <img
                id="video_image"
                src={markerUrl || markerLess?.reference_image_thumbnail_url}
                onError={() => {}}
                onLoad={e => {
                  resizeImage(imgRoot.current, e.currentTarget);
                }}
                alt="Preview"
                style={{
                  margin: "auto",
                  display: "block",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  width: "auto",
                  height: "auto",
                  userSelect: "none",
                  border:
                    model.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER
                      ? `3px solid ${
                          delayMapperBorderFlag || notOnRefImage
                            ? theme.palette.error.dark
                            : "#000"
                        }`
                      : undefined,
                }}
              />
              {overlayControl.models.map(m => {
                if (!m.canvasId) return null;
                if (!m.canvasOverReference) return null;

                return (
                  <canvas
                    key={m.canvasId}
                    id={m.canvasId}
                    style={{
                      position: "absolute",
                      margin: "auto",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                  />
                );
              })}
            </>
          ) : (
            <LocalFileBlob />
          )}

          <StaticImageMapperPointerCanvas />

          {isLoading ? (
            <Box m="auto">
              <CircularProgress />
            </Box>
          ) : null}

          {!isLoading &&
            !markerLess &&
            !currentImage &&
            model &&
            (model.kind === EnrichmentTypesEnum.SLAM_MAPPER ||
              model.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER) && (
              <Box m="auto">
                <UploadButton
                  text="Upload a reference image"
                  onSuccess={onSuccess}
                  disabled={isDisabledByPermissions || isFormDisabled}
                  disabledTitle={
                    isDisabledByPermissions
                      ? t("Disabled by permissions")
                      : isFormDisabled && model?.status?.SUCCESS !== 1
                      ? t("Disabled because part of enrichment has been computed")
                      : ""
                  }
                />
              </Box>
            )}

          {!isLoading &&
            model &&
            model.kind === EnrichmentTypesEnum.MARKER_MAPPER &&
            !markerUrl &&
            isInitiated && (
              <Box m="auto">
                <Typography sx={{ color: "text.secondary", textAlign: "center" }}>
                  Run the enrichment to see
                  <br />
                  an image of the detected surface
                </Typography>
              </Box>
            )}

          {!isLoading &&
            model &&
            model.kind === EnrichmentTypesEnum.MARKER_MAPPER &&
            !markerUrl &&
            !isInitiated && (
              <Box m="auto">
                <Typography sx={{ color: "text.secondary", textAlign: "center" }}>
                  Surface needs to be defined
                </Typography>
              </Box>
            )}
        </Box>
      </Paper>
    </Box>
  );
};

const fileToDataUri = (file: File) =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = event => {
      resolve(event.target?.result);
    };
    reader.readAsDataURL(file);
  });

const LocalFileBlob = memo(() => {
  const ref = useRef<HTMLImageElement | null>(null);
  const [dataUri, setDataUri] = useState("");
  const base64 = useAppSelector(state => state.uploader.base64);
  const dragger = useAppSelector(state => state.dragger.video);

  useLayoutEffect(() => {
    const resize = () => {
      const parent = ref.current?.parentElement as HTMLDivElement | null;
      const img = ref.current;

      if (!img) return;

      resizeImage(parent, img);
    };

    resize();

    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [dragger]);

  useEffect(() => {
    if (!base64) return;

    fileToDataUri(base64).then(uri => {
      setDataUri(uri as string);
    });
  }, [base64]);

  if (dataUri) {
    return (
      <img
        width="100%"
        height="100%"
        src={dataUri}
        alt="avatar"
        style={{
          position: "absolute",
          margin: "auto",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9,
          maxWidth: "100%",
          maxHeight: "100%",
          userSelect: "none",
        }}
        onLoad={e => {
          resizeImage(
            e.currentTarget.parentElement as HTMLDivElement | null,
            e.currentTarget,
          );
        }}
      />
    );
  }

  return null;
});

const STATIC_IMAGE_MAPPER_CANVAS_ID = "STATIC_IMAGE_MAPPER_CANVAS_ID";
let staticImageMapperCanvasRef: HTMLCanvasElement | null = null;
const StaticImageMapperPointerCanvas = memo(() => {
  const dispatch = useAppDispatch();
  const hide = useAppSelector(state => !state.staticImageMappers.current);
  const currentPosition = useRef<{ x: number; y: number } | null>(null);
  const currentPositionMouse = useRef<{ x: number; y: number } | null>(null);
  const clickRef = useRef(false);

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.code !== "KeyS") return;

      const canvas = staticImageMapperCanvasRef;

      if (!canvas || !currentPositionMouse.current) return;

      const ctx = canvas.getContext("2d");

      if (!ctx) return;

      const rect = canvas.getBoundingClientRect();
      ctx.clearRect(0, 0, rect.width, rect.height);
      const fixationIndex = store.getState().staticImageMappers.currentFixationIndex;
      const fixationId = fixationIndex === null ? "" : (fixationIndex + 1).toString();

      staticImageMapperOverlay.circle(
        ctx,
        [currentPositionMouse.current.x, currentPositionMouse.current.y],
        fixationId,
      );
    }

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  if (hide) return null;

  const drawOnPosition = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (isStaticImageMapperPointerCanvasDisabled() || currentPosition.current) return;

    const ctx = e.currentTarget.getContext("2d");

    if (!ctx) return;

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    ctx.clearRect(0, 0, rect.width, rect.height);

    const state = store.getState().staticImageMappers;
    const fixationIndex = state.currentFixationIndex;

    if (fixationIndex === null) return;

    const fixationId = state.fixations[fixationIndex]?.fixation_id;

    if (fixationId === undefined) return;

    currentPositionMouse.current = { x, y };
    staticImageMapperOverlay.circle(ctx, [x, y], fixationId.toString());
  };

  return (
    <Box
      sx={{
        position: "absolute",
        margin: "auto",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
      onMouseDown={() => {
        if (store.getState().staticImageMappers.currentFixationIndex === null) return;

        dispatch.staticImageMappers.setMousePositionNotOnRefImg(true);
      }}
      onMouseUp={() => {
        if (store.getState().staticImageMappers.currentFixationIndex === null) return;

        dispatch.staticImageMappers.setMousePositionNotOnRefImg(false);
      }}
      onClick={() => {
        if (store.getState().staticImageMappers.currentFixationIndex === null) return;

        if (!clickRef.current) {
          dispatch.staticImageMappers.setFixationNotOnRefImg(null);
        }

        clickRef.current = false;
      }}
    >
      <canvas
        id={STATIC_IMAGE_MAPPER_CANVAS_ID}
        ref={e => {
          staticImageMapperCanvasRef = e;
        }}
        onMouseMove={drawOnPosition}
        onMouseLeave={e => {
          currentPosition.current = null;
          currentPositionMouse.current = null;
          const ctx = e.currentTarget.getContext("2d");
          const rect = e.currentTarget.getBoundingClientRect();

          if (!ctx) return;

          ctx.clearRect(0, 0, rect.width, rect.height);
        }}
        onMouseDown={e => {
          if (e.button === 2) return;
          if (isStaticImageMapperPointerCanvasDisabled()) return;

          clickRef.current = true;

          const w = e.currentTarget.offsetWidth;
          const h = e.currentTarget.offsetHeight;
          const rect = e.currentTarget.getBoundingClientRect();
          const x = ((e.clientX - rect.left) / (rect.right - rect.left)) * w;
          const y = ((e.clientY - rect.top) / (rect.bottom - rect.top)) * h;
          const xPercentage = x / w;
          const yPercentage = y / h;

          currentPosition.current = {
            x: Number(xPercentage.toFixed(5)),
            y: Number(yPercentage.toFixed(5)),
          };

          const ctx = e.currentTarget.getContext("2d");

          if (!ctx) return;

          ctx.clearRect(0, 0, rect.width, rect.height);

          const fixationIndex =
            store.getState().staticImageMappers.currentFixationIndex;
          const fixationId =
            fixationIndex === null ? "" : (fixationIndex + 1).toString();

          staticImageMapperOverlay.circle(ctx, [x, y], fixationId, false, true);
        }}
        onMouseUp={e => {
          if (e.button === 2) return;
          if (currentPosition.current === null) return;
          if (store.getState().staticImageMappers.currentFixationIndex === null) return;

          const ctx = e.currentTarget.getContext("2d");

          if (!ctx) return;

          const rect = e.currentTarget.getBoundingClientRect();
          ctx.clearRect(0, 0, rect.width, rect.height);

          dispatch.staticImageMappers.setFixationPosition({
            ...currentPosition.current,
            gazeOnAoi: 1,
          });

          currentPosition.current = null;
          drawOnPosition(e);
        }}
        style={{
          position: "absolute",
          margin: "auto",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: 1,
          height: 1,
        }}
      />
      <StaticImageMapperPointerCanvasWatcher
        currentPositionMouse={currentPositionMouse}
      />
    </Box>
  );
});

const StaticImageMapperPointerCanvasWatcher: FC<{
  currentPositionMouse: React.MutableRefObject<{
    x: number;
    y: number;
  } | null>;
}> = ({ currentPositionMouse }) => {
  const rerenderMouse = useAppSelector(state => state.staticImageMappers.rerenderMouse);

  useEffect(() => {
    if (!staticImageMapperCanvasRef || !currentPositionMouse.current) return;

    const ctx = staticImageMapperCanvasRef.getContext("2d");

    if (!ctx) return;

    const rect = staticImageMapperCanvasRef.getBoundingClientRect();
    const x = currentPositionMouse.current.x;
    const y = currentPositionMouse.current.y;

    ctx.clearRect(0, 0, rect.width, rect.height);

    const state = store.getState().staticImageMappers;
    const fixationIndex = state.currentFixationIndex;

    if (fixationIndex === null) return;

    const fixationId = state.fixations[fixationIndex]?.fixation_id;

    if (fixationId === undefined) return;

    staticImageMapperOverlay.circle(ctx, [x, y], fixationId.toString());
  }, [rerenderMouse, currentPositionMouse]);

  return null;
};
