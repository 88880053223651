import { ReactRouterLinkCustom } from "@components";
import { SettingItem } from "@components/settings/SettingItem";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { RouterHelper } from "@pages";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

const google = "google.com";

export const AccountSettings: FC = () => {
  const { t } = useTranslation();
  const user = useAppSelector(state => state.app.apiUser);
  const dispatch = useAppDispatch();

  const handleChangeNewsletter = async (e: ChangeEvent<HTMLInputElement>) => {
    await dispatch.app.subscribeProductUpdates(e.target.checked);
  };

  return (
    <>
      <Grid display="grid" container>
        <SettingItem primary="Display Picture">
          <Avatar
            alt={user?.name}
            src={user?.photo_url}
            sx={{ width: 100, height: 100 }}
            variant="rounded"
          />
          <Button
            component={ReactRouterLinkCustom}
            to={RouterHelper.accountSettingsEditImage.relativeTemplatePath}
            sx={{ maxWidth: 100 }}
            fullWidth
            variant="text"
            color="primary"
          >
            Edit Image
          </Button>
        </SettingItem>
        <SettingItem primary={t("Account Name")}>
          <Typography>{user?.name}</Typography>
          <Button
            component={ReactRouterLinkCustom}
            to={RouterHelper.accountSettingsEditName.relativeTemplatePath}
            variant="text"
            color="primary"
          >
            Edit Account Name
          </Button>
        </SettingItem>
        <SettingItem
          primary={user?.provider === google ? t("Email & Password") : t("Email")}
        >
          <Typography>{user?.email}</Typography>
          {user?.provider === google ? (
            <Typography variant="subtitle2" color="text.secondary">
              Managed by Google
            </Typography>
          ) : (
            <Button
              component={ReactRouterLinkCustom}
              to={RouterHelper.accountSettingsEditEmail.relativeTemplatePath}
              variant="text"
              color="primary"
            >
              Edit Email
            </Button>
          )}
          <Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user?.product_updates_subscribed}
                    disabled={!user}
                    onChange={handleChangeNewsletter}
                  />
                }
                label={
                  <Typography variant="body2" color="text.secondary">
                    Receive Pupil Cloud product updates
                  </Typography>
                }
              />
            </FormGroup>
          </Box>
        </SettingItem>
        {user?.provider !== google && (
          <SettingItem primary={t("Password")}>
            <Button
              component={ReactRouterLinkCustom}
              to={RouterHelper.accountSettingsEditPassword.relativeTemplatePath}
            >
              Edit Password
            </Button>
          </SettingItem>
        )}
        <SettingItem
          primary={t("Delete Account")}
          secondary={t(
            "Deleting your account will delete all Pupil Cloud platform data associated with this account. Please download any data you would like to save before deleting your account.",
          )}
        >
          <Button
            component={ReactRouterLinkCustom}
            to={RouterHelper.accountSettingsDelete.relativeTemplatePath}
            variant="text"
            color="primary"
          >
            Delete Account
          </Button>
        </SettingItem>
      </Grid>
      <Outlet />
    </>
  );
};
