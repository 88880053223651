import { Profile, User } from "@api";
import { FormDialog, TextField } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import { auth, updateProfile } from "@services/firebase";
import { useAppDispatch } from "@storeRematch";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

export interface AccountNameFormProps {
  open: boolean;
  onClose(): void;
  user?: User | Profile;
}

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
  })
  .required();

export const AccountNameForm: React.FC<AccountNameFormProps> = ({
  open,
  onClose,
  user = undefined,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSubmit = async (formData: User) => {
    if (!user || !auth.currentUser) return;

    await updateProfile(auth.currentUser, { displayName: formData.name });
    await dispatch.app.updateAccount();
    onClose();
  };

  return (
    <FormDialog
      open={open}
      title={t("Edit account name")}
      onClose={onClose}
      onFormSubmit={handleSubmit}
      useFormProps={{
        defaultValues: user || { name: "" },
        resolver: yupResolver(schema),
      }}
      create={!user}
      loading={false}
    >
      <TextField fullWidth name="name" autoFocus placeholder={t("Edit account name")} />
    </FormDialog>
  );
};
