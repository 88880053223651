import {
  AoiStats,
  GenerateHeatmap,
  ProjectEnrichmentAoi,
  VisualizationTypes,
  api,
} from "@api";
import { createModel } from "@rematch/core";
import { RematchAdapter, formatReadableAoiStatMs, rematchAdapter } from "@utils";
import type { AoiStatsWithData, RootModel } from ".";
import { AOI_HEATMAP_TYPES, AOI_HEATMAP_TYPES_MAX } from "./types";

interface ExtraProps {
  heatmapType: AOI_HEATMAP_TYPES | null;
  heatmapColor: GenerateHeatmap["colormap"];
  showNames: boolean;
  showMetricValue: boolean;
  max: Record<AOI_HEATMAP_TYPES, number>;
}

interface Adapter extends RematchAdapter<AoiStats, AoiStatsWithData>, ExtraProps {}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.aoi_id,
  extraProps: {
    heatmapType: null,
    heatmapColor: undefined,
    showNames: true,
    showMetricValue: true,
    max: { ...AOI_HEATMAP_TYPES_MAX },
  },
});

export const aoiStats = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
    setHeatmapType(state, data: Adapter["heatmapType"]) {
      state.heatmapType = data;
    },
    setHeatmapColor(state, data: Adapter["heatmapColor"]) {
      state.heatmapColor = data;
    },
    setShowNames(state, data: Adapter["showNames"]) {
      state.showNames = data;
    },
    setShowMetricValue(state, data: Adapter["showMetricValue"]) {
      state.showMetricValue = data;
    },
    setMax(state, data: Adapter["max"]) {
      state.max = data;
    },
  },
  effects: dispatch => ({
    async prepare(_, state) {
      const aoiAreasById = state.aoiAreas.dataById;
      const visibility = state.aoiAreas.visibility;
      const data = state.aoiStats.data;

      const { tableData, tableDataById, nextMax } = dispatch.aoiStats.innerPrepare({
        data,
        visibility,
        aoiAreasById,
      });

      dispatch.aoiStats.setTableData(tableData);
      dispatch.aoiStats.setTableDataById(tableDataById);
      dispatch.aoiStats.setMax(nextMax);
    },
    innerPrepare({
      data,
      visibility,
      aoiAreasById,
    }: {
      data: AoiStats[];
      visibility: Record<string, boolean>;
      aoiAreasById: Map<string, ProjectEnrichmentAoi>;
    }) {
      const tableData: Adapter["tableData"] = [];
      const tableDataById: Adapter["tableDataById"] = new Map();

      const nextMax = data.reduce(
        (acc, one) => {
          if (!visibility[one.aoi_id]) return acc;

          if (
            one.avg_fixation_duration_ms &&
            one.avg_fixation_duration_ms > acc.AVERAGE_FIXATION_DURATION
          ) {
            acc.AVERAGE_FIXATION_DURATION = one.avg_fixation_duration_ms;
          }

          if (
            one.avg_ms_till_first_fixation &&
            one.avg_ms_till_first_fixation > acc.TIME_TO_FIRST_FIXATION
          ) {
            acc.TIME_TO_FIRST_FIXATION = one.avg_ms_till_first_fixation;
          }

          if (
            one.total_fixation_duration_ms &&
            one.total_fixation_duration_ms > acc.TOTAL_FIXATION_DURATION
          ) {
            acc.TOTAL_FIXATION_DURATION = one.total_fixation_duration_ms;
          }

          if (one.avg_fixations && one.avg_fixations > acc.FIXATION_COUNT) {
            acc.FIXATION_COUNT = one.avg_fixations;
          }

          return acc;
        },
        { ...AOI_HEATMAP_TYPES_MAX },
      );

      data.forEach(one => {
        if (!aoiAreasById.has(one.aoi_id) || !visibility[one.aoi_id]) return;

        const next: AoiStatsWithData = {
          id: one.aoi_id,
          aoiStats: one,
          percentage: {
            [AOI_HEATMAP_TYPES.AVERAGE_FIXATION_DURATION]: one.avg_fixation_duration_ms
              ? one.avg_fixation_duration_ms / nextMax.AVERAGE_FIXATION_DURATION
              : 0,
            [AOI_HEATMAP_TYPES.TOTAL_FIXATION_DURATION]: one.total_fixation_duration_ms
              ? one.total_fixation_duration_ms / nextMax.TOTAL_FIXATION_DURATION
              : 0,
            [AOI_HEATMAP_TYPES.TIME_TO_FIRST_FIXATION]: one.avg_ms_till_first_fixation
              ? one.avg_ms_till_first_fixation / nextMax.TIME_TO_FIRST_FIXATION
              : 0,
            [AOI_HEATMAP_TYPES.REACH]: one.reach || 0,
            [AOI_HEATMAP_TYPES.FIXATION_COUNT]: one.avg_fixations
              ? one.avg_fixations / nextMax.FIXATION_COUNT
              : 0,
          },
          readable: {
            [AOI_HEATMAP_TYPES.AVERAGE_FIXATION_DURATION]: formatReadableAoiStatMs(
              one.avg_fixation_duration_ms,
            ),
            [AOI_HEATMAP_TYPES.TOTAL_FIXATION_DURATION]: formatReadableAoiStatMs(
              one.total_fixation_duration_ms,
            ),
            [AOI_HEATMAP_TYPES.TIME_TO_FIRST_FIXATION]: formatReadableAoiStatMs(
              one.avg_ms_till_first_fixation,
            ),
            [AOI_HEATMAP_TYPES.REACH]: `${((one.reach || 0) * 100).toFixed(0)}%`,
            [AOI_HEATMAP_TYPES.FIXATION_COUNT]: `${(one.avg_fixations || 0).toFixed(
              0,
            )}`,
          },
        };

        tableData.push(next);
        tableDataById.set(next.id, next);
      });

      return { tableData, tableDataById, nextMax };
    },
    async get(
      { id, recordingIds }: { id: string | null; recordingIds?: string[] },
      state,
    ) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichmentId = id || state.projectEdit.currentEnrichment?.id;

      if (
        !workspaceId ||
        !projectId ||
        !enrichmentId ||
        !document.URL.includes("visualizations")
      )
        return;

      if (recordingIds && !recordingIds.length) {
        dispatch.aoiStats.setMax({ ...AOI_HEATMAP_TYPES_MAX });
        dispatch.aoiStats.setAll([]);
        dispatch.aoiStats.prepare(null);
        return;
      }

      const currentVisualization = state.visualizations.currentVisualization;
      const recordingIdsChecked =
        !recordingIds &&
        currentVisualization &&
        currentVisualization.kind === VisualizationTypes.AOI_HEATMAP
          ? currentVisualization.payload.recording_ids
          : recordingIds;

      try {
        const data = await api.getAoiStats({
          workspaceId,
          projectId,
          enrichmentId,
          params: recordingIdsChecked?.length
            ? recordingIdsChecked.reduce((acc, id, i) => {
                if (i !== 0) {
                  acc += "&";
                }

                acc += `rids=${id}`;

                return acc;
              }, "")
            : undefined,
        });

        dispatch.aoiStats.setAll(Object.values(data.result.aois));
        dispatch.aoiStats.prepare(null);
      } catch (error) {
        console.error(error);
      }
    },
  }),
});
