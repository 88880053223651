import { ErrorPage } from "@components";
import { useAppSelector } from "@storeRematch";
import { useNavigate } from "react-router-dom";
import { AccountNameForm } from "./AccountNameForm";

export const AccountNameEdit = () => {
  const navigate = useNavigate();
  const currentUser = useAppSelector(state => state.app.apiUser);

  if (!currentUser) {
    return <ErrorPage title={`User not found in this workspace`} />;
  }

  return (
    <AccountNameForm user={currentUser} onClose={() => navigate(-1)} open={true} />
  );
};
