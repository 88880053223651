import { DownloadProjectEnrichmentsObject, EnrichmentTypesEnum } from "@api";
import { Loader } from "@components/Loader";
import { Info, KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { RecordingWithData, useAppDispatch, useAppSelector } from "@storeRematch";
import { formatReadableEnrichment, isVisualizationNotDownloadable } from "@utils";
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";

type DownloadSpec = {
  title: string;
  subtitle?: string;
  extraInfo?: string;
  uid: string;
  files: File[];
  disabled?: boolean;
};

type File = {
  id: string;
  filename: string;
  checked: boolean;
  action?: React.ReactNode;
};

const recExportDataList: DownloadSpec[] = [
  {
    title: "Timeseries CSV",
    uid: "rawDataExport",
    files: [
      { id: "recdata-rde-1", filename: "enrichment_info.txt", checked: true },
      { id: "recdata-rde-2", filename: "sections.csv", checked: true },
      { id: "recdata-rde-3", filename: "info.json", checked: true },
      { id: "recdata-rde-4", filename: "scene_camera.json", checked: true },
      { id: "recdata-rde-5", filename: "world_timestamps.csv", checked: true },
      { id: "recdata-rde-6", filename: "events.csv", checked: true },
      { id: "recdata-rde-7", filename: "gaze.csv", checked: true },
      { id: "recdata-rde-8", filename: "imu.csv", checked: true },
      { id: "recdata-rde-10", filename: "fixations.csv", checked: true },
      {
        id: "recdata-rde-11",
        filename: "3d_eye_states.csv",
        checked: true,
      },
    ],
  },
  {
    title: "Timeseries CSV and Scene Video",
    uid: "rawDataExportInclude",
    files: [
      { id: "recdata-rde-1", filename: "enrichment_info.txt", checked: true },
      { id: "recdata-rde-2", filename: "sections.csv", checked: true },
      { id: "recdata-rde-3", filename: "info.json", checked: true },
      { id: "recdata-rde-4", filename: "scene_camera.json", checked: true },
      { id: "recdata-rde-5", filename: "world_timestamps.csv", checked: true },
      { id: "recdata-rde-6", filename: "events.csv", checked: true },
      { id: "recdata-rde-7", filename: "gaze.csv", checked: true },
      { id: "recdata-rde-8", filename: "imu.csv", checked: true },
      { id: "recdata-rde-9", filename: "scene_video.mp4", checked: true },
      { id: "recdata-rde-10", filename: "fixations.csv", checked: true },
      {
        id: "recdata-rde-11",
        filename: "3d_eye_states.csv",
        checked: true,
      },
    ],
  },
  {
    title: "Native Recording Data",
    uid: "rawSensorData",
    files: [
      { id: "recdata-rde-1", filename: "gaze", checked: true },
      { id: "recdata-rde-2", filename: "IMU", checked: true },
      { id: "recdata-rde-3", filename: "world video + audio", checked: true },
      { id: "recdata-rde-4", filename: "eye videos", checked: true },
      { id: "recdata-rde-5", filename: "world_timestamps.csv", checked: true },
      { id: "recdata-rde-6", filename: "events.json", checked: true },
      { id: "recdata-rde-7", filename: "templates.json", checked: true },
    ],
  },
];

interface CheckState {
  checkedCount: number;
  indeterminate: boolean;
  key: string;
  label: string;
  subLabel?: string;
  parent: CheckState | null;
  children: CheckState[];
  disabled?: boolean;
}

enum CheckStateChangeType {
  UP,
  DOWN,
  ALL,
}

function createCheckStateItem(partial: Partial<CheckState>): CheckState {
  return {
    checkedCount: 0,
    indeterminate: false,
    key: "",
    label: "",
    parent: null,
    children: [],
    ...partial,
  };
}
// this is a bit boring, but needed since we are creating structure...
// this can also be made abstract
function createCheckState(spec: DownloadSpec[], initName: string) {
  const state = createCheckStateItem({
    key: initName,
    label: initName,
  });

  spec.forEach(one => {
    // TODO(ivan): disable for testing, later to be removed
    // const isIncluded =
    //   store.getState().app.currentWorkspaceMembership?.workspace.raw_file_downloads;

    // if (one.uid === "rawSensorData" && !isIncluded) return;

    const item = createCheckStateItem({
      key: one.uid,
      label: one.title,
      subLabel: one.subtitle,
      parent: state,
      disabled: one.disabled,
    });

    one.files.forEach(file => {
      if (file.checked) {
        item.checkedCount += 1;
        item.indeterminate = true;
      }

      item.children.push(
        createCheckStateItem({
          checkedCount: file.checked ? 1 : 0,
          key: file.id,
          label: file.filename,
          parent: item,
          indeterminate: Boolean(file.checked),
        }),
      );
    });

    if (item.checkedCount) {
      state.indeterminate = true;
    }

    if (item.checkedCount === item.children.length) {
      state.checkedCount += 1;
    }

    state.children.push(item);
  });

  return state;
}

// after mutation find root node ref
// so you can set the state
function getRoot(state: CheckState): CheckState {
  return state.parent ? getRoot(state.parent) : state;
}

function setIndeterminate(state: CheckState) {
  state.indeterminate = state.children.some(c => c.indeterminate === true);
}

// for checking all checkboxes up from ref
function checkUp(state: CheckState, checked: boolean): CheckState {
  const prevCount = state.checkedCount;
  state.checkedCount += checked ? 1 : -1;

  if (!state.children.length) {
    state.indeterminate = !!state.checkedCount;
  }

  if (state.parent) {
    setIndeterminate(state.parent);
  } else {
    setIndeterminate(state);
  }

  if (
    state.parent &&
    (!state.children.length ||
      prevCount === state.children.length ||
      state.checkedCount === state.children.length)
  ) {
    checkUp(state.parent, checked);
  }

  return state;
}

// for checking all checkboxes down from ref
function checkDown(state: CheckState, checked: boolean) {
  state.checkedCount = checked ? state.children.length || 1 : 0;
  state.indeterminate = checked;

  if (state.children.length) state.children.map(child => checkDown(child, checked));

  return state;
}

// now you can got as deep as you need, with checkboxes
// you just need to know where is the root and the end (CheckStateChangeType.DOWN, CheckStateChangeType.UP)
// and you can split it into two sides
// and add other logic....

export const ProjectDownload: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <EmptyInfo>
        <Box display="flex" overflow="hidden" height="100%">
          <RecordingData />
          <Divider orientation="vertical" />
          <EnrichmentData />
        </Box>
      </EmptyInfo>

      {/* <Divider sx={{ mt: "auto" }} />

        <Box sx={{ py: 1.5, px: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button
            color="primary"
            size="small"
            onClick={dispatch.download.download}
            disabled={disabled}
          >
            Download
          </Button>
        </Box> */}
    </Box>
  );
};

const EmptyInfo: FC<PropsWithChildren> = ({ children }) => {
  const data = useAppSelector(state => state.projectEdit.recordings);
  const isLoading = useAppSelector(
    state =>
      state.loading.effects.app.loadWorkspaceData.loading ||
      state.loading.effects.app.loadProjectData.loading,
  );

  if (isLoading) return <Loader />;

  if (data.length) return <>{children}</>;

  return (
    <>
      <Typography sx={{ textAlign: "center", mt: "35%" }}>
        No recordings in this project.
      </Typography>
      <Typography sx={{ textAlign: "center" }}>
        Go back to your workspace. Select recordings and
      </Typography>
      <Typography sx={{ textAlign: "center" }}>add them to this project.</Typography>
    </>
  );
};

function createRecordingDownload(list: CheckState, data: RecordingWithData[]) {
  const files = list.children[0].children.reduce<string[]>((acc, f) => {
    if (!f.checkedCount) {
      acc.push(f.label);
    }

    return acc;
  }, []);

  return data.map(one => ({
    recording_id: one.id,
    exclude_files: files,
  }));
}

const RecordingData = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(state => state.projectEdit.recordings);
  const [list, setList] = useState<CheckState | undefined>();
  const reset = useAppSelector(
    state => state.app.currentWorkspaceMembership?.workspace.raw_file_downloads,
  );

  useEffect(() => {
    setList(createCheckState(recExportDataList, "Recording data"));
  }, [reset]);

  useEffect(() => {
    if (!list) return;
    dispatch.download.setRecordings(createRecordingDownload(list, data));
  }, [data, dispatch, list]);

  if (!list) return null;

  return (
    <CheckList
      defaultValue={data.length ? list : { ...list, children: [] }}
      onChange={list => {
        dispatch.download.setRecordings(createRecordingDownload(list, data));
      }}
      link="https://docs.pupil-labs.com/export-formats/recording-data/neon/"
    >
      <Typography color="GrayText">{data.length} recordings</Typography>
    </CheckList>
  );
};

function createEnrichmentDownload(list: CheckState) {
  return list.children.reduce<DownloadProjectEnrichmentsObject[]>((acc, c) => {
    if (c.checkedCount) {
      const files = c.children.reduce<string[]>((acc, f) => {
        if (!f.checkedCount) {
          acc.push(f.label);
        }

        return acc;
      }, []);

      acc.push({ enrichment_id: c.key, exclude_files: files });
    }

    return acc;
  }, []);
}

const SLAM_MAPPER_ITEMS = [
  {
    id: "enrichment_info.txt",
    filename: "enrichment_info.txt",
    checked: true,
  },
  {
    id: "sections.csv",
    filename: "sections.csv",
    checked: true,
  },
  {
    id: "reference_image.jpg or .png",
    filename: "reference_image.jpg or .png",
    checked: true,
  },
  {
    id: "gaze.csv",
    filename: "gaze.csv",
    checked: true,
  },
  {
    id: "fixations.csv",
    filename: "fixations.csv",
    checked: true,
  },
  {
    id: "aoi_metrics.csv",
    filename: "aoi_metrics.csv",
    checked: true,
  },
];

const MAP_MAKER_ITEMS = [
  {
    id: "enrichment_info.txt",
    filename: "enrichment_info.txt",
    checked: true,
  },
  {
    id: "sections.csv",
    filename: "sections.csv",
    checked: true,
  },
  {
    id: "fixations.csv",
    filename: "fixations.csv",
    checked: true,
  },
  {
    id: "surface_positions.csv",
    filename: "surface_positions.csv",
    checked: true,
  },
  {
    id: "aoi_metrics.csv",
    filename: "aoi_metrics.csv",
    checked: true,
  },
];

const FACE_MAPPER_ITEMS = [
  {
    id: "enrichment_info.txt",
    filename: "enrichment_info.txt",
    checked: true,
  },
  {
    id: "sections.csv",
    filename: "sections.csv",
    checked: true,
  },
  {
    id: "gaze_on_face.csv",
    filename: "gaze_on_face.csv",
    checked: true,
  },
  {
    id: "face_detections.csv",
    filename: "face_detections.csv",
    checked: true,
  },
];

const MANUAL_MAPPER_ITEMS = [
  {
    id: "enrichment_info.txt",
    filename: "enrichment_info.txt",
    checked: true,
  },
  {
    id: "sections.csv",
    filename: "sections.csv",
    checked: true,
  },
  {
    id: "reference_image.jpg or .png",
    filename: "reference_image.jpg or .png",
    checked: true,
  },
  {
    id: "fixations.csv",
    filename: "fixations.csv",
    checked: true,
  },
  {
    id: "aoi_metrics.csv",
    filename: "aoi_metrics.csv",
    checked: true,
  },
  {
    id: "aoi_fixations.csv",
    filename: "aoi_fixations.csv",
    checked: true,
  },
];

const EnrichmentData = () => {
  const dispatch = useAppDispatch();
  const list = useAppSelector(state => state.enrichments.data);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const [counter, setCounter] = useState(list.length);

  const [value, size] = useMemo(() => {
    const spec = [...list]
      .filter(o => o.kind !== "render")
      .sort((a, b) =>
        a.created_at && b.created_at
          ? new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          : 0,
      )
      .reduce<DownloadSpec[]>((acc, one) => {
        acc.push({
          title: one.name,
          subtitle: formatReadableEnrichment(one.kind),
          uid: one.id,
          files:
            one.kind === EnrichmentTypesEnum.SLAM_MAPPER
              ? SLAM_MAPPER_ITEMS
              : one.kind === EnrichmentTypesEnum.FACE_MAPPER
              ? FACE_MAPPER_ITEMS
              : one.kind === EnrichmentTypesEnum.MARKER_MAPPER
              ? MAP_MAKER_ITEMS
              : one.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER
              ? MANUAL_MAPPER_ITEMS
              : [],
          disabled: isVisualizationNotDownloadable(one),
        });

        return acc;
      }, []);

    return [createCheckState(spec, "Enrichment data"), spec.length];
  }, [list]);

  useEffect(() => {
    dispatch.download.setEnrichments(createEnrichmentDownload(value));
  }, [value, dispatch]);

  useEffect(() => {
    setCounter(list.length);
  }, [list]);

  return (
    <CheckList
      defaultValue={value}
      onChange={list => {
        const enrichments = createEnrichmentDownload(list);
        setCounter(enrichments.length);
        dispatch.download.setEnrichments(enrichments);
      }}
      link="https://docs.pupil-labs.com/cloud-downloads/"
    >
      <Typography color="GrayText">{size} enrichments</Typography>
    </CheckList>
  );
};

const CheckList: FC<
  PropsWithChildren<{
    defaultValue: CheckState;
    onChange: (list: CheckState) => void;
    link: string;
  }>
> = ({ defaultValue, onChange, link, children }) => {
  const [list, setList] = useState(defaultValue);

  useEffect(() => {
    setList(defaultValue);
  }, [defaultValue]);

  // this is a bit dangerous because we are mutating the state...
  // there are other ways
  const handleChange =
    (item: CheckState, type = CheckStateChangeType.ALL) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      let next: CheckState;

      switch (type) {
        case CheckStateChangeType.UP:
          next = checkUp(item, checked);
          break;
        case CheckStateChangeType.DOWN:
          next = checkDown(item, checked);
          break;
        case CheckStateChangeType.ALL:
        default:
          checkDown(item, checked);
          // casting should not be a problem
          // since we checked all types
          next = checkUp(item.parent as CheckState, checked);

          break;
      }

      next = { ...getRoot(next) };
      setList(next);
      onChange(next);
    };

  return (
    <Box display="flex" flexDirection="column" width="100%" overflow="hidden">
      <Box px={2} py={1} display="flex" alignItems="center">
        <CheckboxControl
          item={list}
          onChange={handleChange(list, CheckStateChangeType.DOWN)}
        />
        <IconButton size="small" component={Link} href={link}>
          <Info fontSize="small" />
        </IconButton>

        <Box ml="auto" display="flex" alignItems="center">
          {children}
        </Box>
      </Box>

      <Divider />

      <Box px={2} height="100%" overflow="auto">
        {list.children.map(item => {
          return (
            <CheckListChild
              key={item.key}
              parentId={item.key}
              list={item}
              onChange={handleChange}
              disabled={item.disabled}
            />
          );
        })}
      </Box>
    </Box>
  );
};

const CheckListChild = ({
  list,
  onChange,
  parentId,
  disabled,
}: {
  list: CheckState;
  parentId: string;
  disabled?: boolean;
  onChange: (
    item: CheckState,
    type?: CheckStateChangeType,
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const dispatch = useAppDispatch();
  const disabledLoading = useAppSelector(
    state =>
      state.loading.effects.app.loadWorkspaceData.loading ||
      state.loading.effects.app.loadProjectData.loading ||
      state.loading.effects.download.download.loading,
  );
  const [open, setOpen] = useState(false);
  const Icon = open ? KeyboardArrowDown : KeyboardArrowRight;
  const isDisabled = disabled || disabledLoading;

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <Box mt={1} mb={1} position="relative" sx={{ overflow: "hidden" }}>
      <Box sx={{ display: "flex" }}>
        <IconButton
          sx={{ mr: 1, mt: 0.8 }}
          size="small"
          onClick={toggle}
          disabled={isDisabled}
        >
          <Icon fontSize="small" />
        </IconButton>
        <CheckboxControl item={list} onChange={onChange(list)} disabled={isDisabled} />
      </Box>
      {open && (
        <Box sx={{ display: "flex", flexDirection: "column", ml: 6 }}>
          {list.children.map(file => {
            return (
              <CheckboxControl
                key={file.key}
                item={file}
                onChange={onChange(file, CheckStateChangeType.UP)}
                secondary
              />
            );
          })}
        </Box>
      )}
      <Button
        variant="text"
        color="primary"
        size="small"
        sx={{ right: 0, top: 8, position: "absolute" }}
        disabled={isDisabled}
        onClick={() => {
          if (list.subLabel === formatReadableEnrichment("static-image-mapper")) {
            dispatch.staticImageMappers.download(list.key);
          } else if (parentId === "rawSensorData") {
            dispatch.download.downloadPupilPlayerFormat(true);
          } else {
            dispatch.download.download(
              parentId === "rawDataExportInclude"
                ? undefined
                : parentId === "rawDataExport"
                ? null
                : parentId,
            );
          }
        }}
      >
        Download
      </Button>
    </Box>
  );
};

const CheckboxControl = ({
  item,
  disabled,
  secondary,
}: {
  item: CheckState;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  secondary?: boolean;
}) => {
  // const checked = item.children.length
  //   ? item.checkedCount === item.children.length
  //   : !!item.checkedCount;

  // const indeterminate = item.children.length ? !checked && item.indeterminate : false;

  return (
    <FormControl
      sx={{
        mb: 0,
        display: "flex",
        flexDirection: "row",
        maxWidth: "calc(100% - 110px)",
      }}
    >
      {/* <FormControlLabel
        label={item.label}
        control={
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            onChange={onChange}
          />
        }
      /> */}
      <Typography
        noWrap
        sx={{
          mt:
            item.label === "Recording data" || item.label === "Enrichment data"
              ? 0
              : 1.2,
          mr: 0.5,
          maxWidth: "20rem",
          color: secondary ? "text.secondary" : disabled ? "text.disabled" : "inherit",
        }}
      >
        {item.label}
      </Typography>

      {item.subLabel && (
        <Typography
          noWrap
          sx={{
            mt: 1.2,
            maxWidth: "20rem",
            color: disabled ? "text.disabled" : "GrayText",
          }}
        >
          {"- "}
          {item.subLabel}
        </Typography>
      )}
      {item.key === "recdata-rde-11" ? (
        <Tooltip title="only for Neon" placement="top" arrow>
          <Info
            fontSize="small"
            sx={{ color: "text.secondary", ml: 0.5, mt: "auto", mb: "3px" }}
          />
        </Tooltip>
      ) : null}
    </FormControl>
  );
};
