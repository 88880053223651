import { api } from "@api";
import { createModel } from "@rematch/core";
import {
  DisableByPermissionsType,
  KONVA_ADD_CURSOR,
  KONVA_CIRCLE_HIT_RADIUS,
  KONVA_REMOVE_CURSOR,
  createShape,
  disabledByPermission,
  isLineDrawn,
} from "@utils";
import { getOpacityIndexOfImageData } from "@utils/getOpacityIndexOfImageData";
import Konva from "konva";
import { RootModel } from ".";
import { AoiTool, QuestionDialogTypes } from "./types";

interface InitialState {
  tool: AoiTool;
  line: number[];
  pos: { x: number; y: number };
  stageRef: Konva.Stage | null;
  groupRef: Konva.Group | null;
  paint: boolean;
  imageUrl: string | undefined;
  imageNotAvailable: boolean;
  imageNaturalDim: { width: number; height: number } | null;
}

const initialState: InitialState = {
  tool: AoiTool.POINTER,
  line: [],
  pos: { x: 0, y: 0 },
  stageRef: null,
  groupRef: null,
  paint: false,
  imageUrl: undefined,
  imageNotAvailable: false,
  imageNaturalDim: null,
};

export const aoiTool = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setTool: (state, data: InitialState["tool"]) => {
      state.tool = data;
    },
    setLine(state, data: InitialState["line"]) {
      state.line = data;
    },
    setPos(state, data: InitialState["pos"]) {
      state.pos = data;
    },
    setStageRef(state, data: InitialState["stageRef"]) {
      state.stageRef = data;
    },
    setGroupRef(state, data: InitialState["groupRef"]) {
      state.groupRef = data;
    },
    setPaint(state, data: InitialState["paint"]) {
      state.paint = data;
    },
    setImageUrl(state, data: InitialState["imageUrl"]) {
      state.imageUrl = data;
    },
    setImageNotAvailable(state, data: InitialState["imageNotAvailable"]) {
      state.imageNotAvailable = data;
    },
    setImageNaturalDim(state, data: InitialState["imageNaturalDim"]) {
      state.imageNaturalDim = data;
    },
    reset() {
      return { ...initialState };
    },
  },
  effects: dispatch => ({
    async get(id: string, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichment = state.enrichments.dataById.get(id);

      if (
        !workspaceId ||
        !projectId ||
        !enrichment ||
        (enrichment.kind !== "slam-mapper" &&
          enrichment.kind !== "marker-mapper" &&
          enrichment.kind !== "static-image-mapper")
      )
        return;

      try {
        const url = await api.getHeatmapBackground({
          enrichment_id: enrichment.id,
          project_id: projectId,
          workspaceId,
        });

        dispatch.aoiTool.setImageUrl(url);
        dispatch.aoiTool.setImageNotAvailable(false);
      } catch (e) {
        console.error(e);
        dispatch.aoiTool.setImageNotAvailable(true);
      }
    },
    clear(_, state) {
      dispatch.aoiTool.setLine([]);
      state.aoiTool.groupRef?.removeChildren().clearCache();
    },
    onMouseDown(e: Konva.KonvaEventObject<MouseEvent>, state) {
      if (e.evt.button !== 0) return;

      const tool = state.aoiTool.tool;
      const stage = state.aoiTool.stageRef;
      const line = state.aoiTool.line;

      if ((tool !== AoiTool.PEN_ADD && tool !== AoiTool.PEN_REMOVE) || !stage) return;

      dispatch.aoiTool.setPaint(true);

      const pos = stage.getRelativePointerPosition();

      if (!pos) return;

      console.log("onMouseDown", line.concat([pos.x, pos.y]));

      dispatch.aoiTool.setPos(pos);
      dispatch.aoiTool.setLine(line.concat([pos.x, pos.y]));
    },
    onMouseUp(e: Konva.KonvaEventObject<MouseEvent>, state) {
      if (e.evt.button !== 0) return;

      const tool = state.aoiTool.tool;
      const stage = state.aoiTool.stageRef;
      const group = state.aoiTool.groupRef;
      const line = state.aoiTool.line;

      dispatch.aoiTool.setPaint(false);

      if ((tool !== AoiTool.PEN_ADD && tool !== AoiTool.PEN_REMOVE) || !stage || !group)
        return;

      const pos = stage.getRelativePointerPosition();

      if (!pos) return;
      if (line[0] === undefined || line[1] === undefined || !isLineDrawn(line)) return;

      const diffX = Math.abs(pos.x - line[0]);
      const diffY = Math.abs(pos.y - line[1]);

      if (diffX > KONVA_CIRCLE_HIT_RADIUS || diffY > KONVA_CIRCLE_HIT_RADIUS) return;

      console.log(
        "onMouseUp",
        tool === AoiTool.PEN_ADD ? "PEN_ADD" : "PEN_REMOVE",
        line.concat([pos.x, pos.y]),
      );

      group
        .add(
          createShape({
            points: line.concat([pos.x, pos.y]),
            tool: tool,
          }),
        )
        .cache();

      dispatch.aoiAreas.updateShape(null);
    },
    onMouseLeave() {
      dispatch.aoiTool.setPaint(false);
    },
    onMouseMove(_, state) {
      const tool = state.aoiTool.tool;
      const stage = state.aoiTool.stageRef;
      const line = state.aoiTool.line;

      if (!stage) return;

      const pointer = stage.getRelativePointerPosition();

      if (!pointer) return;

      console.log(
        "onMouseMove",
        tool === AoiTool.POINTER
          ? "POINTER"
          : tool === AoiTool.PEN_ADD
          ? "PEN_ADD"
          : "PEN_REMOVE",
        pointer,
      );

      if ((tool === AoiTool.PEN_ADD || tool === AoiTool.PEN_REMOVE) && line.length) {
        dispatch.aoiTool.setPos(pointer);
      }

      if (state.aoiTool.paint) {
        dispatch.aoiTool.setLine(line.concat([pointer.x, pointer.y]));
      }

      if (state.aoiTool.tool === AoiTool.POINTER)
        dispatch.aoiAreas.setHover({ pointer, width: stage.width() });
    },
    deselectAreas(e: MouseEvent, state) {
      if ((e.target as any)?.tagName.toUpperCase() === "INPUT") {
        dispatch.aoiAreas.setStopDeselect(false);
        return;
      }

      const stage = state.aoiTool.stageRef;
      const questionDialogType = state.questionDialog.props?.type;

      if (
        questionDialogType === QuestionDialogTypes.DRAW_AREA_CLEAR ||
        questionDialogType === QuestionDialogTypes.AOI_DELETE
      ) {
        dispatch.aoiAreas.setStopDeselect(true);
        return;
      }

      if (state.aoiTool.tool !== AoiTool.POINTER) {
        return;
      }

      if (state.aoiAreas.stopDeselect) {
        dispatch.aoiAreas.setStopDeselect(false);
        return;
      }

      if (stage && state.aoiAreas.current) {
        const image = state.aoiAreas.images[state.aoiAreas.current.id];
        const aIndex = getOpacityIndexOfImageData({
          pointer: stage.getRelativePointerPosition(),
          width: stage.width(),
        });

        if (image && image.imageData.data[aIndex]) {
          return;
        }
      }

      dispatch.aoiTool.selectPointerTool(null);
    },
    selectPointerTool(_, state) {
      const disabledPermissions = disabledByPermission({
        type: DisableByPermissionsType.EDIT,
      });

      if (disabledPermissions) return;

      const stage = state.aoiTool.stageRef;

      dispatch.aoiTool.setTool(AoiTool.POINTER);

      if (stage) stage.container().style.cursor = "default";

      dispatch.aoiTool.clear(null);
      dispatch.aoiAreas.setCurrent(null);
      dispatch.aoiAreas.resetSelectedIds();
    },
    selectPenAddTool(_, state) {
      const disabledPermissions = disabledByPermission({
        type: DisableByPermissionsType.EDIT,
      });

      if (disabledPermissions || state.aoiAreas.selectedIds.size > 1) return;

      const stage = state.aoiTool.stageRef;

      dispatch.aoiTool.setTool(AoiTool.PEN_ADD);
      if (stage) stage.container().style.cursor = KONVA_ADD_CURSOR;
    },
    selectPenRemoveTool(_, state) {
      const disabledPermissions = disabledByPermission({
        type: DisableByPermissionsType.EDIT,
      });

      if (disabledPermissions || state.aoiAreas.selectedIds.size > 1) return;

      const stage = state.aoiTool.stageRef;

      dispatch.aoiTool.setTool(AoiTool.PEN_REMOVE);
      if (stage) stage.container().style.cursor = KONVA_REMOVE_CURSOR;
    },
  }),
});
