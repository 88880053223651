import { useAppDispatch, useAppSelector } from "@storeRematch";
import { SnackbarKey, useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";

const displayed = new Set();

export const Notifier = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(state => state.notifier.data);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = useCallback(
    (myKey: SnackbarKey) => {
      dispatch.notifier.removeSnackbar(myKey);
      closeSnackbar(myKey);
      displayed.delete(myKey);
    },
    [closeSnackbar, dispatch],
  );

  useEffect(() => {
    notifications.forEach(({ message, options }) => {
      if (displayed.has(options.key)) return;

      enqueueSnackbar(message, {
        ...options,
        onExited(_, myKey) {
          handleClose(myKey);
        },
        onClose(_, myKey) {
          handleClose(myKey);
        },
        onExit(_, myKey) {
          handleClose(myKey);
        },
        persist: options.persist,
      });

      displayed.add(options.key);
    });
  }, [notifications, enqueueSnackbar, dispatch, handleClose]);

  return null;
};
