import { Typography } from "@mui/material";
import { memo } from "react";

export const NoDataDefault = memo(({ name }: { name: string }) => {
  return (
    <>
      <Typography sx={{ textAlign: "center" }}>No {name} in this workspace.</Typography>
    </>
  );
});
