import { PointCloud } from "@api";
import { overlayCalculations } from "./OverlayCalculations";
import { ShapesControl, ShapesControlChildProps } from "./ShapesControl";
import { getPlConfig } from "./helpers";

export class PointCloudOverlay extends ShapesControl<PointCloud, SVGCircleElement> {
  constructor(props?: ShapesControlChildProps) {
    super({ canvasId: "PointCloud", paintType: true, ...props });
  }

  paintAll(event?: PointCloud) {
    if (!event) return;

    const ctx = this.getCtx();

    if (!ctx) return;

    const points = overlayCalculations.getPointCloudPoints(event);

    if (!points) return;

    const plConfig = getPlConfig();

    points.forEach(([x, y]) => {
      ctx.beginPath();
      ctx.arc(
        x,
        y,
        plConfig?.pointCloud?.radius ? plConfig?.pointCloud?.radius : 1,
        0,
        2 * Math.PI,
      );
      ctx.fillStyle = "rgba(255,255,255,1)";
      ctx.strokeStyle = "rgba(255,255,255,0.25)";
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.fill();
      ctx.closePath();
    });
  }
}
