import { VisualizationTypes } from "@api";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AOI_HEATMAP_TYPES, useAppSelector } from "@storeRematch";
import {
  HEATMAP_LEGEND_ARR,
  formatReadableAoiStatMs,
  getHeatmapLegendGradientColors,
} from "@utils";
import { memo } from "react";

export const HeatMapLegend = memo(() => {
  const heatmapColor = useAppSelector(state => state.aoiStats.heatmapColor);
  const heatmapType = useAppSelector(state => state.aoiStats.heatmapType);
  const max = useAppSelector(state => state.aoiStats.max);
  const noRecordings = useAppSelector(
    state =>
      state.visualizations.currentVisualization &&
      state.visualizations.currentVisualization.kind ===
        VisualizationTypes.AOI_HEATMAP &&
      !state.visualizations.currentVisualization.payload.recording_ids.length,
  );

  return (
    <Box sx={{ display: "flex", ml: 2, mr: "10%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column-reverse",
          position: "relative",
          margin: "auto",
          height: "50%",
          width: 10,
          background: getHeatmapLegendGradientColors(heatmapColor),
          py: "0.5px",
        }}
      >
        {HEATMAP_LEGEND_ARR.map(n => {
          const num =
            !heatmapType || heatmapType === AOI_HEATMAP_TYPES.REACH
              ? `${n}%`
              : heatmapType === AOI_HEATMAP_TYPES.FIXATION_COUNT
              ? `${Math.round((max[heatmapType] * n) / 100)}`
              : `${formatReadableAoiStatMs(Math.round((max[heatmapType] * n) / 100))}`;

          return (
            <Box
              key={`${n}_${heatmapType}`}
              sx={{
                width: "8px",
                height: "1px",
                ml: "10px",
                backgroundColor: "text.secondary",
                display: "flex",
              }}
            >
              <Typography variant="caption" sx={{ mt: "-5px", ml: "10px" }}>
                {noRecordings ? "" : num}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
});
