import { Button, ButtonProps } from "@mui/material";
import { FC, PropsWithChildren, useRef } from "react";
import { useFormState } from "react-hook-form";

export const SubmitButton: FC<
  PropsWithChildren<ButtonProps & { useIsDirty?: boolean; useIsValid?: boolean }>
> = ({ useIsDirty = true, useIsValid = false, disabled, children, ...props }) => {
  const { isDirty, isSubmitting, isValid } = useFormState();
  const ref = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <button
        ref={ref}
        aria-hidden="true"
        style={{ display: "hidden" }}
        disabled
        type="submit"
      ></button>
      <Button
        color="primary"
        {...props}
        disabled={
          isSubmitting ||
          (useIsDirty ? !isDirty : false) ||
          (useIsValid ? !isValid : false) ||
          disabled === true
        }
        onClick={() => {
          if (!ref.current) return;

          ref.current.disabled = false;
          ref.current.click();
          ref.current.disabled = true;
        }}
      >
        {children || "Save"}
      </Button>
    </>
  );
};
