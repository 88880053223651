import { VolumeDown, VolumeMute, VolumeUp } from "@customIcons";
import { IconButton, Slider } from "@mui/material";
import { Box } from "@mui/system";
import { useAppSelector } from "@storeRematch";
import { LocalStorageAdapterNames, localStorageAdapter } from "@utils";
import { WheelEvent, useEffect, useState } from "react";

const getDefaultSize = () => {
  return (
    localStorageAdapter.get<number | undefined>(LocalStorageAdapterNames.videoVolume) ??
    100
  );
};
const DEFAULT_CSS_SIZE = 60;

export const VideoSound = () => {
  const instance = useAppSelector(state => state.video.instance);
  const [size, setSize] = useState(getDefaultSize());
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!instance) return;

    const update = () => {
      setSize(() => instance.volume() * 100);
    };

    instance.on("volumechange", update);

    return () => {
      instance.off("volumechange", update);
    };
  }, [instance]);

  useEffect(() => {
    const next = size * 0.01;

    if (!instance || instance.isDisposed() || instance.volume() === next) return;

    instance.volume(next);
  }, [size, instance]);

  const setSizeCb = (next: number) => {
    setSize(next);

    if (next) localStorageAdapter.set(LocalStorageAdapterNames.videoVolumeToggle, next);
    localStorageAdapter.set(LocalStorageAdapterNames.videoVolume, next);
  };

  const toggleSize = () => {
    if (size) setSizeCb(0);
    else
      setSizeCb(
        localStorageAdapter.get(LocalStorageAdapterNames.videoVolumeToggle) ?? 100,
      );
  };

  const onWheel = (e: WheelEvent<HTMLButtonElement | HTMLDivElement>) => {
    const next = size + -(e.deltaY * 0.3);

    if (next >= 0 && next <= 100) setSizeCb(next);
    else if (next > 100 && size !== 100) setSizeCb(100);
    else if (next < 0 && size !== 0) setSizeCb(0);
  };

  const onMouseEnter = () => {
    setShow(true);
  };

  const onMouseLeave = () => {
    setShow(false);
  };

  return (
    <Box position="relative" zIndex={3}>
      <IconButton
        onClick={toggleSize}
        onWheel={onWheel}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {size === 0 ? <VolumeMute /> : size > 50 ? <VolumeUp /> : <VolumeDown />}
      </IconButton>
      {show && (
        <Box
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onWheel={onWheel}
          position="absolute"
          sx={{
            top: 5,
            bottom: 0,
            left: `${DEFAULT_CSS_SIZE - 25}px`,
            paddingBottom: 1,
            paddingLeft: 1,
            width: DEFAULT_CSS_SIZE,
            height: 31,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Slider
            color="secondary"
            sx={{
              color: "text.secondary",

              "& .MuiSlider-thumb": {
                color: "primary.contrastText",
                width: "8px",
                height: "8px",
              },
            }}
            onChange={(_, e) => setSizeCb(e as number)}
            size="small"
            value={size}
            valueLabelDisplay="off"
          />
        </Box>
      )}
    </Box>
  );
};
