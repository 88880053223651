import { store } from "@storeRematch";

export function copyText(title: string, text: string) {
  navigator.clipboard.writeText(text).then(
    () => {
      store.dispatch.notifier.enqueueSnackbar({
        message: title,
        options: {
          key: "copy_id_copied",
          variant: "success",
        },
      });
    },
    () => {
      store.dispatch.notifier.enqueueSnackbar({
        message: "Error copying to clipboard",
        options: {
          key: "copy_id_copied",
          variant: "error",
        },
      });
    },
  );
}
