import { api } from "@api";
import { RELEASE_NOTES_URL } from "@constants";
import { createModel } from "@rematch/core";
import { localStorageAdapter, LocalStorageAdapterNames } from "@utils";
import type { RootModel } from "./index";

// video instructions
// <video width="100%" height="100%" controls>
// <source src="url" type="video/mp4">
// Your browser does not support the video tag.
// </video>
// <br />

// image instructions
// ![Screenshot 2023-11-06 at 14.09.36.png](${require("../assets/images/releaseNotes/img0.png")})
// <br />

// link instructions
// [info@pupil-labs.com](mailto:info@pupil-labs.com)

const release: Release = {
  created: "",
  id: "1",
  product: "cloud",
  tag: "v7.2",
  title: "v7.2 - Manual Mapper, Gaze Offset, and more",
  text: `
  We are excited to announce new a big update with two new features for Pupil Cloud. We added a new manual gaze mapping enrichment - Manual Mapper - as well as the ability to offset gaze post-hoc - Gaze Offset. 

  ## Manual Mapper

  <video width="100%" height="100%" controls>
  <source src="https://assets.pupil-labs.com/cloud/manualmapper.mp4" type="video/mp4">
  Your browser does not support the video tag.
  </video>
  <br />

  This tool provides a streamlined interface enabling you to manually map fixations onto a reference image. Click on the reference image to map the fixation and the recording will automatically advance to the next fixation. Mark fixations as not on the reference image by clicking outside of the reference image boundary. 
  
  <br />
  ![Screenshot 2023-11-06 at 14.09.36.png](${require("../assets/images/releaseNotes/img0.png")})
  <br />

  You will see all fixations and their durations visualized in the timeline below.
  <br />

  

  After mapping is complete you can visualize the mapped data just as you would with any of our automated gaze mapping tools. Generate heatmaps, draw AOIs + get metrics on AOIs, and export mapped data as CSVs. 
  <br />

  

  The Manual Mapper was designed for standard fixation mapping tasks, but has a lot of potential for further exploration e.g. semantic labeling tasks. We encourage you to explore!
  
  ## Gaze Offset

  <video width="100%" height="100%" controls>
  <source src="https://assets.pupil-labs.com/cloud/gazeoffset.mp4" type="video/mp4">
  Your browser does not support the video tag.
  </video>
  <br />

  For some subjects, you may find a constant offset in their gaze estimates. This is usually compensated for in the Neon Companion App. Now you can also do this post-hoc in Pupil Cloud. Click on the gaze offset icon and drag the gaze circle to apply the offset. 
  <br />

  

  Note: Modifying the gaze offset impacts all downstream data, such as fixations, mapped gaze from enrichments, and visualizations. Where possible, data is updated instantly. If not, the respective data will be deleted, requiring (partial) re-computation of enrichments or visualizations.

  ## Heatmap Updates

  <video width="100%" height="100%" controls>
  <source src="https://assets.pupil-labs.com/cloud/aoilabels.mp4" type="video/mp4">
  Your browser does not support the video tag.
  </video>
  <br />

  We have added the ability to update the position and size of labels in AOI heatmaps. Click to select a label, drag the label to position, select and drag a corner to adjust the size. 
  <br />

  
  We also added the legend to the downloaded heatmap image.

  ## Editing the Wearer in a Recording

  It is now possible to change the assigned wearer in a recording post hoc in Pupil Cloud. Simply select “Change Wearer” from the context menu of a recording!
`,
  action: {
    title: "Read more",
    url: "https://pupil-labs.com/releases/cloud-v7-2",
  },
};

type Product = "invisible" | "core" | "neon" | "cloud";

interface Action {
  url: string;
  title: string;
}

interface Release {
  tag: string;
  id: string;
  product: Product;
  created: string;
  title: string;
  text: string;
  action?: Action;
}

interface InitialState {
  openOnboard: boolean;
  startOnboard: boolean;
  steps: number;
  currentStepIndex: number;
  openReleaseNotes: boolean;
  releaseNotesData: Release | null | undefined;
}

const initialState: InitialState = {
  openOnboard: false,
  startOnboard: false,
  steps: 0,
  currentStepIndex: 0,
  openReleaseNotes: false,
  releaseNotesData: null,
};

export const onboarding = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setSteps(state, steps: InitialState["steps"]) {
      state.steps = steps;
    },
    setCurrentStepIndex(state, step: InitialState["currentStepIndex"]) {
      state.currentStepIndex = step;
    },
    setOpenOnboard(state, openOnboard: InitialState["openOnboard"]) {
      state.openOnboard = openOnboard;
    },
    setStartOnboard(state, startOnboard: InitialState["startOnboard"]) {
      state.startOnboard = startOnboard;
    },
    setOpenReleaseNotes(state, data: InitialState["openReleaseNotes"]) {
      state.openReleaseNotes = data;
    },
    setReleaseNotesData(state, data: InitialState["releaseNotesData"]) {
      state.releaseNotesData = data;
    },
    reset(state) {
      return { ...initialState, releaseNotesData: state.releaseNotesData };
    },
  },
  effects: dispatch => ({
    async onboardUser(_, state) {
      const user = state.app.apiUser;

      if (!user) return;

      if (user.onboarded_at) {
        const currentVersion = localStorageAdapter.get(
          LocalStorageAdapterNames.releaseNotesVersion,
        );
        const tag = state.onboarding.releaseNotesData?.tag;

        if (!currentVersion || currentVersion !== tag) {
          dispatch.onboarding.showReleaseNotes(null);
        }
        return;
      }

      try {
        const res = await api.patchProfile({
          profilePatchRequest: { onboarded: true },
        });
        dispatch.app.setUser(res);
        dispatch.onboarding.setOpenOnboard(true);
      } catch (e) {
        console.error(`Error onboarding user: ${user.id}`, e);
      }
    },
    async getReleaseNotes() {
      try {
        const test = false;
        let item = release;

        // Leaving logic for later use....
        if (test) {
          if (!RELEASE_NOTES_URL) throw new Error("Release notes url does not exit.");

          const [{ releases }]: [{ releases: Release[] }] = await (
            await fetch(RELEASE_NOTES_URL)
          ).json();

          const filtered = releases
            .filter(o => o.product === "cloud")
            .sort(
              (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
            );

          item = filtered[0];
        }

        if (!item) return;

        const baseUrl = new URL(RELEASE_NOTES_URL).origin;

        item.text = item.text
          .replaceAll(' src="/', ` src="${baseUrl}/`)
          .replaceAll("<content-img", "<img")
          .replaceAll("</content-img>", "")
          .replaceAll(' class="', ' className="');

        dispatch.onboarding.setReleaseNotesData(item);
      } catch (e) {
        console.error(`Error release notes`, e);
      }
    },
    async showReleaseNotes(_, state) {
      if (!state.onboarding.releaseNotesData || !state.onboarding.releaseNotesData.tag)
        return;

      localStorageAdapter.set(
        LocalStorageAdapterNames.releaseNotesVersion,
        state.onboarding.releaseNotesData?.tag,
      );
      dispatch.onboarding.setOpenReleaseNotes(true);
    },
  }),
});
