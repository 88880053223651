import { ApriltagResult } from "@api";
import {
  aprilTagNotSelectedColor,
  aprilTagsColorOpacity,
  aprilTagSelectedColor,
} from "@constants";
import { store } from "@storeRematch";
import { DisableByPermissionsType, disabledByPermission } from "@utils";
import { Selection } from "d3";
import { overlayCalculations } from "./OverlayCalculations";
import { overlayControl } from "./OverlayControl";
import { ShapesControl, ShapesControlChildProps } from "./ShapesControl";

export class AprilTagsOverlay extends ShapesControl<ApriltagResult, SVGCircleElement> {
  availableTags: Record<string, boolean> = {};
  disabled = false;

  constructor(props?: ShapesControlChildProps) {
    super({ shapeType: "polyline", shapeIds: [], paintType: true, ...props });
  }

  hideShapes(): void {
    store.dispatch.video.setMarkerError(true);
    super.hideShapes();
  }

  toggleTag(name: string) {
    if (!overlayControl.instance) return;
    if (disabledByPermission({ type: DisableByPermissionsType.EDIT })) return;

    if (this.availableTags[name]) {
      delete this.availableTags[name];
    } else {
      this.availableTags[name] = true;
    }

    if (store.getState().projectEdit.currentSurface?.is_initialized) {
      store.dispatch.video.setDistortedLocationUsingRecording(
        overlayControl.instance.currentTime(),
      );
    }

    overlayControl.rerender();
  }

  paintAll(event?: ApriltagResult) {
    if (!event || !event.normalized_apriltags) return;

    const tags = event.normalized_apriltags;
    const shapes: Selection<SVGCircleElement, unknown, HTMLElement, any>[] = [];
    const points: string[] = [];
    const selected: boolean[] = [];
    const names: string[] = [];

    let selectedCount = 0;

    Object.keys(tags).forEach(name => {
      const { shape, shapeIdIndex } = this.firstOrCreateShape(name);

      shapes.push(shape);
      names.push(name);

      const tag = tags[this.shapeIds[shapeIdIndex]];

      if (!tag) return;

      let start = "";

      const point = tag.reduce((acc, xy, i) => {
        const scaled = overlayCalculations.distortAndScale(xy);

        if (!scaled) return acc;
        if (i === 0) start = scaled.join(",");

        return `${acc ? `${acc} ` : ""}${scaled.join(",")}`;
      }, "");

      if (this.availableTags[name]) {
        selectedCount += 1;
      }

      points.push(`${point} ${start}`);
      selected.push(this.availableTags[name]);
    });

    store.dispatch.video.setMarkerError(selected.length <= 1);
    store.dispatch.projectEdit.setIsSelectedAprilTagVisible(selectedCount > 0);

    shapes.forEach((s, i) => {
      const isSelected = selected[i];
      const name = names[i];
      const point = points[i];

      const color = isSelected ? aprilTagSelectedColor : aprilTagNotSelectedColor;

      this.showShape(s);
      s.attr("fill", color)
        .attr("fill-opacity", aprilTagsColorOpacity)
        .attr("points", point)
        .style("cursor", "not-allowed")
        .style("stroke-width", "1px")
        .style("stroke", color)
        .on("click", null);

      if ((selectedCount > 1 || !isSelected) && !this.disabled) {
        s.style("cursor", "pointer").on("click", () => this.toggleTag(name));
      }
    });
  }
}
