import { createModel } from "@rematch/core";
import {
  LabelsTableData,
  ProjectsTableData,
  RecordingWithData,
  RootModel,
  TemplatesTableData,
  ViewDetailsTypes,
  WearersTableData,
} from "./index";

type StateGenerator<T extends ViewDetailsTypes, M> = { type: T; model: M };

type DataType =
  | StateGenerator<ViewDetailsTypes.RECORDING, RecordingWithData>
  | StateGenerator<ViewDetailsTypes.LABEL, LabelsTableData>
  | StateGenerator<ViewDetailsTypes.WEARER, WearersTableData>
  | StateGenerator<ViewDetailsTypes.TEMPLATE, TemplatesTableData>
  | StateGenerator<ViewDetailsTypes.PROJECT, ProjectsTableData>;

interface InitialState {
  data?: DataType;
}

const initialState: InitialState = {};

export const viewDetails = createModel<RootModel>()({
  state: initialState,
  reducers: {
    set(state, data: InitialState["data"]) {
      state.data = data;
    },
    reset(state) {
      state.data = undefined;
    },
  },
  effects: dispatch => ({
    viewRecordingFirst(_, state) {
      const id = state.entityTable.selectedIds[0];

      if (!id) return;

      const model = state.recordings.tableDataById.get(id);

      if (!model) return;

      dispatch.viewDetails.set({ type: ViewDetailsTypes.RECORDING, model });
    },
    viewRecording(id: string, state) {
      const model = state.recordings.tableDataById.get(id);

      if (!model) return;

      dispatch.viewDetails.set({ type: ViewDetailsTypes.RECORDING, model });
    },
    viewLabel(id: string, state) {
      const model = state.labels.tableDataById.get(id);

      if (!model) return;

      dispatch.viewDetails.set({ type: ViewDetailsTypes.LABEL, model });
    },
    viewWearer(id: string, state) {
      const model = state.wearers.tableDataById.get(id);

      if (!model) return;

      dispatch.viewDetails.set({ type: ViewDetailsTypes.WEARER, model });
    },
    viewTemplate(id: string, state) {
      const model = state.templates.tableDataById.get(id);

      if (!model) return;

      dispatch.viewDetails.set({ type: ViewDetailsTypes.TEMPLATE, model });
    },
    viewProject(id: string, state) {
      const model = state.projects.tableDataById.get(id);

      if (!model) return;

      dispatch.viewDetails.set({ type: ViewDetailsTypes.PROJECT, model });
    },
    viewCurrentRecording(_, state) {
      const id = state.entityTable.selectedIds[0];
      const model = state.recordings.tableDataById.get(id);

      if (!model) return;

      dispatch.viewDetails.set({ type: ViewDetailsTypes.RECORDING, model });
    },
  }),
});
