import { Button, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { RouterHelper } from "@pages";
import { useAppSelector } from "@storeRematch";
import { DisableByPermissionsType, disabledByPermission } from "@utils";
import { matchPath, useLocation } from "react-router-dom";
import { GlobalModalTypes, createGlobalModalRoute } from "./GlobalModal";
import { ReactRouterLinkCustom } from "./ReactRouterLinkCustom";

export const QuickCreateProjectModel = () => {
  const location = useLocation();
  const workspace = !!matchPath(
    RouterHelper.workspaceRecordings.fullTemplatePath,
    location.pathname,
  );
  const selectedIds = useAppSelector(state => state.entityTable.selectedIds);
  const disabledPermissionsCreate = disabledByPermission({
    type: DisableByPermissionsType.CREATE,
  });
  const inactive = useAppSelector(state => state.entityTable.quickFilter.isInactive);

  if (disabledPermissionsCreate || selectedIds.length < 2 || !workspace || inactive)
    return null;

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 20,
        zIndex: 10,
        left: 0,
        right: 0,
        margin: "auto",
        width: "60%",
        maxWidth: 335,
      }}
    >
      <Paper
        sx={{
          py: 1,
          px: 1.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {selectedIds.length} selected
        <Button
          variant="text"
          color="primary"
          sx={{ ml: 2 }}
          component={ReactRouterLinkCustom}
          to={createGlobalModalRoute(GlobalModalTypes.PROJECT_CREATE_SELECTION)}
        >
          Create project with selection
        </Button>
      </Paper>
    </Box>
  );
};
