import { api, Label, PatchLabelApiArg, PostLabelApiArg } from "@api";
import { createModel } from "@rematch/core";
import { rematchAdapter, RematchAdapter } from "@utils";
import i18n from "../i18n";
import type { LabelsTableData, RootModel } from "./index";

interface Adapter extends RematchAdapter<Label, LabelsTableData> {}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.id,
  extraProps: {},
});

export const labels = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
  },
  effects: dispatch => ({
    async get(workspaceId: string) {
      const data = await api.getLabels({ workspaceId });

      dispatch.labels.setAll(data.result as Label[]);
    },
    async create(props: PostLabelApiArg) {
      const data = await api.postLabel(props);

      if (typeof data !== "object") return null;

      dispatch.labels.upsertOne(data.result);

      dispatch.labels.prepare(null);

      return data.result;
    },
    async update(props: PatchLabelApiArg) {
      const data = await api.patchLabel(props);

      dispatch.labels.upsertOne(data.result);
      dispatch.labels.prepare(null);
      dispatch.recordings.prepare(null);
    },
    async delete(ids: string[], state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;

      if (!workspaceId) return null;

      try {
        await api.deleteLabels({
          workspaceId,
          labelsDeleteRequest: { label_ids: ids },
        });

        dispatch.labels.deleteMany(ids);
        dispatch.labels.prepare(null);
        dispatch.recordings.prepare(null);

        const message = ids.length
          ? i18n.t("Labels deleted").toString()
          : i18n.t("Label deleted").toString();
        dispatch.notifier.enqueueSnackbar({
          message,
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    prepare(_, state) {
      const labels = state.labels.data;
      const counters = state.recordings.counters;
      const tableDataById = new Map<string, LabelsTableData>();

      const tableData: LabelsTableData[] = labels.map(label => {
        const next: LabelsTableData = {
          id: label.id,
          label,
          recordingsCount: counters.labels[label.id]?.count || 0,
        };
        tableDataById.set(label.id, next);
        return next;
      });

      dispatch.labels.setTableData(tableData);
      dispatch.labels.setTableDataById(tableDataById);
    },
    getLabelState(id: string, state) {
      const next = { checked: false, indeterminate: false };
      const selectedIds = state.entityTable.selectedIds;
      const labelCounter = state.recordings.counters.labels[id];

      if (labelCounter) {
        selectedIds.forEach((recordingId, index) => {
          next.checked =
            labelCounter.recordingIds.has(recordingId) &&
            (index === 0 || next.checked === true);

          if (!next.indeterminate) {
            next.indeterminate = labelCounter.recordingIds.has(recordingId);
          }
        });
      }

      return {
        checked: next.checked,
        indeterminate: !next.checked && next.indeterminate,
      };
    },
    async createAndAddToRecordings(name: string, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;

      if (!workspaceId) return;

      const label = await dispatch.labels.create({
        workspaceId,
        labelPostRequest: { name, color: "#00B4FA" },
      });

      if (label) dispatch.recordings.addLabelsToSelected([label.id]);
    },
  }),
});
