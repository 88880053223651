import { Box, Chip, ChipProps } from "@mui/material";

interface LabelChipProps extends ChipProps {
  bgColor: string | null;
}

export const LabelChip = ({ bgColor, ...props }: LabelChipProps) => {
  const backgroundColor = bgColor?.includes("#") ? bgColor : `#${bgColor}`;

  return (
    <Chip
      variant="outlined"
      size="small"
      icon={
        <Box
          sx={{
            minWidth: "8px",
            minHeight: "8px",
            backgroundColor,
            borderRadius: "50%",
          }}
        />
      }
      {...props}
    />
  );
};
