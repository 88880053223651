import { Recording } from "@api";
import { useAppSelector } from "@storeRematch";
import { FC, memo } from "react";
import { VideoDivider } from "./VideoComponentsBase";
import { VideoControls } from "./VideoControls";
import { VideoEvents } from "./VideoEvents";
import { VideoWrapper } from "./VideoWrapper";

interface VideoPlayerProps {
  recording?: Recording;
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ recording }) => {
  return (
    <>
      <VideoWrapper recording={recording} />
      <VideoControls />
      <VideoDivider />
      <VideoEvents />
    </>
  );
};

export const RecordingViewer = memo(() => {
  const current = useAppSelector(state =>
    state.entityTable.selectedIds.length
      ? state.recordings.dataById.get(state.entityTable.selectedIds[0])
      : undefined,
  );

  //if (!current || !current.transcoded_url) return null;

  return <VideoPlayer recording={current} />;
});
