import { RouterHelper } from "@pages";
import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { createContextWrapper } from "./createContextWrapper";

const useValue = () => {
  const [previousPage, setPreviousPage] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    const locationState = location.state as {
      previous?: {
        pathname: string;
      };
    };

    if (!locationState || !locationState.previous) return;

    const workspacesPath = !!matchPath(
      RouterHelper.workspaceSettings.fullTemplatePathAny,
      locationState.previous.pathname,
    );
    const projectsPath = !!matchPath(
      RouterHelper.accountSettings.fullTemplatePathAny,
      locationState.previous.pathname,
    );

    if (workspacesPath || projectsPath) return;

    const previousPage =
      locationState.previous.pathname !== location.pathname
        ? locationState.previous.pathname
        : null;

    if (previousPage) setPreviousPage(previousPage);
  }, [location]);

  return { previousPage };
};

export const [BackButtonProvider, useBackButton] = createContextWrapper(useValue, {
  provider: "BackButtonProvider",
  useContext: "BackButtonUseContext",
});
