import { api, WorkspaceInvitation, WorkspaceMembership } from "@api";
import { createModel } from "@rematch/core";
import { rematchAdapter, RematchAdapter } from "@utils/rematchAdapter";
import i18n from "../i18n";
import type { RootModel } from "./index";

interface Adapter extends RematchAdapter<WorkspaceInvitation> {}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.id,
  extraProps: {},
});

export const invites = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
  },
  effects: dispatch => ({
    prepare() {},
    async get(workspaceId: string, state) {
      const role = state.app.currentWorkspaceMembership?.role;

      if (!role || (role !== "ADMIN" && role !== "OWNER")) return;

      const res = await api.getWorkspaceInvitation({ workspaceId, token: "" });

      dispatch.invites.setAll(res.result);
    },
    async createInvite(data: { emails: string[]; role: string }, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;

      if (!workspaceId) return;

      try {
        const arr = data.emails.map(email =>
          api.createWorkspaceInvitation({
            workspaceId,
            workspaceInvitationPostRequest: {
              email,
              role: data.role as WorkspaceMembership["role"],
            },
          }),
        );

        const res = await Promise.all(arr);

        res.map(r => dispatch.invites.upsertOne(r.result));

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Invites sent").toString(),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async resendInvite(data: WorkspaceInvitation) {
      await dispatch.invites.createInvite({ emails: [data.email], role: data.role });
      dispatch.invites.deleteMany([data.id]);
    },
    deleteByEmail(email: string, state) {
      const data = state.invites.data.filter(i => i.email === email).map(i => i.id);

      if (!data.length) return;

      dispatch.invites.deleteMany(data);
      dispatch.invites.prepare();
    },
  }),
});
