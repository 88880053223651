import { RecordingWithData, useAppSelector } from "@storeRematch/index";
import { useMemo } from "react";

export const useVisualizationRecordings = (enrichmentId: string) => {
  const recordingsByEnrichment = useAppSelector(state =>
    state.enrichments.enrichmentsWithRecordings.get(enrichmentId),
  );
  const recordingsById = useAppSelector(state => state.recordings.tableDataById);

  const recordings = useMemo(() => {
    const ids = Array.from(recordingsByEnrichment || []);

    return ids.reduce((acc, id) => {
      if (recordingsById.has(id)) acc.push(recordingsById.get(id) as RecordingWithData);

      return acc;
    }, [] as RecordingWithData[]);
  }, [recordingsByEnrichment, recordingsById]);

  return recordings;
};

export const useVisualizationRecordingIds = (
  enrichmentId: string,
  recordingIds: string[],
) => {
  const data = useVisualizationRecordings(enrichmentId);

  const ids = useMemo(() => {
    const recordingIdsSet = new Set(recordingIds);

    return data.reduce((acc, one) => {
      if (recordingIdsSet.has(one.id)) acc.push(one.id);

      return acc;
    }, [] as string[]);
  }, [data, recordingIds]);

  return ids;
};
