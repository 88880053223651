import { ProjectEnrichment } from "@api";
import { EntityDataTable, NoDataDefault, ReactRouterLinkCustom } from "@components";
import { INACTIVE_FIELD_NAME } from "@constants";
import { Button } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { RouterHelper } from "@pages";
import { TemplatesTableData, useAppDispatch, useAppSelector } from "@storeRematch";
import {
  DisableByPermissionsType,
  LocalStorageAdapterNames,
  disabledByPermission,
} from "@utils";
import { Outlet, useNavigate } from "react-router-dom";
import TimeAgo from "timeago-react";
import { v4 as uuidv4 } from "uuid";
import { TemplatesContextMenu } from "./TemplatesContextMenu";

export const Templates = () => {
  const navigate = useNavigate();
  const rows = useAppSelector(state => state.templates.tableData);
  const isLoading = useAppSelector(
    state => state.loading.effects.app.loadWorkspaceData.loading,
  );

  return (
    <>
      <EntityDataTable
        loading={isLoading}
        rows={rows}
        columns={columns}
        noDataMessage={<NoDataDefault name="templates" />}
        noFilters
        initialState={{
          columns: {
            columnVisibilityModel: {
              [INACTIVE_FIELD_NAME]: false,
            },
          },
        }}
        tableColumnInitialStateStorageKey={
          LocalStorageAdapterNames.settingsTemplatesColumState
        }
        onRowDoubleClick={(props: GridRowParams<ProjectEnrichment>) => {
          navigate(
            RouterHelper.workspaceSettingsTemplatesEdit.getRelativePath({
              props: { templateId: props.row.id },
            }),
          );
        }}
      >
        <CreateButton />
      </EntityDataTable>
      <TemplatesContextMenu />
      <Outlet />
    </>
  );
};

const CreateButton = () => {
  const id = uuidv4();
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(
    state => state.loading.models.app.loading || state.loading.models.templates.loading,
  );
  const disabledPermissions = disabledByPermission({
    type: DisableByPermissionsType.CREATE,
  });

  const onClick = async () => {
    await dispatch.templates.create(id);
  };

  return (
    <Button
      component={ReactRouterLinkCustom}
      to={RouterHelper.workspaceSettingsTemplatesEdit.getRelativePath({
        props: { templateId: id },
      })}
      onClick={onClick}
      replace={true}
      disabled={disabled || disabledPermissions}
      color="primary"
    >
      New Template
    </Button>
  );
};

const columns: GridColDef<TemplatesTableData>[] = [
  {
    width: 200,
    field: "name",
    headerName: "Name",
    valueGetter: (_, row) => row.template.name,
  },
  {
    field: "recordingsCount",
    headerName: "Recordings",
    type: "number",
    align: "left",
  },
  {
    field: "status",
    headerName: "Status",
    valueGetter: (_, row) => (row.template.published_at ? "Published" : "Draft"),
  },
  {
    field: "created_at",
    headerName: "Created",
    type: "date",
    valueGetter: (_, row) =>
      row.template.created_at ? new Date(row.template.created_at) : null,
    renderCell: params => {
      return (
        params.row.template.created_at && (
          <TimeAgo
            datetime={params.row.template.created_at}
            opts={{ minInterval: 60 }}
          />
        )
      );
    },
  },
  {
    field: "created_by_user_id",
    headerName: "Created By",
    valueGetter: (_, row) => row.createdBy?.user?.name,
  },
  {
    field: INACTIVE_FIELD_NAME,
    headerName: "Trashed",
    type: "boolean",

    valueGetter: (_, row) => {
      return row.template.archived_at ? true : false;
    },
  },
];
