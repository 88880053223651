import { EnrichmentTypesEnum, ProjectEnrichment } from "@api";
import { Check, Remove, Warning } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

export function useGetEnrichmentStatusIcon(
  model?: ProjectEnrichment,
  disabled?: boolean,
) {
  if (!model) return undefined;

  if (model.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER) {
    const { checked, total } = getManualMapperNumbers(model);

    return (
      <CircularProgress
        size={16}
        sx={{ color: "text.secondary" }}
        variant="determinate"
        value={(checked / total) * 100}
      />
    );
  }

  if (model.status?.ERROR) {
    return <Warning fontSize="small" color="warning" />;
  }

  if (!model)
    return disabled ? undefined : (
      <CircularProgress size={16} color="inherit" variant="determinate" value={100} />
    );

  if (model.status?.READY || model.status?.STALE) {
    return (
      <CircularProgress
        size={16}
        sx={{ color: "text.secondary" }}
        variant="determinate"
        value={100}
      />
    );
  }

  if (model.status?.COMPUTING) {
    return <CircularProgress size={16} sx={{ color: "text.primary" }} />;
  }

  if (model.status?.SUCCESS === 1) {
    return <Check fontSize="small" />;
  }

  return disabled ? undefined : <Remove fontSize="small" />;
}

export function getEnrichmentStatusText(model?: ProjectEnrichment) {
  if (!model) return;

  if (model.status?.ERROR) {
    return "Error";
  }

  if (model.status?.COMPUTING) {
    return "Running";
  }

  if (model.status?.SUCCESS === 1) {
    return "Completed";
  }

  return "";
}

export function getEnrichmentStatusTextFull(model?: ProjectEnrichment) {
  if (!model) return;

  if (model.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER) {
    const { checked, total } = getManualMapperNumbers(model);

    if (checked === total) return "Completed";

    return `${((checked / total) * 100).toFixed(0)}% Mapping`;
  }

  if (model.status?.COMPUTING) {
    const num = Math.ceil((1 - model.status.COMPUTING) * 100);

    return `Processing ${num ? `${num}%` : "..."}`;
  }

  const text = getEnrichmentStatusText(model);

  if (!text) return "Not started";

  return text;
}

function getManualMapperNumbers(model: ProjectEnrichment) {
  if (model.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER)
    return { checked: 0, total: 0 };

  return model.fixations_status.reduce(
    (acc, next) => {
      acc.checked += next.checked_fixations;
      acc.total += next.total_fixations;
      return acc;
    },
    { checked: 0, total: 0 },
  );
}
