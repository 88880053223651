import { store } from "@storeRematch";
import { downloadLocallyBlob, extractErrorMessage } from "@utils";

interface FetchBaseProps {
  url: string;
  method?: string;
  body?: any;
  headers?: object;
  params?: object;
  disableAbort?: boolean;
  disableError?: boolean;
}

const controllers: Record<string, AbortController | null> = {};

export async function fetchBase<T = void>(props: FetchBaseProps): Promise<T> {
  const state = store.getState();
  const baseUrl = state.app.apiUrl;
  const apiKey = state.app.apiKey;
  const headers: HeadersInit = { ...props.headers, "content-type": "application/json" };

  if (apiKey) {
    headers["x-api-key"] = apiKey;
  }

  let nextAbortController: AbortController | null = null;

  if (!props.disableAbort) {
    const abortName = `${props.url.split("?")[0]}_${props.url.includes("detect")}`;
    const controller = controllers[abortName];

    if (controller && (!props.method || props.method.toUpperCase() === "GET")) {
      controller.abort();
      delete controllers[abortName];
    }

    nextAbortController = new AbortController();
    controllers[abortName] = nextAbortController;
  }

  const res = await fetch(`${baseUrl}${props.url}`, {
    method: props.method,
    credentials: "include",
    cache: "default",
    body: props.body ? JSON.stringify(props.body) : undefined,
    headers,
    signal: nextAbortController?.signal,
  });

  if (!res.ok && !props.url.includes("/invites/")) {
    const error = await res.json();

    if (res.status === 401) {
      store.dispatch.app.reset();
    } else {
      if (!props.disableError) {
        store.dispatch.notifier.enqueueSnackbar({
          message: extractErrorMessage(error),
          options: {
            variant: "error",
          },
        });
      }
    }

    throw error;
  }

  const headerType = res.headers.get("content-type");

  if (headerType?.endsWith("zip")) {
    let fileName =
      res.headers.get("content-disposition")?.split("filename=")[1].split(";")[0] ||
      "unknown.zip";

    if (fileName.startsWith('"')) fileName = fileName.substring(1, fileName.length);
    if (fileName.endsWith('"')) fileName = fileName.substring(0, fileName.length - 1);

    const blob = await res.blob();
    downloadLocallyBlob({ blob, name: fileName });

    return null as T;
  }

  if (headerType?.endsWith("jpeg")) {
    const blob = await res.blob();
    const url = window.URL.createObjectURL(blob);

    return url as T;
  }

  return res.json();
}
