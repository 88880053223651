import { LabelsTableData } from "@storeRematch";
import { formatHexColor } from "@utils/formatHexColor";
import { formatTimeZone } from "@utils/formatTimeZone";
import { DetailItem } from "./DetailItem";

export const LabelContent: React.FC<{ model: LabelsTableData }> = ({ model }) => {
  return (
    <>
      <DetailItem
        name="ID"
        content={model.label.id}
        copyTextProps={{ copy: { title: "Label", text: model.label.id } }}
      />
      <DetailItem name="Name" content={model.label.name} />
      <DetailItem name="Recordings" content={model.recordingsCount} />
      <DetailItem name="Color" color={formatHexColor(model.label.color)} />
      <DetailItem name="Hex Color" content={formatHexColor(model.label.color)} />
      <DetailItem name="Created" content={formatTimeZone(model.label.created_at)} />
      <DetailItem name="Updated" content={formatTimeZone(model.label.updated_at)} />
      <DetailItem name="Description" content={model.label.description} />
    </>
  );
};
