import { ENVIRONMENT, GOOGLE_ANALYTICS_4_ID, MUI_KEY, SENTRY_DSN } from "@constants";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "@styles/index.css";
import "cropperjs/dist/cropper.min.css";
import Konva from "konva";
import ReactDOMClient from "react-dom/client";
import ReactGA from "react-ga4";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";

import { localStorageAdapter, LocalStorageAdapterNames } from "@utils";

// overrides console functions with prefixed version
export * from "./logger";
export * from "./storeRematch";

Konva.pixelRatio = 2;

// check canny sso redirect param on load
const searchParams = new URLSearchParams(window.location.search);
if (searchParams.has("canny-sso")) {
  const redirect = searchParams.get("redirect");
  const companyId = searchParams.get("companyID");
  if (
    (redirect?.startsWith("https://canny") ||
      redirect?.startsWith("https://feedback.pupil-labs.com")) &&
    companyId
  ) {
    localStorageAdapter.set(LocalStorageAdapterNames.cannySsoRedirect, redirect);
    localStorageAdapter.set(LocalStorageAdapterNames.cannyCompanyId, companyId);
  }
}

if (MUI_KEY) LicenseInfo.setLicenseKey(MUI_KEY);

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: ENVIRONMENT,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

if (GOOGLE_ANALYTICS_4_ID) {
  ReactGA.initialize(GOOGLE_ANALYTICS_4_ID);
}

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container as HTMLElement);

localStorageAdapter.clearUnneeded();

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
