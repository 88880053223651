import { TextFieldChip } from "@components/TextFieldChip";
import { Chip, ChipProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { useTranslation } from "react-i18next";
import {
  TemplateItemBase,
  TemplateItemBody,
  TemplateItemTitle,
} from "./TemplateItemBase";

function getItemName(one: string) {
  return one.replaceAll(/\{|\}/g, "");
}

function getItemColor(one: string): ChipProps["color"] {
  return one.match(/\{|\}/g) ? "primary" : "default";
}

export const TemplateRecordName = () => {
  const { t } = useTranslation();
  const nameFormat = useAppSelector(state => state.templateForm.draft.nameFormat);
  const availableFormat = useAppSelector(
    state => state.templateForm.draft.availableFormat,
  );
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(
    state =>
      (state.templateForm.current !== null &&
        state.templateForm.current.published_at !== null) ||
      state.loading.models.templates.loading ||
      state.loading.models.app.loading,
  );

  const handleClear = () => {
    dispatch.templateForm.clearNameFormat();
  };

  const handleDelete = (index: number) => {
    dispatch.templateForm.removeNameFormat(index);
  };

  const handleAdd = (name: string) => {
    dispatch.templateForm.addNameFormat(name);
  };

  return (
    <TemplateItemBase>
      <TemplateItemTitle>{t("Recording Name")}</TemplateItemTitle>

      <TemplateItemBody>
        <TextFieldChip
          onAdd={handleAdd}
          onClear={handleClear}
          onDelete={handleDelete}
          options={nameFormat}
          getItemName={getItemName}
          getItemColor={getItemColor}
          disabled={disabled}
        />

        <Box display="flex" flexWrap="wrap" alignItems="center" gap={1} mt={2}>
          <Typography variant="body3">{t("Components")}</Typography>
          {availableFormat.map((one, index) => (
            <Chip
              disabled={disabled}
              key={`${one}-${index}`}
              label={getItemName(one)}
              onClick={() => handleAdd(one)}
              color="primary"
            />
          ))}
        </Box>

        {!nameFormat.length && (
          <Typography mt={2} color="error">
            {t("Recording name cannot be empty. Please select and/or enter text.")}
          </Typography>
        )}
      </TemplateItemBody>
    </TemplateItemBase>
  );
};
