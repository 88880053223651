import { WorkspaceInvitation, WorkspaceMember } from "@api";
import { EntityDataTable, NoDataMembers } from "@components";
import { UserAvatar } from "@components/Common/StringAvatar";
import { Select, SubmitButton, TextFieldChip } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select as MuiSelect,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { store, useAppDispatch, useAppSelector } from "@storeRematch";
import {
  DisableByPermissionsType,
  LocalStorageAdapterNames,
  disabledByPermission,
  getSelectRoles,
} from "@utils";
import { isBefore } from "date-fns";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import TimeAgo from "timeago-react";
import * as yup from "yup";
import { MembersContextMenu } from "./MembersContextMenu";

export const Members = () => {
  const rows = useAppSelector(state => state.members.data);
  const invites = useAppSelector(state => state.invites.data);
  const isLoading = useAppSelector(
    state => state.loading.effects.app.loadWorkspaceData.loading,
  );

  return (
    <>
      <EntityDataTable
        titleComponent={<Title />}
        getRowId={row => row.user_id || row.id}
        loading={isLoading}
        rows={rows.concat(invites as any)}
        columns={columns}
        noFilters
        noColumnFilter
        noDataMessage={<NoDataMembers />}
        tableColumnInitialStateStorageKey={
          LocalStorageAdapterNames.settingsMembersColumState
        }
      >
        <Invite />
      </EntityDataTable>
      <MembersContextMenu />
      <Outlet />
    </>
  );
};

const columns: GridColDef<WorkspaceMember | WorkspaceInvitation>[] = [
  {
    width: 200,
    field: "name",
    headerName: "Name",
    valueGetter: (_, row) =>
      (row as WorkspaceMember).user ? (row as WorkspaceMember).user?.name : "Pending",
    renderCell: params => {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }}
        >
          <UserAvatar user={(params.row as WorkspaceMember).user} />
          <Typography ml={1.5} noWrap>
            {(params.row as WorkspaceMember).user?.name || "Pending"}
          </Typography>
        </Box>
      );
    },
  },
  {
    width: 200,
    field: "email",
    headerName: "Email",
    valueGetter: (_, row) =>
      (row as WorkspaceMember).user?.email || (row as WorkspaceInvitation).email,
  },
  {
    field: "role",
    headerName: "Role",
    valueGetter: (_, row) =>
      (row as WorkspaceMember).role || (row as WorkspaceInvitation).role,
    renderCell: params => {
      return (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          {(params.row as WorkspaceInvitation).token ? (
            (params.row as WorkspaceInvitation).role
          ) : (
            <ChangeRole
              userId={(params.row as WorkspaceMember).user_id}
              role={(params.row as WorkspaceMember).role}
            />
          )}
        </Box>
      );
    },
  },
  {
    field: "created_at",
    headerName: "Invited",
    type: "date",
    valueGetter: (_, row) => {
      return row.created_at ? new Date(row.created_at) : null;
    },
    renderCell: params => {
      return (
        params.row.created_at && (
          <TimeAgo datetime={params.row.created_at} opts={{ minInterval: 60 }} />
        )
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    filterable: false,
    valueGetter: (_, row) => {
      return getStatusText({ params: row });
    },
    renderCell: params => {
      return <StatusCell params={params.row} />;
    },
  },
  {
    field: "action",
    headerName: "",
    type: "boolean",
    width: 120,
    sortable: false,
    resizable: false,
    renderCell: params => {
      if (!(params.row as WorkspaceInvitation).token) return <></>;

      return (
        <Button
          variant="text"
          color="primary"
          onClick={() =>
            store.dispatch.invites.resendInvite(params.row as WorkspaceInvitation)
          }
        >
          Resend Invite
        </Button>
      );
    },
  },
];

const StatusCell = ({ params }: { params: WorkspaceMember | WorkspaceInvitation }) => {
  const ownerIdOverride = useAppSelector(
    state => state.app.currentWorkspaceMembership?.workspace.created_by_user_id,
  );

  return <>{getStatusText({ params, ownerIdOverride })}</>;
};

function getStatusText({
  params,
  ownerIdOverride,
}: {
  params: WorkspaceMember | WorkspaceInvitation;
  ownerIdOverride?: string;
}) {
  const ownerId =
    ownerIdOverride ||
    store.getState().app.currentWorkspaceMembership?.workspace.created_by_user_id;

  if (
    (params as WorkspaceInvitation).expires_at &&
    isBefore(new Date((params as WorkspaceInvitation).expires_at as string), new Date())
  )
    return "Expired";

  if ((params as WorkspaceInvitation).token) return "Pending";

  if ((params as WorkspaceMember).user_id === ownerId) return "";

  return "Accepted";
}

const ChangeRole = memo(({ userId, role }: { userId: string; role: any }) => {
  const isLoading = useAppSelector(
    state =>
      state.loading.models.app.loading ||
      state.loading.effects.members.changeRole.loading,
  );
  const currentRole = useAppSelector(
    state => state.app.currentWorkspaceMembership?.role,
  );
  const options = getSelectRoles(currentRole);
  const dispatch = useAppDispatch();

  if (!currentRole) return null;

  if (!options.find(o => o.value === role)) {
    return <Typography variant="body1">{role}</Typography>;
  }

  return (
    <MuiSelect
      fullWidth
      defaultValue={role}
      disabled={isLoading}
      onChange={a => dispatch.members.changeRole({ userId, role: a.target.value })}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </MuiSelect>
  );
});

const Title = memo(() => {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Typography variant="subtitle2" mb={2}>
          {t("Manage Members")}
        </Typography>
        <Typography maxWidth={600} mb={2}>
          {t(
            "Manage members in this workspace. You can invite other users to collaborate on Pupil Cloud using their email address.",
          )}
        </Typography>
      </Box>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
    </>
  );
});

const schema = yup
  .object()
  .shape({
    emails: yup.array(yup.string().email()).min(1),
    role: yup.string().required(),
  })
  .required();

const Invite = memo(() => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const role = useAppSelector(state => state.app.currentWorkspaceMembership?.role);
  const rolesCalc = getSelectRoles(role);

  const methods = useForm({
    defaultValues: { emails: [], role: rolesCalc[1]?.value },
    resolver: yupResolver(schema),
  });

  const handleSubmit = async (form: any) => {
    await dispatch.invites.createInvite(form);
    methods.reset();
  };

  return (
    <Box width="100%" my={2}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(handleSubmit)}
          onKeyDown={e => (e.key === "Enter" ? e.preventDefault() : null)}
          noValidate
        >
          <Box display="flex">
            <TextFieldChip
              name="emails"
              placeholder="Type and press enter to add emails"
              disabled={!rolesCalc.length}
              isError={name =>
                !RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g).test(name)
              }
            />
            <Select
              name="role"
              options={rolesCalc}
              sx={{ ml: 2, width: "20%" }}
              variant="outlined"
              size="small"
              disabled={!rolesCalc.length}
            />
            <SubmitButton
              disabled={disabledByPermission({
                type: DisableByPermissionsType.INVITE_MEMBERS,
              })}
              sx={{ ml: 2, width: "20%" }}
            >
              {t("Send Invite")}
            </SubmitButton>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
});
