import { ProjectEnrichmentPostRequest } from "@api";
import { AprilTagsOverlay } from "./AprilTagsOverlay";
import { FaceOverlay } from "./FaceOverlay";
import { GazeAOIOverlay, GazeOffsetOverlay, GazeOverlay } from "./GazeOverlay";
import { overlayControl } from "./OverlayControl";
import { PointCloudOverlay } from "./PointCloud";
import { ScanPathOverlay } from "./ScanPathOverlay";
import { StaticImageMapperOverlay } from "./StaticImageMapperOverlay";
import { SurfaceCornersOverlay } from "./SurfaceCornersOverlay";

export * from "./OverlayCalculations";
export * from "./OverlayControl";
export * from "./ShapesControl";
export * from "./helpers";

export const gazeOverlay = new GazeOverlay();
export const gazeAOIOverlay = new GazeAOIOverlay();
export const aprilTagOverlay = new AprilTagsOverlay();
export const surfaceCornersOverlay = new SurfaceCornersOverlay();
export const scanPathOverlay = new ScanPathOverlay();
export const faceOverlay = new FaceOverlay();
export const pointCloudOverlay = new PointCloudOverlay();
export const staticImageMapperOverlay = new StaticImageMapperOverlay();
export const gazeOffsetOverlay = new GazeOffsetOverlay();

overlayControl.models.push(gazeOverlay);
overlayControl.models.push(gazeAOIOverlay);
overlayControl.models.push(aprilTagOverlay);
overlayControl.models.push(surfaceCornersOverlay);
overlayControl.models.push(scanPathOverlay);
overlayControl.models.push(faceOverlay);
overlayControl.models.push(pointCloudOverlay);
overlayControl.models.push(staticImageMapperOverlay);
overlayControl.models.push(gazeOffsetOverlay);

export function getEnrichmentOverlays(
  kind: ProjectEnrichmentPostRequest["kind"] | "gaze",
) {
  switch (kind) {
    case "face-mapper":
      return [faceOverlay];
    case "marker-mapper":
      return [aprilTagOverlay, surfaceCornersOverlay];
    case "raw-data-export":
      return undefined;
    case "render":
      return [scanPathOverlay];
    case "gaze":
      return [gazeOverlay];
    case "slam-mapper":
      return [pointCloudOverlay];
    case "static-image-mapper":
      return undefined;
    default:
      console.error("getEnrichmentOverlays unknown type");
      return undefined;
  }
}
