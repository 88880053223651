import {
  EnrichmentTypesEnum,
  ManualMapperFixationStatus,
  ProjectEnrichment,
  ProjectEnrichmentSlice,
} from "@api";
import {
  EntityDataTable,
  EntityDataTableProps,
  FilterLabelChip,
  NoDataRecordings,
} from "@components";
import {
  recordingCreatedByFilterOperatorsParams,
  recordingLabelsFilterOperatorsParams,
  recordingTemplateNameFilterOperatorsParams,
  recordingWearerFilterOperatorsParams,
} from "@components/filter/utils";
import { Thumbnail } from "@components/recordings/Thumbnail";
import { INACTIVE_FIELD_NAME } from "@constants";
import { Box, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  RecordingWithData,
  store,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import {
  LocalStorageAdapterNames,
  localStorageAdapter,
  recordingHasEvents,
  useIsRunDisabledCheck,
} from "@utils";
import { memo, useEffect, useMemo } from "react";
import TimeAgo from "timeago-react";
import { ProjectContextMenu } from "./ProjectContextMenu";

type AddEnrichmentStatus = Record<
  ProjectEnrichmentSlice["status"] | "NO_EVENTS" | "NOT_READY",
  number
> & { MAPPING?: ManualMapperFixationStatus };

function addEnrichmentStatus(enrichment?: ProjectEnrichment, data?: RecordingWithData) {
  const hasEvents = recordingHasEvents(enrichment, data?.id);

  if (!hasEvents) return { NO_EVENTS: 1 } as AddEnrichmentStatus;

  if (enrichment?.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER) {
    const staticStatus = {} as AddEnrichmentStatus;

    if (!enrichment.fixations_status) {
      return staticStatus;
    }

    const find = enrichment.fixations_status.find(s => s.recording_id === data?.id);

    if (find) {
      staticStatus["MAPPING"] = find;
    }

    return staticStatus;
  }

  if (enrichment?.kind === EnrichmentTypesEnum.RENDER) {
    const renderStatusOverride = {} as AddEnrichmentStatus;

    if (enrichment.status?.ERROR !== undefined && enrichment.status?.ERROR > 0) {
      renderStatusOverride["ERROR"] = 1;
    } else if (
      enrichment.status?.COMPUTING !== undefined &&
      enrichment.status?.COMPUTING > 0
    ) {
      renderStatusOverride["PROCESSING"] = 1;
    } else if (
      (enrichment.status?.READY !== undefined && enrichment.status?.READY > 0) ||
      (enrichment.status?.STALE !== undefined && enrichment.status?.STALE > 0)
    ) {
      renderStatusOverride["READY"] = 1;
    } else if (
      enrichment.status?.SUCCESS !== undefined &&
      enrichment.status?.SUCCESS > 0
    ) {
      renderStatusOverride["SUCCESS"] = 1;
    } else {
      renderStatusOverride["NOT_READY"] = 1;
    }

    return renderStatusOverride;
  }

  let allSlices = 0;

  const slicesByStatus = !enrichment
    ? null
    : (enrichment.slices as ProjectEnrichmentSlice[]).reduce<AddEnrichmentStatus>(
        (acc, s) => {
          if (!data) return acc;
          if (s.recording_id !== data.id) return acc;

          allSlices += s.end_time_s - s.start_time_s;

          if (!acc[s.status]) acc[s.status] = 0;

          acc[s.status] += s.end_time_s - s.start_time_s;

          return acc;
        },
        {} as any,
      );

  if (!slicesByStatus) return;

  Object.keys(slicesByStatus).forEach(key => {
    const typedKey = key as ProjectEnrichmentSlice["status"];
    slicesByStatus[typedKey] = slicesByStatus[typedKey] / allSlices;
  });

  if (slicesByStatus["PROCESSING"]) {
    slicesByStatus["PROCESSING"] = 1 - slicesByStatus["PROCESSING"];
  }

  return slicesByStatus;
}

export const useEnrichmentStatus = (recording: RecordingWithData) => {
  const enrichment = useAppSelector(state =>
    state.projectEdit.currentEnrichment
      ? {
          ...state.projectEdit.currentEnrichment,
          from_event_name: state.projectEdit.form.from_event_name,
          to_event_name: state.projectEdit.form.to_event_name,
        }
      : undefined,
  );

  return addEnrichmentStatus(enrichment, recording);
};

const EnrichmentRIM = memo(({ params }: { params: RecordingWithData }) => {
  const status = useEnrichmentStatus(params);
  const isRun = useIsRunDisabledCheck();

  const text = status?.ERROR
    ? "Errored"
    : status?.CANCELLING
    ? "Cancelling"
    : status?.SCHEDULED || status?.PROCESSING
    ? "Processing"
    : status?.SUCCESS
    ? "Done"
    : status?.NO_EVENTS
    ? "Not matched"
    : isRun
    ? "Ready"
    : "Not started";

  const backgroundColor = status?.ERROR
    ? "#F44336"
    : status?.CANCELLING
    ? "#F44336"
    : status?.SCHEDULED || status?.PROCESSING
    ? "#07A62780"
    : status?.SUCCESS
    ? "#07A627"
    : status?.NO_EVENTS
    ? null
    : isRun
    ? "#A09FA6"
    : "#504F57";

  const width = status?.ERROR
    ? 100
    : status?.PROCESSING
    ? status?.PROCESSING * 100
    : status?.SUCCESS
    ? status?.SUCCESS * 100
    : 0;

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }}>
      {backgroundColor && (
        <Box sx={{ width: 8, height: 8, borderRadius: 2, mr: 1, backgroundColor }} />
      )}
      <Typography
        variant="body2"
        sx={{ color: backgroundColor ? undefined : "text.secondary" }}
      >
        {text === "Processing" ? `${Math.round(width)}% ` : ""}
        {text}
      </Typography>
    </Box>
  );
});

const EnrichmentManualMapper = memo(({ params }: { params: RecordingWithData }) => {
  const status = useEnrichmentStatus(params);

  const backgroundColor =
    status?.MAPPING &&
    status.MAPPING.total_fixations === status.MAPPING.checked_fixations
      ? "#07A627"
      : status?.MAPPING
      ? "#A09FA6"
      : null;

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }}>
      {backgroundColor && (
        <Box sx={{ width: 8, height: 8, borderRadius: 2, mr: 1, backgroundColor }} />
      )}
      <Typography
        variant="body2"
        sx={{ color: backgroundColor ? undefined : "text.secondary" }}
      >
        {status?.MAPPING
          ? `${status.MAPPING.checked_fixations} / ${status.MAPPING.total_fixations}`
          : "Not matched"}
      </Typography>
    </Box>
  );
});

const columns: GridColDef<RecordingWithData>[] = [
  { field: "id", headerName: "ID" },
  {
    width: 224,
    field: "name",
    headerName: "Recording Name",
    valueGetter: (_, row) => {
      return row.recording.name;
    },
    renderCell: params => {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }}
        >
          <Thumbnail recording={params.row.recording} />
          <Tooltip title={params.row.recording.name}>
            <Typography ml={1.5} noWrap variant="body2">
              {params.row.recording.name}
            </Typography>
          </Tooltip>
        </Box>
      );
    },
  },
  {
    width: 130,
    field: "enrichment",
    headerName: "Status",
    type: "number",
    filterable: false,
    valueGetter: (_, row) => {
      const rematchStore = store.getState();
      const enrichment = rematchStore.projectEdit.currentEnrichment;
      const status = addEnrichmentStatus(enrichment, row);

      if (status?.CANCELLING) return 101;
      if (status?.ERROR) return 102;

      if (status?.MAPPING)
        return 104 + status.MAPPING.checked_fixations / status.MAPPING.total_fixations;
      if (status?.SUCCESS) return 106;
      if (status?.SCHEDULED) return 107;
      if (status?.NO_EVENTS) return 108;
      if (status?.READY) return 109;

      return 10;
    },
    renderCell: params => {
      if (
        store.getState().projectEdit.currentEnrichment?.kind ===
        EnrichmentTypesEnum.STATIC_IMAGE_MAPPER
      ) {
        return <EnrichmentManualMapper params={params.row} />;
      }

      return <EnrichmentRIM params={params.row} />;
    },
  },

  {
    field: "duration_ns",
    width: 100,
    headerName: "Duration",
    type: "number",
    valueGetter: (_, row) => {
      return row.recording.duration_ns;
    },
    renderCell: params => {
      if (!params.row.recording.duration_ns) return null;

      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {new Date(params.row.recording.duration_ns / 1e6).toISOString().substr(11, 8)}
        </Box>
      );
    },
  },
  {
    field: "recorded_at",
    width: 107,
    headerName: "Recorded",
    type: "date",
    valueGetter: (_, row) => {
      return row.recording.recorded_at ? new Date(row.recording.recorded_at) : null;
    },
    renderCell: params => {
      return params.row.recording.recorded_at ? (
        <TimeAgo
          datetime={params.row.recording.recorded_at}
          opts={{ minInterval: 60 }}
        />
      ) : null;
    },
  },
  {
    field: "wearer",
    width: 93,
    headerName: "Wearer",
    valueGetter: (_, row) => {
      return row.wearer?.name;
    },
    ...recordingWearerFilterOperatorsParams(),
  },
  {
    field: "labels",
    width: 250,
    headerName: "Labels",
    sortable: false,
    renderCell: params => {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }}
          gap={0.5}
        >
          {params.row.labels.map(label => (
            <FilterLabelChip key={label.id} label={label} />
          ))}
        </Box>
      );
    },
    ...recordingLabelsFilterOperatorsParams(),
  },
  {
    field: "template_id",
    headerName: "Template Name",
    valueGetter: (_, row) => {
      return row.template?.name;
    },
    ...recordingTemplateNameFilterOperatorsParams(),
  },
  {
    field: "created_by_user_id",
    headerName: "Created By",
    valueGetter: (_, row) => {
      return row.createdBy?.user?.name;
    },
    ...recordingCreatedByFilterOperatorsParams(),
  },
  {
    field: INACTIVE_FIELD_NAME,
    headerName: "Trashed",
    type: "boolean",
    valueGetter: (_, row) => {
      return row.recording.trashed_at ? true : false;
    },
  },
];

const columnsNoStatus = columns.filter(
  c => c.field !== "enrichment" && c.field !== "manual_mapping",
);

export const ProjectRecordings = ({
  singleSelect,
  onCustomSelect,
  noContextMenu,
  outlet,
  disableStatus,
  saveFilter,
  tableColumnInitialStateStorageKey,
  disableSelectModel = false,
  disableAutoSelect = false,
  disableFooter = false,
  cssHeaderBackGround,
}: Pick<
  EntityDataTableProps,
  | "singleSelect"
  | "onCustomSelect"
  | "noContextMenu"
  | "outlet"
  | "tableColumnInitialStateStorageKey"
  | "disableSelectModel"
  | "disableFooter"
  | "cssHeaderBackGround"
> & { disableStatus?: boolean; saveFilter?: boolean; disableAutoSelect?: boolean }) => {
  const ids = useAppSelector(state => state.app.currentProject?.recording_ids);
  const dataById = useAppSelector(state => state.recordings.tableDataById);

  const rows = useMemo(() => {
    return ids
      ? ids.reduce((acc, id) => {
          if (dataById.has(id)) acc.push(dataById.get(id) as RecordingWithData);

          return acc;
        }, [] as RecordingWithData[])
      : [];
  }, [ids, dataById]);

  return (
    <ProjectRecordingsNoRows
      rows={rows}
      singleSelect={singleSelect}
      onCustomSelect={onCustomSelect}
      noContextMenu={noContextMenu}
      outlet={outlet}
      disableStatus={disableStatus}
      saveFilter={saveFilter}
      tableColumnInitialStateStorageKey={tableColumnInitialStateStorageKey}
      disableSelectModel={disableSelectModel}
      disableAutoSelect={disableAutoSelect}
      disableFooter={disableFooter}
      cssHeaderBackGround={cssHeaderBackGround}
    />
  );
};

export const ProjectRecordingsNoRows = ({
  singleSelect,
  onCustomSelect,
  noContextMenu,
  outlet,
  disableStatus,
  saveFilter,
  tableColumnInitialStateStorageKey,
  disableSelectModel = true,
  disableAutoSelect = false,
  disableFooter = false,
  cssHeaderBackGround,
  rows,
}: Pick<
  EntityDataTableProps,
  | "singleSelect"
  | "onCustomSelect"
  | "noContextMenu"
  | "outlet"
  | "tableColumnInitialStateStorageKey"
  | "disableSelectModel"
  | "disableFooter"
  | "cssHeaderBackGround"
> & {
  disableStatus?: boolean;
  saveFilter?: boolean;
  disableAutoSelect?: boolean;
  rows: RecordingWithData[];
}) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(
    state =>
      state.loading.effects.projectEdit.get.loading ||
      state.loading.effects.app.loadWorkspaceData.loading,
  );

  useEffect(() => {
    if (!disableAutoSelect) dispatch.entityTable.setProjectDefaultSelected(null);
  }, [dispatch, disableAutoSelect]);

  return (
    <EntityDataTable
      loading={isLoading}
      rows={rows}
      columns={disableStatus ? columnsNoStatus : columns}
      toolbarPadding
      noResetSelected
      singleSelect={singleSelect}
      onCustomSelect={onCustomSelect}
      noContextMenu={noContextMenu}
      outlet={outlet}
      initialState={{
        sorting: {
          sortModel: saveFilter
            ? localStorageAdapter.get(
                LocalStorageAdapterNames.projectTableQuickSelectSortModel,
              ) || [{ field: "recorded_at", sort: "desc" }]
            : [{ field: "recorded_at", sort: "desc" }],
        },
        filter: {
          filterModel: saveFilter
            ? localStorageAdapter.get(
                LocalStorageAdapterNames.projectTableQuickSelectFilterModel,
              ) || undefined
            : undefined,
        },
        columns: {
          columnVisibilityModel: {
            id: false,
            template_id: false,
            created_by_user_id: false,
            [INACTIVE_FIELD_NAME]: false,
          },
        },
      }}
      filterLocalStorageKey={
        saveFilter
          ? LocalStorageAdapterNames.projectTableQuickSelectFilterModel
          : undefined
      }
      sortLocalStorageKey={
        saveFilter
          ? LocalStorageAdapterNames.projectTableQuickSelectSortModel
          : undefined
      }
      noDataMessage={<NoDataRecordings />}
      disableSelectModel={disableSelectModel}
      disableFooter={disableFooter}
      tableColumnInitialStateStorageKey={tableColumnInitialStateStorageKey}
      cssHeaderBackGround={cssHeaderBackGround}
    />
  );
};

export const ProjectRecordingsRoute = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <ProjectRecordings
        outlet
        disableStatus
        tableColumnInitialStateStorageKey={
          LocalStorageAdapterNames.projectRecordingsColumState
        }
        onCustomSelect={(id: string) => {
          dispatch.projectEdit.setCurrentRecordingIdToLoad(id);
          dispatch.entityTable.setSelected([id]);
        }}
      />
      <ProjectContextMenu />
    </>
  );
};
