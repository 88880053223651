import { api, EnrichmentTypesEnum, Recording } from "@api";
import {
  aprilTagOverlay,
  faceOverlay,
  gazeAOIOverlay,
  gazeOffsetOverlay,
  gazeOverlay,
  overlayCalculations,
  overlayControl,
  pointCloudOverlay,
  scanPathOverlay,
  staticImageMapperOverlay,
  surfaceCornersOverlay,
} from "@components/videoPlayer/controller";
import { formatDurationInput } from "@components/videoPlayer/utils";
import { EVENTS_CALC_ROOT_ID } from "@components/videoPlayer/VideoEvents";
import { HORIZONTAL_SCROLL_ID } from "@constants";
import {
  getNumberOfTimesConfigSize,
  getTimesSplit,
  resetTimeWidthToUse,
  VIDEO_TIMELINE_SCROLL_ROOT,
} from "@hooks";
import { RouterHelper } from "@pages";
import { createModel } from "@rematch/core";
import {
  createVideoSources,
  findAllEventSlices,
  jumpedToEvent,
  markerMapperLocalizationSlicer,
  moveZoomHorizontalScroll,
  setJumpedToEvent,
} from "@utils";
import videojs from "video.js";
import { type RootModel } from "./index";

interface InitialState {
  initLoad: boolean;
  instance: ReturnType<typeof videojs> | null;
  selectedPercentage: number | null;
  selectedLineRef: HTMLDivElement | null;
  currentLineRef: HTMLDivElement | null;
  currentLinePosTrackerRef: HTMLDivElement | null;
  currentLineCanvasRef: HTMLCanvasElement | null;
  recording: Recording | null;
  recordingSlices: ReturnType<typeof findAllEventSlices>;
  chunkTime: { start: number; end: number };
  fullScreen: boolean;
  currentSelectedTime: {
    seconds: number;
    percentage: number;
  };
  hasGaze: boolean;
  hasFixation: boolean;
  hideVideoPlayer: boolean;
  markerError: boolean;
  zoom: {
    min: number;
    max: number;
    steps: number;
    current: number;
    zoomOffset: number;
    selectedPercentage: number;
    css: {
      width: string;
      left: number;
    };
    time: {
      start: number;
      end: number;
    };
    zoomOffsetUpdateCurrentPosition: boolean;
  };
  videoEventsBackgroundMove: boolean;
  rememberRecordingCurrentTime: number | boolean;
}

const initialOptions: videojs.PlayerOptions = {
  controls: false,
  fill: true,
  enableSourceset: true,
  preload: "auto",
};

const initialChunkTime: InitialState["chunkTime"] = { start: 0, end: 5 };

// Load next chunk before end (1 ~ 3s)
const DATA_CHUNK_LOADING_DEVIATION = 3;
const DATA_CHUNK_LOADING_DEVIATION_MINUS = 0.1;

const initialState: InitialState = {
  initLoad: false,
  instance: null,
  selectedPercentage: null,
  selectedLineRef: null,
  currentLineRef: null,
  currentLinePosTrackerRef: null,
  currentLineCanvasRef: null,
  chunkTime: { ...initialChunkTime },
  recording: null,
  recordingSlices: [],
  fullScreen: false,
  currentSelectedTime: {
    seconds: 0,
    percentage: 0,
  },
  hasGaze: false,
  hasFixation: false,
  hideVideoPlayer: false,
  markerError: false,
  zoom: {
    min: 0,
    max: 10,
    steps: 1,
    current: 0,
    zoomOffset: 0,
    selectedPercentage: 0,
    css: {
      width: "100%",
      left: 0,
    },
    time: {
      start: 0,
      end: 0,
    },
    zoomOffsetUpdateCurrentPosition: false,
  },
  videoEventsBackgroundMove: true,
  rememberRecordingCurrentTime: false,
};

export const video = createModel<RootModel>()({
  state: initialState,
  reducers: {
    init(
      state,
      {
        element,
        options,
      }: { element: HTMLVideoElement; options?: videojs.PlayerOptions },
    ) {
      if (!state.instance) {
        state.instance = videojs(element, { ...initialOptions, ...options });
      }
    },
    disposeReducer(state) {
      if (state.instance) {
        state.instance.dispose();
        state.instance = null;
      }
    },
    setSelectedLineRef(state, data: InitialState["selectedLineRef"]) {
      state.selectedLineRef = data;
    },
    setCurrentLineRef(state, data: InitialState["currentLineRef"]) {
      state.currentLineRef = data;
    },
    setCurrentLinePosTrackerRef(state, data: InitialState["currentLinePosTrackerRef"]) {
      state.currentLinePosTrackerRef = data;
    },
    setCurrentLineCanvasRef(state, data: InitialState["currentLineCanvasRef"]) {
      state.currentLineCanvasRef = data;

      if (state.currentLineCanvasRef) {
        const parentElement = state.currentLineCanvasRef.parentElement;

        if (parentElement) {
          state.currentLineCanvasRef.width = parentElement.offsetWidth;
          state.currentLineCanvasRef.height = parentElement.offsetHeight;
        }
      }

      //setCurrentLineRefPos(state);
    },
    setSelectedPercentage(state, e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      const root = document.getElementById(EVENTS_CALC_ROOT_ID);
      const scrollWidth =
        document.getElementById(VIDEO_TIMELINE_SCROLL_ROOT)?.offsetWidth || 0;

      if (root) {
        const boundingReact = root.getBoundingClientRect();
        const pos = e.clientX < boundingReact.x ? 0 : e.clientX - boundingReact.left;
        const percentage = (pos / root.offsetWidth) * 100;

        state.selectedPercentage = percentage > 100 ? 100 : percentage;
        state.zoom.selectedPercentage =
          ((state.zoom.zoomOffset + pos) / scrollWidth) * 100;
      }

      if (
        state.selectedLineRef &&
        state.instance &&
        state.instance.readyState() === 4 &&
        state.selectedPercentage !== null
      ) {
        state.selectedLineRef.style.display = "unset";
        state.selectedLineRef.style.left = `${state.selectedPercentage}%`;

        const timeRef = state.selectedLineRef?.children[0] as HTMLElement | null;

        if (timeRef) {
          positionMoveAnimation(state, timeRef);

          const timeRefInner = timeRef.children[0] as HTMLElement | null;

          if (timeRefInner) {
            const time =
              (state.instance.duration() * state.zoom.selectedPercentage) / 100;

            timeRefInner.dataset.currentTime = `${
              (scrollWidth * state.selectedPercentage) / 100
            }`;
            timeRefInner.innerText = formatDurationInput(
              isNaN(time) || !time || time < 0 ? 0 : time,
            );
          }
        }

        positionMoveAnimation(
          state,
          state.selectedLineRef?.children[1] as HTMLElement | null,
        );

        state.instance.hasStarted(true);
      }
    },
    toggleZoomOffsetUpdateCurrentPosition(state) {
      state.zoom.zoomOffsetUpdateCurrentPosition =
        !state.zoom.zoomOffsetUpdateCurrentPosition;
    },
    clearSelectedPercentage(state) {
      state.selectedPercentage = initialState.selectedPercentage;
      if (state.selectedLineRef) {
        state.selectedLineRef.style.display = "none";
      }
    },
    setTimeToSelected(state) {
      if (
        state.instance &&
        state.selectedPercentage !== null &&
        !state.hideVideoPlayer &&
        state.instance.readyState() === 4
      ) {
        const duration = state.instance.duration();
        const next = (duration * state.zoom.selectedPercentage) / 100;

        state.instance.currentTime(next);

        if (state.currentLinePosTrackerRef) {
          //state.currentLineRef.style.left = `${state.zoom.selectedPercentage}%`;
          state.currentLinePosTrackerRef.style.left = `${state.zoom.selectedPercentage}%`;

          //checkCurrentLineRefDisplay(state);
        }
      }
    },
    setTimeToCurrent(state) {
      if (state.instance && state.currentLinePosTrackerRef) {
        const remaining = state.instance.remainingTime();
        const duration = state.instance.duration();
        const next = ((duration - remaining) / duration) * 100;

        //state.currentLineRef.style.left = `${next}%`;
        state.currentLinePosTrackerRef.style.left = `${next}%`;

        // checkCurrentLineRefDisplay(state);

        if (jumpedToEvent) {
          setJumpedToEvent(false);
        }
      }
    },
    setTimeChuckStart(state, data: InitialState["chunkTime"]) {
      state.chunkTime = data;
    },
    setRecording(state, data: InitialState["recording"]) {
      state.recording = data;
    },
    setRecordingSlices(state, data: InitialState["recordingSlices"]) {
      state.recordingSlices = data;
    },
    toggleFullScreen(state) {
      state.fullScreen = !state.fullScreen;
    },
    setFullScreen(state, data: InitialState["fullScreen"]) {
      state.fullScreen = data;
    },
    setHasGaze(state, data: InitialState["hasGaze"]) {
      state.hasGaze = data;
    },
    setHasFixation(state, data: InitialState["hasFixation"]) {
      state.hasFixation = data;
    },
    setInitLoad(state, data: InitialState["initLoad"]) {
      state.initLoad = data;
    },
    setHideVideoPlayer(state, data: InitialState["hideVideoPlayer"]) {
      if (data) state.instance?.pause();
      state.hideVideoPlayer = data;
    },
    setMarkerError(state, data: InitialState["markerError"]) {
      state.markerError = data;
    },
    setZoom(state, data: Partial<InitialState["zoom"]>) {
      state.zoom = { ...state.zoom, ...data };

      //checkCurrentLineRefDisplay(state);
    },
    setZoomCss(state, data?: Partial<InitialState["zoom"]["css"]["left"]>) {
      if (data !== undefined) {
        state.zoom.zoomOffset = data;
        state.zoom.css = {
          left: -data,
          width: generateZoomWidth(state),
        };
        // checkCurrentLineRefDisplay(state);
      } else {
        state.zoom.css.width = generateZoomWidth(state);
      }
    },
    toggleVideoEventsBackgroundMove(state) {
      const next = !state.videoEventsBackgroundMove;
      state.videoEventsBackgroundMove = next;

      if (next) moveZoomHorizontalScroll(false, state.currentLinePosTrackerRef);
    },
    setRememberRecordingCurrentTime(
      state,
      data: InitialState["rememberRecordingCurrentTime"],
    ) {
      if (data === true && state.instance) {
        state.rememberRecordingCurrentTime = state.instance.currentTime();
      } else {
        state.rememberRecordingCurrentTime = data;
      }
    },
  },
  effects: dispatch => ({
    dispose() {
      overlayControl.reset(true);
      dispatch.video.disposeReducer();
    },
    async addGazeConfig(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const enrichment = state.projectEdit.currentEnrichment;

      if (
        !workspaceId ||
        !enrichment ||
        enrichment.kind !== "render" ||
        !enrichment.args ||
        !enrichment.args.render_definition_id
      ) {
        gazeOverlay.addConfig(undefined);
        return;
      }

      try {
        const res = await api.getRender({
          workspaceId,
          renderDefinitionId: enrichment.args.render_definition_id,
        });

        if (!res.result.gaze_circle) return;

        dispatch.projectEdit.setCurrentRender(res.result);
        gazeOverlay.addConfig(res.result.gaze_circle);
      } catch (error) {
        console.error("error addGazeConfig", error);
      }
    },
    changeSrc(recording: Recording, state) {
      if (recording.id === state.video.recording?.id) return;

      if (!window.location.pathname.includes("projects")) {
        dispatch.video.setInitLoad(false);
      }

      const video = state.video.instance;

      resetTimeWidthToUse();
      overlayControl.reset(true);
      dispatch.filmstrip.resetImages();

      dispatch.video.setTimeChuckStart({ ...initialChunkTime });
      dispatch.video.setRecording(recording);
      dispatch.recordingEvents.get(null);
      dispatch.staticImageMappers.getFixations(null);

      gazeOverlay.setDefaultConfig(recording);
      gazeAOIOverlay.setDefaultConfig(recording);
      gazeOffsetOverlay.setDefaultConfig(recording);

      if (video && recording.transcoded_url) {
        if (recording.thumbnail_url)
          video.poster(
            `${recording.thumbnail_url}${recording.family === "neon" ? "&w=500" : ""}`,
          );

        overlayControl.recordingFamily = recording.family;

        video.src(createVideoSources(recording));

        video.load();

        video.one("loadedmetadata", () => {
          dispatch.localization.get(null);

          const duration = video.duration();
          const { max, min } = getNumberOfTimesConfigSize(duration);

          dispatch.video.setZoom({
            ...initialState.zoom,
            current: min,
            max,
            min,
            css: {
              left: initialState.zoom.css.left,
              width: generateZoomWidth(state.video),
            },
            time: { start: 0, end: duration },
          });

          dispatch.filmstrip.recalculate(false);

          if (typeof state.video.rememberRecordingCurrentTime === "number") {
            video.currentTime(state.video.rememberRecordingCurrentTime);
            dispatch.video.setRememberRecordingCurrentTime(false);
          }

          dispatch.video.prepareRecordingSlices(null);
        });

        dispatch.video.setHideVideoPlayer(false);
        dispatch.video.getCalibration(null);
        dispatch.video.getNextDataChunk(null);
        dispatch.video.addGazeConfig(null);
        dispatch.recordingGazeOffset.changeRecordingOffset(recording);
      } else {
        dispatch.video.setHideVideoPlayer(true);
      }
    },
    getNextDataChunk(
      props: { currentTime?: number; enrichment?: boolean } | null,
      state,
    ) {
      const currentTime = props?.currentTime || null;
      //if (props?.enrichment) overlayControl.reset();

      const recording = state.video.recording;
      const enrichment = state.projectEdit.currentEnrichment;
      const videoTime = state.video.instance?.currentTime();

      if (enrichment?.id !== currentSkipDetect?.id) currentSkipDetect = null;

      if (!recording) return;

      const chunkTime = state.video.chunkTime;
      const start =
        Number(
          (currentTime !== null
            ? currentTime
            : videoTime !== undefined
            ? videoTime
            : initialChunkTime.start
          ).toFixed(3),
        ) - DATA_CHUNK_LOADING_DEVIATION_MINUS;
      const end = Number((start + initialChunkTime.end).toFixed(3));

      if (enrichment && state.video.instance?.paused()) {
        if (skipDetect({ id: enrichment.id + recording.id, time: start })) return;

        if (
          enrichment.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER &&
          chunkTime.end - start >= DATA_CHUNK_LOADING_DEVIATION &&
          start >= chunkTime.start
        ) {
          return;
        }

        dispatch.video.setInitLoad(true);

        dispatch.video.getGazeData({ recording, start, end });
        dispatch.video.getMarkerLessGazeData({
          recordingId: recording.id,
          start,
          end,
        });

        if (enrichment.args && enrichment.args.surface_id) {
          dispatch.video.getSingleSurfaceData({
            surfaceId: enrichment.args.surface_id,
            recording: recording,
            enrichmentId: enrichment.id,
            start: start + DATA_CHUNK_LOADING_DEVIATION_MINUS,
            end,
          });
          dispatch.video.getSurfaceData({
            surfaceId: enrichment.args.surface_id,
            recording: recording,
            enrichmentId: enrichment.id,
            start,
            end,
          });
        }

        if (
          enrichment.kind === EnrichmentTypesEnum.FACE_MAPPER &&
          enrichment.status?.SUCCESS === 1
        ) {
          dispatch.video.getFaceData({ recording, start, end });
        }

        if (
          enrichment.kind === EnrichmentTypesEnum.SLAM_MAPPER &&
          enrichment.status?.SUCCESS === 1
        ) {
          dispatch.video.getPointCloudData({ recording, start, end });
        }

        dispatch.video.setTimeChuckStart({ start, end });

        return;
      }

      const checkInitLoad = window.location.pathname.includes("projects")
        ? true
        : state.video.initLoad;

      if (
        checkInitLoad &&
        chunkTime.end - start >= DATA_CHUNK_LOADING_DEVIATION &&
        start >= chunkTime.start
      )
        return;

      //if (!props?.enrichment) {
      dispatch.video.setInitLoad(true);
      dispatch.video.setTimeChuckStart({ start, end });
      dispatch.video.getGazeData({ recording, start, end });
      dispatch.video.getMarkerLessGazeData({ recordingId: recording.id, start, end });
      //}

      if (!enrichment) return;

      if (enrichment.args && enrichment.args.surface_id) {
        dispatch.video.getSurfaceData({
          surfaceId: enrichment.args.surface_id,
          recording: recording,
          enrichmentId: enrichment.id,
          start,
          end,
        });
      }

      if (
        enrichment.kind === EnrichmentTypesEnum.FACE_MAPPER &&
        enrichment.status?.SUCCESS === 1
      ) {
        dispatch.video.getFaceData({ recording, start, end });
      }

      if (
        enrichment.kind === EnrichmentTypesEnum.SLAM_MAPPER &&
        enrichment.status?.SUCCESS === 1
      ) {
        dispatch.video.getPointCloudData({ recording, start, end });
      }
    },
    async getGazeData(
      { recording, start, end }: { recording: Recording; start: number; end: number },
      state,
    ) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;

      if (!workspaceId) return;

      const format = `json?start=${start}&end=${end}&max_length=0.2`;

      try {
        const [gazeRes, scanRes] = await Promise.all([
          api.getRecordingGaze({
            workspaceId,
            recordingId: recording.id,
            format,
          }),
          api.getRecordingScanPath({
            workspaceId,
            recordingId: recording.id,
            format,
          }),
        ]);

        gazeOverlay.setEvents(gazeRes.result);
        overlayControl.shouldRender(gazeOverlay);

        if (RouterHelper.workspaceRecordingsEditGazeOffset.matchCurrentPath()) {
          gazeOffsetOverlay.setEvents(gazeRes.result);
          overlayControl.shouldRender(gazeOffsetOverlay);
        }

        scanPathOverlay.setEvents(scanRes.result);
        overlayControl.shouldRender(scanPathOverlay);
        overlayControl.shouldRender(staticImageMapperOverlay);

        dispatch.video.setHasGaze(
          state.video.recording?.gazepipeline_status === "done",
        );
        dispatch.video.setHasFixation(state.video.recording?.has_scanpath || false);
      } catch (error) {
        console.error("Gaze not loaded!", error);
      }
    },
    async getMarkerLessGazeData(
      { recordingId, start, end }: { recordingId: string; start: number; end: number },
      state,
    ) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const markerlessId = state.projectEdit.currentEnrichment?.args?.markerless_id;
      const surfaceId = state.projectEdit.currentEnrichment?.args?.surface_id;
      const projectId = state.app.currentProject?.id;
      const enrichmentId = state.projectEdit.currentEnrichment?.id;
      const enrichment = state.projectEdit.currentEnrichment;

      if (
        !workspaceId ||
        !projectId ||
        !enrichmentId ||
        !enrichment ||
        (enrichment.kind !== EnrichmentTypesEnum.SLAM_MAPPER &&
          enrichment.kind !== EnrichmentTypesEnum.MARKER_MAPPER)
      )
        return;

      if (enrichment.kind === EnrichmentTypesEnum.SLAM_MAPPER && !markerlessId) return;
      if (enrichment.kind === EnrichmentTypesEnum.MARKER_MAPPER && !surfaceId) return;

      const format = `?start=${start}&end=${end}&recording_id=${recordingId}&project_id=${projectId}&enrichment_id=${enrichmentId}`;

      try {
        const res = await (enrichment.kind === EnrichmentTypesEnum.SLAM_MAPPER
          ? api.getGazeOnAoi({
              workspaceId,
              markerlessId: markerlessId as unknown as string,
              format,
            })
          : api.getGazeOnAoiSurface({
              workspaceId,
              surfaceId: surfaceId as unknown as string,
              format,
            }));

        gazeAOIOverlay.setEvents(res.result);
        gazeAOIOverlay.hide = !res.result.length;
      } catch (error) {
        console.error("getMarkerLessGazeData not loaded!", error);
      }
    },
    async getCalibration(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const recordingId = state.video.recording?.id;
      const serialNumber = state.video.recording?.scene_camera_id;

      if (!workspaceId || !recordingId) return;

      try {
        const res = await (serialNumber
          ? api.getHardware({ serialNumber, version: "v1?json" })
          : api.getHardwareScene({ workspaceId, recordingId }));

        overlayCalculations.calibration = res.result;
      } catch (error) {
        console.error("Calibration not loaded!", error);
      }
    },
    async getSurface(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const enrichment = state.projectEdit.currentEnrichment;

      if (
        !workspaceId ||
        !enrichment ||
        !enrichment.args ||
        !enrichment.args.surface_id
      )
        return;

      dispatch.projectEdit.setCurrentSurface(undefined);

      try {
        const res = await api.getSurface({
          surfaceId: enrichment.args.surface_id,
          workspaceId,
        });
        dispatch.projectEdit.setCurrentSurface(res.result);

        if (res.result.is_initialized) {
          aprilTagOverlay.availableTags = (res.result.markers_used || []).reduce<
            Record<string, boolean>
          >((acc, one) => {
            acc[one] = true;
            return acc;
          }, {});
        }

        //dispatch.video.getNextDataChunk({ enrichment: true });

        if (state.video.recording) {
          dispatch.video.getSingleSurfaceData({
            surfaceId: res.result.id,
            enrichmentId: enrichment.id,
            recording: state.video.recording,
            start: state.video.chunkTime.start,
            end: state.video.chunkTime.end,
          });
          dispatch.video.getSurfaceData({
            surfaceId: res.result.id,
            enrichmentId: enrichment.id,
            recording: state.video.recording,
            start: state.video.chunkTime.start,
            end: state.video.chunkTime.end,
          });
        }
      } catch (error) {
        console.error("Surface not loaded!", error);
      }
    },
    async getAprilTagsData(
      {
        recording,
        enrichmentId,
        surfaceId,
        start,
        end,
      }: {
        recording: Recording;
        enrichmentId: string;
        surfaceId: string;
        start: number;
        end: number;
      },
      state,
    ) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;

      if (!workspaceId || !projectId) return;

      try {
        const res = await api.getApriltagDetections({
          workspaceId,
          surfaceId,
          format: `?start=${start}&end=${end}&project_id=${projectId}&enrichment_id=${enrichmentId}&recording_id=${recording.id}`,
        });

        aprilTagOverlay.setEvents(res.result);
      } catch (error) {
        console.error("AprilTags not loaded!", error);
      }
    },
    async getSingleSurfaceData(
      {
        recording,
        enrichmentId,
        surfaceId,
        start,
      }: {
        recording: Recording;
        enrichmentId: string;
        surfaceId: string;
        start: number;
        end: number;
      },
      state,
    ) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;

      if (!workspaceId || !projectId) return;

      try {
        const format = `?start=${start}&detect=1&project_id=${projectId}&enrichment_id=${enrichmentId}&recording_id=${recording.id}`;
        const surface = state.projectEdit.currentSurface;

        const [aprilTagRes, surfaceCornersRes] = await Promise.all([
          api.getApriltagDetections({
            workspaceId,
            surfaceId,
            format,
          }),
          surface &&
          surface.is_initialized &&
          surface.id === state.projectEdit.currentEnrichment?.args?.surface_id
            ? api.getSurfaceCorners({
                workspaceId,
                surfaceId,
                format,
              })
            : undefined,
        ]);

        if (
          surfaceCornersRes?.result.length &&
          surfaceCornersRes.result[0].marker_ids &&
          surfaceCornersRes.result[0].marker_ids.length
        ) {
          aprilTagOverlay.availableTags = surfaceCornersRes.result[0].marker_ids.reduce<
            Record<string, boolean>
          >((acc, one) => {
            acc[one] = true;
            return acc;
          }, {});
          dispatch.projectEdit.setIsSurfaceDetected(true);
        } else {
          if (
            !surfaceCornersRes &&
            aprilTagRes.result[0] &&
            aprilTagRes.result[0].normalized_apriltags &&
            !Object.keys(aprilTagOverlay.availableTags).length
          ) {
            Object.keys(aprilTagRes.result[0].normalized_apriltags).forEach(k => {
              aprilTagOverlay.availableTags[k] = true;
            });
          }
          dispatch.projectEdit.setIsSurfaceDetected(false);
        }

        aprilTagOverlay.disabled =
          surfaceCornersRes &&
          surfaceCornersRes.result.length &&
          !surfaceCornersRes?.result[0].corners
            ? true
            : false;

        aprilTagOverlay.mergeEvents(aprilTagRes.result);
        overlayControl.shouldRender(aprilTagOverlay);

        if (surfaceCornersRes) {
          surfaceCornersOverlay.mergeEvents(surfaceCornersRes.result);
          overlayControl.shouldRender(surfaceCornersOverlay);
        }
      } catch (error) {
        console.error("Surface not loaded!", error);
      }
    },
    async getSurfaceData(
      {
        recording,
        enrichmentId,
        surfaceId,
        start,
        end,
      }: {
        recording: Recording;
        enrichmentId: string;
        surfaceId: string;
        start: number;
        end: number;
      },
      state,
    ) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const isInitialized = state.projectEdit.currentSurface?.is_initialized;

      if (!workspaceId || !projectId) return;

      try {
        const format = `?start=${start}&end=${end}&project_id=${projectId}&enrichment_id=${enrichmentId}&recording_id=${recording.id}`;

        markerMapperLocalizationSlicer.get({
          workspaceId,
          recordingId: recording.id,
          surfaceId,
          time: start,
        });

        const [aprilTagRes, surfaceCornersRes] = await Promise.all([
          api.getApriltagDetections({
            workspaceId,
            surfaceId,
            format,
          }),
          isInitialized
            ? api.getSurfaceCorners({
                workspaceId,
                surfaceId,
                format,
              })
            : undefined,
        ]);

        if (state.video.instance?.paused()) {
          aprilTagOverlay.mergeEvents(aprilTagRes.result);
          if (surfaceCornersRes)
            surfaceCornersOverlay.mergeEvents(surfaceCornersRes.result);
        } else {
          aprilTagOverlay.setEvents(aprilTagRes.result);
          if (surfaceCornersRes)
            surfaceCornersOverlay.setEvents(surfaceCornersRes.result);
        }
      } catch (error) {
        console.error("Surface not loaded!", error);
      }
    },
    async getFaceData(
      { recording, start, end }: { recording: Recording; start: number; end: number },
      state,
    ) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;

      if (!workspaceId) return;

      try {
        const res = await api.getRecordingFaceDetections({
          workspaceId,
          recordingId: recording.id,
          format: `?start=${start}&end=${end}&max_length=0.2&with_landmarks=1`,
        });

        faceOverlay.setEvents(res.result);
        overlayControl.shouldRender(faceOverlay);
      } catch (error) {
        console.error("Faces not loaded!", error);
      }
    },
    async getMarkerLessPointCloud(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const markerlessId = state.projectEdit.currentMarkerLess?.id;

      if (!workspaceId || !markerlessId) return;

      try {
        const res = await api.getMarkerLessPointCloud({
          workspaceId,
          markerlessId,
        });

        overlayCalculations.points3D = res.result.map(({ x, y, z }) => [x, y, z]);
      } catch (error) {
        console.error("getMarkerLessPointCloud not loaded!", error);
      }
    },
    async getPointCloudData(
      { recording, start, end }: { recording: Recording; start: number; end: number },
      state,
    ) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const markerlessId = state.projectEdit.currentMarkerLess?.id;

      if (!workspaceId || !markerlessId) return;

      try {
        const res = await api.getRecordingPointCloud({
          workspaceId,
          recordingId: recording.id,
          markerlessId,
          format: `.json?start=${start}&end=${end}&max_length=0.2`,
        });

        pointCloudOverlay.setEvents(res.result);
        overlayControl.shouldRender(pointCloudOverlay);
      } catch (error) {
        console.error("getPointCloudData not loaded!", error);
      }
    },
    async setDistortedLocationUsingRecording(timestamp: number, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const enrichment = state.projectEdit.currentEnrichment;
      const recording = state.video.recording;

      if (
        !workspaceId ||
        !recording ||
        !enrichment ||
        !enrichment.args ||
        !enrichment.args.surface_id
      )
        return;

      const surfaceId = enrichment.args.surface_id;

      try {
        const res = await api.postDistortedSurfaceLocation({
          workspaceId,
          surfaceId,
          setDistortedSurfaceRequest: {
            corners: surfaceCornersOverlay.lastMoved.corners
              ? Object.keys(surfaceCornersOverlay.lastMoved.corners).reduce(
                  (acc, a) => {
                    //@ts-ignore
                    const value = surfaceCornersOverlay.lastMoved?.corners?.[a as any];

                    acc[a] = overlayCalculations.revertScale(value);

                    return acc;
                  },
                  {} as Record<string, [number, number]>,
                )
              : undefined,
            markers_used: Object.keys(aprilTagOverlay.availableTags),
            recording_id: recording.id,
            timestamp,
          },
        });

        surfaceCornersOverlay.setEvents([
          {
            ...res.result,
            start_timestamp: state.video.instance?.currentTime(),
            end_timestamp: state.video.instance?.currentTime(),
          } as any,
        ]);
        overlayControl.shouldRender(surfaceCornersOverlay);
      } catch (error) {
        console.error("setDistortedLocationUsingRecording", error);
      }
    },
    onVideoControlMouseMove(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      dispatch.video.setSelectedPercentage(e);
    },
    onVideoWheel(e: React.WheelEvent<HTMLDivElement>) {
      if (e.deltaY === 0) {
        e.preventDefault();
        e.stopPropagation();

        const scrollRootRef = document.getElementById(HORIZONTAL_SCROLL_ID);

        if (!scrollRootRef) return;

        scrollRootRef.scrollBy(e.deltaX, 0);

        return;
      }

      if (!e.shiftKey) return;

      dispatch.video.onTimeLineZoom({ direction: e.deltaY < 0 });
    },
    onTimeLineZoom(
      { value, direction }: { value?: number; direction?: boolean },
      state,
    ) {
      if (!isFinite(state.video.zoom.steps)) return;

      const duration = state.video.instance?.duration();
      const zoom = state.video.zoom;
      const steps = zoom.steps;
      const next =
        value === undefined ? zoom.current + (direction ? steps : -steps) : value;

      const checkedNext =
        next !== zoom.max && next > zoom.max
          ? zoom.max
          : next !== zoom.min && next < zoom.min
          ? zoom.min
          : next;

      if (checkedNext < zoom.min || checkedNext > zoom.max || !duration) return;

      dispatch.video.setZoom({
        current: checkedNext,
        css: {
          left: -zoom.zoomOffset,
          width: generateZoomWidth(state.video),
        },
      });

      moveZoomHorizontalScroll(true);
    },
    toggleFullScreenEffect(_, state) {
      if (state.video.fullScreen) {
        dispatch.dragger.resetVideoFullScreen();
      }

      dispatch.video.toggleFullScreen();
    },
    prepareRecordingSlices(_, state) {
      const enrichment = state.projectEdit.currentEnrichment;
      const recordingId = state.video.recording?.id;
      const events = state.recordingEvents.eventsByRecordingId.get(recordingId || "");
      const duration = state.video.instance?.duration();

      if (!enrichment || !events || !duration) return;

      const slices = findAllEventSlices({
        events,
        from: enrichment.from_event_name,
        to: enrichment.to_event_name,
        duration,
        selection: { from: enrichment.from_event_name, to: enrichment.to_event_name },
      });

      dispatch.video.setRecordingSlices(slices);
    },
  }),
});

function generateZoomWidth(state: InitialState) {
  const w = document.getElementById(VIDEO_TIMELINE_SCROLL_ROOT)?.parentElement
    ?.offsetWidth;

  const { delta, timeWidth } = getTimesSplit();
  const duration = state.instance?.duration() || 0;

  if (duration) {
    const mod = duration / delta;
    const width = mod * timeWidth;

    return `${width}px`;
  }

  return document.URL.includes("enrichments") || document.URL.includes("render")
    ? "100%"
    : `${w}px`;
}

interface SkipDetect {
  id: string;
  time: number;
}
let currentSkipDetect: SkipDetect | null = null;
function skipDetect(next: SkipDetect) {
  if (
    !currentSkipDetect ||
    currentSkipDetect.id !== next.id ||
    currentSkipDetect.time !== next.time
  ) {
    currentSkipDetect = next;
    return false;
  }

  return true;
}

function positionMoveAnimation(state: InitialState, ref: HTMLElement | null) {
  if (!ref || !state.selectedLineRef || state.selectedPercentage === null) return;

  const maxWidth = state.selectedLineRef.parentElement?.clientWidth || 0;
  const cursorPositionPx = (maxWidth * state.selectedPercentage) / 100;
  const offset = ref.clientWidth / 2;

  if (cursorPositionPx - offset <= 0) {
    const m = cursorPositionPx > 0 ? -cursorPositionPx : 0;

    ref.style.marginLeft = `${m}px`;
  } else if (cursorPositionPx + offset >= maxWidth) {
    const m = maxWidth - cursorPositionPx - offset;

    ref.style.marginLeft = `${-(offset + -m) + 0.9}px`;
  } else {
    ref.style.marginLeft = `${-offset}px`;
  }
}
