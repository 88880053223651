import { Loader } from "@components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { useAppSelector } from "@storeRematch";
import { FC, PropsWithChildren, useEffect } from "react";
import { FormProvider, SubmitHandler, UseFormProps, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CancelButton } from "./CancelButton";
import { SubmitButton } from "./SubmitButton";

export interface FormDialogProps extends DialogProps {
  description?: string;
  onFormSubmit: SubmitHandler<any | Promise<any>>;
  useFormProps: UseFormProps;
  create?: boolean;
  buttonText?: string;
  loading?: boolean;
}

export const FormDialog: FC<PropsWithChildren<FormDialogProps>> = ({
  title,
  children,
  description = "",
  onFormSubmit,
  useFormProps,
  create,
  buttonText,
  loading,
  ...props
}) => {
  const { t } = useTranslation();
  const methods = useForm(useFormProps);
  const isLoading = useAppSelector(
    state => !state.loading.effects.app.loadWorkspaceData.success,
  );
  const disabled = (loading === undefined && isLoading) || loading;

  useEffect(() => {
    if (typeof useFormProps.defaultValues === "object") {
      methods.reset(useFormProps.defaultValues);
    }
  }, [useFormProps.defaultValues, methods]);

  return (
    <Dialog {...props} fullWidth maxWidth="sm">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onFormSubmit)} noValidate>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            {description ? (
              <DialogContentText sx={{ marginBottom: 2 }}>
                {description}
              </DialogContentText>
            ) : null}
            {disabled ? <Loader /> : children}
          </DialogContent>
          <DialogActions>
            <CancelButton onClick={() => props.onClose?.(null as any, null as any)} />
            <SubmitButton disabled={disabled} useIsDirty={false}>
              {buttonText ? buttonText : create ? t("Add") : t("Confirm")}
            </SubmitButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};
