import { Calibration, PointCloud } from "@api";
import { add, dotDivide, dotMultiply, norm, transpose } from "mathjs";
import { overlayControl } from "./OverlayControl";

interface Scale {
  x?: number;
  y?: number;
}

class OverlayCalculations {
  points3D: number[][] | null = null;
  calibration: Calibration | null = null;

  scaleCoordinates(event: Scale) {
    if (!overlayControl.instance) return;
    if (!event || event.x === undefined || event.y === undefined) return;

    const rootCanvas = document.getElementById(
      "GazeOverlay",
    ) as HTMLCanvasElement | null;

    if (!rootCanvas) return;

    const rect = rootCanvas.getBoundingClientRect();

    const x = (event.x * rect.width) / overlayControl.getVideoWidth();
    const y = (event.y * rect.height) / overlayControl.getVideoHeight();

    return { x, y };
  }

  scaleNormalized(point: number[]) {
    if (!overlayControl.instance) return;

    const { width, height } = this.getDimension1();

    const w = width / overlayControl.getVideoWidth();
    const h = height / overlayControl.getVideoHeight();

    const x = point[0] * overlayControl.getVideoWidth() * w;
    const y = point[1] * overlayControl.getVideoHeight() * h;

    return [x, y];
  }

  // Copied form the original code
  distortPoint(point: number[]) {
    if (!this.calibration) return;

    const cameraMatrix = this.calibration.camera_matrix;
    const distCoffs = this.calibration.dist_coefs[0];

    const [x, y] = point;
    const M = cameraMatrix;

    const [x_dist_prime, y_dist_prime] = this.applyDistortionModel([x, y], distCoffs);

    const x_corr = x_dist_prime * M[0][0] + M[0][2];
    const y_corr = y_dist_prime * M[1][1] + M[1][2];

    return [x_corr, y_corr];
  }

  distortAndScale(point: number[]) {
    const distorted = this.distortPoint(point);

    if (!distorted) return;

    const scaled = this.scaleCoordinates({
      x: distorted[0],
      y: distorted[1],
    });

    if (!scaled || !overlayControl.instance) return;

    return [scaled.x, scaled.y];
  }

  // Coped from original code
  distort3D(point: number[]) {
    if (!this.calibration) return [0, 0, 0];
    const coefs = this.calibration.dist_coefs[0];

    const x = point[0];
    const y = point[1];
    const z = point[2];
    const k1 = coefs[0];
    const k2 = coefs[1];
    const p1 = coefs[2];
    const p2 = coefs[3];
    const k3 = coefs[4];
    const k4 = coefs[5];
    const k5 = coefs[6];
    const k6 = coefs[7];
    const r2 = x ** 2 + y ** 2;

    // Radial distortion
    let x2 =
      (x * (1 + k1 * r2 + k2 * r2 ** 2 + k3 * r2 ** 3)) /
      (1 + k4 * r2 + k5 * r2 ** 2 + k6 * r2 ** 3);
    let y2 =
      (y * (1 + k1 * r2 + k2 * r2 ** 2 + k3 * r2 ** 3)) /
      (1 + k4 * r2 + k5 * r2 ** 2 + k6 * r2 ** 3);

    // Tangential distortion
    x2 += 2 * p1 * x * y + p2 * (r2 + 2 * x ** 2);
    y2 += p1 * (r2 + 2 * y ** 2) + 2 * p2 * x * y;

    return [x2, y2, z];
  }

  // Copied form the original code
  applyDistortionModel(point: number[], distCoffs: number[]) {
    const [k1, k2, p1, p2, k3, k4, k5, k6] =
      distCoffs.length < 8 ? [...distCoffs, 0.0, 0.0, 0.0] : distCoffs;

    const [x, y] = point;
    const r = Math.sqrt(x ** 2 + y ** 2);

    const scale =
      (1 + k1 * r ** 2 + k2 * r ** 4 + k3 * r ** 6) /
      (1 + k4 * r ** 2 + k5 * r ** 4 + k6 * r ** 6);

    const x_dist = scale * x + 2 * p1 * x * y + p2 * (r ** 2 + 2 * x ** 2);
    const y_dist = scale * y + p1 * (r ** 2 + 2 * y ** 2) + 2 * p2 * x * y;

    return [x_dist, y_dist];
  }

  // Copied form the original code
  interpolateFromCorners(points: number[][]) {
    const points01 = this.interpolate(points[0], points[1]);
    const ds1 = this.distortAndScale(points[0]);
    const ds2 = this.distortAndScale(points[1]);

    if (!ds1 || !ds2) return [];

    return [...ds1, ...points01, ...ds2];
  }

  // Copied form the original code
  interpolate(point1: number[], point2: number[], nrSteps = 104) {
    const generatedPoints = [];
    if (point1 && point2) {
      const x1 = point1[0];
      const y1 = point1[1];
      const x2 = point2[0];
      const y2 = point2[1];
      const totalDistance = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
      const stepSize = totalDistance / nrSteps;
      const angle = Math.atan2(y2 - y1, x2 - x1);
      let stepDistance = stepSize;

      while (totalDistance > stepDistance) {
        const newX = stepDistance * Math.cos(angle) + x1;
        const newY = stepDistance * Math.sin(angle) + y1;
        const ds = this.distortAndScale([newX, newY]);

        if (ds) {
          generatedPoints.push(ds[0]);
          generatedPoints.push(ds[1]);
        }

        stepDistance += stepSize;
      }
    }
    return generatedPoints;
  }

  getPointCloudPoints(event: PointCloud) {
    const points3D = this.transformPointsByPose(event);

    if (!points3D) return;

    return this.applyCameraIntrinsics(points3D);
  }

  //Copied form the original code
  transformPointsByPose(event: PointCloud) {
    if (
      !this.points3D ||
      !event.rotation_x ||
      !event.rotation_y ||
      !event.rotation_z ||
      !event.translation_x ||
      !event.translation_y ||
      !event.translation_z
    )
      return null;

    const cameraPose = [
      event.rotation_x,
      event.rotation_y,
      event.rotation_z,
      event.translation_x,
      event.translation_y,
      event.translation_z,
    ];

    // """
    // Transform 3d points from world coordinates to camera coordinates
    //  :param points_3d_world: 3d points in world coordinates, shape: (N x 3)
    // :param camera_pose: pose of the target camera, shape: (6,)
    // :return: 3d points in camera coordinates, shape: (N x 3)
    // """

    // points_3d_world = np.asarray(points_3d_world, dtype=np.float64).reshape(-1, 3)
    // if camera_pose_is_nan(camera_pose):
    //  return np.full((len(points_3d_world), 3), np.nan)

    // Camera pose is given as a transformation from camera coordinates to world coordinates
    let points_3d_world = this.points3D;
    let rotation_camera_to_world = this.rodrigues_vec_to_rotation_mat([
      cameraPose[0],
      cameraPose[1],
      cameraPose[2],
    ]);
    let translation_camera_to_world = [cameraPose[3], cameraPose[4], cameraPose[5]];

    // Invert the transformation to obtain one from world coordinates to camera coordinates
    let rotation_world_to_camera = transpose(rotation_camera_to_world);
    let translation_world_to_camera = [];
    for (let i = 0; i < rotation_world_to_camera.length; i++) {
      let row = rotation_world_to_camera[i];
      translation_world_to_camera.push(
        -row[0] * translation_camera_to_world[0] +
          -row[1] * translation_camera_to_world[1] +
          -row[2] * translation_camera_to_world[2],
      );
    }

    // Apply the transformation to the points
    let points_3d_camera = [];
    for (let i = 0; i < points_3d_world.length; i++) {
      let point = points_3d_world[i];
      let dotPoint = [];
      for (var k = 0; k < rotation_world_to_camera.length; k++) {
        let row = rotation_world_to_camera[k];
        dotPoint.push(row[0] * point[0] + row[1] * point[1] + row[2] * point[2]);
      }
      var pointOut = [
        dotPoint[0] + translation_world_to_camera[0],
        dotPoint[1] + translation_world_to_camera[1],
        dotPoint[2] + translation_world_to_camera[2],
      ];
      points_3d_camera.push(pointOut);
    }

    return points_3d_camera;
  }

  // Copied form the original code
  rodrigues_vec_to_rotation_mat(rodrigues_vec: number[]): number[][] {
    // Converts a rotation formulated as rodriguez vector to a rotation matrix similar to cv2.Rodriguez
    // Taken from https://stackoverflow.com/questions/62345076/how-to-convert-a-rodrigues-vector-to-a-rotation-matrix-without-opencv-using-pyth
    const theta = norm(rodrigues_vec);

    if (+theta < Number.EPSILON) {
      return [
        [1.0, 0.0, 0.0],
        [0.0, 1.0, 0.0],
        [0.0, 0.0, 1.0],
      ];
    }

    const r = dotDivide(rodrigues_vec, theta) as any;

    const I = [
      [1.0, 0.0, 0.0],
      [0.0, 1.0, 0.0],
      [0.0, 0.0, 1.0],
    ];
    const r_rT = [
      [r[0] * r[0], r[0] * r[1], r[0] * r[2]],
      [r[1] * r[0], r[1] * r[1], r[1] * r[2]],
      [r[2] * r[0], r[2] * r[1], r[2] * r[2]],
    ];
    const r_cross = [
      [0, -r[2], r[1]],
      [r[2], 0, -r[0]],
      [-r[1], r[0], 0],
    ];

    const part1 = dotMultiply(Math.cos(theta as number), I);
    const part2 = dotMultiply(1 - Math.cos(theta as number), r_rT);
    const part3 = dotMultiply(Math.sin(theta as number), r_cross);

    // @ts-ignore
    return add(part1, part2, part3);
  }

  // Copied form the original code
  applyCameraIntrinsics(points_3d: number[][]): number[][] {
    // if (points_3d[2] < 0.1) {
    //   return [-9999, -9999];
    // }

    const camera_matrix = this.calibration?.camera_matrix;
    // let dist_coefs = this.intrinsics.dist_coefs

    if (!camera_matrix) return [];

    // To homogeneous coordinates
    const points_3d_hom: number[][] = [];
    for (let i = 0; i < points_3d.length; i++) {
      let point = points_3d[i];
      points_3d_hom.push([
        point[0] / point[2],
        point[1] / point[2],
        point[2] / point[2],
      ]);
    }

    // distort points
    const points_3d_dist: number[][] = [];
    for (let i = 0; i < points_3d_hom.length; i++) {
      const distortedPoint = this.distort3D(points_3d_hom[i]);

      if (distortedPoint !== undefined) points_3d_dist.push(distortedPoint);
    }

    // Project to camera plane
    // matrix multiplication (matrix, vector)
    const points_2d: number[][] = [];
    for (let i = 0; i < points_3d_dist.length; i++) {
      const point = points_3d_dist[i];
      points_2d.push([]);

      for (let k = 0; k < camera_matrix.length; k++) {
        const row = camera_matrix[k];
        points_2d[i].push(row[0] * point[0] + row[1] * point[1] + row[2] * point[2]);
      }
    }

    const points_2d_c: number[][] = [];
    for (let i = 0; i < points_2d.length; i++) {
      const scaledPoint = this.scaleCoordinates({
        x: points_2d[i][0],
        y: points_2d[i][1],
      });

      if (!scaledPoint) continue;

      points_2d_c.push([scaledPoint.x, scaledPoint.y]);
    }

    return points_2d_c;
  }

  getDimension(instance = overlayControl.instance) {
    if (!instance || instance.isDisposed()) return 0;

    const width =
      instance.currentWidth() *
      (overlayControl.getVideoHeight() / overlayControl.getVideoWidth());
    const height =
      instance.currentHeight() *
      (overlayControl.getVideoWidth() / overlayControl.getVideoHeight());

    if (width && height) {
      if (instance.currentHeight() < width) return height;
      else return width;

      // return width < height
      //   ? instance.videoWidth() > 1600
      //     ? width
      //     : instance.currentWidth()
      //   : height;
    }

    return 0;
  }

  getDimension1(instance = overlayControl.instance) {
    const value = {
      width: 0,
      height: 0,
    };

    if (!instance || instance.isDisposed()) return value;

    const width =
      instance.currentWidth() *
      (overlayControl.getVideoHeight() / overlayControl.getVideoWidth());
    const height =
      instance.currentHeight() *
      (overlayControl.getVideoWidth() / overlayControl.getVideoHeight());

    if (width && height) {
      if (instance.currentHeight() < width)
        return { height: instance.currentHeight(), width: height };
      else return { height: width, width: instance.currentWidth() };
    }

    return value;
  }

  revertScale([x, y]: [number, number]): [number, number] {
    //const { width, height } = this.getDimension1();
    const el = document.getElementById("GazeOverlay");
    const width = el ? el.clientWidth : 0;
    const height = el ? el.clientHeight : 0;

    const videoWidth = overlayControl.getVideoWidth();
    const videoHeight = overlayControl.getVideoHeight();

    const xP = x / width;
    const yP = y / height;

    return [videoWidth * xP, videoHeight * yP];
  }
}

export const overlayCalculations = new OverlayCalculations();
