import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { RouterHelper } from "@pages";
import { useAppSelector } from "@storeRematch";
import { FC, PropsWithChildren, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

export interface OnboardingStep {
  id: string;
  subId?: string;
  title: string;
  text?: string;
  children?: ReactNode;
  to?: () => string;
  highlight?: string;
  placement: "top-left" | "top-right" | "bottom-left" | "bottom-right";
}

export interface OnboardingModalProp {
  content: OnboardingStep;
  onClose: () => void;
  onNext: () => void;
}

export const OnboardingModal: FC<PropsWithChildren<OnboardingModalProp>> = ({
  content,
  onNext,
  onClose,
}) => {
  const { title, children, text } = content;
  const theme = useTheme();
  const navigate = useNavigate();
  const steps = useAppSelector(state => state.onboarding.steps);
  const currentStepIndex = useAppSelector(state => state.onboarding.currentStepIndex);
  const defaultWorkspaceId = useAppSelector(
    state => state.app.apiUser?.default_workspace_id,
  );

  const isModalEnd = currentStepIndex + 1 === steps;

  const handleClose = () => {
    onClose();
    navigate(
      RouterHelper.workspaceRecordings.getFullPath({
        props: { workspaceId: defaultWorkspaceId },
      }),
    );
  };

  return (
    <Card sx={{ backgroundColor: theme.palette.primary.main }}>
      <CardContent sx={{ padding: "8px" }}>
        <Box
          display="flex"
          gap={1}
          mb={0.5}
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="body2">
            {currentStepIndex + 1}/{steps}
          </Typography>
        </Box>
        {children ? (
          children
        ) : (
          <Typography variant="body2" color="text.secondary">
            {text}
          </Typography>
        )}
      </CardContent>
      <CardContent sx={{ py: 0 }}>
        <Divider />
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between" }}>
        <Button variant="text" color="inherit" size="small" onClick={onClose}>
          {isModalEnd ? "Stay here" : "Skip"}
        </Button>
        <Button
          variant="text"
          color="inherit"
          size="small"
          onClick={isModalEnd ? handleClose : onNext}
        >
          {isModalEnd ? "My Workspace" : "Next"}
        </Button>
      </CardActions>
    </Card>
  );
};
