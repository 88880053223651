export function formatReadableAoiStatMs(ms: number | null) {
  if (!ms) return `0ms`;

  const date = new Date(Date.UTC(0, 0, 0, 0, 0, 0, ms));

  const s = date.getUTCSeconds();
  const m = date.getUTCMinutes();
  const h = date.getUTCHours();

  if (!s && !m && !h) return `${Math.round(ms)}ms`;
  if (!h && !m) return `${s}s`;
  if (!h) return `${formatZero(m)}:${formatZero(s)}m`;

  return `${formatZero(h)}:${formatZero(m)}:${formatZero(s)}h`;
}

function formatZero(num: number) {
  return num < 10 ? `0${num}` : num;
}
