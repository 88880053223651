import { Box, Button, Typography } from "@mui/material";
import { RouterHelper } from "@pages";
import { useAppSelector } from "@storeRematch";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FullScreenPaper } from "./FullScreenPaper";
import { ReactRouterLinkCustom } from "./ReactRouterLinkCustom";

export interface ErrorVariantProps {
  variant: "notfound" | "error";
}

export interface ErrorPageProps {
  title?: string;
  variant?: ErrorVariantProps["variant"];
  reload?: boolean;
}

interface ClientMouse {
  x: number;
  y: number;
}

const GradientBallSvg: React.FC<ErrorVariantProps> = ({ variant }) => {
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
  const ratioX = windowWidth / 2 / 150;
  const ratioY = windowHeight / 2 / 150;
  const ballEl = useRef<SVGRectElement>(null);

  const [clientMousePos, setClientMousePos] = useState<ClientMouse>({
    x: windowWidth / 2,
    y: windowHeight / 2,
  });

  const clamp = (num: number, min: number, max: number) => {
    return Math.min(Math.max(num, min), max);
  };

  const calculateRadiusScale = () => {
    let distanceFromCenter = 0;
    const elem = ballEl.current?.getBoundingClientRect();

    if (elem) {
      distanceFromCenter = Math.floor(
        Math.sqrt(
          Math.pow(clientMousePos.x - (elem.left + elem.width / 2), 2) +
            Math.pow(clientMousePos.y - (elem.top + elem.height / 2), 2),
        ),
      );
    }

    return clamp(distanceFromCenter / 250, 1, 2.5);
  };

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setClientMousePos({
        x: Math.round(event.clientX),
        y: Math.round(event.clientY),
      });
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="299" height="286" fill="none">
      <defs>
        <radialGradient
          id="a"
          cx="0"
          cy="0"
          r={calculateRadiusScale()}
          gradientTransform={`matrix(0 143 -149.5 0 ${clientMousePos?.x / ratioX} ${
            clientMousePos?.y / ratioY
          })`}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={variant === "notfound" ? "#D9D9D9" : "#6D7BE0"} />
          <stop stopColor="#D9D9D9" stopOpacity="0" offset="1" />
        </radialGradient>
      </defs>
      <rect ref={ballEl} width="299" height="286" fill="url(#a)" rx="143" />
    </svg>
  );
};

export const ErrorPage: React.FC<React.PropsWithChildren<ErrorPageProps>> = ({
  title = "Error 404",
  children,
  variant = "notfound",
  reload = false,
}) => {
  const { t } = useTranslation();
  const defaultWorkspaceId = useAppSelector(
    state => state.app.apiUser?.default_workspace_id,
  );

  return (
    <FullScreenPaper>
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        position="relative"
      >
        <Box display="grid" gap={3}>
          <Box width="300px" height="300px" sx={{ position: "relative" }}>
            <GradientBallSvg variant={variant} />
          </Box>
          <Box display="grid" gap={1.5}>
            <Typography variant="h6">{title}</Typography>
            {children ? (
              children
            ) : (
              <Box>
                <Typography variant="body1">
                  {t("Oops! Something’s missing.")}
                </Typography>
                <Typography variant="body1">
                  {t("This is not the page you are looking for.")}
                </Typography>
              </Box>
            )}
            <Box>
              {reload ? (
                <Button color="primary" onClick={() => document.location.reload()}>
                  {t("Reload")}
                </Button>
              ) : (
                <Button
                  color="primary"
                  component={ReactRouterLinkCustom}
                  to={RouterHelper.workspaceRecordings.getFullPath({
                    props: { workspaceId: defaultWorkspaceId },
                  })}
                >
                  {t("Go to your workspace")}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </FullScreenPaper>
  );
};
