import {
  BackButtonBase,
  FullScreenPaper,
  FullScreenPaperBody,
  FullScreenPaperTitle,
  TemplateFormDivider,
  TemplateItemItems,
  TemplateItemItemsControls,
  TemplateNameEdit,
  TemplateRecordDescription,
  TemplateRecordName,
} from "@components";
import { Button, Divider, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { QuestionDialogTypes, useAppDispatch, useAppSelector } from "@storeRematch";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export const TemplateForm: React.FC = () => {
  return (
    <FullScreenPaper>
      <FullScreenPaperTitle>
        <Box maxWidth="30%">
          <BackButtonWrapper />
        </Box>
        <TemplateNameEdit />
        <Box maxWidth="30%">
          <Control />
        </Box>
      </FullScreenPaperTitle>
      <Divider />
      <FullScreenPaperBody>
        <TemplateRecordName />
        <TemplateFormDivider />
        <TemplateItemItems />
        <TemplateItemItemsControls />
        <TemplateFormDivider />
        <TemplateRecordDescription />
      </FullScreenPaperBody>

      <Watcher />
    </FullScreenPaper>
  );
};

const Control = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(
    state =>
      !state.templateForm.draft.nameFormat.length ||
      state.loading.models.templates.loading ||
      state.loading.models.app.loading ||
      (state.templateForm.current !== null &&
        state.templateForm.current.published_at !== null),
  );

  return (
    <Box display="flex" width="100%" justifyContent="flex-end">
      <Grid container spacing={2} width="auto">
        <Grid item>
          <Button onClick={() => dispatch.templateForm.setShowPreview()}>
            {t("Preview")}
          </Button>
        </Grid>
        <Grid item>
          <Button disabled={disabled} onClick={() => dispatch.templates.update(false)}>
            {t("Save")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={disabled}
            onClick={() => {
              dispatch.questionDialog.set({
                type: QuestionDialogTypes.TEMPLATE_FORM_PUBLISH,
                onSuccess: () => dispatch.templates.update(true),
              });
            }}
          >
            {t("Publish")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const Watcher = () => {
  const countRef = useRef(0);
  const draft = useAppSelector(state => state.templateForm.draft);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (countRef.current < 2) countRef.current += 1;
    else if (!draft.published) dispatch.templateForm.setUnsaved(true);
  }, [draft, dispatch]);

  return null;
};

const BackButtonWrapper = () => {
  const unsaved = useAppSelector(state => state.templateForm.unsaved);
  const dispatch = useAppDispatch();

  return (
    <BackButtonBase
      to=".."
      text="Templates"
      onClick={e => {
        if (!unsaved) return;

        const currentTarget = e.currentTarget;
        e.preventDefault();

        dispatch.questionDialog.set({
          type: QuestionDialogTypes.TEMPLATE_FORM_UNSAVED,
          onSuccess: () => {
            dispatch.templateForm.setUnsaved(false);

            setTimeout(() => {
              currentTarget.click();
            });
          },
        });
      }}
    />
  );
};
