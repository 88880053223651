import { useBackButton } from "@hooks";
import { ChevronLeft } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactRouterLinkCustom } from "./ReactRouterLinkCustom";

export const BackButton = ({
  text = "Back",
  prev,
}: {
  text?: string;
  prev?: string;
}) => {
  const { previousPage } = useBackButton();

  return (
    <BackButtonBase
      disabled={!prev && !previousPage}
      to={previousPage || prev || "/"}
      text={text}
    />
  );
};

export const BackButtonBase = ({
  to,
  disabled,
  text,
  onClick,
}: {
  to: string;
  disabled?: boolean;
  text: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}) => {
  const { t } = useTranslation();

  return (
    <MenuItem
      disabled={disabled}
      component={ReactRouterLinkCustom}
      to={to}
      onClick={onClick}
    >
      <ListItemIcon>
        <ChevronLeft />
      </ListItemIcon>
      <ListItemText primary={t(text)} />
    </MenuItem>
  );
};
