import { SvgIcon, SvgIconProps } from "@mui/material";

export const VisualizationsHeatmap: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="65"
      height="48"
      viewBox="0 0 65 48"
      fill="none"
      sx={{ ...props.sx, fill: "none", fontSize: "4.5rem" }}
    >
      <g clipPath="url(#clip0_3659_74377)">
        <rect width="65" height="48" fill="#2C3232" />
        <path
          d="M21.6094 19C21.6094 16.7909 23.4002 15 25.6094 15H35.6094C40.0277 15 43.6094 18.5817 43.6094 23V25C43.6094 29.4183 40.0277 33 35.6094 33H29.6094C25.1911 33 21.6094 29.4183 21.6094 25V19Z"
          fill="#E2BC85"
        />
        <path
          d="M40.6094 24C40.6094 27.3137 37.9231 30 34.6094 30L32.6094 30C29.2957 30 26.6094 27.3137 26.6094 24L26.6094 22C26.6094 19.7909 28.4002 18 30.6094 18L34.6094 18C37.9231 18 40.6094 20.6863 40.6094 24V24Z"
          stroke="#2C3232"
        />
        <path
          d="M31.6094 25C31.6094 26.1046 32.5048 27 33.6094 27L34.6094 27C36.2662 27 37.6094 25.6569 37.6094 24V24C37.6094 22.3431 36.2662 21 34.6094 21V21C32.9525 21 31.6094 22.3431 31.6094 24L31.6094 25Z"
          stroke="#2C3232"
        />
      </g>
      <defs>
        <clipPath id="clip0_3659_74377">
          <rect width="65" height="48" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
