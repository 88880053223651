import { api } from "@api";
import { GoogleButton, Loader } from "@components";
import { PasswordTextField, SubmitButton, TextField } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Grid, Link, Stack, Typography } from "@mui/material";
import {
  EmailPasswordCredentials,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import {
  LocalStorageAdapterNames,
  currentPasswordSchema,
  emailSchema,
  googleGroupedLogin,
  localStorageAdapter,
  useNavigateToEmailVerified,
} from "@utils";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    email: emailSchema,
    password: currentPasswordSchema,
  })
  .required();

export const SignIn: React.FC = () => {
  const { t } = useTranslation();
  const user = useAppSelector(state => state.app.apiUser);
  const isLoading = useAppSelector(
    state => state.app.authLoading || state.loading.effects.app.finalizeAuth.loading,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setNavigate, Navigate } = useNavigateToEmailVerified();
  const [cannyLoading, setCannyLoading] = useState(false);

  useEffect(() => {
    dispatch.app.finalizeAuth({});
  }, [dispatch]);

  useEffect(() => {
    if (!user) return;

    const cannyRedirect = localStorageAdapter.get<string>(
      LocalStorageAdapterNames.cannySsoRedirect,
    );
    const cannyCompanyId = localStorageAdapter.get<string>(
      LocalStorageAdapterNames.cannyCompanyId,
    );
    if (cannyRedirect && cannyCompanyId) {
      setCannyLoading(true);
      localStorageAdapter.delete(LocalStorageAdapterNames.cannySsoRedirect);
      localStorageAdapter.delete(LocalStorageAdapterNames.cannyCompanyId);
      api
        .getCannySsoToken()
        .then(data => {
          window.location.replace(
            `https://canny.io/api/redirects/sso?companyID=${cannyCompanyId}&ssoToken=${data.result.token}&redirect=${cannyRedirect}`,
          );
        })
        .catch(() => {
          console.error("Unable to redirect to canny.io");
          dispatch.notifier.enqueueSnackbar({
            message: "Unable to redirect to canny.io",
            options: { variant: "error" },
          });
        })
        .finally(() => setCannyLoading(false));
      return;
    }

    const queryParams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop as string),
    });

    // @ts-ignore
    const redirectPath = queryParams.redirect || "/";
    navigate(redirectPath, { replace: true });
  }, [user, navigate, dispatch]);

  const methods = useForm<EmailPasswordCredentials>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const emailLogin = (credentials: EmailPasswordCredentials) => {
    setNavigate(googleGroupedLogin(dispatch, credentials));
  };

  const googleLogin = () => {
    setNavigate(googleGroupedLogin(dispatch, undefined, true));
  };

  if (Navigate) return Navigate;

  if (isLoading || cannyLoading) {
    return <Loader />;
  }

  return (
    <>
      <GoogleButton onClick={googleLogin} />

      <Typography variant="body2" color="text.secondary">
        {t(`Or, sign in using your email address.`)}
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(emailLogin)} noValidate>
          <Stack spacing={2}>
            <TextField
              autoFocus
              name="email"
              autoComplete="email"
              fullWidth
              label="Email"
              required
            />
            <PasswordTextField
              name="password"
              autoComplete="current-password"
              label="Password"
              fullWidth
              required
            />
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              flexWrap="nowrap"
            >
              <Grid item>
                <Typography variant="body2" color="primary.main">
                  <Link href={"/remind"}>{t(`Forgot your password?`)}</Link>
                </Typography>
              </Grid>
              <Grid item>
                <SubmitButton>{t("Log in")}</SubmitButton>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </FormProvider>

      <Divider />

      <Box>
        <Typography variant="body2" color="text.secondary" component="span" mr={1}>
          {t(`Don't have an account?`)}
        </Typography>
        <Typography variant="body1" component="span">
          <Link href={"/signup"}>{t(`Sign Up for Pupil Cloud`)}</Link>
        </Typography>
      </Box>
    </>
  );
};
