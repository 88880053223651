import { Label } from "@api";
import { LabelChip } from "@components/LabelChip";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { v4 as uuidv4 } from "uuid";

export function FilterLabelChip({ label }: { label: Label }) {
  const apiRef = useGridApiContext();

  const saveFilter = () => {
    apiRef.current.upsertFilterItem({
      id: uuidv4(),
      field: "labels",
      value: [{ id: label.id, name: label.name }],
      operator: "isOneOf",
    });
  };

  return (
    <LabelChip
      key={label.id}
      bgColor={label.color}
      label={label.name}
      onClick={saveFilter}
    />
  );
}
