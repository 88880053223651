import { api, PostHeatmapApiResponse } from "@api";
import { HeatmapFormValues } from "@pages";
import { createModel } from "@rematch/core";
import { createCanvasLegend, downloadLocallyBlob, prepareHeatMap } from "@utils";
import JSZip from "jszip";
import i18n from "../i18n";
import type { RootModel } from "./index";

let heatmapData: PostHeatmapApiResponse["result"] | undefined = undefined;

interface InitialState {
  canvasRef: HTMLCanvasElement | null;
  imageUrl?: string;
  nBins: number;
  imageLoaded: boolean;
  currentEnrichmentId?: string;
}

const initialState: InitialState = {
  canvasRef: null,
  imageUrl: undefined,
  nBins: 300,
  imageLoaded: false,
  currentEnrichmentId: undefined,
};

export const heatmap = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setImageUrl(state, data: InitialState["imageUrl"]) {
      state.imageUrl = data;
    },
    setCanvasRef(state, data: InitialState["canvasRef"]) {
      state.canvasRef = data;
    },
    setImageLoaded(state, data: InitialState["imageLoaded"]) {
      state.imageLoaded = data;
    },
    setCurrentEnrichmentId(state, data: InitialState["currentEnrichmentId"]) {
      state.currentEnrichmentId = data;
    },
    reset() {
      heatmapData = undefined;
      return { ...initialState };
    },
  },
  effects: dispatch => ({
    async get(
      {
        id,
        recordingIds,
        disableCheck = false,
      }: { id: string; recordingIds?: string[]; disableCheck?: boolean },
      state,
    ) {
      const currentEnrichmentId = state.heatmap.currentEnrichmentId;

      if (!disableCheck && currentEnrichmentId === id) return;

      dispatch.heatmap.reset();
      dispatch.heatmap.setCurrentEnrichmentId(id);

      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichment = state.enrichments.dataById.get(id);

      if (!workspaceId || !projectId || !enrichment) return;

      try {
        const [imageUrl, heatmap] = await Promise.all([
          api.getHeatmapBackground({
            enrichment_id: enrichment.id,
            project_id: projectId,
            workspaceId,
            maxSize: 1920,
          }),
          api.postHeatmap({
            workspaceId,
            generateHeatmap: {
              enrichment_id: enrichment.id,
              height: 1920,
              json: true,
              n_bins: 300,
              project_id: projectId,
              recording_ids: recordingIds,
              width: 1920,
            },
          }),
        ]);

        heatmapData = recordingIds?.length ? heatmap.result : undefined;
        dispatch.heatmap.setImageUrl(imageUrl);
      } catch (error) {
        console.error(error);
      }
    },
    dataChange(data: Omit<HeatmapFormValues, "id">, state) {
      const canvas = state.heatmap.canvasRef;
      const imageLoaded = state.heatmap.imageLoaded;

      if (!heatmapData || !canvas || !imageLoaded) return;

      const width = canvas.previousElementSibling?.clientWidth || 0;
      const height = canvas.previousElementSibling?.clientHeight || 0;

      const heatmapCanvas = createHeatmapDataCanvas({
        width,
        height,
        heatmapData,
        data,
        nBins: state.heatmap.nBins,
      });

      if (!heatmapCanvas) return;

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");

      if (!ctx) return;

      ctx.drawImage(heatmapCanvas, 0, 0, width, height);
    },
    async download(data: HeatmapFormValues, state) {
      let url = state.heatmap.imageUrl;
      let heatmapDataLocal = heatmapData;
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;

      if (!data.enrichmentId || !projectId || !workspaceId || !data.recordingIds.length)
        return;

      try {
        if (!url || !heatmapData) {
          const [imageUrl, heatmap] = await Promise.all([
            api.getHeatmapBackground({
              enrichment_id: data.enrichmentId,
              project_id: projectId,
              workspaceId,
              maxSize: 1920,
            }),
            api.postHeatmap({
              workspaceId,
              generateHeatmap: {
                enrichment_id: data.enrichmentId,
                height: 1920,
                json: true,
                n_bins: 300,
                project_id: projectId,
                recording_ids: data.recordingIds,
                width: 1920,
              },
            }),
          ]);

          url = imageUrl;
          heatmapDataLocal = heatmap.result;
        }

        if (!url) return;

        await new Promise(resolve => {
          const image = new Image();
          image.onload = async () => {
            if (!heatmapDataLocal) return;

            const width = image.naturalWidth;
            const height = image.naturalHeight;

            const canvas = createCanvasLegend({
              width,
              height,
              draw: ctx => {
                ctx.drawImage(image, 0, 0);
              },
              heatmapColor: data.colorMap,
              heatmapType: null,
            });

            const ctx = canvas.getContext("2d");

            const heatmapCanvas = createHeatmapDataCanvas({
              width: width,
              height: canvas.height,
              heatmapData: heatmapDataLocal,
              data,
              nBins: state.heatmap.nBins,
            });

            if (!ctx || !heatmapCanvas) return;

            ctx.drawImage(heatmapCanvas, 0, 0, width, canvas.height);

            const zip = new JSZip();

            canvas.toBlob(blob => {
              if (!blob) return;
              zip.file("overlay.png", blob, { base64: true });

              const overlay = document.createElement("canvas");
              const ctx = overlay.getContext("2d");
              overlay.width = width;
              overlay.height = canvas.height;

              if (!ctx) return;

              ctx.drawImage(heatmapCanvas, 0, 0, overlay.width, overlay.height);

              overlay.toBlob(blob => {
                if (!blob) return;
                zip.file("heatmap.png", blob, { base64: true });

                zip.generateAsync({ type: "blob" }).then(function (blob) {
                  downloadLocallyBlob({ blob, name: `${data.name}.zip` });
                  resolve(true);
                });
              });
            });

            dispatch.notifier.enqueueSnackbar({
              message: i18n.t("Heatmap downloaded"),
              options: {
                variant: "success",
              },
            });
          };

          image.onerror = () => {
            dispatch.notifier.enqueueSnackbar({
              message: i18n.t("Unable to download Heatmap"),
              options: {
                variant: "error",
              },
            });
          };

          if (url) image.src = url;
        });
      } catch (error) {
        console.error(error);
      }
    },
    clearCanvas(_, state) {
      const canvas = state.heatmap.canvasRef;
      const ctx = canvas?.getContext("2d");

      if (!ctx || !canvas) return;

      const { width, height } = canvas.getBoundingClientRect();

      ctx.clearRect(0, 0, width, height);
    },
  }),
});

function createHeatmapDataCanvas({
  width,
  height,
  heatmapData,
  data,
  nBins,
}: {
  width: number;
  height: number;
  heatmapData: PostHeatmapApiResponse["result"];
  data: Omit<HeatmapFormValues, "id">;
  nBins: number;
}) {
  const heatmap = prepareHeatMap(
    [...heatmapData.histogram],
    heatmapData.width,
    heatmapData.height,
    (data.scale / 100) * nBins,
    data.colorMap,
  );

  // first canvas to create heatmap image data
  const canvas1 = document.createElement("canvas");
  const ctx1 = canvas1.getContext("2d");
  canvas1.width = width;
  canvas1.height = height;

  if (!ctx1) return;

  const imageData = ctx1.createImageData(heatmapData.width, heatmapData.height);
  imageData.data.set(heatmap);

  // another canvas for the overlay
  const canvas2 = document.createElement("canvas");
  const ctx2 = canvas2.getContext("2d");
  canvas2.width = heatmapData.width;
  canvas2.height = heatmapData.height;

  if (!ctx2) return;

  ctx2.putImageData(imageData, 0, 0);

  return canvas2;
}
