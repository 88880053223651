import { EnrichmentTypesEnum, ProjectEnrichment } from "@api";
import {
  EntityDataTable,
  NewProjectModelDialogMainButton,
  NoEnrichmentsData,
} from "@components";
import { Box, Divider } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { RouterHelper } from "@pages";
import { store, useAppSelector } from "@storeRematch";
import {
  LocalStorageAdapterNames,
  formatReadableEnrichment,
  getEnrichmentStatusTextFull,
  useGetEnrichmentStatusIcon,
} from "@utils";
import { FC, useMemo } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import TimeAgo from "timeago-react";
import { ProjectEnrichmentTableContextMenu } from "./ProjectEnrichmentTableContextMenu";

const columns: GridColDef<ProjectEnrichment>[] = [
  { field: "id", headerName: "ID" },
  {
    width: 300,
    field: "name",
    headerName: "Enrichment Name",
    valueGetter: (_, row) => {
      return row.name;
    },
  },
  {
    field: "kind",
    width: 170,
    headerName: "Type",
    type: "string",
    valueGetter: (_, row) => {
      return formatReadableEnrichment(row.kind);
    },
  },
  {
    field: "created_at",
    width: 107,
    headerName: "Created",
    type: "date",
    valueGetter: (_, row) => {
      return row.created_at ? new Date(row.created_at) : null;
    },
    renderCell: params => {
      return params.row.created_at ? (
        <TimeAgo datetime={params.row.created_at} opts={{ minInterval: 60 }} />
      ) : null;
    },
  },
  {
    field: "recordings_included",
    width: 145,
    headerName: "Recordings included",
    type: "number",
    valueGetter: (_, row) => {
      const data =
        store.getState().enrichments.enrichmentsWithRecordings.get(row.id)?.size || 0;

      return data ? data : 0;
    },
    renderCell: params => {
      const count = store.getState().projectEdit.recordings.length;
      const data =
        store.getState().enrichments.enrichmentsWithRecordings.get(params.row.id)
          ?.size || 0;

      return count !== undefined && data !== undefined ? (
        <>
          {data} / {count}
        </>
      ) : null;
    },
    align: "left",
  },
  {
    field: "status",
    width: 125,
    headerName: "Status",
    type: "number",
    valueGetter: (_, row) => {
      if (row.status?.SUCCESS) return 10;
      if (row.status?.READY || row.status?.STALE) return 9;
      if (row.status?.COMPUTING) return 8;
      if (row.status?.ERROR) return 7;
      return 5;
    },
    renderCell: params => {
      return (
        <Box sx={{ display: "flex", width: "100%" }}>
          <EnrichmentStatus model={params.row} />
        </Box>
      );
    },
  },
];

const EnrichmentStatus: FC<{ model: ProjectEnrichment }> = ({ model }) => {
  return (
    <>
      <Box sx={{ mr: 1, alignItems: "center", display: "flex" }}>
        {useGetEnrichmentStatusIcon(model, true)}
      </Box>
      {getEnrichmentStatusTextFull(model)}
    </>
  );
};

export const ProjectEnrichmentTable = () => {
  const params = useParams();
  const navigate = useNavigate();
  const enrichments = useAppSelector(state => state.enrichments.data);
  const loading = useAppSelector(
    state =>
      state.loading.models.projects.loading ||
      state.loading.models.app.loading ||
      state.loading.models.enrichments.loading,
  );
  const rows = useMemo(() => {
    const prepared: ProjectEnrichment[] = [];

    for (const one of enrichments) {
      if (
        one.kind === EnrichmentTypesEnum.RAW_DATA_EXPORT ||
        one.kind === EnrichmentTypesEnum.RENDER
      )
        continue;

      prepared.push(one);
    }

    return prepared;
  }, [enrichments]);

  if (params.enrichmentId) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Outlet />
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <EntityDataTable
        rows={rows}
        columns={columns}
        noDataMessage={<NoEnrichmentsData />}
        tableColumnInitialStateStorageKey={
          LocalStorageAdapterNames.projectEnrichmentColumState
        }
        initialState={{
          columns: { columnVisibilityModel: { id: false } },
          sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
        }}
        onRowDoubleClick={(props: GridRowParams<ProjectEnrichment>) => {
          navigate(
            RouterHelper.projectEnrichmentsEdit.getRelativePath({
              props: { enrichmentId: props.row.id },
            }),
          );
        }}
        childrenLeftFilter
        toolbarPadding
        loading={loading}
      >
        <NewProjectModelDialogMainButton main />
        <Divider orientation="vertical" sx={{ mx: 1, height: 26 }} />
      </EntityDataTable>
      <ProjectEnrichmentTableContextMenu />
    </Box>
  );
};
