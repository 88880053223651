export function formatTimeNumber(num: number, mili?: boolean) {
  return `${num < 10 ? "0" : ""}${mili && num < 100 ? "0" : ""}${num}`;
}

export function getTimeNumbers(num: number) {
  const start = Math.floor(num);
  const hours = Math.floor(start / 3600);
  const minutes = Math.floor((start - hours * 3600) / 60);
  const seconds = start - hours * 3600 - minutes * 60;
  const milliseconds = Math.trunc(Number(String((num * 1000) % 1000).slice(0, 3)));

  return {
    hours,
    minutes,
    seconds,
    milliseconds,
  };
}

function getNumber(value: string, pos: number) {
  const num = Number(value[pos]);

  return isNaN(num) ? 0 : num;
}

export function getHMSMsFromDurationString(value: string) {
  const h = Number(`${getNumber(value, 0)}${getNumber(value, 1)}`);
  const m = Number(`${getNumber(value, 3)}${getNumber(value, 4)}`);
  const s = Number(`${getNumber(value, 6)}${getNumber(value, 7)}`);
  const ms = Number(
    `${getNumber(value, 9)}${getNumber(value, 10)}${getNumber(value, 11)}`,
  );

  return { h, m, s, ms };
}

export function formatReadableDuration(num: number): string {
  const { hours, minutes, seconds } = getTimeNumbers(num);

  return `${formatTimeNumber(hours)}:${formatTimeNumber(minutes)}:${formatTimeNumber(
    seconds,
  )}`;
}

export function formatDurationInput(num: number): string {
  const { hours, minutes, seconds, milliseconds } = getTimeNumbers(num);

  return `${formatTimeNumber(hours)}:${formatTimeNumber(minutes)}:${formatTimeNumber(
    seconds,
  )}.${formatTimeNumber(milliseconds, true)}`;
}

export function formatDurationInputTimeline(num: number) {
  const { hours, minutes, seconds } = getTimeNumbers(num);

  const fHours = hours;
  const fMinutes = `${minutes < 10 ? 0 : ""}${minutes}`;
  const fSeconds = `${seconds < 10 ? 0 : ""}${seconds}`;

  if (!hours && !minutes) return `:${fSeconds}`;
  if (!hours) return `${fMinutes}:${fSeconds}`;
  if (hours) return `${fHours}:${fMinutes}:${fSeconds}`;

  return `:00`;
}

export function formatDurationNs(value: number) {
  return new Date(value / 1e6).toISOString().substring(11, 19);
}

export function formatDurationNsFull(value: number) {
  return new Date(value / 1e6).toISOString().substring(11, 23);
}
