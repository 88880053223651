import { api, ProjectEnrichment, RecordingEvent } from "@api";
import { createModel } from "@rematch/core";
import {
  localStorageAdapter,
  LocalStorageAdapterNames,
  moveZoomHorizontalScroll,
} from "@utils";
import { v4 as uuidv4 } from "uuid";
import i18n from "../i18n";
import { RootModel } from "./index";
import { VideoEventsTypes, VirtualListRows } from "./types";

export const DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT = 30;
export const DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_OPEN_HEIGHT = 45;

interface InitialState {
  resetFromIndex: number | null;
  scrollToIndex: number | null;
  openEvents: boolean;
  virtualListRows: Array<VirtualListRows>;
  virtualListEvents: Array<VirtualListRows>;
  currentEvent: RecordingEvent | null;
  editEvent: RecordingEvent | null;
  newEventId: string | null;
  currentEnrichmentEvents: Record<"start" | "end", RecordingEvent> | null;
}

const DEFAULT_ENRICHMENT: VirtualListRows = {
  type: VideoEventsTypes.ENRICHMENT,
  height: DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT,
};
const DEFAULT_FIXATION: VirtualListRows = {
  type: VideoEventsTypes.FIXATION,
  height: DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT,
};
const DEFAULT_GAZE: VirtualListRows = {
  type: VideoEventsTypes.GAZE,
  height: DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT,
};
const DEFAULT_BLINKS: VirtualListRows = {
  type: VideoEventsTypes.BLINKS,
  height: DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT,
};

const initialState: InitialState = {
  openEvents:
    localStorageAdapter.get<boolean>(
      LocalStorageAdapterNames.videoEventsVirtualListOpen,
    ) ?? false,
  resetFromIndex: null,
  scrollToIndex: null,
  virtualListRows: [
    {
      type: VideoEventsTypes.THUMBNAIL,
      height: DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_OPEN_HEIGHT,
    },
    DEFAULT_GAZE,
    DEFAULT_FIXATION,
    DEFAULT_BLINKS,
    { type: VideoEventsTypes.EVENTS, height: DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT },
    DEFAULT_ENRICHMENT,
  ],
  virtualListEvents: [],
  currentEvent: null,
  editEvent: null,
  newEventId: null,
  currentEnrichmentEvents: null,
};

function concatRows(state: InitialState) {
  let next = state.virtualListRows.slice(0, 5);

  if (state.openEvents) {
    next = next.concat(state.virtualListEvents);
  }

  next.push(DEFAULT_ENRICHMENT);

  state.virtualListRows = next;
}

export const videoEvents = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setResetFromIndex(state, data: InitialState["resetFromIndex"]) {
      state.resetFromIndex = data;
    },
    setScrollToIndex(state, data: InitialState["scrollToIndex"]) {
      state.scrollToIndex = data;
    },
    setOpenEvents(state, data: InitialState["openEvents"]) {
      state.openEvents = data;
      state.resetFromIndex = 1;

      localStorageAdapter.set(
        LocalStorageAdapterNames.videoEventsVirtualListOpen,
        data,
      );

      concatRows(state);
    },
    setVirtualListEvents(state, data: InitialState["virtualListEvents"]) {
      state.virtualListEvents = data;
    },
    setVirtualListRows(state) {
      if (state.openEvents) {
        concatRows(state);
      }
    },
    toggleThumbnailVisible(state) {
      if (state.virtualListRows[0].height !== DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT) {
        state.virtualListRows[0].height = DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT;
      } else {
        state.virtualListRows[0].height = initialState.virtualListRows[0].height;
      }
      state.resetFromIndex = 0;
    },
    setCurrentEvent(state, data: InitialState["currentEvent"]) {
      state.currentEvent = data;
    },
    setEditEvent(state, data?: InitialState["editEvent"]) {
      state.editEvent = data === null ? data : state.currentEvent;
    },
    setNewEventId(state, data: InitialState["newEventId"]) {
      state.newEventId = data;
    },
    setCurrentEnrichmentEvents(state, data: InitialState["currentEnrichmentEvents"]) {
      state.currentEnrichmentEvents = data;
      concatRows(state);
    },
    reset() {
      return { ...initialState };
    },
  },
  effects: dispatch => ({
    prepare(_, state) {
      const recordingId = state.video.recording?.id;
      const newEventId = state.videoEvents.newEventId;

      if (!recordingId) return;

      const events = state.recordingEvents.eventsByRecordingId.get(recordingId);

      if (!events) return;

      const sortedEvents = Array.from(events).sort((a, b) => a.offset_s - b.offset_s);

      const virtualListRows: InitialState["virtualListRows"] = [];

      sortedEvents.forEach(e => {
        virtualListRows.push({
          type: VideoEventsTypes.EVENT,
          height: DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT,
          data: e,
        });
      });

      dispatch.videoEvents.setVirtualListEvents(virtualListRows);
      dispatch.videoEvents.setVirtualListRows();
      dispatch.videoEvents.calculateEnrichmentEvents(
        state.projectEdit.currentEnrichment,
      );

      if (newEventId) {
        const indexOfNewEvent = sortedEvents.findIndex(e => e.id === newEventId);

        if (indexOfNewEvent > -1)
          dispatch.videoEvents.setScrollToIndex(indexOfNewEvent);
      }
    },
    jumpToEventTime(_, state) {
      const instance = state.video.instance;
      const event = state.videoEvents.currentEvent;

      if (!instance || !event) return;

      instance.currentTime(event.offset_s);
      dispatch.videoEvents.setCurrentEvent(null);

      moveZoomHorizontalScroll(true);
    },
    async updateEvent(event: RecordingEvent, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const recordingId = state.video.recording?.id;

      if (!workspaceId || !recordingId) return;

      try {
        const res = await api.patchRecordingEvents({
          workspaceId,
          recordingId,
          eventId: event.id,
          RecordingEventPatchRequest: {
            name: event.name,
            offset_s: event.offset_s,
          },
        });

        dispatch.recordingEvents.upsertOne(res.result);
        dispatch.recordingEvents.prepare(null);
        dispatch.videoEvents.prepare(null);
        dispatch.videoEvents.setEditEvent(null);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Event updated").toString(),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async updateCurrentEventTime(_, state) {
      const event = state.videoEvents.currentEvent;
      const instance = state.video.instance;

      if (!event || !instance) return;

      dispatch.videoEvents.updateEventTime({ event, time: instance.currentTime() });
    },
    async updateEventTime(
      {
        event,
        time,
        revert,
      }: { event: RecordingEvent; time: number; revert?: boolean },
      state,
    ) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const recordingId = state.video.recording?.id;

      if (!workspaceId || !recordingId) return;

      try {
        const res = await api.patchRecordingEvents({
          workspaceId,
          recordingId,
          eventId: event.id,
          RecordingEventPatchRequest: {
            name: event.name,
            offset_s: time,
          },
        });

        dispatch.recordingEvents.upsertOne(res.result);
        dispatch.recordingEvents.prepare(null);
        dispatch.videoEvents.prepare(null);
        dispatch.videoEvents.setEditEvent(null);
        dispatch.videoEvents.setCurrentEvent(null);

        if (revert) {
          dispatch.notifier.enqueueSnackbar({
            message: i18n.t("Event time reverted").toString(),
            options: {
              variant: "success",
            },
          });
        } else {
          dispatch.notifier.enqueueSnackbar({
            message: i18n.t("Event time updated").toString(),
            options: {
              variant: "success",
              buttonComponent: {
                text: "Undo",
                onClick: () => {
                  dispatch.videoEvents.updateEventTime({
                    event,
                    time: event.offset_s,
                    revert: true,
                  });
                },
              },
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    prepareNewEvent(_, state) {
      const instance = state.video.instance;
      const recordingId = state.video.recording?.id;

      if (!instance || !recordingId) return;

      const newEvent: RecordingEvent = {
        id: uuidv4(),
        name: "",
        offset_s: instance.currentTime(),
        recording_id: recordingId,
        origin: "cloud",
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };

      dispatch.recordingEvents.upsertOne(newEvent);
      dispatch.videoEvents.setCurrentEvent(newEvent);
      dispatch.videoEvents.setEditEvent(undefined);
      dispatch.videoEvents.setNewEventId(newEvent.id);
      dispatch.recordingEvents.prepare(null);

      if (!state.videoEvents.openEvents) dispatch.videoEvents.setOpenEvents(true);
    },
    async createEvent(event: RecordingEvent, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const recordingId = state.video.recording?.id;

      if (!workspaceId || !recordingId) return;

      try {
        const res = await api.postRecordingEvents({
          workspaceId,
          recordingId,
          RecordingEventPostRequest: {
            offset_s: event.offset_s,
            name: event.name,
          },
        });

        if (typeof res === "string") return;

        dispatch.recordingEvents.deleteMany([event.id]);
        dispatch.recordingEvents.upsertOne(res.result);
        dispatch.recordingEvents.prepare(null);

        dispatch.videoEvents.setEditEvent(null);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Event created").toString(),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async deleteEvent(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const recordingId = state.video.recording?.id;

      const eventId = state.videoEvents.currentEvent?.id;

      if (!eventId || !workspaceId || !recordingId) return;

      try {
        await api.deleteRecordingEvents({
          workspaceId,
          recordingId,
          eventId,
        });

        dispatch.recordingEvents.deleteMany([eventId]);
        dispatch.recordingEvents.prepare(null);
        dispatch.videoEvents.setEditEvent(null);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Event deleted").toString(),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    calculateEnrichmentEvents(model: ProjectEnrichment | undefined, state) {
      if (!model) return;

      const recording = state.video.recording;

      if (!recording) return;

      const eventsByRecordingId = state.recordingEvents.eventsByRecordingId.get(
        recording.id,
      );

      if (!eventsByRecordingId) return null;

      const arr = Array.from(eventsByRecordingId);

      let start: RecordingEvent | null = null;
      let end: RecordingEvent | null = null;

      for (const one of arr) {
        if (
          one.name === model.from_event_name &&
          (!start || start.offset_s > one.offset_s)
        ) {
          start = one;
        }

        if (one.name === model.to_event_name && (!end || end.offset_s > one.offset_s)) {
          end = one;
        }
      }

      dispatch.videoEvents.setCurrentEnrichmentEvents(
        start && end ? { start, end } : null,
      );
    },
  }),
});
