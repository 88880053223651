import { useAppSelector } from "@storeRematch/index";
import { useEffect, useState } from "react";

export const useInstanceDuration = () => {
  const instance = useAppSelector(state => state.video.instance);
  const [duration, setDuration] = useState(instance?.duration());

  useEffect(() => {
    if (!instance) return;

    const update = () => {
      setDuration(instance.duration());
    };

    const reset = () => {
      setDuration(undefined);
    };

    instance.on("loadeddata", update);
    instance.on("loadstart", reset);

    return () => {
      instance.on("loadeddata", update);
      instance.on("loadstart", reset);
    };
  }, [instance]);

  return duration;
};
