import { StaticImageMapperFixation } from "@api";
import { store } from "@storeRematch";
import { overlayCalculations, scanPathOverlay } from ".";
import { ShapesControl, ShapesControlChildProps } from "./ShapesControl";
import { drawScanPath } from "./helpers";

export class StaticImageMapperOverlay extends ShapesControl<
  StaticImageMapperFixation,
  SVGCircleElement
> {
  constructor(props?: ShapesControlChildProps) {
    super({
      canvasId: "StaticImageMapperOverlay",
      paintType: true,
      canvasOverReference: true,
      ...props,
    });
  }

  circle(
    ctx: CanvasRenderingContext2D,
    point: number[],
    text = "",
    real = false,
    fill = false,
  ) {
    const defaultRadius = 17;
    const event = this.events[this.currentEventIndex];
    const scanPathEvent = scanPathOverlay.events[scanPathOverlay.currentEventIndex];
    const ms =
      event && scanPathEvent
        ? scanPathEvent.path.find(x => x.id === event.fixation_id)?.ms
        : null;

    const scaledRadius =
      typeof ms === "number"
        ? overlayCalculations.scaleCoordinates({
            x: 2 * Math.sqrt(ms / Math.PI),
            y: 2 * Math.sqrt(ms / Math.PI),
          })?.x
        : defaultRadius;

    drawScanPath({
      ctx,
      x: point[0],
      y: point[1],
      radius: scaledRadius ? scaledRadius : defaultRadius,
      text,
      strokeStyleBlur: real,
      lineWidth: 2,
      offsetTextX: 10,
      offsetTextY: 25,
      fillCircle: fill,
    });
  }

  notPainted(): void {
    store.dispatch.staticImageMappers.tryToSetCurrentFixationIndex(null);
  }

  paintAll(event?: StaticImageMapperFixation) {
    store.dispatch.staticImageMappers.tryToSetCurrentFixationIndex(
      this.currentEventIndex,
    );
    store.dispatch.staticImageMappers.toggleRerenderMouse();

    if (!event || event.x === null || event.y === null) return;

    const ctx = this.getCtx();

    if (!ctx) return;

    const x = ctx.canvas.offsetWidth * event.x;
    const y = ctx.canvas.offsetHeight * event.y;

    this.circle(ctx, [x, y], event.fixation_id.toString(), true);
  }
}
