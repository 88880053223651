import { EnrichmentTypesEnum, VisualizationTypes } from "@api";
import { ContextMenu, CtxMenuItem } from "@components";
import { MenuList } from "@mui/material";
import {
  ContextMenuTypes,
  QuestionDialogTypes,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import {
  DisableByPermissionsType,
  disabledByPermission,
  isVisualizationNotDownloadable,
} from "@utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { HeatmapFormValues } from "./HeatMap";

export const ProjectVisualizationsContextMenu: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selected = useAppSelector(state => state.entityTable.selectedIds);
  const id = selected[0];
  const enrichmentsById = useAppSelector(state => state.enrichments.dataById);
  const currentEnrichment = enrichmentsById.get(id);
  const visualizationsById = useAppSelector(state => state.visualizations.dataById);
  const currentVisualization = visualizationsById.get(id);
  const numSelected = selected.length;
  const isMultiSelect = numSelected > 1;
  const isNotDownloadable =
    currentVisualization?.kind === VisualizationTypes.AOI_HEATMAP
      ? !currentVisualization.enrichment_id ||
        !currentVisualization.payload.recording_ids.length ||
        !currentVisualization.payload.aoi_ids.length
      : currentVisualization?.kind === VisualizationTypes.HEATMAP
      ? !currentVisualization.enrichment_id ||
        !currentVisualization.payload.recording_ids.length
      : isVisualizationNotDownloadable(currentEnrichment);
  const disabledPermissionsDelete = disabledByPermission({
    type: DisableByPermissionsType.DELETE,
  });

  const onClose = () => {
    dispatch.ctxMenu.closeAll();
  };

  const handleDelete = () => {
    onClose();
    dispatch.questionDialog.set({
      type: QuestionDialogTypes.VISUALIZATION_DELETE,
      onSuccess: () => {
        const { enrichments, visualizations } = selected.reduce<{
          enrichments: string[];
          visualizations: string[];
        }>(
          (acc, id) => {
            if (enrichmentsById.has(id)) {
              acc.enrichments.push(id);
            } else if (visualizationsById.has(id)) {
              acc.visualizations.push(id);
            }

            return acc;
          },
          { enrichments: [], visualizations: [] },
        );

        if (enrichments.length) {
          dispatch.projectEdit.deleteVisualizations(enrichments);
        }

        if (visualizations.length) {
          dispatch.visualizations.delete(visualizations);
        }

        onClose();
      },
      extra: {
        count: selected.length,
      },
    });
  };

  return (
    <ContextMenu type={ContextMenuTypes.RECORD_TABLE}>
      <MenuList>
        <CtxMenuItem
          label={t("View Visualization")}
          onClick={() => {
            dispatch.visualizations.resetAoiIdsToAutoAdd();
            onClose();
          }}
          to={`./${
            currentEnrichment
              ? "render"
              : currentVisualization?.kind === VisualizationTypes.HEATMAP
              ? "heatmap"
              : "aoi"
          }/${currentEnrichment?.id || currentVisualization?.id}`}
          disabled={isMultiSelect}
          tooltip={t("Multiple items selected")}
        />
        <CtxMenuItem
          label={t("Download")}
          onClick={() => {
            if (currentEnrichment?.kind === EnrichmentTypesEnum.RENDER) {
              dispatch.download.download(currentEnrichment.id);
            } else if (
              currentVisualization?.kind === VisualizationTypes.HEATMAP &&
              currentVisualization.enrichment_id
            ) {
              dispatch.heatmap.download({
                id: currentVisualization.id,
                colorMap: currentVisualization.payload.color_map,
                scale: currentVisualization.payload.scale,
                recordingIds: currentVisualization.payload.recording_ids,
                name: currentVisualization.name,
                enrichmentId: currentVisualization.enrichment_id,
              } as HeatmapFormValues);
            } else if (currentVisualization) {
              dispatch.visualizationDownloads.addToQuery(currentVisualization.id);
            }
            onClose();
          }}
          divider
          disabled={isMultiSelect || isNotDownloadable}
          tooltip={t(
            isMultiSelect
              ? t("Multiple items selected")
              : t("Visualization is not downloadable"),
          )}
        />
        <CtxMenuItem
          label={
            numSelected === 1
              ? t("Delete visualization")
              : t("Delete {{numSelected}} visualizations", { numSelected })
          }
          onClick={handleDelete}
          disabled={disabledPermissionsDelete}
          tooltip={t("Disabled by permissions")}
        />
      </MenuList>
    </ContextMenu>
  );
};
