import { Box, Divider, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FC, PropsWithChildren } from "react";
export interface SettingItemProps {
  primary: string;
  secondary?: string;
}

export const SettingItem: FC<PropsWithChildren<SettingItemProps>> = ({
  primary,
  secondary,
  children,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box p={2}>
        <Grid display="grid" container gap={1} justifyItems={"flex-start"}>
          <Typography variant="subtitle2" color="text.secondary">
            {primary}
          </Typography>
          {secondary && (
            <Typography variant="body1" width={matches ? "100%" : "60%"}>
              {secondary}
            </Typography>
          )}
          {children}
        </Grid>
      </Box>
      <Divider />
    </>
  );
};
