import { ErrorPage } from "@components";
import { RouterHelper } from "@pages";
import { QuestionDialogTypes, useAppDispatch, useAppSelector } from "@storeRematch";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const WorkspaceDelete = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const workspaceId = String(params.workspaceId);
  const workspace = useAppSelector(
    state => state.app.currentWorkspaceMembership?.workspace,
  );
  const isLoading = useAppSelector(
    state => state.loading.effects.app.loadWorkspaceData.loading,
  );
  const currentId = useRef("");

  useEffect(() => {
    if (workspace && !isLoading && currentId.current !== workspaceId) {
      currentId.current = workspaceId;
      dispatch.questionDialog.set({
        type: QuestionDialogTypes.CONFIRM_DELETE,
        onSuccess: async () => {
          const defaultWorkspaceId = await dispatch.app.deleteWorkspace(workspaceId);
          if (defaultWorkspaceId) {
            navigate(
              RouterHelper.workspaceRecordings.getFullPath({
                prefix: "/",
                props: { workspaceId: defaultWorkspaceId },
              }),
              { replace: true },
            );
          }
        },
        extra: { title: "Workspace" },
      });
    }
  }, [workspace, isLoading, dispatch, workspaceId, navigate]);

  if (!workspace && !isLoading) {
    return <ErrorPage title={`Workspace ${workspaceId} not found in this account`} />;
  }

  return null;
};
