import { Token } from "@api";
import { EntityDataTable, NoDataDefault } from "@components";
import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GridColDef } from "@mui/x-data-grid-pro";
import { DeveloperContextMenu } from "@pages";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { LocalStorageAdapterNames } from "@utils";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import TimeAgo from "timeago-react";
import { AddTokenButton } from "./AddTokenButton";
import { TokenCopy } from "./TokenCopy";

export const AccountDeveloper = () => {
  const dispatch = useAppDispatch();
  const rows = useAppSelector(state => state.tokens.data);
  const isLoading = useAppSelector(state => state.loading.effects.tokens.get.loading);
  const activeToken = useAppSelector(state => state.tokens.activeToken);

  useEffect(() => {
    return () => {
      dispatch.tokens.setActiveToken(null);
    };
  }, [dispatch]);

  return (
    <>
      <EntityDataTable
        titleComponent={<Title />}
        loading={isLoading}
        rows={rows}
        columns={columns(activeToken)}
        noFilters
        noColumnFilter
        noSearch
        noDataMessage={<NoDataDefault name="tokens" />}
        tableColumnInitialStateStorageKey={
          LocalStorageAdapterNames.accountTokenColumState
        }
      >
        <AddTokenButton />
      </EntityDataTable>
      <DeveloperContextMenu />
      <Outlet />
    </>
  );
};

const Title = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Typography variant="subtitle2" mb={2}>
          {t("Native Recording Data")}
        </Typography>
        <Typography maxWidth={600} mb={2}>
          {t(
            "Create API tokens to access Pupil Cloud API. You can create and manage your API Tokens here.",
          )}
        </Typography>
      </Box>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
    </>
  );
};

const columns = (activeToken: Token | null) => {
  const columnData: GridColDef<Token>[] = [
    {
      width: 200,
      field: "name",
      headerName: "Token name",
      flex: 0,
      valueGetter: (_, row) => (row.name ? row.name : "Pending"),
      renderCell: params => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography ml={1.5} noWrap>
              {params.row.name || "Pending"}
            </Typography>
          </Box>
        );
      },
    },
    {
      width: 200,
      field: "last_used_at",
      headerName: "Last Used",
      flex: 0,
      type: "date",
      valueGetter: (_, row) => (row.last_used_at ? new Date(row.last_used_at) : null),
      renderCell: params => {
        return (
          (params.row.last_used_at && (
            <TimeAgo datetime={params.row.last_used_at} opts={{ minInterval: 60 }} />
          )) || <Typography>Never used</Typography>
        );
      },
    },
    {
      field: "token",
      headerName: "",
      flex: 1,
      sortable: false,
      renderCell: params => {
        if (activeToken && activeToken.token && activeToken.id === params.row.id) {
          return <TokenCopy token={activeToken.token} />;
        } else {
          return <></>;
        }
      },
    },
  ];

  return columnData;
};
