import { WorldRender } from "@api";

export const defaultRenderConfig = {
  gaze_circle: {
    color: {
      red: 255,
      green: 0,
      blue: 4,
      alpha: 128,
    },
    radius: 80,
    stroke_width: 20,
  },
  undistort_frames: false,
} as WorldRender;

export const aprilTagsColorOpacity = 0.6;
export const aprilTagSelectedColor = "#24FF00";
export const aprilTagNotSelectedColor = "#E5463B";
