import { GoogleButton } from "@components";
import { Loader } from "@components/Loader";
import { PasswordTextField, SubmitButton, TextField } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import { auth } from "@services/firebase";
import {
  EmailPasswordCredentials,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import {
  LocalStorageAdapterNames,
  emailSchema,
  googleGroupedLogin,
  localStorageAdapter,
  newPasswordSchema,
  useNavigateToEmailVerified,
} from "@utils";
import {
  AuthError,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Navigate as RouterNavigate } from "react-router-dom";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    username: yup.string().required(),
    email: emailSchema,
    password: newPasswordSchema,
  })
  .required();

export const SignUp: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isLoading = useAppSelector(
    state => state.app.authLoading || state.loading.effects.app.finalizeAuth.loading,
  );
  const { setNavigate, Navigate } = useNavigateToEmailVerified();
  const [navSolo, setNavSolo] = useState(false);

  const methods = useForm({
    defaultValues: {
      username: "",
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const createEmailUser = async (
    credentials: EmailPasswordCredentials & { username: string },
  ) => {
    if (!auth) return;

    try {
      const res = await createUserWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password,
      );

      await updateProfile(res.user, { displayName: credentials.username });

      localStorageAdapter.set(
        LocalStorageAdapterNames.unVerifiedEmail,
        auth.currentUser?.email,
      );
      return false;
    } catch (error) {
      let msg = "";

      switch ((error as AuthError).code) {
        case "auth/email-already-in-use":
          msg = "Email address already in use";
          break;
        case "auth/invalid-email":
          msg = "Email address is invalid.";
          break;
        case "auth/operation-not-allowed":
          msg = "Error during sign up.";
          break;
        case "auth/weak-password":
          msg =
            "Password is not strong enough. Add additional characters including special characters and numbers.";
          break;
        default:
          msg = "Unable to create credentials";
          break;
      }

      dispatch.notifier.enqueueSnackbar({
        message: t(msg),
        options: {
          variant: "error",
        },
      });
    }
  };

  const handleEmailSubmit = async (
    credentials: EmailPasswordCredentials & { username: string },
  ) => {
    const res = await createEmailUser(credentials);

    if (res === false) {
      const loginRes = await googleGroupedLogin(dispatch, credentials, true);

      if (loginRes === false) {
        setNavigate(new Promise(resolve => resolve(false)));
      } else {
        setNavSolo(true);
      }
    }
  };

  const handleGoogleLogin = async () => {
    const loginRes = await googleGroupedLogin(dispatch, undefined, true);

    if (loginRes === false) {
      setNavigate(new Promise(resolve => resolve(false)));
    } else {
      setNavSolo(true);
    }
  };

  if (navSolo) return <RouterNavigate to="/" replace />;

  if (Navigate) return Navigate;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <GoogleButton onClick={handleGoogleLogin} />

      <Typography variant="body2" color="text.secondary">
        {t(`To continue, please sign in using your email address.`)}
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleEmailSubmit)} noValidate>
          <Stack spacing={2}>
            <TextField autoFocus name="username" fullWidth label="Username" required />
            <TextField name="email" fullWidth label="Email" required />
            <PasswordTextField
              name="password"
              autoComplete="current-password"
              label="Password"
              fullWidth
              required
            />
            <SubmitButton fullWidth>{t("Create account")}</SubmitButton>
          </Stack>
        </form>
      </FormProvider>

      <Divider />

      <Box>
        <Typography variant="body2" color="text.secondary" component="span" mr={1}>
          {t(`Already have an account?`)}
        </Typography>
        <Typography variant="body1" component="span" color="primary.main">
          <Link href={"/signin"}>{t(`Log in to Pupil Cloud`)}</Link>
        </Typography>
      </Box>
    </>
  );
};
