import { Project } from "@api";
import {
  ProjectFilteredRecordingsFormMessage,
  ProjectFilteredRecordingsMessage,
} from "@components";
import { FormDialog, TextField } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import Stack from "@mui/material/Stack";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

export interface ProjectFormProps {
  open: boolean;
  onClose(): void;
  project?: Project;
  createWithSelection?: boolean;
}

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
  })
  .required();

export const ProjectForm: React.FC<ProjectFormProps> = ({
  open,
  onClose,
  project = undefined,
  createWithSelection = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentWorkspaceId = useAppSelector(
    state => state.app.currentWorkspaceMembership?.workspace.id,
  );
  const isLoading = useAppSelector(state => state.loading.models.app.loading);

  useEffect(() => {
    dispatch.projectFilterRecordings.findFilteredCreate(null);
  }, [dispatch]);

  if (isLoading) return null;

  const handleSubmit = async (formData: Project) => {
    const payload = {
      id: formData.id,
      name: formData.name,
    };

    if (createWithSelection) {
      onClose();
      dispatch.projects.newProjectFromSelection({ name: formData.name });
    } else {
      if (!currentWorkspaceId) return;

      try {
        if (formData.id) {
          await dispatch.projects.update({
            workspaceId: currentWorkspaceId,
            projectId: formData.id,
            projectPatchRequest: payload,
          });
        } else {
          await dispatch.projects.create({
            workspaceId: currentWorkspaceId,
            projectPostRequest: payload,
          });
        }
        const message = project ? t("Project name updated") : t("New project created");
        dispatch.notifier.enqueueSnackbar({
          message,
          options: {
            variant: "success",
          },
        });
        onClose();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <FormDialog
      open={open}
      title={project ? t("Edit project") : t("Create project")}
      onFormSubmit={handleSubmit}
      onClose={onClose}
      useFormProps={{
        defaultValues: project || { name: "" },
        resolver: yupResolver(schema),
      }}
      create={!project}
      buttonText={project ? t("Edit project") : t("Create project")}
    >
      <ProjectFilteredRecordingsMessage />

      <ProjectFilteredRecordingsFormMessage created>
        <Stack spacing={4}>
          <TextField name="name" label={t("Project name")} autoFocus />
        </Stack>
      </ProjectFilteredRecordingsFormMessage>
    </FormDialog>
  );
};
