import { CtxMenuItem } from "@components";
import { formatDurationNsFull } from "@components/videoPlayer/utils";
import { INACTIVE_FIELD_NAME, READABLE_DATE_FORMAT } from "@constants";
import { Close } from "@mui/icons-material";
import { Box, Button, ButtonGroup, MenuList, Typography, styled } from "@mui/material";
import { GridColType, GridFilterItem, useGridApiContext } from "@mui/x-data-grid-pro";
import { ContextMenuTypes, useAppDispatch } from "@storeRematch";
import { createContextMenuId } from "@storeRematch/ctxMenu";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { FilterPopover } from "./FilterPopover";
import { FilterValueForm } from "./FilterValueForm";
import { getOperatorsByType, isCustomFilter, isOperatorExcluded } from "./utils";

const Root = styled(Box)(({ theme: { palette, spacing, shape } }) => ({
  borderRadius: shape.borderRadius,
  backgroundColor: palette.customColors.light1,
  display: "flex",
  maxHeight: 36.5,
  flexShrink: 0,
  marginRight: spacing(2),
  marginBottom: spacing(1.5),
}));

const ColumnName = styled(Typography)(({ theme: { palette, spacing, shape } }) => ({
  backgroundColor: palette.customColors.mauve1,
  display: "flex",
  alignSelf: "stretch",
  textAlign: "center",
  padding: `${spacing(0.5)} ${spacing(1)}`,
  borderRadius: shape.borderRadius,
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
  alignItems: "center",
}));

let showRef = false;

export function FilterGroup() {
  const apiRef = useGridApiContext();
  const filterState = apiRef.current.state.filter;
  const dispatch = useAppDispatch();
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(showRef);

  const deleteItem = (item: GridFilterItem) => () => {
    apiRef.current.deleteFilterItem(item);
  };

  const deleteItemTrashed = (item: GridFilterItem) => () => {
    apiRef.current.upsertFilterItem({ ...item, value: "false" });
    dispatch.entityTable.setQuickFilter({ isInactive: false });
  };

  const handleOperatorChange =
    (item: GridFilterItem, filterItem: { type?: string; field?: string }) =>
    (operator: string) => {
      const { isArray } = isCustomFilter(filterItem);
      const resetValue =
        isArray && operator === "contains" && !Array.isArray(item.value)
          ? { value: [] }
          : item.operator === "contains" && typeof item.value !== "string"
          ? { value: "" }
          : { value: item.value };

      apiRef.current.upsertFilterItem({ ...item, operator, ...resetValue });

      if (isArray && operator === "contains") {
        dispatch.ctxMenu.autoOpenFilterFormValue(null);
      }
    };

  const handleValueChange = (item: GridFilterItem) => (value: string) => {
    apiRef.current.upsertFilterItem({
      ...item,
      value:
        item.field === "duration_ns" && (!value || Number(value) === 0) ? "0" : value,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const root = document.getElementById("filter-group-root");
    const items = document.querySelectorAll('[data-id="filter-items"]');

    if (!root) return;

    let count = 0;

    for (let i = items.length - 1; i >= 0; i--) {
      const item = items[i];

      // @ts-ignore
      item.style.display = "flex";

      if (showRef) continue;

      if (76 < root.scrollHeight) {
        // @ts-ignore
        item.style.display = "none";
        count += 1;
      }
    }

    if (root.scrollHeight < 76) {
      showRef = false;
      setShow(false);
    }
    setCount(count);
    root.style.maxHeight = `${showRef ? 250 : 76}px`;
  });

  return (
    <>
      {filterState.filterModel.items.map(filterItem => {
        const isInactive = filterItem.field === INACTIVE_FIELD_NAME;
        if (isInactive && filterItem.value === "false") return null;

        const item = apiRef.current.getColumn(filterItem.field);
        const filterTypeOp = createContextMenuId(
          ContextMenuTypes.FILTER_GROUP_OPERATOR,
          filterItem.id,
        );
        const filterTypeVa = createContextMenuId(
          ContextMenuTypes.FILTER_GROUP_VALUE,
          filterItem.id,
        );

        const readableValue =
          item.field === "duration_ns"
            ? formatDurationNsFull(Number(filterItem.value))
            : item.type === "date" || item.type === "dateTime"
            ? format(new Date(filterItem.value), READABLE_DATE_FORMAT)
            : Array.isArray(filterItem.value)
            ? filterItem.value.length === 1 && filterItem.value[0].name
              ? filterItem.value[0].name
              : `${filterItem.value.length} item${
                  filterItem.value.length === 1 ? "" : "s"
                }`
            : filterItem.value;

        return (
          <Root key={filterItem.id} data-id="filter-items" data-v={readableValue}>
            <ColumnName variant="button">{item.headerName}</ColumnName>
            <ButtonGroup disableRipple size="small">
              <FilterPopover
                disabled={isInactive}
                text={filterItem.operator?.replace(/([A-Z])/g, " $1").toLowerCase()}
                filterType={filterTypeOp}
                sx={{ borderRadius: 0 }}
              >
                <ChangeOperator
                  type={item.type}
                  onClick={handleOperatorChange(filterItem, {
                    field: item.field,
                    type: item.type,
                  })}
                  filterType={filterTypeOp}
                />
              </FilterPopover>
              <FilterPopover
                text={`${readableValue.slice(0, 25)}${
                  readableValue.length > 25 ? "..." : ""
                }`}
                tooltipText={
                  Array.isArray(filterItem.value) && filterItem.value.length > 1
                    ? filterItem.value.map(a => a.name).join(", ")
                    : undefined
                }
                filterType={filterTypeVa}
                autoOpen
                disabled={isInactive}
              >
                <FilterValueForm
                  filterType={filterTypeVa}
                  onSave={handleValueChange(filterItem)}
                  defaultValue={filterItem.value}
                  item={{ field: item.field, type: item.type }}
                  isContained={filterItem.operator === "contains"}
                />
              </FilterPopover>
              <Button
                onClick={
                  isInactive ? deleteItemTrashed(filterItem) : deleteItem(filterItem)
                }
              >
                <Close fontSize="small" />
              </Button>
            </ButtonGroup>
          </Root>
        );
      })}
      <Root sx={{ backgroundColor: "inherit" }}>
        <Button
          variant="text"
          color="primary"
          size="small"
          sx={{
            opacity: count || showRef ? 1 : 0,
            pointerEvents: count || showRef ? "unset" : "none",
          }}
          onClick={() => {
            showRef = !showRef;
            setShow(showRef);
          }}
        >
          {show ? "Hide" : `Show ${count} more`}
        </Button>
      </Root>
    </>
  );
}

interface ChangeOperatorProps {
  type?: GridColType;
  onClick: (operatorValue: string) => void;
  filterType: ContextMenuTypes;
}

function ChangeOperator({ type, onClick, filterType }: ChangeOperatorProps) {
  const dispatch = useAppDispatch();
  const operators = getOperatorsByType(type);

  const handleClick = (value: string) => () => {
    dispatch.ctxMenu.close(filterType);
    onClick(value);
  };

  return (
    <MenuList>
      {operators?.map(config => {
        if (isOperatorExcluded(config.value)) return null;

        return (
          <CtxMenuItem
            key={config.value}
            label={config.value.replace(/([A-Z])/g, " $1")}
            onClick={handleClick(config.value)}
            sx={{ textTransform: "lowercase" }}
          />
        );
      })}
    </MenuList>
  );
}
