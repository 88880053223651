import { nanoid } from "@reduxjs/toolkit";
import { createModel } from "@rematch/core";
import { SnackbarKey } from "notistack";
import type { RootModel, SnackbarNotification } from "./index";

interface InitialState {
  data: SnackbarNotification[];
  dataById: Map<SnackbarNotification["options"]["key"], SnackbarNotification>;
}

const initialState: InitialState = {
  data: [],
  dataById: new Map(),
};

export const notifier = createModel<RootModel>()({
  state: initialState,
  reducers: {
    enqueueSnackbar: (state, payload: SnackbarNotification) => {
      const next = {
        ...payload,
        options: payload.options.key
          ? payload.options
          : {
              ...payload.options,
              key: nanoid(),
            },
      };

      if (!state.dataById.has(next.options.key)) {
        state.data.push(next);
        state.dataById.set(next.options.key, next);
      }
    },
    removeSnackbar: (state, payload: SnackbarKey) => {
      if (state.dataById.has(payload)) {
        state.dataById.delete(payload);
        state.data = Array.from(state.dataById.values());
      }
    },
  },
});
