import { GenerateHeatmap } from "@api";
import { AOI_HEATMAP_TYPES, AoiStatsWithData } from "@storeRematch/types";
import { customColors } from "@styles/radix";
import { formatReadableAoiStatMs } from "./formatAoiStats";

// Whole logic copied form the original code

function gaussBlur(scl: number[], tcl: Float32Array, w: number, h: number, r: number) {
  const bxs = boxesForGauss(r, 3);
  boxBlur_4(scl, tcl, w, h, (bxs[0] - 1) / 2);
  boxBlur_4(tcl, scl, w, h, (bxs[1] - 1) / 2);
  boxBlur_4(scl, tcl, w, h, (bxs[2] - 1) / 2);
}

function boxesForGauss(sigma: number, n: number) {
  // standard deviation, number of boxes
  let wIdeal = Math.sqrt((12 * sigma * sigma) / n + 1); // Ideal averaging filter width
  let wl = Math.floor(wIdeal);
  if (wl % 2 === 0) wl--;
  let wu = wl + 2;

  let mIdeal = (12 * sigma * sigma - n * wl * wl - 4 * n * wl - 3 * n) / (-4 * wl - 4);
  let m = Math.round(mIdeal);

  let sizes = [];
  for (let i = 0; i < n; i++) sizes.push(i < m ? wl : wu);
  return sizes;
}

function boxBlur_4(
  scl: number[] | Float32Array,
  tcl: Float32Array | number[],
  w: number,
  h: number,
  r: number,
) {
  for (let i = 0; i < scl.length; i++) tcl[i] = scl[i];
  boxBlurH_4(tcl, scl, w, h, r);
  boxBlurT_4(scl, tcl, w, h, r);
}

function boxBlurH_4(
  scl: number[] | Float32Array,
  tcl: Float32Array | number[],
  w: number,
  h: number,
  r: number,
) {
  let iarr = 1 / (r + r + 1);
  for (let i = 0; i < h; i++) {
    let ti = i * w,
      li = ti,
      ri = ti + r;
    let fv = scl[ti],
      lv = scl[ti + w - 1],
      val = (r + 1) * fv;
    for (let j = 0; j < r; j++) val += scl[ti + j];
    for (let j = 0; j <= r; j++) {
      val += scl[ri++] - fv;
      tcl[ti++] = val * iarr;
    }
    for (let j = r + 1; j < w - r; j++) {
      val += scl[ri++] - scl[li++];
      tcl[ti++] = val * iarr;
    }
    for (let j = w - r; j < w; j++) {
      val += lv - scl[li++];
      tcl[ti++] = val * iarr;
    }
  }
}

function boxBlurT_4(
  scl: number[] | Float32Array,
  tcl: Float32Array | number[],
  w: number,
  h: number,
  r: number,
) {
  let iarr = 1 / (r + r + 1);
  for (let i = 0; i < w; i++) {
    let ti = i,
      li = ti,
      ri = ti + r * w;
    let fv = scl[ti],
      lv = scl[ti + w * (h - 1)],
      val = (r + 1) * fv;
    for (let j = 0; j < r; j++) val += scl[ti + j * w];
    for (let j = 0; j <= r; j++) {
      val += scl[ri] - fv;
      tcl[ti] = val * iarr;
      ri += w;
      ti += w;
    }
    for (let j = r + 1; j < h - r; j++) {
      val += scl[ri] - scl[li];
      tcl[ti] = val * iarr;
      li += w;
      ri += w;
      ti += w;
    }
    for (let j = h - r; j < h; j++) {
      val += lv - scl[li];
      tcl[ti] = val * iarr;
      li += w;
      ti += w;
    }
  }
}

function blur(src: number[], width: number, height: number, radius: number) {
  let out = new Float32Array(src.length);

  gaussBlur(src, out, width, height, radius);

  let histogram_max = 0;
  for (let i = 0; i < out.length; i++) {
    histogram_max = out[i] > histogram_max ? out[i] : histogram_max;
  }

  out = out.map(x => x * (255.0 / histogram_max) || 0);

  return out;
}

function getColorMap(name: GenerateHeatmap["colormap"]) {
  const colormap_turbo = [
    [48, 18, 59],
    [50, 21, 67],
    [51, 24, 74],
    [52, 27, 81],
    [53, 30, 88],
    [54, 33, 95],
    [55, 36, 102],
    [56, 39, 109],
    [57, 42, 115],
    [58, 45, 121],
    [59, 47, 128],
    [60, 50, 134],
    [61, 53, 139],
    [62, 56, 145],
    [63, 59, 151],
    [63, 62, 156],
    [64, 64, 162],
    [65, 67, 167],
    [65, 70, 172],
    [66, 73, 177],
    [66, 75, 181],
    [67, 78, 186],
    [68, 81, 191],
    [68, 84, 195],
    [68, 86, 199],
    [69, 89, 203],
    [69, 92, 207],
    [69, 94, 211],
    [70, 97, 214],
    [70, 100, 218],
    [70, 102, 221],
    [70, 105, 224],
    [70, 107, 227],
    [71, 110, 230],
    [71, 113, 233],
    [71, 115, 235],
    [71, 118, 238],
    [71, 120, 240],
    [71, 123, 242],
    [70, 125, 244],
    [70, 128, 246],
    [70, 130, 248],
    [70, 133, 250],
    [70, 135, 251],
    [69, 138, 252],
    [69, 140, 253],
    [68, 143, 254],
    [67, 145, 254],
    [66, 148, 255],
    [65, 150, 255],
    [64, 153, 255],
    [62, 155, 254],
    [61, 158, 254],
    [59, 160, 253],
    [58, 163, 252],
    [56, 165, 251],
    [55, 168, 250],
    [53, 171, 248],
    [51, 173, 247],
    [49, 175, 245],
    [47, 178, 244],
    [46, 180, 242],
    [44, 183, 240],
    [42, 185, 238],
    [40, 188, 235],
    [39, 190, 233],
    [37, 192, 231],
    [35, 195, 228],
    [34, 197, 226],
    [32, 199, 223],
    [31, 201, 221],
    [30, 203, 218],
    [28, 205, 216],
    [27, 208, 213],
    [26, 210, 210],
    [26, 212, 208],
    [25, 213, 205],
    [24, 215, 202],
    [24, 217, 200],
    [24, 219, 197],
    [24, 221, 194],
    [24, 222, 192],
    [24, 224, 189],
    [25, 226, 187],
    [25, 227, 185],
    [26, 228, 182],
    [28, 230, 180],
    [29, 231, 178],
    [31, 233, 175],
    [32, 234, 172],
    [34, 235, 170],
    [37, 236, 167],
    [39, 238, 164],
    [42, 239, 161],
    [44, 240, 158],
    [47, 241, 155],
    [50, 242, 152],
    [53, 243, 148],
    [56, 244, 145],
    [60, 245, 142],
    [63, 246, 138],
    [67, 247, 135],
    [70, 248, 132],
    [74, 248, 128],
    [78, 249, 125],
    [82, 250, 122],
    [85, 250, 118],
    [89, 251, 115],
    [93, 252, 111],
    [97, 252, 108],
    [101, 253, 105],
    [105, 253, 102],
    [109, 254, 98],
    [113, 254, 95],
    [117, 254, 92],
    [121, 254, 89],
    [125, 255, 86],
    [128, 255, 83],
    [132, 255, 81],
    [136, 255, 78],
    [139, 255, 75],
    [143, 255, 73],
    [146, 255, 71],
    [150, 254, 68],
    [153, 254, 66],
    [156, 254, 64],
    [159, 253, 63],
    [161, 253, 61],
    [164, 252, 60],
    [167, 252, 58],
    [169, 251, 57],
    [172, 251, 56],
    [175, 250, 55],
    [177, 249, 54],
    [180, 248, 54],
    [183, 247, 53],
    [185, 246, 53],
    [188, 245, 52],
    [190, 244, 52],
    [193, 243, 52],
    [195, 241, 52],
    [198, 240, 52],
    [200, 239, 52],
    [203, 237, 52],
    [205, 236, 52],
    [208, 234, 52],
    [210, 233, 53],
    [212, 231, 53],
    [215, 229, 53],
    [217, 228, 54],
    [219, 226, 54],
    [221, 224, 55],
    [223, 223, 55],
    [225, 221, 55],
    [227, 219, 56],
    [229, 217, 56],
    [231, 215, 57],
    [233, 213, 57],
    [235, 211, 57],
    [236, 209, 58],
    [238, 207, 58],
    [239, 205, 58],
    [241, 203, 58],
    [242, 201, 58],
    [244, 199, 58],
    [245, 197, 58],
    [246, 195, 58],
    [247, 193, 58],
    [248, 190, 57],
    [249, 188, 57],
    [250, 186, 57],
    [251, 184, 56],
    [251, 182, 55],
    [252, 179, 54],
    [252, 177, 54],
    [253, 174, 53],
    [253, 172, 52],
    [254, 169, 51],
    [254, 167, 50],
    [254, 164, 49],
    [254, 161, 48],
    [254, 158, 47],
    [254, 155, 45],
    [254, 153, 44],
    [254, 150, 43],
    [254, 147, 42],
    [254, 144, 41],
    [253, 141, 39],
    [253, 138, 38],
    [252, 135, 37],
    [252, 132, 35],
    [251, 129, 34],
    [251, 126, 33],
    [250, 123, 31],
    [249, 120, 30],
    [249, 117, 29],
    [248, 114, 28],
    [247, 111, 26],
    [246, 108, 25],
    [245, 105, 24],
    [244, 102, 23],
    [243, 99, 21],
    [242, 96, 20],
    [241, 93, 19],
    [240, 91, 18],
    [239, 88, 17],
    [237, 85, 16],
    [236, 83, 15],
    [235, 80, 14],
    [234, 78, 13],
    [232, 75, 12],
    [231, 73, 12],
    [229, 71, 11],
    [228, 69, 10],
    [226, 67, 10],
    [225, 65, 9],
    [223, 63, 8],
    [221, 61, 8],
    [220, 59, 7],
    [218, 57, 7],
    [216, 55, 6],
    [214, 53, 6],
    [212, 51, 5],
    [210, 49, 5],
    [208, 47, 5],
    [206, 45, 4],
    [204, 43, 4],
    [202, 42, 4],
    [200, 40, 3],
    [197, 38, 3],
    [195, 37, 3],
    [193, 35, 2],
    [190, 33, 2],
    [188, 32, 2],
    [185, 30, 2],
    [183, 29, 2],
    [180, 27, 1],
    [178, 26, 1],
    [175, 24, 1],
    [172, 23, 1],
    [169, 22, 1],
    [167, 20, 1],
    [164, 19, 1],
    [161, 18, 1],
    [158, 16, 1],
    [155, 15, 1],
    [152, 14, 1],
    [149, 13, 1],
    [146, 11, 1],
    [142, 10, 1],
    [139, 9, 2],
    [136, 8, 2],
    [133, 7, 2],
    [129, 6, 2],
    [126, 5, 2],
    [122, 4, 3],
  ];
  const colormap_magma = [
    [0, 0, 4],
    [1, 0, 5],
    [1, 1, 6],
    [1, 1, 8],
    [2, 1, 9],
    [2, 2, 11],
    [2, 2, 13],
    [3, 3, 15],
    [3, 3, 18],
    [4, 4, 20],
    [5, 4, 22],
    [6, 5, 24],
    [6, 5, 26],
    [7, 6, 28],
    [8, 7, 30],
    [9, 7, 32],
    [10, 8, 34],
    [11, 9, 36],
    [12, 9, 38],
    [13, 10, 41],
    [14, 11, 43],
    [16, 11, 45],
    [17, 12, 47],
    [18, 13, 49],
    [19, 13, 52],
    [20, 14, 54],
    [21, 14, 56],
    [22, 15, 59],
    [24, 15, 61],
    [25, 16, 63],
    [26, 16, 66],
    [28, 16, 68],
    [29, 17, 71],
    [30, 17, 73],
    [32, 17, 75],
    [33, 17, 78],
    [34, 17, 80],
    [36, 18, 83],
    [37, 18, 85],
    [39, 18, 88],
    [41, 17, 90],
    [42, 17, 92],
    [44, 17, 95],
    [45, 17, 97],
    [47, 17, 99],
    [49, 17, 101],
    [51, 16, 103],
    [52, 16, 105],
    [54, 16, 107],
    [56, 16, 108],
    [57, 15, 110],
    [59, 15, 112],
    [61, 15, 113],
    [63, 15, 114],
    [64, 15, 116],
    [66, 15, 117],
    [68, 15, 118],
    [69, 16, 119],
    [71, 16, 120],
    [73, 16, 120],
    [74, 16, 121],
    [76, 17, 122],
    [78, 17, 123],
    [79, 18, 123],
    [81, 18, 124],
    [82, 19, 124],
    [84, 19, 125],
    [86, 20, 125],
    [87, 21, 126],
    [89, 21, 126],
    [90, 22, 126],
    [92, 22, 127],
    [93, 23, 127],
    [95, 24, 127],
    [96, 24, 128],
    [98, 25, 128],
    [100, 26, 128],
    [101, 26, 128],
    [103, 27, 128],
    [104, 28, 129],
    [106, 28, 129],
    [107, 29, 129],
    [109, 29, 129],
    [110, 30, 129],
    [112, 31, 129],
    [114, 31, 129],
    [115, 32, 129],
    [117, 33, 129],
    [118, 33, 129],
    [120, 34, 129],
    [121, 34, 130],
    [123, 35, 130],
    [124, 35, 130],
    [126, 36, 130],
    [128, 37, 130],
    [129, 37, 129],
    [131, 38, 129],
    [132, 38, 129],
    [134, 39, 129],
    [136, 39, 129],
    [137, 40, 129],
    [139, 41, 129],
    [140, 41, 129],
    [142, 42, 129],
    [144, 42, 129],
    [145, 43, 129],
    [147, 43, 128],
    [148, 44, 128],
    [150, 44, 128],
    [152, 45, 128],
    [153, 45, 128],
    [155, 46, 127],
    [156, 46, 127],
    [158, 47, 127],
    [160, 47, 127],
    [161, 48, 126],
    [163, 48, 126],
    [165, 49, 126],
    [166, 49, 125],
    [168, 50, 125],
    [170, 51, 125],
    [171, 51, 124],
    [173, 52, 124],
    [174, 52, 123],
    [176, 53, 123],
    [178, 53, 123],
    [179, 54, 122],
    [181, 54, 122],
    [183, 55, 121],
    [184, 55, 121],
    [186, 56, 120],
    [188, 57, 120],
    [189, 57, 119],
    [191, 58, 119],
    [192, 58, 118],
    [194, 59, 117],
    [196, 60, 117],
    [197, 60, 116],
    [199, 61, 115],
    [200, 62, 115],
    [202, 62, 114],
    [204, 63, 113],
    [205, 64, 113],
    [207, 64, 112],
    [208, 65, 111],
    [210, 66, 111],
    [211, 67, 110],
    [213, 68, 109],
    [214, 69, 108],
    [216, 69, 108],
    [217, 70, 107],
    [219, 71, 106],
    [220, 72, 105],
    [222, 73, 104],
    [223, 74, 104],
    [224, 76, 103],
    [226, 77, 102],
    [227, 78, 101],
    [228, 79, 100],
    [229, 80, 100],
    [231, 82, 99],
    [232, 83, 98],
    [233, 84, 98],
    [234, 86, 97],
    [235, 87, 96],
    [236, 88, 96],
    [237, 90, 95],
    [238, 91, 94],
    [239, 93, 94],
    [240, 95, 94],
    [241, 96, 93],
    [242, 98, 93],
    [242, 100, 92],
    [243, 101, 92],
    [244, 103, 92],
    [244, 105, 92],
    [245, 107, 92],
    [246, 108, 92],
    [246, 110, 92],
    [247, 112, 92],
    [247, 114, 92],
    [248, 116, 92],
    [248, 118, 92],
    [249, 120, 93],
    [249, 121, 93],
    [249, 123, 93],
    [250, 125, 94],
    [250, 127, 94],
    [250, 129, 95],
    [251, 131, 95],
    [251, 133, 96],
    [251, 135, 97],
    [252, 137, 97],
    [252, 138, 98],
    [252, 140, 99],
    [252, 142, 100],
    [252, 144, 101],
    [253, 146, 102],
    [253, 148, 103],
    [253, 150, 104],
    [253, 152, 105],
    [253, 154, 106],
    [253, 155, 107],
    [254, 157, 108],
    [254, 159, 109],
    [254, 161, 110],
    [254, 163, 111],
    [254, 165, 113],
    [254, 167, 114],
    [254, 169, 115],
    [254, 170, 116],
    [254, 172, 118],
    [254, 174, 119],
    [254, 176, 120],
    [254, 178, 122],
    [254, 180, 123],
    [254, 182, 124],
    [254, 183, 126],
    [254, 185, 127],
    [254, 187, 129],
    [254, 189, 130],
    [254, 191, 132],
    [254, 193, 133],
    [254, 194, 135],
    [254, 196, 136],
    [254, 198, 138],
    [254, 200, 140],
    [254, 202, 141],
    [254, 204, 143],
    [254, 205, 144],
    [254, 207, 146],
    [254, 209, 148],
    [254, 211, 149],
    [254, 213, 151],
    [254, 215, 153],
    [254, 216, 154],
    [253, 218, 156],
    [253, 220, 158],
    [253, 222, 160],
    [253, 224, 161],
    [253, 226, 163],
    [253, 227, 165],
    [253, 229, 167],
    [253, 231, 169],
    [253, 233, 170],
    [253, 235, 172],
    [252, 236, 174],
    [252, 238, 176],
    [252, 240, 178],
    [252, 242, 180],
    [252, 244, 182],
    [252, 246, 184],
    [252, 247, 185],
    [252, 249, 187],
    [252, 251, 189],
    [252, 253, 191],
  ];
  const colormap_viridis = [
    [68, 1, 84],
    [68, 2, 86],
    [69, 4, 87],
    [69, 5, 89],
    [70, 7, 90],
    [70, 8, 92],
    [70, 10, 93],
    [70, 11, 94],
    [71, 13, 96],
    [71, 14, 97],
    [71, 16, 99],
    [71, 17, 100],
    [71, 19, 101],
    [72, 20, 103],
    [72, 22, 104],
    [72, 23, 105],
    [72, 24, 106],
    [72, 26, 108],
    [72, 27, 109],
    [72, 28, 110],
    [72, 29, 111],
    [72, 31, 112],
    [72, 32, 113],
    [72, 33, 115],
    [72, 35, 116],
    [72, 36, 117],
    [72, 37, 118],
    [72, 38, 119],
    [72, 40, 120],
    [72, 41, 121],
    [71, 42, 122],
    [71, 44, 122],
    [71, 45, 123],
    [71, 46, 124],
    [71, 47, 125],
    [70, 48, 126],
    [70, 50, 126],
    [70, 51, 127],
    [70, 52, 128],
    [69, 53, 129],
    [69, 55, 129],
    [69, 56, 130],
    [68, 57, 131],
    [68, 58, 131],
    [68, 59, 132],
    [67, 61, 132],
    [67, 62, 133],
    [66, 63, 133],
    [66, 64, 134],
    [66, 65, 134],
    [65, 66, 135],
    [65, 68, 135],
    [64, 69, 136],
    [64, 70, 136],
    [63, 71, 136],
    [63, 72, 137],
    [62, 73, 137],
    [62, 74, 137],
    [62, 76, 138],
    [61, 77, 138],
    [61, 78, 138],
    [60, 79, 138],
    [60, 80, 139],
    [59, 81, 139],
    [59, 82, 139],
    [58, 83, 139],
    [58, 84, 140],
    [57, 85, 140],
    [57, 86, 140],
    [56, 88, 140],
    [56, 89, 140],
    [55, 90, 140],
    [55, 91, 141],
    [54, 92, 141],
    [54, 93, 141],
    [53, 94, 141],
    [53, 95, 141],
    [52, 96, 141],
    [52, 97, 141],
    [51, 98, 141],
    [51, 99, 141],
    [50, 100, 142],
    [50, 101, 142],
    [49, 102, 142],
    [49, 103, 142],
    [49, 104, 142],
    [48, 105, 142],
    [48, 106, 142],
    [47, 107, 142],
    [47, 108, 142],
    [46, 109, 142],
    [46, 110, 142],
    [46, 111, 142],
    [45, 112, 142],
    [45, 113, 142],
    [44, 113, 142],
    [44, 114, 142],
    [44, 115, 142],
    [43, 116, 142],
    [43, 117, 142],
    [42, 118, 142],
    [42, 119, 142],
    [42, 120, 142],
    [41, 121, 142],
    [41, 122, 142],
    [41, 123, 142],
    [40, 124, 142],
    [40, 125, 142],
    [39, 126, 142],
    [39, 127, 142],
    [39, 128, 142],
    [38, 129, 142],
    [38, 130, 142],
    [38, 130, 142],
    [37, 131, 142],
    [37, 132, 142],
    [37, 133, 142],
    [36, 134, 142],
    [36, 135, 142],
    [35, 136, 142],
    [35, 137, 142],
    [35, 138, 141],
    [34, 139, 141],
    [34, 140, 141],
    [34, 141, 141],
    [33, 142, 141],
    [33, 143, 141],
    [33, 144, 141],
    [33, 145, 140],
    [32, 146, 140],
    [32, 146, 140],
    [32, 147, 140],
    [31, 148, 140],
    [31, 149, 139],
    [31, 150, 139],
    [31, 151, 139],
    [31, 152, 139],
    [31, 153, 138],
    [31, 154, 138],
    [30, 155, 138],
    [30, 156, 137],
    [30, 157, 137],
    [31, 158, 137],
    [31, 159, 136],
    [31, 160, 136],
    [31, 161, 136],
    [31, 161, 135],
    [31, 162, 135],
    [32, 163, 134],
    [32, 164, 134],
    [33, 165, 133],
    [33, 166, 133],
    [34, 167, 133],
    [34, 168, 132],
    [35, 169, 131],
    [36, 170, 131],
    [37, 171, 130],
    [37, 172, 130],
    [38, 173, 129],
    [39, 173, 129],
    [40, 174, 128],
    [41, 175, 127],
    [42, 176, 127],
    [44, 177, 126],
    [45, 178, 125],
    [46, 179, 124],
    [47, 180, 124],
    [49, 181, 123],
    [50, 182, 122],
    [52, 182, 121],
    [53, 183, 121],
    [55, 184, 120],
    [56, 185, 119],
    [58, 186, 118],
    [59, 187, 117],
    [61, 188, 116],
    [63, 188, 115],
    [64, 189, 114],
    [66, 190, 113],
    [68, 191, 112],
    [70, 192, 111],
    [72, 193, 110],
    [74, 193, 109],
    [76, 194, 108],
    [78, 195, 107],
    [80, 196, 106],
    [82, 197, 105],
    [84, 197, 104],
    [86, 198, 103],
    [88, 199, 101],
    [90, 200, 100],
    [92, 200, 99],
    [94, 201, 98],
    [96, 202, 96],
    [99, 203, 95],
    [101, 203, 94],
    [103, 204, 92],
    [105, 205, 91],
    [108, 205, 90],
    [110, 206, 88],
    [112, 207, 87],
    [115, 208, 86],
    [117, 208, 84],
    [119, 209, 83],
    [122, 209, 81],
    [124, 210, 80],
    [127, 211, 78],
    [129, 211, 77],
    [132, 212, 75],
    [134, 213, 73],
    [137, 213, 72],
    [139, 214, 70],
    [142, 214, 69],
    [144, 215, 67],
    [147, 215, 65],
    [149, 216, 64],
    [152, 216, 62],
    [155, 217, 60],
    [157, 217, 59],
    [160, 218, 57],
    [162, 218, 55],
    [165, 219, 54],
    [168, 219, 52],
    [170, 220, 50],
    [173, 220, 48],
    [176, 221, 47],
    [178, 221, 45],
    [181, 222, 43],
    [184, 222, 41],
    [186, 222, 40],
    [189, 223, 38],
    [192, 223, 37],
    [194, 223, 35],
    [197, 224, 33],
    [200, 224, 32],
    [202, 225, 31],
    [205, 225, 29],
    [208, 225, 28],
    [210, 226, 27],
    [213, 226, 26],
    [216, 226, 25],
    [218, 227, 25],
    [221, 227, 24],
    [223, 227, 24],
    [226, 228, 24],
    [229, 228, 25],
    [231, 228, 25],
    [234, 229, 26],
    [236, 229, 27],
    [239, 229, 28],
    [241, 229, 29],
    [244, 230, 30],
    [246, 230, 32],
    [248, 230, 33],
    [251, 231, 35],
    [253, 231, 37],
  ];
  const colormap_jet = [
    [0, 0, 128],
    [0, 0, 132],
    [0, 0, 136],
    [0, 0, 140],
    [0, 0, 144],
    [0, 0, 148],
    [0, 0, 152],
    [0, 0, 156],
    [0, 0, 160],
    [0, 0, 164],
    [0, 0, 168],
    [0, 0, 172],
    [0, 0, 176],
    [0, 0, 180],
    [0, 0, 184],
    [0, 0, 188],
    [0, 0, 192],
    [0, 0, 196],
    [0, 0, 200],
    [0, 0, 204],
    [0, 0, 208],
    [0, 0, 212],
    [0, 0, 216],
    [0, 0, 220],
    [0, 0, 224],
    [0, 0, 228],
    [0, 0, 232],
    [0, 0, 236],
    [0, 0, 240],
    [0, 0, 244],
    [0, 0, 248],
    [0, 0, 252],
    [0, 0, 255],
    [0, 4, 255],
    [0, 8, 255],
    [0, 12, 255],
    [0, 16, 255],
    [0, 20, 255],
    [0, 24, 255],
    [0, 28, 255],
    [0, 32, 255],
    [0, 36, 255],
    [0, 40, 255],
    [0, 44, 255],
    [0, 48, 255],
    [0, 52, 255],
    [0, 56, 255],
    [0, 60, 255],
    [0, 64, 255],
    [0, 68, 255],
    [0, 72, 255],
    [0, 76, 255],
    [0, 80, 255],
    [0, 84, 255],
    [0, 88, 255],
    [0, 92, 255],
    [0, 96, 255],
    [0, 100, 255],
    [0, 104, 255],
    [0, 108, 255],
    [0, 112, 255],
    [0, 116, 255],
    [0, 120, 255],
    [0, 124, 255],
    [0, 128, 255],
    [0, 132, 255],
    [0, 136, 255],
    [0, 140, 255],
    [0, 144, 255],
    [0, 148, 255],
    [0, 152, 255],
    [0, 156, 255],
    [0, 160, 255],
    [0, 164, 255],
    [0, 168, 255],
    [0, 172, 255],
    [0, 176, 255],
    [0, 180, 255],
    [0, 184, 255],
    [0, 188, 255],
    [0, 192, 255],
    [0, 196, 255],
    [0, 200, 255],
    [0, 204, 255],
    [0, 208, 255],
    [0, 212, 255],
    [0, 216, 255],
    [0, 220, 255],
    [0, 224, 255],
    [0, 228, 255],
    [0, 232, 255],
    [0, 236, 255],
    [0, 240, 255],
    [0, 244, 255],
    [0, 248, 255],
    [0, 252, 255],
    [2, 255, 254],
    [6, 255, 250],
    [10, 255, 246],
    [14, 255, 242],
    [18, 255, 238],
    [22, 255, 234],
    [26, 255, 230],
    [30, 255, 226],
    [34, 255, 222],
    [38, 255, 218],
    [42, 255, 214],
    [46, 255, 210],
    [50, 255, 206],
    [54, 255, 202],
    [58, 255, 198],
    [62, 255, 194],
    [66, 255, 190],
    [70, 255, 186],
    [74, 255, 182],
    [78, 255, 178],
    [82, 255, 174],
    [86, 255, 170],
    [90, 255, 166],
    [94, 255, 162],
    [98, 255, 158],
    [102, 255, 154],
    [106, 255, 150],
    [110, 255, 146],
    [114, 255, 142],
    [118, 255, 138],
    [122, 255, 134],
    [126, 255, 130],
    [130, 255, 126],
    [134, 255, 122],
    [138, 255, 118],
    [142, 255, 114],
    [146, 255, 110],
    [150, 255, 106],
    [154, 255, 102],
    [158, 255, 98],
    [162, 255, 94],
    [166, 255, 90],
    [170, 255, 86],
    [174, 255, 82],
    [178, 255, 78],
    [182, 255, 74],
    [186, 255, 70],
    [190, 255, 66],
    [194, 255, 62],
    [198, 255, 58],
    [202, 255, 54],
    [206, 255, 50],
    [210, 255, 46],
    [214, 255, 42],
    [218, 255, 38],
    [222, 255, 34],
    [226, 255, 30],
    [230, 255, 26],
    [234, 255, 22],
    [238, 255, 18],
    [242, 255, 14],
    [246, 255, 10],
    [250, 255, 6],
    [254, 255, 1],
    [255, 252, 0],
    [255, 248, 0],
    [255, 244, 0],
    [255, 240, 0],
    [255, 236, 0],
    [255, 232, 0],
    [255, 228, 0],
    [255, 224, 0],
    [255, 220, 0],
    [255, 216, 0],
    [255, 212, 0],
    [255, 208, 0],
    [255, 204, 0],
    [255, 200, 0],
    [255, 196, 0],
    [255, 192, 0],
    [255, 188, 0],
    [255, 184, 0],
    [255, 180, 0],
    [255, 176, 0],
    [255, 172, 0],
    [255, 168, 0],
    [255, 164, 0],
    [255, 160, 0],
    [255, 156, 0],
    [255, 152, 0],
    [255, 148, 0],
    [255, 144, 0],
    [255, 140, 0],
    [255, 136, 0],
    [255, 132, 0],
    [255, 128, 0],
    [255, 124, 0],
    [255, 120, 0],
    [255, 116, 0],
    [255, 112, 0],
    [255, 108, 0],
    [255, 104, 0],
    [255, 100, 0],
    [255, 96, 0],
    [255, 92, 0],
    [255, 88, 0],
    [255, 84, 0],
    [255, 80, 0],
    [255, 76, 0],
    [255, 72, 0],
    [255, 68, 0],
    [255, 64, 0],
    [255, 60, 0],
    [255, 56, 0],
    [255, 52, 0],
    [255, 48, 0],
    [255, 44, 0],
    [255, 40, 0],
    [255, 36, 0],
    [255, 32, 0],
    [255, 28, 0],
    [255, 24, 0],
    [255, 20, 0],
    [255, 16, 0],
    [255, 12, 0],
    [255, 8, 0],
    [255, 4, 0],
    [255, 0, 0],
    [252, 0, 0],
    [248, 0, 0],
    [244, 0, 0],
    [240, 0, 0],
    [236, 0, 0],
    [232, 0, 0],
    [228, 0, 0],
    [224, 0, 0],
    [220, 0, 0],
    [216, 0, 0],
    [212, 0, 0],
    [208, 0, 0],
    [204, 0, 0],
    [200, 0, 0],
    [196, 0, 0],
    [192, 0, 0],
    [188, 0, 0],
    [184, 0, 0],
    [180, 0, 0],
    [176, 0, 0],
    [172, 0, 0],
    [168, 0, 0],
    [164, 0, 0],
    [160, 0, 0],
    [156, 0, 0],
    [152, 0, 0],
    [148, 0, 0],
    [144, 0, 0],
    [140, 0, 0],
    [136, 0, 0],
    [132, 0, 0],
    [128, 0, 0],
  ];
  const colormap_traffic_light = [
    [0, 255, 0],
    [1, 255, 0],
    [2, 255, 0],
    [3, 255, 0],
    [5, 255, 0],
    [6, 255, 0],
    [7, 255, 0],
    [9, 255, 0],
    [10, 255, 0],
    [11, 255, 0],
    [13, 255, 0],
    [14, 255, 0],
    [15, 255, 0],
    [17, 255, 0],
    [18, 255, 0],
    [19, 255, 0],
    [21, 255, 0],
    [22, 255, 0],
    [23, 255, 0],
    [25, 255, 0],
    [26, 255, 0],
    [27, 255, 0],
    [28, 255, 0],
    [30, 255, 0],
    [31, 255, 0],
    [32, 255, 0],
    [34, 255, 0],
    [35, 255, 0],
    [36, 255, 0],
    [38, 255, 0],
    [39, 255, 0],
    [40, 255, 0],
    [42, 255, 0],
    [43, 255, 0],
    [44, 255, 0],
    [46, 255, 0],
    [47, 255, 0],
    [48, 255, 0],
    [50, 255, 0],
    [51, 255, 0],
    [52, 255, 0],
    [54, 255, 0],
    [55, 255, 0],
    [56, 255, 0],
    [57, 255, 0],
    [59, 255, 0],
    [60, 255, 0],
    [61, 255, 0],
    [63, 255, 0],
    [64, 255, 0],
    [65, 255, 0],
    [67, 255, 0],
    [68, 255, 0],
    [69, 255, 0],
    [71, 255, 0],
    [72, 255, 0],
    [73, 255, 0],
    [75, 255, 0],
    [76, 255, 0],
    [77, 255, 0],
    [79, 255, 0],
    [80, 255, 0],
    [81, 255, 0],
    [83, 255, 0],
    [84, 255, 0],
    [85, 255, 0],
    [86, 255, 0],
    [88, 255, 0],
    [89, 255, 0],
    [90, 255, 0],
    [92, 255, 0],
    [93, 255, 0],
    [94, 255, 0],
    [96, 255, 0],
    [97, 255, 0],
    [98, 255, 0],
    [100, 255, 0],
    [101, 255, 0],
    [102, 255, 0],
    [104, 255, 0],
    [105, 255, 0],
    [106, 255, 0],
    [108, 255, 0],
    [109, 255, 0],
    [110, 255, 0],
    [112, 255, 0],
    [113, 255, 0],
    [114, 255, 0],
    [115, 255, 0],
    [117, 255, 0],
    [118, 255, 0],
    [119, 255, 0],
    [121, 255, 0],
    [122, 255, 0],
    [123, 255, 0],
    [125, 255, 0],
    [126, 255, 0],
    [127, 255, 0],
    [129, 255, 0],
    [130, 255, 0],
    [131, 255, 0],
    [133, 255, 0],
    [134, 255, 0],
    [135, 255, 0],
    [137, 255, 0],
    [138, 255, 0],
    [139, 255, 0],
    [141, 255, 0],
    [142, 255, 0],
    [143, 255, 0],
    [144, 255, 0],
    [146, 255, 0],
    [147, 255, 0],
    [148, 255, 0],
    [150, 255, 0],
    [151, 255, 0],
    [152, 255, 0],
    [154, 255, 0],
    [155, 255, 0],
    [156, 255, 0],
    [158, 255, 0],
    [159, 255, 0],
    [160, 255, 0],
    [162, 255, 0],
    [163, 255, 0],
    [164, 255, 0],
    [166, 255, 0],
    [167, 255, 0],
    [168, 255, 0],
    [169, 255, 0],
    [171, 255, 0],
    [172, 255, 0],
    [173, 255, 0],
    [175, 255, 0],
    [176, 255, 0],
    [177, 255, 0],
    [179, 255, 0],
    [180, 255, 0],
    [181, 255, 0],
    [183, 255, 0],
    [184, 255, 0],
    [185, 255, 0],
    [187, 255, 0],
    [188, 255, 0],
    [189, 255, 0],
    [191, 255, 0],
    [192, 255, 0],
    [193, 255, 0],
    [195, 255, 0],
    [196, 255, 0],
    [197, 255, 0],
    [198, 255, 0],
    [200, 255, 0],
    [201, 255, 0],
    [202, 255, 0],
    [204, 255, 0],
    [205, 255, 0],
    [206, 255, 0],
    [208, 255, 0],
    [209, 255, 0],
    [210, 255, 0],
    [212, 255, 0],
    [213, 255, 0],
    [214, 255, 0],
    [216, 255, 0],
    [217, 255, 0],
    [218, 255, 0],
    [220, 255, 0],
    [221, 255, 0],
    [222, 255, 0],
    [224, 255, 0],
    [225, 255, 0],
    [226, 255, 0],
    [227, 255, 0],
    [229, 255, 0],
    [230, 255, 0],
    [231, 255, 0],
    [233, 255, 0],
    [234, 255, 0],
    [235, 255, 0],
    [237, 255, 0],
    [238, 255, 0],
    [239, 255, 0],
    [241, 255, 0],
    [242, 255, 0],
    [243, 255, 0],
    [245, 255, 0],
    [246, 255, 0],
    [247, 255, 0],
    [249, 255, 0],
    [250, 255, 0],
    [251, 255, 0],
    [253, 252, 0],
    [254, 248, 0],
    [255, 244, 0],
    [255, 240, 0],
    [255, 236, 0],
    [255, 232, 0],
    [255, 228, 0],
    [255, 224, 0],
    [255, 220, 0],
    [255, 216, 0],
    [255, 212, 0],
    [255, 208, 0],
    [255, 204, 0],
    [255, 200, 0],
    [255, 196, 0],
    [255, 192, 0],
    [255, 188, 0],
    [255, 184, 0],
    [255, 180, 0],
    [255, 176, 0],
    [255, 172, 0],
    [255, 168, 0],
    [255, 164, 0],
    [255, 160, 0],
    [255, 156, 0],
    [255, 152, 0],
    [255, 148, 0],
    [255, 144, 0],
    [255, 140, 0],
    [255, 136, 0],
    [255, 132, 0],
    [255, 128, 0],
    [255, 124, 0],
    [255, 120, 0],
    [255, 116, 0],
    [255, 112, 0],
    [255, 108, 0],
    [255, 104, 0],
    [255, 100, 0],
    [255, 96, 0],
    [255, 92, 0],
    [255, 88, 0],
    [255, 84, 0],
    [255, 80, 0],
    [255, 76, 0],
    [255, 72, 0],
    [255, 68, 0],
    [255, 64, 0],
    [255, 60, 0],
    [255, 56, 0],
    [255, 52, 0],
    [255, 48, 0],
    [255, 44, 0],
    [255, 40, 0],
    [255, 36, 0],
    [255, 32, 0],
    [255, 28, 0],
    [255, 24, 0],
    [255, 20, 0],
    [255, 16, 0],
    [255, 12, 0],
    [255, 8, 0],
    [255, 4, 0],
    [255, 0, 0],
  ];

  if (name === "Magma") {
    return colormap_magma;
  }

  if (name === "Viridis") {
    return colormap_viridis;
  }
  if (name === "Jet") {
    return colormap_jet;
  }
  if (name === "Traffic Light") {
    return colormap_traffic_light;
  }

  return colormap_turbo;
}

function applyColormap(src: Float32Array, colormap_name: GenerateHeatmap["colormap"]) {
  // input:
  // src: Float32Array with one float for each pixel
  // output: Uint8ClampedArray
  //

  const num_pixels = src.length;
  let buffer = new Uint8ClampedArray(num_pixels * 4);
  let buffer_pos, histogram_value, rgb;
  const colormap: number[][] = getColorMap(colormap_name);

  for (let pixel_pos = 0; pixel_pos < num_pixels; pixel_pos++) {
    buffer_pos = pixel_pos * 4;
    histogram_value = Math.floor(Math.max(0, Math.min(255, src[pixel_pos]))); // clip to [0,255]

    rgb = colormap[histogram_value];
    buffer[buffer_pos] = rgb[0];
    buffer[buffer_pos + 1] = rgb[1];
    buffer[buffer_pos + 2] = rgb[2];
    buffer[buffer_pos + 3] = Math.min(0.8 * 255, histogram_value);
  }
  return buffer;
}

export function prepareHeatMap(
  src: number[],
  width: number,
  height: number,
  radius: number,
  colormap_name: GenerateHeatmap["colormap"],
) {
  return applyColormap(blur(src, width, height, radius), colormap_name);
}

export function prepareAoiHeatMapColor(
  percentage: number,
  name: GenerateHeatmap["colormap"] = "Turbo",
) {
  const colorMap = getColorMap(name);
  const index = Math.round((colorMap.length - 1) * percentage);

  return colorMap[index];
}

export function getAoiHeatMapPercentage(
  type: AOI_HEATMAP_TYPES | null,
  data?: AoiStatsWithData,
) {
  if (!data || !type) return 0;

  return data.percentage[type];
}

export function formatReadableAoiHeatMapMetric(
  type: AOI_HEATMAP_TYPES | null,
  data?: AoiStatsWithData,
) {
  if (!data || !type) return 0;

  return data.readable[type];
}

export const HEATMAP_LEGEND_ARR = [0, 25, 50, 75, 100];

//ex. "linear-gradient(180deg, #871515 0%, #EF1010 25%, #FFB84D 43.11%, #D9FF43 56.25%, #43F4FF 88.73%)"
export function getHeatmapLegendGradientColors(colorName: GenerateHeatmap["colormap"]) {
  const map = getColorMap(colorName);
  let str = "linear-gradient(360deg, ";

  for (let i = 0; i < HEATMAP_LEGEND_ARR.length; i++) {
    const one = HEATMAP_LEGEND_ARR[i];
    const isLast = i === HEATMAP_LEGEND_ARR.length - 1;
    let index = 10;

    if (isLast) index = map.length - 1;
    else if (one !== 0) index = Math.ceil((map.length * one) / 100);

    str += `rgb(${map[index]}) ${one}%`;

    if (!isLast) str += ", ";
  }

  return str + ")";
}

function getHeatmapLegendGradientColorsExport(colorName: GenerateHeatmap["colormap"]) {
  const map = getColorMap(colorName);
  const arr: Array<{ percent: number; color: string }> = [];

  for (let i = 0; i < HEATMAP_LEGEND_ARR.length; i++) {
    const one = HEATMAP_LEGEND_ARR[i];
    const isLast = i === HEATMAP_LEGEND_ARR.length - 1;
    let index = 10;

    if (isLast) index = map.length - 1;
    else if (one !== 0) index = Math.ceil((map.length * one) / 100);

    arr.push({ percent: 1 - (one === 0 ? 0 : one / 100), color: `rgb(${map[index]})` });
  }

  return arr;
}

export function createCanvasLegend({
  width,
  height,
  draw,
  heatmapColor,
  heatmapType = null,
  max = {} as Record<AOI_HEATMAP_TYPES, number>,
}: {
  width: number;
  height: number;
  draw: (ctx: CanvasRenderingContext2D) => void;
  heatmapColor: GenerateHeatmap["colormap"];
  heatmapType: AOI_HEATMAP_TYPES | null;
  max?: Record<AOI_HEATMAP_TYPES, number>;
}) {
  const canvas = document.createElement("canvas");
  canvas.width = width + 530;
  canvas.height = height > 150 ? height : 150;

  const ctx = canvas.getContext("2d");

  if (!ctx) return canvas;

  ctx.fillStyle = customColors.mauve2;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  draw(ctx);

  const colorMap = getHeatmapLegendGradientColorsExport(heatmapColor);

  const paddingY = canvas.height * 0.1;
  const rectX = width + 150;
  const rectW = 50;
  const rectH = canvas.height - paddingY * 2;
  const fontSize = canvas.width * (14 / 1000);
  const lineWidth = fontSize * 1.5;

  const grad = ctx.createLinearGradient(0, paddingY, 0, canvas.height - paddingY);

  const step = rectH / (colorMap.length - 1);

  colorMap.forEach((one, i) => {
    grad.addColorStop(one.percent, one.color);

    const lineY = paddingY + step * i;

    const text =
      !heatmapType || heatmapType === AOI_HEATMAP_TYPES.REACH
        ? `${one.percent * 100}%`
        : heatmapType === AOI_HEATMAP_TYPES.FIXATION_COUNT
        ? `${Math.round(max[heatmapType] * one.percent)}`
        : `${formatReadableAoiStatMs(Math.round(max[heatmapType] * one.percent))}`;

    ctx.beginPath();
    ctx.moveTo(rectX + rectW, lineY);
    ctx.lineTo(rectX + rectW + lineWidth, lineY);
    ctx.strokeStyle = "#fff";
    ctx.lineWidth = 2;
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.font = `${fontSize}px Inter`;
    ctx.fillStyle = "#fff";
    ctx.fillText(text, rectX + rectW + lineWidth + 5, lineY + fontSize / 3);
    ctx.closePath();
  });

  // Fill rectangle with gradient
  ctx.fillStyle = grad;
  ctx.fillRect(rectX, paddingY, rectW, rectH);

  return canvas;
}
