import { WearersTableData } from "@storeRematch";
import { formatTimeZone } from "@utils/formatTimeZone";
import { DetailItem } from "./DetailItem";

export const WearerContent: React.FC<{ model: WearersTableData }> = ({ model }) => {
  return (
    <>
      <DetailItem
        name="ID"
        content={model.wearer.id}
        copyTextProps={{ copy: { title: "Wearer", text: model.wearer.id } }}
      />
      <DetailItem name="Name" content={model.wearer.name} />
      <DetailItem name="Recordings" content={model.recordingsCount} />
      <DetailItem name="Created" content={formatTimeZone(model.wearer.created_at)} />
      <DetailItem name="Updated" content={formatTimeZone(model.wearer.updated_at)} />
    </>
  );
};
