import { Paper } from "@mui/material";
import { Box, styled } from "@mui/system";

export const FullScreenPaper = styled(Paper)(({ theme }) => {
  return {
    marginTop: theme.components?.MuiAppBar?.styleOverrides?.root?.maxHeight || 48,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: `calc(100vh - ${
      theme.components?.MuiAppBar?.styleOverrides?.root?.maxHeight || 48
    }px)`,
    overflow: "hidden",
  };
});
FullScreenPaper.defaultProps = {
  elevation: 0,
};

const MAX_HEIGHT_TITLE = 65;
export const FullScreenPaperTitle = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
    maxHeight: MAX_HEIGHT_TITLE,
    justifyContent: "space-between",
  };
});

export const FullScreenPaperBody = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: `${theme.spacing(3)} 0px`,
    maxHeight: `calc(100vh - ${
      (theme.components?.MuiAppBar?.styleOverrides?.root?.maxHeight || 48) +
      MAX_HEIGHT_TITLE
    }px)`,
  };
});
