import { ProjectEnrichment } from "@api";
import { store } from "@storeRematch";

export function recordingHasEvents(
  enrichment?: ProjectEnrichment,
  recordingId?: string,
) {
  if (!enrichment || !recordingId) return false;

  const recordingEvents = store
    .getState()
    .projectEvents.eventsByRecordingId.get(recordingId);

  if (!recordingEvents) return false;

  const arr = Array.from(recordingEvents).sort((a, b) => a.offset_s - b.offset_s);

  let start: number | null = null;
  let end: number | null = null;

  for (let i = 0; i < arr.length; i++) {
    if (start !== null && end !== null) break;

    const one = arr[i];

    if (start === null && one.name === enrichment.from_event_name) {
      start = i;
    }

    if (start !== null && one.name === enrichment.to_event_name) {
      end = i;
    }
  }

  return start !== null && end !== null && start < end;
}
