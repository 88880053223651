import { FC, useEffect, useRef } from "react";
import videojs from "video.js";

const defaultOptions: videojs.PlayerOptions = {
  controls: false,
  fill: true,
  enableSourceset: true,
  preload: "auto",
};

export const VideoJS: FC<{
  onReady: (plyer: ReturnType<typeof videojs>) => void;
  options: videojs.PlayerOptions;
}> = ({ options, onReady }) => {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<ReturnType<typeof videojs> | null>(null);

  useEffect(() => {
    if (!videoRef.current) return;
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(
        videoElement,
        { ...defaultOptions, ...options },
        () => {
          videojs.log("player is ready");
          onReady && onReady(player);
        },
      ));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      if (options) {
        if (options.autoplay) player.autoplay(options.autoplay);
        if (options.sources) player.src(options.sources);
      }
    }
  }, [onReady, options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player style={{ width: "100%", height: "100%" }}>
      <div ref={videoRef} style={{ width: "100%", height: "100%" }} />
    </div>
  );
};
