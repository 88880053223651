import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const JumpToEvent: FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="12" height="12" viewBox="0 0 12 12" {...props}>
      <path
        d="M5.24741 1.5V3.09375C4.60314 3.25979 4.03235 3.63528 3.6248 4.16117C3.21726 4.68706 2.99609 5.33351 2.99609 5.99883C2.99609 6.66415 3.21726 7.3106 3.6248 7.83648C4.03235 8.36237 4.60314 8.73787 5.24741 8.90391L5.24741 10.5C5.24741 10.6989 5.32643 10.8897 5.46708 11.0303C5.60774 11.171 5.7985 11.25 5.99741 11.25C6.19633 11.25 6.38709 11.171 6.52774 11.0303C6.6684 10.8897 6.74741 10.6989 6.74741 10.5V8.90508C7.3922 8.73945 7.96359 8.36405 8.37158 7.838C8.77958 7.31195 9.00101 6.66514 9.00101 5.99941C9.00101 5.33369 8.77958 4.68688 8.37158 4.16083C7.96359 3.63478 7.3922 3.25938 6.74741 3.09375L6.74741 1.5C6.74741 1.30109 6.6684 1.11032 6.52774 0.96967C6.38709 0.829018 6.19633 0.75 5.99741 0.75C5.7985 0.75 5.60774 0.829018 5.46708 0.96967C5.32643 1.11032 5.24741 1.30109 5.24741 1.5ZM7.49741 6C7.49741 6.29667 7.40944 6.58668 7.24462 6.83335C7.0798 7.08003 6.84553 7.27229 6.57144 7.38582C6.29735 7.49935 5.99575 7.52906 5.70478 7.47118C5.41381 7.4133 5.14653 7.27044 4.93675 7.06066C4.72698 6.85088 4.58411 6.58361 4.52624 6.29264C4.46836 6.00166 4.49806 5.70006 4.61159 5.42598C4.72513 5.15189 4.91739 4.91762 5.16406 4.7528C5.41073 4.58797 5.70074 4.5 5.99741 4.5C6.39511 4.50043 6.77638 4.65861 7.0576 4.93982C7.33881 5.22103 7.49698 5.60231 7.49741 6Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
