import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";

interface VirtualizedListProps<T> {
  itemData: T[];
  itemKey: (index: number, data: T[]) => string | number;
  itemSize: number;
  renderRow(props: ListChildComponentProps<T[]>): JSX.Element;
}

export const VirtualizedList = <T,>({
  itemData,
  itemKey,
  itemSize,
  renderRow,
}: VirtualizedListProps<T>) => {
  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          height={height || 0}
          width={width || 0}
          itemSize={itemSize}
          itemCount={itemData.length}
          itemData={itemData}
          itemKey={itemKey}
          overscanCount={5}
        >
          {renderRow}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};
