import { Box, Button, Divider, Link, Typography } from "@mui/material";
import { RouterHelper } from "@pages";
import { auth } from "@services/firebase";
import { useAppDispatch } from "@storeRematch";
import { LocalStorageAdapterNames, localStorageAdapter } from "@utils";
import { sendEmailVerification } from "firebase/auth";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";

export const VerifyEmailRequest: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const unVerifiedEmail = localStorageAdapter.get(
    LocalStorageAdapterNames.unVerifiedEmail,
  );

  const handleLogin = () => {
    navigate(RouterHelper.signIn.getFullPath());
  };

  const handleSendEmailVerification = useCallback(async () => {
    if (!unVerifiedEmail) {
      dispatch.notifier.enqueueSnackbar({
        message: t(`Unable to send verification email`),
        options: {
          variant: "error",
        },
      });
      return;
    }

    if (auth.currentUser) {
      try {
        await sendEmailVerification(auth.currentUser);
        dispatch.notifier.enqueueSnackbar({
          message: t(`Verification email sent`),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
        dispatch.notifier.enqueueSnackbar({
          message: t(`Unable to send verification email`),
          options: {
            variant: "error",
          },
        });
      }
    }
  }, [dispatch, t, unVerifiedEmail]);

  useEffect(() => {
    handleSendEmailVerification();
  }, [handleSendEmailVerification]);

  if (!auth.currentUser)
    return <Navigate to={RouterHelper.signIn.getFullPath()} replace />;

  return (
    <>
      <Typography variant="body2" color="text.secondary">
        {t(
          `An email with email verification link have been sent to ${unVerifiedEmail}.`,
        )}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {t(
          `Click on the link to verify your email and make sure to verify from the same device. Check the spam folder if the email does not appear within a few minutes.`,
        )}
      </Typography>
      <Box display="flex" gap={1} justifyContent="space-between">
        <Button color="primary" variant="text" onClick={handleLogin}>
          {t("Sign in")}
        </Button>
        <Button color="primary" onClick={handleSendEmailVerification}>
          {t("Resend")}
        </Button>
      </Box>
      <Divider />
      <Box>
        <Typography variant="body2" color="text.secondary" component="span" mr={1}>
          {t(`Still need help?`)}
        </Typography>
        <Typography variant="body1" component="span" color="primary.main">
          <Link href={"/signup"}>{t(`Contact us`)}</Link>
        </Typography>
      </Box>
    </>
  );
};
