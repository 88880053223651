import {
  api,
  EnrichmentTypesEnum,
  ProjectEnrichment,
  ProjectRecordingEvent,
} from "@api";
import { createModel } from "@rematch/core";
import { recordingHasEvents, remapStaticImageMapper } from "@utils";
import { rematchAdapter, RematchAdapter } from "@utils/rematchAdapter";
import type { RootModel } from "./index";

interface Adapter extends RematchAdapter<ProjectEnrichment> {
  enrichmentsWithRecordings: Map<string, Set<string>>;
}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.id,
  extraProps: {
    enrichmentsWithRecordings: new Map(),
  },
});

export const enrichments = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
    setEnrichmentWithRecordings(state, data: Adapter["enrichmentsWithRecordings"]) {
      state.enrichmentsWithRecordings = data;
    },
  },
  effects: dispatch => ({
    async get({ workspaceId, projectId }: { workspaceId: string; projectId: string }) {
      const [data, mappersData] = await Promise.all([
        api.getProjectEnrichments({ workspaceId, projectId }),
        api.getStaticImageMappers({ workspaceId, projectId }),
      ]);

      dispatch.enrichments.setAll(
        data.result.filter(one => one.kind !== "raw-data-export"),
      );
      dispatch.enrichments.updateMany(
        mappersData.result.map(o => remapStaticImageMapper(o)),
      );
      dispatch.enrichments.prepare(null);
      dispatch.visualizations.get(null);
    },
    prepare(_, state) {
      const data = state.enrichments.data;
      const enrichmentsWithRecordings: Adapter["enrichmentsWithRecordings"] = new Map();
      const recordings = state.app.currentProject?.recording_ids;
      const projectRecordingsSet = new Set(recordings);
      const recordingsById = state.recordings.dataById;

      data.forEach(d => {
        if (!enrichmentsWithRecordings.has(d.id))
          enrichmentsWithRecordings.set(d.id, new Set());

        const current = enrichmentsWithRecordings.get(d.id);

        for (const slice of d.slices) {
          if (
            projectRecordingsSet.has(slice.recording_id) &&
            recordingsById.has(slice.recording_id) &&
            !recordingsById.get(slice.recording_id)?.trashed_at &&
            recordingHasEvents(d, recordingsById.get(slice.recording_id)?.id) &&
            ((d.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER &&
              d.fixations_status?.find(
                s =>
                  s.recording_id === slice.recording_id &&
                  s.enrichment_id === slice.enrichment_id &&
                  s.total_fixations,
              )) ||
              d.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER)
          ) {
            current?.add(slice.recording_id);
          }
        }
      });

      dispatch.enrichments.setEnrichmentWithRecordings(enrichmentsWithRecordings);
      dispatch.visualizations.prepare(null);
    },
    async checkStatus(event: ProjectRecordingEvent, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichments = state.enrichments.data;

      if (!workspaceId || !projectId) return;

      const hasEvent = enrichments.find(
        e =>
          (e.to_event_name === event.name || e.from_event_name === event.name) &&
          e.status?.SUCCESS === 1,
      );

      if (!hasEvent) return;

      await dispatch.enrichments.get({ workspaceId, projectId });
    },
    getRunningTime(_, state) {
      const enrichment = state.projectEdit.currentEnrichment;
      const events = state.projectEvents.data;

      if (
        !enrichment ||
        (enrichment.kind !== "slam-mapper" && enrichment.kind !== "face-mapper")
      )
        return null;

      const result: Array<[ProjectRecordingEvent, ProjectRecordingEvent]> = [];
      const usedEventIndices = new Set<number>();
      let seconds = 0;

      const fromName = enrichment.from_event_name;
      const toName = enrichment.to_event_name;

      for (let i = 0; i < events.length; i++) {
        if (!usedEventIndices.has(i) && events[i].name === fromName) {
          for (let k = i + 1; k < events.length; k++) {
            if (usedEventIndices.has(k)) {
              continue;
            }
            if (events[k].name === toName) {
              result.push([events[i], events[k]]);
              seconds += events[k].offset_s - events[i].offset_s;
              usedEventIndices.add(k);
              break;
            }
          }
        }
      }

      const date = new Date(0);
      date.setSeconds(seconds);

      return {
        seconds,
        readableFormat: date.toISOString().substring(11, 19),
        fromName,
        toName,
      };
    },
  }),
});
