import { api, ProjectEvent } from "@api";
import { createModel } from "@rematch/core";
import { rematchAdapter, RematchAdapter } from "@utils/rematchAdapter";
import type { RootModel } from "./index";

interface ExtraProps {
  select: Array<{ value: string; label: string }>;
}

interface Adapter extends RematchAdapter<ProjectEvent>, ExtraProps {}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.name || "",
  extraProps: {
    select: [],
  },
});

export const uniqEvents = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
    setSelect(state, data: ExtraProps["select"]) {
      state.select = data;
    },
  },
  effects: dispatch => ({
    async get({ workspaceId }: { workspaceId: string }) {
      const data = await api.getWorkspaceUniqueEvents({ workspaceId });

      dispatch.uniqEvents.setAll(data.result);
    },
    prepare(_, state) {
      dispatch.uniqEvents.setSelect(
        state.uniqEvents.data.reduce<ExtraProps["select"]>((acc, one) => {
          if (one.name) {
            acc.push({
              value: one.name,
              label: one.name,
            });
          }

          return acc;
        }, []),
      );
    },
  }),
});
