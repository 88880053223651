import {
  EnrichmentTypesEnum,
  ProjectEnrichment,
  ProjectEnrichmentSlice,
  RecordingEvent,
} from "@api";
import { ContextMenu, CtxMenuItem, VideoEventsCanvas } from "@components";
import { HORIZONTAL_SCROLL_ID } from "@constants";
import { JumpToEvent } from "@customIcons";
import {
  VIDEO_POSITIONS_ROOT,
  VIDEO_TIMELINE_SCROLL_ROOT,
  getNumberOfTimesConfigSize,
  useGesture,
} from "@hooks";
import {
  Add,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Visibility,
  VisibilityOff,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  MenuList,
  Slider,
  TextField,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { RouterHelper } from "@pages";
import { Instance } from "@popperjs/core";
import {
  ContextMenuTypes,
  QuestionDialogTypes,
  VideoEventsTypes,
  store,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import {
  DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT,
  DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_OPEN_HEIGHT,
} from "@storeRematch/videoEvents";
import {
  DisableByPermissionsType,
  disabledByPermission,
  findAllEventSlices,
  formatReadableEnrichmentEyeIconTooltip,
  getLocalizationColor,
  getLocalizationStartAndEndTimes,
  isLocalizationWithData,
  setScrollDisabled,
} from "@utils";
import {
  FC,
  PropsWithChildren,
  ReactNode,
  WheelEvent,
  WheelEventHandler,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import { GazeOffsetLink } from "./GazeOffsetLink";
import {
  LIST_ROOT_ID,
  LIST_WIDTH_CALC,
  VideoEventsBase,
  VideoEventsItem,
  WIDTH_SCROLL_DEFAULT,
  useVirtualListScrollWidth,
} from "./VideoComponentsBase";
import { VideoEventsFilmstrip } from "./VideoEventsFilmstrip";
import { VideoPositions } from "./VideoPositions";
import { VideoTimeline } from "./VideoTimeline";
import { ShapesControl, gazeOverlay, getEnrichmentOverlays } from "./controller";
import { GazeOverlay } from "./controller/GazeOverlay";

const Row = ({ index, style }: { index: number; style?: any }) => {
  const rows = useAppSelector(state => state.videoEvents.virtualListRows);
  const row = rows[index];

  return (
    <div style={style}>
      {row.type === VideoEventsTypes.EVENTS ? <Events /> : null}
      {row.type === VideoEventsTypes.EVENT ? <Event data={row.data} /> : null}
      {row.type === VideoEventsTypes.ENRICHMENT ? <Enrichment /> : null}
      {row.type === VideoEventsTypes.FIXATION ? <Fixation /> : null}
      {row.type === VideoEventsTypes.GAZE ? <Gaze /> : null}
      {row.type === VideoEventsTypes.BLINKS ? <Blinks /> : null}
    </div>
  );
};

const RowBase: FC<
  PropsWithChildren<{
    name: string;
    nameTooltip?: string;
    openId?: string;
    open?: boolean;
    onOpen?: () => void;
    visible?: boolean;
    onVisible?: () => void;
    visibleDisabled?: boolean;
    action?: ReactNode;
    hover?: boolean;
    isSelected?: boolean;
    onContextMenu?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onJumpToEvent?: () => void;
    EditComponent?: JSX.Element;
    onDoubleClick?: () => void;
    onWheel?: WheelEventHandler<HTMLDivElement>;
    onTouchMove?: any;
    onScroll?: any;
    offWidth?: boolean;
    id?: string;
    showHideButtonTooltip?: string;
    disabled?: boolean;
    disableOverflow?: boolean;
    gaze?: boolean;
  }>
> = ({
  name,
  nameTooltip,
  openId,
  open,
  onOpen,
  visible,
  onVisible,
  visibleDisabled,
  action,
  hover,
  isSelected,
  onContextMenu,
  onJumpToEvent,
  children,
  EditComponent,
  onDoubleClick,
  onWheel,
  onTouchMove,
  onScroll,
  offWidth = false,
  id,
  showHideButtonTooltip,
  disabled,
  disableOverflow = true,
  gaze = false,
}) => {
  const dispatch = useAppDispatch();
  const { styleWidth } = useVirtualListScrollWidth();
  const disable = useAppSelector(state => state.video.hideVideoPlayer);

  return (
    <VideoEventsBase>
      <VideoEventsItem
        hover={hover}
        isSelected={isSelected}
        onContextMenu={onContextMenu}
        leftContent={
          <Box display="flex" alignItems="flex-start" width="100%">
            {open === undefined ? (
              <Box minWidth={27.5} />
            ) : (
              <IconButton onClick={onOpen} size="small" id={openId}>
                {open === true ? <KeyboardArrowDown fontSize="small" /> : null}
                {open === false ? <KeyboardArrowRight fontSize="small" /> : null}
              </IconButton>
            )}

            <Box
              sx={{
                display: "flex",
                mx: 0.5,
                my: "auto",
                marginRight: "auto",
              }}
              onDoubleClick={onDoubleClick}
            >
              {EditComponent || (
                <Tooltip title={nameTooltip || name} disableInteractive>
                  <Typography
                    my="auto"
                    maxWidth={onJumpToEvent && !EditComponent ? "7rem" : "6.5rem"}
                    noWrap
                    variant="body2"
                    color={disabled ? "text.secondary" : "default"}
                  >
                    {name}
                  </Typography>
                </Tooltip>
              )}
            </Box>
            {gaze ? <GazeOffsetLink /> : null}
            {action ? (
              action
            ) : onVisible ? (
              <Tooltip title={showHideButtonTooltip} disableInteractive>
                <Box>
                  <IconButton
                    sx={{ my: "auto", ml: "auto" }}
                    onClick={onVisible}
                    size="small"
                    disabled={visibleDisabled}
                  >
                    {visible ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff fontSize="small" />
                    )}
                  </IconButton>
                </Box>
              </Tooltip>
            ) : null}
            {onJumpToEvent && !EditComponent ? (
              <Tooltip title="Move Playhead to Event Time" disableInteractive>
                <span>
                  <IconButton
                    sx={{ ml: "auto", opacity: 0, "&:hover": { opacity: 1 } }}
                    onClick={onJumpToEvent}
                    size="small"
                    data-showonhover="true"
                    disabled={disable}
                  >
                    <JumpToEvent fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            ) : null}
          </Box>
        }
        rightContent={
          <Box
            id={id}
            width={offWidth ? "100%" : styleWidth}
            height="100%"
            onClick={dispatch.video.setTimeToSelected}
            onMouseMove={dispatch.video.onVideoControlMouseMove}
            onMouseLeave={dispatch.video.clearSelectedPercentage}
            onWheel={e => {
              if (onWheel) onWheel(e);
              dispatch.video.onVideoWheel(e);
            }}
            onTouchMove={onTouchMove}
            onScroll={onScroll}
            display="flex"
            position="relative"
            overflow={disableOverflow ? "hidden" : "unset"}
          >
            {children}
          </Box>
        }
      />
    </VideoEventsBase>
  );
};

export const EVENTS_CALC_ROOT_ID = "events_root_id";
const Thumbnail = memo(
  ({ scrollTo }: { scrollTo: (num: number, max: number) => void }) => {
    const row = useAppSelector(state => state.videoEvents.virtualListRows[0]);
    const { height, styleWidth } = useVirtualListScrollWidth(true);
    const isVisible = row.height > DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT;

    const onWheel = (e: WheelEvent<HTMLDivElement>) => {
      const offset = isVisible
        ? DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_HEIGHT
        : DEFAULT_VIDEO_VIRTUAL_LIST_ITEM_OPEN_HEIGHT;

      scrollTo(e.deltaY, height + offset);
    };

    return (
      <Box
        sx={{
          zIndex: 2,
          top: 0,
          position: "absolute",
          width: styleWidth,
          overflow: "hidden",
        }}
        onWheel={onWheel}
      >
        <Box
          sx={{
            backgroundColor: "background.default",
            width: "100%",
            height: row.height,
            display: "flex",
          }}
        >
          <RowBase
            id={EVENTS_CALC_ROOT_ID}
            offWidth
            name="Thumbnail"
            visible={isVisible}
            onWheel={onWheel}
          >
            {/* <FilmStrip isVisible={isVisible} /> */}
            <VideoEventsFilmstrip />
          </RowBase>
        </Box>
      </Box>
    );
  },
);

const Events = memo(() => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const open = useAppSelector(state => state.videoEvents.openEvents);
  const disabled = useAppSelector(
    state => !!state.videoEvents.editEvent || state.video.hideVideoPlayer,
  );
  const events = useAppSelector(state => state.videoEvents.virtualListEvents);
  const disabledPermissions = disabledByPermission({
    type: DisableByPermissionsType.CREATE,
  });

  return (
    <RowBase
      name="Events"
      openId="video_events_open"
      open={events.length ? open : undefined}
      onOpen={() => dispatch.videoEvents.setOpenEvents(!open)}
      action={
        <Tooltip
          title={
            disabledPermissions
              ? t("Disabled by permission")
              : disabled
              ? t("Disabled while loading data")
              : undefined
          }
          disableInteractive
        >
          <span>
            <Button
              disabled={disabledPermissions || disabled}
              sx={{ ml: "auto", my: "auto" }}
              size="small"
              variant="text"
              color="primary"
              startIcon={<Add fontSize="small" />}
              onClick={dispatch.videoEvents.prepareNewEvent}
            >
              {t("Add")}
            </Button>
          </span>
        </Tooltip>
      }
      disableOverflow={false}
    >
      <VideoEventsCanvas events={events} />
    </RowBase>
  );
});

const EditEvent = ({ event }: { event?: RecordingEvent }) => {
  const dispatch = useAppDispatch();
  const newEventId = useAppSelector(state => state.videoEvents.newEventId);
  const eventSuggestions = useAppSelector(state => state.uniqEvents.select);
  const disabled = useAppSelector(
    state =>
      state.loading.effects.videoEvents.createEvent.loading ||
      state.loading.effects.videoEvents.updateEvent.loading,
  );

  const clear = useCallback(() => {
    if (event && event.id === newEventId) {
      dispatch.recordingEvents.deleteMany([event.id]);
      dispatch.recordingEvents.prepare(null);
      dispatch.videoEvents.setNewEventId(null);
    }

    dispatch.videoEvents.setCurrentEvent(null);
    dispatch.videoEvents.setEditEvent(null);
  }, [dispatch, event, newEventId]);

  return (
    <Autocomplete
      value={event ? { value: event.name, label: event.name } : undefined}
      disabled={disabled}
      options={eventSuggestions}
      size="small"
      disableClearable
      open
      freeSolo
      disablePortal
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              ...params.inputProps.style,
              fontSize: "0.875rem",
              lineHeight: "16px",
              width: "100%",
            },
          }}
          autoFocus
        />
      )}
      ListboxProps={{ style: { maxHeight: 150 } }}
      onChange={(_, value) => {
        if (!event) return;

        const next = typeof value === "string" ? value : value.value;

        if (event.id === newEventId)
          dispatch.videoEvents.createEvent({ ...event, name: next });
        else dispatch.videoEvents.updateEvent({ ...event, name: next });

        clear();
      }}
      onBlur={clear}
      onKeyDown={e => {
        if (e.key === "Escape") clear();
        if (e.key === "Enter") {
          const inputRef = e.currentTarget?.children?.[0]?.children?.[0]?.children?.[0];

          if (
            inputRef &&
            inputRef.nodeName === "INPUT" &&
            !(inputRef as HTMLInputElement).value
          ) {
            dispatch.videoEvents.deleteEvent(null);
          }
        }
      }}
    />
  );
};

const useInstanceDuration = () => {
  const instance = useAppSelector(state => state.video.instance);
  const [duration, setDuration] = useState(instance?.duration());

  useEffect(() => {
    if (!instance) return;

    const update = () => {
      setDuration(instance.duration());
    };

    const reset = () => {
      setDuration(undefined);
    };

    instance.on("loadeddata", update);
    instance.on("loadstart", reset);

    return () => {
      instance.on("loadeddata", update);
      instance.on("loadstart", reset);
    };
  }, [instance]);

  return duration;
};

type UseVideoEventOffsetSlices = Array<{
  key: string;
  slice: ProjectEnrichmentSlice;
  offsetStart: number;
  offsetEnd: number;
}>;

type UseVideEventOffsetLocalization = Array<{
  key: string;
  offsetStart: number;
  offsetEnd: number;
  color: boolean | null;
}>;

const useVideoEventOffset = ({
  start,
  end,
  enrichment,
}: {
  start?: RecordingEvent;
  end?: RecordingEvent;
  enrichment?: ProjectEnrichment;
}) => {
  const duration = useInstanceDuration();
  const checkedDuration = duration === undefined || isNaN(duration) ? -1 : duration;
  const recordingId = useAppSelector(state => state.video.recording?.id);
  const events = useAppSelector(state =>
    state.recordingEvents.eventsByRecordingId.get(recordingId || ""),
  );
  const cutVideoEnrichmentSlices = useAppSelector(
    state => state.app.cutVideoEnrichmentSlices,
  );
  const faceLocalization = useAppSelector(state => state.localization.face);
  const surfaceLocalization = useAppSelector(state => state.localization.surface);
  const markerlessLocalization = useAppSelector(state => state.localization.markerless);
  const markerMapperLocalization = useAppSelector(
    state => state.localization.markerMapper,
  );

  const offsetStart = ((start ? start.offset_s : 0) / checkedDuration) * 100;
  const offsetEnd = ((end ? end.offset_s : 0) / checkedDuration) * 100;

  const video = store.getState().video.instance;

  const slices = useMemo(() => {
    const slices: UseVideoEventOffsetSlices = [];

    if (
      enrichment &&
      (enrichment.kind === EnrichmentTypesEnum.FACE_MAPPER ||
        enrichment.kind === EnrichmentTypesEnum.SLAM_MAPPER ||
        enrichment.kind === EnrichmentTypesEnum.MARKER_MAPPER ||
        enrichment.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER)
    ) {
      const sections = findAllEventSlices({
        events,
        from: start?.name || "",
        to: end?.name || "",
        duration,
        selection: { from: start?.name || "", to: end?.name || "" },
      });

      for (const slice of enrichment.slices) {
        if (!recordingId || slice.recording_id !== recordingId) continue;

        let startTime = slice.start_time_s;
        let endTime =
          video && slice.end_time_s > video.duration()
            ? video.duration()
            : slice.end_time_s;

        startTime = (startTime / checkedDuration) * 100;
        endTime = (endTime / checkedDuration) * 100;

        if (cutVideoEnrichmentSlices) {
          sections.forEach((s, i) => {
            if (
              s.start === undefined ||
              s.end === undefined ||
              isNaN(s.start) ||
              isNaN(s.end)
            )
              return;

            let newStartTime = startTime;
            let newEndTime = endTime;

            if (s.start > startTime) {
              newStartTime = s.start;
            }

            if (s.end <= endTime) {
              newEndTime = s.end;
            }

            slices.push({
              key: `${i}_${slice.id}`,
              slice,
              offsetStart: newStartTime,
              offsetEnd: newEndTime,
            });
          });
        } else {
          slices.push({
            key: slice.id,
            slice,
            offsetStart: startTime,
            offsetEnd: endTime,
          });
        }
      }
    }

    return enrichment && enrichment.kind === EnrichmentTypesEnum.RENDER
      ? [
          {
            key: "0",
            offsetStart: 0,
            offsetEnd: video ? (video.duration() / checkedDuration) * 100 : 0,
            slice: {
              id: "simulated",
              status: enrichment.status?.COMPUTING
                ? "PROCESSING"
                : enrichment.status?.READY || enrichment.status?.STALE
                ? "READY"
                : enrichment.status?.SUCCESS
                ? "SUCCESS"
                : "ERROR",
            },
          },
        ]
      : slices;
  }, [
    checkedDuration,
    duration,
    end,
    enrichment,
    events,
    recordingId,
    start,
    video,
    cutVideoEnrichmentSlices,
  ]);

  const localization = useMemo(() => {
    const localization: UseVideEventOffsetLocalization = [];

    const localizationArr = markerMapperLocalization.length
      ? markerMapperLocalization
      : faceLocalization.length
      ? faceLocalization
      : markerlessLocalization.length
      ? markerlessLocalization
      : surfaceLocalization;

    localizationArr.forEach(l => {
      if (!isLocalizationWithData(l)) return;

      let { startTime, endTime } = getLocalizationStartAndEndTimes(video, l);

      startTime = (startTime / checkedDuration) * 100;
      endTime = (endTime / checkedDuration) * 100;

      if (cutVideoEnrichmentSlices) {
        slices.forEach((s, i) => {
          if (
            s.offsetStart === undefined ||
            s.offsetEnd === undefined ||
            isNaN(s.offsetStart) ||
            isNaN(s.offsetEnd)
          )
            return;

          let newStartTime = startTime;
          let newEndTime = endTime;

          if (s.offsetStart > startTime) {
            newStartTime = s.offsetStart;
          }

          if (s.offsetEnd <= endTime) {
            newEndTime = s.offsetEnd;
          }

          if (newStartTime >= newEndTime) return;

          localization.push({
            key: `${i}_${newStartTime}_${newEndTime}`,
            offsetStart: newStartTime,
            offsetEnd: newEndTime,
            color: getLocalizationColor(l),
            l,
          } as any);
        });
      } else {
        const slice = slices.find(
          s =>
            s.offsetStart <= startTime &&
            s.offsetEnd >= endTime &&
            s.slice.status === "SUCCESS",
        );

        if (!slice) return;

        localization.push({
          key: `${startTime}_${endTime}`,
          offsetStart: startTime,
          offsetEnd: endTime,
          color: getLocalizationColor(l),
        });
      }
    });

    return localization;
  }, [
    checkedDuration,
    cutVideoEnrichmentSlices,
    faceLocalization,
    surfaceLocalization,
    markerlessLocalization,
    markerMapperLocalization,
    slices,
    video,
  ]);

  return { offsetStart, offsetEnd, slices, localization };
};

function isSystemEvent(event: RecordingEvent) {
  return event.origin === "recording";
}

const useVisibleOverlay = (overlays?: Array<ShapesControl<any, any>>) => {
  const [visible, setVisible] = useState(
    overlays ? overlays[0].hide : gazeOverlay.hide,
  );

  if (!overlays) return { visible: undefined, onVisible: undefined };

  const onVisible = () => {
    overlays.forEach(o => {
      o.hide = !visible;
    });

    setVisible(!visible);
  };

  return { visible: !visible, onVisible };
};

const Event = memo(({ data }: { data: RecordingEvent }) => {
  const dispatch = useAppDispatch();
  const isSelected = useAppSelector(
    state => state.videoEvents.currentEvent?.id === data.id,
  );
  const isEdit = useAppSelector(state => state.videoEvents.editEvent?.id === data.id);
  const isSystem = isSystemEvent(data);
  const disabledPermission = disabledByPermission({
    type: DisableByPermissionsType.EDIT,
  });

  const setEvent = () => {
    dispatch.videoEvents.setCurrentEvent(data);
  };

  const jumpToEventTime = () => {
    setEvent();
    dispatch.videoEvents.jumpToEventTime(null);
  };

  const editEvent = () => {
    setEvent();
    dispatch.videoEvents.setEditEvent(undefined);
  };

  return (
    <RowBase
      name={data.name}
      EditComponent={
        isEdit && !isSystem && !disabledPermission ? (
          <EditEvent event={data} />
        ) : undefined
      }
      hover
      isSelected={isSelected}
      onContextMenu={e => {
        setEvent();
        dispatch.ctxMenu.handleClick({ type: ContextMenuTypes.PLAYER_EVENT, e });
      }}
      onJumpToEvent={jumpToEventTime}
      onDoubleClick={editEvent}
      disableOverflow={false}
    >
      <VideoEventsCanvas event={data} />
    </RowBase>
  );
});

const GradientLoader = styled(Box)`
  ${() => `
  background: linear-gradient(90deg, #A09FA6, rgba(217, 217, 217, 0), #A09FA6);
  background-size: 400% 400%;
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;

@keyframes placeHolderShimmer{
  0%{ background-position:0% 50% }
  50%{ background-position:100% 50% }
  100%{ background-position:0% 50% }
}
`}
`;

const LOCALIZATION_COLORS = {
  GAZE_ON: "#FFF",
  LOCALIZED: "primary.main",
  COMPLETED: "#737389",
};

// http://localhost:3000/workspaces/805abbd1-b56b-4dc9-9179-532eead979bd/projects/cca603ac-f0e6-4361-adf5-6b1b1cfe4f48/enrichments/efe078c4-f87a-49de-9557-568bb1abd050
// http://localhost:3000/workspaces/e95681c6-92c3-4d52-a92a-9db4b22f7299/projects/3b676201-39cf-4c05-b0a4-4ec75edcbbc6/enrichments/dffa2c6d-2219-4e30-9b04-60ddce6161db
// for those 3 sections we'd expect:
// section1. [blue]
// section2. [blue+gray+red]
// section3. [gray, orange?, gray, animation]
const Enrichment = memo(() => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const zoomCss = useAppSelector(state => state.video.zoom.css);
  const enrichment = useAppSelector(state => state.projectEdit.currentEnrichment);
  const currentEnrichmentEvents = useAppSelector(
    state => state.videoEvents.currentEnrichmentEvents,
  );
  const { slices, localization } = useVideoEventOffset({
    ...currentEnrichmentEvents,
    enrichment,
  });
  const useVisible = useVisibleOverlay(
    enrichment ? getEnrichmentOverlays(enrichment.kind) : undefined,
  );
  const name = enrichment
    ? formatReadableEnrichmentEyeIconTooltip(enrichment.kind)
    : "";
  const disabled = !currentEnrichmentEvents;

  useEffect(() => {
    if (enrichment) {
      dispatch.videoEvents.calculateEnrichmentEvents(enrichment);
    } else {
      dispatch.videoEvents.setCurrentEnrichmentEvents(null);
    }
  }, [enrichment, dispatch]);

  return (
    <RowBase
      disabled={disabled}
      visibleDisabled={disabled}
      name={enrichment?.name || ""}
      nameTooltip={disabled ? "Recording is not included in the enrichment" : undefined}
      {...(!enrichment || enrichment.kind === EnrichmentTypesEnum.RENDER
        ? {}
        : useVisible)}
      showHideButtonTooltip={
        disabled ? "" : useVisible.visible ? t(`Hide ${name}`) : t(`Show ${name}`)
      }
    >
      <Box
        sx={{
          height: "100%",
          position: "absolute",
          display: "flex",
          ...zoomCss,
        }}
      >
        {enrichment ? <EnrichmentBackground /> : null}
        {enrichment && disabled ? (
          <Box
            sx={{
              width: "100%",
              border: "1px solid red",
              borderRadius: "2px",
              height: 12,
              borderColor: "text.disabled",
              m: "auto",
            }}
          />
        ) : null}
        {enrichment && enrichment.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER
          ? slices.map((o, i) => {
              const isLoading =
                o.slice.status === "SCHEDULED" ||
                o.slice.status === "PROCESSING" ||
                o.slice.status === "CANCELLING";
              const Component = isLoading ? GradientLoader : Box;

              const defaultBorder = "2px";

              const next = slices[i + 1];
              const nextBorder =
                next && o.offsetEnd === next.offsetStart ? 0 : defaultBorder;

              const previous = slices[i - 1];
              const previousBorder =
                previous && o.offsetStart === previous.offsetEnd ? 0 : defaultBorder;

              return (
                <Component
                  key={o.key}
                  sx={{
                    top: 0,
                    bottom: 0,
                    marginY: "auto",
                    borderRadius: defaultBorder,
                    borderTopLeftRadius: previousBorder,
                    borderBottomLeftRadius: previousBorder,
                    borderTopRightRadius: nextBorder,
                    borderBottomRightRadius: nextBorder,
                    position: "absolute",
                    height: 8,
                    marginLeft: `${o.offsetStart}%`,
                    width: `${o.offsetEnd - o.offsetStart}%`,
                    pointerEvents: "none",
                    backgroundColor:
                      isLoading ||
                      o.slice.status === "READY" ||
                      o.slice.status === "STALE"
                        ? undefined
                        : o.slice.status === "ERROR"
                        ? "error.main"
                        : LOCALIZATION_COLORS.COMPLETED,
                  }}
                />
              );
            })
          : null}
      </Box>
      {enrichment ? <EnrichmentLocalization localization={localization} /> : null}
    </RowBase>
  );
});

const EnrichmentLocalization: FC<{ localization: UseVideEventOffsetLocalization }> = ({
  localization,
}) => {
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const disabled = useAppSelector(state => state.video.hideVideoPlayer);
  const zoomOffset = useAppSelector(state => state.video.zoom.zoomOffset);
  const zoomCss = useAppSelector(state => state.video.zoom.css);
  const fullScreen = useAppSelector(state => state.video.fullScreen);
  const size = useAppSelector(
    state => state.dragger.table.size + state.dragger.tableProject.size,
  );
  const duration = useInstanceDuration();
  const theme = useTheme();

  useLayoutEffect(() => {
    function draw() {
      const root = document.getElementById(VIDEO_TIMELINE_SCROLL_ROOT);
      const rootWidth = root?.offsetWidth;
      const rootParentWidth = root?.parentElement?.offsetWidth;

      if (!rootWidth || !rootParentWidth) return null;

      if (!canvas.current) return;

      canvas.current.width = canvas.current.parentElement?.offsetWidth || 0;
      canvas.current.height = canvas.current.parentElement?.offsetHeight || 0;

      const ctx = canvas.current.getContext("2d");

      if (!ctx) return;

      ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);

      if (disabled) return;

      const checkedDuration = duration === undefined || isNaN(duration) ? -1 : duration;
      if (checkedDuration === -1) return;

      const w = rootWidth;

      for (let i = 0; i < localization.length; i++) {
        const one = localization[i];

        const x = w * (one.offsetStart / 100) - zoomOffset;
        const y = w * (one.offsetEnd / 100) - zoomOffset;
        const diff = y - x;

        if ((x < 0 && y < 0) || (x > canvas.current.width && y > canvas.current.width))
          continue;

        ctx.beginPath();
        ctx.fillStyle =
          one.color === null
            ? LOCALIZATION_COLORS.COMPLETED
            : one.color
            ? LOCALIZATION_COLORS.GAZE_ON
            : theme.palette.primary.main;
        ctx.fillRect(x, 0, diff < 0.5 ? 0.5 : diff, canvas.current.height);
        ctx.closePath();
      }
    }

    draw();

    window.addEventListener("resize", draw);
    return () => window.removeEventListener("resize", draw);
  }, [zoomOffset, zoomCss, fullScreen, size, duration, disabled, localization, theme]);

  return (
    <canvas
      id="EnrichmentLocalization"
      ref={canvas}
      style={{
        width: "100%",
        height: 8,
        margin: "auto",
        zIndex: 1,
        pointerEvents: "none",
      }}
    />
  );
};

const EnrichmentBackground = () => {
  const enrichment = useAppSelector(state => state.projectEdit.currentEnrichment);
  const from = useAppSelector(state => state.projectEdit.form.from_event_name);
  const to = useAppSelector(state => state.projectEdit.form.to_event_name);
  const events = useAppSelector(state =>
    state.video.recording
      ? state.recordingEvents.eventsByRecordingId.get(state.video.recording.id)
      : null,
  );
  const duration = useInstanceDuration();

  const found = useMemo(() => {
    if (enrichment?.kind === EnrichmentTypesEnum.RENDER) {
      return [
        {
          start: 0,
          end: 100,
          key: "0",
        },
      ];
    }

    return findAllEventSlices({
      events,
      from,
      to,
      duration,
      selection: { from, to },
    });
  }, [events, from, to, duration, enrichment]);

  return (
    <>
      {found.map(
        f =>
          f.start !== undefined &&
          f.end !== undefined &&
          !isNaN(f.start) &&
          !isNaN(f.end) && (
            <EnrichmentBackgroundSliceAndTooltip key={f.key} found={f} />
          ),
      )}
    </>
  );
};

const EnrichmentBackgroundSliceAndTooltip: FC<{
  found: { start?: number | undefined; end?: number | undefined; key: string };
}> = ({ found }) => {
  const { t } = useTranslation();
  const cutVideoEnrichmentSlices = useAppSelector(
    state => state.app.cutVideoEnrichmentSlices,
  );
  const isError = useAppSelector(
    state => !!state.projectEdit.currentEnrichment?.status?.ERROR,
  );
  const isLoading = useAppSelector(
    state => !!state.projectEdit.currentEnrichment?.status?.COMPUTING,
  );
  const kind = useAppSelector(state => state.projectEdit.currentEnrichment?.kind);
  const show = useAppSelector(
    state =>
      state.projectEdit.currentEnrichment?.kind === EnrichmentTypesEnum.SLAM_MAPPER ||
      state.projectEdit.currentEnrichment?.kind === EnrichmentTypesEnum.MARKER_MAPPER ||
      state.projectEdit.currentEnrichment?.kind === EnrichmentTypesEnum.FACE_MAPPER ||
      state.projectEdit.currentEnrichment?.kind ===
        EnrichmentTypesEnum.STATIC_IMAGE_MAPPER,
  );
  const positionRef = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = useRef<Instance>(null);
  const areaRef = useRef<Element | null>(null);

  const handleMouseMove = (e: React.MouseEvent) => {
    positionRef.current = { x: e.clientX, y: e.clientY };
    areaRef.current = e.currentTarget;

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  if (found.start === undefined || found.end === undefined || !show) return null;

  return (
    <>
      <Tooltip
        placement="bottom-start"
        disableInteractive
        title={
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER ? (
              <EnrichmentBackgroundSliceAndTooltipBlock
                transparent
                text={t("No fixation")}
              />
            ) : null}

            <EnrichmentBackgroundSliceAndTooltipBlock
              color={LOCALIZATION_COLORS.COMPLETED}
              text={
                kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER
                  ? t("Fixation not mapped")
                  : t("Enrichment completed")
              }
            />

            {kind === EnrichmentTypesEnum.SLAM_MAPPER ||
            kind === EnrichmentTypesEnum.MARKER_MAPPER ||
            kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER ? (
              <EnrichmentBackgroundSliceAndTooltipBlock
                color={LOCALIZATION_COLORS.GAZE_ON}
                text={t(
                  `${
                    kind === EnrichmentTypesEnum.SLAM_MAPPER
                      ? "Reference image localized"
                      : kind === EnrichmentTypesEnum.MARKER_MAPPER
                      ? "Surface localized"
                      : "Fixation mapped outside of reference image"
                  }`,
                )}
              />
            ) : null}

            <EnrichmentBackgroundSliceAndTooltipBlock
              color={LOCALIZATION_COLORS.LOCALIZED}
              text={t(
                `${
                  kind === EnrichmentTypesEnum.SLAM_MAPPER
                    ? "Gaze on reference image"
                    : kind === EnrichmentTypesEnum.MARKER_MAPPER
                    ? "Gaze on surface"
                    : kind === EnrichmentTypesEnum.FACE_MAPPER
                    ? "Face detected"
                    : "Fixation mapped on reference image"
                }`,
              )}
            />

            {isError && (
              <EnrichmentBackgroundSliceAndTooltipBlock
                color="error.main"
                text={t("Errored")}
              />
            )}

            {isLoading && (
              <EnrichmentBackgroundSliceAndTooltipBlock text={t("Processing")} />
            )}
          </Box>
        }
        PopperProps={{
          popperRef,
          anchorEl: {
            getBoundingClientRect: () => {
              return new DOMRect(
                positionRef.current.x,
                areaRef.current?.getBoundingClientRect().y,
                0,
                0,
              );
            },
          },
        }}
        enterDelay={500}
      >
        <Box
          onMouseMove={handleMouseMove}
          sx={{
            top: 0,
            bottom: 0,
            marginY: "auto",
            borderRadius: "2px",
            position: "absolute",
            height: 12,
            border: "1px solid",
            borderColor: "customColors.light3",
            marginLeft: cutVideoEnrichmentSlices ? `${found.start}%` : 0,
            width: cutVideoEnrichmentSlices ? `${found.end - found.start}%` : "100%",
          }}
        />
      </Tooltip>
    </>
  );
};

const EnrichmentBackgroundSliceAndTooltipBlock: FC<{
  color?: string;
  transparent?: boolean;
  text: string;
}> = ({ color, transparent, text }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        sx={{
          mr: 1,
          width: 10,
          height: 10,
          backgroundColor: transparent ? "none" : color,
          borderRadius: "2px",
          border: transparent ? `1px solid black` : undefined,
          borderColor: transparent ? "text.secondary" : undefined,
        }}
      >
        {!color && !transparent && <GradientLoader sx={{ height: "100%" }} />}
      </Box>
      <Typography variant="body2">{text}</Typography>
    </Box>
  );
};

const MockedLine = ({ disabled }: { disabled: boolean }) => {
  if (disabled) return null;

  return (
    <Box
      sx={{
        height: 2,
        backgroundColor: "text.secondary",
        margin: "auto",
        width: "100%",
      }}
    />
  );
};

const Fixation = memo(() => {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(state => !state.video.hasFixation);
  const useVisible = useVisibleOverlay(
    getEnrichmentOverlays(EnrichmentTypesEnum.RENDER),
  );
  const hideFixation = useAppSelector(state => !state.projectEdit.hideFixation);
  const { t } = useTranslation();

  useEffect(() => {
    if (useVisible.visible !== hideFixation) useVisible.onVisible?.();
  }, [hideFixation, useVisible]);

  return (
    <RowBase
      name="Fixation"
      onVisible={() => {
        useVisible.onVisible?.();
        dispatch.projectEdit.tryUpdateRender(null);
      }}
      visibleDisabled={disabled}
      visible={disabled ? false : useVisible.visible}
      showHideButtonTooltip={
        disabled
          ? t("Disabled")
          : useVisible.visible
          ? t("Hide fixation overlay")
          : t("Show fixation overlay")
      }
    >
      <MockedLine disabled={disabled} />
    </RowBase>
  );
});

const Gaze = memo(() => {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(state => !state.video.hasGaze);
  const useVisible = useVisibleOverlay(getEnrichmentOverlays("gaze"));
  const hideGaze = useAppSelector(state => !state.projectEdit.hideGaze);

  const { t } = useTranslation();

  useEffect(() => {
    if (useVisible.visible !== hideGaze) useVisible.onVisible?.();
  }, [hideGaze, useVisible]);

  return (
    <RowBase
      name="Gaze"
      onVisible={() => {
        useVisible.onVisible?.();
        dispatch.projectEdit.tryUpdateRender(null);
      }}
      visible={useVisible.visible}
      visibleDisabled={disabled}
      showHideButtonTooltip={
        disabled
          ? t("Disabled")
          : useVisible.visible
          ? t("Hide gaze overlay")
          : t("Show gaze overlay")
      }
      gaze
    >
      <MockedLine disabled={disabled} />
    </RowBase>
  );
});

const useBlinksOverlay = (overlay: GazeOverlay[]) => {
  const [visible, setVisible] = useState(gazeOverlay.hideBlinks);

  const onVisible = () => {
    overlay[0].hideBlinks = !visible;
    setVisible(!visible);
  };

  return { visible: !visible, onVisible };
};

const Blinks = memo(() => {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(state => !state.video.hasGaze);
  const useVisible = useBlinksOverlay(getEnrichmentOverlays("gaze") as GazeOverlay[]);
  const hideBlinks = useAppSelector(state => !state.projectEdit.hideBlinks);
  const { t } = useTranslation();

  useEffect(() => {
    if (useVisible.visible !== hideBlinks) useVisible.onVisible?.();
  }, [hideBlinks, useVisible]);

  return (
    <RowBase
      name="Blinks"
      onVisible={() => {
        useVisible.onVisible?.();
        dispatch.projectEdit.tryUpdateRender(null);
      }}
      visible={useVisible.visible}
      visibleDisabled={disabled}
      showHideButtonTooltip={
        disabled
          ? t("Disabled")
          : useVisible.visible
          ? t("Show gaze during blinks")
          : t("Hide gaze during blinks")
      }
    ></RowBase>
  );
});

const TimeLineWrapper = () => {
  const dispatch = useAppDispatch();
  const target = useGesture();

  return (
    <Box>
      <VideoEventsItem
        rightContent={
          <Box display="flex">
            <Box
              ref={target}
              width={LIST_WIDTH_CALC}
              onClick={dispatch.video.setTimeToSelected}
              onMouseMove={dispatch.video.onVideoControlMouseMove}
              onMouseLeave={dispatch.video.clearSelectedPercentage}
              onWheel={dispatch.video.onVideoWheel}
            >
              <VideoTimeline />
            </Box>
            <Box sx={{ backgroundColor: "action.hover", flexGrow: 1 }} />
          </Box>
        }
      />
    </Box>
  );
};

const VideoPositionWrapper = () => {
  return (
    <Box
      id={VIDEO_POSITIONS_ROOT}
      width={LIST_WIDTH_CALC}
      position="absolute"
      height="100%"
      display="flex"
      sx={{ pointerEvents: "none" }}
      zIndex={3}
    >
      <VideoEventsItem rightContent={<VideoPositions />} />
    </Box>
  );
};

const VideoTimelineExtraLines = () => {
  return (
    <Box
      paddingTop="12px"
      width={LIST_WIDTH_CALC}
      position="absolute"
      height="100%"
      display="flex"
      sx={{ pointerEvents: "none" }}
      zIndex={3}
    >
      <VideoEventsItem
        divider
        sx={{ overflow: "hidden", borderRight: "1px solid", borderColor: "divider" }}
      />
    </Box>
  );
};

const VideoTimeLineTemporalSelectionOverlay = () => {
  const location = useLocation();
  const hide =
    !!matchPath(RouterHelper.projectRecordings.fullTemplatePath, location.pathname) ||
    !!matchPath(RouterHelper.workspaceRecordings.fullTemplatePath, location.pathname);
  const temporalSelectionHover = useAppSelector(
    state => state.projectEdit.temporalSelectionHover,
  );
  const from = useAppSelector(state => state.projectEdit.form.from_event_name);
  const to = useAppSelector(state => state.projectEdit.form.to_event_name);
  const events = useAppSelector(state =>
    state.video.recording
      ? state.recordingEvents.eventsByRecordingId.get(state.video.recording.id)
      : null,
  );
  const cutVideoEnrichmentSlices = useAppSelector(
    state => state.app.cutVideoEnrichmentSlices,
  );
  const duration = useInstanceDuration();
  const hasOnHover = temporalSelectionHover.from || temporalSelectionHover.to;

  const hoverFound = useMemo(() => {
    return findAllEventSlices({
      events,
      from,
      to,
      duration,
      selection: temporalSelectionHover,
    });
  }, [events, from, to, duration, temporalSelectionHover]);

  const currentFound = useMemo(() => {
    if (cutVideoEnrichmentSlices) return [];

    return findAllEventSlices({
      events,
      from,
      to,
      duration,
      selection: { from, to },
    });
  }, [events, from, to, duration, cutVideoEnrichmentSlices]);

  if (hide) return null;

  return (
    <Box
      paddingTop={1.5}
      width={LIST_WIDTH_CALC}
      position="absolute"
      height="100%"
      display="flex"
      sx={{ pointerEvents: "none" }}
    >
      <VideoEventsItem
        rightContent={
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              position: "relative",
            }}
          >
            {currentFound.map(
              o =>
                o.start !== undefined &&
                o.end !== undefined &&
                !isNaN(o.start) &&
                !isNaN(o.end) && (
                  <Box
                    key={o.key}
                    sx={{
                      position: "absolute",
                      marginLeft: `${o.start}%`,
                      width: `${o.end - o.start}%`,
                      backgroundColor: hasOnHover ? "#504f5726" : "#3b82f626",
                      height: "100%",
                    }}
                  />
                ),
            )}
            {hasOnHover &&
              hoverFound.map(
                o =>
                  o.start !== undefined &&
                  o.end !== undefined &&
                  !isNaN(o.start) &&
                  !isNaN(o.end) && (
                    <Box
                      key={o.key}
                      sx={{
                        position: "absolute",
                        marginLeft: `${o.start}%`,
                        width: `${o.end - o.start}%`,
                        backgroundColor: "#3b82f626",
                        height: "100%",
                      }}
                    />
                  ),
              )}
          </Box>
        }
      />
    </Box>
  );
};

export const VideoEvents = memo(() => {
  const dispatch = useAppDispatch();
  const rows = useAppSelector(state => state.videoEvents.virtualListRows);
  const resetFromIndex = useAppSelector(state => state.videoEvents.resetFromIndex);
  const scrollToIndex = useAppSelector(state => state.videoEvents.scrollToIndex);
  const videoSize = useAppSelector(
    state => state.dragger.video.size + state.dragger.videoFullScreen.size,
  );
  const fullScreen = useAppSelector(state => state.video.fullScreen);
  const hide = useAppSelector(state => {
    return (
      state.video.fullScreen &&
      state.dragger.videoFullScreen.max - state.dragger.videoFullScreen.size < 8
    );
  });
  const editEvent = useAppSelector(state => state.videoEvents.editEvent);
  const ref = useRef<VariableSizeList | null>(null);
  const [emptyHeight, setEmptyHeight] = useState(0);
  const targetRoot = useGesture();
  const targetEmpty = useGesture();

  useEffect(() => {
    setTimeout(() => {
      const ele = document.getElementById(LIST_ROOT_ID);

      if (!ele) return;

      const max = ele.clientHeight;

      const min = Number(
        ele?.children?.[1]?.children?.[0]?.children?.[0]
          ? (ele.children[1].children[0].children[0] as any).style.height.replace(
              "px",
              "",
            )
          : "0",
      );

      setEmptyHeight(max - min);
    });
  }, [rows, videoSize, fullScreen]);

  useEffect(() => {
    if (ref.current && resetFromIndex !== null) {
      ref.current?.resetAfterIndex(resetFromIndex);
    }
  }, [rows, resetFromIndex]);

  useEffect(() => {
    ref.current?.resetAfterIndex(0);
  }, [videoSize]);

  useEffect(() => {
    if (scrollToIndex) ref.current?.scrollToItem(scrollToIndex + 10);
  }, [scrollToIndex]);

  const getItemSize = (index: number) => {
    return rows[index]?.height ?? 0;
  };

  const scrollTo = (num: number, max: number) => {
    if (ref.current) {
      // @ts-ignore
      const next = ref.current.state.scrollOffset + num;
      if (next > max) return;
      ref.current.scrollTo(next);
    }
  };

  return (
    <Box sx={{ display: hide ? "none" : "flex", flexGrow: 1, flexDirection: "column" }}>
      <Box
        flexGrow={1}
        overflow="hidden"
        position="relative"
        display="flex"
        flexDirection="column"
      >
        <VideoTimelineExtraLines />
        <PlayerContextMenu />
        <VideoTimeLineTemporalSelectionOverlay />
        <TimeLineWrapper />
        <VideoPositionWrapper />

        <Box
          id={LIST_ROOT_ID}
          flexGrow={1}
          position="relative"
          overflow="hidden"
          ref={targetRoot}
        >
          <Thumbnail scrollTo={scrollTo} />

          <AutoSizer disableWidth>
            {({ height }) => {
              return (
                <VariableSizeList
                  overscanCount={5}
                  ref={ref}
                  height={height - HORIZONTAL_SCROLL_HEIGHT || 0}
                  width="100%"
                  itemCount={rows.length}
                  itemSize={getItemSize}
                >
                  {Row}
                </VariableSizeList>
              );
            }}
          </AutoSizer>
        </Box>

        <Box
          id="overlay-over-top"
          sx={{
            width: 5,
            height: 12,
            right: 15,
            position: "absolute",
            pointerEvents: "none",
            backgroundColor: "#252528",
          }}
        />
        <Box
          id="overlay-over-bottom"
          sx={{
            top: 12,
            width: 5,
            height: 45,
            right: 15,
            position: "absolute",
            pointerEvents: "none",
            backgroundColor: "background.default",
          }}
        />
        <Box
          id="empty"
          sx={{
            display: editEvent ? "none" : "auto",
            position: "absolute",
            width: LIST_WIDTH_CALC,
            bottom: 0,
            height: emptyHeight,
            overflow: "hidden",
          }}
        >
          <VideoEventsItem
            ref={targetEmpty}
            onClick={dispatch.video.setTimeToSelected}
            onMouseMove={dispatch.video.onVideoControlMouseMove}
            onMouseLeave={dispatch.video.clearSelectedPercentage}
            onWheel={dispatch.video.onVideoWheel}
          />
        </Box>
      </Box>
      <HorizontalScroll />
    </Box>
  );
});

const HORIZONTAL_SCROLL_HEIGHT = 15;

const HorizontalScroll = () => {
  const dispatch = useAppDispatch();
  const recordingId = useAppSelector(state => state.video.recording?.id);
  const enrichmentId = useAppSelector(state => state.projectEdit.currentEnrichment?.id);
  const zoomMin = useAppSelector(state => state.video.zoom.min);
  const zoomMax = useAppSelector(state => state.video.zoom.max);
  const zoomCurrent = useAppSelector(state => state.video.zoom.current);
  const zoomSteps = useAppSelector(state => state.video.zoom.steps);
  const width = useAppSelector(state => state.video.zoom.css.width);
  const fullScreen = useAppSelector(state => state.video.fullScreen);
  const dragger = useAppSelector(
    state =>
      state.dragger.table.size +
      state.dragger.tableProject.size +
      state.dragger.videoFullScreen.size,
  );
  const target = useGesture();

  useLayoutEffect(() => {
    const resize = () => {
      const root = document.getElementById(HORIZONTAL_SCROLL_ID);

      if (root) {
        // root.style.maxWidth = `calc(${
        //   document.getElementById(VIDEO_TIMELINE_SCROLL_ROOT)?.parentElement
        //     ?.offsetWidth || "0"
        // }px + ${WIDTH_SCROLL_DEFAULT}px)`;

        root.style.maxWidth = "0px";

        setTimeout(() => {
          root.style.maxWidth = `calc(${
            document.getElementById(VIDEO_TIMELINE_SCROLL_ROOT)?.parentElement
              ?.offsetWidth || "0"
          }px + ${WIDTH_SCROLL_DEFAULT}px)`;

          const maxWidthBefore = root.scrollWidth - root.clientWidth;
          const scrollInPercentageBefore = root.scrollLeft / maxWidthBefore;

          setTimeout(() => {
            const maxWidthAfter = root.scrollWidth - root.clientWidth;
            const maxScrollAfter = maxWidthAfter * scrollInPercentageBefore;

            root.scrollBy({ left: maxScrollAfter });
          });
        });
      }
    };

    resize();

    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [fullScreen, recordingId, enrichmentId, dragger, dispatch]);

  useEffect(() => {
    const resize = () => {
      dispatch.video.setZoomCss(undefined);
    };

    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [dispatch]);

  useLayoutEffect(() => {
    if (zoomCurrent !== zoomMin) return;

    const duration = store.getState().video.instance?.duration();

    if (!duration) return;

    const { min, max } = getNumberOfTimesConfigSize(duration);

    dispatch.video.setZoom({
      current: min,
      max,
      min,
    });
  }, [fullScreen, dragger, dispatch, zoomCurrent, zoomMin]);

  const onWheel = (e: WheelEvent<HTMLButtonElement | HTMLDivElement>) => {
    let next = zoomCurrent + (e.deltaY > 0 ? -1 : 1);

    if (next > zoomMax && next !== zoomMax) next = zoomMax;
    else if (next < zoomMin && next !== zoomMin) next = zoomMin;

    dispatch.video.onTimeLineZoom({ value: next });
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        pb: 0.8,
      }}
    >
      <Box
        sx={{
          minWidth: 166,
          height: HORIZONTAL_SCROLL_HEIGHT,
          display: "flex",
          alignItems: "center",
          px: 1,
        }}
        onWheel={onWheel}
      >
        <ZoomOut
          fontSize="small"
          sx={{ color: zoomMax <= 1 ? "action.disabled" : undefined }}
        />
        <Slider
          size="small"
          onChange={(_, value) => {
            dispatch.video.onTimeLineZoom({ value: value as number });
          }}
          onChangeCommitted={() => {
            const active = document?.activeElement;
            if (active && active.tagName === "INPUT" && active instanceof HTMLElement) {
              active.blur();
            }
          }}
          sx={{
            mx: 1,

            "& .MuiSlider-thumb": {
              boxShadow: "none",
              "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                boxShadow: "none",
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                  boxShadow: "none",
                },
              },
            },
          }}
          min={zoomMin}
          max={zoomMax}
          value={zoomCurrent}
          step={zoomSteps}
          disabled={zoomMax === zoomMin}
        />
        <ZoomIn
          fontSize="small"
          sx={{ color: zoomMax <= 1 ? "action.disabled" : undefined }}
        />
      </Box>
      <Box
        ref={target}
        id={HORIZONTAL_SCROLL_ID}
        sx={{
          width: "100%",
          overflowX: "scroll",
          height: HORIZONTAL_SCROLL_HEIGHT,
        }}
        onScroll={e => {
          dispatch.video.setZoomCss(e.currentTarget.scrollLeft);
          dispatch.filmstrip.scroll(e.currentTarget.scrollLeft);
        }}
        onMouseDown={() => {
          setScrollDisabled(true);
        }}
        onMouseUp={() => {
          setScrollDisabled(false);
        }}
      >
        <Box
          sx={{
            width: zoomCurrent === zoomMin ? width : `calc(${width} + 5px)`,
            height: HORIZONTAL_SCROLL_HEIGHT,
            position: "relative",
          }}
        >
          <CurrentPositionTracker />
        </Box>
      </Box>
    </Box>
  );
};

const CurrentPositionTracker = () => {
  const dispatch = useAppDispatch();

  return (
    <Box
      id="CurrentPositionTracker"
      ref={dispatch.video.setCurrentLinePosTrackerRef}
      sx={{
        backgroundColor: "red",
        width: "5px",
        height: "5px",
        visibility: "hidden",
        position: "absolute",
      }}
    />
  );
};

const PlayerContextMenu = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isSystem = useAppSelector(
    state =>
      (state.videoEvents.currentEvent !== null &&
        isSystemEvent(state.videoEvents.currentEvent)) ||
      state.video.hideVideoPlayer,
  );
  const [clear, setClear] = useState(false);
  const disabledPermissionsEdit = disabledByPermission({
    type: DisableByPermissionsType.EDIT,
  });
  const disabledPermissionsDelete = disabledByPermission({
    type: DisableByPermissionsType.DELETE,
  });

  const close = () => {
    setClear(true);
    dispatch.ctxMenu.close(ContextMenuTypes.PLAYER_EVENT);
  };

  const onClose = () => {
    if (clear) return;
    dispatch.videoEvents.setCurrentEvent(null);
  };

  const onOpen = () => {
    setClear(false);
  };

  return (
    <ContextMenu type={ContextMenuTypes.PLAYER_EVENT} onClose={onClose} onOpen={onOpen}>
      <MenuList>
        <CtxMenuItem
          label={t("Jump to event time")}
          onClick={() => {
            dispatch.videoEvents.jumpToEventTime(null);
            close();
          }}
        />
        <CtxMenuItem
          label={t("Move event to playhead")}
          onClick={() => {
            dispatch.videoEvents.updateCurrentEventTime(null);
            close();
          }}
          disabled={disabledPermissionsEdit || isSystem}
          tooltip={
            disabledPermissionsEdit
              ? t("Disabled by permissions")
              : t("Cannot move default event")
          }
        />
        <CtxMenuItem
          label={t("Rename")}
          onClick={() => {
            dispatch.videoEvents.setEditEvent(undefined);
            close();
          }}
          disabled={disabledPermissionsEdit || isSystem}
          tooltip={
            disabledPermissionsEdit
              ? t("Disabled by permissions")
              : t("Cannot rename default event")
          }
          divider
        />
        <CtxMenuItem
          label={t("Delete event")}
          onClick={() => {
            close();
            dispatch.questionDialog.set({
              type: QuestionDialogTypes.EVENT_DELETE,
              onSuccess: () => {
                dispatch.videoEvents.deleteEvent(null);
              },
            });
          }}
          disabled={disabledPermissionsDelete || isSystem}
          tooltip={
            disabledPermissionsEdit
              ? t("Disabled by permissions")
              : t("Cannot delete default event")
          }
        />
      </MenuList>
    </ContextMenu>
  );
};
