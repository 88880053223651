import { RecordingFile } from "@api";
import { Button, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { ChangeEvent, useEffect } from "react";

export const UploadButton = ({
  text = "Upload",
  recordingId,
  onSuccess,
  disabled,
  offType = false,
  disabledTitle,
}: {
  text?: string;
  recordingId?: string;
  onSuccess: (file: RecordingFile) => void;
  disabled?: boolean;
  offType?: boolean;
  disabledTitle?: string;
}) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(state => state.uploader.loading);

  useEffect(() => {
    return () => {
      dispatch.uploader.setFile(undefined);
      dispatch.uploader.setBase64(undefined);
    };
  }, [dispatch]);

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];

    if (file) {
      dispatch.projectEdit.setCurrentMarkerLess(undefined);
      const uploadedFile = await dispatch.uploader.upload({ recordingId, file });

      if (uploadedFile) onSuccess(uploadedFile);
    }
  };

  return (
    <Tooltip title={disabledTitle}>
      <span>
        <Button
          component="label"
          disabled={isLoading || disabled}
          variant={offType ? "text" : undefined}
          color={offType ? "primary" : undefined}
        >
          <input
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            hidden
            onChange={onChange}
          />
          {text}
        </Button>
      </span>
    </Tooltip>
  );
};
