import {
  createGlobalModalRoute,
  CtxMenuItem,
  CtxMenuLabel,
  CtxNestedMenu,
  GlobalModalTypes,
  ProjectsAsList,
} from "@components";
import { ModalIds } from "@components/onboarding/OnboardingConfig";
import { Add } from "@mui/icons-material";
import { MenuList } from "@mui/material";
import { RouterHelper } from "@pages";
import {
  ContextMenuTypes,
  QuestionDialogTypes,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import { DisableByPermissionsType, disabledByPermission } from "@utils";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { BreadcrumbsPopover } from "./BreadcrumbsPopover";

const filterType = ContextMenuTypes.PROJECT_MENU;
const FOCUS_INPUT = "project_list_id";

export const ProjectBreadcrumb = () => {
  const navigate = useNavigate();
  const { workspaceId, projectId } = useParams();
  const { t } = useTranslation();
  const currentProject = useAppSelector(state => state.app.currentProject);
  const selectedIds = useAppSelector(state => state.entityTable.selectedIds);
  const isInactive = useAppSelector(state => state.entityTable.quickFilter.isInactive);
  const dispatch = useAppDispatch();
  const disabledPermissionsCreate = disabledByPermission({
    type: DisableByPermissionsType.CREATE,
  });
  const disabledPermissionsEdit = disabledByPermission({
    type: DisableByPermissionsType.EDIT,
  });
  const disabledPermissionsDelete = disabledByPermission({
    type: DisableByPermissionsType.DELETE,
  });

  const close = () => {
    dispatch.ctxMenu.closeAll();
  };

  return (
    <BreadcrumbsPopover
      id={ModalIds.PROJECTS}
      to={
        currentProject && projectId
          ? RouterHelper.projectRecordings.getFullPath({
              props: { workspaceId, projectId },
            })
          : undefined
      }
      text={currentProject?.name || t("Select a project")}
      filterType={filterType}
    >
      <MenuList id={ModalIds.PROJECTS_MODAL}>
        <CtxNestedMenu
          onClose={() => {
            document.getElementById(FOCUS_INPUT)?.focus();
          }}
          CtxMenuItemProps={{
            label: t("Add to project"),
            disabled:
              disabledPermissionsEdit ||
              selectedIds.length === 0 ||
              !!projectId ||
              isInactive,
            tooltip: disabledPermissionsEdit
              ? t("Disabled by permissions")
              : projectId
              ? t("Unable to add recordings")
              : isInactive
              ? t("Unable to add trashed recordings to project")
              : t("No recordings selected"),
          }}
        >
          <MenuList onKeyDown={e => e.stopPropagation()}>
            <ProjectsAsList
              filterType={filterType}
              onClick={dispatch.projectFilterRecordings.findFilteredAdd}
            />
          </MenuList>
        </CtxNestedMenu>
        <CtxMenuItem
          label={t("Create project with selection")}
          disabled={
            disabledPermissionsCreate ||
            selectedIds.length === 0 ||
            !!projectId ||
            isInactive
          }
          tooltip={
            disabledPermissionsCreate
              ? t("Disabled by permissions")
              : projectId
              ? t("Unable to create projects")
              : isInactive
              ? t("Unable to add trashed recordings to project")
              : t("No recordings selected")
          }
          onClick={() => dispatch.ctxMenu.close(filterType)}
          to={createGlobalModalRoute(GlobalModalTypes.PROJECT_CREATE_SELECTION)}
          rightIcon={<Add />}
        />
        <CtxNestedMenu
          CtxMenuItemProps={{
            label: t("Project Settings"),
            disabled: !projectId,
            tooltip: t("Only available in project view"),
            divider: true,
          }}
        >
          <MenuList>
            <CtxMenuItem
              label={t("Rename project")}
              onClick={close}
              to={createGlobalModalRoute(GlobalModalTypes.PROJECT_EDIT)}
              disabled={disabledPermissionsEdit}
              tooltip={t("Disabled by permissions")}
            />
            <CtxMenuItem
              label={t("Delete project")}
              onClick={() => {
                if (!projectId) return;

                close();
                dispatch.questionDialog.set({
                  type: QuestionDialogTypes.PROJECTS_DELETE,
                  onSuccess: async () => {
                    await dispatch.projects.delete([projectId]);
                    navigate(
                      RouterHelper.workspaceRecordings.getFullPath({
                        props: { workspaceId },
                      }),
                    );
                  },
                  extra: {
                    count: 1,
                  },
                });
              }}
              disabled={disabledPermissionsDelete}
              tooltip={t("Disabled by permissions")}
            />
          </MenuList>
        </CtxNestedMenu>
        <CtxMenuLabel secondary={t("SWITCH PROJECTS")} />
        <CtxMenuLabel onKeyDown={e => e.stopPropagation()} sx={{ m: 0 }}>
          <ProjectsAsList filterType={filterType} id={FOCUS_INPUT} />
        </CtxMenuLabel>
      </MenuList>
    </BreadcrumbsPopover>
  );
};
