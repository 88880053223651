import { CheckBoxChecked, CheckBoxIndeterminate } from "@customIcons";
import { PaletteMode } from "@mui/material";
import { PaletteOptions, ThemeOptions, createTheme } from "@mui/material/styles";
import { GridExpandMoreIcon } from "@mui/x-data-grid-pro";
import { customColors } from "@styles/radix";
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from "react-router-dom";

import { PropsWithChildren } from "react";

// adding typing for the customColors in the theme ref
declare module "@mui/material/styles" {
  interface Palette {
    customColors: typeof customColors;
  }
  interface PaletteOptions {
    customColors: typeof customColors;
  }
  interface TypographyVariants {
    body3: React.CSSProperties;
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }>
>((props, ref) => {
  const { href, children, ...other } = props;
  const location = useLocation();

  if ((href as string).includes("https://")) {
    return (
      <a target="_blank" rel="noreferrer" href={href as string} {...other}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink ref={ref} to={href} state={{ previous: location }} {...other}>
      {children}
    </RouterLink>
  );
});

// palette values for light mode
const lightMode: PaletteOptions = {
  customColors,
};
// palette values for dark mode
const darkMode: PaletteOptions = {
  customColors,
  text: {
    primary: "#EDEDEF", //customColors.light5,
    secondary: "#A09FA6",
    disabled: "#504F57",
  },
  primary: {
    main: "#6D7BE0", //customColors.plue2,
    dark: "#7986E2",
    light: "#A09FA61A",
    contrastText: "#EDEDEF",
  },
  secondary: {
    main: "#2E2E32", //customColors.plue5,
    dark: "#34343A",
    light: "#A09FA61A",
    contrastText: "#A09FA6",
  },
  error: {
    main: "#F44336",
    dark: "#D42D21",
    light: "#A09FA61A",
    contrastText: "#EDEDEF",
  },
  warning: {
    main: "#FF960E",
    dark: "#CA7A1C",
    light: "#A09FA61A",
    contrastText: "#EDEDEF",
  },
  success: {
    main: "#0AB42C",
    dark: "#049320",
    light: "#A09FA61A",
    contrastText: "#EDEDEF",
  },
  background: {
    default: "#1C1C1F", //customColors.mauve2,
    paper: "#1C1C1F", //customColors.mauve4,
  },
  action: {
    hover: "#FFFFFF0a",
    selected: "#FFFFFF14",
    active: "#FFFFFF8F",
    focus: "#FFFFFF1F",
    disabled: "#FFFFFF4D",
    disabledBackground: "#FFFFFF1F",
  },
  divider: "#FFFFFF0F", //customColors.mauve2,
};

const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  components: {
    MuiAppBar: {
      defaultProps: {
        position: "sticky",
      },
      styleOverrides: {
        root: {
          // carful when removing maxHeight
          // needed for content hight calculation
          maxHeight: 48,
          backgroundColor: customColors.mauve1,
          boxShadow: "none",
          backgroundImage: "none",
          borderBottom: "1px solid black",
          borderColor: darkMode.action?.selected,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        // bad @mui types
        //@ts-ignore
        component: LinkBehavior,
        color: "inherit",
        underline: "none",
      },
    },
    // MuiLoadingButton: {
    //   defaultProps: {
    //     variant: "outlined",
    //   },
    // },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        LinkComponent: LinkBehavior,
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        color: "secondary",
      },
      styleOverrides: {
        root: {
          boxShadow: "none",

          ":hover": {
            boxShadow: "none",
          },
        },
        contained: {
          "&.Mui-disabled": {
            backgroundColor: "#343437",
          },
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: false,
        variant: "contained",
        color: "secondary",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
        InputLabelProps: {
          shrink: true,
          color: "primary",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit",

          ":hover, :focus,:not(:focus)": {
            backgroundColor: "inherit",
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
        IconComponent: GridExpandMoreIcon,
      },
      styleOverrides: {
        standard: {
          backgroundColor: "inherit",
          paddingTop: 8,

          ":hover, :focus,:not(:focus)": {
            backgroundColor: "inherit",
          },

          "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        //color: "primary",
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        autoComplete: "off",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiInput: {
      defaultProps: {
        //disableUnderline: true,
      },
    },
    MuiMenuList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiChip: {
      defaultProps: {
        variant: "filled",
        size: "medium",
      },
      styleOverrides: {
        root: {
          borderRadius: "4px",
          gap: "4px",
          padding: "4px 8px",
          textOverflow: "ellipsis",
          maxWidth: "300px",
          whiteSpace: "nowrap",
        },
        icon: {
          margin: "unset",
        },
        deleteIcon: {
          margin: "unset",
        },
        avatar: {
          margin: "unset",
        },
        label: {
          padding: "unset",
          lineHeight: "1",
        },
        sizeMedium: {
          height: "24px",
        },
        sizeSmall: {
          height: "16px",
          padding: "4px",
        },
        deleteIconMedium: {
          fontSize: "18px",
        },
        deleteIconSmall: {
          fontSize: "14px",
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: { elevation: 1 },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <CheckBoxChecked />,
        indeterminateIcon: <CheckBoxIndeterminate />,
      },
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "#707178",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          display: "flex",
          "& .MuiSwitch-switchBase": {
            padding: 2,
            "&.Mui-checked": {
              color: "#fff",
              "& + .MuiSwitch-track": {
                opacity: 1,
                borderColor: "transparent",
              },
              "&.Mui-disabled": {
                color: customColors.light2,

                "& + .MuiSwitch-track": {
                  backgroundColor: customColors.mauve6,
                },
              },
            },
            "&.Mui-disabled": {
              color: customColors.light2,

              "& + .MuiSwitch-track": {
                borderColor: customColors.mauve6,
              },
            },
          },
          "& .MuiSwitch-thumb": {
            boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
            transition: createTheme().transitions.create(["width"], {
              duration: 200,
            }),
          },
          "& .MuiSwitch-track": {
            border: "1px solid",
            borderColor: darkMode.action?.selected,
            opacity: 1,
            backgroundColor: "transparent",
            boxSizing: "border-box",
          },
        },
        sizeSmall: {
          width: 20,
          height: 12,
          "&:active": {
            "& .MuiSwitch-thumb": {
              width: 11,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
              transform: "translateX(6px)",
            },
          },
          "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
              transform: "translateX(8px)",
            },
          },
          "& .MuiSwitch-thumb": {
            width: 8,
            height: 8,
            borderRadius: 4,
          },
          "& .MuiSwitch-track": {
            borderRadius: 12 / 2,
          },
        },
        sizeMedium: {
          width: 28,
          height: 16,
          "&:active": {
            "& .MuiSwitch-thumb": {
              width: 15,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
              transform: "translateX(9px)",
            },
          },
          "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
              transform: "translateX(12px)",
            },
          },
          "& .MuiSwitch-thumb": {
            width: 12,
            height: 12,
            borderRadius: 6,
          },
          "& .MuiSwitch-track": {
            borderRadius: 16 / 2,
          },
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          backgroundImage: "none",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 1,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#353538",
        },
        arrow: {
          color: "#353538",
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          borderRadius: "50%",
          boxShadow: `inset 0 0 0 1px #EDEDEF4D`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "#FFFFFF4D",
            "& .MuiSlider-thumb": {
              color: "#504F57",
            },
          },
        },
      },
    },
  },

  typography: {
    allVariants: {
      textTransform: "none",
    },
    fontFamily: ['"Inter"', '"Roboto"', '"Arial"', "sans-serif"].join(","),
    h1: {
      fontSize: "1.7rem",
      lineHeight: "102px",
    },
    h2: {
      fontSize: "1.4rem",
      lineHeight: "64px",
    },
    h3: {
      fontSize: "1.1rem",
      lineHeight: "51px",
    },
    h4: {
      lineHeight: "36px",
    },
    h5: {
      lineHeight: "28px",
    },
    h6: {
      lineHeight: "24px",
    },
    subtitle1: {
      lineHeight: "20px",
    },
    subtitle2: {
      lineHeight: "16px",
    },
    body1: {
      lineHeight: "20px",
    },
    body2: {
      lineHeight: "16px",
    },
    body3: {
      fontSize: "0.785rem",
      lineHeight: "16px",
    },
    button: {
      lineHeight: "16px",
    },
    caption: {
      lineHeight: "12px",
    },
    overline: {
      lineHeight: "12px",
    },
  },

  palette: {
    mode,
    ...(mode === "light" ? lightMode : darkMode),
  },
});

const darkModeThemeCustom = createTheme(getDesignTokens("dark"));
const lightModeThemeCustom = createTheme(getDesignTokens("light"));

const darkModeThemeDefault = createTheme({
  palette: {
    mode: "dark",
    customColors,
  },
});

const lightModeThemeDefault = createTheme({
  palette: {
    mode: "light",
    customColors,
  },
});

export const themes = {
  dark: {
    default: darkModeThemeDefault,
    custom: darkModeThemeCustom,
  },
  light: {
    default: lightModeThemeDefault,
    custom: lightModeThemeCustom,
  },
};
