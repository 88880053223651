import {
  AoiDrawArea,
  AoiDrawToolbar,
  Centered,
  ContextMenu,
  CtxMenuItem,
  Loader,
} from "@components";
import { Add } from "@mui/icons-material";
import { Box, Button, Divider, MenuList, Tooltip, Typography } from "@mui/material";
import {
  ContextMenuTypes,
  QuestionDialogTypes,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import { DisableByPermissionsType, disabledByPermission } from "@utils";
import { FC, PropsWithChildren, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AreaList } from "./AreaList";

const HEADER_HEIGHT = 56;

export const ProjectEnrichmentsAOI: FC = () => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(state => state.loading.effects.aoiTool.get.loading);
  const imageNotAvailable = useAppSelector(state => state.aoiTool.imageNotAvailable);
  const disabledPermissions = disabledByPermission({
    type: DisableByPermissionsType.CREATE,
  });

  useEffect(() => {
    if (params.enrichmentId) {
      dispatch.aoiTool.get(params.enrichmentId);
    }

    return () => {
      dispatch.aoiTool.reset();
      dispatch.aoiAreas.reset();
      dispatch.aoiStats.reset();
      dispatch.projectEdit.reset();
    };
  }, [dispatch, params]);

  if (loading) {
    return (
      <Centered>
        <Loader />
      </Centered>
    );
  }

  if (imageNotAvailable) {
    return (
      <Centered>
        <Typography sx={{ m: "auto" }}>{t("Image not found")}</Typography>
      </Centered>
    );
  }

  return (
    <>
      <Box sx={{ height: HEADER_HEIGHT, width: "100%", display: "flex" }}>
        <BlockSplit
          left={
            <Header>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography variant="body1">{t("Areas")}</Typography>
                <Tooltip
                  title={
                    <Typography variant="body2" color="text.secondary">
                      or press N
                    </Typography>
                  }
                  placement="right"
                >
                  <span style={{ marginLeft: "auto" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<Add />}
                      onClick={dispatch.aoiAreas.create}
                      disabled={disabledPermissions}
                    >
                      {t("Add")}
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </Header>
          }
          right={
            <Header>
              <AoiDrawToolbar />
            </Header>
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          width: "100%",
          height: `calc(100% - ${HEADER_HEIGHT}px)`,
          flexGrow: 1,
        }}
      >
        <BlockSplit
          left={
            <>
              <Box
                sx={{
                  // Box + marginBottom
                  height: `calc(100% - 16px - 10px)`,
                  mt: 2,
                  width: "100%",
                }}
              >
                <AreaList />
              </Box>
            </>
          }
          right={<AoiDrawArea />}
        />
      </Box>
      <AoiContextMenu />
    </>
  );
};

const BlockSplit = ({ left, right }: { left: ReactNode; right: ReactNode }) => {
  return (
    <>
      <Box sx={{ minWidth: 294 }}>{left}</Box>
      <Divider orientation="vertical" />
      <Box sx={{ flex: 1 }}>{right}</Box>
    </>
  );
};

const Header: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: 56,
          px: 2,
          py: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
      <Divider />
    </>
  );
};

const AoiContextMenu = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const current = useAppSelector(state => state.aoiAreas.current);
  const selectedLength = useAppSelector(state => state.aoiAreas.selectedIds.size);

  const disabledPermissionsDelete = disabledByPermission({
    type: DisableByPermissionsType.DELETE,
  });

  const close = () => {
    dispatch.ctxMenu.close(ContextMenuTypes.AOI_LIST);
  };

  return (
    <ContextMenu type={ContextMenuTypes.AOI_LIST}>
      <MenuList>
        <CtxMenuItem
          label={t(
            selectedLength > 1 ? `Delete ${selectedLength} areas` : "Delete area",
          )}
          onClick={() => {
            close();
            dispatch.questionDialog.set(
              selectedLength > 1
                ? {
                    type: QuestionDialogTypes.AOIS_DELETE,
                    onSuccess: () => dispatch.aoiAreas.delete(null),
                    extra: {
                      count: selectedLength,
                    },
                  }
                : {
                    type: QuestionDialogTypes.AOI_DELETE,
                    onSuccess: () => dispatch.aoiAreas.delete(null),
                    extra: {
                      title: current ? current.name : "",
                    },
                  },
            );
          }}
          disabled={disabledPermissionsDelete}
          tooltip={disabledPermissionsDelete ? t("Disabled by permissions") : undefined}
          divider
        />
        <CtxMenuItem
          label={t("Select all")}
          onClick={() => {
            close();
            setTimeout(() => dispatch.aoiAreas.selectAll(null));
          }}
        />
      </MenuList>
    </ContextMenu>
  );
};
