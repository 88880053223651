import { ErrorPage } from "@components";
import { useAppSelector } from "@storeRematch";
import { useNavigate, useParams } from "react-router-dom";
import { WorkspaceForm } from "./WorkspaceForm";

export const WorkspaceEdit = () => {
  const navigate = useNavigate();

  const params = useParams();
  const workspaceId = String(params.workspaceId);
  const workspace = useAppSelector(
    state => state.app.currentWorkspaceMembership?.workspace,
  );
  const isLoading = useAppSelector(
    state => state.loading.effects.app.loadWorkspaceData.loading,
  );
  if (!workspace && !isLoading) {
    return <ErrorPage title={`Workspace ${workspaceId} not found in this account`} />;
  }

  return (
    <WorkspaceForm workspace={workspace} onClose={() => navigate(-1)} open={true} />
  );
};
