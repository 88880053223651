import {
  createGlobalModalRoute,
  CtxMenuItem,
  CtxMenuLabel,
  GlobalModalTypes,
  VirtualizedList,
} from "@components";
import { SearchBox } from "@components/Common/SearchBox";
import { ModalIds } from "@components/onboarding/OnboardingConfig";
import { Add, Check } from "@mui/icons-material";
import { MenuList } from "@mui/material";
import { Box } from "@mui/system";
import { RouterHelper } from "@pages";
import { ContextMenuTypes, useAppDispatch, useAppSelector } from "@storeRematch";
import { sanitizeForRegex } from "@utils";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BreadcrumbsPopover } from "./BreadcrumbsPopover";

const filterType = ContextMenuTypes.WORKSPACE_SETTINGS;

export const WorkspaceBreadcrumb = ({ active }: { active: boolean }) => {
  const dispatch = useAppDispatch();
  const { workspaceId } = useParams();
  const { t } = useTranslation();
  const currentWorkspace = useAppSelector(
    state => state.app.currentWorkspaceMembership,
  );
  const workspaceMemberships = useAppSelector(state => state.workspaceMemberships.data);
  const [found, setFound] = useState({ text: "", data: workspaceMemberships || [] });
  const closed = useAppSelector(state => !state.ctxMenu.open[filterType]);

  useEffect(() => {
    if (closed) {
      setFound({ text: "", data: workspaceMemberships });
    }
  }, [closed, workspaceMemberships]);

  useEffect(() => {
    if (workspaceMemberships) {
      setFound({ text: "", data: workspaceMemberships });
    }
  }, [workspaceMemberships, setFound]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const nextFound = workspaceMemberships?.filter(
      workspaceMembership =>
        workspaceMembership.workspace.name.match(
          new RegExp(sanitizeForRegex(e.currentTarget.value), "i"),
        ) ||
        workspaceMembership.workspace.id.match(
          new RegExp(sanitizeForRegex(e.currentTarget.value), "i"),
        ),
    );
    setFound({ text: e.currentTarget.value, data: nextFound });
  };

  return (
    <BreadcrumbsPopover
      id={ModalIds.DEMO_WORKSPACE}
      to={
        currentWorkspace
          ? RouterHelper.workspaceRecordings.getFullPath({
              props: { workspaceId: currentWorkspace.workspace.id },
            })
          : undefined
      }
      text={currentWorkspace?.workspace.name || t("Select a workspace")}
      filterType={filterType}
      active={active}
    >
      <MenuList id={ModalIds.DEMO_WORKSPACE_MODAL}>
        <CtxMenuItem
          to={
            currentWorkspace
              ? RouterHelper.workspaceSettingsMembers.getFullPath({
                  props: { workspaceId: currentWorkspace.workspace.id },
                })
              : undefined
          }
          label={t("Invite collaborators")}
          disabled={!currentWorkspace}
          tooltip={t("Workspace not selected")}
        />
        <CtxMenuItem
          to={
            currentWorkspace
              ? RouterHelper.workspaceSettingsLayout.getFullPath({
                  props: { workspaceId: currentWorkspace.workspace.id },
                })
              : undefined
          }
          label={t("Workspace settings")}
          disabled={!currentWorkspace}
          tooltip={t("Workspace not selected")}
        />
        <CtxMenuItem
          onClick={() => dispatch.ctxMenu.close(filterType)}
          to={createGlobalModalRoute(GlobalModalTypes.WORKSPACE_CREATE)}
          label={t("Create new workspace")}
          rightIcon={<Add />}
          divider
        />
        <CtxMenuLabel secondary={t("SWITCH WORKSPACE")} />
        <CtxMenuLabel onKeyDown={e => e.stopPropagation()}>
          <SearchBox
            sx={{ width: "250px" }}
            name="Workspace settings search"
            onChange={handleSearch}
            onClear={() => {
              setFound({ text: "", data: workspaceMemberships });
            }}
          />
        </CtxMenuLabel>

        {!found.data.length && <CtxMenuItem label={t("No workspace")} disabled />}

        <Box height={found.data.length * 33} maxHeight="40vh">
          <VirtualizedList
            itemData={found.data}
            itemKey={(index, data) => data[index].workspace.id}
            itemSize={33}
            renderRow={({ index, style, data }) => (
              <CtxMenuItem
                key={index}
                style={style}
                to={RouterHelper.workspaceRecordings.getFullPath({
                  props: { workspaceId: data[index].workspace.id },
                })}
                label={data[index].workspace.name}
                subLabel={data[index].workspace.id}
                boldText={found.text}
                defaultTooltip
                rightIcon={
                  data[index].workspace.id === workspaceId ? <Check /> : undefined
                }
              />
            )}
          />
        </Box>
      </MenuList>
    </BreadcrumbsPopover>
  );
};
