import { createContext, FC, PropsWithChildren, useContext } from "react";

export function createContextWrapper<T>(
  useValue: () => T,
  naming = { provider: "Provider", useContext: "useContext" },
): [FC<PropsWithChildren>, () => T] {
  const Context = createContext<T | null>(null);

  const useContextWrapper = () => {
    const myContext = useContext(Context);

    if (!myContext) {
      throw new Error(`${naming.useContext} must be used within a ${naming.provider}`);
    }
    return myContext;
  };

  const Provider: FC<PropsWithChildren> = ({ children }) => {
    return <Context.Provider value={useValue()}>{children}</Context.Provider>;
  };

  return [Provider, useContextWrapper];
}
