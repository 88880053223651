import { AppContent, AppLayout, UnAuthenticatedRoute } from "@components";
import { Box } from "@mui/system";
import { RouterHelper } from "@pages";
import { useAppSelector } from "@storeRematch";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const AuthenticatedRoute: React.FC = () => {
  const user = useAppSelector(state => state.app.apiUser);
  const { pathname, search } = useLocation();

  if (!user) {
    // TODO(dan): add notification/snack message here eg.
    // notifications.emit('warning', 'This page requires logging in')
    return (
      <Navigate
        to={RouterHelper.signIn.getRelativePath({
          suffix: `?redirect=${pathname + encodeURIComponent(search)}`,
        })}
        replace
      />
    );
  }

  if (document.URL.includes("accept/invite")) {
    return <UnAuthenticatedRoute disableBorder />;
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <AppLayout />
      <AppContent>
        <Outlet />
      </AppContent>
    </Box>
  );
};
