import { styled } from "@mui/material";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";
import { LocalStorageAdapterNames } from "@utils";
import React from "react";

export interface EntityDataTableProps extends DataGridProProps {
  noSearch?: boolean;
  noColumnFilter?: boolean;
  noFilters?: boolean;
  noContextMenu?: boolean;
  titleComponent?: JSX.Element;
  toolbarPadding?: boolean;
  noResetSelected?: boolean;
  singleSelect?: boolean;
  outlet?: boolean;
  disableSelect?: boolean;
  onCustomSelect?: (id: string) => void;
  noDataMessage: React.ReactNode;
  filterLocalStorageKey?: LocalStorageAdapterNames;
  sortLocalStorageKey?: LocalStorageAdapterNames;
  disableSelectModel?: boolean;
  cssHeight?: string;
  cssHeaderBackGround?: string;
}

export const StyledDataGrid = styled(DataGridPro, {
  shouldForwardProp: prop => prop !== "selectedId" && prop !== "hideScrollbar",
})<{ selectedId: string; hideScrollbar: boolean; cssHeaderBackGround?: string }>(
  ({ selectedId, hideScrollbar, cssHeaderBackGround, theme }) => {
    const focusedRowBorderColor =
      theme.palette.mode === "light" ? "#deadbeef" : theme.palette.primary.main;

    return {
      height: "100%",
      border: "none",
      "& .MuiDataGrid-virtualScroller": {
        overflow: hideScrollbar ? "hidden" : "auto",
      },

      // remove focused cell border
      "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
      // add focused row border
      "& .MuiDataGrid-row": {
        border: `1px solid transparent`,
      },
      "& .MuiDataGrid-row:focus-within": {
        border: `1px solid ${focusedRowBorderColor} !important`,
      },
      // reorder header button/text
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        flexDirection: "row !important",
      },
      // not sure
      "& .MuiDataGrid-columnHeaderTitleContainerContent": {
        marginRight: 1,
      },
      // remove border under column headers
      "& .MuiDataGrid-topContainer::after": {
        backgroundColor: "transparent",
      },
      // remove border under last element
      "& .MuiDataGrid-filler > div": {
        border: "none",
      },
      // remove border from cells
      "& .MuiDataGrid-cell": {
        border: "none",
      },
      // make footer shorter
      "& .MuiDataGrid-footerContainer": {
        minHeight: 32,
      },
      // selected row background color
      [`& .MuiDataGrid-row.Mui-selected[data-id='${selectedId}']`]: {
        backgroundColor: "rgb(255 255 255 / 14%) !important",
      },
      "& .MuiDataGrid-row.Mui-selected": {
        backgroundColor:
          theme.palette.mode === "light"
            ? "#deadbeef"
            : "rgb(255 255 255 / 8%) !important",
      },
      "& .MuiDataGrid-row.Mui-selected:hover": {
        backgroundColor: "rgb(255 255 255 / 20%) !important",
      },
      "& .MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--borderTop": {
        border: "none",
      },
      "& .MuiDataGrid-topContainer > div > div": {
        backgroundColor: cssHeaderBackGround
          ? `${cssHeaderBackGround} !important`
          : undefined,
      },

      // column header border
      // "& .MuiDataGrid-columnHeader:target, & .MuiDataGrid-columnHeader:focus-within":
      //   {
      //     outline: "none",
      //   },
    };
  },
);
