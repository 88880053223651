import {
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential,
} from "@firebase/auth";
import { RouterHelper } from "@pages";
import { auth, googleProvider } from "@services/firebase";
import { Dispatch, EmailPasswordCredentials } from "@storeRematch";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { localStorageAdapter, LocalStorageAdapterNames } from "./localStorageAdapter";

export const useNavigateToEmailVerified = () => {
  const [navigate, setNavigate] = useState(false);

  return {
    Navigate: navigate ? (
      <Navigate to={RouterHelper.verifyEmail.getFullPath({ prefix: "../" })} replace />
    ) : null,
    setNavigate: async (promise: Promise<false | undefined>) =>
      (await promise) === false ? setNavigate(true) : null,
  };
};

export const googleGroupedLogin = async (
  dispatch: Dispatch,
  credentials?: EmailPasswordCredentials,
  register = false,
): Promise<false | undefined> => {
  try {
    dispatch.app.setAuthLoading(true);
    let cred: UserCredential | null = null;

    if (credentials) {
      cred = await signInWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password,
      );
    } else {
      cred = await signInWithPopup(auth, googleProvider);
    }

    const isEmailVerified = cred.user.emailVerified;
    if (!isEmailVerified) {
      localStorageAdapter.set(
        LocalStorageAdapterNames.unVerifiedEmail,
        cred.user.email,
      );
      await dispatch.app.finalizeAuth({ refresh: false, register });
      return false;
    }

    dispatch.app.finalizeAuth({ refresh: false, register });
  } catch {
    dispatch.app.setAuthLoading(false);
  }
};
