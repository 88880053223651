import { Project } from "@api";
import { createModel } from "@rematch/core";
import { filterCreateProjectRecordingsList } from "@utils";
import { ProjectFilterRecordingsType, RecordingWithData, RootModel } from "./index";

interface InitialState {
  filtered: RecordingWithData[];
  showCheck: boolean;
  type: ProjectFilterRecordingsType | null;
  project: Project | null;
}

const initialState: InitialState = {
  filtered: [],
  showCheck: false,
  type: null,
  project: null,
};

export const projectFilterRecordings = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setFiltered(state, data: InitialState["filtered"]) {
      state.filtered = data;
    },
    setShowCheck(state, data: InitialState["showCheck"]) {
      state.showCheck = data;
    },
    setType(state, data: InitialState["type"]) {
      state.type = data;
    },
    setProject(state, data: InitialState["project"]) {
      state.project = data;
    },
    reset() {
      return { ...initialState };
    },
  },
  effects: dispatch => ({
    findFilteredCreate(_) {
      dispatch.projectFilterRecordings.setType(ProjectFilterRecordingsType.CREATE);
      dispatch.projectFilterRecordings.setFiltered(filterCreateProjectRecordingsList());
    },
    findFilteredAdd(project: Project) {
      const next = filterCreateProjectRecordingsList();

      if (!next.length) {
        dispatch.projects.addRecordingsToProject(project);
        return;
      }

      dispatch.projectFilterRecordings.setProject(project);
      dispatch.projectFilterRecordings.setType(ProjectFilterRecordingsType.ADD);
      dispatch.projectFilterRecordings.setFiltered(next);
    },
    addToProject(_, state) {
      const project = state.projectFilterRecordings.project;

      if (!project) return;

      dispatch.projects.addRecordingsToProject(project);
      dispatch.projectFilterRecordings.reset();
    },
  }),
});
