import { WorkspaceMembership } from "@api";
import { ReactRouterLinkCustom } from "@components";
import { Loader } from "@components/Loader";
import { Box, Button, Typography } from "@mui/material";
import { RouterHelper } from "@pages";
import { useAppDispatch } from "@storeRematch/index";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const AcceptInvite = () => {
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [membership, setMembership] = useState<WorkspaceMembership | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!token) return;

    dispatch.app
      .acceptInvite(token)
      .then(r => {
        setMembership(r);
        setDone(true);
      })
      .catch(() => setError(true));
  }, [token, dispatch]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {error ? (
        <>
          <Typography variant="h6">Unable to accept invitation</Typography>
          <Typography sx={{ mt: 1.5 }}>
            This workspace invitation has already been accepted or has expired.
          </Typography>
        </>
      ) : done ? (
        <>
          <Typography variant="h6">Invitation accepted</Typography>
          <Typography sx={{ mt: 1.5 }}>
            Your invitation to {membership?.workspace.name} workspace has been accepted.
          </Typography>
        </>
      ) : (
        <Loader />
      )}

      {(done || error) && (
        <Button
          component={ReactRouterLinkCustom}
          to={
            done && membership
              ? RouterHelper.workspaceRecordings.getFullPath({
                  prefix: "../",
                  props: { workspaceId: membership.workspace.id },
                })
              : "/"
          }
          sx={{ mt: 2, mx: "auto" }}
          color="primary"
        >
          {done ? "Go to workspace" : "Go to your default workspace"}
        </Button>
      )}
    </Box>
  );
};
