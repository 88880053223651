import { HeatMapLegend } from "@components";
import { useWindowDimensions } from "@hooks/useWindowDimensions";
import { Box } from "@mui/system";
import { AoiTool, useAppDispatch, useAppSelector } from "@storeRematch";
import {
  KONVA_ADD_COLOR,
  KONVA_CIRCLE_HIT_RADIUS,
  KONVA_CIRCLE_RADIUS,
  KONVA_ERASE_COLOR,
  KONVA_OFF_COLOR,
  calculateAspectRatioFit,
  isLineDrawn,
} from "@utils";
import { FC, useEffect, useRef, useState } from "react";
import { Circle, Group, Image, Layer, Line, Stage } from "react-konva";
import { AreaLayersBase } from "./AreaLayersBase";
import { DEFAULT_OPACITY } from "./helpers";

let prevDim = { width: 2, height: 2 };

export const AoiDrawArea: FC<{ visualizationId?: string }> = ({ visualizationId }) => {
  const disabled = !!visualizationId;
  const windowResize = useWindowDimensions();
  const [dim, setDim] = useState({ ...prevDim });

  const dispatch = useAppDispatch();
  const imageUrl = useAppSelector(state => state.aoiTool.imageUrl);
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    document.addEventListener("click", dispatch.aoiTool.deselectAreas);

    return () => {
      document.removeEventListener("click", dispatch.aoiTool.deselectAreas);
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      prevDim = { width: 2, height: 2 };
    };
  }, []);

  useEffect(() => {
    dispatch.aoiTool.selectPointerTool(null);

    setDim(a => {
      if (a.width !== prevDim.width) {
        prevDim = a;
      }

      return {
        width: 1,
        height: 1,
      };
    });
  }, [windowResize, dispatch]);

  useEffect(() => {
    if (
      imageRef.current &&
      dim.width === 1 &&
      imageRef.current.naturalWidth &&
      imageRef.current.naturalHeight
    ) {
      const { width, height } = calculateAspectRatioFit(
        imageRef.current.naturalWidth,
        imageRef.current.naturalHeight,
        imageRef.current.parentElement?.offsetWidth || 0,
        imageRef.current.parentElement?.offsetHeight || 0,
      );

      imageRef.current.style.width = `${width}px`;
      imageRef.current.style.height = `${height}px`;

      setDim({
        width,
        height,
      });

      setTimeout(() => {
        dispatch.aoiAreas.prepare(true);
      });
    }
  }, [dim, dispatch]);

  return (
    <Box sx={{ height: "calc(100% - 16px)", display: "flex", flexDirection: "row" }}>
      <Box
        sx={{
          m: 1,
          flex: 1,

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <img
          id="AoiDrawArea_image"
          ref={imageRef}
          src={imageUrl}
          onLoadStart={() => {
            dispatch.aoiAreas.setBackgroundImageLoaded(false);
          }}
          onLoad={e => {
            const parentWidth = e.currentTarget.parentElement?.offsetWidth || 0;
            const parentHeight = e.currentTarget.parentElement?.offsetHeight || 0;
            let width = e.currentTarget.width;
            let height = e.currentTarget.height;

            if (parentWidth / 2 > width && parentHeight / 2 > height) {
              const calcSize = calculateAspectRatioFit(
                width,
                height,
                parentWidth,
                parentHeight,
              );

              width = calcSize.width;
              height = calcSize.height;

              e.currentTarget.style.width = `${width}px`;
              e.currentTarget.style.height = `${height}px`;
            }

            setDim({
              width,
              height,
            });
            dispatch.aoiTool.setImageNaturalDim({
              width: e.currentTarget.naturalWidth,
              height: e.currentTarget.naturalHeight,
            });
            dispatch.aoiAreas.setBackgroundImageLoaded(true);
            dispatch.aoiAreas.get(visualizationId);
          }}
          onError={() => {
            dispatch.aoiAreas.setBackgroundImageLoaded(false);
          }}
          style={{
            maxHeight: "100%",
            maxWidth: "100%",
            height: "auto",
            width: "auto",
            margin: "auto",
            display: "block",
            opacity: imageUrl ? 1 : 0,
            position: "absolute",
          }}
          alt="aoi_image"
        />
        <Stage
          ref={dispatch.aoiTool.setStageRef}
          width={dim.width}
          height={dim.height}
          draggable={false}
          onMouseDown={disabled ? undefined : dispatch.aoiTool.onMouseDown}
          onMouseUp={disabled ? undefined : dispatch.aoiTool.onMouseUp}
          onMouseLeave={disabled ? undefined : dispatch.aoiTool.onMouseLeave}
          onMouseMove={disabled ? undefined : dispatch.aoiTool.onMouseMove}
          onClick={disabled ? undefined : dispatch.aoiAreas.selectFistHovered}
        >
          <AreaLayers disabled={disabled} />
          <AreaLayersHover />
          <Layer opacity={DEFAULT_OPACITY}>
            <Group ref={dispatch.aoiTool.setGroupRef} />
          </Layer>
          <PenSubToolShapes />
        </Stage>
      </Box>
      {disabled ? <HeatMapLegend /> : null}
    </Box>
  );
};

const PenSubToolShapes = () => {
  const tool = useAppSelector(state => state.aoiTool.tool);
  const line = useAppSelector(state => state.aoiTool.line);
  const pos = useAppSelector(state => state.aoiTool.pos);
  const [hit, setHit] = useState(false);

  const isHit = hit && isLineDrawn(line);
  const circleColor = tool === AoiTool.PEN_ADD ? KONVA_ADD_COLOR : KONVA_ERASE_COLOR;

  return (
    <Layer>
      <Line
        points={line}
        opacity={DEFAULT_OPACITY}
        stroke={tool === AoiTool.PEN_ADD ? KONVA_ADD_COLOR : KONVA_ERASE_COLOR}
      />
      {(tool === AoiTool.PEN_ADD || tool === AoiTool.PEN_REMOVE) && line.length ? (
        <>
          <Line
            stroke={"#000"}
            points={[line[line.length - 2], line[line.length - 1], pos.x, pos.y]}
          />
          <Line
            dash={[5, 2]}
            stroke={KONVA_OFF_COLOR}
            points={[line[line.length - 2], line[line.length - 1], pos.x, pos.y]}
          />
          <Circle
            onMouseEnter={() => setHit(true)}
            onMouseLeave={() => setHit(false)}
            x={line[0]}
            y={line[1]}
            radius={isHit ? KONVA_CIRCLE_HIT_RADIUS : KONVA_CIRCLE_RADIUS}
            fill={isHit ? KONVA_OFF_COLOR : circleColor}
            stroke={isHit ? circleColor : KONVA_OFF_COLOR}
          />
        </>
      ) : null}
    </Layer>
  );
};

const AreaLayers: FC<{ disabled: boolean }> = ({ disabled }) => {
  const stageRef = useAppSelector(state => state.aoiTool.stageRef);
  const data = useAppSelector(state => state.aoiAreas.data);
  const images = useAppSelector(state => state.aoiAreas.images);
  const visibility = useAppSelector(state => state.aoiAreas.visibility);
  const draw = useAppSelector(state => !!state.aoiAreas.current);
  const aoiStatsById = useAppSelector(state => state.aoiStats.tableDataById);
  const heatmapType = useAppSelector(state => state.aoiStats.heatmapType);
  const heatmapColor = useAppSelector(state => state.aoiStats.heatmapColor);
  const showMetricValue = useAppSelector(state => state.aoiStats.showMetricValue);
  const showNames = useAppSelector(state => state.aoiStats.showNames);
  const aoiAreasById = useAppSelector(state => state.aoiAreas.dataById);

  return (
    <AreaLayersBase
      width={stageRef?.width() || 0}
      height={stageRef?.height() || 0}
      scale={stageRef?.scale()}
      disabled={disabled}
      data={data}
      images={images}
      visibility={visibility}
      draw={draw}
      aoiStatsById={aoiStatsById}
      heatmapType={heatmapType}
      heatmapColor={heatmapColor}
      showMetricValue={showMetricValue}
      showNames={showNames}
      aoiAreasById={aoiAreasById}
    />
  );
};

const AreaLayersHover = () => {
  const stageRef = useAppSelector(state => state.aoiTool.stageRef);
  const images = useAppSelector(state => state.aoiAreas.images);
  const hover = useAppSelector(state => state.aoiAreas.hover);
  const current = useAppSelector(state => state.aoiAreas.current);

  return (
    <Layer opacity={DEFAULT_OPACITY}>
      {hover.map(key => {
        if (!images[key] || key === current?.id) return undefined;

        return (
          <Image
            key={key}
            id={key}
            image={images[key].image}
            scale={stageRef?.scale()}
            width={stageRef?.width()}
            height={stageRef?.height()}
          />
        );
      })}
    </Layer>
  );
};
