import { Workspace } from "@api";
import { FormDialog, Switch, TextField } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography } from "@mui/material";
import { RouterHelper } from "@pages";
import { useAppDispatch } from "@storeRematch";
import { isUserAllowedFaceBlur } from "@utils";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

interface FormProps {
  name: string;
  videoUpload: boolean;
  faceBlur: boolean;
}

export interface WorkspaceFormProps {
  open: boolean;
  onClose(): void;
  workspace?: Workspace;
}

const videoUploadFormName = "videoUpload";
const faceBlurFormName = "faceBlur";

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
    videoUpload: yup.boolean().required(),
    faceBlur: yup.boolean().required(),
  })
  .required();

export const WorkspaceForm: React.FC<WorkspaceFormProps> = ({
  open,
  onClose,
  workspace = undefined,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSubmit = async (formData: FormProps) => {
    if (workspace) {
      await dispatch.app.updateWorkspace({ name: formData.name });
      onClose();
    } else {
      const newWorkspaceId = await dispatch.app.createWorkspace(formData);

      if (newWorkspaceId) {
        dispatch.app.setInviteMembersAfterWorkspaceIsCreated(true);
        navigate(
          RouterHelper.workspaceRecordings.getFullPath({
            props: { workspaceId: newWorkspaceId },
          }),
        );
      }
    }
  };

  return (
    <FormDialog
      open={open}
      title={workspace ? t("Edit Workspace Name") : t("Create New Workspace")}
      buttonText={workspace ? t("Update") : t("Create")}
      onClose={onClose}
      onFormSubmit={handleSubmit}
      useFormProps={{
        defaultValues: {
          name: workspace ? workspace.name : "",
          videoUpload: true,
          faceBlur: false,
        },
        resolver: yupResolver(schema),
      }}
      create={!workspace}
      loading={false}
    >
      <Stack alignItems="flex-start" spacing={2}>
        <TextField
          required
          fullWidth
          name="name"
          autoFocus
          placeholder={workspace ? t("Edit workspace name") : t("New workspace name")}
        />

        {!workspace ? (
          <>
            <Switch name={videoUploadFormName} label={t("Upload World Video")} />

            <FaceBlur />

            <Typography color="text.secondary" sx={{ mt: 1.5 }}>
              {t(
                "Privacy settings are permanent and cannot be changed once the Workspace has been created.",
              )}
            </Typography>
          </>
        ) : null}
      </Stack>
    </FormDialog>
  );
};

const FaceBlur = () => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const watch = useWatch({ name: videoUploadFormName });
  const methods = useFormContext();

  useEffect(() => {
    setDisabled(!watch);
    methods.setValue(faceBlurFormName, false);
  }, [watch, methods]);

  if (!isUserAllowedFaceBlur()) return null;

  return (
    <Switch name={faceBlurFormName} label={t("Face Blurring")} disabled={disabled} />
  );
};
