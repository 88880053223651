import { store } from "@storeRematch";

const allowed = new Set([
  "e505d233-4c4d-4379-8f0f-b3bc6681b591",
  "a0df8e08-b131-403d-acd4-83486a53fcc1",
  "c65a3054-583e-44d0-8a9e-9e48bc4d5c3d",
  "81cfe935-37c8-4cfc-9f59-6f3d982eda9d",
  "da0eac42-cc20-4a6d-9a86-7c92b34a9b91",
  "91f5402e-119d-4bdd-96d9-a271c1b45607",
  "7b2a5848-c647-4b79-a4fe-a5ebbdf77f4e",
  "d296e1e6-ee31-4500-9345-25b47e5c50bf",
  "f50a7d8d-5f48-4eb8-8487-662c6db71f17",
  "7c59db74-05be-4677-abf5-b4045e6d097a",
]);

export function isUserAllowedFaceBlur() {
  const state = store.getState();

  if (state.app.showPrivacySettings) return true;

  const user = state.app.apiUser;

  if (!user) return false;
  if (allowed.has(user.id)) return true;

  return false;
}
