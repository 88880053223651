import { Loader } from "@components/Loader";
import { Box, Button, Divider, Link, Typography } from "@mui/material";
import { RouterHelper } from "@pages";
import { auth } from "@services/firebase";
import { useAppDispatch } from "@storeRematch";
import { LocalStorageAdapterNames, localStorageAdapter } from "@utils";
import { applyActionCode } from "firebase/auth";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

enum VerifyingStatus {
  Verifying,
  Success,
  Error,
}

const ExpiredLink = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate(RouterHelper.signIn.getFullPath());
  };

  return (
    <>
      <Typography variant="body2" color="text.secondary">
        {t(`This link has expired, been used, or is malformed. Try logging in again.`)}
      </Typography>
      <Box display="flex" justifyContent="center">
        <Button color="primary" variant="text" onClick={handleLogin}>
          {t("Sign in")}
        </Button>
      </Box>
      <Divider />
      <Box>
        <Typography variant="body2" color="text.secondary" component="span" mr={1}>
          {t(`Still need help?`)}
        </Typography>
        <Typography variant="body1" component="span" color="text.primary">
          <Link href={"/signup"}>{t(`Contact us`)}</Link>
        </Typography>
      </Box>
    </>
  );
};

const EmailVerified = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate(RouterHelper.signIn.getRelativePath({ prefix: "../" }));
  };
  return (
    <>
      <Typography variant="body2" color="text.secondary">
        {t(`Your email has been verified. Proceed to signin.`)}
      </Typography>
      <Box display="flex" justifyContent="center">
        <Button color="primary" variant="text" onClick={handleLogin}>
          {t("Sign in")}
        </Button>
      </Box>
      <Divider />
      <Box>
        <Typography variant="body2" color="text.secondary" component="span" mr={1}>
          {t(`Still need help?`)}
        </Typography>
        <Typography variant="body1" component="span" color="primary.main">
          <Link href={"/signup"}>{t(`Contact us`)}</Link>
        </Typography>
      </Box>
    </>
  );
};

export const VerifyEmailAction: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [params] = useSearchParams();

  const [verifiedStatus, setVerifiedStatus] = useState(VerifyingStatus.Verifying);

  useEffect(() => {
    const oobCode = params.get("oobCode");

    const verifyEmail = async (oobCode: string) => {
      try {
        await applyActionCode(auth, oobCode);
        setVerifiedStatus(VerifyingStatus.Success);
        localStorageAdapter.delete(LocalStorageAdapterNames.unVerifiedEmail);
      } catch (error) {
        console.error(error);
        setVerifiedStatus(VerifyingStatus.Error);
      }
    };

    verifyEmail(oobCode!);
  }, [dispatch, params, t]);

  if (verifiedStatus === VerifyingStatus.Verifying) return <Loader />;

  if (verifiedStatus === VerifyingStatus.Success) return <EmailVerified />;

  return <ExpiredLink />;
};
