import { ErrorPage } from "@components";
import { LabelForm } from "@pages/workspaces/settings/labels/LabelForm";
import { useAppSelector } from "@storeRematch";
import { useNavigate, useParams } from "react-router-dom";

export const LabelEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const labelId = String(params.labelId);
  const label = useAppSelector(state => state.labels.dataById.get(labelId));
  const isLoading = useAppSelector(
    state => state.loading.models.labels.loading || state.loading.models.app.loading,
  );

  if (!label && !isLoading) {
    return <ErrorPage title={`Label ${labelId} not found in this workspace`} />;
  }

  return <LabelForm label={label} onClose={() => navigate(-1)} open={true} />;
};
