import { DrawPenAdd, DrawPenRemove, DrawPointer } from "@customIcons";
import {
  Box,
  Button,
  IconButton,
  IconButtonProps,
  Divider as MuiDivider,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AoiTool,
  QuestionDialogTypes,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import { DisableByPermissionsType, disabledByPermission } from "@utils";
import { FC, memo } from "react";
import { useTranslation } from "react-i18next";

const DrawToolbarButton: FC<
  IconButtonProps & {
    checked?: boolean;
    left?: boolean;
    tooltip: string;
    hotkey?: string;
  }
> = ({ children, sx, checked, left, tooltip, hotkey, ...props }) => {
  return (
    <Tooltip
      title={
        <Box sx={{ display: "flex" }}>
          <Typography variant="body2">{tooltip}</Typography>
          {hotkey && (
            <Typography variant="body2" color="text.secondary" sx={{ ml: 1.5 }}>
              {hotkey}
            </Typography>
          )}
        </Box>
      }
    >
      <span>
        <IconButton
          color="primary"
          sx={{
            color: "text.primary",
            backgroundColor: checked ? "primary.main" : undefined,
            "&: hover": {
              backgroundColor: checked ? "primary.main" : undefined,
            },
            mr: left ? 0 : 1,
            ...sx,
          }}
          {...props}
        >
          {children}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export const AoiDrawToolbar = memo(() => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tool = useAppSelector(state => state.aoiTool.tool);
  const disablePen = useAppSelector(
    state => !state.aoiAreas.current || state.aoiAreas.selectedIds.size > 1,
  );
  const disabledPermissions = disabledByPermission({
    type: DisableByPermissionsType.EDIT,
  });

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <DrawToolbarButton
        tooltip={t("Select area to edit")}
        hotkey="V / ESC"
        onClick={dispatch.aoiTool.selectPointerTool}
        checked={tool === AoiTool.POINTER}
      >
        <DrawPointer />
      </DrawToolbarButton>

      <Divider />

      <DrawToolbarButton
        tooltip={t("Add to area")}
        hotkey="P"
        onClick={dispatch.aoiTool.selectPenAddTool}
        checked={tool === AoiTool.PEN_ADD}
        disabled={disabledPermissions || disablePen}
      >
        <DrawPenAdd />
      </DrawToolbarButton>

      <DrawToolbarButton
        tooltip={t("Subtract from area")}
        hotkey="E"
        onClick={dispatch.aoiTool.selectPenRemoveTool}
        checked={tool === AoiTool.PEN_REMOVE}
        disabled={disabledPermissions || disablePen}
      >
        <DrawPenRemove />
      </DrawToolbarButton>

      <Button
        variant="text"
        color="primary"
        onClick={() => {
          dispatch.questionDialog.set({
            type: QuestionDialogTypes.DRAW_AREA_CLEAR,
            onSuccess: () => {
              dispatch.aoiTool.clear(null);
              dispatch.aoiAreas.updateShape(null);
            },
          });
        }}
        disabled={disabledPermissions || disablePen}
      >
        {t("Clear")}
      </Button>
    </Box>
  );
});

const Divider = ({ left }: { left?: boolean }) => {
  return (
    <MuiDivider
      orientation="vertical"
      sx={{ height: 37, mr: left ? 1 : 2, ml: left ? "auto" : 1 }}
    />
  );
};
