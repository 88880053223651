import { CtxMenuItem } from "@components/ctxMenu";
import { INACTIVE_FIELD_NAME } from "@constants";
import { createInactive } from "@hooks/useTableSearchParams";
import { Check } from "@mui/icons-material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { useAppDispatch } from "@storeRematch";
import { useEffect } from "react";

export const TrashFilter = () => {
  const dispatch = useAppDispatch();
  const apiRef = useGridApiContext();
  const filterState = apiRef.current.state.filter;
  const inactive = filterState.filterModel.items.find(
    o => o.field === INACTIVE_FIELD_NAME,
  );
  const inactiveColumn = inactive
    ? apiRef.current.getColumn(inactive.field)
    : undefined;

  useEffect(() => {
    if (inactive) {
      dispatch.entityTable.setQuickFilter({ isInactive: inactive.value === "true" });
    }
  }, [inactive, dispatch]);

  const onInactive = () => {
    const next = inactive
      ? {
          ...inactive,
          value: inactive.value === "true" ? "false" : "true",
        }
      : createInactive();

    dispatch.entityTable.setQuickFilter({ isInactive: next.value === "true" });
    dispatch.entityTable.setSelected([]);
    apiRef.current.upsertFilterItem(next);
    dispatch.ctxMenu.closeAll();
  };

  if (!inactiveColumn) return null;

  return (
    <CtxMenuItem
      label="Show trashed"
      onClick={onInactive}
      rightIcon={inactive && inactive.value === "true" ? <Check /> : undefined}
    />
  );
};
