import { useVisualizationRecordings } from "@hooks";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ProjectRecordingsNoRows } from "@pages";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { LocalStorageAdapterNames } from "@utils";
import { FC, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const RecordingsSelectModal: FC<{
  handleConfirm: (params: { enrichmentId: string; selectedIds: string[] }) => void;
  handleReset: (params: { enrichmentId: string; selectedIds: string[] }) => void;
  handleFirstReset: () => void;
  enrichmentId: string;
  disabled?: boolean;
  name?: string;
}> = ({
  handleConfirm,
  handleReset,
  handleFirstReset,
  enrichmentId,
  disabled,
  name = "recordingIds",
}) => {
  const { t } = useTranslation();
  const recordings = useVisualizationRecordings(enrichmentId);
  const selectedIds = useAppSelector(state => state.entityTable.selectedIds);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const refFirstOpen = useRef(false);
  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    dispatch.entityTable.setSelected(recordings.map(r => r.id));
  }, [dispatch.entityTable, recordings]);

  useEffect(() => {
    dispatch.entityTable.setSelected(getValues(name));
  }, [dispatch, getValues, name]);

  const toggleOpen = () => {
    if (!open && !refFirstOpen.current) {
      refFirstOpen.current = true;
      dispatch.entityTable.resetSelected();
      setValue(name, []);
      handleFirstReset();
    }

    setOpen(!open);
  };

  const onConfirm = () => {
    toggleOpen();
    setValue(name, selectedIds);
    handleConfirm({ enrichmentId, selectedIds });
  };

  const onSelect = (id: string) => {
    dispatch.entityTable.toggleSelected(id);
  };

  const onSelectAll = () => {
    dispatch.entityTable.setSelected(recordings.map(r => r.id));
  };

  const onReset = () => {
    const recordingIds = recordings.map(r => r.id);

    setValue(name, recordingIds);
    dispatch.entityTable.setSelected(recordingIds);
    handleReset({ enrichmentId, selectedIds: recordingIds });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 16,
        }}
      >
        <Typography variant="subtitle2">{t("Recording Data")}</Typography>
        {selectedIds.length !== recordings.length ? (
          <Button variant="text" color="primary" size="small" onClick={onReset}>
            {t("Reset")}
          </Button>
        ) : null}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        <Typography variant="subtitle2">
          {selectedIds.length}/{recordings.length} {t("Recordings")}
        </Typography>
        <Button size="small" sx={{ ml: 2 }} onClick={toggleOpen} disabled={disabled}>
          {t("Edit")}
        </Button>
      </Box>
      {!selectedIds.length ? (
        <Typography variant="caption" color="error" sx={{ mt: 2 }}>
          {t("Please select at least one recording")}
        </Typography>
      ) : null}
      <Dialog
        open={open}
        onClose={onConfirm}
        fullWidth
        maxWidth="md"
        PaperProps={{ sx: { height: "80vh" }, elevation: 0 }}
      >
        <Box
          sx={{
            display: "flex",
            px: 2,
            pt: 3,
            pb: 1,
          }}
        >
          <Typography variant="h6">{t("Select Recording")}</Typography>{" "}
          <Typography variant="h6" sx={{ ml: "auto" }}>
            {selectedIds.length}/{recordings.length}
          </Typography>
        </Box>

        <DialogContent sx={{ p: 0 }}>
          <ProjectRecordingsNoRows
            rows={recordings}
            onCustomSelect={onSelect}
            tableColumnInitialStateStorageKey={
              LocalStorageAdapterNames.projectRecordingsExtraColumState
            }
            disableSelectModel={false}
            disableAutoSelect
            disableFooter
          />
        </DialogContent>
        <Box sx={{ display: "flex", pb: 1, pt: 2, px: 2 }}>
          <Button variant="text" color="primary" onClick={onSelectAll}>
            {t("Select All")}
          </Button>
          <Button color="primary" sx={{ ml: "auto" }} onClick={onConfirm}>
            {t("Confirm")}
          </Button>
        </Box>
      </Dialog>
    </>
  );
};
