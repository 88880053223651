import {
  FaceEnrichmentLocalization,
  MarkerMapperEnrichmentLocalization,
  MarkerlessEnrichmentLocalization,
  SurfaceEnrichmentLocalization,
} from "@api";
import { VideoJsPlayer } from "video.js";

type Localization =
  | FaceEnrichmentLocalization
  | SurfaceEnrichmentLocalization
  | MarkerlessEnrichmentLocalization
  | MarkerMapperEnrichmentLocalization;

export function isLocalizationWithData(localization: Localization) {
  if ((localization as FaceEnrichmentLocalization).avg_faces) return true;
  if (
    typeof (localization as SurfaceEnrichmentLocalization).aoi_detected === "number" &&
    (localization as SurfaceEnrichmentLocalization).aoi_detected > 0.5
  )
    return true;
  if (
    typeof (localization as MarkerlessEnrichmentLocalization).aoi_detected ===
      "number" &&
    (localization as MarkerlessEnrichmentLocalization).aoi_detected > 0.5
  )
    return true;

  if ((localization as MarkerMapperEnrichmentLocalization).markerMapper) {
    return true;
  }

  return false;
}

export function getLocalizationColor(localization: Localization) {
  if (
    typeof (localization as MarkerlessEnrichmentLocalization).gaze_on_aoi ===
      "number" &&
    (localization as MarkerlessEnrichmentLocalization).gaze_on_aoi <= 0.5
  )
    return true;

  if (
    typeof (localization as SurfaceEnrichmentLocalization).gaze_on_aoi === "number" &&
    (localization as SurfaceEnrichmentLocalization).gaze_on_aoi <= 0.5
  )
    return true;

  if (
    (localization as MarkerMapperEnrichmentLocalization).onRefImg === 0 &&
    (localization as MarkerMapperEnrichmentLocalization).hasCoordinates === false
  ) {
    return true;
  }

  if (
    (localization as MarkerMapperEnrichmentLocalization).onRefImg === null &&
    (localization as MarkerMapperEnrichmentLocalization).hasCoordinates === false
  ) {
    return null;
  }

  return false;
}

export function getLocalizationStartAndEndTimes(
  video: VideoJsPlayer | null,
  localization: Localization,
) {
  return {
    startTime: localization.start_timestamp,
    endTime:
      video && localization.end_timestamp > video.duration()
        ? video.duration()
        : localization.end_timestamp,
  };
}
