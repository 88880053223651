import { ContextMenu, CtxMenuItem } from "@components";
import { MenuList } from "@mui/material";
import { RouterHelper } from "@pages";
import {
  ContextMenuTypes,
  QuestionDialogTypes,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import { DisableByPermissionsType, disabledByPermission } from "@utils";
import React from "react";
import { useTranslation } from "react-i18next";

export const ProjectEnrichmentTableContextMenu: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selected = useAppSelector(state => state.entityTable.selectedIds);
  const enrichmentsById = useAppSelector(state => state.enrichments.dataById);
  const numSelected = selected.length;
  const isMultiSelect = numSelected > 1;
  const disabledPermissionsDelete = disabledByPermission({
    type: DisableByPermissionsType.DELETE,
  });

  const onClose = () => {
    dispatch.ctxMenu.closeAll();
  };

  const handleDelete = () => {
    onClose();
    dispatch.questionDialog.set({
      type: QuestionDialogTypes.ENRICHMENT_DELETE,
      onSuccess: () => {
        dispatch.projectEdit.deleteEnrichment(selected);
        onClose();
      },
      extra: {
        title:
          selected.length === 1 ? enrichmentsById.get(selected[0])?.name || "" : "",
        count: selected.length > 1 ? selected.length : undefined,
      },
    });
  };

  return (
    <ContextMenu type={ContextMenuTypes.RECORD_TABLE}>
      <MenuList>
        <CtxMenuItem
          label={t("View enrichment")}
          onClick={() => {
            onClose();
          }}
          to={
            selected[0]
              ? RouterHelper.projectEnrichmentsEdit.getRelativePath({
                  props: { enrichmentId: selected[0] },
                })
              : undefined
          }
          disabled={isMultiSelect}
          tooltip={t("Multiple items selected")}
          divider
        />

        <CtxMenuItem
          label={
            numSelected === 1
              ? t("Delete enrichment")
              : t("Delete {{numSelected}} enrichments", { numSelected })
          }
          onClick={handleDelete}
          disabled={disabledPermissionsDelete}
          tooltip={t("Disabled by permissions")}
        />
      </MenuList>
    </ContextMenu>
  );
};
