import { CtxMenuItem, CtxMenuItemProps } from "@components";
import { ChevronRight } from "@mui/icons-material";
import React, { FC, PropsWithChildren, useState } from "react";
import { CtxMenuBase } from "./CtxMenuBase";

export const CtxNestedMenu: FC<
  PropsWithChildren<{ CtxMenuItemProps: CtxMenuItemProps; onClose?: () => void }>
> = ({ CtxMenuItemProps, onClose, children }) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = !!anchorEl;

  const openMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!anchorEl) setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    onClose?.();
    setAnchorEl(null);
  };

  return (
    <div onMouseEnter={openMenu} onMouseLeave={closeMenu}>
      <CtxMenuItem {...CtxMenuItemProps} rightIcon={<ChevronRight />} />
      <CtxMenuBase
        open={CtxMenuItemProps.disabled ? false : open}
        anchorEl={anchorEl}
        placement="right-start"
      >
        {children}
      </CtxMenuBase>
    </div>
  );
};
