import { ProjectEnrichmentAoi, VisualizationTypes, api } from "@api";
import { AoiHeatmapFormValues } from "@pages";
import { createModel } from "@rematch/core";
import {
  DisableByPermissionsType,
  RematchAdapter,
  createCanvasLegend,
  createImage,
  disabledByPermission,
  downloadLocally,
  rematchAdapter,
  scaleCanvas,
} from "@utils";
import { getOpacityIndexOfImageData } from "@utils/getOpacityIndexOfImageData";
import Konva from "konva";
import { Vector2d } from "konva/lib/types";
import { v4 as uuidv4 } from "uuid";
import { AOI_HEATMAP_TYPES, AoiTool, AreaImages, RootModel } from ".";
import i18n from "../i18n";

interface Adapter extends RematchAdapter<ProjectEnrichmentAoi> {
  current: ProjectEnrichmentAoi | null;
  visibility: Record<string, boolean>;
  hover: string[];
  images: Record<string, AreaImages>;
  scrollToIndex: number | null;
  currentIndex: number | null;
  backgroundImageLoaded: boolean;
  stopDeselect: boolean;
  selectedIds: Set<string>;
}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.id,
  sort: data =>
    data.sort(
      (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    ),
  extraProps: {
    current: null,
    visibility: {},
    hover: [],
    images: {},
    scrollToIndex: null,
    currentIndex: null,
    backgroundImageLoaded: false,
    stopDeselect: false,
    selectedIds: new Set(),
  },
});

export const aoiAreas = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
    setCurrent(state, data: Adapter["current"]) {
      state.current = data;
    },
    setVisibility(state, data: { id: string; value: boolean }) {
      state.visibility[data.id] = data.value;

      const index = state.hover.findIndex(h => h === data.id);
      if (index !== -1) state.hover.splice(index, 1);
    },
    setAllVisibility(state, data: Adapter["visibility"]) {
      state.visibility = data;
      state.hover = [];
    },
    setHover(state, data: { pointer: Vector2d; width: number }) {
      const aIndex = getOpacityIndexOfImageData(data);
      let scrollToIndex: Adapter["scrollToIndex"] = null;

      state.hover = state.data.reduce((acc, { id }, index) => {
        const image = state.images[id];

        if (image && image.imageData.data[aIndex] && state.visibility[id]) {
          scrollToIndex = index;
          acc.push(id);
        }

        return acc;
      }, [] as string[]);

      if (scrollToIndex !== null) state.scrollToIndex = scrollToIndex;
    },
    addHover(state, data: string | undefined) {
      state.hover = data ? [data] : [];
    },
    addImage(state, data: AreaImages) {
      state.images[data.id] = data;
    },
    removeImage(state, data: string) {
      delete state.images[data];
    },
    setImages(state, data: Adapter["images"]) {
      state.images = data;
    },
    setScrollToIndex(state, data: Adapter["scrollToIndex"]) {
      state.scrollToIndex = data;

      if (data !== null) state.currentIndex = data;
    },
    setBackgroundImageLoaded(state, data: Adapter["backgroundImageLoaded"]) {
      state.backgroundImageLoaded = data;
    },
    setStopDeselect(state, data: Adapter["stopDeselect"]) {
      state.stopDeselect = data;
    },
    addToSelectedIds(state, id) {
      state.selectedIds.add(id);
    },
    removeFromSelectedIds(state, id) {
      state.selectedIds.delete(id);
    },
    resetSelectedIds(state, data = []) {
      state.selectedIds = new Set(data);

      if (!data.length) state.currentIndex = null;
    },
    reset() {
      nextColor = -1;
      return adapter.reducers.reset();
    },
  },
  effects: dispatch => ({
    async prepare(all: boolean | null, state) {
      const data = state.aoiAreas.data;
      const stage = state.aoiTool.stageRef;
      const visualization = state.visualizations.currentVisualization;
      const visualizationAoiIds =
        visualization && visualization.kind === VisualizationTypes.AOI_HEATMAP
          ? new Set(visualization.payload.aoi_ids)
          : undefined;

      if (!stage || stage.width() < 3 || !data.length) return;
      const t0 = performance.now();

      const { images, visibility } = await dispatch.aoiAreas.innerPrepare({
        stateImages: {},
        visualizationAoiIds,
        data,
        width: stage.width() || 0,
        height: stage.height() || 0,
        all,
      });

      if (data.length) {
        const lastColor = data[data.length - 1].color;

        if (
          lastColor &&
          colorsByIndex[lastColor] !== undefined &&
          colorsByIndex[lastColor] > nextColor
        ) {
          nextColor = colorsByIndex[lastColor];
        }
      }

      dispatch.aoiAreas.setAllVisibility(visibility);
      dispatch.aoiAreas.setImages(images);

      const t1 = performance.now();
      console.log(`Call to aoiAreas prepare took ${t1 - t0} milliseconds.`);
    },
    async innerPrepare({
      stateImages,
      visualizationAoiIds,
      data,
      all,
      width,
      height,
    }: {
      stateImages: Record<string, AreaImages>;
      visualizationAoiIds: Set<string> | undefined;
      data: ProjectEnrichmentAoi[];
      all: boolean | null;
      width: number;
      height: number;
    }) {
      const visibility: Record<string, boolean> = {};
      const images: Record<string, AreaImages> = { ...stateImages };
      const imagePromises: Promise<{
        id: string;
        image: HTMLImageElement;
        updatedAt: Date;
      } | null>[] = [];

      data.forEach(one => {
        if (!visualizationAoiIds || visualizationAoiIds.has(one.id)) {
          visibility[one.id] = true;
        }

        if (
          one.mask_image_data_url &&
          (all ||
            !images[one.id] ||
            images[one.id].updatedAt < new Date(one.updated_at))
        ) {
          imagePromises.push(
            new Promise(resolve => {
              const img = new Image();
              img.onload = () =>
                resolve({
                  id: one.id,
                  image: img,
                  updatedAt: new Date(one.updated_at),
                });
              img.onerror = () => {};
              img.setAttribute("crossOrigin", "");
              img.src = one.mask_image_data_url as string;
            }),
          );
        }
      });

      const settled = await Promise.allSettled(imagePromises);

      settled.forEach(s => {
        if (s.status !== "fulfilled") return;
        if (!s.value) return;

        const image = s.value.image;

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = Math.round(width);
        canvas.height = Math.round(height);

        if (!ctx) return;

        ctx.drawImage(
          image,
          0,
          0,
          image.width,
          image.height,
          0,
          0,
          canvas.width,
          canvas.height,
        );
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        images[s.value.id] = {
          id: s.value.id,
          updatedAt: s.value.updatedAt,
          image,
          imageData,
        };
      });

      return { images, visibility };
    },
    presetCurrentEnrichment(id: string, state) {
      dispatch.projectEdit.setCurrentEnrichment(state.enrichments.dataById.get(id));
    },
    async get(visualizationId: string | undefined, state) {
      dispatch.visualizations.resetAoiIdsToAutoAdd();

      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichmentId = state.projectEdit.currentEnrichment?.id;

      if (!workspaceId || !projectId || !enrichmentId) return;

      dispatch.aoiAreas.presetCurrentEnrichment(enrichmentId);

      if (!state.aoiAreas.backgroundImageLoaded) return;

      try {
        const data = await api.getProjectEnrichmentsAois({
          workspaceId,
          projectId,
          enrichmentId,
        });

        const visualization = state.visualizations.dataById.get(visualizationId || "");
        if (
          visualization &&
          (visualization.kind === VisualizationTypes.AOI_HEATMAP ||
            visualization?.kind === VisualizationTypes.HEATMAP)
        ) {
          dispatch.aoiStats.get({
            id: enrichmentId,
            recordingIds: visualization.payload.recording_ids || undefined,
          });
        }

        dispatch.aoiAreas.setAll(data.result);
        dispatch.aoiAreas.prepare(null);
      } catch (error) {
        console.error(error);
      }
    },
    async create(_, state) {
      const disabledPermissions = disabledByPermission({
        type: DisableByPermissionsType.CREATE,
      });

      if (disabledPermissions) return;

      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichmentId = state.projectEdit.currentEnrichment?.id;
      const userId = state.app.currentWorkspaceMembership?.workspace.created_by_user_id;

      if (!workspaceId || !projectId || !enrichmentId || !userId) return;

      try {
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = document.getElementById(
          "AoiDrawArea_image",
        ) as HTMLImageElement | null;

        if (!ctx || !img) return;

        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        ctx.drawImage(
          new Image(img.naturalWidth, img.naturalHeight),
          img.naturalWidth,
          img.naturalHeight,
        );

        const nextAoi: ProjectEnrichmentAoi = {
          bounding_box: {
            max_x: 0,
            max_y: 0,
            min_x: 0,
            min_y: 0,
          },
          centroid_xy: { x: 0, y: 0 },
          color: customColorLogic(),
          created_at: new Date().toISOString(),
          created_by_user_id: userId,
          description: "",
          enrichment_id: enrichmentId,
          id: uuidv4(),
          name: "Untitled",
          updated_at: new Date().toISOString(),
          mask_image_data_url: canvas.toDataURL(),
        };

        api.postProjectEnrichmentAois({
          workspaceId,
          projectId,
          enrichmentId,
          projectEnrichmentPostRequest: nextAoi,
        });

        dispatch.aoiAreas.upsertOne(nextAoi);
        dispatch.aoiAreas.select({ id: nextAoi.id });
        dispatch.aoiTool.selectPenAddTool(null);
        dispatch.visualizations.addAoiIdsToAutoAdd(nextAoi.id);
      } catch (error) {
        console.error(error);
      }
    },
    async delete(_, state) {
      const disabledPermissions = disabledByPermission({
        type: DisableByPermissionsType.DELETE,
      });

      if (disabledPermissions) return;

      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichmentId = state.projectEdit.currentEnrichment?.id;
      const aoiIds = Array.from(state.aoiAreas.selectedIds);

      if (!workspaceId || !projectId || !enrichmentId || !aoiIds.length) return;

      try {
        dispatch.aoiAreas.deleteMany(aoiIds);
        aoiIds.forEach(id => dispatch.aoiAreas.removeImage(id));
        dispatch.aoiAreas.setCurrent(null);
        dispatch.aoiAreas.resetSelectedIds();
        dispatch.aoiTool.clear(null);
        dispatch.aoiTool.selectPointerTool(null);
        aoiIds.forEach(id => dispatch.visualizations.removeAoiIdsToAutoAdd(id));

        await Promise.all(
          aoiIds.map(aoiId =>
            api.deleteProjectEnrichmentAoiResource({
              workspaceId,
              projectId,
              enrichmentId,
              aoiId,
            }),
          ),
        );
      } catch (error) {
        console.error(error);
      }
    },
    async updateShape(_, state) {
      const disabledPermissions = disabledByPermission({
        type: DisableByPermissionsType.EDIT,
      });

      if (disabledPermissions) return;

      const stage = state.aoiTool.stageRef;
      const group = state.aoiTool.groupRef;
      const groupLayer = group?.getLayer();
      const imageNaturalDim = state.aoiTool.imageNaturalDim;
      const currentArea = state.aoiAreas.current;

      if (!stage || !group || !imageNaturalDim || !groupLayer || !currentArea) return;

      const pos = stage.position();
      const scale = stage.scale();
      const size = stage.size();

      const nextScaleW = imageNaturalDim.width / size.width;
      const nextScaleH = imageNaturalDim.height / size.height;

      stage
        .size({ width: size.width * nextScaleW, height: size.height * nextScaleH })
        .position({
          x: 0,
          y: 0,
        })
        .scale({ x: nextScaleW, y: nextScaleH });

      const groupLayerOpacity = groupLayer.opacity();
      group.clearCache();

      group.find("Line").forEach(l => {
        const line = l as Konva.Line;

        if (line.globalCompositeOperation() === "source-over")
          line.fill(currentArea.color as string);
      });

      const url = groupLayer.opacity(1).toDataURL();

      // disabled for testing
      //dispatch.uploader.uploadEnrichmentAoi(url);

      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichmentId = state.projectEdit.currentEnrichment?.id;
      const currentAoi = state.aoiAreas.current;

      if (workspaceId && projectId && enrichmentId && currentAoi) {
        api.patchProjectEnrichmentAoiResource({
          workspaceId,
          enrichmentId,
          projectId,
          aoiId: currentAoi.id,
          projectEnrichmentAoiPatchRequest: {
            id: currentAoi.id,
            mask_image_data_url: url,
          },
        });
      }

      stage.position(pos).scale(scale).size(size);
      groupLayer.opacity(groupLayerOpacity);

      const imageData = groupLayer
        .getContext()
        .getImageData(0, 0, size.width, size.height);

      dispatch.aoiAreas.setCurrent(currentArea);
      dispatch.aoiAreas.addToSelectedIds(currentArea.id);
      dispatch.aoiAreas.upsertOne(currentArea);

      dispatch.aoiTool.clear(null);

      const image = new Image();
      image.onload = () => {
        const img = createImage({ image, scale, size, id: currentArea.id });
        group.add(img);
        dispatch.aoiAreas.addImage({
          id: currentArea.id,
          image,
          imageData,
          updatedAt: new Date(currentArea.updated_at),
        });
      };
      image.src = url;
    },
    testDownload(_, state) {
      const stage = state.aoiTool.stageRef;
      const group = state.aoiTool.groupRef;

      const imageNaturalDim = state.aoiTool.imageNaturalDim;
      const currentArea = state.aoiAreas.current;

      if (!stage || !group || !imageNaturalDim || !currentArea) return;

      const groupLayer = stage.getLayers()[0];

      const pos = stage.position();
      const scale = stage.scale();
      const size = stage.size();

      const nextScaleW = imageNaturalDim.width / size.width;
      const nextScaleH = imageNaturalDim.height / size.height;

      const layer = new Konva.Layer();

      stage.add(layer);

      const images = Object.values(state.aoiAreas.images);

      const nextData = new Uint8ClampedArray(images[0].imageData.data.length);

      for (var imgIndex = 0; imgIndex < 32; imgIndex += 1) {
        const image = images[imgIndex];
        // Get the offset for the image data array we need to look at:
        // 0 = R      1 = G      2 = B      3 = A
        const colorChannelOffset = Math.floor(imgIndex / 8);

        // Loop over every 4th entry in the image data array which is an array
        // of [R,G,B,A, R,G,B,A...] for each pixel in the image
        for (let i = 0; i < nextData.length; i += 4) {
          const composedImageIndex = i + colorChannelOffset;
          // we only care to see if alpha for the aoi is
          // transparent = off
          // nontransparent = on
          const alpha = image.imageData.data[i + 3];
          const pixelIsSet = alpha > 0;

          // toggle the bit for this channel to 0 if the aoi image was empty
          // otherwise 1
          if (pixelIsSet) {
            nextData[composedImageIndex] |= 1 << imgIndex % 8;
          } else {
            nextData[composedImageIndex] &= ~(1 << imgIndex % 8);
          }
        }
      }

      // for (let i = 0; i < nextData.length; i += 4) {
      //   const rIndex = i;
      //   const gIndex = i + 1;
      //   const bIndex = i + 2;
      //   const aIndex = i + 3;

      //   let rBin = "";
      //   let gBin = "";
      //   let bBin = "";
      //   let aBin = "";

      //   images.forEach(({ imageData: { data } }, j) => {
      //     if (j < 8) {
      //       rBin +=
      //         data[rIndex] || data[gIndex] || data[bIndex] || data[aIndex] ? "1" : "0";
      //     } else if (j < 16) {
      //       gBin +=
      //         data[rIndex] || data[gIndex] || data[bIndex] || data[aIndex] ? "1" : "0";
      //     } else if (j < 24) {
      //       bBin +=
      //         data[rIndex] || data[gIndex] || data[bIndex] || data[aIndex] ? "1" : "0";
      //     } else {
      //       aBin +=
      //         data[rIndex] || data[gIndex] || data[bIndex] || data[aIndex] ? "1" : "0";
      //     }
      //   });

      //   nextData[rIndex] = parseInt(rBin, 2);
      //   nextData[gIndex] = parseInt(gBin, 2);
      //   nextData[bIndex] = parseInt(bBin, 2);
      //   nextData[aIndex] = parseInt(aBin, 2);
      // }
      const t0 = performance.now();
      const nImageData = new ImageData(
        nextData,
        images[0].imageData.width,
        images[0].imageData.height,
        { colorSpace: images[0].imageData.colorSpace },
      );

      console.log(nImageData);

      layer.getContext().putImageData(nImageData, 0, 0);
      const url = layer.opacity(1).toDataURL();
      layer.destroy();
      const t1 = performance.now();
      console.log(`Call to testDownload prepare took ${t1 - t0} milliseconds.`);

      const img = createImage({
        image: layer.getCanvas()._canvas,
        scale,
        size,
        id: currentArea.id,
      });
      group.add(img);
      // dispatch.aoiAreas.addImage({
      //   id: currentArea.id,
      //   image,
      //   imageData: nImageData,
      // });

      // const image = new Image();
      // image.onload = () => {
      //   if (!nImageData) return;

      // };
      // image.src = url;

      stage
        .size({ width: size.width * nextScaleW, height: size.height * nextScaleH })
        .position({
          x: 0,
          y: 0,
        })
        .scale({ x: nextScaleW, y: nextScaleH });

      const groupLayerOpacity = groupLayer.opacity();
      group.clearCache();

      stage.position(pos).scale(scale).size(size);
      groupLayer.opacity(groupLayerOpacity);

      // const imageData = groupLayer
      //   .getContext()
      //   .getImageData(0, 0, size.width, size.height);

      //download test
      downloadLocally({ href: url, name: "stage.png" });
    },
    async downloadAoiHeatmap(data: AoiHeatmapFormValues, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const stage = state.aoiTool.stageRef;
      const groupLayer = stage?.getLayers()[0];
      const enrichment = state.enrichments.dataById.get(data.enrichmentId || "");

      if (!workspaceId || !projectId || !stage || !groupLayer || !enrichment) return;

      try {
        const url = await api.getHeatmapBackground({
          enrichment_id: enrichment.id,
          project_id: projectId,
          workspaceId,
          maxSize: 1920,
        });

        const image = new Image();
        image.onload = async () => {
          const width = image.naturalWidth;
          const height = image.naturalHeight;

          scaleCanvas({
            width,
            height,
            cb: layer => {
              const aoisImage = layer.toCanvas();

              const canvas = createCanvasLegend({
                width,
                height,
                draw: ctx => {
                  ctx.drawImage(image, 0, 0);
                  ctx.drawImage(aoisImage, 0, 0);
                },
                heatmapType: data.metrics as AOI_HEATMAP_TYPES,
                heatmapColor: data.colorMap,
                max: state.aoiStats.max,
              });

              const href = canvas.toDataURL();
              downloadLocally({
                href,
                name: data.name,
              });
            },
          });

          dispatch.notifier.enqueueSnackbar({
            message: i18n.t("AOI Heatmap downloaded"),
            options: {
              variant: "success",
            },
          });
        };

        image.onerror = () => {
          dispatch.notifier.enqueueSnackbar({
            message: i18n.t("Unable to download AOI Heatmap"),
            options: {
              variant: "error",
            },
          });
        };

        image.src = url;
      } catch (error) {
        console.error(error);
      }
    },
    async update(model: Partial<ProjectEnrichmentAoi> & { id: string }, state) {
      const disabledPermissions = disabledByPermission({
        type: DisableByPermissionsType.EDIT,
      });

      if (disabledPermissions) return;

      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichmentId = state.projectEdit.currentEnrichment?.id;
      const aoiId = state.aoiAreas.current?.id;

      if (!workspaceId || !projectId || !enrichmentId || !aoiId) return;

      try {
        const res = await api.patchProjectEnrichmentAoiResource({
          workspaceId,
          projectId,
          enrichmentId,
          aoiId: model.id,
          projectEnrichmentAoiPatchRequest: model,
        });

        dispatch.aoiAreas.upsertOne(res.result);
        if (state.aoiAreas.current?.id === res.result.id) {
          dispatch.aoiAreas.setCurrent(res.result);
          dispatch.aoiAreas.addToSelectedIds(res.result.id);
        }
      } catch (error) {
        console.error(error);
      }
    },
    selectFistHovered(_, state) {
      const hover = state.aoiAreas.hover;

      if (!hover.length || state.aoiTool.tool !== AoiTool.POINTER) return;

      dispatch.aoiAreas.select({ id: hover[hover.length - 1] });
    },
    select({ id, multi }: { id: string; multi?: boolean }, state) {
      const stage = state.aoiTool.stageRef;
      const group = state.aoiTool.groupRef;
      const item = state.aoiAreas.dataById.get(id);
      const data = state.aoiAreas.data;
      const selectedIds = state.aoiAreas.selectedIds;

      if (id === state.aoiAreas.current?.id || !group || !stage || !item) return;

      const areaImageIds = multi ? Array.from(selectedIds) : [item.id];

      if (multi) {
        if (!selectedIds.has(item.id)) areaImageIds.push(item.id);

        dispatch.aoiAreas.addToSelectedIds(item.id);
      } else {
        dispatch.aoiAreas.resetSelectedIds([item.id]);
      }

      const index = data.findIndex(o => o.id === id);

      dispatch.aoiTool.clear(null);

      const scale = stage.scale();
      const size = stage.size();

      for (const areaImageId of areaImageIds) {
        const areaImages = state.aoiAreas.images[areaImageId];

        if (!areaImages) continue;

        group.add(createImage({ image: areaImages.image, scale, size }));
      }

      dispatch.aoiAreas.setStopDeselect(true);

      if (!multi) dispatch.aoiAreas.setCurrent(item);
      else dispatch.aoiAreas.setCurrent(null);

      dispatch.aoiAreas.addHover(item.id);
      dispatch.aoiAreas.setVisibility({ id: item.id, value: true });

      if (index > -1) dispatch.aoiAreas.setScrollToIndex(index);
    },
    updateHovered(id: string | undefined, state) {
      if (id && !state.aoiAreas.visibility[id]) return;

      dispatch.aoiAreas.addHover(id);
    },
    selectAll(_, state) {
      const ids = state.aoiAreas.data.map(({ id }) => id);

      dispatch.aoiAreas.setCurrent(null);
      dispatch.aoiAreas.resetSelectedIds(ids);
    },
    selectShift(next: number, state) {
      const currentIndex =
        state.aoiAreas.currentIndex === null ? next : state.aoiAreas.currentIndex;
      const items = state.aoiAreas.data;

      if (state.aoiAreas.selectedIds.has(items[next].id)) return;

      const ids = Array.from(state.aoiAreas.selectedIds);

      if (next < currentIndex) {
        for (let i = next; i <= currentIndex; i++) {
          const item = items[i];

          if (item) ids.push(item.id);
        }
      } else if (next > currentIndex) {
        for (let i = currentIndex; i <= next; i++) {
          const item = items[i];

          if (item) ids.push(item.id);
        }
      } else {
        const item = items[next];

        if (item) ids.push(item.id);
      }

      dispatch.aoiAreas.setCurrent(null);
      dispatch.aoiAreas.resetSelectedIds(ids);
    },
  }),
});

const colors = [
  "#F77189",
  "#8EA631",
  "#3BA3EC",
  "#F560E4",
  "#33B07A",
  "#CA9232",
  "#50B131",
  "#38A9C5",
  "#CC7AF4",
  "#36ACAE",
  "#F66AB7",
  "#9591F4",
  "#AE9D31",
  "#F37A32",
  "#35AE99",
];

const colorsByIndex = colors.reduce((acc, n, i) => {
  acc[n] = i;
  return acc;
}, {} as Record<string, number>);

let nextColor = -1;

const customColorLogic = () => {
  nextColor += 1;
  const color = colors[nextColor];

  if (color) return color;

  nextColor = 0;
  return colors[nextColor];
};
