import { Box, Typography } from "@mui/material";
import { FC } from "react";

export const VisualizationsName: FC<{
  name?: string;
  sub?: string;
}> = ({ name, sub }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body3" color="text.secondary" sx={{ mb: 1, mt: 1 }}>
        {name}
      </Typography>
      <Typography variant="body1">{sub}</Typography>
    </Box>
  );
};
