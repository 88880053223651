import { TemplateItem } from "@api";
import { DragCustomContext, DraggableCustom } from "@components/DragAndDrop";
import { AddCircle, Close, ContentCopy, Delete, ViewStream } from "@mui/icons-material";
import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { formatTemplateItemWidgetType } from "@utils/formatTemplateItemWidgetType";
import { formatShortUUID } from "@utils/formatUUID";
import { ChangeEvent, KeyboardEvent, memo } from "react";
import { useTranslation } from "react-i18next";
import {
  TemplateItemBase,
  TemplateItemBaseActions,
  TemplateItemBody,
  TemplateItemDragRoot,
  TemplateItemTitle,
} from "./TemplateItemBase";

export const TemplateItemItems = () => {
  const dispatch = useAppDispatch();
  const items = useAppSelector(state => state.templateForm.draft.items);
  const disabled = useAppSelector(
    state =>
      (state.templateForm.current !== null &&
        state.templateForm.current.published_at !== null) ||
      state.loading.models.templates.loading ||
      state.loading.models.app.loading,
  );

  return (
    <DragCustomContext
      droppableId="template-form"
      direction="vertical"
      onDragEnd={disabled ? undefined : dispatch.templateForm.onDragEnd}
      Root={TemplateItemDragRoot}
    >
      {items.map((item, index) => (
        <MemoItem key={item.id} item={item} index={index} disabled={disabled} />
      ))}
    </DragCustomContext>
  );
};

const MoveIndicator = memo(
  styled("div")<{ disabled?: boolean }>(({ theme, disabled }) => ({
    cursor: "pointer",
    width: 32,
    height: 6,
    margin: "auto",
    borderRadius: 6,
    backgroundColor: theme.palette.customColors.light2,

    ".templateItemMoveHover:hover &": {
      cursor: disabled ? "auto" : "pointer",
      backgroundColor: disabled
        ? theme.palette.customColors.light2
        : theme.palette.customColors.light5,
    },
  })),
);

const Item = ({
  item,
  index,
  disabled,
}: {
  item: TemplateItem;
  index: number;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const type = item.widget_type;
  const isSectionTitle = type === "SECTION_HEADER";
  const isMultipleValues = type === "CHECKBOX_LIST" || type === "RADIO_LIST";
  const isText = type === "TEXT";
  const isTextArea = type === "PARAGRAPH";

  const onChangeTitle = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch.templateForm.setTitle({ index, data: e.currentTarget.value });
  };

  const onChangeHelperText = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (isText || isTextArea) {
      dispatch.templateForm.setHelperText({ index, data: e.currentTarget.value });
    }
  };

  // Bad Mui typings on TextField
  const onKeyDownHelperText = (e: KeyboardEvent<HTMLDivElement>) => {
    // @ts-ignore
    const value = e.target.value as string | undefined;

    if (isMultipleValues && e.key === "Enter") {
      dispatch.templateForm.addChoice({ index, data: value || "" });
      // @ts-ignore
      e.target.value = "";
    }
  };

  const onChangeChoice = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const indexChoice = e.target.dataset.id;

    if (!indexChoice) return;

    dispatch.templateForm.setChoice({
      indexItem: index,
      indexChoice: +indexChoice,
      data: e.currentTarget.value,
    });
  };

  const onDeleteChoice = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const indexChoice = e.currentTarget.dataset.id;

    if (!indexChoice) return;

    dispatch.templateForm.deleteChoice({ indexItem: index, indexChoice: +indexChoice });
  };

  const onRequired = (e: ChangeEvent<HTMLInputElement>) => {
    const index = e.currentTarget.dataset.id;

    if (index) {
      dispatch.templateForm.setRequired({
        index: +index,
        data: e.currentTarget.checked,
      });
    }
  };

  const onDeleteSection = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const index = e.currentTarget.dataset.id;

    if (!index) return;

    dispatch.templateForm.deleteSectionByIndex(+index);
  };

  const onCopySection = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const index = e.currentTarget.dataset.id;

    if (!index) return;

    dispatch.templateForm.copySectionByIndex({
      indexAddAfter: +index + 1,
      indexCopy: +index,
    });
  };

  const onAddTitle = () => {
    dispatch.templateForm.createSection({
      index,
      partial: { widget_type: "SECTION_HEADER" },
    });
  };

  const onAddSection = () => {
    dispatch.templateForm.createSection({
      index,
      partial: { widget_type: "TEXT" },
    });
  };

  const onSectionTypeChange = (event: SelectChangeEvent) => {
    dispatch.templateForm.tryToChangeWidgetType({
      id: item.id,
      index,
      type: event.target.value as TemplateItem["widget_type"],
    });
  };

  const onSectionInputTypeChange = (event: SelectChangeEvent) => {
    dispatch.templateForm.updateSection({
      index,
      partial: { input_type: event.target.value as TemplateItem["input_type"] },
    });
  };

  return (
    <DraggableCustom
      droppableId={item.id}
      index={index}
      Root={TemplateItemBase}
      RootProps={{ sx: isSectionTitle && index !== 0 ? { marginTop: 4 } : undefined }}
      disabled={disabled}
    >
      <TemplateItemBaseActions>
        <Tooltip title={t("Add Title")} placement="right">
          <IconButton
            disabled={disabled}
            size="small"
            onClick={onAddTitle}
            data-id={index}
          >
            <ViewStream />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("Add Question")} placement="right">
          <IconButton
            disabled={disabled}
            size="small"
            onClick={onAddSection}
            data-id={index}
          >
            <AddCircle />
          </IconButton>
        </Tooltip>
      </TemplateItemBaseActions>
      <TemplateItemTitle divider>
        <Box display="flex" alignItems="center">
          <Box width="21%">
            {isSectionTitle ? (
              <Typography>Section</Typography>
            ) : (
              <Select
                value={type}
                size="small"
                fullWidth
                onChange={onSectionTypeChange}
                disabled={disabled}
                variant="standard"
              >
                <MenuItem value="TEXT">{formatTemplateItemWidgetType("TEXT")}</MenuItem>
                <MenuItem value="PARAGRAPH">
                  {formatTemplateItemWidgetType("PARAGRAPH")}
                </MenuItem>
                <MenuItem value="CHECKBOX_LIST">
                  {formatTemplateItemWidgetType("CHECKBOX_LIST")}
                </MenuItem>
                <MenuItem value="RADIO_LIST">
                  {formatTemplateItemWidgetType("RADIO_LIST")}
                </MenuItem>
              </Select>
            )}
          </Box>
          <MoveIndicator disabled={disabled} />
          <Box width="21%" display="flex" justifyContent="flex-end">
            <IconButton
              disabled={disabled}
              size="small"
              onClick={onCopySection}
              data-id={index}
            >
              <ContentCopy />
            </IconButton>
            <IconButton
              disabled={disabled}
              size="small"
              onClick={onDeleteSection}
              data-id={index}
            >
              <Delete />
            </IconButton>
          </Box>
        </Box>
      </TemplateItemTitle>

      <TemplateItemBody>
        <TextField
          value={item.title}
          placeholder={isSectionTitle ? "Section Title" : "Title / Question"}
          fullWidth
          size="small"
          disabled={disabled}
          onChange={onChangeTitle}
        />

        {isMultipleValues &&
          item.choices?.map((choice, index) => (
            <Box key={`${index}`} mt={2}>
              <TextField
                fullWidth
                size="small"
                value={choice}
                disabled={disabled}
                inputProps={{ "data-id": index }}
                onChange={onChangeChoice}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={onDeleteChoice}
                        data-id={index}
                      >
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          ))}

        {!isSectionTitle && (
          <Box mt={2} display="flex">
            {isText && (
              <Select
                sx={{ mr: 2, maxWidth: "20%" }}
                fullWidth
                value={item.input_type}
                size="small"
                disabled={disabled}
                onChange={onSectionInputTypeChange}
                variant="standard"
              >
                <MenuItem value="any">{t("Text")}</MenuItem>
                <MenuItem value="integer">{t("Number")}</MenuItem>
                <MenuItem value="float">{t("Decimal Number")}</MenuItem>
              </Select>
            )}
            <TextField
              fullWidth
              minRows={3}
              multiline={isTextArea}
              size="small"
              disabled={disabled}
              defaultValue={item.help_text}
              onChange={onChangeHelperText}
              onKeyDown={onKeyDownHelperText}
              type={
                isText && (item.input_type === "float" || item.input_type === "integer")
                  ? "number"
                  : undefined
              }
              placeholder={
                isMultipleValues
                  ? "Type text and press enter to add option"
                  : "Placeholder text"
              }
              label=" "
            />
          </Box>
        )}

        {!isSectionTitle && (
          <Box display="flex" justifyContent="space-between" mt={4}>
            <Typography>{formatShortUUID(item.id)}</Typography>
            <FormControlLabel
              control={
                <Switch
                  disabled={disabled}
                  checked={item.required}
                  onChange={onRequired}
                  // @ts-ignore
                  inputProps={{ "data-id": index }}
                  sx={{ mr: 1 }}
                />
              }
              label="Required"
            />
          </Box>
        )}
      </TemplateItemBody>
    </DraggableCustom>
  );
};
const MemoItem = memo(Item);
