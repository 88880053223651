import { CopyText, CopyTextProps } from "@components/CopyText";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
interface DetailItemProps {
  name: string;
  content?: string | number | Date | null;
  color?: string | null;
  copyTextProps?: CopyTextProps;
}

export const DetailItem = ({
  name,
  content,
  color,
  copyTextProps,
}: DetailItemProps) => {
  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="body2" sx={{ mb: 0.5 }}>
        {name}:{" "}
      </Typography>
      {!color && (
        <Grid container alignItems={"center"} gap={0.5}>
          <Typography variant="body2" color="text.secondary">
            {!content ? "none" : String(content)}
          </Typography>
          {copyTextProps && <CopyText {...copyTextProps} />}
        </Grid>
      )}
      {color && (
        <Box borderRadius={50} bgcolor={color} height={40} width={40} mt={0.5}></Box>
      )}
    </Box>
  );
};
