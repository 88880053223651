import { ErrorPage } from "@components";
import { ProjectForm } from "@pages/workspaces/settings/projects/ProjectForm";
import { useAppSelector } from "@storeRematch";
import { useNavigate, useParams } from "react-router-dom";

export const ProjectEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = String(params.projectId);
  const project = useAppSelector(state => state.projects.dataById.get(projectId));
  const isLoading = useAppSelector(
    state => state.loading.models.projects.loading || state.loading.models.app.loading,
  );

  if (!project && !isLoading) {
    return <ErrorPage title={`Project ${projectId} not found in this workspace`} />;
  }

  return <ProjectForm project={project} onClose={() => navigate(-1)} open={true} />;
};
