import { Typography } from "@mui/material";
import { useAppSelector } from "@storeRematch";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export const ProjectFilteredRecordingsFormMessage: React.FC<
  PropsWithChildren<{ created?: boolean }>
> = ({ created, children }) => {
  const { t } = useTranslation();
  const selectedIds = useAppSelector(state => state.entityTable.selectedIds);
  const filtered = useAppSelector(state => state.projectFilterRecordings.filtered);

  if (filtered.length === selectedIds.length) {
    return (
      <Typography color="error" sx={{ mx: 2 }}>
        {t(
          created
            ? "Project can not be created, because all selected recordings are processing or have errors."
            : "Recordings can not be added to the project, because all selected recordings are processing or have errors.",
        )}
      </Typography>
    );
  }

  return <>{children}</>;
};
