import { ReactRouterLinkCustom } from "@components";
import { SettingItem } from "@components/settings/SettingItem";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { RouterHelper } from "@pages";
import { useAppSelector } from "@storeRematch";
import {
  DisableByPermissionsType,
  disabledByPermission,
  isUserAllowedFaceBlur,
} from "@utils";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

export const WorkspaceSettings: React.FC = () => {
  const { t } = useTranslation();
  const workspaceMembership = useAppSelector(
    state => state.app.currentWorkspaceMembership,
  );
  const defaultWorkspaceID = useAppSelector(
    state => state.app.apiUser?.default_workspace_id,
  );
  // TODO(ivan): disable for testing, later to be removed
  // const rawSensorData = useAppSelector(
  //   state => state.app.currentWorkspaceMembership?.workspace.raw_file_downloads,
  // );

  const disableEdit = disabledByPermission({
    type: DisableByPermissionsType.EDIT_WORKSPACE_SETTINGS,
  });

  const isDefaultWorkspace =
    workspaceMembership?.workspace.id === defaultWorkspaceID ||
    disabledByPermission({ type: DisableByPermissionsType.DELETE_WORKSPACE });

  return (
    <>
      <Grid display="grid" container>
        <SettingItem
          primary="Workspace Name"
          secondary={workspaceMembership?.workspace?.name}
        >
          <Button
            component={ReactRouterLinkCustom}
            to={RouterHelper.workspaceSettingsEdit.getRelativePath()}
            variant="text"
            color="primary"
            disabled={disableEdit}
          >
            Edit Workspace Name
          </Button>
        </SettingItem>
        {/*TODO(ivan): disable for testing, later to be removed */}
        {/* <SettingItem
          primary="Raw Data Sensor"
          secondary="Native Recording Data downloads can be found in 'Downloads'."
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Show Native Recording Data</Typography>
            <Switch
              checked={rawSensorData}
              onChange={(_, checked) =>
                dispatch.app.updateWorkspace({ raw_file_downloads: checked })
              }
              disabled={disableEdit}
            />
          </Stack>
        </SettingItem> */}
        <SettingItem primary="Upload World Video">
          <Stack spacing={1} alignItems="start">
            <PrivacyText
              primary="World video upload"
              secondary={
                workspaceMembership?.workspace.world_video_mode === "BLUR" ||
                workspaceMembership?.workspace.world_video_mode === "NORMAL"
                  ? "ON"
                  : "OFF"
              }
            />

            {isUserAllowedFaceBlur() ? (
              <PrivacyText
                primary="Face Blurrin"
                secondary={
                  workspaceMembership?.workspace.world_video_mode === "BLUR"
                    ? "ON"
                    : "OFF"
                }
              />
            ) : null}

            <Typography color="text.secondary">
              Contact us to change settings.
            </Typography>
          </Stack>
        </SettingItem>
        <SettingItem
          primary="Delete Workspace"
          secondary={
            isDefaultWorkspace
              ? t("Unable to delete default workspace.")
              : t(
                  "Deleting your workspace will delete all Pupil Cloud platform data associated with this space. Please download any data you would like to save before deleting your account.",
                )
          }
        >
          <Button
            component={ReactRouterLinkCustom}
            to={RouterHelper.workspaceSettingsDelete.getRelativePath()}
            disabled={isDefaultWorkspace}
            color="primary"
          >
            Delete Workspace
          </Button>
        </SettingItem>
      </Grid>
      <Outlet />
    </>
  );
};

const PrivacyText = ({
  primary,
  secondary,
}: {
  primary: string;
  secondary: string;
}) => {
  return (
    <Box display="flex">
      <Typography sx={{ minWidth: 130 }}>{primary}</Typography>
      <Typography sx={{ ml: 3, minWidth: 30, textAlign: "end" }}>
        {secondary}
      </Typography>
    </Box>
  );
};
