import {
  api,
  PatchProjectResourceApiArg,
  PostProjectsResourceApiArg,
  Project,
  Recording,
} from "@api";
import { RouterHelper } from "@pages";
import { createModel } from "@rematch/core";
import {
  filterCreateProjectRecordingsSave,
  rematchAdapter,
  RematchAdapter,
} from "@utils";
import i18n from "../i18n";
import type { ProjectsTableData, RootModel } from "./index";

interface ExtraPropsType {
  projectsByRecordingId: Map<string, Map<string, Project>>;
}

interface Adapter extends RematchAdapter<Project, ProjectsTableData>, ExtraPropsType {}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.id,
  sort: a =>
    a.sort((a, b) =>
      a.created_at && b.created_at
        ? new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        : 0,
    ),
  extraProps: {
    projectsByRecordingId: new Map(),
  },
});

export const projects = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
    setProjectsByRecordingId(state, data: ExtraPropsType["projectsByRecordingId"]) {
      state.projectsByRecordingId = data;
    },
  },
  effects: dispatch => ({
    async get(workspaceId: string) {
      const data = await api.getProjectsResource({ workspaceId });

      dispatch.projects.setAll(data.result);
      dispatch.projects.prepare(null);
    },
    async create(props: PostProjectsResourceApiArg) {
      const data = await api.postProjectsResource(props);

      if (typeof data !== "object") return;

      dispatch.projects.upsertOne(data.result);
      dispatch.projects.prepare(null);

      return data;
    },
    async update(props: PatchProjectResourceApiArg, state) {
      const currentProjectId = state.app.currentProject?.id;

      const data = await api.patchProjectResource(props);
      dispatch.projects.upsertOne(data.result);
      dispatch.projects.prepare(null);

      if (currentProjectId === data.result.id) {
        dispatch.app.setCurrentProject(data.result);
      }
    },
    async delete(ids: string[], state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;

      if (!workspaceId) return null;

      try {
        await api.deleteProjectsResource({
          workspaceId,
          projectsDeleteRequest: { project_ids: ids },
        });

        const message = ids.length
          ? i18n.t("Projects deleted").toString()
          : i18n.t("Project deleted").toString();
        dispatch.notifier.enqueueSnackbar({
          message,
          options: {
            variant: "success",
          },
        });

        dispatch.projects.deleteMany(ids);
        dispatch.projects.prepare(null);
      } catch (error) {
        console.error(error);
      }
    },
    prepare(_, state) {
      const projects = state.projects.data;
      const recordingsById = state.recordings.dataById;
      const membersById = state.members.dataById;
      const tableData: ProjectsTableData[] = [];
      const tableDataById = new Map<string, ProjectsTableData>();
      const projectsByRecordingId = new Map<string, Map<string, Project>>();

      projects.forEach(project => {
        const next = {
          id: project.id,
          project,
          recordings: project.recording_ids
            ? project.recording_ids.reduce<Recording[]>((acc, id) => {
                const recording = recordingsById.get(id);

                if (recording) {
                  acc.push(recording);
                  const projectsByRecordingIdRecordings = projectsByRecordingId.get(
                    recording.id,
                  );

                  if (projectsByRecordingIdRecordings) {
                    if (!projectsByRecordingIdRecordings.has(project.id)) {
                      projectsByRecordingIdRecordings.set(project.id, project);
                    }
                  } else {
                    projectsByRecordingId.set(
                      recording.id,
                      new Map().set(project.id, project),
                    );
                  }
                }

                return acc;
              }, [])
            : [],
          createdBy: membersById.get(project.created_by_user_id),
        };
        tableData.push(next);
        tableDataById.set(project.id, next);
      });

      dispatch.projects.setTableData(tableData);
      dispatch.projects.setTableDataById(tableDataById);
      dispatch.projects.setProjectsByRecordingId(projectsByRecordingId);
      dispatch.recordings.prepare(null);
    },
    async addRecordingsToProject(project: Project, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const filteredIds = filterCreateProjectRecordingsSave();
      const selectedIds = filteredIds.map(({ id }) => id);

      if (!workspaceId || !selectedIds.length) return null;

      try {
        const nextIds = new Set([...(project.recording_ids || []), ...selectedIds]);

        await dispatch.projects.update({
          workspaceId: String(workspaceId),
          projectId: project.id,
          projectPatchRequest: {
            id: project.id,
            name: project.name,
            recording_ids: Array.from(nextIds),
          },
        });
        dispatch.ctxMenu.closeAll();
        dispatch.notifier.enqueueSnackbar({
          message: i18n.t(
            `${selectedIds.length} recording${
              selectedIds.length > 1 ? "s" : ""
            } added to Project '${project.name}'`,
          ),
          options: {
            variant: "success",
            linkComponent: {
              to: RouterHelper.projectRecordings.getFullPath({
                props: { workspaceId, projectId: project.id },
              }),
              text: `Go to '${project.name}'`,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async newProjectFromSelection(project: { name: string }, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const filteredIds = filterCreateProjectRecordingsSave();
      const selectedIds = filteredIds.map(({ id }) => id);

      if (!workspaceId || !selectedIds.length) return null;

      try {
        const res = await dispatch.projects.create({
          workspaceId: String(workspaceId),
          projectPostRequest: {
            name: project.name,
            recording_ids: selectedIds,
          },
        });

        if (!res?.result) return;

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t(
            `${selectedIds.length} recording${
              selectedIds.length > 1 ? "s" : ""
            } added to Project '${res.result.name}'`,
          ),
          options: {
            variant: "success",
            linkComponent: {
              to: RouterHelper.projectRecordings.getFullPath({
                props: { workspaceId, projectId: res.result.id },
              }),
              text: `Go to '${res.result.name}'`,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async removeRecordingsFromProject(project: Project, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const selectedIds = state.entityTable.selectedIds;

      if (!workspaceId || !selectedIds.length) return null;

      try {
        const nextIds = (project.recording_ids || []).filter(
          id => id !== selectedIds[0],
        );

        await dispatch.projects.update({
          workspaceId: String(workspaceId),
          projectId: project.id,
          projectPatchRequest: {
            id: project.id,
            name: project.name,
            recording_ids: nextIds,
          },
        });
        dispatch.ctxMenu.closeAll();
        dispatch.notifier.enqueueSnackbar({
          message: i18n.t(`Recording removed from Project ${project.name}`),
          options: {
            variant: "success",
            linkComponent: {
              to: RouterHelper.projectRecordings.getFullPath({
                props: { workspaceId, projectId: project.id },
              }),
              text: project.name,
            },
          },
        });

        return nextIds;
      } catch (error) {
        console.error(error);
      }
    },
  }),
});
