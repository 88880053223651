import { Profile, WorkspaceMembership } from "@api";
import { DEMO_WORKSPACE_ID } from "@constants";
import { User } from "@sentry/react";
import { store } from "@storeRematch";

export enum DisableByPermissionsType {
  CREATE,
  EDIT,
  DELETE,
  TRASH,
  ASSIGN_LABEL_TO_RECORDING,
  RENAME_RECORDING,
  DELETE_WORKSPACE,
  INVITE_MEMBERS,
  DELETE_MEMBERS,
  EDIT_WORKSPACE_SETTINGS,
}

interface DisabledByPermissionParams {
  type: DisableByPermissionsType;
  ids?: string[];
}

export function disabledByPermission(props: DisabledByPermissionParams): boolean {
  const state = store.getState();
  const workspace = state.app.currentWorkspaceMembership;
  const user = state.app.apiUser;

  if (!workspace || !user) return true;

  if (workspace.role === "OWNER") {
    return ownerPermissions(props, user);
  }

  if (workspace.role === "ADMIN") {
    return adminPermissions(props, workspace);
  }

  if (workspace.role === "EDITOR") {
    return editorPermissions(props);
  }

  if (workspace.role === "VIEWER") {
    return viewerPermissions(props);
  }

  if (workspace.role === "DEMO") {
    if (DEMO_WORKSPACE_ID === workspace.workspace.id) {
      return viewerPermissions(props);
    }

    return true;
  }

  console.error("Unknown permission check...");

  return true;
}

function aggregation({ type }: DisabledByPermissionParams) {
  if (type === DisableByPermissionsType.DELETE_WORKSPACE) return true;
  if (type === DisableByPermissionsType.DELETE_MEMBERS) return true;
  if (type === DisableByPermissionsType.INVITE_MEMBERS) return true;
  if (type === DisableByPermissionsType.EDIT_WORKSPACE_SETTINGS) return true;

  return false;
}

function ownerPermissions(props: DisabledByPermissionParams, user: User | Profile) {
  if (props.type === DisableByPermissionsType.DELETE_MEMBERS) {
    if (props.ids?.find(id => id === user.id)) {
      return true;
    }

    return false;
  }

  return false;
}

function adminPermissions(
  props: DisabledByPermissionParams,
  workspace: WorkspaceMembership,
) {
  if (props.type === DisableByPermissionsType.DELETE_MEMBERS) {
    if (props.ids?.find(id => id === workspace.workspace.created_by_user_id)) {
      return true;
    }

    return false;
  }

  if (props.type === DisableByPermissionsType.INVITE_MEMBERS) return false;
  if (props.type === DisableByPermissionsType.EDIT_WORKSPACE_SETTINGS) return false;

  return aggregation(props);
}

function editorPermissions(props: DisabledByPermissionParams) {
  return aggregation(props);
}

function viewerPermissions(props: DisabledByPermissionParams) {
  if (props.type === DisableByPermissionsType.CREATE) return true;
  if (props.type === DisableByPermissionsType.EDIT) return true;
  if (props.type === DisableByPermissionsType.TRASH) return true;
  if (props.type === DisableByPermissionsType.DELETE) return true;

  return aggregation(props);
}
