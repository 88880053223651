import { DropResult } from "react-beautiful-dnd";

export function onDragEnd<T>(selector: (state: T) => Array<unknown>) {
  return function (state: T, { destination, source }: DropResult) {
    if (!destination) return;

    const arr = selector(state);

    const [removed] = arr.splice(source.index, 1);
    arr.splice(destination.index, 0, removed);
  };
}
