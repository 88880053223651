import { store } from "@storeRematch/index";

let disabled = false;

export function isScrollDisabled() {
  return disabled || !store.getState().video.videoEventsBackgroundMove;
}

export function setScrollDisabled(next: boolean) {
  disabled = next;
}
