import {
  ProjectFilteredRecordingsFormMessage,
  ProjectFilteredRecordingsMessage,
} from "@components";
import { Box, Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import {
  ProjectFilterRecordingsType,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import { useTranslation } from "react-i18next";

export const ProjectAddDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(state => state.loading.models.app.loading);
  const type = useAppSelector(state => state.projectFilterRecordings.type);
  const filtered = useAppSelector(state => state.projectFilterRecordings.filtered);
  const disabled = useAppSelector(
    state =>
      state.projectFilterRecordings.filtered.length ===
      state.entityTable.selectedIds.length,
  );

  if (isLoading) return null;
  if (type !== ProjectFilterRecordingsType.ADD) return null;
  if (!filtered.length) return null;

  return (
    <Dialog open fullWidth maxWidth="sm">
      <DialogTitle>{t("Add to project")}</DialogTitle>

      <Box sx={{ px: 1.5, mb: 2 }}>
        <ProjectFilteredRecordingsMessage />

        <ProjectFilteredRecordingsFormMessage />
      </Box>

      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={() => dispatch.projectFilterRecordings.reset()}
        >
          {t("Cancel")}
        </Button>
        <Button
          color="primary"
          disabled={disabled}
          onClick={() => dispatch.projectFilterRecordings.addToProject(null)}
        >
          {t("Continue")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
