import { KeyboardArrowDown } from "@mui/icons-material";
import {
  AutocompleteProps,
  ListItemText,
  Autocomplete as MuiAutocomplete,
  TextField,
} from "@mui/material";
import { useFormContext, useFormState } from "react-hook-form";

export const Autocomplete = <T extends { value: string; label: string }>({
  name,
  ...props
}: Omit<AutocompleteProps<T, any, any, any>, "renderInput"> & { name: string }) => {
  const methods = useFormContext();
  const { errors } = useFormState();

  return (
    <MuiAutocomplete
      {...props}
      {...methods.register(name)}
      isOptionEqualToValue={(a, b) => a.value === b.value}
      getOptionLabel={a => (typeof a === "string" ? a : a.label)}
      filterOptions={(arr, { inputValue }) => {
        const next = arr.filter(({ label }) =>
          label.toLocaleLowerCase().startsWith(inputValue.toLocaleLowerCase()),
        );

        return next;
      }}
      filterSelectedOptions
      onChange={(_, value) => {
        if (
          value &&
          typeof value === "object" &&
          !Array.isArray(value) &&
          value.value
        ) {
          methods.setValue(name, value.value);
        } else {
          methods.setValue(name, "");
        }
      }}
      componentsProps={{
        paper: { elevation: 4 },
      }}
      popupIcon={<KeyboardArrowDown />}
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          error={!!errors[name]}
          helperText={errors[name]?.message as string}
        />
      )}
      renderOption={(props, item) => (
        <li {...props} key={item.value}>
          <ListItemText>{item.label}</ListItemText>
        </li>
      )}
    />
  );
};
