import { Box, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { ViewDetailsTypes, useAppDispatch, useAppSelector } from "@storeRematch";
import { LabelContent } from "./LabelContent";
import { ProjectContent } from "./ProjectContent";
import { RecordingContent } from "./RecordingContent";
import { TemplateContent } from "./TemplateContent";
import { WearerContent } from "./WearerContent";

const ViewDetailsContainer: React.FC<{ title: string; content: React.ReactNode }> = ({
  title,
  content,
}) => {
  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container display={"grid"} gap={1}>
          {content}
        </Grid>
      </DialogContent>
    </>
  );
};

export const ViewDetails = () => {
  const state = useAppSelector(state => state.viewDetails.data);
  const dispatch = useAppDispatch();

  if (!state) return null;

  return (
    <Dialog open onClose={dispatch.viewDetails.reset} fullWidth maxWidth="sm">
      <Box sx={{ borderRadius: 4 }}>
        {state.type === ViewDetailsTypes.RECORDING && (
          <ViewDetailsContainer
            title="Recording Details"
            content={<RecordingContent model={state.model} />}
          />
        )}
        {state.type === ViewDetailsTypes.LABEL && (
          <ViewDetailsContainer
            title="Label Details"
            content={<LabelContent model={state.model} />}
          />
        )}
        {state.type === ViewDetailsTypes.WEARER && (
          <ViewDetailsContainer
            title="Wearer Details"
            content={<WearerContent model={state.model} />}
          />
        )}
        {state.type === ViewDetailsTypes.TEMPLATE && (
          <ViewDetailsContainer
            title="Template Details"
            content={<TemplateContent model={state.model} />}
          />
        )}
        {state.type === ViewDetailsTypes.PROJECT && (
          <ViewDetailsContainer
            title="Project Details"
            content={<ProjectContent model={state.model} />}
          />
        )}
      </Box>
    </Dialog>
  );
};
