import { ProjectEnrichment } from "@api";
import { EntityDataTable, NoDataDefault, ReactRouterLinkCustom } from "@components";
import { Circle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { RouterHelper } from "@pages";
import { LabelsTableData, useAppSelector } from "@storeRematch";
import {
  DisableByPermissionsType,
  LocalStorageAdapterNames,
  disabledByPermission,
} from "@utils";
import { formatHexColor } from "@utils/formatHexColor";
import { Outlet, useNavigate } from "react-router-dom";
import TimeAgo from "timeago-react";
import { LabelsContextMenu } from "./LabelsContextMenu";

export const Labels = () => {
  const navigate = useNavigate();
  const rows = useAppSelector(state => state.labels.tableData);
  const isLoading = useAppSelector(
    state => state.loading.effects.app.loadWorkspaceData.loading,
  );
  const disabledPermissions = disabledByPermission({
    type: DisableByPermissionsType.CREATE,
  });

  return (
    <>
      <EntityDataTable
        loading={isLoading}
        rows={rows}
        columns={columns}
        noFilters
        noColumnFilter
        noDataMessage={<NoDataDefault name="labels" />}
        tableColumnInitialStateStorageKey={
          LocalStorageAdapterNames.settingsLabelsColumState
        }
        onRowDoubleClick={(props: GridRowParams<ProjectEnrichment>) => {
          navigate(
            RouterHelper.workspaceSettingsLabelsEdit.getRelativePath({
              props: { labelId: props.row.id },
            }),
          );
        }}
      >
        <Button
          component={ReactRouterLinkCustom}
          to={RouterHelper.workspaceSettingsLabelsNew.getRelativePath()}
          replace={true}
          color="primary"
          disabled={disabledPermissions}
        >
          New Label
        </Button>
      </EntityDataTable>
      <LabelsContextMenu />
      <Outlet />
    </>
  );
};

const columns: GridColDef<LabelsTableData>[] = [
  {
    width: 200,
    field: "name",
    headerName: "Name",
    valueGetter: (_, row) => row.label.name,
    renderCell: params => {
      return (
        <span>
          <Circle
            sx={{
              color: formatHexColor(params.row.label.color),
              mr: 1,
              my: 0.5,
            }}
          />
          <span>{params.row.label.name}</span>
        </span>
      );
    },
  },
  {
    field: "recordingsCount",
    headerName: "Recordings",
    type: "number",
    align: "left",
  },
  {
    field: "created_at",
    headerName: "Created",
    type: "date",
    valueGetter: (_, row) => new Date(row.label.created_at),
    renderCell: params => {
      return (
        <TimeAgo datetime={params.row.label.created_at} opts={{ minInterval: 60 }} />
      );
    },
  },
];
