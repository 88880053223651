import { useAppDispatch } from "@storeRematch";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// So that we don't need to hookup react-router with redux
export const CtxMenuControl = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch.ctxMenu.closeAll();
  }, [pathname, dispatch]);

  return null;
};
