import { RecordingEvent } from "@api";

export function findAllEventSlices({
  events,
  from,
  to,
  duration,
  selection,
}: {
  events: Set<RecordingEvent> | null | undefined;
  from: string;
  to: string;
  duration: number | undefined;
  selection: {
    from: string | null;
    to: string | null;
  };
}) {
  const found: { start?: number; end?: number; key: string }[] = [{ key: "0" }];
  let currentIndex = 0;
  const arr = Array.from(events || []).sort((a, b) => a.offset_s - b.offset_s);

  const fromChecked = selection.from ? selection.from : from;
  const toChecked = selection.to ? selection.to : to;

  arr.forEach(e => {
    if (e.name === fromChecked && found[currentIndex].start === undefined) {
      found[currentIndex].start = (e.offset_s / (duration || 0)) * 100;
      found[currentIndex].end = undefined;
    }

    if (e.name === toChecked && found[currentIndex].end === undefined) {
      found[currentIndex].end = (e.offset_s / (duration || 0)) * 100;
    }

    if (
      found[currentIndex].start !== undefined &&
      found[currentIndex].end !== undefined &&
      isFinite(found[currentIndex].start as number) &&
      isFinite(found[currentIndex].end as number)
    ) {
      currentIndex += 1;
      found.push({ key: currentIndex.toString() });
    }
  });

  return found;
}
