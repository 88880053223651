import { SurfaceCornerNames, SurfaceResult } from "@api";
import { store } from "@storeRematch";
import { DisableByPermissionsType, disabledByPermission } from "@utils";
import { drag } from "d3";
import { overlayCalculations } from "./OverlayCalculations";
import { overlayControl } from "./OverlayControl";
import { ShapesControl, ShapesControlChildProps } from "./ShapesControl";

interface LastMoved {
  name: SurfaceCornerNames | null;
  scaled: number[] | null;
  corners: Partial<Record<SurfaceCornerNames, number[]>> | null;
}

const defaultLastMove: LastMoved = {
  name: null,
  scaled: null,
  corners: null,
};

export class SurfaceCornersOverlay extends ShapesControl<
  SurfaceResult,
  SVGCircleElement
> {
  lastMoved: LastMoved = { ...defaultLastMove };

  constructor(props?: ShapesControlChildProps) {
    super({
      shapeType: "circle",
      shapeIds: ["1", "2", "3", "4"],
      canvasId: "SurfaceCornersOverlay",
      ...props,
    });
  }

  isDisabled() {
    return (
      disabledByPermission({ type: DisableByPermissionsType.EDIT }) ||
      store.getState().projectEdit.currentEnrichment?.slices.length
    );
  }

  getData(
    {
      event,
      index,
      shape,
    }: {
      shape: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;
      event: SurfaceResult;
      index: number;
    },
    lastMovedProps: { nextMovedPoint?: number[]; useLastMoved?: boolean } = {},
  ) {
    const { nextMovedPoint, useLastMoved = false } = lastMovedProps;

    if (!nextMovedPoint && !useLastMoved) this.lastMoved = { ...defaultLastMove };

    const corners = event.corners;

    if (!corners) return;

    const cornersArr = Object.keys(corners) as SurfaceCornerNames[];
    const name = cornersArr[index];
    const point = corners[name];

    if (!name || !point || point.length !== 2) return;

    const scaled = nextMovedPoint
      ? nextMovedPoint
      : useLastMoved && this.lastMoved.corners && this.lastMoved.corners[name]
      ? this.lastMoved.corners[name]
      : overlayCalculations.distortAndScale(point);

    if (nextMovedPoint) this.lastMoved.name = name;
    if (useLastMoved || nextMovedPoint) {
      if (this.lastMoved.corners) this.lastMoved.corners[name] = scaled;
      else this.lastMoved.corners = { [name]: scaled };
    }

    if (!scaled) return;

    // Way to detect orientation
    const nextCoordName =
      name === SurfaceCornerNames.TOP_LEFT
        ? SurfaceCornerNames.TOP_RIGHT
        : name === SurfaceCornerNames.TOP_RIGHT
        ? SurfaceCornerNames.BOTTOM_RIGHT
        : name === SurfaceCornerNames.BOTTOM_RIGHT
        ? SurfaceCornerNames.BOTTOM_LEFT
        : SurfaceCornerNames.TOP_LEFT;

    const next =
      this.lastMoved.corners && this.lastMoved.corners[nextCoordName]
        ? this.lastMoved.corners[nextCoordName]
        : corners[nextCoordName];

    if (!next) return;

    const inter =
      this.lastMoved.corners && this.lastMoved.corners[nextCoordName]
        ? undefined
        : overlayCalculations.interpolateFromCorners([point, next]);

    return {
      point,
      next,
      name,
      corners,
      scaled,
      inter,
      shape,
      event,
      index,
    };
  }

  dragCoords(xy: { x: number; y: number }) {
    const width = overlayControl.instance?.currentWidth() || 0;
    const height = overlayControl.instance?.currentWidth() || 0;
    const copy = { ...xy };

    if (copy.x <= 0) {
      copy.x = 0;
    } else if (copy.x >= width) {
      copy.x = width;
    }

    if (copy.y <= 0) {
      copy.y = 0;
    } else if (copy.y >= height) {
      copy.y = height;
    }

    return copy;
  }

  drag({
    event,
    index,
    shape,
  }: {
    shape: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;
    event: SurfaceResult;
    index: number;
  }) {
    return drag<SVGCircleElement, unknown>()
      .on("drag", d => {
        if (this.isDisabled()) return;

        this.clearCtx();

        const { x, y } = this.dragCoords(d);

        const firstData = this.getData(
          { event, index, shape },
          { nextMovedPoint: [x, y] },
        );

        if (!firstData) return;

        this.draw(firstData);

        this.shapes.forEach((shape, i) => {
          if (i === index) return;

          const data = this.getData({ event, index: i, shape }, { useLastMoved: true });

          if (!data) return;

          this.draw(data);
        });
      })
      .on("end", () => {
        if (!overlayControl.instance) return;
        if (this.isDisabled()) return;

        store.dispatch.video.setDistortedLocationUsingRecording(
          overlayControl.instance.currentTime(),
        );
      });
  }

  paint({
    shape,
    event,
    index,
  }: {
    shape: d3.Selection<SVGCircleElement, unknown, HTMLElement, any>;
    event?: SurfaceResult;
    index: number;
  }) {
    if (!event || !event.corners) return;

    const data = this.getData({
      shape,
      event,
      index,
    });

    this.draw(data);
  }

  draw(data: ReturnType<SurfaceCornersOverlay["getData"]>) {
    if (!data) return;

    const { shape, event, index, name, scaled, inter, next } = data;

    if (overlayControl.instance?.paused() && !this.isDisabled()) {
      this.showShape(shape);
      shape
        .attr("r", 6)
        .attr("stroke", "#039BE5")
        .attr("stroke-width", 2)
        .attr("fill", "#fff")
        .attr("cx", scaled[0])
        .attr("cy", scaled[1])
        .style("cursor", "pointer")
        .call(this.drag({ shape, event, index }));
    }

    const ctx = this.getCtx();

    if (!ctx) return;

    ctx.beginPath();
    if (inter) {
      for (let i = 0; i < inter.length; i += 6) {
        ctx.bezierCurveTo(
          inter[i],
          inter[i + 1],
          inter[i + 2],
          inter[i + 3],
          inter[i + 4],
          inter[i + 5],
        );
      }
    } else {
      ctx.moveTo(scaled[0], scaled[1]);
      ctx.lineTo(next[0], next[1]);
    }

    ctx.lineWidth = 2;
    ctx.strokeStyle = name === SurfaceCornerNames.BOTTOM_RIGHT ? "#f00" : "#039BE5";
    ctx.stroke();
  }
}
