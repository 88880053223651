import { BackButton } from "@components";
import { LinkMenuList } from "@components/Common/LinkMenuList";
import { Loader } from "@components/Loader";
import { Box, Unstable_Grid2 as Grid, Paper } from "@mui/material";
import { useAppSelector } from "@storeRematch";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

export const WorkspaceSettingsLayout = () => {
  const { t } = useTranslation();

  const links = [
    {
      name: t("General Settings"),
      to: "",
    },
    {
      name: t("Wearers"),
      secondary: useAppSelector(state => state.wearers.data.length),
      to: "wearers",
    },
    {
      name: t("Labels"),
      secondary: useAppSelector(state => state.labels.data.length),
      to: "labels",
    },
    {
      name: t("Members"),
      secondary: useAppSelector(state => state.members.data.length),
      to: "members",
    },
    {
      name: t("Templates"),
      secondary: useAppSelector(state => state.templates.data.length),
      to: "templates",
    },
    {
      name: t("Projects"),
      secondary: useAppSelector(state => state.projects.data.length),
      to: "projects",
    },
  ];
  const workspace = useAppSelector(state => state.app.currentWorkspaceMembership);
  if (!workspace) {
    return <Loader />;
  }
  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid sx={{ height: "100%" }} xs={3} md={2}>
        <Paper sx={{ height: "100%" }} elevation={1}>
          <Box p={1}>
            <BackButton />
            <LinkMenuList links={links} />
          </Box>
        </Paper>
      </Grid>
      <Grid sx={{ height: "100%" }} padding={2} pb={0} xs={9} md={10}>
        <Outlet />
      </Grid>
    </Grid>
  );
};
