import { store } from "@storeRematch";

export const isStaticImageMapperPointerCanvasDisabled = () => {
  const state = store.getState();

  return (
    state.staticImageMappers.currentFixationIndex === null ||
    !state.video.recording?.has_scanpath
  );
};
