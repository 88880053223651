import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const BreadCrumbsText = styled(Typography, {
  shouldForwardProp: prop => prop !== "minWidthOff",
})<{ minWidthOff?: boolean }>(({ minWidthOff, theme }) => ({
  minWidth: minWidthOff ? "auto" : "20px",
  maxWidth: 250,

  [theme.breakpoints.down("md")]: {
    maxWidth: 100,
  },
}));
BreadCrumbsText.defaultProps = {
  noWrap: true,
  variant: "body1",
};
