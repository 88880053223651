import { SvgIcon, SvgIconProps } from "@mui/material";

export const DrawPointer: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.58983 1.05939C1.44569 1.00007 1.28721 0.984762 1.13437 1.01538C0.981535 1.046 0.841183 1.12118 0.731007 1.23144C0.620831 1.3417 0.545762 1.48211 0.515261 1.63497C0.484761 1.78783 0.500195 1.9463 0.559617 2.09041L6.08567 15.511C6.14486 15.6548 6.24518 15.7779 6.37406 15.8649C6.50294 15.9519 6.65464 15.9989 6.81012 16C6.96561 16.0011 7.11795 15.9562 7.24805 15.8711C7.37814 15.7859 7.48019 15.6642 7.54139 15.5213L9.66103 10.5754C9.74088 10.3892 9.88927 10.2408 10.0755 10.1609L15.0213 8.04127C15.1642 7.98007 15.2859 7.87802 15.3711 7.74792C15.4562 7.61782 15.5011 7.46548 15.5 7.30999C15.4989 7.1545 15.4519 7.0028 15.3649 6.87392C15.2779 6.74504 15.1548 6.64471 15.011 6.58553L1.58983 1.05939Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
