import { CropFree, ZoomInMap } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { useEffect } from "react";

export const VideoFullScreen = () => {
  const dispatch = useAppDispatch();
  const fullScreen = useAppSelector(state => state.video.fullScreen);
  const resetOnSwitch = useAppSelector(state => state.app.resetOnSwitch);

  useEffect(() => {
    dispatch.video.setFullScreen(false);
  }, [resetOnSwitch, dispatch]);

  return (
    <IconButton onClick={dispatch.video.toggleFullScreenEffect}>
      {fullScreen ? <ZoomInMap /> : <CropFree />}
    </IconButton>
  );
};
