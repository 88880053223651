import { ReactRouterLinkCustom } from "@components";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { Button, Divider, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { isVisualizationNotDownloadable } from "@utils";
import { useTranslation } from "react-i18next";
import { ProjectEnrichmentForm } from "../ProjectEnrichment";

export const Render = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column", width: 300 }}>
      <Box display="flex" alignItems="center" py={1} px={2} height={44}>
        <IconButton
          sx={{ mr: 1 }}
          size="small"
          component={ReactRouterLinkCustom}
          to={`../visualizations`}
        >
          <KeyboardArrowLeft fontSize="small" />
        </IconButton>
        <Typography>{t("Video Renderer")}</Typography>
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" flexGrow={1} px={2}>
        <ProjectEnrichmentForm />
        <DownloadButton />
      </Box>
    </Box>
  );
};

const DownloadButton = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const enrichment = useAppSelector(state => state.projectEdit.currentEnrichment);
  const isDisabled = isVisualizationNotDownloadable(enrichment);

  return (
    <Button
      sx={{ mt: "auto", mb: 2 }}
      color="primary"
      disabled={isDisabled}
      onClick={() => {
        dispatch.download.download(enrichment?.id);
      }}
      fullWidth
    >
      {t("Download")}
    </Button>
  );
};
