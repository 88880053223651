import {
  Dragger,
  NewProjectModelDialogMain,
  ReactRouterLinkCustom,
  RecordingQuickSelectBar,
  RecordingViewer,
} from "@components";
import { ErrorPage } from "@components/ErrorPage";
import { ModalIds } from "@components/onboarding/OnboardingConfig";
import {
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { RouterHelper } from "@pages";
import { DraggerTypes, useAppDispatch, useAppSelector } from "@storeRematch";
import { LocalStorageAdapterNames } from "@utils";
import { FC, PropsWithChildren, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, matchPath, useLocation, useParams } from "react-router-dom";
import { ProjectRecordings } from "./ProjectRecordings";

export const ProjectLayout = () => {
  const params = useParams();
  const projectId = String(params.projectId);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isWorkspaceAllowed = useAppSelector(state => state.app.isWorkspaceAllowed);
  const isProjectAllowed = useAppSelector(state => state.app.isProjectAllowed);
  const size = useAppSelector(state => state.dragger.tableProject.size);
  const isLoaded = useAppSelector(
    state => state.loading.effects.app.loadWorkspaceData.success,
  );
  const user = useAppSelector(state => state.app.apiUser);
  const fullScreen = useAppSelector(state => state.video.fullScreen);
  const location = useLocation();
  const isEnrichment = !!matchPath(
    RouterHelper.projectEnrichmentsEdit.fullTemplatePath,
    location.pathname,
  );
  const isRecordings = !!matchPath(
    RouterHelper.projectRecordings.fullTemplatePath,
    location.pathname,
  );
  const isHeatmap = !!matchPath(
    RouterHelper.projectVisualizationsHeatmap.fullTemplatePathAny,
    location.pathname,
  );
  const isRender = !!matchPath(
    RouterHelper.projectVisualizationsRender.fullTemplatePathAny,

    location.pathname,
  );
  const isAOI = !!matchPath(
    RouterHelper.projectEnrichmentsAOI.fullTemplatePath,
    location.pathname,
  );
  const isVisualizationsAOI = !!matchPath(
    RouterHelper.projectVisualizationsAoi.fullTemplatePath,
    location.pathname,
  );

  useEffect(() => {
    if (isLoaded) {
      dispatch.app.loadProjectData(projectId);
      dispatch.entityTable.setProjectDefaultSelected(null);
    }

    return () => {
      dispatch.app.setCurrentProject(null);
      dispatch.entityTable.setWorkspaceDefaultSelected(null);
      dispatch.projectEvents.reset();
      dispatch.enrichments.reset();
      dispatch.visualizations.reset();
    };
  }, [dispatch, projectId, isLoaded]);

  useEffect(() => {
    if (!isWorkspaceAllowed && isLoaded) {
      dispatch.notifier.enqueueSnackbar({
        message: t("You do not have access to this project"),
        options: {
          key: "project_not_allowed",
          variant: "error",
          autoHideDuration: null,
          linkComponent: {
            to: user
              ? RouterHelper.workspaceRecordings.getFullPath({
                  props: { workspaceId: user.default_workspace_id },
                })
              : "/",
            text: t("Go to Default Workspace"),
          },
        },
      });
    }
  }, [isWorkspaceAllowed, isLoaded, user, dispatch, t]);

  if (!isWorkspaceAllowed && isLoaded) {
    return (
      <ErrorPage title={t("Permission Failed")}>
        {t("You do not have access to this project.")}
      </ErrorPage>
    );
  }

  if (!isProjectAllowed && isLoaded) {
    return (
      <ErrorPage title={t("Project not found")}>
        {t("This project does not exist.")}
      </ErrorPage>
    );
  }

  return (
    <Box display="flex" height="100%" flexDirection="row">
      {!fullScreen && !isAOI && !isVisualizationsAOI && !isHeatmap && !isRender ? (
        <Box id={ModalIds.DOWNLOADS} display="flex" width="30%" maxWidth="159px">
          <Paper
            sx={{
              height: "100%",
              width: "100%",
              borderRadius: 0,
              backgroundColor: "customColors.mauve1",
            }}
            elevation={0}
          >
            <ProjectNavigation />
          </Paper>
        </Box>
      ) : null}
      <Box
        flex="1"
        overflow="hidden"
        display="flex"
        flexWrap="nowrap"
        flexDirection="row"
        //flexGrow={1}
      >
        <Box
          id={ModalIds.ENRICH_SETUP}
          sx={{
            display: fullScreen ? "none" : "flex",
            flexGrow: 1,
            width: isEnrichment || isAOI ? "100%" : `calc(100% - ${size}px)`,
            maxWidth: isEnrichment || isRender ? "300px" : "unset",
            minWidth: isEnrichment || isRender ? "300px" : "unset",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Outlet />
        </Box>
        {(isRecordings || isEnrichment || isRender) && (
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              position: "relative",
              borderLeft: "1px solid",
              borderColor: "divider",
              width: fullScreen || isRender || isEnrichment ? "100%" : size,
            }}
          >
            {isRecordings && <Dragger type={DraggerTypes.TABLE_PROJECT} vertical />}
            {isEnrichment || isRender ? (
              <RecordingQuickSelectBar>
                <ProjectRecordings
                  onCustomSelect={(id: string) => {
                    dispatch.projectEdit.setCurrentRecordingIdToLoad(id);
                    dispatch.entityTable.setSelected([id]);
                  }}
                  singleSelect
                  noContextMenu
                  saveFilter
                  tableColumnInitialStateStorageKey={
                    LocalStorageAdapterNames.projectRecordingsExtraColumState
                  }
                  cssHeaderBackGround={"#28282B"}
                />
              </RecordingQuickSelectBar>
            ) : null}
            <RecordingViewer />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ProjectNavigation = () => {
  const location = useLocation();
  const isEnrichment =
    !!matchPath(RouterHelper.projectEnrichments.fullTemplatePath, location.pathname) ||
    !!matchPath(
      RouterHelper.projectEnrichmentsEdit.fullTemplatePath,
      location.pathname,
    );
  const isRecordings = !!matchPath(
    RouterHelper.projectRecordings.fullTemplatePath,
    location.pathname,
  );
  const isDownloads = !!matchPath(
    RouterHelper.projectDownloads.fullTemplatePath,
    location.pathname,
  );
  const isVisualizations = !!matchPath(
    RouterHelper.projectVisualizations.fullTemplatePath,
    location.pathname,
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      id={ModalIds.PROJECT}
    >
      <NewProjectModelDialogMain buttonId={ModalIds.ENRICHMENTS} />
      <MenuList
        sx={{
          p: 0,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          borderRight: "1px solid black",
          borderColor: "divider",
        }}
      >
        <ProjectNavigationItem
          text="Recordings"
          to={RouterHelper.projectRecordings.getRelativePath({ prefix: "./" })}
          checked={isRecordings}
        />

        <ProjectNavigationItem
          text="Enrichments"
          to={RouterHelper.projectEnrichments.getRelativePath({ prefix: "./" })}
          checked={isEnrichment}
        />

        <ProjectNavigationItem
          text="Visualizations"
          to={RouterHelper.projectVisualizations.getRelativePath({ prefix: "./" })}
          checked={isVisualizations}
        />

        <ProjectNavigationItem
          text="Downloads"
          to={RouterHelper.projectDownloads.getRelativePath({ prefix: "./" })}
          checked={isDownloads}
        />
      </MenuList>
    </Box>
  );
};

const ProjectNavigationItem: FC<
  PropsWithChildren<{
    text: string;
    to: string;
    checked?: boolean;
  }>
> = ({ text, to, checked }) => {
  return (
    <Tooltip title={text} placement="right">
      <MenuItem
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: checked ? "action.selected" : "inherit",
          px: 1,
          height: 60,
          justifyContent: "center",
          borderBottom: "1px solid black",
          borderColor: "divider",
        }}
        component={ReactRouterLinkCustom}
        to={to}
        onClick={e => e.currentTarget.blur()}
      >
        <ListItemText
          sx={{ display: "flex", width: "100%" }}
          primary={
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                color: checked ? "text.primary" : "text.secondary",
                m: "auto",
                ml: 2,
              }}
            >
              {text}
            </Typography>
          }
        />
      </MenuItem>
    </Tooltip>
  );
};
