import { ScanPathDatapoint } from "@api";
import { store } from "@storeRematch";
import { overlayCalculations } from "./OverlayCalculations";
import { ShapesControl, ShapesControlChildProps } from "./ShapesControl";
import { drawScanPath } from "./helpers";

export class ScanPathOverlay extends ShapesControl<
  ScanPathDatapoint,
  SVGCircleElement
> {
  onlyCurrent = false;

  constructor(props?: ShapesControlChildProps) {
    super({ canvasId: "ScanPathOverlay", paintType: true, ...props });
  }

  get hide() {
    return super.hide;
  }

  set hide(next: boolean) {
    store.dispatch.projectEdit.setHideFixation(next);
    super.hide = next;
  }

  paintAll(event?: ScanPathDatapoint) {
    if (!event) return;

    const ctx = this.getCtx();

    if (!ctx) return;

    const state = store.getState().staticImageMappers;
    const currentFixationIndex = state.currentFixationIndex;
    const currentFixationId =
      currentFixationIndex === null
        ? null
        : state.fixations[currentFixationIndex]?.fixation_id;

    for (let i = 0; i < event.path.length; i++) {
      const p1 = event.path[i];
      const p2 = event.path[i + 1];

      if (!p1 || (this.onlyCurrent && p1.id !== currentFixationId)) continue;

      const scaled1 = overlayCalculations.scaleCoordinates({ x: p1.x, y: p1.y });

      if (!scaled1) continue;

      const radius = 2 * Math.sqrt(p1.ms / Math.PI);
      const scaledRadius = overlayCalculations.scaleCoordinates({
        x: radius,
        y: radius,
      });

      drawScanPath({
        ctx,
        x: scaled1.x,
        y: scaled1.y,
        radius: scaledRadius ? scaledRadius.x : 0,
        text: p1.id.toString(),
      });

      if (!p2 || this.onlyCurrent) continue;

      const scaled2 = overlayCalculations.scaleCoordinates({ x: p2.x, y: p2.y });

      if (!scaled2) continue;

      ctx.beginPath();
      ctx.moveTo(scaled1.x, scaled1.y);
      ctx.lineTo(scaled2.x, scaled2.y);
      ctx.strokeStyle = "#90A4AE";
      ctx.lineWidth = 2;
      ctx.stroke();
      ctx.closePath();
    }
  }
}
