import {
  FormControlLabel,
  FormControlLabelProps,
  Switch as MuiSwitch,
  SwitchProps,
} from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const Switch: FC<
  SwitchProps & {
    name: string;
    label?: string;
    noMargin?: boolean;
    labelPlacement?: FormControlLabelProps["labelPlacement"];
    colorSecondary?: boolean;
  }
> = ({ name, label, noMargin, labelPlacement = "start", colorSecondary, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field: { value, onChange } }) => {
        return (
          <FormControlLabel
            control={
              <MuiSwitch
                {...props}
                onChange={onChange}
                checked={value}
                sx={{
                  ml: labelPlacement === "start" ? 1 : 0,
                  mr: labelPlacement === "end" ? 1 : 0,
                }}
              />
            }
            labelPlacement={labelPlacement}
            label={label}
            sx={{
              m: noMargin ? 0 : "default",
              color: colorSecondary ? "text.secondary" : "default",
            }}
          />
        );
      }}
      name={name}
      control={control}
    />
  );
};
