import { OffsetCorrection, Recording, api } from "@api";
import {
  gazeOffsetOverlay,
  gazeOverlay,
  overlayControl,
} from "@components/videoPlayer/controller";
import { createModel } from "@rematch/core";
import i18n from "../i18n";
import type { RootModel } from "./index";

interface InitialState {
  originalOffset: OffsetCorrection | null;
  androidOffset: OffsetCorrection | null;
  nextOffset: OffsetCorrection | null;
}

const initialState: InitialState = {
  originalOffset: null,
  androidOffset: null,
  nextOffset: null,
};

export const recordingGazeOffset = createModel<RootModel>()({
  state: initialState,
  reducers: {
    changeRecordingOffset(state, data: Recording) {
      const offset = { ...data.gaze_offset };
      const androidOffset = { ...data.android_gaze_offset };

      state.originalOffset = data.gaze_offset;
      state.androidOffset = data.android_gaze_offset;

      gazeOverlay.offset = offset;
      gazeOverlay.androidOffset = androidOffset;
      overlayControl.shouldRender(gazeOverlay);

      gazeOffsetOverlay.moved = false;
      gazeOffsetOverlay.initOffset = offset;
      overlayControl.shouldRender(gazeOffsetOverlay);
    },
    setNextOffset(state, data: InitialState["nextOffset"]) {
      state.nextOffset = data;
    },
  },
  effects: dispatch => ({
    async updateOffset(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const recording = state.video.recording;
      const offset = state.recordingGazeOffset.nextOffset;

      if (!workspaceId || !recording || !offset) return;

      try {
        await api.patchRecording({
          workspaceId,
          recordingId: recording.id,
          recordingPatchRequest: {
            id: recording.id,
            gaze_offset_x: offset.x,
            gaze_offset_y: offset.y,
          },
        });

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Recording gaze offset update"),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
  }),
});
