import { ReactRouterLinkCustom } from "@components";
import {
  Box,
  ListItemIcon,
  MenuItem,
  MenuItemProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { sanitizeForRegex } from "@utils";
import React, { FC, useEffect, useState } from "react";

export type CtxMenuItemProps = MenuItemProps & {
  label?: string;
  subLabel?: string;
  boldText?: string;
  icon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  to?: string;
  tooltip?: string;
  defaultTooltip?: boolean;
  href?: string;
  target?: string;
  rel?: string;
};

export const CtxMenuItem = React.forwardRef<HTMLLIElement | null, CtxMenuItemProps>(
  (
    {
      label,
      subLabel,
      boldText,
      icon,
      rightIcon,
      disabled,
      to,
      tooltip,
      defaultTooltip,
      href,
      ...props
    },
    ref,
  ) => {
    const internalLinkProps = to ? { component: ReactRouterLinkCustom, to } : {};
    const externalLinkProps = {
      component: "a",
      href,
      target: "_blank",
      rel: "noreferrer",
    };
    const linkProps = href ? externalLinkProps : internalLinkProps;
    const [data, setData] = useState<{ text?: JSX.Element[]; subText?: JSX.Element[] }>(
      {},
    );

    useEffect(() => {
      if (boldText) {
        const regex = new RegExp(sanitizeForRegex(boldText), "gi");
        const foundLabel = label?.match(regex) || [];
        const foundSubLabel = subLabel?.match(regex) || [];

        setData({
          text: label?.split(regex).map((x, i, arr) => {
            return (
              <Typography component="span" key={x}>
                {x}
                {i !== arr.length - 1 && foundLabel[i] ? (
                  <Typography component="span" fontWeight={600}>
                    {foundLabel[i]}
                  </Typography>
                ) : null}
              </Typography>
            );
          }),
          subText: subLabel?.split(regex).map((x, i, arr) => {
            return (
              <Typography variant="caption" component="span" key={x}>
                {x}
                {i !== arr.length - 1 && foundSubLabel[i] ? (
                  <Typography variant="caption" component="span" fontWeight={600}>
                    {foundSubLabel[i]}
                  </Typography>
                ) : null}
              </Typography>
            );
          }),
        });
      } else {
        setData({
          text: [<React.Fragment key={label}>{label}</React.Fragment>],
          subText: [<React.Fragment key={label}>{subLabel}</React.Fragment>],
        });
      }
    }, [label, subLabel, boldText]);

    const Item = (
      <MenuItem ref={ref} disabled={disabled} {...linkProps} {...props}>
        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}

          <ItemText label={data.text} subLabel={data.subText} />

          {rightIcon ? (
            <Typography ml="auto" variant="body2" color="text.secondary">
              {rightIcon}
            </Typography>
          ) : null}
        </Box>
      </MenuItem>
    );

    if (defaultTooltip) {
      return (
        <MenuItem ref={ref} disabled={disabled} {...linkProps} {...props}>
          <Tooltip
            componentsProps={{
              tooltip: { sx: { maxWidth: 500 } },
            }}
            title={<ItemText label={data.text} subLabel={data.subText} noMaxWidth />}
            placement="right"
            disableInteractive
          >
            <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
              {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}

              <Typography noWrap maxWidth={200} sx={{ textTransform: "inherit" }}>
                {data.text}
              </Typography>

              {rightIcon ? (
                <Typography ml="auto" variant="body2" color="text.secondary">
                  {rightIcon}
                </Typography>
              ) : null}
            </Box>
          </Tooltip>
        </MenuItem>
      );
    }

    if (disabled && tooltip) {
      return (
        <Tooltip title={tooltip} placement="right" arrow disableInteractive>
          <span>{Item}</span>
        </Tooltip>
      );
    }

    return Item;
  },
);

const ItemText: FC<{
  label?: JSX.Element[];
  subLabel?: JSX.Element[];
  noMaxWidth?: boolean;
}> = ({ label, subLabel, noMaxWidth }) => {
  // TODO(ivan): check
  const maxWidth = noMaxWidth ? undefined : undefined;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Typography noWrap maxWidth={maxWidth} sx={{ textTransform: "inherit" }}>
        {label}
      </Typography>

      {subLabel ? (
        <Typography
          variant="caption"
          noWrap
          maxWidth={maxWidth}
          sx={{ textTransform: "inherit", color: "text.secondary" }}
        >
          {subLabel}
        </Typography>
      ) : null}
    </Box>
  );
};
