import { Recording } from "@api";
import {
  ContextMenu,
  createGlobalModalRoute,
  CtxMenuItem,
  CtxNestedMenu,
  GlobalModalTypes,
  LabelsAsListAddRemove,
  ProjectsAsList,
} from "@components";
import { MenuList } from "@mui/material";
import { RouterHelper } from "@pages";
import {
  ContextMenuTypes,
  QuestionDialogTypes,
  store,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import { DisableByPermissionsType, disabledByPermission } from "@utils";
import React from "react";
import { useTranslation } from "react-i18next";

const filterType = ContextMenuTypes.PROJECT_MENU;

export const RecordingsContextMenu: React.FC<{ project?: boolean }> = ({ project }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isInactive = useAppSelector(state => state.entityTable.quickFilter.isInactive);
  // TODO(ivan): disable for testing, later to be removed
  // const showPupilPlayer = useAppSelector(
  //   state => state.app.currentWorkspaceMembership?.workspace.raw_file_downloads,
  // );
  const selected = useAppSelector(state => state.entityTable.selectedIds);
  const template = useAppSelector(state =>
    state.recordings.dataById.get(state.entityTable.selectedIds[0])
      ? state.templates.dataById.get(
          (state.recordings.dataById.get(state.entityTable.selectedIds[0]) as Recording)
            .template_id,
        )
      : undefined,
  );
  const isDownloadable = useAppSelector(state =>
    state.entityTable.selectedIds.reduce((acc, id) => {
      if (!state.recordings.dataById.get(id)?.is_uploaded) return false;

      return acc;
    }, true),
  );
  const numSelected = selected.length;
  const isMultiSelect = numSelected > 1;
  const disabledPermissionsCreate = disabledByPermission({
    type: DisableByPermissionsType.CREATE,
  });
  const disabledPermissionsEdit = disabledByPermission({
    type: DisableByPermissionsType.EDIT,
  });
  const disabledPermissionsDelete = disabledByPermission({
    type: DisableByPermissionsType.DELETE,
  });
  const disabledPermissionsTrash = disabledByPermission({
    type: DisableByPermissionsType.TRASH,
  });

  const close = () => {
    dispatch.ctxMenu.closeAll();
  };

  return (
    <ContextMenu type={ContextMenuTypes.RECORD_TABLE}>
      <MenuList>
        <CtxMenuItem
          label={t("View recording information")}
          onClick={() => {
            dispatch.viewDetails.viewRecording(selected[0]);
            close();
          }}
          disabled={isMultiSelect}
          tooltip={t("Multiple recordings selected")}
          divider
        />

        {!isInactive && (
          <CtxMenuItem
            label={t("Rename")}
            to={
              selected.length && selected[0]
                ? RouterHelper.projectRecordingsEdit.getRelativePath({
                    prefix: project
                      ? `../${RouterHelper.projectRecordings.relativeTemplatePath}/`
                      : "",
                    props: { recordingId: selected[0] },
                  })
                : undefined
            }
            disabled={disabledPermissionsEdit || isMultiSelect}
            tooltip={
              disabledPermissionsEdit
                ? t("Disabled by permissions")
                : t("Multiple recordings selected")
            }
          />
        )}

        {!isInactive && (
          <CtxMenuItem
            label={t("View template data")}
            onClick={() => {
              dispatch.templateForm.setShowPreview(template);
              close();
            }}
            disabled={isMultiSelect || !template}
            tooltip={
              isMultiSelect
                ? t("Multiple recordings selected")
                : !template
                ? t("Template does not exists")
                : t("Unknown")
            }
          />
        )}

        {!isInactive && (
          <CtxMenuItem
            label={t("Change Wearer")}
            to={
              selected.length && selected[0]
                ? RouterHelper.projectRecordingsEditWearer.getRelativePath({
                    prefix: project
                      ? `../${RouterHelper.projectRecordings.relativeTemplatePath}/`
                      : "",
                    props: { recordingId: selected[0] },
                  })
                : undefined
            }
            disabled={disabledPermissionsEdit || isMultiSelect}
            tooltip={
              disabledPermissionsEdit
                ? t("Disabled by permissions")
                : isMultiSelect
                ? t("Multiple recordings selected")
                : t("Unknown")
            }
          />
        )}

        {!isInactive && (
          <CtxNestedMenu
            CtxMenuItemProps={{
              label: "Labels",
              disabled: disabledPermissionsEdit,
              tooltip: t("Disabled by permissions"),
            }}
          >
            <LabelsAsListAddRemove />
          </CtxNestedMenu>
        )}

        {!project && !isInactive && (
          <CtxNestedMenu
            CtxMenuItemProps={{
              label: t("Add to project"),
              disabled: disabledPermissionsEdit || selected.length === 0 || isInactive,
              tooltip: disabledPermissionsEdit
                ? t("Disabled by permissions")
                : selected.length === 0
                ? t("No recordings selected")
                : isInactive
                ? t("Unable to add trashed recordings to project")
                : undefined,
            }}
          >
            <MenuList>
              <ProjectsAsList
                filterType={filterType}
                onClick={dispatch.projectFilterRecordings.findFilteredAdd}
              />
            </MenuList>
          </CtxNestedMenu>
        )}

        {!project && !isInactive && (
          <CtxMenuItem
            onClick={() => close()}
            to={createGlobalModalRoute(GlobalModalTypes.PROJECT_CREATE_SELECTION)}
            label={t("Create project with selection")}
            disabled={disabledPermissionsCreate || selected.length === 0 || isInactive}
            tooltip={
              disabledPermissionsCreate
                ? t("Disabled by permissions")
                : isInactive
                ? t("Unable to add trashed recordings to project")
                : t("No recordings selected")
            }
          />
        )}

        <CtxNestedMenu
          CtxMenuItemProps={{
            label: t("Share"),
          }}
        >
          <MenuList>
            <CtxMenuItem
              onClick={() => {
                close();
                dispatch.download.generatePupilPlayerFormatDownloadLink(false);
              }}
              label={t(getDownloadLinkText(selected), { numSelected })}
              disabled={selected.length === 0}
              tooltip={selected.length === 0 ? t("No recordings selected") : ""}
            />
          </MenuList>
        </CtxNestedMenu>

        {!isInactive && (
          <CtxNestedMenu
            CtxMenuItemProps={{
              label: "Download",
              divider: true,
            }}
          >
            <MenuList>
              <CtxMenuItem
                disabled={!isDownloadable}
                label={t("Timeseries Data")}
                onClick={() =>
                  dispatch.download.downloadTimeSeriesDataAndSceneVideo(true)
                }
                tooltip={t("Upload not finished")}
              />
              <CtxMenuItem
                disabled={!isDownloadable}
                label={t("Timeseries Data + Scene Video")}
                onClick={() =>
                  dispatch.download.downloadTimeSeriesDataAndSceneVideo(false)
                }
                tooltip={t("Upload not finished")}
              />
              {/* 
              // TODO(ivan): disable for testing, later to be removed
              {showPupilPlayer === true && (
                <CtxMenuItem
                  disabled={!isDownloadable}
                  label={t(getDownloadText(selected), { numSelected })}
                  onClick={() => dispatch.download.downloadPupilPlayerFormat(false)}
                  tooltip={t("Upload not finished")}
                />
              )} */}
              <CtxMenuItem
                disabled={!isDownloadable}
                label={t(getDownloadText(selected), { numSelected })}
                onClick={() => dispatch.download.downloadPupilPlayerFormat(false)}
                tooltip={t("Upload not finished")}
              />
            </MenuList>
          </CtxNestedMenu>
        )}

        {!project && isInactive && (
          <CtxMenuItem
            onClick={() => {
              close();
              dispatch.questionDialog.set({
                type: QuestionDialogTypes.RECORDING_RESTORE,
                onSuccess: () => {
                  dispatch.recordings.updateInactive({
                    ids: selected,
                    inactive: false,
                  });
                  dispatch.entityTable.setSelected([]);
                },
                extra: { count: selected.length },
              });
            }}
            label={
              numSelected === 1
                ? t("Restore Recording")
                : t("Restore {{numSelected}} Recordings", { numSelected })
            }
            disabled={disabledPermissionsTrash}
            tooltip={t("Disabled by permissions")}
          />
        )}

        {!project && isInactive && (
          <CtxMenuItem
            onClick={() => {
              close();
              dispatch.questionDialog.set({
                type: QuestionDialogTypes.RECORDING_DELETE,
                onSuccess: () => {
                  dispatch.recordings.delete(selected);
                },
                extra: { count: selected.length },
              });
            }}
            label={
              numSelected === 1
                ? t("Delete Recording")
                : t("Delete {{numSelected}} Recordings", {
                    numSelected,
                  })
            }
            disabled={disabledPermissionsDelete}
            tooltip={t("Disabled by permissions")}
          />
        )}

        {!project && !isInactive && (
          <CtxMenuItem
            onClick={() => {
              close();
              dispatch.questionDialog.set({
                type: QuestionDialogTypes.RECORDING_TRASH,
                onSuccess: () => {
                  dispatch.recordings.updateInactive({ ids: selected, inactive: true });
                  dispatch.entityTable.setSelected([]);
                },
                extra: { count: selected.length },
              });
            }}
            label={
              numSelected === 1
                ? t("Trash recording")
                : t("Trash {{numSelected}} recordings", {
                    numSelected,
                  })
            }
            disabled={disabledPermissionsTrash}
            tooltip={t("Disabled by permissions")}
          />
        )}

        {project && !isInactive && (
          <CtxMenuItem
            label={t("Remove from project")}
            onClick={() => {
              close();
              dispatch.projectEdit.removeFromProject(null);
            }}
            disabled={disabledPermissionsDelete}
            tooltip={t("Disabled by permissions")}
          />
        )}
      </MenuList>
    </ContextMenu>
  );
};

function getDownloadText(selectedIds: string[]) {
  const recordings = store.getState().recordings.dataById;
  const neon = selectedIds.filter(
    id => recordings.get(id) && recordings.get(id)?.family === "neon",
  );

  const prefix = neon.length ? "Native Recording Data" : "Pupil Player Format";

  return prefix;
}

function getDownloadLinkText(selectedIds: string[]) {
  const recordings = store.getState().recordings.dataById;
  const neon = selectedIds.filter(
    id => recordings.get(id) && recordings.get(id)?.family === "neon",
  );

  const prefix = neon.length
    ? "Copy native recording data link"
    : "Copy pupil player format link";

  return prefix;
}
