import { SvgIcon, SvgIconProps } from "@mui/material";

export const GazeOffset: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="12"
      height="12"
      viewBox="0 0 12 12"
      {...props}
      sx={{ fill: "none", ...props.sx }}
    >
      <circle cx="5" cy="7" r="3.5" stroke="currentColor" />
      <circle cx="7" cy="5" r="3.5" stroke="currentColor" />
    </SvgIcon>
  );
};
