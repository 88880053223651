import { ErrorPage } from "@components/ErrorPage";
import { Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";

export const WorkspaceLayout = () => {
  const params = useParams();
  const workspaceId = String(params.workspaceId);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isWorkspaceAllowed = useAppSelector(state => state.app.isWorkspaceAllowed);
  const isSomethingBroken = useAppSelector(state => state.app.isSomethingBroken);

  useEffect(() => {
    dispatch.app.loadWorkspaceData(workspaceId);
    dispatch.onboarding.onboardUser(null);
  }, [workspaceId, dispatch]);

  if (isSomethingBroken) {
    return (
      <ErrorPage title={t("Something went wrong")} variant="error" reload>
        <Typography variant="body1">{t("Please reload the page")}</Typography>
        <Typography variant="body1">
          {t("If error persists please contact the support")}
        </Typography>
      </ErrorPage>
    );
  }

  if (!isWorkspaceAllowed) {
    return (
      <ErrorPage title={t("Access Denied")} variant="error">
        <Typography variant="body1">
          {t("You do not have access to this workspace.")}
        </Typography>
      </ErrorPage>
    );
  }

  return <Outlet />;
};
