import { api, RecordingEvent } from "@api";
import { createModel } from "@rematch/core";
import { rematchAdapter, RematchAdapter } from "@utils";
import type { RootModel } from "./index";

interface ExtraPropsType {
  eventsByRecordingId: Map<string, Set<RecordingEvent>>;
}

interface Adapter extends RematchAdapter<RecordingEvent>, ExtraPropsType {}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.id,
  extraProps: {
    eventsByRecordingId: new Map(),
  },
});

export const recordingEvents = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
    setEventsByRecordingId(state, data: Adapter["eventsByRecordingId"]) {
      state.eventsByRecordingId = data;
    },
  },
  effects: dispatch => ({
    async get(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const recordingId = state.video.recording?.id;

      if (!workspaceId || !recordingId) return;

      dispatch.recordingEvents.reset();
      dispatch.videoEvents.reset();

      try {
        const res = await api.getRecordingEvents({ workspaceId, recordingId });

        dispatch.recordingEvents.setAll(res.result);
        dispatch.recordingEvents.prepare(null);
        dispatch.videoEvents.prepare(null);
      } catch (error) {
        console.error("recordingEvents, get", error);
      }
    },
    prepare(_, state) {
      const events = state.recordingEvents.data;
      const eventsByRecordingId: Adapter["eventsByRecordingId"] = new Map();

      events.forEach(e => {
        const byRecording = eventsByRecordingId.get(e.recording_id);

        if (byRecording) {
          byRecording.add(e);
        } else {
          eventsByRecordingId.set(e.recording_id, new Set([e]));
        }
      });

      dispatch.recordingEvents.setEventsByRecordingId(eventsByRecordingId);
      dispatch.videoEvents.prepare(null);
      dispatch.video.prepareRecordingSlices(null);
    },
  }),
});
