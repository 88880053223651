export interface FirebaseConfig {
  appId: string;
  apiKey: string;
  projectId: string;
  messagingSenderId: string;
}
const firebaseConfigs: { [env: string]: FirebaseConfig } = {
  prod: {
    appId: "",
    apiKey: "AIzaSyChJ-eCFKXeLV8cklbuWXfy_Q7vzBxc5nA",
    projectId: "pupil-cloud-auth",
    messagingSenderId: "948639958543",
  },
  dev: {
    appId: "1:811053328343:web:c74accaddf3e1bdd9f4d92",
    apiKey: "AIzaSyB1ahHm0ZRsit6nKGa1CCVGXp97wrosikE",
    projectId: "pupil-cloud-auth-dev",
    messagingSenderId: "948639958543",
  },
};

export interface Config {
  environment: string;
  uiUrl: string;
  apiUrl: string;
  releasesUrl: string;
  demoWorkspaceId: string;
  sentryDsn: string;
  analyticsUA: string;
  analyticsGA4: string;
  firebase: {
    appId: string;
    apiKey: string;
    projectId: string;
    messagingSenderId: string;
  };
}

export const envConfigs: { [pl_env: string]: Config } = {
  prd: {
    environment: "prd",
    uiUrl: "https://ui.cloud.pupil-labs.com",
    apiUrl: "https://api.cloud.pupil-labs.com/v2",
    releasesUrl: "https://pupil-labs.com/api/updates.json",
    demoWorkspaceId: "78cddeee-772e-4e54-9963-1cc2f62825f9",
    sentryDsn:
      "https://f4b1b8d9713144ff9754ec48e7dd63be@o263561.ingest.sentry.io/4504485458149376",
    analyticsUA: "UA-40856943-4",
    analyticsGA4: "G-DY5GWCYJ4X",
    firebase: firebaseConfigs.prod,
  },
  stg: {
    environment: "stg",
    uiUrl: "https://ui.cloud.pupil-labs.dev",
    apiUrl: "https://api.cloud.pupil-labs.dev/v2",
    releasesUrl: "https://dev.pupil-labs.com/api/updates.json",
    demoWorkspaceId: "0a29826a-dec8-4e8b-a402-130e1ad28be7",
    sentryDsn:
      "https://f4b1b8d9713144ff9754ec48e7dd63be@o263561.ingest.sentry.io/4504485458149376",
    analyticsUA: "",
    analyticsGA4: "",
    firebase: firebaseConfigs.dev,
  },
  lcl: {
    environment: "lcl",
    uiUrl: "http://pupilcloud.local",
    apiUrl: "https://api.pupilcloud.local/v2",
    releasesUrl: "https://dev.pupil-labs.com/api/updates.json",
    demoWorkspaceId: "0a29826a-dec8-4e8b-a402-130e1ad28be7",
    sentryDsn:
      "https://f4b1b8d9713144ff9754ec48e7dd63be@o263561.ingest.sentry.io/4504485458149376",
    analyticsUA: "",
    analyticsGA4: "",
    firebase: firebaseConfigs.dev,
  },
  dev: {
    environment: "dev",
    uiUrl: "https://ui.cloud.pupil-labs.dev",
    apiUrl: "https://api.cloud.pupil-labs.dev/v2",
    releasesUrl: "https://dev.pupil-labs.com/api/updates.json",
    demoWorkspaceId: "0a29826a-dec8-4e8b-a402-130e1ad28be7",
    sentryDsn:
      "https://f4b1b8d9713144ff9754ec48e7dd63be@o263561.ingest.sentry.io/4504485458149376",
    analyticsUA: "",
    analyticsGA4: "",
    firebase: firebaseConfigs.dev,
  },
};
