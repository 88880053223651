import { ClickAwayListener } from "@mui/material";
import { ContextMenuTypes, useAppDispatch, useAppSelector } from "@storeRematch";
import React, { useEffect } from "react";
import { CtxMenuBase } from "./CtxMenuBase";

export const ContextMenu: React.FC<
  React.PropsWithChildren<{
    type: ContextMenuTypes;
    anchorWidth?: boolean;
    minHeight?: number | string;
    minWidth?: number | string;
    onClose?: () => void;
    onOpen?: () => void;
    id?: string;
  }>
> = ({
  type,
  anchorWidth = false,
  minHeight,
  minWidth,
  onOpen,
  onClose,
  children,
  id,
}) => {
  const anchorEl = useAppSelector(state => state.ctxMenu.open[type]);
  const startOnboard = useAppSelector(state => state.onboarding.startOnboard);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!anchorEl && onClose) {
      onClose();
    } else {
      onOpen?.();
    }
  }, [onClose, onOpen, anchorEl]);

  return (
    <CtxMenuBase
      id={id}
      open={!!anchorEl}
      anchorEl={anchorEl}
      placement="bottom-start"
      maxHeight={minHeight}
      sx={{ width: anchorWidth && anchorEl ? anchorEl.el.clientWidth : minWidth }}
    >
      <ClickAwayListener
        onClickAway={() => {
          if (!startOnboard) {
            dispatch.ctxMenu.close(type);
          }
        }}
      >
        <div role="presentation" style={{ height: "100%" }}>
          {children}
        </div>
      </ClickAwayListener>
    </CtxMenuBase>
  );
};
