function withVersion(name: string, version: number) {
  return `${name}_v${version}` as unknown as number;
}

export enum LocalStorageAdapterNames {
  lastWorkspaceId = "lastWorkspaceId",
  unVerifiedEmail = "unVerifiedEmail",
  videoVolume = "videoVolume",
  videoSpeed = "videoSpeed",
  videoVolumeToggle = "videoVolumeToggle",
  videoEventsVirtualListOpen = "videoEventsVirtualListOpen",
  releaseNotesVersion = "releaseNotesVersion",
  projectTableQuickSelectSortModel = "projectTableQuickSelectSortModel",
  projectTableQuickSelectFilterModel = "projectTableQuickSelectFilterModel",
  cannySsoRedirect = "cannySsoRedirect",
  cannyCompanyId = "cannyCompanyId",
  draggerConfig = withVersion("draggerConfig", 1),
  workspaceRecordingsColumState = "workspaceRecordingsColumState",
  workspaceRecordingsErroredColumState = "workspaceRecordingsErroredColumState",
  accountTokenColumState = "accountTokenColumState",
  projectRecordingsColumState = "projectRecordingsColumState",
  projectRecordingsExtraColumState = withVersion("projectRecordingsExtraColumState", 2),
  projectEnrichmentColumState = "projectEnrichmentColumState",
  projectVisualizationsColumState = "projectVisualizationsColumState",
  settingsLabelsColumState = "settingsLabelsColumState",
  settingsMembersColumState = "settingsMembersColumState",
  settingsProjectsColumState = "settingsProjectsColumState",
  settingsTemplatesColumState = "settingsTemplatesColumState",
  settingsWearersColumState = "settingsWearersColumState",
  tableVisibilityStateDefault = "tableVisibilityStateDefault",
  autoSelectRecording = "autoSelectRecording",
}

class LocalStorageAdapter {
  private name = "plConst";
  private regex = new RegExp("_(.+?)(_(.+)|$)");

  private generateName(name: LocalStorageAdapterNames) {
    return `${this.name}_${name}`;
  }

  set<T>(name: LocalStorageAdapterNames, value: T) {
    localStorage.setItem(this.generateName(name), JSON.stringify(value));
  }

  get<T>(name: LocalStorageAdapterNames): T | null {
    const value = localStorage.getItem(this.generateName(name));

    if (!value || value === "undefined") return null;

    return JSON.parse(value);
  }

  delete(name: LocalStorageAdapterNames) {
    localStorage.removeItem(this.generateName(name));
  }

  clearUnneeded() {
    for (const k in localStorage) {
      if (!k.startsWith(this.name)) continue;

      const matches = this.regex.exec(k);

      if (!matches || matches.length < 2) continue;

      const name = matches[1] as keyof typeof LocalStorageAdapterNames;
      const value = `${name}${matches[2]}`;
      const adapterValue = LocalStorageAdapterNames[name];

      if (!adapterValue || adapterValue !== value) localStorage.removeItem(k);
    }
  }
}

export const localStorageAdapter = new LocalStorageAdapter();
