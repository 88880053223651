import { Recording } from "@api";
import { store } from "@storeRematch";
import { VideoJsPlayer } from "video.js";
import { Model } from "./ShapesControl";
import { selectOverlay } from "./helpers";

class OverlayControl<T extends Model> {
  private readonly RENDER_OFFSET = 0.05;
  private requestAnimationFrameId = 0;

  playCb = this.play.bind(this);
  pauseCb = this.pause.bind(this);
  timeChangeCb = this.timeChange.bind(this);
  fullScreenCb = this.rerender.bind(this);

  models: T[] = [];
  instance: VideoJsPlayer | null = null;
  recordingFamily: Recording["family"] | null = null;
  d3 = selectOverlay();

  reset(full = false) {
    this.models.forEach(x => x.reset(full));
    this.rerender();
  }

  rerender() {
    this.models.forEach(x => this.shouldRender(x));
  }

  shouldRender(model: T) {
    model.hideShapes();

    if (!this.instance || !this.instance.hasStarted() || model.hide) {
      model.notPainted();
      return false;
    }

    const currentTime =
      this.instance.currentTime() + (this.instance.paused() ? 0 : this.RENDER_OFFSET);

    if (this.instance.paused() && model.currentEventIndex) model.currentEventIndex -= 1;

    const event = model.filterBasedOnTimeRange(currentTime);

    if (!model.isCloseEnough(event, currentTime) || !model.checkConditions(event)) {
      model.notPainted();
      return;
    }

    model.paintType
      ? model.paintAll(event)
      : model.shapes.forEach((shape, index) => model.paint({ shape, event, index }));
  }

  timeChange() {
    if (this.instance?.paused() === true) {
      this.models.forEach(m =>
        m.findCurrentEventIndex(this.instance?.currentTime() || 0),
      );
      this.rerender();
    }

    if (this.instance && this.instance.currentTime()) {
      store.dispatch.video.getNextDataChunk({
        currentTime: this.instance.currentTime(),
      });
    }
  }

  play() {
    if (this.instance && (this.instance.isDisposed() || this.instance.paused())) {
      this.pause();
      return;
    }

    this.rerender();
    store.dispatch.video.setTimeToCurrent();

    this.requestAnimationFrameId = requestAnimationFrame(this.playCb);
  }

  pause() {
    if (this.requestAnimationFrameId) {
      cancelAnimationFrame(this.requestAnimationFrameId);
    }
  }

  start(instance: VideoJsPlayer) {
    if (!instance) return;

    if (instance.isDisposed()) {
      this.pause();
      this.instance = null;
      return;
    }

    this.instance = instance;
    this.d3 = selectOverlay();

    this.models.forEach(x => x.initShapes());
    this.rerender();

    this.instance.on("play", this.playCb);
    this.instance.on("pause", this.pauseCb);
    this.instance.on("timeupdate", this.timeChangeCb);
    this.instance.on("enterFullWindow", this.fullScreenCb);
    this.instance.on("exitFullWindow", this.fullScreenCb);
  }

  end() {
    this.pause();

    this.models.forEach(x => x.removeShapes());

    if (!this.instance) return;

    this.instance.off("play", this.playCb);
    this.instance.off("pause", this.pauseCb);
    this.instance.off("timeupdate", this.timeChangeCb);
    this.instance.off("enterFullWindow", this.fullScreenCb);
    this.instance.off("exitFullWindow", this.fullScreenCb);
    this.instance = null;
  }

  getVideoWidth(family = this.recordingFamily) {
    if (family === "neon") return 1600;
    if (family === "invisible") return 1088;
    return this.instance?.videoWidth() || 0;
  }

  getVideoHeight(family = this.recordingFamily) {
    if (family === "neon") return 1200;
    if (family === "invisible") return 1080;
    return this.instance?.videoHeight() || 0;
  }
}

export const overlayControl = new OverlayControl();
