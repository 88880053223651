import { generatePageTitle } from "@utils";
import { PropsWithChildren, useEffect } from "react";

export const RoutePage: React.FC<PropsWithChildren<{ title?: string }>> = ({
  title,
  children,
}) => {
  useEffect(() => {
    document.title = title ?? generatePageTitle();
  });

  return <>{children}</>;
};
