import { api, ProjectRecordingEvent } from "@api";
import { createModel } from "@rematch/core";
import { rematchAdapter, RematchAdapter } from "@utils";
import type { RootModel } from "./index";

interface ExtraPropsType {
  eventsByRecordingId: Map<string, Set<ProjectRecordingEvent>>;
  select: Array<{ value: string; label: string }>;
}

interface Adapter extends RematchAdapter<ProjectRecordingEvent>, ExtraPropsType {}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.id,
  extraProps: {
    eventsByRecordingId: new Map(),
    select: [],
  },
});

export const projectEvents = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
    setEventsByRecordingId(state, data: Adapter["eventsByRecordingId"]) {
      state.eventsByRecordingId = data;
    },
    setSelect(state, data: ExtraPropsType["select"]) {
      state.select = data;
    },
  },
  effects: dispatch => ({
    async get(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;

      if (!workspaceId || !projectId) return;

      dispatch.projectEvents.reset();

      try {
        const res = await api.getProjectEventsResource({ workspaceId, projectId });

        dispatch.projectEvents.setAll(res.result);
        dispatch.projectEvents.prepare(null);
      } catch (error) {
        console.error("projectEvents, get", error);
      }
    },
    prepare(_, state) {
      const events = state.projectEvents.data;
      const eventsByRecordingId: Adapter["eventsByRecordingId"] = new Map();
      const recordingsById = state.recordings.dataById;

      events.forEach(e => {
        const byRecording = eventsByRecordingId.get(e.recording_id);

        if (!recordingsById.get(e.recording_id)?.trashed_at) {
          if (byRecording) {
            byRecording.add(e);
          } else {
            eventsByRecordingId.set(e.recording_id, new Set([e]));
          }
        }
      });

      const repeatedNames = new Set<string>();

      dispatch.projectEvents.setSelect(
        events.reduce<ExtraPropsType["select"]>((acc, one) => {
          if (one.name && !repeatedNames.has(one.name)) {
            repeatedNames.add(one.name);
            acc.push({
              value: one.name,
              label: one.name,
            });
          }

          return acc;
        }, []),
      );

      dispatch.projectEvents.setEventsByRecordingId(eventsByRecordingId);
    },
  }),
});
