import { init, Models, RematchDispatch, RematchRootState } from "@rematch/core";
import immerPlugin from "@rematch/immer";
import loadingPlugin, { ExtraModelsFromLoading } from "@rematch/loading";
import { enableMapSet } from "immer";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { aoiAreas } from "./aoiAreas";
import { aoiStats } from "./aoiStats";
import { aoiTool } from "./aoiTool";
import { app } from "./app";
import { ctxMenu } from "./ctxMenu";
import { download } from "./download";
import { dragger } from "./dragger";
import { enrichments } from "./enrichments";
import { entityTable } from "./entityTable";
import { filmstrip } from "./filmstrip";
import { heatmap } from "./heatmap";
import { invites } from "./invites";
import { labels } from "./labels";
import { localization } from "./localization";
import { members } from "./members";
import { notifier } from "./notifier";
import { onboarding } from "./onboarding";
import { projectEdit } from "./projectEdit";
import { projectEvents } from "./projectEvents";
import { projectFilterRecordings } from "./projectFilterRecordings";
import { projects } from "./projects";
import { questionDialog } from "./questionDialog";
import { recordingEvents } from "./recordingEvents";
import { recordingGazeOffset } from "./recordingGazeOffset";
import { recordings } from "./recordings";
import { staticImageMappers } from "./staticImageMappers";
import { templateForm } from "./templateForm";
import { templates } from "./templates";
import { tokens } from "./tokens";
import { uniqEvents } from "./uniqEvents";
import { uploader } from "./uploader";
import { video } from "./video";
import { videoEvents } from "./videoEvents";
import { viewDetails } from "./viewDetails";
import { visualizationDownloads } from "./visualizationDownloads";
import { visualizations } from "./visualizations";
import { wearers } from "./wearers";
import { workspaceMemberships } from "./workspaceMemberships";

export * from "./types";

export const RESET_APP = "RESET_APP";

// so we can use js map and set with immer
enableMapSet();

export interface RootModel extends Models<RootModel> {
  app: typeof app;
  notifier: typeof notifier;
  recordings: typeof recordings;
  labels: typeof labels;
  wearers: typeof wearers;
  projects: typeof projects;
  entityTable: typeof entityTable;
  members: typeof members;
  templates: typeof templates;
  templateForm: typeof templateForm;
  questionDialog: typeof questionDialog;
  ctxMenu: typeof ctxMenu;
  video: typeof video;
  viewDetails: typeof viewDetails;
  projectEdit: typeof projectEdit;
  enrichments: typeof enrichments;
  uniqEvents: typeof uniqEvents;
  uploader: typeof uploader;
  videoEvents: typeof videoEvents;
  recordingEvents: typeof recordingEvents;
  workspaceMemberships: typeof workspaceMemberships;
  invites: typeof invites;
  tokens: typeof tokens;
  dragger: typeof dragger;
  download: typeof download;
  onboarding: typeof onboarding;
  heatmap: typeof heatmap;
  projectEvents: typeof projectEvents;
  projectFilterRecordings: typeof projectFilterRecordings;
  aoiTool: typeof aoiTool;
  aoiAreas: typeof aoiAreas;
  localization: typeof localization;
  aoiStats: typeof aoiStats;
  filmstrip: typeof filmstrip;
  visualizations: typeof visualizations;
  visualizationDownloads: typeof visualizationDownloads;
  staticImageMappers: typeof staticImageMappers;
  recordingGazeOffset: typeof recordingGazeOffset;
}

const models: RootModel = {
  app,
  notifier,
  recordings,
  labels,
  wearers,
  projects,
  entityTable,
  members,
  templates,
  templateForm,
  questionDialog,
  ctxMenu,
  video,
  viewDetails,
  projectEdit,
  enrichments,
  uniqEvents,
  uploader,
  videoEvents,
  recordingEvents,
  workspaceMemberships,
  invites,
  tokens,
  dragger,
  download,
  onboarding,
  heatmap,
  projectEvents,
  projectFilterRecordings,
  aoiTool,
  aoiAreas,
  localization,
  aoiStats,
  filmstrip,
  visualizations,
  visualizationDownloads,
  staticImageMappers,
  recordingGazeOffset,
};

type FullModel = ExtraModelsFromLoading<RootModel, { type: "full" }>;

export const store = init<RootModel, FullModel>({
  models,
  plugins: [immerPlugin(), loadingPlugin({ type: "full" })],
  redux: {
    rootReducers: {
      [RESET_APP]: () => undefined,
    },
  },
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel, FullModel>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<Dispatch>();
