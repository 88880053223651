import { RecordingWithData, useAppSelector } from "@storeRematch";
import { formatBytes } from "@utils/formatBytes";
import { formatDurationNS } from "@utils/formatDuration";
import { formatTimeZone } from "@utils/formatTimeZone";
import { DetailItem } from "./DetailItem";

export const RecordingContent: React.FC<{
  model: RecordingWithData;
  disableCopy?: boolean;
  useView?: boolean;
}> = ({ model, disableCopy }) => {
  const projectsByRecordingId = useAppSelector(state =>
    state.projects.projectsByRecordingId.get(model.id),
  );

  return (
    <>
      <DetailItem
        name="ID"
        content={model.recording.id}
        copyTextProps={
          disableCopy
            ? undefined
            : { copy: { title: "Recording", text: model.recording.id } }
        }
      />
      <DetailItem name="Wearer" content={model.wearer?.name} />
      <DetailItem
        name="Duration"
        content={formatDurationNS(model.recording.duration_ns)}
      />
      <DetailItem
        name="Recorded"
        content={formatTimeZone(model.recording.recorded_at)}
      />
      <DetailItem name="Template" content={model.template?.name} />
      <DetailItem name="Labels" content={model.labels.map(l => l.name).join(", ")} />
      <DetailItem
        name="Associated Projects"
        content={
          projectsByRecordingId
            ? [...projectsByRecordingId.values()].reduce((acc, next, index) => {
                return `${acc}${index !== 0 ? ", " : ""}${next.name}`;
              }, "")
            : undefined
        }
      />
      <DetailItem name="Sensors" content={model.recording?.sensors?.join(", ")} />
      <DetailItem name="Product ID" content={model.recording.glasses_id} />
      <DetailItem name="Phone Name" content={model.recording.device_name} />
      <DetailItem name="Companion Device" content={model.recording.device_model} />
      <DetailItem name="Size" content={formatBytes(model.recording.size)} />
    </>
  );
};
