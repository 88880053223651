import {
  AoiStats,
  Label,
  Project,
  ProjectEnrichment,
  Recording,
  RecordingEvent,
  Template,
  Visualizations,
  Wearer,
  WorkspaceMember,
} from "@api";
import { OptionsObject } from "notistack";

export * from "./questionDialog";

export interface EmailPasswordCredentials {
  email: string;
  password: string;
}
export interface LabelsTableData {
  id: string;
  label: Label;
  recordingsCount: number;
}
export interface ProjectsTableData {
  id: string;
  project: Project;
  recordings: Recording[];
  createdBy: WorkspaceMember | undefined;
}
export type RecordingWithData = {
  id: string;
  recording: Recording;
  wearer?: Wearer;
  labels: Label[];
  createdBy: WorkspaceMember | undefined;
  template?: Template;
  projects: Project[];
};
export interface TemplatesTableData {
  id: string;
  template: Template;
  createdBy: WorkspaceMember | undefined;
  recordingsCount: number;
}
export interface WearersTableData {
  id: string;
  wearer: Wearer;
  recordingsCount: number;
}
export interface VisualizationsTableData {
  id: string;
  visualization: Visualizations;
  enrichment?: ProjectEnrichment;
}
export enum DraggerTypes {
  VIDEO = "video",
  VIDEO_FULL_SCREEN = "videoFullScreen",
  TABLE = "table",
  TABLE_PROJECT = "tableProject",
}
export enum ContextMenuTypes {
  RECORD_TABLE,
  FILTER,
  FILTER_GROUP_OPERATOR,
  FILTER_GROUP_VALUE,
  FILTER_COLUMN,
  WORKSPACE_SETTINGS,
  USER_MENU,
  PROJECT_MENU,
  HELP_MENU,
  RECORDING_QUICK_SELECT,
  PROJECT_SETTINGS,
  PLAYER_EVENT,
  ENRICHMENT_FORM,
  ENRICHMENT_PROCESS,
  COLOR_PICKER,
  ONBOARDING_MODAL,
  VIDEO_SPEED,
  AOI_LIST,
  ENRICHMENT_SELECT,
  ENRICHMENT_FORM_MANUAL_MAPPER,
  VIDEO_REFERENCE_IMAGE,
}
export interface SnackbarNotification {
  message: string;
  options: OptionsObject & {
    linkComponent?: {
      to: string;
      text: string;
      onClick?: () => void;
    };
    buttonComponent?: {
      text: string;
      onClick?: () => void;
    };
  };
}
export enum ViewDetailsTypes {
  RECORDING,
  LABEL,
  WEARER,
  TEMPLATE,
  PROJECT,
}
export enum ProjectFilterRecordingsType {
  CREATE,
  ADD,
}
export enum AoiTool {
  POINTER,
  PEN_ADD,
  PEN_REMOVE,
}
export enum VideoEventsTypes {
  THUMBNAIL,
  EVENTS,
  EVENT,
  ENRICHMENT,
  FIXATION,
  GAZE,
  BLINKS,
}

export enum AOI_HEATMAP_TYPES {
  AVERAGE_FIXATION_DURATION = "AVERAGE_FIXATION_DURATION",
  TIME_TO_FIRST_FIXATION = "TIME_TO_FIRST_FIXATION",
  REACH = "REACH",
  TOTAL_FIXATION_DURATION = "TOTAL_FIXATION_DURATION",
  FIXATION_COUNT = "FIXATION_COUNT",
}

export const AOI_HEATMAP_TYPES_MAX = Object.keys(AOI_HEATMAP_TYPES).reduce((acc, k) => {
  acc[k as AOI_HEATMAP_TYPES] = 0;

  return acc;
}, {} as Record<AOI_HEATMAP_TYPES, number>);

export type VirtualListRows =
  | { height: number; type: VideoEventsTypes.THUMBNAIL }
  | { height: number; type: VideoEventsTypes.EVENTS }
  | { height: number; type: VideoEventsTypes.EVENT; data: RecordingEvent }
  | { height: number; type: VideoEventsTypes.ENRICHMENT }
  | { height: number; type: VideoEventsTypes.FIXATION }
  | { height: number; type: VideoEventsTypes.GAZE }
  | { height: number; type: VideoEventsTypes.BLINKS };

export type AoiStatsWithData = {
  id: string;
  aoiStats: AoiStats;
  percentage: Record<AOI_HEATMAP_TYPES, number>;
  readable: Record<AOI_HEATMAP_TYPES, string>;
};

export interface AreaImages {
  id: string;
  image: CanvasImageSource;
  imageData: ImageData;
  updatedAt: Date;
}
