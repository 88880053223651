import { ContextMenu, CtxMenuItem } from "@components";

import { MenuList } from "@mui/material";
import { RouterHelper } from "@pages";
import {
  ContextMenuTypes,
  QuestionDialogTypes,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import { DisableByPermissionsType, disabledByPermission } from "@utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const TemplatesContextMenu: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isInactive = useAppSelector(state => state.entityTable.quickFilter.isInactive);
  const selected = useAppSelector(state => state.entityTable.selectedIds);
  const numSelected = selected.length;
  const isMultiSelect = numSelected > 1;
  const disabledPermissionsEdit = disabledByPermission({
    type: DisableByPermissionsType.EDIT,
  });
  const disabledPermissionsDelete = disabledByPermission({
    type: DisableByPermissionsType.DELETE,
  });
  const disabledPermissionsTrash = disabledByPermission({
    type: DisableByPermissionsType.TRASH,
  });

  const onClose = () => {
    dispatch.ctxMenu.closeAll();
  };

  return (
    <ContextMenu type={ContextMenuTypes.RECORD_TABLE}>
      <MenuList>
        <CtxMenuItem
          label={t("View details")}
          onClick={() => {
            dispatch.viewDetails.viewTemplate(selected[0]);
            onClose();
          }}
          divider
          disabled={isMultiSelect}
          tooltip={t("Multiple templates selected")}
        />
        <CtxMenuItem
          label={t("Edit")}
          onClick={() => {
            if (!selected.length) return;

            navigate(
              RouterHelper.workspaceSettingsTemplatesEdit.getRelativePath({
                props: { templateId: selected[0] },
              }),
            );
          }}
          disabled={disabledPermissionsEdit || isMultiSelect}
          tooltip={
            disabledPermissionsEdit
              ? t("Disabled by permissions")
              : t("Multiple templates selected")
          }
          divider
        />

        {isInactive && (
          <CtxMenuItem
            onClick={() => {
              onClose();
              dispatch.questionDialog.set({
                type: QuestionDialogTypes.TEMPLATES_RESTORE,
                onSuccess: () => {
                  dispatch.templates.updateInactive({
                    ids: selected,
                    inactive: false,
                  });
                },
                extra: { count: selected.length },
              });
            }}
            label={
              numSelected === 1
                ? t("Restore Template")
                : t("Restore {{numSelected}} Templates", { numSelected })
            }
            disabled={disabledPermissionsTrash}
            tooltip={t("Disabled by permissions")}
          />
        )}

        {isInactive && (
          <CtxMenuItem
            label={
              numSelected === 1
                ? t("Delete Template")
                : t("Delete {{numSelected}} Templates", { numSelected })
            }
            onClick={() => {
              onClose();
              dispatch.questionDialog.set({
                type: QuestionDialogTypes.TEMPLATES_DELETE,
                onSuccess: () => {
                  dispatch.templates.delete(selected);
                },
                extra: {
                  count: selected.length,
                },
              });
            }}
            disabled={disabledPermissionsDelete}
            tooltip={t("Disabled by permissions")}
          />
        )}

        {!isInactive && (
          <CtxMenuItem
            onClick={() => {
              onClose();
              dispatch.templates.updateInactive({ ids: selected, inactive: true });
            }}
            label={
              numSelected === 1
                ? t("Trash template")
                : t("Trash {{numSelected}} templates", {
                    numSelected,
                  })
            }
            disabled={disabledPermissionsTrash}
            tooltip={t("Disabled by permissions")}
          />
        )}
      </MenuList>
    </ContextMenu>
  );
};
