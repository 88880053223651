import { ReactRouterLinkCustom } from "@components/ReactRouterLinkCustom";
import { GazeOffset } from "@customIcons";
import { Box, IconButton, Tooltip } from "@mui/material";
import { RouterHelper } from "@pages";
import { useAppDispatch, useAppSelector } from "@storeRematch/index";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";

export const GazeOffsetLink: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const workspaceId = useAppSelector(
    state => state.app.currentWorkspaceMembership?.workspace.id,
  );
  const recordingId = useAppSelector(state => state.video.recording?.id);
  const disabled = useAppSelector(
    state =>
      state.loading.effects.app.loadWorkspaceData.loading ||
      state.loading.effects.app.loadProjectData.loading,
  );
  const location = useLocation();
  const isRecordingGazeEdit = !!matchPath(
    RouterHelper.workspaceRecordingsEditGazeOffset.fullTemplatePath,
    location.pathname,
  );

  if (isRecordingGazeEdit) return null;

  return (
    <Tooltip title={t("Edit gaze offset ")} disableInteractive>
      <Box>
        <IconButton
          sx={{ my: "auto", ml: "auto" }}
          size="small"
          component={ReactRouterLinkCustom}
          onClick={() => {
            dispatch.video.setRememberRecordingCurrentTime(true);
          }}
          to={
            workspaceId && recordingId
              ? RouterHelper.workspaceRecordingsEditGazeOffset.getFullPath({
                  prefix: "../../",
                  props: { workspaceId, recordingId },
                })
              : ""
          }
          disabled={!workspaceId || !recordingId || disabled}
        >
          <GazeOffset fontSize="small" />
        </IconButton>
      </Box>
    </Tooltip>
  );
};
