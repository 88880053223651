import {
  api,
  Profile,
  ProfileGetResponse,
  Project,
  User,
  WorkspaceMembership,
  WorkspacePatchRequest,
} from "@api";
import { API_KEY, API_URL } from "@constants";
import { createModel } from "@rematch/core";
import { auth } from "@services/firebase";
import { localStorageAdapter, LocalStorageAdapterNames, wsClient } from "@utils";
import { updateEmail, updatePassword } from "firebase/auth";
import i18n from "../i18n";
import { RESET_APP, RootModel } from "./index";

interface AppState {
  apiKey?: string;
  apiUrl: string;
  apiUser: User | Profile | null;
  currentWorkspaceMembership: WorkspaceMembership | null | undefined;
  currentProject: Project | null | undefined;
  authLoading: boolean;
  useDarkMode: boolean;
  useDefaultTheme: boolean;
  isWorkspaceAllowed: boolean;
  isProjectAllowed: boolean;
  resetOnSwitch: boolean | null;
  isSomethingBroken: boolean;
  showPrivacySettings: boolean;
  cutVideoEnrichmentSlices: boolean;
  inviteMembersAfterWorkspaceIsCreated: boolean;
}

const initialState: AppState = {
  apiKey: API_KEY,
  apiUrl: API_URL,
  apiUser: null,
  currentWorkspaceMembership: null,
  currentProject: null,
  authLoading: false,
  useDarkMode: true,
  useDefaultTheme: false,
  isWorkspaceAllowed: true,
  isProjectAllowed: true,
  resetOnSwitch: null,
  isSomethingBroken: false,
  showPrivacySettings: false,
  cutVideoEnrichmentSlices: true,
  inviteMembersAfterWorkspaceIsCreated: false,
};

export const app = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setUser(state, payload: ProfileGetResponse) {
      state.apiUser = payload.result;
    },
    setApiUrl(state, payload: string) {
      state.apiUrl = payload;
    },
    setApiKey(state, payload: string) {
      state.apiKey = payload;
    },
    setAuthLoading(state, payload: boolean) {
      state.authLoading = payload;
    },
    toggleDarkMode(state) {
      state.useDarkMode = !state.useDarkMode;
    },
    toggleDefaultTheme(state) {
      state.useDefaultTheme = !state.useDefaultTheme;
    },
    setCurrentWorkspaceMembership(state, payload: WorkspaceMembership) {
      localStorageAdapter.set(
        LocalStorageAdapterNames.lastWorkspaceId,
        payload.workspace.id,
      );
      state.currentWorkspaceMembership = payload;
    },
    setIsWorkspaceAllowed(state, payload: boolean) {
      state.isWorkspaceAllowed = payload;
    },
    setIsSomethingBroken(state, payload: AppState["isSomethingBroken"]) {
      state.isSomethingBroken = payload;
    },
    setCurrentProject(state, payload: AppState["currentProject"]) {
      state.currentProject = payload;
    },
    setIsProjectAllowed(state, payload: boolean) {
      state.isProjectAllowed = payload;
    },
    toggleResetOnSwitch(state) {
      state.resetOnSwitch = !state.resetOnSwitch;
    },
    toggleShowPrivacySettings(state) {
      state.showPrivacySettings = !state.showPrivacySettings;
    },
    toggleCutVideoEnrichmentSlices(state) {
      state.cutVideoEnrichmentSlices = !state.cutVideoEnrichmentSlices;
    },
    setInviteMembersAfterWorkspaceIsCreated(
      state,
      data: AppState["inviteMembersAfterWorkspaceIsCreated"],
    ) {
      state.inviteMembersAfterWorkspaceIsCreated = data;
    },
  },
  effects: dispatch => ({
    async finalizeAuth({
      refresh = false,
      register = false,
    }: {
      refresh?: boolean;
      register?: boolean;
    }) {
      try {
        if (auth.currentUser) {
          const firebaseIdToken = await auth.currentUser.getIdToken(refresh);
          await api.postLogin({
            loginRequest: {
              csrf_token: "string",
              id_token: firebaseIdToken,
              type: register ? "register" : "login",
            },
          });
        }

        const user = await api.getProfile();
        await dispatch.onboarding.getReleaseNotes();

        dispatch.tokens.get();
        dispatch.app.setUser(user);
        dispatch.workspaceMemberships.setAll(
          user.result.user_workspaces.filter(o => o.workspace.deleted_at === null),
        );
      } catch (e) {
        console.error(e);
      } finally {
        dispatch.app.setAuthLoading(false);
      }
    },
    async logout() {
      try {
        await auth.signOut();
        await api.postLogout();
      } catch (error) {
        console.error("logout error", error);
      } finally {
        dispatch.app.reset();
      }
    },
    reset() {
      dispatch({ type: RESET_APP });
      wsClient?.disconnect();
    },
    async loadWorkspaceData(workspaceId: string, state) {
      const currentUser = state.app.apiUser;

      if (!currentUser) return;

      const currentWorkspaceMembership = state.app.currentWorkspaceMembership;

      if (
        currentWorkspaceMembership &&
        currentWorkspaceMembership.workspace.id === workspaceId
      )
        return;

      const nextWorkspaceMembership =
        state.workspaceMemberships.dataById.get(workspaceId);

      if (!nextWorkspaceMembership) {
        dispatch.app.setIsWorkspaceAllowed(false);
        return;
      }

      if (state.app.currentWorkspaceMembership) {
        dispatch.app.toggleResetOnSwitch();
      }

      dispatch.entityTable.resetQuickFilter();
      dispatch.video.setHideVideoPlayer(true);
      dispatch.app.setIsSomethingBroken(false);
      dispatch.recordings.reset();
      dispatch.wearers.reset();
      dispatch.labels.reset();
      dispatch.projects.reset();
      dispatch.invites.reset();
      dispatch.templates.reset();
      dispatch.uniqEvents.reset();
      dispatch.videoEvents.reset();

      dispatch.app.setCurrentWorkspaceMembership(nextWorkspaceMembership);

      try {
        await Promise.all([
          dispatch.recordings.get(workspaceId),
          dispatch.labels.get(workspaceId),
          dispatch.wearers.get(workspaceId),
          dispatch.projects.get(workspaceId),
          dispatch.members.get(workspaceId),
          dispatch.invites.get(workspaceId),
          dispatch.templates.get(workspaceId),
        ]);

        dispatch.app.setIsWorkspaceAllowed(true);
        dispatch.recordings.prepare(null);
        dispatch.wearers.prepare(null);
        dispatch.labels.prepare(null);
        dispatch.projects.prepare(null);
        dispatch.templates.prepare(null);
        wsClient?.connect(workspaceId);

        if (!document.URL.includes("projects")) {
          dispatch.entityTable.setWorkspaceDefaultSelected(null);
        }
      } catch (e) {
        console.error("global error catch", e);
        dispatch.app.setIsSomethingBroken(true);
        return;
      }

      // non critical
      try {
        await dispatch.uniqEvents.get({ workspaceId });
        dispatch.uniqEvents.prepare(null);
      } catch (error) {
        console.error(error);
      }
    },
    loadProjectData(projectId: string, state) {
      const currentUser = state.app.apiUser;

      if (!currentUser) return;

      const currentProject = state.app.currentProject;

      if (currentProject && currentProject.id === projectId) return;

      const nextProject = state.projects.dataById.get(projectId);

      if (!nextProject) {
        dispatch.app.setIsProjectAllowed(false);
        return;
      }

      dispatch.app.toggleResetOnSwitch();
      dispatch.app.setCurrentProject(nextProject);
      dispatch.projectEdit.get(null);
      dispatch.app.setIsWorkspaceAllowed(true);
    },
    async updateWorkspace(data: WorkspacePatchRequest, state) {
      const currentWorkspace = state.app.currentWorkspaceMembership;
      const workspaceId = currentWorkspace?.workspace.id;
      if (!workspaceId) return;

      try {
        const res = await api.updateWorkspace({
          workspaceId,
          workspacePatchRequest: data,
        });
        if (typeof res === "string") return;

        const nextWorkspaceMembership =
          state.workspaceMemberships.dataById.get(workspaceId);

        if (!nextWorkspaceMembership) return;

        const updatedMembership = {
          ...nextWorkspaceMembership,
          workspace: res.result,
        };

        dispatch.workspaceMemberships.upsertOne(updatedMembership);
        dispatch.app.setCurrentWorkspaceMembership(updatedMembership);

        // TODO(ivan): disable for testing, later to be removed
        // dispatch.notifier.enqueueSnackbar({
        //   message: i18n.t(
        //     data.raw_file_downloads !== undefined
        //       ? `Native Recording Data ${
        //           data.raw_file_downloads ? "enabled" : "disabled"
        //         } in downloads`
        //       : "Workspace name updated",
        //   ),
        //   options: {
        //     variant: "success",
        //   },
        // });
        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Workspace name updated"),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async createWorkspace({
      name,
      videoUpload,
      faceBlur,
    }: {
      name: string;
      videoUpload: boolean;
      faceBlur: boolean;
    }) {
      try {
        const res = await api.postWorkspace({
          workspacePostRequest: {
            name,
            world_video_mode: faceBlur ? "BLUR" : videoUpload ? "NORMAL" : "NOUPLOAD",
          },
        });

        if (typeof res === "string") return;

        const next: WorkspaceMembership = {
          role: "OWNER",
          workspace: res.result,
        };

        dispatch.workspaceMemberships.upsertOne(next);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Workspace created"),
          options: {
            variant: "success",
          },
        });

        return res.result.id;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteWorkspace(workspaceId: string, state): Promise<string | void> {
      if (!workspaceId) return;

      try {
        await api.deleteWorkspace({ workspaceId });

        if (!state.app.apiUser) return;

        const defaultWorkspaceId = state.app.apiUser.default_workspace_id;

        dispatch.workspaceMemberships.deleteMany([workspaceId]);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Workspace deleted"),
          options: {
            variant: "success",
          },
        });

        return defaultWorkspaceId;
      } catch (error) {
        console.error(error);
      }
    },
    async updateEmail(email: string): Promise<void> {
      try {
        if (!auth.currentUser?.email) return;

        await updateEmail(auth.currentUser, email);
        await auth.signOut();
        api.postLogout();

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Email updated"),
          options: { variant: "success" },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async updatePassword(newPassword: string): Promise<void> {
      try {
        if (!auth.currentUser) return;

        await updatePassword(auth.currentUser, newPassword);
        await api.postLogout();
        dispatch.app.finalizeAuth({ refresh: true });

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Password updated"),
          options: { variant: "success" },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async updateAccount(): Promise<void> {
      try {
        if (!auth.currentUser) return;

        await api.postLogout();
        dispatch.app.finalizeAuth({ refresh: true });

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Account updated"),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async deleteAccount(): Promise<void> {
      try {
        const res = await api.postUnregister();

        if (typeof res === "string") return;

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Account deleted"),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async subscribeProductUpdates(checked: boolean): Promise<void> {
      try {
        const res = await api.patchProfile({
          profilePatchRequest: { product_updates_subscribed: checked },
        });

        if (typeof res === "string") return;

        dispatch.app.setUser(res);
        dispatch.notifier.enqueueSnackbar({
          message: res.result.product_updates_subscribed
            ? i18n.t("Subscribed successfully")
            : i18n.t("Unsubsribed successfully"),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async acceptInvite(token: string) {
      try {
        const res = await api.postAcceptInvitationResource({
          token,
        });

        if (res.code === 401) throw new Error("invite failed");

        const user = await api.getProfile();

        dispatch.app.setUser(user);
        dispatch.workspaceMemberships.setAll(
          user.result.user_workspaces.filter(o => o.workspace.deleted_at === null),
        );
        const membership = user.result.user_workspaces.find(
          o => o.workspace.id === res.result.workspace_id,
        );

        return membership;
      } catch (error) {
        console.error(error);

        throw new Error("invite failed");
      }
    },
  }),
});
