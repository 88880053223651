import { Clear, Search } from "@mui/icons-material";
import { IconButton, Input, InputAdornment, InputProps } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import i18n from "../../i18n";

export interface SearchBoxProps {
  name: string;
  onClear?: () => void;
  searchIcon?: boolean;
}

export const SearchBox = ({
  placeholder = i18n.t("Search").toString(),
  onClear,
  value,
  searchIcon = false,
  ...props
}: SearchBoxProps & InputProps) => {
  const ref = useRef<HTMLInputElement | null>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(!!value);
  }, [value]);

  useEffect(() => {
    if (ref.current && ref.current.value !== value) {
      const typed = value as string | undefined | null;

      ref.current.value = typed === undefined || typed === null ? "" : typed;
    }
  }, [value]);

  return (
    <Input
      inputRef={ref}
      autoFocus
      placeholder={placeholder}
      {...props}
      disableUnderline={true}
      onChange={e => {
        setShow(!!e.target.value);
        props.onChange?.(e);
      }}
      startAdornment={
        searchIcon ? <Search sx={{ color: "text.secondary", mr: 1 }} /> : undefined
      }
      endAdornment={
        onClear && show ? (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={() => {
                if (ref.current) {
                  ref.current.value = "";
                  ref.current.focus();
                  setShow(false);
                }
                onClear();
              }}
            >
              <Clear fontSize="small" />
            </IconButton>
          </InputAdornment>
        ) : undefined
      }
    />
  );
};
