import { ProjectsTableData } from "@storeRematch";
import { formatTimeZone } from "@utils/formatTimeZone";
import { DetailItem } from "./DetailItem";

export const ProjectContent: React.FC<{ model: ProjectsTableData }> = ({ model }) => {
  return (
    <>
      <DetailItem
        name="ID"
        content={model.project.id}
        copyTextProps={{ copy: { title: "Project", text: model.project.id } }}
      />
      <DetailItem name="Name" content={model.project.name} />
      <DetailItem name="Created" content={formatTimeZone(model.project.created_at)} />
      <DetailItem name="Updated" content={formatTimeZone(model.project.updated_at)} />
      <DetailItem name="Description" content={"TODO"} />
    </>
  );
};
