import { CtxMenuItem } from "@components";
import { SearchBox } from "@components/Common/SearchBox";
import { INACTIVE_FIELD_NAME } from "@constants";
import { MenuList, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { ContextMenuTypes } from "@storeRematch";
import { useCallback, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { FilterValueForm } from "./FilterValueForm";
import { getOperatorsByType, isOperatorExcluded } from "./utils";

enum Steps {
  ColumnField,
  OperatorValue,
  Value,
}

export function FilterContent() {
  const apiRef = useGridApiContext();
  const columns = useMemo(() => {
    return Object.values(apiRef.current.getAllColumns());
  }, [apiRef]);
  const [item, setItem] = useState<
    Partial<GridFilterItem & { type: string; headerName?: string; field: string }>
  >({});
  const [step, setStep] = useState<Steps>(Steps.ColumnField);
  const [search, setSearch] = useState("");

  const addItemColumnField = useCallback((config: GridColDef) => {
    setItem(i => ({
      ...i,
      field: config.field,
      type: config.type,
      headerName: config.headerName,
      operator: config.filterOperators
        ? config.field === "duration_ns"
          ? config.filterOperators[3].value
          : config.filterOperators[0].value
        : undefined,
    }));
    setStep(Steps.Value);
    setSearch("");
  }, []);

  const addItemOperatorValue = useCallback((config: GridFilterOperator) => {
    setItem(i => ({ ...i, operatorValue: config.value }));
    setStep(Steps.Value);
    setSearch("");
  }, []);

  const saveFilter = (value: any) => {
    const { field, operator } = item;
    apiRef.current.upsertFilterItem({
      id: uuidv4(),
      field: field as string,
      value:
        item.field === "duration_ns" && (!value || Number(value) === 0) ? "0" : value,
      operator: operator as string,
    });
    setStep(Steps.ColumnField);
    setItem({});
    setSearch("");
  };

  if (step === Steps.OperatorValue) {
    const operators = getOperatorsByType(item.type);

    return (
      <MenuList>
        {operators?.map(config => {
          if (isOperatorExcluded(config.value)) return null;

          return (
            <CtxMenuItem
              key={config.value}
              label={config.value}
              onClick={() => addItemOperatorValue(config)}
            />
          );
        })}
      </MenuList>
    );
  }

  if (step === Steps.Value) {
    return (
      <>
        <Box px={2} pt={1}>
          <Typography
            variant="caption"
            color="GrayText"
            fontWeight={500}
            sx={{ textTransform: "uppercase" }}
          >
            {item.headerName || item.field}
          </Typography>
        </Box>
        <FilterValueForm
          filterType={ContextMenuTypes.FILTER}
          onSave={saveFilter}
          item={{ field: item.field, type: item.type }}
        />
      </>
    );
  }

  return (
    <>
      <Box px={1.5} pt={1}>
        <Typography variant="caption" color="GrayText" fontWeight={500}>
          FILTER
        </Typography>
      </Box>
      <Box px={1.5} pt={1}>
        <SearchBox
          sx={{ width: "250px" }}
          autoFocus
          name="Labels search"
          onChange={e => setSearch(e.target.value)}
        />
      </Box>
      <MenuList>
        {columns.map(config => {
          if (config.field === INACTIVE_FIELD_NAME) return null;
          if (!config.filterable) return null;
          if (search && !config.field.startsWith(search)) return null;

          return (
            <CtxMenuItem
              key={config.headerName}
              label={config.headerName}
              onClick={() => addItemColumnField(config)}
            />
          );
        })}
      </MenuList>
    </>
  );
}
