import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";

export interface LoaderProps {}

export const Loader: React.FC<React.PropsWithChildren<LoaderProps>> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 100,
        m: "auto !important",
      }}
    >
      <Typography py={3} m="auto">
        {children ?? "Loading..."}
      </Typography>
      <CircularProgress sx={{ m: "auto" }} />
    </Box>
  );
};
