import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { Box } from "@mui/system";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { useTranslation } from "react-i18next";

export const Demo: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const darkMode = useAppSelector(state => state.app.useDarkMode);
  const devUseDefaultTheme = useAppSelector(state => state.app.useDefaultTheme);
  const showPrivacySettings = useAppSelector(state => state.app.showPrivacySettings);
  const cutVideoEnrichmentSlices = useAppSelector(
    state => state.app.cutVideoEnrichmentSlices,
  );

  return (
    <Box px={2} py={1}>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={darkMode} sx={{ mr: 1 }} />}
          label={t("Dark mode")}
          sx={{ mb: 2 }}
          onChange={dispatch.app.toggleDarkMode}
        />
        <FormControlLabel
          control={<Switch checked={devUseDefaultTheme} sx={{ mr: 1 }} />}
          label={t("Default theme")}
          sx={{ mb: 2 }}
          onChange={dispatch.app.toggleDefaultTheme}
        />
        <FormControlLabel
          control={<Switch checked={showPrivacySettings} sx={{ mr: 1 }} />}
          label={t("Show privacy settings")}
          sx={{ mb: 2 }}
          onChange={dispatch.app.toggleShowPrivacySettings}
        />
        <FormControlLabel
          control={<Switch checked={cutVideoEnrichmentSlices} sx={{ mr: 1 }} />}
          label={t("Cut video enrichment slices")}
          sx={{ mb: 2 }}
          onChange={dispatch.app.toggleCutVideoEnrichmentSlices}
        />
      </FormGroup>
      <Button
        sx={{ my: 2 }}
        onClick={() => {
          // @ts-ignore
          checkSentryFunctionDoesNotExits();
        }}
      >
        Check Sentry
      </Button>
    </Box>
  );
};
