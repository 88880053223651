import { Project } from "@api";
import { CtxMenuItem, CtxMenuLabel, VirtualizedList } from "@components";
import { SearchBox } from "@components/Common/SearchBox";
import { Check } from "@mui/icons-material";
import { Box } from "@mui/system";
import { RouterHelper } from "@pages";
import { ContextMenuTypes, useAppSelector } from "@storeRematch";
import { sanitizeForRegex } from "@utils";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface ProjectsAsListProps {
  filterType: ContextMenuTypes;
  onClick?: (project: Project) => void;
  id?: string;
}

export const ProjectsAsList: FC<ProjectsAsListProps> = ({
  filterType,
  onClick,
  id,
}) => {
  const { workspaceId, projectId } = useParams();
  const { t } = useTranslation();
  const projects = useAppSelector(state => state.projects.data);
  const closed = useAppSelector(state => !state.ctxMenu.open[filterType]);
  const [found, setFound] = useState({ text: "", data: projects });

  useEffect(() => {
    if (closed) {
      setFound({ text: "", data: projects });
    }
  }, [closed, projects]);

  useEffect(() => {
    if (projects) {
      setFound({ text: "", data: projects });
    }
  }, [projects, setFound]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const next = projects?.filter(
      project =>
        project.name.match(new RegExp(sanitizeForRegex(e.currentTarget.value), "i")) ||
        project.id.match(new RegExp(sanitizeForRegex(e.currentTarget.value), "i")),
    );
    setFound({ text: e.currentTarget.value, data: next });
  };

  return (
    <>
      <CtxMenuLabel>
        <SearchBox
          id={id}
          sx={{ width: "250px" }}
          name="Projects settings search"
          onChange={handleSearch}
          onKeyDown={e => e.stopPropagation()}
          onClear={() => setFound({ text: "", data: projects })}
        />
      </CtxMenuLabel>

      {!found.data.length && <CtxMenuItem label={t("No projects")} disabled />}

      <Box height={found.data.length * 33} maxHeight="40vh">
        <VirtualizedList
          itemData={found.data}
          itemKey={(index, data) => data[index].id}
          itemSize={33}
          renderRow={({ index, style, data }) => (
            <CtxMenuItem
              key={index}
              style={style}
              onClick={() => onClick?.(data[index])}
              to={
                onClick !== undefined || !workspaceId
                  ? undefined
                  : RouterHelper.projectRecordings.getFullPath({
                      props: { workspaceId, projectId: data[index].id },
                    })
              }
              label={data[index].name}
              subLabel={data[index].id}
              boldText={found.text}
              defaultTooltip
              rightIcon={
                onClick !== undefined ? undefined : data[index].id === projectId ? (
                  <Check />
                ) : undefined
              }
            />
          )}
        />
      </Box>
    </>
  );
};
