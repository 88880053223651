const LOG_PREFIX = "[pupil-cloud]";

console.log = console.log.bind(console, LOG_PREFIX);
console.warn = console.warn.bind(console, LOG_PREFIX);
console.error = console.error.bind(console, LOG_PREFIX);
console.debug = console.debug.bind(console, LOG_PREFIX);
console.trace = console.trace.bind(console, LOG_PREFIX);

console.log(`Bound ${LOG_PREFIX} prefix to console messages`);

export {};
