import { ErrorPage } from "@components";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { TemplateForm } from "./TemplateForm";

export const TemplateEdit = () => {
  const params = useParams();
  const templateId = String(params.templateId);
  const template = useAppSelector(state => state.templates.dataById.get(templateId));
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(
    state => state.loading.models.templates.loading || state.loading.models.app.loading,
  );

  useEffect(() => {
    if (template) {
      dispatch.templateForm.setCurrent(template);
    }
  }, [template, dispatch]);

  if (!template && !isLoading) {
    return <ErrorPage title={`Template ${templateId} not found in this workspace`} />;
  }

  return <TemplateForm />;
};
