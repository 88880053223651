import { Info, KeyboardArrowDown } from "@mui/icons-material";
import { Button, Divider, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { ContextMenuTypes, useAppDispatch, useAppSelector } from "@storeRematch";
import { FC, PropsWithChildren } from "react";
import { ContextMenu } from "./ctxMenu";

export const RecordingQuickSelectBar: FC<PropsWithChildren> = ({ children }) => {
  const fullScreen = useAppSelector(state => state.video.fullScreen);

  if (fullScreen) return null;

  return (
    <>
      <Box
        display="flex"
        px={1}
        mt={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <RecordingDropSelect>{children}</RecordingDropSelect>

        <RecordingInfoButton />
      </Box>
      <Divider sx={{ mt: 1 }} />
    </>
  );
};

const RecordingInfoButton = () => {
  const dispatch = useAppDispatch();

  return (
    <IconButton
      size="small"
      sx={{ ml: 2 }}
      onClick={dispatch.viewDetails.viewCurrentRecording}
    >
      <Info fontSize="small" />
    </IconButton>
  );
};

const RecordingDropSelect: FC<PropsWithChildren> = ({ children }) => {
  const filterType = ContextMenuTypes.RECORDING_QUICK_SELECT;
  const dispatch = useAppDispatch();
  const disabledProject = useAppSelector(
    state => !state.loading.effects.app.loadProjectData.success,
  );
  const disabledWorkspace = useAppSelector(
    state => !state.loading.effects.app.loadWorkspaceData.success,
  );
  const recording = useAppSelector(state =>
    state.entityTable.selectedIds.length
      ? state.recordings.dataById.get(state.entityTable.selectedIds[0])
      : undefined,
  );

  return (
    <>
      <ContextMenu type={filterType} anchorWidth minHeight={"80vh"}>
        {children}
      </ContextMenu>

      <Button
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexGrow: 1,
          height: "100%",
        }}
        disabled={
          document.URL.includes("projects") ? disabledProject : disabledWorkspace
        }
        size="small"
        onClick={e =>
          dispatch.ctxMenu.handleClick({ type: filterType, position: true, e })
        }
        endIcon={<KeyboardArrowDown />}
        fullWidth
      >
        <Box display="flex">{recording?.name || "Select recording"}</Box>
      </Button>
    </>
  );
};
