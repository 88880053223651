import { ArtWork, Discord, Linkedin, Twitter, Youtube } from "@customIcons";
import { AppBar, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { Outlet } from "react-router-dom";

export const Header = () => {
  return (
    <AppBar sx={{ height: "70px" }}>
      <ArtWork fontSize="large" sx={{ width: "100%", m: "auto" }} />
    </AppBar>
  );
};

const links = [
  { Icon: Linkedin, link: "https://www.linkedin.com/company/pupil-labs" },
  { Icon: Twitter, link: "https://twitter.com/pupil_labs" },
  { Icon: Youtube, link: "https://www.youtube.com/c/PupilLabs" },
  { Icon: Discord, link: "https://pupil-labs.com/chat" },
];

export const Footer = () => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" mb={5}>
      <Box width="250px" display="flex" mx="auto" justifyContent="space-evenly">
        {links.map(({ Icon, link }) =>
          link ? (
            <a href={link} key={link} rel="noreferrer" target="_blank">
              <Icon fontSize="large" sx={{ color: "text.secondary" }} />
            </a>
          ) : null,
        )}
      </Box>

      <Box width="250px" my={4} mx="auto">
        <Typography textAlign="center" color="text.secondary">
          Pupil Labs GmbH
        </Typography>
        <Typography color="text.secondary">
          Sanderstr. 28, 12047 Berlin, Germany
        </Typography>
      </Box>
      <Typography textAlign="center">
        <a href="https://www.pupil-labs.com" rel="noreferrer" target="_blank">
          www.pupil-labs.com
        </a>
      </Typography>
    </Box>
  );
};

export const UnAuthenticatedRoute = ({
  disableBorder = false,
}: {
  disableBorder?: boolean;
}) => {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Header />

      <Paper
        sx={{
          maxWidth: disableBorder ? "100%" : "400px",
          width: "100%",
          border: disableBorder ? "none" : "1px solid black",
          borderColor: "divider",
          m: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          p: disableBorder ? 0 : 2,
        }}
        elevation={0}
      >
        <Stack spacing={2}>
          <Outlet />
        </Stack>
      </Paper>

      <Footer />
    </Box>
  );
};
