import { SvgIcon, SvgIconProps } from "@mui/material";

export const VisualizationsVideo: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      width="65"
      height="48"
      viewBox="0 0 65 48"
      {...props}
      sx={{ ...props.sx, fill: "none", fontSize: "4.5rem" }}
    >
      <g clipPath="url(#clip0_3659_74371)">
        <rect width="65" height="48" fill="#2C3232" />
        <rect x="22.6094" y="16" width="20" height="16" rx="2" fill="#BAA9DC" />
        <path
          d="M22.6094 20L42.6094 20M27.6094 20L27.6094 16M32.6094 20L32.6094 16M37.6094 20L37.6094 16"
          stroke="#2C3232"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M32.6094 24V29" stroke="#2C3232" strokeLinecap="round" />
        <path
          d="M29.6094 26L32.6094 23L35.6094 26"
          stroke="#2C3232"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3659_74371">
          <rect width="65" height="48" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
