import { Recording } from "@api";
import { FormDialog, TextField } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/system";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
  })
  .required();

export const RecordingEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const recordingId = String(params.recordingId);
  const recording = useAppSelector(state => state.recordings.dataById.get(recordingId));
  const dispatch = useAppDispatch();

  const onClose = () => {
    navigate(-1);
  };

  const handleSubmit = async (formData: Recording) => {
    if (!params.workspaceId || !recording) return;

    await dispatch.recordings.rename({
      id: recording.id,
      name: formData.name as string,
    });

    onClose();
  };

  return (
    <FormDialog
      open
      title={t("Rename Recording")}
      onFormSubmit={handleSubmit}
      onClose={onClose}
      useFormProps={{
        defaultValues: recording || { name: "" },
        resolver: yupResolver(schema),
      }}
      create={!recording}
    >
      <Stack spacing={4}>
        <TextField name="name" label={t("Recording Name")} autoFocus required />
      </Stack>
    </FormDialog>
  );
};
