import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const Play: FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M5.44375 17.1876C5.20391 17.1871 4.96833 17.1243 4.76016 17.0051C4.29141 16.7395 4 16.2239 4 15.6641V4.33601C4 3.77468 4.29141 3.26062 4.76016 2.99499C4.97328 2.8725 5.2154 2.80952 5.46119 2.81261C5.70699 2.8157 5.94744 2.88476 6.15742 3.01257L15.8391 8.80788C16.0408 8.9344 16.2072 9.11009 16.3225 9.31848C16.4378 9.52687 16.4983 9.76113 16.4983 9.99929C16.4983 10.2374 16.4378 10.4717 16.3225 10.6801C16.2072 10.8885 16.0408 11.0642 15.8391 11.1907L6.15586 16.9876C5.94097 17.1175 5.69485 17.1866 5.44375 17.1876Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
