import { OnboardingStep } from "@components/onboarding/OnboardingModal";
import { createModel } from "@rematch/core";
import { ContextMenuTypes, RootModel } from "./index";

interface InitialState {
  open: Partial<Record<ContextMenuTypes, any>>;
  hideScrollBar: boolean;
  anchorEl: null | any;
  autoOpenFilterFormRef: HTMLButtonElement | null;
}

const initialState: InitialState = {
  open: {},
  hideScrollBar: false,
  anchorEl: null,
  autoOpenFilterFormRef: null,
};

export const ctxMenu = createModel<RootModel>()({
  state: initialState,
  reducers: {
    handleClick(
      state,
      {
        type,
        position = false,
        center = false,
        e,
      }: {
        type: ContextMenuTypes;
        position?: boolean;
        center?: boolean;
        e: React.MouseEvent;
      },
    ) {
      e.preventDefault();
      const { clientX, clientY } = e;
      const { bottom, top, left, right } = e.currentTarget.getBoundingClientRect();

      const testY = bottom < 100 ? bottom : top;
      const testX = window.innerWidth - 100 < right ? right : left;

      const topBottom = position ? testY : clientY;
      const leftRight = position
        ? center
          ? e.currentTarget.parentElement
            ? (e.currentTarget.parentElement.clientWidth - 200) / 2
            : 0
          : testX
        : clientX;

      if (type === ContextMenuTypes.RECORD_TABLE) {
        state.hideScrollBar = true;
      }

      state.open = {};
      state.open[type] = {
        el: e.currentTarget,
        getBoundingClientRect: () => ({
          width: 0,
          height: 0,
          top: topBottom,
          right: leftRight,
          bottom: topBottom,
          left: leftRight,
        }),
      };
    },
    onboardOpen(
      state,
      {
        type,
        position,
        placement,
      }: {
        type: ContextMenuTypes;
        position?: DOMRect;
        placement: OnboardingStep["placement"];
      },
    ) {
      const padding = 8;
      const initialPos = { bottom: 0, top: 0, left: 0, right: 0, width: 0 };
      const { bottom, top, left, right, width } = position ? position : initialPos;

      const testTop = window.innerHeight - 100 < top ? top - padding : top;
      const testBottom =
        window.innerHeight - 100 < bottom ? bottom - padding : bottom + padding;
      const testLeft = window.innerWidth - 100 < left ? left - padding : left + padding;
      const testRight =
        window.innerWidth - 100 < right ? right - padding : right + padding;

      let topBottom = 0;
      let leftRight = 0;

      switch (placement) {
        case "top-left":
          topBottom = testTop;
          leftRight = testLeft - (width + 24 + padding);
          break;
        case "top-right":
          topBottom = testTop;
          leftRight = testRight;
          break;
        case "bottom-left":
          topBottom = testBottom;
          leftRight = testLeft;
          break;
        case "bottom-right":
          topBottom = testBottom;
          leftRight = testRight;
          break;
        default:
          break;
      }

      state.open[type] = {
        getBoundingClientRect: () => ({
          width: 0,
          height: 0,
          top: topBottom,
          right: leftRight,
          bottom: topBottom,
          left: leftRight,
        }),
      };
    },
    close(state, type: ContextMenuTypes) {
      state.open[type] = false;
      state.hideScrollBar = false;
    },
    closeAll(state) {
      state.open = {};
      state.hideScrollBar = false;
    },
    setAutoOpenFilterFormRef(state, data: InitialState["autoOpenFilterFormRef"]) {
      state.autoOpenFilterFormRef = data;
    },
  },
  effects: _dispatch => ({
    autoOpenFilterFormValue(_, state) {
      state.ctxMenu.autoOpenFilterFormRef?.click();
    },
  }),
});

// Helper function for multiple menus of same type
// Braking types a bit...don't see any better way
export function createContextMenuId(
  type: ContextMenuTypes,
  id: string | number | undefined,
) {
  return `${type}-${id || 1}` as unknown as ContextMenuTypes;
}
