import {
  GenerateHeatmap,
  VisualizationDataAoi,
  VisualizationTypes,
  Visualizations,
} from "@api";
import { AoiDrawArea, LinkToAoiEdit, Loader, ReactRouterLinkCustom } from "@components";
import { RecordingsSelectModal, Select, SubmitButton, Switch, TextField } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useVisualizationRecordingIds } from "@hooks";
import { KeyboardArrowLeft } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { GridExpandMoreIcon } from "@mui/x-data-grid-pro";
import { DEFAULT_HEATMAP_COLOR_TYPES, RouterHelper } from "@pages";
import {
  AOI_HEATMAP_TYPES,
  store,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import { CheckIfModelNeedsUpdate, isArrayEqual } from "@utils";
import { debounce } from "debounce";
import { FC, useEffect, useRef } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import * as yup from "yup";
import { EnrichmentName } from "./EnrichmentName";

export const VisualizationsAoi = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const visualizationId = String(params.visualizationId);
  const visualization = useAppSelector(state =>
    state.visualizations.dataById.get(visualizationId),
  );
  const isLoaded = useAppSelector(
    state =>
      state.loading.effects.app.loadProjectData.success &&
      state.loading.effects.enrichments.get.success,
  );
  const loading = useAppSelector(
    state =>
      state.loading.effects.aoiAreas.get.loading &&
      state.loading.effects.aoiTool.get.loading,
  );
  const imageNotAvailable = useAppSelector(state => state.aoiTool.imageNotAvailable);

  useEffect(() => {
    dispatch.visualizations.tryToAutoAddAois(visualization);

    return () => {
      dispatch.visualizations.setCurrentVisualization(null);
    };
  }, [dispatch, visualization]);

  useEffect(() => {
    if (isLoaded && visualization?.enrichment_id) {
      dispatch.aoiAreas.presetCurrentEnrichment(visualization.enrichment_id);
      dispatch.aoiTool.get(visualization.enrichment_id);
    }
  }, [dispatch, visualization?.enrichment_id, isLoaded]);

  useEffect(() => {
    return () => {
      dispatch.aoiAreas.reset();
      dispatch.aoiTool.reset();
      dispatch.aoiStats.reset();
      dispatch.projectEdit.reset();
    };
  }, [dispatch]);

  if (loading || !isLoaded) {
    return <Loader />;
  }

  return (
    <>
      <Box width="300px" display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" py={1} px={2} height={44}>
          <IconButton
            sx={{ mr: 1 }}
            size="small"
            component={ReactRouterLinkCustom}
            to={RouterHelper.projectVisualizations.getRelativePath({
              prefix: "../",
            })}
          >
            <KeyboardArrowLeft fontSize="small" />
          </IconButton>
          <Typography>{t("AOI Heatmap")}</Typography>
        </Box>
        <Divider />
        <Box py={1} px={2} display="flex" flexDirection="column" height="100%">
          {visualization ? <Form model={visualization} /> : null}
        </Box>
      </Box>
      <Divider orientation="vertical" />

      {imageNotAvailable || !visualization?.enrichment_id ? (
        <Box sx={{ flexGrow: 1, display: "flex" }}>
          <Typography component="span" sx={{ margin: "auto" }}>
            {!visualization ? (
              <Loader />
            ) : !visualization.enrichment_id ? (
              "Enrichment does not exist"
            ) : (
              "Image not available"
            )}
          </Typography>
        </Box>
      ) : (
        <Box flexGrow={1}>
          <AoiDrawArea visualizationId={visualization.id} />
        </Box>
      )}
    </>
  );
};

export interface AoiHeatmapFormValues {
  id: string;
  name: string;
  metrics: AOI_HEATMAP_TYPES;
  colorMap: GenerateHeatmap["colormap"];
  showNames: boolean;
  showMetricValue: boolean;
  aoisArr: string[];
  aoisSet: Set<string>;
  recordingIds: string[];
  enrichmentId?: string | null;
}

const DEFAULT_HEATMAP_TYPES: Array<{
  value: AOI_HEATMAP_TYPES;
  label: string;
  sub: string;
}> = [
  {
    value: AOI_HEATMAP_TYPES.TOTAL_FIXATION_DURATION,
    label: "Total Fixation Duration",
    sub: "Total fixation duration on the AOI.",
  },
  {
    value: AOI_HEATMAP_TYPES.AVERAGE_FIXATION_DURATION,
    label: "Average Fixation Duration",
    sub: "Average fixation duration on the AOI.",
  },
  {
    value: AOI_HEATMAP_TYPES.TIME_TO_FIRST_FIXATION,
    label: "Time to First Fixation",
    sub: "Average time until an AOI gets fixated on for the first time in a recording.",
  },
  {
    value: AOI_HEATMAP_TYPES.REACH,
    label: "Reach",
    sub: "Percentage of recordings in which an AOI was fixated on at least once.",
  },
  {
    value: AOI_HEATMAP_TYPES.FIXATION_COUNT,
    label: "Fixation Count",
    sub: "Average number of fixations on the AOI.",
  },
];

const schema = yup
  .object()
  .shape({
    id: yup.string(),
    name: yup.string(),
    metrics: yup.string().required(),
    showNames: yup.boolean().required(),
    showMetricValue: yup.boolean().required(),
    aoisArr: yup.array().of(yup.string()).required().min(1),
    recordingIds: yup.array().of(yup.string()),
    enrichmentId: yup.string(),
  })
  .required();

const Form = ({ model }: { model: Visualizations }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isDisabled = useAppSelector(
    state =>
      state.loading.effects.aoiAreas.downloadAoiHeatmap.loading ||
      state.loading.effects.aoiTool.get.loading,
  );
  //const aoiIdsToAutoAdd = useAppSelector(state => state.visualizations.aoiIdsToAutoAdd)
  const filteredRecordingIds = useVisualizationRecordingIds(
    model.enrichment_id || "",
    (model.payload as VisualizationDataAoi).recording_ids,
  );

  const methods = useForm<AoiHeatmapFormValues>({
    defaultValues: {
      id: model.id,
      name: model.name,
      metrics: (model.payload as VisualizationDataAoi).metrics,
      enrichmentId: model.enrichment_id,
      colorMap: (model.payload as VisualizationDataAoi).color_map,
      showNames: (model.payload as VisualizationDataAoi).show_names,
      showMetricValue: (model.payload as VisualizationDataAoi).show_metric_values,
      recordingIds: filteredRecordingIds,
      aoisArr: (model.payload as VisualizationDataAoi).aoi_ids,
      aoisSet: new Set<string>((model.payload as VisualizationDataAoi).aoi_ids),
    },
    resolver: yupResolver(schema),
  });

  const handleSubmit = async (data: AoiHeatmapFormValues) => {
    if (!model.enrichment_id) return;

    await dispatch.aoiAreas.downloadAoiHeatmap(data);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleSubmit)}
        noValidate
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
            <Typography>{t("Settings")}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "inherit", maxWidth: 275 }}>
            <TextField
              name="name"
              label="Visualization name"
              sx={{ mt: 1 }}
              disabled={isDisabled}
              fullWidth
            />

            <InnerDivider />

            {/* <VizEnrichmentSelect disabled={isDisabled} /> */}
            <EnrichmentName id={model.enrichment_id} type />

            <InnerDivider />

            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              {t("Metrics")}
            </Typography>

            <Select
              name="metrics"
              label={t("Metrics")}
              options={DEFAULT_HEATMAP_TYPES}
              fullWidth
              disabled={isDisabled}
              checkedIcon
              variant="standard"
            />

            <InnerDivider />

            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              {t("Display")}
            </Typography>

            <Select
              name="colorMap"
              label={t("Color map")}
              options={DEFAULT_HEATMAP_COLOR_TYPES as any}
              fullWidth
              sx={{ mb: 2 }}
              disabled={isDisabled}
              variant="standard"
            />

            <Stack direction="column" spacing={1} alignItems="left">
              <Switch
                name="showNames"
                label="Show Names"
                checked={false}
                disabled={isDisabled}
                sx={{ m: 0 }}
                noMargin
                labelPlacement="end"
                colorSecondary
              />
              <Switch
                name="showMetricValue"
                label="Show Metric Values"
                checked={false}
                disabled={isDisabled}
                sx={{ m: 0 }}
                noMargin
                labelPlacement="end"
                colorSecondary
              />
            </Stack>

            <InnerDivider />

            <RecordingsSelect />
          </AccordionDetails>
        </Accordion>

        <InnerDivider off />

        <LinkToAoiEdit isDisabled={isDisabled} />

        <List />

        <InnerDivider />

        <Submit disabled={isDisabled} />

        <Watcher model={model} />
      </form>
    </FormProvider>
  );
};

const Submit: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { t } = useTranslation();
  const aoisArr: [] = useWatch({ name: "aoisArr" });
  const recordingIds: [] = useWatch({ name: "recordingIds" });
  console.log(aoisArr, recordingIds);

  return (
    <SubmitButton
      color="primary"
      fullWidth
      sx={{ mt: "auto" }}
      useIsDirty={false}
      disabled={disabled || !aoisArr.length || !recordingIds.length}
    >
      {t("Download")}
    </SubmitButton>
  );
};

const InnerDivider: FC<{ off?: boolean }> = ({ off }) => {
  return (
    <Divider sx={{ mb: 2, mt: off ? 1 : 2, width: "calc(100% + 32px)", ml: -2 }} />
  );
};

const checkDebounce = new CheckIfModelNeedsUpdate();
const debouncedUpdate = debounce(
  (data: Parameters<typeof store.dispatch.visualizations.update>[0]) => {
    if (checkDebounce.checkOne(data.id, data)) {
      store.dispatch.visualizations.update(data);
    }
  },
  500,
);

const Watcher = ({ model }: { model: Visualizations }) => {
  const dispatch = useAppDispatch();
  const values = useWatch();
  const methods = useFormContext();
  const updateAois = useRef<boolean>(false);
  const aois = useAppSelector(state => state.aoiAreas.data);
  const current = useAppSelector(state => state.visualizations.currentVisualization);

  useEffect(() => {
    dispatch.aoiStats.setHeatmapType(values.metrics);
  }, [values.metrics, dispatch]);

  useEffect(() => {
    dispatch.aoiStats.setShowNames(values.showNames);
  }, [values.showNames, dispatch]);

  useEffect(() => {
    dispatch.aoiStats.setShowMetricValue(values.showMetricValue);
  }, [values.showMetricValue, dispatch]);

  useEffect(() => {
    dispatch.aoiStats.setHeatmapColor(values.colorMap);
  }, [values.colorMap, dispatch]);

  useEffect(() => {
    if (model.enrichment_id !== values.enrichmentId) {
      updateAois.current = true;
    }
  }, [values.enrichmentId, model.enrichment_id, methods, dispatch]);

  useEffect(() => {
    if (updateAois.current) {
      const { arr, set } = aois.reduce(
        (acc, one) => {
          acc.arr.push(one.id);
          acc.set.add(one.id);

          return acc;
        },
        {
          arr: [] as string[],
          set: new Set<string>(),
        },
      );

      methods.setValue("aoisArr", arr);
      methods.setValue("aoisSet", set);

      updateAois.current = false;
    }
  }, [aois, dispatch, methods]);

  useEffect(() => {
    debouncedUpdate({
      id: values.id,
      name: values.name,
      enrichment_id: values.enrichmentId,
      payload: {
        show_metric_values: values.showMetricValue,
        show_names: values.showNames,
        metrics: values.metrics,
        color_map: values.colorMap,
        recording_ids: values.recordingIds,
        aoi_ids: values.aoisArr,
      },
    });
  }, [values, dispatch]);

  useEffect(() => {
    if (current?.kind === VisualizationTypes.AOI_HEATMAP) {
      if (!isArrayEqual(current.payload.aoi_ids, methods.getValues("aoisArr"))) {
        const { arr, set } = current.payload.aoi_ids.reduce(
          (acc, one) => {
            acc.arr.push(one);
            acc.set.add(one);

            return acc;
          },
          {
            arr: [] as string[],
            set: new Set<string>(),
          },
        );

        methods.setValue("aoisArr", arr);
        methods.setValue("aoisSet", set);
        dispatch.aoiStats.prepare(null);

        updateAois.current = false;
      }
    }
  }, [current, methods, dispatch]);

  useEffect(() => {
    if (
      current?.kind === VisualizationTypes.AOI_HEATMAP &&
      !isArrayEqual(current.payload.recording_ids, methods.getValues("recordingIds"))
    ) {
      methods.setValue("recordingIds", current.payload.recording_ids);
    }
  }, [current, methods]);

  return null;
};

const RecordingsSelect = () => {
  const dispatch = useAppDispatch();
  const enrichmentId = useWatch({ name: "enrichmentId" });

  return (
    <RecordingsSelectModal
      handleFirstReset={() => {
        dispatch.aoiStats.get({ id: enrichmentId, recordingIds: [] });
      }}
      handleConfirm={({ enrichmentId, selectedIds }) => {
        dispatch.aoiStats.get({ id: enrichmentId, recordingIds: selectedIds });
      }}
      handleReset={({ enrichmentId, selectedIds }) => {
        dispatch.aoiStats.get({ id: enrichmentId, recordingIds: selectedIds });
      }}
      enrichmentId={enrichmentId}
    />
  );
};

const List = () => {
  const rowsLength = useAppSelector(state => state.aoiAreas.data.length);

  return (
    <Box sx={{ display: "flex", flexGrow: 1, mb: 2 }}>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <FixedSizeList
              height={height}
              width={width}
              itemSize={35.5}
              itemCount={rowsLength}
            >
              {ListRow}
            </FixedSizeList>
          );
        }}
      </AutoSizer>
    </Box>
  );
};

const ListRow = ({ index, style }: { index: number; style?: any }) => {
  return (
    <div style={style}>
      <Item index={index} />
    </div>
  );
};

const Item = ({ index }: { index: number }) => {
  const name = "aoisSet";
  const item = useAppSelector(state => state.aoiAreas.data[index]);
  const methods = useFormContext();
  const dispatch = useAppDispatch();
  const isDisabled = useAppSelector(
    state =>
      state.loading.effects.aoiAreas.downloadAoiHeatmap.loading ||
      state.loading.effects.aoiTool.get.loading,
  );

  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field }) => {
        const typed = field.value as Set<string>;
        const checked = typed.has(item.id);

        return (
          <MenuItem
            sx={{ p: 0 }}
            disabled={isDisabled}
            onClick={() => {
              if (isDisabled) return;

              if (checked) {
                typed.delete(item.id);
                dispatch.aoiAreas.setVisibility({ id: item.id, value: false });
              } else {
                typed.add(item.id);
                dispatch.aoiAreas.setVisibility({ id: item.id, value: true });
              }

              methods.setValue(name, typed);
              methods.setValue("aoisArr", Array.from(typed));

              dispatch.aoiStats.prepare(null);
            }}
          >
            <Checkbox
              color="primary"
              sx={{ mr: 1.5 }}
              size="small"
              checked={checked}
              disabled={isDisabled}
            />
            {item.name}
          </MenuItem>
        );
      }}
    />
  );
};
