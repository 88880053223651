import { Box } from "@mui/system";
import { store, useAppSelector } from "@storeRematch";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { VideoJsPlayer } from "video.js";
import { OVERLAY_ID, overlayCalculations, overlayControl } from "./controller";

function updateDimensions(el: HTMLDivElement, instance: VideoJsPlayer | null) {
  const parentWidth = el.parentElement?.clientWidth || 0;
  const parentHeight = el.parentElement?.clientHeight || 0;

  const { width, height } = overlayCalculations.getDimension1(instance);
  //const dimension = `${num}px`;

  const w = parentWidth > width ? width : parentWidth;
  const h = parentHeight > height ? height : parentHeight;
  const dimW = `${w}px`;
  const dimH = `${h}px`;

  el.style.width = dimW;
  el.style.height = dimH;

  const ratio = window.devicePixelRatio;
  //const scaled = num * ratio;
  const scaledW = w * ratio;
  const scaledH = h * ratio;

  overlayControl.models.forEach(m => {
    if (m.canvasSkip) return;

    const can = m.getCanvas();

    if (can) {
      can.style.width = dimW;
      can.style.height = dimH;

      can.width = scaledW;
      can.height = scaledH;
      can.getContext("2d")?.scale(ratio, ratio);
    }
  });
  overlayControl.rerender();
}

export const VideoOverlay = () => {
  const params = useParams();
  const enrichmentId = String(params.enrichmentId);
  const model = useAppSelector(state => state.enrichments.dataById.get(enrichmentId));
  const fullScreen = useAppSelector(state => state.video.fullScreen);
  const instance = useAppSelector(state => state.video.instance);
  const root = useRef<HTMLDivElement | null>(null);
  const size = useAppSelector(
    state =>
      state.dragger.video.size +
      state.dragger.videoFullScreen.size +
      Number(state.video.fullScreen) +
      state.dragger.table.size +
      state.dragger.tableProject.size,
  );

  useEffect(() => {
    if (root.current) {
      updateDimensions(root.current, instance);
    }
  }, [instance, model, size, fullScreen]);

  useEffect(() => {
    function resizeUpdate() {
      if (root.current) {
        updateDimensions(root.current, instance);
      }
    }

    function resize() {
      resizeUpdate();

      store.dispatch.dragger.reset();
    }

    resize();
    window.addEventListener("resize", resize);
    instance?.on("loadeddata", resizeUpdate);

    return () => {
      window.removeEventListener("resize", resize);
      instance?.off("loadeddata", resizeUpdate);
    };
  }, [instance]);

  useEffect(() => {
    if (instance) {
      overlayControl.start(instance);
    } else {
      overlayControl.pause();
    }

    return () => {
      overlayControl.end();
    };
  }, [instance]);

  return (
    <Box
      position="absolute"
      ref={root}
      margin="auto"
      left={0}
      right={0}
      top={0}
      bottom={0}
    >
      <Box position="relative" width="100%" height="100%">
        {overlayControl.models.map(m => {
          if (!m.canvasId) return null;
          if (m.canvasSkip) return null;

          return (
            <canvas key={m.canvasId} id={m.canvasId} style={{ position: "absolute" }} />
          );
        })}

        <svg
          width="100%"
          height="100%"
          id={OVERLAY_ID}
          style={{ position: "absolute" }}
        />
      </Box>
    </Box>
  );
};
