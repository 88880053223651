import * as yup from "yup";
import i18n from "../i18n";

export const emailSchema = yup.string().email().required();
export const currentPasswordSchema = yup.string().required();

export const newPasswordSchema = yup
  .string()
  .min(8, i18n.t("Password must be at least 8 characters.").toString())
  .required()
  .matches(/[0-9]/, i18n.t("Password requires a number.").toString())
  .matches(/[A-Z]/, i18n.t("Password requires an uppercase letter.").toString());

export const passwordConfirmSchema = yup
  .string()
  .oneOf([yup.ref("password"), null], "Passwords don't match!")
  .required();
