import { SvgIcon, SvgIconProps } from "@mui/material";

export const CheckBoxChecked: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <rect x="3" y="3" width="10" height="10" fill="#EDEDEF" />
      <path
        d="M12.5 1.5H3.5C2.96974 1.50058 2.46137 1.71148 2.08643 2.08643C1.71148 2.46137 1.50058 2.96974 1.5 3.5V12.5C1.50058 13.0303 1.71148 13.5386 2.08643 13.9136C2.46137 14.2885 2.96974 14.4994 3.5 14.5H12.5C13.0303 14.4994 13.5386 14.2885 13.9136 13.9136C14.2885 13.5386 14.4994 13.0303 14.5 12.5V3.5C14.4994 2.96974 14.2885 2.46137 13.9136 2.08643C13.5386 1.71148 13.0303 1.50058 12.5 1.5ZM11.3828 5.82156L7.18281 10.8216C7.13674 10.8764 7.07941 10.9208 7.01471 10.9516C6.95001 10.9823 6.87945 10.9989 6.80781 11H6.79938C6.72929 11 6.66 10.9852 6.59599 10.9567C6.53198 10.9282 6.47468 10.8865 6.42781 10.8344L4.62781 8.83438C4.5821 8.78589 4.54654 8.72876 4.52322 8.66633C4.4999 8.60391 4.4893 8.53745 4.49203 8.47087C4.49477 8.40429 4.51078 8.33892 4.53914 8.27862C4.56749 8.21831 4.60761 8.16429 4.65715 8.11971C4.70668 8.07514 4.76463 8.04091 4.82757 8.01905C4.89052 7.99719 4.95721 7.98813 5.02371 7.9924C5.09021 7.99668 5.15518 8.01421 5.21481 8.04396C5.27444 8.0737 5.32752 8.11507 5.37094 8.16562L6.78625 9.73812L10.6172 5.17844C10.7031 5.07909 10.8247 5.01754 10.9556 5.00711C11.0866 4.99668 11.2164 5.03819 11.317 5.12268C11.4175 5.20717 11.4808 5.32784 11.4931 5.45862C11.5055 5.5894 11.4658 5.71977 11.3828 5.82156Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
