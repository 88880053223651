import {
  ColumnsFilter,
  ErrorBoundary,
  Filter,
  FilterGroup,
  GlobalModalTypes,
  QuickCreateProjectModel,
  SearchFilter,
  createGlobalModalRoute,
} from "@components";
import {
  useGetTableColumnState,
  useTableSearchParams,
  useWindowDimensions,
} from "@hooks";
import { FilterList } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import {
  DataGridProProps,
  GridCallbackDetails,
  GridRowSelectionModel,
  gridVisibleRowsLookupSelector,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { RouterHelper } from "@pages";
import { ContextMenuTypes, useAppDispatch, useAppSelector } from "@storeRematch";
import {
  DisableByPermissionsType,
  LocalStorageAdapterNames,
  disabledByPermission,
} from "@utils";
import React, { memo, useEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { StyledDataGrid } from "./StyledGrid";

export interface EntityDataTableProps extends DataGridProProps {
  noSearch?: boolean;
  noColumnFilter?: boolean;
  noFilters?: boolean;
  noContextMenu?: boolean;
  titleComponent?: JSX.Element;
  toolbarPadding?: boolean;
  noResetSelected?: boolean;
  singleSelect?: boolean;
  outlet?: boolean;
  disableSelect?: boolean;
  onCustomSelect?: (id: string) => void;
  noDataMessage: React.ReactNode;
  filterLocalStorageKey?: LocalStorageAdapterNames;
  sortLocalStorageKey?: LocalStorageAdapterNames;
  tableColumnInitialStateStorageKey: LocalStorageAdapterNames;
  disableSelectModel?: boolean;
  cssHeight?: string;
  disableFooter?: boolean;
  childrenLeftFilter?: boolean;
  cssHeaderBackGround?: string;
}

// disable select all event
let disableSelectAllHotkey = 0;

export const EntityDataTable: React.FC<
  React.PropsWithChildren<EntityDataTableProps>
> = ({
  titleComponent,
  noSearch = false,
  noFilters = false,
  noColumnFilter = false,
  noContextMenu = false,
  toolbarPadding = false,
  noResetSelected = false,
  singleSelect = false,
  outlet = false,
  disableSelect = false,
  onCustomSelect,
  noDataMessage,
  filterLocalStorageKey,
  sortLocalStorageKey,
  tableColumnInitialStateStorageKey,
  disableSelectModel = false,
  cssHeight,
  disableFooter = false,
  children,
  initialState,
  childrenLeftFilter = false,
  cssHeaderBackGround,
  ...rest
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectionModel = useAppSelector(state => state.entityTable.selectedIds);
  const hideScrollbar = useAppSelector(state => state.ctxMenu.hideScrollBar);
  const { onFilterModelChange, filterModel, onSortModelChange, sortModel, onClear } =
    useTableSearchParams({
      defaultSortModel: initialState?.sorting?.sortModel,
      defaultFilterModel: initialState?.filter?.filterModel,
      filterLocalStorageKey,
      sortLocalStorageKey,
    });
  const {
    columnsState,
    onColumnOrderChange,
    onColumnResize,
    onColumnVisibilityModelChange,
  } = useGetTableColumnState({
    columns: rest.columns,
    localStorageName: tableColumnInitialStateStorageKey,
    visibilityModelDefault: initialState?.columns?.columnVisibilityModel,
  });
  const { width, height } = useWindowDimensions();

  // const onRowClick = (params: GridRowParams, e: React.MouseEvent) => {
  //   const id = params.id as string;

  //   if (onCustomSelect) {
  //     onCustomSelect(id);
  //   } else if ((e.metaKey || e.ctrlKey) && !singleSelect) {
  //     dispatch.entityTable.addSelected(id);
  //   } else {
  //     dispatch.entityTable.setSelected([id]);
  //   }

  //   dispatch.ctxMenu.closeAll();
  // };

  const openContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    const clickedRowId = event.currentTarget.getAttribute("data-id");
    dispatch.ctxMenu.closeAll();

    if (clickedRowId && !selectionModel.includes(clickedRowId)) {
      dispatch.entityTable.setSelected([clickedRowId]);
    }

    dispatch.ctxMenu.handleClick({ type: ContextMenuTypes.RECORD_TABLE, e: event });
  };

  useEffect(() => {
    return () => {
      if (!noResetSelected) dispatch.entityTable.resetSelected();
    };
  }, [dispatch, noResetSelected]);

  // Fixing error when screen is to small to render table
  if (width < 250 || height < 250) return null;

  const onSelectionModelChange = (
    selectionModel: GridRowSelectionModel,
    _details?: GridCallbackDetails<any>,
  ) => {
    if (disableSelect) return;

    if (disableSelectAllHotkey === 2) {
      disableSelectAllHotkey = 0;
      return;
    }

    if (disableSelectAllHotkey === 1) {
      disableSelectAllHotkey = 2;
    }

    if (onCustomSelect) {
      onCustomSelect(selectionModel[0] as any);
    } else {
      dispatch.entityTable.setSelected(selectionModel as any);
    }

    if (singleSelect) dispatch.ctxMenu.closeAll();
  };

  return (
    <>
      {outlet && <Outlet />}
      <Box display="flex" height="100%" width="100%" alignItems="stretch">
        <Box sx={{ width: "100%", height: cssHeight, position: "relative" }}>
          <ErrorBoundary onError={onClear}>
            <StyledDataGrid
              columnBufferPx={400}
              rowBufferPx={600}
              hideScrollbar={hideScrollbar}
              selectedId={selectionModel[0]}
              onCellKeyDown={(params, event, details) => {
                if (event.code === "Space") {
                  event.preventDefault();
                  event.stopPropagation();
                  onSelectionModelChange([params.id]);
                } else if (event.code === "Escape") {
                  onSelectionModelChange([]);
                } else if (
                  event.code === "Delete" &&
                  RouterHelper.workspaceRecordings.matchCurrentPath()
                ) {
                  dispatch.entityTable.deleteOrTrash(null);
                } else if (
                  event.code === "KeyP" &&
                  selectionModel.length &&
                  RouterHelper.workspaceRecordings.matchCurrentPath()
                ) {
                  if (
                    disabledByPermission({
                      type: DisableByPermissionsType.CREATE,
                    })
                  )
                    return;
                  navigate(
                    createGlobalModalRoute(GlobalModalTypes.PROJECT_CREATE_SELECTION),
                  );
                } else if (event.code === "KeyA" && (event.ctrlKey || event.metaKey)) {
                  disableSelectAllHotkey = 1;
                  event.preventDefault();
                  event.stopPropagation();

                  // using deprecated api
                  if ((details as any)?.api) {
                    const visible = gridVisibleRowsLookupSelector(
                      (details as any).api.state,
                    );
                    const selectionIds = Object.keys(visible).reduce((acc, i) => {
                      if (visible[i]) acc.push(i);

                      return acc;
                    }, [] as string[]);
                    onSelectionModelChange(selectionIds);
                  }
                }
              }}
              columnHeaderHeight={32}
              initialState={{
                pinnedColumns: { right: ["actions"] },
                ...initialState,
                columns: columnsState,
              }}
              filterModel={filterModel}
              onFilterModelChange={onFilterModelChange}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              onRowClick={() => dispatch.ctxMenu.closeAll()}
              slotProps={{
                row: !noContextMenu
                  ? {
                      onContextMenu: openContextMenu,
                    }
                  : undefined,
                toolbar: {
                  quickFilterProps: { debounceMs: 350 },
                },
              }}
              slots={{
                toolbar: () => {
                  return (
                    <>
                      {titleComponent}
                      <Subscriber />
                      <Box
                        mb={1}
                        display="flex"
                        flexDirection="column"
                        px={toolbarPadding ? 1 : 0}
                        pt={toolbarPadding ? 1 : 0}
                      >
                        {!noSearch && <SearchFilter />}

                        <Box
                          id="filter-group-root"
                          display="flex"
                          flexWrap="wrap"
                          sx={{ maxHeight: 76, overflowY: "auto" }}
                        >
                          {childrenLeftFilter && children}
                          {!noColumnFilter && <ColumnsFilter />}
                          {!noFilters && (
                            <>
                              <Filter />
                              <FilterGroup />
                            </>
                          )}
                          {!childrenLeftFilter && children}
                        </Box>
                      </Box>
                    </>
                  );
                },
                noRowsOverlay: () => {
                  return (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {noDataMessage}
                    </Box>
                  );
                },
                footer: disableFooter ? () => <></> : Footer,
                columnHeaderFilterIconButton: filed => {
                  if (
                    !filterModel ||
                    !filterModel.items.find(i => i.field === filed.field)
                  )
                    return null;

                  return <FilterList sx={{ mx: 0.5 }} />;
                },
              }}
              {...rest}
              rowSelectionModel={
                disableSelect || disableSelectModel
                  ? []
                  : singleSelect && selectionModel.length
                  ? [selectionModel[0]]
                  : selectionModel
              }
              onRowSelectionModelChange={onSelectionModelChange}
              onColumnOrderChange={onColumnOrderChange}
              onColumnResize={onColumnResize}
              onColumnVisibilityModelChange={onColumnVisibilityModelChange}
              disableColumnMenu
              cssHeaderBackGround={cssHeaderBackGround}
            />
          </ErrorBoundary>
          <QuickCreateProjectModel />
        </Box>
      </Box>
    </>
  );
};

const Footer = () => {
  const apiRef = useGridApiContext();
  const selected = useAppSelector(state => state.entityTable.selectedIds.length);
  const visible = gridVisibleRowsLookupSelector(apiRef.current.state);
  const filtered = useMemo(() => {
    return Object.keys(visible).reduce((acc, i) => {
      return visible[i] ? acc + 1 : acc;
    }, 0);
  }, [visible]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        px: 1,
        py: 0.5,
      }}
    >
      <Typography color="GrayText">Selected: {selected}</Typography>
      <Typography color="GrayText" sx={{ ml: 1 }}>
        Total: {filtered}
      </Typography>
    </Box>
  );
};

const Subscriber = memo(() => {
  const apiRef = useGridApiContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectionModel = useAppSelector(state => state.entityTable.selectedIds);
  const scrollToId = useAppSelector(state => state.entityTable.scrollToId);

  useEffect(() => {
    apiRef.current.subscribeEvent("cellKeyDown", (params, event) => {
      // cell navigation is still cell based
      if (event.code === "ArrowLeft" || event.code === "ArrowRight") {
        const totalWidth = apiRef.current.getAllColumns().reduce((acc, i) => {
          return acc + (i.width || 0);
        }, 0);
        const rootDimensions = apiRef.current.getRootDimensions();
        const width = rootDimensions?.viewportInnerSize.width || 0;
        const { left } = apiRef.current.getScrollPosition();
        const step = 100;

        let next = 0;

        if (event.code === "ArrowRight") {
          const nextRight = left + step;
          next = nextRight + width > totalWidth ? totalWidth - width : nextRight;
        } else {
          const nextLeft = left - step;
          next = nextLeft > 0 ? nextLeft : 0;
        }

        apiRef.current.scroll({
          left: next,
        });

        return;
      }
    });
  }, [apiRef, dispatch.entityTable, navigate, selectionModel]);

  useEffect(() => {
    if (scrollToId) {
      dispatch.entityTable.setScrollToId(null);

      const index = apiRef.current.getSortedRowIds().findIndex(id => id === scrollToId);

      if (index !== -1) {
        apiRef.current.scrollToIndexes({ rowIndex: index });
      }
    }
  }, [apiRef, scrollToId, dispatch]);

  return null;
});
