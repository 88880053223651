export interface RematchAdapter<D = unknown, T = unknown, ID = string> {
  data: D[];
  dataById: Map<ID, D>;
  tableData: T[];
  tableDataById: Map<ID, T>;
}

type extractDataType<Type> = Type extends RematchAdapter<infer D> ? D : never;
type extractTableType<Type> = Type extends RematchAdapter<unknown, infer T> ? T : never;
type extractIdType<Type> = Type extends RematchAdapter<unknown, unknown, infer ID>
  ? ID
  : never;

export function rematchAdapter<
  A extends RematchAdapter,
  E = Omit<A, keyof RematchAdapter>,
>({
  idSelector,
  // TODO(ivan): Extracting types to hard...
  // @ts-ignore
  updatedAtSelector = m => m.updated_at,
  sort,
  extraProps,
}: {
  idSelector: (m: extractDataType<A>) => extractIdType<A>;
  updatedAtSelector?: (m: extractDataType<A>) => extractIdType<A>;
  sort?: (m: extractDataType<A>[]) => extractDataType<A>[];
  extraProps: E;
}) {
  const initialState = {
    data: [] as extractDataType<A>[],
    dataById: new Map<string, extractDataType<A>>(),
    tableData: [] as extractTableType<A>[],
    tableDataById: new Map<string, extractTableType<A>>(),
    ...extraProps,
  } as A & E;

  return {
    initialState,
    reducers: {
      setAll(state: A, data: extractDataType<A>[]) {
        state.data = sort ? sort(data) : data;
        data.forEach(one => state.dataById.set(idSelector(one), one));
      },
      setTableData(state: A, data: extractTableType<A>[]) {
        state.tableData = data;
      },
      setTableDataById(state: A, data: Map<string, extractTableType<A>>) {
        state.tableDataById = data;
      },
      upsertOne(state: A, data: extractDataType<A>) {
        const found = state.dataById.get(idSelector(data));

        if (found) {
          const updatedAtFound = updatedAtSelector(found as any);
          const updatedAtNext = updatedAtSelector(data);

          if (
            updatedAtFound &&
            updatedAtNext &&
            new Date(updatedAtFound) >= new Date(updatedAtNext)
          )
            return;

          state.dataById.set(idSelector(data), data);

          const nextData = Array.from(state.dataById.values()) as extractDataType<A>[];

          state.data = sort ? sort(nextData) : nextData;
        } else {
          state.data.push(data);

          if (sort) {
            state.data = sort(state.data as any);
          }

          state.dataById.set(idSelector(data), data);
        }
      },
      updateMany(state: A, data: extractDataType<A>[]) {
        data.forEach(d => {
          state.dataById.set(idSelector(d), d);
        });

        state.data = Array.from(state.dataById.values());
      },
      updatePartial(
        state: A,
        { fields, data }: { fields: Array<string>; data: extractDataType<A> },
      ) {
        const found = state.dataById.get(idSelector(data));

        if (found) {
          const next: Partial<extractDataType<A>> = {};

          for (const field of fields) {
            next[field as keyof extractDataType<A>] =
              data[field as keyof extractDataType<A>];
          }

          state.dataById.set(idSelector(data), { ...found, ...next });

          const nextData = Array.from(state.dataById.values()) as extractDataType<A>[];

          state.data = sort ? sort(nextData) : nextData;
        }
      },
      deleteMany(state: A, data: extractIdType<A>[]) {
        let deleted = false;

        data.forEach(one => {
          if (state.dataById.has(one)) {
            state.dataById.delete(one);
            deleted = true;
          }
        });

        if (deleted) state.data = Array.from(state.dataById.values());
      },
      reset() {
        return { ...initialState };
      },
    },
  };
}
