import { Label } from "@api";
import { toHex, toRGBA } from "@components";
import { ColorSlider, FormDialog, TextField } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/system";
import { useAppDispatch } from "@storeRematch";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as yup from "yup";

export interface LabelFormProps {
  open: boolean;
  onClose(): void;
  label?: Label;
}

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
  })
  .required();

export const LabelForm: React.FC<LabelFormProps> = ({
  open,
  onClose,
  label = undefined,
}) => {
  const params = useParams();
  const workspaceId = String(params.workspaceId);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleSubmit = async (formData: Label) => {
    const payload = {
      name: formData.name,
      color: toHex(formData.color as any),
      description: formData.description,
    };

    try {
      if (formData.id) {
        await dispatch.labels.update({
          workspaceId: workspaceId,
          labelId: formData.id,
          labelPatchRequest: payload,
        });
      } else {
        await dispatch.labels.create({
          workspaceId: workspaceId,
          labelPostRequest: payload,
        });
      }

      const message = label ? t("Label updated") : t("Label created");
      dispatch.notifier.enqueueSnackbar({
        message,
        options: {
          variant: "success",
        },
      });
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormDialog
      open={open}
      title={label ? t("Edit Label") : t("Add Label")}
      description={t("Labels can be used to organize recordings")}
      onFormSubmit={handleSubmit}
      onClose={onClose}
      useFormProps={{
        defaultValues: label
          ? {
              ...label,
              color: label.color
                ? toRGBA(label.color)
                : { a: 255, r: 255, g: 255, b: 255 },
            }
          : {
              name: "",
              description: "",
              color: { a: 255, r: 255, g: 255, b: 255 },
            },
        resolver: yupResolver(schema),
      }}
      create={!label}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TextField
          name="name"
          label={t("Label Name")}
          autoFocus
          required
          sx={{ mb: 2 }}
        />
        <TextField
          name="description"
          placeholder={t("Description")}
          label={t("Description")}
          sx={{ mb: 2 }}
        />
        <ColorSlider name="color" label={t("Color")} />
        <Box sx={{ width: "100%", height: 100 }} />
      </Box>
    </FormDialog>
  );
};
