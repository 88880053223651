import { SearchBox } from "@components/Common/SearchBox";
import { Box } from "@mui/system";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import debounce from "debounce";
import { useLayoutEffect, useMemo } from "react";

const ID = "table_search";
let focused = false;

export const SearchFilter = () => {
  const apiRef = useGridApiContext();
  const filterState = apiRef.current.state.filter;

  const value = filterState.filterModel.quickFilterValues?.[0];

  const debounced = useMemo(() => {
    return debounce(apiRef.current.setQuickFilterValues, 500);
  }, [apiRef]);

  useLayoutEffect(() => {
    if (focused) document.getElementById(ID)?.focus();
  }, []);

  return (
    <Box mr={1} mb={1.5}>
      <SearchBox
        id={ID}
        name="Table search"
        autoFocus={false}
        fullWidth
        searchIcon
        value={value}
        onChange={e => {
          debounced([e.target.value]);
        }}
        onBlur={() => {
          focused = false;
        }}
        onFocus={() => {
          focused = true;
        }}
        onClear={() => {
          apiRef.current.setQuickFilterValues([]);
        }}
      />
    </Box>
  );
};
