import { ContextMenu, CtxMenuItem } from "@components/ctxMenu";
import { MenuList } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@storeRematch/index";
import { ContextMenuTypes } from "@storeRematch/types";
import { copyText } from "@utils";
import { useTranslation } from "react-i18next";

export const VideoImageContextMenu = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const enrichment = useAppSelector(state => state.projectEdit.currentEnrichment);

  if (!enrichment) return null;

  const id = enrichment.args
    ? enrichment.args.markerless_id ||
      enrichment.args.static_image_id ||
      enrichment.args.surface_id
    : undefined;
  const disabled = !id;

  const close = () => {
    dispatch.ctxMenu.closeAll();
  };

  return (
    <ContextMenu type={ContextMenuTypes.VIDEO_REFERENCE_IMAGE}>
      <MenuList>
        <CtxMenuItem
          label={t("Copy image ID")}
          onClick={() => {
            if (id) copyText(t("Image ID copied"), id);
            close();
          }}
          disabled={disabled}
          tooltip={t("Image does not exist")}
          divider
        />
        <CtxMenuItem
          label={t("Download image")}
          onClick={() => {
            dispatch.download.downloadEnrichmentImage(null);
            close();
          }}
          disabled={disabled}
          tooltip={t("Image does not exist")}
        />
      </MenuList>
    </ContextMenu>
  );
};
