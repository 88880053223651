import { SubmitButton, TextFieldChip } from "@form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Loader } from "./Loader";

const schema = yup
  .object()
  .shape({
    emails: yup.array(yup.string().email()).min(1),
  })
  .required();

export const InviteMembersModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const open = useAppSelector(state => state.app.inviteMembersAfterWorkspaceIsCreated);
  const loading = useAppSelector(
    state => !state.loading.effects.app.loadWorkspaceData.success,
  );
  const name = useAppSelector(
    state => state.app.currentWorkspaceMembership?.workspace.name,
  );

  const methods = useForm({
    defaultValues: { emails: [] },
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    dispatch.app.setInviteMembersAfterWorkspaceIsCreated(false);
    methods.reset();
  };

  const handleSubmit = async ({ emails }: { emails: string[] }) => {
    await dispatch.invites.createInvite({ emails, role: "EDITOR" });
    handleClose();
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t("Your New Workspace")} "{name}"
      </DialogTitle>
      <DialogContent sx={{ pb: 2 }}>
        <Typography variant="body1" color="text.secondary">
          {t(
            "Add collaborators to your workspace. You can always manage users in workspace settings.",
          )}
        </Typography>
        <Divider sx={{ mt: 2, mb: loading ? 0 : 2 }} />

        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleSubmit)}
            onKeyDown={e => (e.key === "Enter" ? e.preventDefault() : null)}
            noValidate
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  {t("Invite Workspace Editors")}
                </Typography>
                <TextFieldChip
                  name="emails"
                  placeholder={t("Type and press enter to add emails")}
                  isError={name =>
                    !RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g).test(name)
                  }
                  variant="filled"
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                  <Button
                    variant="text"
                    color="primary"
                    sx={{ mr: 1 }}
                    onClick={handleClose}
                  >
                    {t("Skip")}
                  </Button>
                  <SubmitButton>{t("Send Invite")}</SubmitButton>
                </Box>
              </>
            )}
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};
