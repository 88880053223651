import { api, Template, TemplatePatchResponse } from "@api";
import { createModel } from "@rematch/core";
import { rematchAdapter, RematchAdapter } from "@utils";
import i18n from "../i18n";
import type { RootModel, TemplatesTableData } from "./index";

interface Adapter extends RematchAdapter<Template, TemplatesTableData> {}

const adapter = rematchAdapter<Adapter>({
  idSelector: m => m.id,
  extraProps: {},
});

export const templates = createModel<RootModel>()({
  state: adapter.initialState,
  reducers: {
    ...adapter.reducers,
  },
  effects: dispatch => ({
    async get(workspaceId: string) {
      const data = await api.getTemplates({ workspaceId });

      dispatch.templates.setAll(data.result);
    },
    async updateInactive(
      { inactive, ids }: { inactive: boolean; ids: string[] },
      state,
    ) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const promises: Promise<TemplatePatchResponse>[] = [];

      if (!workspaceId) return;

      ids.forEach(id => {
        promises.push(
          api.patchTemplate({
            workspaceId,
            templateId: id,
            templatePatchRequest: {
              id,
              archived_at: inactive ? new Date().toISOString() : null,
            },
          }),
        );
      });

      try {
        const res = await Promise.all(promises);

        dispatch.templates.updateMany(res.map(r => r.result));
        dispatch.templates.prepare(null);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t(inactive ? "Templates trashed" : "Templates restored"),
          options: {
            variant: "success",
            buttonComponent: inactive
              ? {
                  text: i18n.t("Undo"),
                  onClick: () => {
                    dispatch.templates.updateInactive({
                      ids,
                      inactive: false,
                    });
                  },
                }
              : undefined,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async delete(ids: string[], state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;

      if (!workspaceId) return null;

      try {
        await api.deleteTemplates({
          workspaceId,
          templatesDeleteRequest: { template_ids: ids },
        });

        dispatch.templates.deleteMany(ids);
        dispatch.templates.prepare(null);
        dispatch.recordings.prepare(null);

        const message = ids.length
          ? i18n.t("Templates deleted").toString()
          : i18n.t("Template deleted").toString();
        dispatch.notifier.enqueueSnackbar({
          message,
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    prepare(_, state) {
      const templates = state.templates.data;
      const counters = state.recordings.counters;
      const membersById = state.members.dataById;
      const tableDataById = new Map<string, TemplatesTableData>();

      const tableData: TemplatesTableData[] = templates.map(template => {
        const next: TemplatesTableData = {
          id: template.id,
          template,
          recordingsCount: counters.templates[template.id]?.count || 0,
          createdBy: membersById.get(template.created_by_user_id),
        };
        tableDataById.set(template.id, next);
        return next;
      });

      dispatch.templates.setTableData(tableData);
      dispatch.templates.setTableDataById(tableDataById);
    },
    async create(id: string, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;

      if (!workspaceId) return;

      try {
        const data = await api.postTemplate({
          workspaceId,
          templatePostRequest: { id, name: "Untitled" },
        });

        dispatch.templates.upsertOne(data.result);
        dispatch.templates.prepare(null);
        dispatch.recordings.prepare(null);

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Template created").toString(),
          options: { variant: "success" },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async update(published: boolean, state) {
      const current = state.templateForm.current;
      const draft = state.templateForm.draft;
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;

      if (!current || !workspaceId) return;

      try {
        dispatch.templateForm.setUnsaved(false);
        const data = await api.patchTemplate({
          workspaceId,
          templateId: current.id,
          templatePatchRequest: {
            id: current.id,
            items: draft.items,
            name: draft.name,
            recording_name_format: draft.nameFormat,
            description: draft.description,
            published_at: published ? new Date().toISOString() : undefined,
          },
        });

        dispatch.templates.upsertOne(data.result);
        dispatch.templates.prepare(null);
        dispatch.recordings.prepare(null);

        dispatch.notifier.enqueueSnackbar({
          message: i18n
            .t(published ? "Template published" : "Template updated")
            .toString(),
          options: { variant: "success" },
        });
      } catch (error) {
        console.error(error);
      }
    },
  }),
});
