import { EnrichmentTypesEnum } from "@api";
import { Error, Warning } from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
import { useAppSelector } from "@storeRematch";
import { getEnrichmentStatusText } from "@utils";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const EnrichmentName: FC<{ id?: string | null; type?: boolean }> = ({
  id,
  type,
}) => {
  const { t } = useTranslation();
  const enrichment = useAppSelector(state => state.enrichments.dataById.get(id || ""));
  const typeName = type ? "AOI Heatmap" : "Heatmap";

  return (
    <>
      <Typography variant="caption" sx={{ mb: 1.5, color: "text.secondary" }}>
        {t("Enrichment")}
      </Typography>
      <Typography>{enrichment?.name || "-"}</Typography>

      {!enrichment ? (
        <Notification text={t("Enrichment does not exist.")} error />
      ) : enrichment.status?.COMPUTING ? (
        <Notification
          text={t(
            `This enrichment is still processing and your ${typeName} may be incomplete.`,
          )}
        />
      ) : enrichment.status?.ERROR ? (
        <Notification
          text={t(
            "Enrichment error. No visualization can be shown. Check the enrichment for details.",
          )}
          error
        />
      ) : !getEnrichmentStatusText(enrichment) &&
        enrichment.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER ? (
        <Notification
          text={t(
            `This enrichment has not been run, and the ${typeName} is incomplete.`,
          )}
        />
      ) : null}
    </>
  );
};

const Notification: FC<{
  text: string;
  error?: boolean;
}> = ({ text, error }) => {
  const Icon = error ? Error : Warning;
  const color = error ? "error" : "warning";

  return (
    <Paper sx={{ display: "flex", alignItems: "center", p: 1, mt: 2 }}>
      <Icon color={color} fontSize="small" sx={{ mr: 1 }} />
      <Typography variant="body3" color="text.secondary">
        {text}
      </Typography>
    </Paper>
  );
};
