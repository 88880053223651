import { Button } from "@mui/material";
import { Box } from "@mui/system";
import * as Sentry from "@sentry/browser";
import { Component, ErrorInfo, PropsWithChildren } from "react";
import i18n from "../i18n";
import { ErrorPage } from "./ErrorPage";

interface ErrorBoundaryProps extends PropsWithChildren {
  ErrorComponent?: JSX.Element;
  onError?: () => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(_: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    Sentry.captureException(errorInfo.componentStack);
    console.log(_, errorInfo);
  }

  onClear = () => {
    this.props.onError?.();
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        this.props.ErrorComponent || (
          <ErrorPage title={i18n.t("Table filter url is invalid").toString()}>
            <Box mb={2}>{i18n.t("Go back to default filter").toString()}</Box>
            {this.props.onError && (
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={this.onClear}>{i18n.t("Back").toString()}</Button>
              </Box>
            )}
          </ErrorPage>
        )
      );
    }

    return this.props.children;
  }
}
