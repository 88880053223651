import { RecordingWithData, store } from "@storeRematch";

export function filterCreateProjectRecordingsList() {
  return base(
    data =>
      data.recording.is_processed === false ||
      Boolean(data.recording.preprocessing_error_message) === true,
  );
}

export function filterCreateProjectRecordingsSave() {
  return base(
    data =>
      data.recording.is_processed === true ||
      Boolean(data.recording.preprocessing_error_message) === false,
  );
}

function base(check: (recording: RecordingWithData) => boolean) {
  const recordings = store.getState().recordings.tableDataById;

  return store.getState().entityTable.selectedIds.reduce((acc, id) => {
    if (recordings.has(id) && check(recordings.get(id) as RecordingWithData))
      acc.push(recordings.get(id) as RecordingWithData);

    return acc;
  }, [] as RecordingWithData[]);
}
