import { ApriltagResult, Calibration, GazeDatapoint, SurfaceResult } from "@api";
import { overlayControl } from "./OverlayControl";

export type Model = ShapesControl<GazeDatapoint | ApriltagResult | SurfaceResult, any>;

export interface DataType {
  start_timestamp?: number;
  end_timestamp?: number;
}

export interface ShapesControlProps {
  shapeType?: string;
  shapeIds?: string[];
  canvasId?: string;
  paintType?: boolean;
  canvasSkip?: boolean;
  canvasOverReference?: boolean;
}

export type ShapesControlChildProps = Partial<ShapesControlProps>;

export abstract class ShapesControl<T extends DataType, S extends d3.BaseType> {
  private _events: T[] = [];
  currentEventIndex: number = 0;
  shapes: d3.Selection<S, unknown, HTMLElement, any>[] = [];
  shapeIds: string[] = [];
  shapeType: ShapesControlProps["shapeType"] = "";
  calibration: Calibration | null = null;
  canvasId: ShapesControlProps["canvasId"] = "";
  canvasSkip = false;
  canvasOverReference = false;
  paintType = false;
  _hide = false;

  constructor({
    shapeType,
    shapeIds,
    canvasId,
    paintType,
    canvasSkip,
    canvasOverReference,
  }: ShapesControlProps) {
    this.shapeType = shapeType;
    this.shapeIds = shapeIds || [];
    this.canvasId = canvasId;
    this.paintType = paintType === true ? true : false;
    this.canvasSkip = canvasSkip || canvasOverReference ? true : false;
    this.canvasOverReference = canvasOverReference ? true : false;
  }

  reset(full: boolean) {
    this.events = [];
    this.currentEventIndex = 0;

    if (full) {
      this.calibration = null;
    }
  }

  set events(events: T[]) {
    this._events = events;

    if (
      overlayControl.instance !== null &&
      overlayControl.instance.paused() &&
      !overlayControl.instance.isInPictureInPicture()
    ) {
      overlayControl.shouldRender(this);
    }
  }

  get events() {
    return this._events;
  }

  get hide() {
    return this._hide;
  }

  set hide(next: boolean) {
    this._hide = next;
    overlayControl.shouldRender(this);
  }

  getCurrentEvent() {
    return this.events[this.currentEventIndex];
  }

  paint(obj: {
    shape: d3.Selection<S, unknown, HTMLElement, any>;
    event?: T;
    index: number;
  }): void {
    console.error("paint", obj);
  }

  paintAll(event?: T): void {
    console.error("paintAll", event);
  }

  notPainted() {}

  getModelTimeStart(model?: T): number {
    return model && model.start_timestamp ? model.start_timestamp : 0;
  }

  getModelTimeEnd(model?: T): number {
    return model && model.end_timestamp ? model.end_timestamp : 0;
  }

  checkConditions(model?: T): boolean {
    return !model ? false : true;
  }

  findCurrentEventIndex(inTime: number) {
    const time = this.formatTimestamp(inTime);
    let index = 0;
    let start = 0;
    let end = this.events.length - 1;

    while (start <= end) {
      if (start === end) {
        break;
      }

      index = Math.floor((start + end) / 2);

      if (this.getModelTimeStart(this.events[index]) < time) {
        start = index + 1;
      } else {
        end = index - 1;
      }
    }

    this.currentEventIndex = Math.max(0, index - 3);
    this.filterBasedOnTimeRange(time);
  }

  setEvents(events: T[]) {
    this.events = events;

    if (!overlayControl.instance) return;

    this.findCurrentEventIndex(overlayControl.instance.currentTime());
  }

  mergeEvents(events: T[]) {
    this.events = this.events.concat(events);

    if (!overlayControl.instance) return;

    this.findCurrentEventIndex(overlayControl.instance.currentTime());
  }

  filterBasedOnTimeRange(currentTime: number) {
    const n = this.events.length;

    let found = false;
    let nextIndex = this.currentEventIndex;

    while (nextIndex < n) {
      if (this.isCloseEnough(this.events[nextIndex], currentTime)) {
        found = true;
        break;
      }

      nextIndex += 1;
    }

    this.currentEventIndex = found ? nextIndex : this.currentEventIndex;

    return this.events[this.currentEventIndex];
  }

  isCloseEnough(event: T, currentTime: number) {
    const time = this.formatTimestamp(currentTime);
    const start = this.formatTimestamp(this.getModelTimeStart(event));
    const end = this.formatTimestamp(this.getModelTimeEnd(event));

    return start <= time && end >= time;
  }

  hideShapes() {
    this.shapes.forEach(s => s.style("display", "none"));
    this.clearCtx();
  }

  showShape(shape: d3.Selection<S, unknown, HTMLElement, any>) {
    shape.style("display", "unset");
  }

  initShapes() {
    this.removeShapes();

    if (this.shapeIds && this.shapeType) {
      this.shapeIds?.forEach(() =>
        this.shapes.push(overlayControl.d3.append(this.shapeType as string)),
      );
      this.hideShapes();
    }
  }

  firstOrCreateShape(id: string) {
    let shapeIdIndex = this.shapeIds.indexOf(id);
    let shape = this.shapes[shapeIdIndex];

    if (!shape) {
      shape = overlayControl.d3.append(this.shapeType as string);
      this.shapeIds.push(id);
      shapeIdIndex = this.shapeIds.length - 1;
      this.shapes.push(shape);
    }

    return { shape, shapeIdIndex };
  }

  removeShapes(): void {
    this.shapes.forEach(s => s.remove());
    this.shapes = [];
  }

  getCanvas() {
    return this.canvasId
      ? (document.getElementById(this.canvasId) as HTMLCanvasElement | null)
      : null;
  }

  getCtx() {
    const can = this.getCanvas();

    if (!can) return null;

    const ctx = can.getContext("2d");

    if (!ctx) return null;

    return ctx;
  }

  clearCtx() {
    const ctx = this.getCtx();

    if (!ctx) return;

    const rect = ctx.canvas.getBoundingClientRect();

    ctx.clearRect(0, 0, rect.width, rect.height);
  }

  formatTimestamp(time: number) {
    return Number(time.toFixed(3));
  }
}
