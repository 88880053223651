import { Play } from "@customIcons";
import { Pause, Replay } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { useEffect, useState } from "react";

enum VideoPlayButtonTypes {
  PLAY,
  PAUSE,
  REPEAT,
}

export const VideoPlayButton = () => {
  const dispatch = useAppDispatch();
  const instance = useAppSelector(state => state.video.instance);
  const [state, setState] = useState(VideoPlayButtonTypes.PLAY);
  const disabled = useAppSelector(state =>
    state.video.hideVideoPlayer
      ? true
      : document.URL.includes("projects")
      ? state.projectEdit.recordings.length === 0
      : state.recordings.data.length === 0,
  );

  const onClick = () => {
    if (!instance) return;

    switch (state) {
      case VideoPlayButtonTypes.PLAY:
        setState(VideoPlayButtonTypes.PAUSE);
        instance.play();
        break;
      case VideoPlayButtonTypes.PAUSE:
        setState(VideoPlayButtonTypes.PLAY);
        instance.pause();
        break;
      case VideoPlayButtonTypes.REPEAT:
        setState(VideoPlayButtonTypes.PAUSE);
        instance.currentTime(0);
        instance.play();
        break;
    }
  };

  useEffect(() => {
    if (!instance) return;

    function ended() {
      setState(VideoPlayButtonTypes.REPEAT);
    }

    function reset() {
      setState(VideoPlayButtonTypes.PLAY);
    }

    function update() {
      if (!instance) return;

      const remaining = instance.remainingTime();
      const duration = instance.duration();

      if (instance.paused() || remaining === duration) {
        setState(VideoPlayButtonTypes.PLAY);
      } else if (!remaining) {
        setState(VideoPlayButtonTypes.REPEAT);
      } else {
        setState(VideoPlayButtonTypes.PAUSE);
      }
    }

    instance.on("timeupdate", update);
    instance.on("ended", ended);
    instance.on("loadstart", reset);

    return () => {
      instance.off("timeupdate", update);
      instance.off("ended", ended);
      instance.off("loadstart", reset);
    };
  }, [instance, dispatch]);

  return (
    <IconButton onClick={onClick} disabled={disabled}>
      {state === VideoPlayButtonTypes.PLAY && <Play />}
      {state === VideoPlayButtonTypes.PAUSE && <Pause />}
      {state === VideoPlayButtonTypes.REPEAT && <Replay />}
    </IconButton>
  );
};
