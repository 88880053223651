import { Template } from "@api";
import { DataAsList } from "@components/DataAsList";
import { useAppSelector } from "@storeRematch";
import { FC } from "react";

export const TemplatesAsList: FC<{
  onClick: (checked: boolean, model: Template) => void;
  localState?: Record<string, { checked: boolean; indeterminate: boolean }>;
}> = ({ localState, onClick }) => {
  const tableData = useAppSelector(state => state.templates.tableData);

  return (
    <DataAsList
      localState={localState}
      tableData={tableData}
      getIdValue={t => t.template.id}
      getModel={t => t.template}
      getSearchValue={t => t.template.name}
      onClick={onClick}
    />
  );
};
