import { Divider } from "@mui/material";
import { Box, BoxProps, styled } from "@mui/system";
import { useAppSelector } from "@storeRematch";
import { FC, forwardRef, useEffect, useState } from "react";

export const LIST_ROOT_ID = "video_events_list";
export const WIDTH_SCROLL_DEFAULT = 20;
export const LIST_WIDTH_CALC = `calc(100% - ${WIDTH_SCROLL_DEFAULT}px)`;
export function widthCalc(width: number, fixed: boolean) {
  return `calc(100% - ${
    width > 0
      ? fixed
        ? WIDTH_SCROLL_DEFAULT
        : WIDTH_SCROLL_DEFAULT - width
      : WIDTH_SCROLL_DEFAULT
  }px)`;
}

export const useVirtualListScrollWidth = (fixed = false) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const show = useAppSelector(state => state.videoEvents.openEvents);
  const rerender = useAppSelector(state => state.videoEvents.virtualListEvents);
  const fullScreen = useAppSelector(state => state.video.fullScreen);
  const dragger = useAppSelector(
    state =>
      state.dragger.video.size +
      state.dragger.videoFullScreen.size +
      Number(state.video.fullScreen) +
      state.dragger.table.size +
      state.dragger.tableProject.size,
  );

  useEffect(() => {
    const rootDiv = document.getElementById(LIST_ROOT_ID) as HTMLElement | null;
    const scrollDiv = rootDiv?.children[1]?.childNodes[0] as HTMLElement | null;
    const contentDiv = scrollDiv?.firstChild as HTMLElement | null;

    const scrollbarWidth =
      scrollDiv && scrollDiv?.offsetHeight < (contentDiv?.offsetHeight || 0)
        ? scrollDiv.offsetWidth - scrollDiv.clientWidth
        : 0;

    if (scrollDiv) {
      scrollDiv.style.overflowX = "hidden";
    }

    setWidth(scrollbarWidth);
    setHeight((contentDiv?.offsetHeight || 0) - (scrollDiv?.offsetHeight || 0));
  }, [show, fullScreen, dragger, rerender]);

  return {
    width,
    height,
    styleWidth: widthCalc(width, fixed),
  };
};

export const VideoDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const VideoEventsBase = styled(Box)(() => ({
  height: "100%",
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
}));

export const VideoEventsItem: FC<
  {
    leftContent?: JSX.Element;
    rightContent?: JSX.Element;
    hover?: boolean;
    isSelected?: boolean;
    divider?: boolean;
    onContextMenu?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  } & BoxProps
> = forwardRef(
  (
    {
      leftContent,
      rightContent,
      hover = false,
      isSelected = false,
      divider = false,
      onContextMenu,
      ...props
    },
    ref,
  ) => {
    return (
      <Box
        display="flex"
        width="100%"
        height="100%"
        sx={{
          backgroundColor: isSelected ? "action.selected" : "none",
          cursor: onContextMenu ? "context-menu" : "default",
          ":hover": {
            backgroundColor: isSelected
              ? "action.selected"
              : hover
              ? "action.hover"
              : "none",
          },

          "&:hover [data-showonhover='true']:not(:hover)": {
            opacity: 1,
          },
        }}
        onContextMenu={e => {
          if (onContextMenu) {
            e.preventDefault();
            onContextMenu(e);
          }
        }}
      >
        <Box width={166} display="flex" pl={0.5} pr={1}>
          {leftContent}
        </Box>
        {divider && <Divider orientation="vertical" />}
        <Box flexGrow={1} ref={ref} {...props} position="relative">
          {rightContent}
        </Box>
      </Box>
    );
  },
);

export const PositionLine = styled(Box, {
  shouldForwardProp: prop => prop !== "sub",
})<BoxProps & { sub?: boolean }>(({ theme, sub }) => {
  return {
    color: sub ? theme.palette.text.primary : theme.palette.primary.main,
    backgroundColor: sub ? theme.palette.text.primary : theme.palette.primary.main,
    left: "0%",
    width: "0.1px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: sub ? "none" : "unset",
    zIndex: sub ? 10 : 11,
  };
});
