import { Profile, User, Wearer, Workspace } from "@api";
import { AvatarProps, Avatar as MUIAvatar } from "@mui/material";
import ColorHash from "color-hash";
import React from "react";

const colorHash = new ColorHash();

export const stringAvatar = (name: string) => {
  let short = "?";
  const bits = name.split(" ");
  if (bits.length > 1) {
    short = bits[0][0] + bits[1][0];
  } else if (bits.length === 1) {
    short = bits[0][0];
  }
  const bgColor = colorHash.hex(name);
  return {
    sx: {
      bgcolor: bgColor,
    },
    children: short.toUpperCase(),
  };
};

export const StringAvatar: React.FC<AvatarProps> = ({ src, alt, ...rest }) => {
  return (
    <MUIAvatar
      src={src}
      alt={alt}
      variant="rounded"
      {...stringAvatar(alt || "Unknown")}
      {...rest}
      sx={{ width: 26, height: 26 }}
      imgProps={{ onError: () => {} }}
    />
  );
};

interface WorkspaceAvatarProps {
  workspace: Workspace;
}
export const WorkspaceAvatar: React.FC<WorkspaceAvatarProps> = ({ workspace }) => {
  return <StringAvatar alt={workspace.name} src={workspace.picture_url ?? ""} />;
};

interface WearerAvatarProps {
  wearer?: Wearer;
}
export const WearerAvatar: React.FC<WearerAvatarProps> = ({ wearer = null }) => {
  if (wearer) {
    return <StringAvatar alt={wearer.name} src={wearer.photo_url ?? ""} />;
  }
  return <StringAvatar alt={"Unknown"} />;
};

interface UserAvatarProps {
  user: User | Profile | null | undefined;
}
export const UserAvatar: React.FC<UserAvatarProps> = ({ user }) => {
  return <StringAvatar alt={user?.name || "Unknown"} src={user?.photo_url ?? ""} />;
};
