import { BackButton } from "@components";
import { LinkMenuList } from "@components/Common/LinkMenuList";
import { IS_DEV_ENVIRONMENT } from "@constants";
import { Box, Grid, Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

export const AccountLayout: React.FC = () => {
  const { t } = useTranslation();
  const links = [
    {
      name: t("General Settings"),
      to: "account",
    },
    {
      name: t("Developer"),
      to: "developer",
    },
  ];

  if (IS_DEV_ENVIRONMENT) {
    links.push({
      name: t("Dev"),
      to: "demo",
    });
  }

  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid item sx={{ height: "100%" }} xs={3} md={2}>
        <Paper sx={{ height: "100%" }} elevation={1}>
          <Box p={1}>
            <BackButton />
            <LinkMenuList links={links} />
          </Box>
        </Paper>
      </Grid>
      <Grid item sx={{ height: "100%" }} padding={2} xs={9} md={10}>
        <Outlet />
      </Grid>
    </Grid>
  );
};
