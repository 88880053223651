import { EntityDataTable, NoDataRecordings } from "@components";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { Box } from "@mui/system";
import { RouterHelper } from "@pages";
import { columns } from "@pages/workspaces/recordings";
import { useAppDispatch, useAppSelector } from "@storeRematch";
import { LocalStorageAdapterNames } from "@utils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const ProjectFilteredRecordingsDialog: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const workspaceId = useAppSelector(
    state => state.app.currentWorkspaceMembership?.workspace.id,
  );
  const filtered = useAppSelector(state => state.projectFilterRecordings.filtered);
  const showCheck = useAppSelector(state => state.projectFilterRecordings.showCheck);
  const isLoading = useAppSelector(state => state.loading.models.app.loading);

  if (isLoading || !showCheck) return null;

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogTitle>{t("Errored recordings")}</DialogTitle>

      <Box sx={{ display: "flex" }}>
        <EntityDataTable
          rows={filtered}
          columns={columns}
          noFilters
          noColumnFilter
          noResetSelected
          noSearch
          sx={{ minHeight: 300, maxHeight: 500, height: "100%" }}
          disableSelect
          hideFooter
          loading={isLoading}
          noDataMessage={<NoDataRecordings />}
          tableColumnInitialStateStorageKey={
            LocalStorageAdapterNames.workspaceRecordingsErroredColumState
          }
        />
      </Box>

      <DialogActions sx={{ display: "flex", mt: 1 }}>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            navigate(
              RouterHelper.workspaceRecordings.getFullPath({
                props: { workspaceId },
              }),
            );
            dispatch.projectFilterRecordings.reset();
          }}
          sx={{ mr: 1, ml: "auto" }}
        >
          {t("Cancel")}
        </Button>
        <Button
          onClick={() => {
            navigate(-1);
            dispatch.projectFilterRecordings.setShowCheck(false);
          }}
          color="primary"
        >
          {t("Continue")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
