import { EnrichmentTypesEnum, ProjectEnrichment } from "@api";
import {
  ColorSlider,
  ContextMenu,
  CtxMenuItem,
  LinkToAoiEdit,
  SliderTextField,
  VisualizationsName,
} from "@components";
import {
  gazeOverlay,
  overlayControl,
  scanPathOverlay,
  staticImageMapperOverlay,
} from "@components/videoPlayer/controller";
import {
  INFO_EMAIL,
  aprilTagNotSelectedColor,
  aprilTagSelectedColor,
} from "@constants";
import { Play } from "@customIcons";
import {
  ArrowForward,
  Check,
  ChevronLeft,
  ChevronRight,
  Close,
  Help,
  MoreVert,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Select,
  SelectProps,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { GridExpandMoreIcon } from "@mui/x-data-grid-pro";
import { RouterHelper } from "@pages";
import {
  ContextMenuTypes,
  QuestionDialogTypes,
  store,
  useAppDispatch,
  useAppSelector,
} from "@storeRematch";
import {
  DisableByPermissionsType,
  LocalStorageAdapterNames,
  copyText,
  ctxMenuAwaitClickAway,
  disabledByPermission,
  formatReadableEnrichment,
  getEnrichmentStatusText,
  isEnrichmentFormDisabled,
  isErrorAvailable,
  isRunningAvailable,
  recordingHasEvents,
  useGetEnrichmentStatusIcon,
  useGetFullMessage,
  useIsRunDisabledCheck,
} from "@utils";
import { formatDurationNS } from "@utils/formatDuration";
import debounce from "debounce";
import { t } from "i18next";
import { FC, MouseEvent, memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ProjectRecordings } from "./ProjectRecordings";

export const ProjectEnrichmentForm = () => {
  const location = useLocation();
  const params = useParams();
  const enrichmentId = String(params.enrichmentId);
  const modelToSet = useAppSelector(state =>
    state.enrichments.dataById.get(enrichmentId),
  );
  const model = useAppSelector(state => state.projectEdit.currentEnrichment);
  const currentMarkerLess = useAppSelector(
    state => state.projectEdit.currentMarkerLess,
  );
  const currentMarkerLessRecording = useAppSelector(
    state => state.projectEdit.currentMarkerLessRecording,
  );
  const isVisualizations = !!matchPath(
    RouterHelper.projectVisualizationsRender.fullTemplatePath,
    location.pathname,
  );

  const render = useAppSelector(state => state.projectEdit.currentRender);
  const isLoading = useAppSelector(
    state =>
      state.loading.effects.app.loadWorkspaceData.loading ||
      state.loading.effects.projectEdit.get.loading,
  );

  const dispatch = useAppDispatch();

  const isDisabledByPermissions = disabledByPermission({
    type: DisableByPermissionsType.EDIT,
  });
  const isRun =
    isRunningAvailable(model) &&
    !isErrorAvailable(model) &&
    model?.status?.SUCCESS !== 1;
  const isFormDisabled = isEnrichmentFormDisabled(model);
  const isDisabled = isDisabledByPermissions || isFormDisabled;
  const isRunDisabled = useIsRunDisabledCheck();

  const isAOI = !!matchPath(
    RouterHelper.projectEnrichmentsAOI.fullTemplatePath,
    location.pathname,
  );

  useEffect(() => {
    if (
      modelToSet &&
      (!model ||
        model.id !== modelToSet.id ||
        model.updated_at !== modelToSet.updated_at ||
        model.status?.SUCCESS !== modelToSet.status?.SUCCESS)
    ) {
      dispatch.projectEdit.setCurrentEnrichment(modelToSet);
      dispatch.staticImageMappers.get(modelToSet);

      if (modelToSet.id !== model?.id) dispatch.localization.reset();

      if (modelToSet.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER) {
        scanPathOverlay.onlyCurrent = true;
      } else {
        scanPathOverlay.onlyCurrent = false;
      }
    }
  }, [enrichmentId, dispatch, model, modelToSet]);

  useEffect(() => {
    dispatch.entityTable.setProjectDefaultSelected(null);

    return () => {
      dispatch.projectEdit.reset();
      dispatch.localization.reset();
      dispatch.staticImageMappers.reset();
      scanPathOverlay.onlyCurrent = false;
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch.projectEdit.setForm({
      recordingName: currentMarkerLessRecording ? currentMarkerLessRecording.name : "",
      recordingId: currentMarkerLessRecording ? currentMarkerLessRecording.id : "",
    });
  }, [dispatch, currentMarkerLessRecording]);

  useEffect(() => {
    dispatch.projectEdit.setForm({
      imageId: currentMarkerLess ? currentMarkerLess.reference_image_id : "",
      imageName: currentMarkerLess ? currentMarkerLess.reference_image_id : "",
    });
  }, [dispatch, currentMarkerLess]);

  useEffect(() => {
    if (model?.args?.static_image_id)
      dispatch.projectEdit.setForm({
        imageId: model?.args?.static_image_id,
        imageName: model?.args?.static_image_id,
      });
  }, [model?.args?.static_image_id, modelToSet, dispatch]);

  useEffect(() => {
    if (model && modelToSet && model.id === modelToSet.id) return;

    dispatch.projectEdit.getMarkerLess(null);
    //dispatch.video.getSurface(null);
    dispatch.video.addGazeConfig(null);

    dispatch.projectEdit.setForm({
      name: modelToSet ? modelToSet.name : "",
      kind: modelToSet ? modelToSet.kind : undefined,
      from_event_name: modelToSet ? modelToSet.from_event_name : "",
      to_event_name: modelToSet ? modelToSet.to_event_name : "",
    });
  }, [model, modelToSet, dispatch]);

  useEffect(() => {
    if (!render) return;

    dispatch.projectEdit.setForm({
      gazeColor: {
        r: render?.gaze_circle?.color?.red,
        g: render?.gaze_circle?.color?.green,
        b: render?.gaze_circle?.color?.blue,
        a: render?.gaze_circle?.color?.alpha,
      },
      gazeCircle: {
        // @ts-ignore
        radius: render?.gaze_circle?.radius,
        // @ts-ignore
        stroke: render?.gaze_circle?.stroke_width,
      },
      undistorted: render ? render.undistort_frames : false,
      withGaze: render.with_gaze !== undefined ? render.with_gaze : false,
      withScanPath: render.with_scanpath !== undefined ? render.with_scanpath : false,
    });

    gazeOverlay.hide = !render.with_gaze;
    scanPathOverlay.hide = !render.with_scanpath;
  }, [dispatch, render]);

  if (isLoading) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isAOI) return <Outlet />;

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        my={1}
        px={isVisualizations ? 0 : 1}
        mx={isVisualizations ? -0.5 : 0}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {model?.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER ? (
            <StaticImageMapperRunInfo model={model} />
          ) : (
            <>
              <EnrichmentProcessWrapper />

              <RunButton show={isRun} disabled={isRunDisabled} />
            </>
          )}

          <EnrichmentContextMenuWrapper />
        </Box>
      </Box>
      <InnerDivider noExtra />
      <Box
        px={isVisualizations ? 0 : 1}
        height="100%"
        display="flex"
        flex={1}
        flexDirection="column"
      >
        {!isVisualizations && (
          <>
            <VisualizationsName
              name="Enrichment Type"
              sub={model ? formatReadableEnrichment(model.kind) : "..."}
            />
            <InnerDivider mt={1} />
          </>
        )}

        <Typography mb={2} mt={1}>
          Settings
        </Typography>

        <ItemWrapper>
          <EnrichmentName
            disabled={isDisabledByPermissions}
            visualization={isVisualizations}
          />
        </ItemWrapper>

        <FixationStepper
          disabled={isDisabled || isRunDisabled || isRun}
          disabledTitle={
            isDisabledByPermissions
              ? t("Disabled by permissions")
              : isFormDisabled && model?.status?.SUCCESS !== 1
              ? t("Disabled because part of enrichment has been computed")
              : ""
          }
        />

        <SelectRecording
          disabled={isDisabled}
          disabledTitle={
            isDisabledByPermissions
              ? t("Disabled by permissions")
              : isFormDisabled && model?.status?.SUCCESS !== 1
              ? t("Disabled because part of enrichment has been computed")
              : ""
          }
        />

        <SurfaceControl />

        <ColorAndSizeChanger disabled={isDisabled} />

        <InnerDivider />
        <Box sx={{ mb: 1 }}>
          <Accordion>
            <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
              <Typography>Advanced Settings</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: "inherit", maxWidth: 275 }}>
              <SelectEventFromTo disabled={isDisabledByPermissions} />
              <EnrichmentRIM />
              <EnrichmentTime />
            </AccordionDetails>
          </Accordion>
        </Box>
        <InnerDivider />

        {model?.kind === EnrichmentTypesEnum.MARKER_MAPPER ||
        model?.kind === EnrichmentTypesEnum.SLAM_MAPPER ||
        model?.kind === EnrichmentTypesEnum.STATIC_IMAGE_MAPPER ? (
          <>
            <Box mb={1}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                  <Typography>Tools</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: "inherit" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <LinkToAoiEdit />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
            <InnerDivider />
          </>
        ) : null}
      </Box>
    </>
  );
};
const InnerDivider = ({ mt, noExtra }: { mt?: number; noExtra?: boolean }) => {
  return (
    <Divider
      sx={{
        mb: noExtra ? 0 : 1,
        mt,
        width: `calc(100% + ${noExtra ? 0 : 32}px)`,
        ml: noExtra ? 0 : -2,
      }}
    />
  );
};

const RunButton = ({ show, disabled }: { show: boolean; disabled: boolean }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(
    state => state.loading.effects.projectEdit.runEnrichment.loading,
  );

  if (!show) return null;

  return (
    <Tooltip title={isLoading ? "Loading" : ""}>
      <span>
        <Button
          startIcon={<Play />}
          sx={{ ml: 1 }}
          size="small"
          color="primary"
          onClick={() => {
            dispatch.projectEdit.runEnrichment(null);
          }}
          disabled={disabled || isLoading}
        >
          Run
        </Button>
      </span>
    </Tooltip>
  );
};

const StaticImageMapperRunInfo: FC<{ model?: ProjectEnrichment }> = ({ model }) => {
  const mapping = useMemo(() => {
    if (model?.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER) return 0;

    const { checked, total } = model.fixations_status.reduce(
      (acc, one) => {
        acc.total += one.total_fixations;
        acc.checked += one.checked_fixations;

        return acc;
      },
      { checked: 0, total: 0 },
    );

    const num = Number(((checked / total) * 100).toFixed(0));

    if (isNaN(num)) return 0;

    return num;
  }, [model]);

  return (
    <>
      <CircularProgress
        variant="determinate"
        value={mapping}
        size={16}
        sx={{ mr: 1 }}
      />
      <Typography>{mapping}% Mapped in enrichment</Typography>
    </>
  );
};

const dispatchDebounceUpdate = debounce(
  store.dispatch.projectEdit.updateEnrichment,
  500,
);

const EnrichmentName = ({
  disabled,
  visualization,
}: {
  disabled: boolean;
  visualization: boolean;
}) => {
  const key = "name";
  const dispatch = useAppDispatch();
  const value = useAppSelector(state => state.projectEdit.form[key]);

  return (
    <TextField
      label={`${visualization ? "Visualization" : "Enrichment"} Name`}
      fullWidth
      required
      value={value}
      onChange={e => {
        dispatch.projectEdit.setForm({ [key]: e.target.value });
        dispatchDebounceUpdate(null);
      }}
      disabled={disabled}
    />
  );
};

export const SelectComponent: FC<
  SelectProps & {
    options: Array<{ value: string | number; label: string | number }>;
    onMouseOverEnter?: (value: string | number) => void;
    onMouseOverExit?: () => void;
  }
> = ({
  options,
  fullWidth,
  required,
  label,
  sx,
  disabled,
  onMouseOverEnter,
  onMouseOverExit,
  ...props
}) => {
  return (
    <FormControl sx={sx} variant="standard" fullWidth={fullWidth}>
      {label && (
        <InputLabel
          shrink
          disabled={disabled}
          color="primary"
          required={required}
          disableAnimation
        >
          {label}
        </InputLabel>
      )}
      <Select
        {...props}
        fullWidth={fullWidth}
        label={undefined}
        disabled={disabled}
        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
      >
        {options.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
            onMouseEnter={() => onMouseOverEnter?.(label)}
            onMouseLeave={() => onMouseOverExit?.()}
          >
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const EnrichmentRIM = memo(() => {
  const count = useAppSelector(state => state.projectEdit.recordings.length);
  const enrichment = useAppSelector(state => state.projectEdit.currentEnrichment);
  const projectRecordings = useAppSelector(state => state.projectEdit.recordings);
  const fromName = useAppSelector(state => state.projectEdit.form.from_event_name);
  const toName = useAppSelector(state => state.projectEdit.form.to_event_name);

  const recCounter = useMemo(() => {
    return projectRecordings.reduce((acc, r) => {
      if (
        enrichment &&
        recordingHasEvents(
          { ...enrichment, from_event_name: fromName, to_event_name: toName },
          r.id,
        )
      ) {
        return acc + 1;
      }

      return acc;
    }, 0);
  }, [enrichment, projectRecordings, fromName, toName]);

  return (
    <Typography color="text.secondary" component="span">
      Recordings included: {recCounter}/{count}
    </Typography>
  );
});

const EnrichmentTime = memo(() => {
  const dispatch = useAppDispatch();
  const enrichment = useAppSelector(state => state.projectEdit.currentEnrichment);

  const time = useMemo(() => {
    return dispatch.enrichments.getRunningTime(enrichment);
  }, [dispatch, enrichment]);

  if (!time) return null;

  return (
    <Typography color="text.secondary" component="div">
      Recording time: {time?.readableFormat}
    </Typography>
  );
});

const ItemWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(2),
}));

const TextWithButton = ({
  name,
  label,
  onClick,
  text,
  ButtonComponent,
  disabled,
  disabledTitle,
}: {
  name: "recordingName";
  label: string;
  onClick?: () => void;
  text?: string;
  ButtonComponent?: JSX.Element;
  disabled: boolean;
  disabledTitle?: string;
}) => {
  const value = useAppSelector(state => state.projectEdit.form[name]);

  return (
    <>
      <Box mr={1} flexGrow={1}>
        <TextField
          value={value}
          required
          fullWidth
          label={label}
          sx={{ pointerEvents: "none" }}
          inputProps={{ tabIndex: -1 }}
        />
      </Box>

      <Tooltip title={disabledTitle}>
        <Box sx={{ mt: 2.5, mb: "auto" }}>
          {ButtonComponent || (
            <Button
              disabled={disabled}
              onClick={onClick}
              variant="text"
              color="primary"
            >
              {text}
            </Button>
          )}
        </Box>
      </Tooltip>
    </>
  );
};

const FixationStepper = ({
  disabled,
}: {
  disabled: boolean;
  disabledTitle?: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const kind = useAppSelector(state => state.projectEdit.form.kind);
  const notAvailable = kind !== "static-image-mapper";
  const loading = useAppSelector(
    state =>
      state.loading.effects.staticImageMappers.getFixations.loading ||
      !state.video.instance?.paused() ||
      state.loading.effects.recordingEvents.get.loading,
  );
  const noScanPath = useAppSelector(state => !state.video.recording?.has_scanpath);
  const fixation = useAppSelector(state =>
    state.staticImageMappers.currentFixationIndex === null
      ? null
      : state.staticImageMappers.fixations[
          state.staticImageMappers.currentFixationIndex
        ],
  );
  const isDisabled = !disabled || !!loading || noScanPath;

  if (notAvailable) return null;

  return (
    <>
      <InnerDivider />
      <Box
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography>{t("Manual Mapper")}</Typography>
        <FixationStepperCtx disabled={isDisabled} />
      </Box>
      <FixationStepperControl disabled={isDisabled} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 0.5,
        }}
      >
        <Typography variant="body3" sx={{ color: "text.secondary" }}>
          {t("Mapping Status")}
        </Typography>
        <Tooltip
          title={t(
            "Mark this fixation on the reference image. If the fixation is not on the reference image, use the keyboard shortcut s.",
          )}
        >
          <Help
            fontSize="small"
            sx={{ color: "text.secondary", ml: 1, fontSize: 14 }}
          />
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
          minHeight: 28,
        }}
      >
        {loading ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CircularProgress color="secondary" size={12} />
            <Typography variant="body2" sx={{ ml: 0.5 }}>
              {t("Loading")}
            </Typography>
          </Box>
        ) : noScanPath ? (
          <Typography variant="body2">
            {t("Fixation data is incomplete for this recording, please contact")}
            <a href={`mailto:${INFO_EMAIL}`}> {INFO_EMAIL} </a>
            {t("to resolve this issue.")}
          </Typography>
        ) : !fixation ? (
          <Typography variant="body2">{t("Fixation not found")}</Typography>
        ) : fixation.gaze_on_aoi === null ? (
          <Typography variant="body2">{t("Not mapped")}</Typography>
        ) : fixation.gaze_on_aoi === 1 ? (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <RadioButtonUnchecked
                sx={{ mr: 0.5, fontSize: 12, color: "primary.main" }}
              />
              <Typography variant="body2">{t("Mapped on reference image")}</Typography>
            </Box>
            <Button
              variant="text"
              color="primary"
              disabled={isDisabled}
              onClick={() => {
                dispatch.staticImageMappers.deleteFixationPosition(null);
              }}
            >
              {t("Clear")}
            </Button>
          </>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Close sx={{ mr: 0.5, fontSize: 12, color: "error.main" }} />
              <Typography variant="body2">{t("Not on reference image")}</Typography>
            </Box>
            <Button
              variant="text"
              color="primary"
              disabled={isDisabled}
              onClick={() => {
                dispatch.staticImageMappers.deleteFixationPosition(null);
              }}
            >
              {t("Clear")}
            </Button>
          </>
        )}
      </Box>
      <FixationRecordingStatus />
      <FixationWatcher />
    </>
  );
};

const FixationWatcher = () => {
  const dispatch = useAppDispatch();
  const fixations = useAppSelector(state => state.staticImageMappers.fixations);
  const currentImage = useAppSelector(state => state.projectEdit.currentImage);

  useEffect(() => {
    if (currentImage) {
      dispatch.projectEdit.setForm({
        imageName: currentImage.name || "Unknown",
        imageId: currentImage.id,
      });
      dispatch.staticImageMappers.update(null);
    }
  }, [currentImage, dispatch]);

  useEffect(() => {
    staticImageMapperOverlay.setEvents(fixations);
    overlayControl.shouldRender(staticImageMapperOverlay);
  }, [fixations]);

  return null;
};

const FixationStepperCtx = memo(({ disabled }: { disabled: boolean }) => {
  const dispatch = useAppDispatch();
  const autoMove = useAppSelector(state => state.staticImageMappers.autoMove);

  const handleClick = (e: MouseEvent) => {
    dispatch.ctxMenu.handleClick({
      type: ContextMenuTypes.ENRICHMENT_FORM_MANUAL_MAPPER,
      e,
      position: true,
    });
  };

  return (
    <>
      <IconButton disabled={disabled} size="small" onClick={handleClick}>
        <MoreVert fontSize="small" />
      </IconButton>
      <ContextMenu type={ContextMenuTypes.ENRICHMENT_FORM_MANUAL_MAPPER}>
        <MenuList sx={{ minWidth: 300 }}>
          <CtxMenuItem
            label={t("Auto-advance")}
            onClick={() => {
              dispatch.ctxMenu.closeAll();
              dispatch.staticImageMappers.toggleAutoMove();
            }}
            rightIcon={autoMove ? <Check /> : undefined}
          />
          <CtxMenuItem
            label={t("Clear all mappings in this recording")}
            onClick={() => {
              dispatch.ctxMenu.closeAll();

              dispatch.questionDialog.set({
                type: QuestionDialogTypes.CLEAR_ALL_RECORDING_MAPPINGS,
                onSuccess: () => {
                  dispatch.staticImageMappers.resetFixations(null);
                },
              });
            }}
          />
        </MenuList>
      </ContextMenu>
    </>
  );
});

const FixationStepperControl = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const fixationId = useAppSelector(state =>
    state.staticImageMappers.currentFixationIndex !== null
      ? state.staticImageMappers.fixations[
          state.staticImageMappers.currentFixationIndex
        ]?.fixation_id
      : null,
  );
  const fixationIndex = useAppSelector(
    state => state.staticImageMappers.currentFixationIndex,
  );
  const fixationsLength = useAppSelector(
    state => state.staticImageMappers.fixations.length,
  );
  const [localFixationId, setLocalFixationId] = useState<string>("");

  useEffect(() => {
    setLocalFixationId(typeof fixationId === "number" ? fixationId.toString() : "");
  }, [fixationId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        mt: 1,
        mb: 2,
      }}
    >
      <Typography variant="body3" sx={{ color: "text.secondary", mb: 0.5 }}>
        {t("Fixation")}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <TextField
            sx={{ width: "40%", minWidth: "40%", mr: 1 }}
            disabled={disabled}
            type="number"
            value={localFixationId}
            onChange={e => {
              const str = e.target.value;
              let value = str;

              if (e.target.value) {
                const abs = Math.abs(Number(e.target.value));
                value = abs > 0 ? abs.toString() : "";
              }

              setLocalFixationId(value);
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                const num = +localFixationId;

                if (isNaN(num) || num < 1) return;

                dispatch.staticImageMappers.setFixation(num);
              }
            }}
            inputProps={{ style: { fontSize: 12.25 }, min: 1 }}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <Tooltip
            title={
              <Typography variant="body3">
                {t("Previous Fixation")}
                <Typography variant="body3" color="text.secondary" sx={{ ml: 1 }}>
                  A
                </Typography>
              </Typography>
            }
          >
            <span>
              <IconButton
                size="small"
                color="primary"
                disabled={disabled || !fixationsLength || fixationIndex === 0}
                onClick={dispatch.staticImageMappers.prevFixation}
              >
                <ChevronLeft fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              <Typography variant="body3">
                {t("Next Fixation")}
                <Typography variant="body3" color="text.secondary" sx={{ ml: 1 }}>
                  D
                </Typography>
              </Typography>
            }
          >
            <span>
              <IconButton
                size="small"
                color="primary"
                disabled={
                  disabled || !fixationsLength || fixationsLength - 1 === fixationIndex
                }
                onClick={dispatch.staticImageMappers.nextFixation}
              >
                <ChevronRight fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

const FixationRecordingStatus = () => {
  const { t } = useTranslation();
  const fixationsStatus = useAppSelector(state => {
    const find = state.enrichments.dataById.get(
      state.staticImageMappers.current?.id || "",
    );

    if (!find || find.kind !== EnrichmentTypesEnum.STATIC_IMAGE_MAPPER) return;

    return find.fixations_status;
  });
  const recordingId = useAppSelector(state => state.video.recording?.id);

  const status = useMemo(() => {
    return fixationsStatus?.find(one => one.recording_id === recordingId);
  }, [fixationsStatus, recordingId]);

  return (
    <Box
      sx={{
        display: "flex",
        mb: 0.5,
        flexDirection: "column",
      }}
    >
      <Typography variant="body3" sx={{ color: "text.secondary" }}>
        {t("Recording Mapping Progress")}
      </Typography>
      <Typography variant="body2" sx={{ my: 1 }}>
        {status?.checked_fixations || 0} / {status?.total_fixations || 0}{" "}
        {t("Fixations Mapped")}
      </Typography>
    </Box>
  );
};

const SelectRecording = ({
  disabled,
  disabledTitle,
}: {
  disabled: boolean;
  disabledTitle?: string;
}) => {
  const [open, setOpen] = useState(false);
  const dataById = useAppSelector(state => state.recordings.dataById);
  const dispatch = useAppDispatch();
  const name = "recordingName";
  const kind = useAppSelector(state => state.projectEdit.form.kind);
  const notAvailable = kind !== "slam-mapper";

  if (notAvailable) return null;

  const toggleOpen = () => {
    setOpen(!open);
  };

  const onSelect = (id: string) => {
    const recording = dataById.get(id);

    if (!recording) return;

    const [h, m, sMs] = formatDurationNS(recording.duration_ns).split(":");
    const [s, ms] = sMs.split(".");

    if (
      Number(h) > 0 ||
      Number(m) > 3 ||
      (Number(m) === 3 && (Number(s) > 0 || Number(ms) > 0))
    ) {
      dispatch.notifier.enqueueSnackbar({
        message: "Recording longer then 3 minutes are not allowed",
        options: {
          key: "recording_duration",
          variant: "error",
        },
      });
      return;
    }

    toggleOpen();

    dispatch.projectEdit.setForm({ [name]: recording.name, recordingId: recording.id });
    dispatch.projectEdit.updateMarkerless(null);
  };

  return (
    <ItemWrapper>
      <TextWithButton
        name={name}
        label="Scanning Recording"
        onClick={toggleOpen}
        text={"Select"}
        disabled={disabled}
        disabledTitle={disabledTitle}
      />
      <Dialog
        open={open}
        onClose={toggleOpen}
        fullWidth
        maxWidth="md"
        PaperProps={{ sx: { height: "80vh" }, elevation: 0 }}
      >
        <DialogTitle>Select Scanning Recording</DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          <ProjectRecordings
            onCustomSelect={onSelect}
            tableColumnInitialStateStorageKey={
              LocalStorageAdapterNames.projectRecordingsExtraColumState
            }
            disableSelectModel
            cssHeaderBackGround="#1C1C1F"
          />
        </DialogContent>
      </Dialog>
    </ItemWrapper>
  );
};

let disableOnHover = true;
const SelectEventFromTo = ({ disabled }: { disabled: boolean }) => {
  const key1 = "from_event_name";
  const key2 = "to_event_name";
  const dispatch = useAppDispatch();
  const value1 = useAppSelector(state => state.projectEdit.form[key1]);
  const value2 = useAppSelector(state => state.projectEdit.form[key2]);
  const kind = useAppSelector(state => state.projectEdit.form.kind);
  const options = useAppSelector(state => state.projectEvents.select);
  const isDisabled = disabled || kind === EnrichmentTypesEnum.RAW_DATA_EXPORT;
  const value1Found = options.find(v => v.value === value1);
  const value2Found = options.find(v => v.value === value2);

  const resetTemporalSelectionHover = () => {
    dispatch.projectEdit.setTemporalSelectionHover({ from: null, to: null });
  };

  return (
    <>
      <InputLabel>Temporal Selection</InputLabel>
      <Box display="flex" mt={1}>
        <SelectComponent
          label="Start"
          required
          sx={{ mb: 2, minWidth: "45%" }}
          value={value1}
          options={
            isDisabled
              ? [{ value: value1, label: value1 }]
              : value1Found
              ? options
              : [...options, { value: value1, label: value1 }]
          }
          disabled={isDisabled}
          onChange={e => {
            resetTemporalSelectionHover();
            dispatch.projectEdit.setForm({ [key1]: e.target.value as any });
            dispatchDebounceUpdate(null);
          }}
          onMouseOverEnter={e => {
            if (disableOnHover) return;
            dispatch.projectEdit.setTemporalSelectionHover({ from: e as string });
          }}
          onMouseOverExit={resetTemporalSelectionHover}
          onBlur={resetTemporalSelectionHover}
          onClose={() => {
            disableOnHover = true;
            resetTemporalSelectionHover();
          }}
          onOpen={() => {
            disableOnHover = false;
          }}
          variant="standard"
        />

        <ArrowForward sx={{ my: "auto", mx: 1 }} color="disabled" />

        <SelectComponent
          label="End"
          required
          sx={{ mb: 2, minWidth: "45%" }}
          value={value2}
          options={
            isDisabled
              ? [{ value: value2, label: value2 }]
              : value2Found
              ? options
              : [...options, { value: value2, label: value2 }]
          }
          disabled={isDisabled}
          onChange={e => {
            resetTemporalSelectionHover();
            dispatch.projectEdit.setForm({ [key2]: e.target.value as any });
            dispatchDebounceUpdate(null);
          }}
          onMouseOverEnter={e => {
            if (disableOnHover) return;
            dispatch.projectEdit.setTemporalSelectionHover({ to: e as string });
          }}
          onMouseOverExit={resetTemporalSelectionHover}
          onBlur={resetTemporalSelectionHover}
          onClose={() => {
            disableOnHover = true;
            resetTemporalSelectionHover();
          }}
          onOpen={() => {
            disableOnHover = false;
          }}
          variant="standard"
        />
      </Box>
    </>
  );
};

const EnrichmentContextMenuWrapper = memo(() => {
  const model = useAppSelector(state => state.projectEdit.currentEnrichment);

  const handleClick = (e: MouseEvent) => {
    ctxMenuAwaitClickAway({
      type: ContextMenuTypes.ENRICHMENT_FORM,
      e,
      position: true,
    });
  };

  return (
    <>
      <IconButton
        sx={{ ml: "auto" }}
        size="small"
        onClick={handleClick}
        disabled={!model}
      >
        <MoreVert fontSize="small" />
      </IconButton>
      {model && <EnrichmentContextMenu model={model} />}
    </>
  );
});

const EnrichmentContextMenu = ({ model }: { model: ProjectEnrichment }) => {
  const isVisualizations = document.URL.includes("/visualizations");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch.ctxMenu.closeAll();
  };
  const isDisabled = isRunningAvailable(model);
  const disabledPermissionsEdit = disabledByPermission({
    type: DisableByPermissionsType.EDIT,
  });
  const disabledPermissionsDelete = disabledByPermission({
    type: DisableByPermissionsType.DELETE,
  });

  return (
    <ContextMenu type={ContextMenuTypes.ENRICHMENT_FORM}>
      <MenuList>
        <CtxMenuItem
          label={t(`Copy ${isVisualizations ? "visualization" : "enrichment"} ID`)}
          onClick={() => {
            onClose();
            if (model)
              copyText(
                t(`${isVisualizations ? "Visualization" : "Enrichment"} ID copied`),
                model.id,
              );
          }}
        />
        <CtxMenuItem
          label={t("Cancel processing")}
          onClick={() => {
            onClose();
            dispatch.projectEdit.stopRunningEnrichment(null);
          }}
          divider
          disabled={disabledPermissionsEdit || isDisabled}
          tooltip={
            disabledPermissionsEdit
              ? t("Disabled by permissions")
              : t(`${isVisualizations ? "Visualization" : "Enrichment"} is not running`)
          }
        />

        <CtxMenuItem
          label={t(`Delete ${isVisualizations ? "visualization" : "enrichment"}`)}
          onClick={async () => {
            onClose();

            dispatch.questionDialog.set({
              type: QuestionDialogTypes.ENRICHMENT_DELETE,
              onSuccess: async () => {
                const isDeleted = await dispatch.projectEdit.deleteEnrichment(null);

                if (!isDeleted) return;

                const isVisualizations = document.URL.includes("/visualizations");

                if (isVisualizations)
                  navigate(
                    RouterHelper.projectVisualizations.getRelativePath({
                      prefix: "../",
                    }),
                  );
                else
                  navigate(
                    RouterHelper.projectEnrichments.getRelativePath({
                      prefix: "../../",
                    }),
                    { replace: true },
                  );
              },
              extra: { title: model.name },
            });
          }}
          disabled={disabledPermissionsDelete}
          tooltip={t("Disabled by permissions")}
        />
      </MenuList>
    </ContextMenu>
  );
};

const EnrichmentProcessWrapper = memo(() => {
  const dispatch = useAppDispatch();
  const model = useAppSelector(state => state.projectEdit.currentEnrichment);

  const handleClick = (e: MouseEvent) => {
    dispatch.ctxMenu.handleClick({
      type: ContextMenuTypes.ENRICHMENT_PROCESS,
      e,
      position: true,
    });
  };

  const text = getEnrichmentStatusText(model);

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        {useGetEnrichmentStatusIcon(model)}
      </IconButton>
      {text && (
        <Typography marginLeft={1} my="auto">
          {text}
        </Typography>
      )}
      <EnrichmentProcess model={model} />
    </>
  );
});

const EnrichmentProcess = ({ model }: { model?: ProjectEnrichment }) => {
  return (
    <ContextMenu type={ContextMenuTypes.ENRICHMENT_PROCESS}>
      <Box width="100%" minWidth={400} maxWidth={400} p={1}>
        <Typography variant="body2">{useGetFullMessage(model)}</Typography>
      </Box>
    </ContextMenu>
  );
};

const SurfaceControl = memo(() => {
  const { t } = useTranslation();
  const model = useAppSelector(state => state.projectEdit.currentEnrichment);
  const isSurfaceDetected = useAppSelector(
    state => state.projectEdit.isSurfaceDetected,
  );
  const isInitiated = useAppSelector(
    state => state.projectEdit.currentSurface?.is_initialized,
  );
  const dispatch = useAppDispatch();
  const kind = useAppSelector(state => state.projectEdit.form.kind);
  const isDisabled = isEnrichmentFormDisabled(model);
  const disabledPermissions = disabledByPermission({
    type: DisableByPermissionsType.EDIT,
  });

  if (kind !== "marker-mapper") return null;

  return (
    <>
      <Box sx={{ display: "flex", mb: 1.5 }}>
        <Typography>Marker Mapper</Typography>
        <Typography color="error" sx={{ ml: "auto", color: "text.secondary" }}>
          {!isSurfaceDetected && !isInitiated
            ? "No surface defined"
            : "Surface defined"}
        </Typography>
      </Box>
      {isInitiated ? (
        <Box display="flex">
          <Tooltip
            title={disabledPermissions ? t("Disabled by permissions") : undefined}
          >
            <Box sx={{ width: "50%", mr: 1 }}>
              <Button
                onClick={dispatch.projectEdit.rotateSurface}
                disabled={disabledPermissions}
                fullWidth
              >
                Rotate Surface
              </Button>
            </Box>
          </Tooltip>

          <Tooltip
            title={
              isDisabled
                ? t("Cannot reset surface once enrichment is processed")
                : disabledPermissions
                ? t("Disabled by permissions")
                : undefined
            }
          >
            <Box sx={{ width: "50%" }}>
              <Button
                onClick={dispatch.projectEdit.resetSurface}
                disabled={isDisabled || disabledPermissions}
                fullWidth
              >
                Reset Surface
              </Button>
            </Box>
          </Tooltip>
        </Box>
      ) : (
        <Tooltip
          title={
            isDisabled
              ? t("Cannot define surface once enrichment is processed")
              : disabledPermissions
              ? t("Disabled by permissions")
              : undefined
          }
        >
          <span>
            <DefineSurfaceBtn disabled={isDisabled || disabledPermissions} />
          </span>
        </Tooltip>
      )}

      <MarkerError />

      <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        <LegendItem color={aprilTagSelectedColor} text="Included marker" />
        <LegendItem color={aprilTagNotSelectedColor} text="Excluded marker" />
      </Box>
    </>
  );
});

const DefineSurfaceBtn = memo(({ disabled }: { disabled: boolean }) => {
  const dispatch = useAppDispatch();
  const error = useAppSelector(state => state.video.markerError);
  const isSelectedAprilTagVisible = useAppSelector(
    state => state.projectEdit.isSelectedAprilTagVisible,
  );

  return (
    <Button
      fullWidth
      onClick={dispatch.projectEdit.defineSurface}
      disabled={disabled || !isSelectedAprilTagVisible || error}
      color="primary"
    >
      Define Surface
    </Button>
  );
});

const MarkerError = memo(() => {
  const error = useAppSelector(state => state.video.markerError);

  return (
    <Typography
      variant="body2"
      color={error ? "error" : "text.secondary"}
      sx={{ mb: 1, mt: 2 }}
    >
      A surface needs to be defined with at least 2 markers
    </Typography>
  );
});

const LegendItem: FC<{ color: string; text: string }> = ({ color, text }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
      <Box sx={{ width: 11, height: 11, backgroundColor: color, mr: 1 }} />
      <Typography color="text.secondary">{text}</Typography>
    </Box>
  );
};

const dispatchDebounceRenderUpdate = debounce(
  store.dispatch.projectEdit.updateRender,
  500,
);

const ColorAndSizeChanger = memo(({ disabled }: { disabled: boolean }) => {
  const dispatch = useAppDispatch();
  const model = useAppSelector(state => state.projectEdit.currentEnrichment);
  const gazeCircle = useAppSelector(state => state.projectEdit.form.gazeCircle);
  const gazeColor = useAppSelector(state => state.projectEdit.form.gazeColor);

  if (model?.kind !== "render") return null;

  return (
    <>
      <Box mb={1}>
        <ColorSlider
          label="Gaze Appearance"
          value={gazeColor}
          onChange={e => {
            dispatch.projectEdit.setForm({
              gazeColor: e,
            });
            dispatchDebounceRenderUpdate(null);
          }}
          disabled={disabled}
        >
          <Box mt={1.5} mb={1}>
            <SliderTextField
              label="Radius"
              value={gazeCircle.radius}
              onChange={e => {
                dispatch.projectEdit.setForm({
                  gazeCircle: {
                    ...gazeCircle,
                    radius: e,
                  },
                });
                dispatchDebounceRenderUpdate(null);
              }}
              min={5}
            />
            <SliderTextField
              label="Stroke"
              min={2}
              value={gazeCircle.stroke}
              onChange={e => {
                dispatch.projectEdit.setForm({
                  gazeCircle: {
                    ...gazeCircle,
                    stroke: e,
                  },
                });
                dispatchDebounceRenderUpdate(null);
              }}
            />
          </Box>
        </ColorSlider>
        <CheckBoxGroups disabled={disabled} />
      </Box>
    </>
  );
});

const CheckBoxGroups = ({ disabled }: { disabled: boolean }) => {
  const dispatch = useAppDispatch();
  const undistorted = useAppSelector(state => state.projectEdit.form.undistorted);
  const hasGaze = useAppSelector(state => !state.video.hasGaze);
  const hasFixation = useAppSelector(state => !state.video.hasFixation);
  const hideGaze = useAppSelector(state => !state.projectEdit.hideGaze);
  const hideFixation = useAppSelector(state => !state.projectEdit.hideFixation);

  return (
    <>
      <Tooltip
        title={
          disabled ? "Unable to change once visualization computing has started" : null
        }
      >
        <FormGroup sx={{ mt: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                disabled={hasGaze || disabled}
                checked={hasGaze ? false : hideGaze}
                onChange={(_, value) => {
                  gazeOverlay.hide = !value;
                  dispatch.projectEdit.tryUpdateRender(null);
                }}
              />
            }
            label={
              <Typography variant="body2" color="text.secondary">
                Show Gaze
              </Typography>
            }
          />
        </FormGroup>
      </Tooltip>
      <Tooltip
        title={
          hasFixation
            ? "Fixations are not available for this recording and cannot be rendered"
            : disabled
            ? "Unable to change once visualization computing has started"
            : null
        }
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                disabled={hasFixation || disabled}
                checked={hasFixation ? false : hideFixation}
                onChange={(_, value) => {
                  scanPathOverlay.hide = !value;
                  dispatch.projectEdit.tryUpdateRender(null);
                }}
              />
            }
            label={
              <Typography variant="body2" color="text.secondary">
                Show Fixations
              </Typography>
            }
          />
        </FormGroup>
      </Tooltip>
      <Tooltip
        title={
          disabled ? "Unable to change once visualization computing has started" : null
        }
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={undistorted}
                disabled={disabled}
                onChange={(_, value) => {
                  dispatch.projectEdit.setForm({ undistorted: value });
                  dispatchDebounceRenderUpdate(null);
                }}
              />
            }
            label={
              <Typography variant="body2" color="text.secondary">
                Undistort Video
              </Typography>
            }
          />
        </FormGroup>
      </Tooltip>
    </>
  );
};
