import { Box, styled } from "@mui/system";
import { DraggerTypes, store, useAppDispatch, useAppSelector } from "@storeRematch";
import { memo, useEffect } from "react";

const DraggerRoot = styled(Box, {
  shouldForwardProp: prop => prop !== "vertical",
})<{ disabled?: boolean; vertical?: boolean }>(({ disabled, vertical, theme }) => {
  return {
    position: "absolute",
    top: 0,
    left: 0,
    width: !vertical ? "100%" : disabled ? "10px" : "3px",
    height: vertical ? "100%" : disabled ? "10px" : "3px",
    overflow: "hidden",
    zIndex: 3,

    "&:hover > *": {
      background: theme.palette.primary.main,
    },

    "&:hover": {
      cursor: vertical ? "ew-resize" : "ns-resize",
    },
  };
});

export const Dragger = memo(
  ({ type, vertical }: { type: DraggerTypes; vertical?: boolean }) => {
    const fullScreen = useAppSelector(state => state.video.fullScreen);
    const size = useAppSelector(state => state.dragger[type].size);
    const dispatch = useAppDispatch();

    const handler = (mouseDownEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      mouseDownEvent.preventDefault();
      const startSize = size;
      const startPosition = vertical ? mouseDownEvent.pageX : mouseDownEvent.pageY;

      function onMouseMove(mouseMoveEvent: MouseEvent) {
        const eventPosition = vertical ? mouseMoveEvent.pageX : mouseMoveEvent.pageY;
        const size = vertical
          ? startSize + startPosition - eventPosition
          : startSize - startPosition + eventPosition;
        const percentage = size / (vertical ? window.innerWidth : window.innerHeight);

        dispatch.dragger.resize({
          size,
          percentage,
          type,
        });
      }
      function onMouseUp() {
        document.body.removeEventListener("mousemove", onMouseMove);
      }

      document.body.addEventListener("mousemove", onMouseMove);
      document.body.addEventListener("mouseup", onMouseUp, { once: true });
    };

    if (fullScreen && vertical) return null;

    return (
      <DraggerRoot onMouseDown={handler} disabled vertical={vertical}>
        <DraggerRoot vertical={vertical} />
      </DraggerRoot>
    );
  },
);

export const DraggerWindowResizeListener = () => {
  useEffect(() => {
    const resize = () => {
      store.dispatch.dragger.tryToRecalculate();
    };

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return null;
};
