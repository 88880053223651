import { Vector2d } from "konva/lib/types";

export function getOpacityIndexOfImageData({
  pointer,
  width,
}: {
  pointer: Vector2d | null;
  width: number;
}) {
  if (!pointer) return -1;

  const rIndex =
    Math.round(pointer.y) * (Math.round(width) * 4) + Math.round(pointer.x) * 4;

  return rIndex + 3;
}
