import {
  DownloadProjectEnrichmentsObject,
  DownloadProjectRecordingsObject,
  api,
} from "@api";
import { createModel } from "@rematch/core";
import { copyText, downloadLocally } from "@utils";
import i18n from "../i18n";
import type { RootModel } from "./index";

interface InitialState {
  recordings: DownloadProjectRecordingsObject[];
  enrichments: DownloadProjectEnrichmentsObject[];
}

const initialState: InitialState = {
  recordings: [],
  enrichments: [],
};

export const download = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setRecordings(state, data: InitialState["recordings"]) {
      state.recordings = data;
    },
    setEnrichments(state, data: InitialState["enrichments"]) {
      state.enrichments = data;
    },
  },
  effects: dispatch => ({
    async download(id: string | undefined | null, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichments = state.enrichments.dataById;

      try {
        if (id && workspaceId && projectId) {
          const enrichment = enrichments.get(id);
          if (!enrichment) return;

          downloadLocally({
            href: `${state.app.apiUrl}/workspaces/${workspaceId}/projects/${projectId}/enrichments/${enrichment.id}/export`,
            name: "enrichment_csv.zip",
          });
        } else {
          const recordings = state.projectEdit.recordings;
          const queryString = recordings.map(rec => `id=${rec.id}`).join("&");

          downloadLocally({
            href: `${
              state.app.apiUrl
            }/workspaces/${workspaceId}/recordings:raw-data-export?${queryString}${
              id === null ? "&exclude=scene_video" : ""
            }`,
            name: "recordings.zip",
          });
        }

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Project data downloaded started").toString(),
          options: { variant: "success" },
        });
      } catch (error) {
        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Error downloading project data").toString(),
          options: { variant: "error" },
        });
      }
    },
    async downloadTimeSeriesDataAndSceneVideo(exclude: boolean, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const extraText = exclude ? "" : " + Scene Video";

      try {
        const recordings = state.entityTable.selectedIds;

        if (!recordings) return;

        const queryString = recordings.map(id => `id=${id}`).join("&");

        downloadLocally({
          href: `${
            state.app.apiUrl
          }/workspaces/${workspaceId}/recordings:raw-data-export?${queryString}${
            exclude ? "&exclude=scene_video" : ""
          }`,
          name: `Timeseries_Data${exclude ? "" : "_and_Scene_Video"}.zip`,
        });

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t(`Timeseries Data${extraText} download started`).toString(),
          options: { variant: "success" },
        });
      } catch (error) {
        dispatch.notifier.enqueueSnackbar({
          message: i18n.t(`Error downloading Timeseries Data${extraText}`).toString(),
          options: { variant: "error" },
        });
      }
    },
    async downloadPupilPlayerFormat(all: boolean | undefined, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const recordingsById = state.recordings.dataById;
      const recordingIds = all
        ? state.projectEdit.recordings.map(({ id }) => id)
        : state.entityTable.selectedIds;
      const isNeon = recordingIds.find(id => recordingsById.get(id)?.family === "neon");
      const prefix = isNeon ? "Native Recording Data" : "Pupil Player Format";

      try {
        const queryString = recordingIds.map(id => `id=${id}`).join("&");

        downloadLocally({
          href: `${state.app.apiUrl}/workspaces/${workspaceId}/recordings.zip?${queryString}`,
          name: "recordings.zip",
        });

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t(`${prefix} download started`).toString(),
          options: { variant: "success" },
        });
      } catch (error) {
        dispatch.notifier.enqueueSnackbar({
          message: i18n.t(`Error downloading ${prefix}`).toString(),
          options: { variant: "error" },
        });
      }
    },
    async generatePupilPlayerFormatDownloadLink(all: boolean | undefined, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const recordingsById = state.recordings.dataById;
      const recordingIds = all
        ? state.projectEdit.recordings.map(({ id }) => id)
        : state.entityTable.selectedIds;
      const isNeon = recordingIds.find(id => recordingsById.get(id)?.family === "neon");
      const prefix = isNeon ? "Native recording data" : "Pupil player format";

      try {
        const queryString = recordingIds.map(id => `id=${id}`).join("&");

        const res = await api.generatePupilPlayerFormatDownloadLink({
          workspaceId,
          queryString,
        });

        copyText(i18n.t(`${prefix} link copied`), res.result);
      } catch (error) {
        console.error(error);
      }
    },
    async downloadEnrichmentImage(_, state) {
      const workspaceId = state.app.currentWorkspaceMembership?.workspace.id;
      const projectId = state.app.currentProject?.id;
      const enrichment = state.projectEdit.currentEnrichment;

      if (!workspaceId || !projectId || !enrichment) return;

      try {
        const href = await api.getHeatmapBackground({
          enrichment_id: enrichment.id,
          project_id: projectId,
          workspaceId,
          maxSize: 1920,
        });

        downloadLocally({ href, name: enrichment.name });

        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Image downloaded"),
          options: {
            variant: "success",
          },
        });
      } catch (error) {
        dispatch.notifier.enqueueSnackbar({
          message: i18n.t("Error downloading image"),
          options: {
            variant: "error",
          },
        });
      }
    },
  }),
});
