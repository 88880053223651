import { Button } from "@mui/material";
import { FC } from "react";

export interface DefaultActionsProps {
  onSuccess: () => void;
  onCancel: () => void;
  successText?: string;
  cancelText?: string;
  variant?: "primary" | "error";
  variantSecondary?: "text";
  colorSecondary?: "primary";
}

export const DefaultActions: FC<DefaultActionsProps> = ({
  onSuccess,
  onCancel,
  variant = "primary",
  variantSecondary,
  colorSecondary,
  successText = "Ok",
  cancelText = "Cancel",
}) => {
  return (
    <>
      <Button onClick={onCancel} variant={variantSecondary} color={colorSecondary}>
        {cancelText}
      </Button>
      <Button onClick={onSuccess} variant="contained" color={variant}>
        {successText}
      </Button>
    </>
  );
};

export const RestoreActions: FC<DefaultActionsProps> = props => {
  return <DefaultActions {...props} successText="Restore" />;
};

export const DeleteActions: FC<DefaultActionsProps> = props => {
  return <DefaultActions {...props} variant="error" successText="Delete" />;
};

export const TrashActions: FC<DefaultActionsProps> = props => {
  return <DefaultActions {...props} variant="error" successText="Trash" />;
};

export const ConfirmActions: FC<DefaultActionsProps> = props => {
  return (
    <DefaultActions
      {...props}
      successText="Confirm"
      colorSecondary="primary"
      variantSecondary="text"
    />
  );
};

export const CloseActions: FC<DefaultActionsProps> = props => {
  return (
    <Button onClick={props.onCancel} color="primary">
      Ok
    </Button>
  );
};
